import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { FaTimes } from 'react-icons/fa';

const NavItem = (props) => {
  // Props
  const {
    navItem: _navItem = {},
    onRemove = () => {},
    onChange = () => {},
  } = props;

  // Hooks
  const [navItem, setNavItem] = useState(_navItem || {});
  const [debouncedNavItem] = useDebounce(navItem, 500);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isUrlFocused, setIsUrlFocused] = useState(false);

  useEffect(() => {
    if (!isNameFocused && !isUrlFocused) {
      onChange(navItem);
    }
  }, [debouncedNavItem, isNameFocused, isUrlFocused]);

  const handleNameBlur = () => {
    setTimeout(() => {
      if (!isUrlFocused) {
        setIsNameFocused(false);
      }
    }, 0);
  };

  const handleUrlBlur = () => {
    setTimeout(() => {
      if (!isNameFocused) {
        setIsUrlFocused(false);
      }
    }, 0);
  };

  // End Render funcs
  return (
    <Card xs="12" className='resource px-4 py-3'>
      <Container>
        <Row>
          <Col>
            <Row>
              <div className="name-and-url-container">
                <div className="input-group mb-4">
                  <label className="form-label">Navigation Item Name</label>
                  <input
                    type="text"
                    value={navItem.name}
                    onChange={(e) =>
                      setNavItem(
                        Object.assign({}, navItem, { name: e.target.value })
                      )
                    }
                    onFocus={() => setIsNameFocused(true)}
                    onBlur={handleNameBlur}
                  />
                </div>
                <div className="input-group">
                  <label className="form-label">Navigation Item Url</label>
                  <input
                    type="url"
                    value={navItem.url}
                    onChange={(e) =>
                      setNavItem(
                        Object.assign({}, navItem, { url: e.target.value })
                      )
                    }
                    onFocus={() => setIsUrlFocused(true)}
                    onBlur={handleUrlBlur}
                  />
                </div>
              </div>
            </Row>
          </Col>
          <div className="delete-resource">
            <div className="icon-circle-container" onClick={onRemove}>
              <FaTimes className="icon-trash" />
            </div>
          </div>
        </Row>
      </Container>
    </Card>
  );
};

export default NavItem;
