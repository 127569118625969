import React, { useState, useEffect } from 'react';

// LaunchDarkly
import { useFlags } from 'launchdarkly-react-client-sdk';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import {
	setShowHighlights,
	setShowSuggestions,
} from 'store/reducers/data/transcript';
import {
	setDrawerTab,
	DRAWER_TABS,
} from 'store/reducers/ui/episodes/studio';
import { showPremiumTranscriptionModal } from 'store/reducers/ui/modals/premiumTranscriptionModal';

// Apollo/GraphQL
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import queries from 'services/graphql/queries';

import { toast } from 'react-toastify';
import Toast from 'services/toast';
import {
	Button,
	Dropdown,
	DropdownButton,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Tooltip,
	Spinner,
} from 'react-bootstrap';
import { BsCloudDownload, BsExclamationCircleFill } from 'react-icons/bs';
import { FaPencilAlt, FaTimes, FaCheck } from 'react-icons/fa';
import aiIcon from 'assets/icons/ai-icon.svg';

const TranscriptNav = (props) => {
	const {
		disableExport,
		editorRef,
		humanTransOrderNumber,
		showTranscript: _showTranscript,
		episodeName,
		isEditing,
		setIsEditing,
		onSave,
		onCancel,
		hasSuggestions,
		transcriptPermission,
		takeawaysPermission,
		refreshClips,
		clipLoading,
		setNewRecsViewed,
		/* Redux props start */
		showHighlights,
		showSuggestions,
		episodeId,
		currentTranscript,
		/* Redux props end */
	} = props;

	const { featureClipSuggestionAccess } = useFlags();

	const [updateEpisode] = useMutation(mutations.updateEpisodeById);

	const [generateSuggestions] = useMutation(mutations.generateClipSuggestions);

	const [showTranscript, setShowTranscript] = useState(_showTranscript);
	const [isGenerating, setIsGenerating] = useState(false);
	const [generateClicked, setGenerateClicked] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);

	const { data, startPolling, stopPolling } = useQuery(queries.checkGenerateClipSuggestions, {
		variables: { episodeId },
		fetchPolicy: 'no-cache',
	});
	const { checkGenerateClipSuggestions } = data || {};

	useEffect(() => {
		if (
			checkGenerateClipSuggestions ||
			checkGenerateClipSuggestions?.attempts >=
				checkGenerateClipSuggestions?.maxAttempts - 1
		) {
			setIsGenerating(true);
			refreshClips();
		} else {
			setIsGenerating(false);
			refreshClips();
		}
	}, [checkGenerateClipSuggestions]);

	useEffect(() => {
		if (isGenerating) {
			startPolling(2500);
		} else {
			stopPolling();
			if (generateClicked && !hasSuggestions) {
				setHasErrors(true);
			}
		}
	}, [isGenerating]);

	useEffect(() => {
		if (generateClicked) {
			setNewRecsViewed(true);
			dispatch(setShowSuggestions(true));
			Toast.success('Clips generated', renderToastLink(), { autoClose: false });
		}
	}, [hasSuggestions])

	const renderToastLink = () => {
		return (
			<>
				<span id="ai-toast-link" onClick={() => { dispatch(setDrawerTab(DRAWER_TABS.CLIP_SUGGESTIONS)); toast.dismiss(); }}>Click here</span> to view your recommended clips
			</>
		)
	}

	const renderGenerateActions = () => {
		if (
			(isGenerating && checkGenerateClipSuggestions?.attempts <= 2) ||
			(isGenerating && !checkGenerateClipSuggestions)
		) {
			return (
				<div className="generating-in-progress">
					<Spinner animation="grow" />
					GENERATING...
				</div>
			);
		} else {
			return (
				<>
					{hasErrors && (
						<OverlayTrigger
							placement="left"
							delay={{ show: 100, hide: 4000 }}
							overlay={
								<Tooltip id="generate-error-tooltip">
									<p>Error Loading Recommendations</p>
									Try again or{' '}
									<a href="https://casted.atlassian.net/servicedesk/customer/portal/3">
										contact support
									</a>
								</Tooltip>
							}
						>
							<BsExclamationCircleFill id="generate-error-icon" />
						</OverlayTrigger>
					)}
					<Button
						id="generate-suggestions"
						onClick={() => {
							generateSuggestions({
								variables: {
									episodeId,
								},
							});
							setGenerateClicked(true);
							setIsGenerating(true);
						}}
					>
						<svg
							width="18"
							height="19"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M6.58969 3.18516L6.72469 3.25266C7.27031 3.53391 7.71469 3.97828 7.99594 4.52391L8.06344 4.65891C8.33344 5.19328 9.09844 5.19328 9.37406 4.65891L9.44156 4.52391C9.72281 3.97828 10.1672 3.53391 10.7128 3.25266L10.8478 3.18516C11.3822 2.91516 11.3822 2.15016 10.8478 1.87453L10.7128 1.80703C10.1672 1.52578 9.72281 1.08141 9.44156 0.535781L9.37406 0.400781C9.10406 -0.133594 8.33906 -0.133594 8.06344 0.400781L7.99594 0.535781C7.71469 1.08141 7.27031 1.52578 6.72469 1.80703L6.58969 1.87453C6.05531 2.14453 6.05531 2.90953 6.58969 3.18516Z"
								fill="#14A7D6"
							/>
							<path
								d="M7.65281 8.45578C8.20406 8.17453 8.20406 7.39266 7.65281 7.11141L6.83156 6.68953C6.10031 6.31266 5.50406 5.71641 5.12719 4.98516L4.70531 4.16391C4.42406 3.61266 3.64219 3.61266 3.36094 4.16391L2.93906 4.98516C2.56219 5.71641 1.96594 6.31266 1.23469 6.68953L0.413438 7.11141C-0.137812 7.39266 -0.137812 8.17453 0.413438 8.45578L1.23469 8.87766C1.96594 9.25453 2.56219 9.85078 2.93906 10.582L3.36094 11.4033C3.64219 11.9545 4.42406 11.9545 4.70531 11.4033L5.12719 10.582C5.50406 9.85078 6.10031 9.25453 6.83156 8.87766L7.65281 8.45578Z"
								fill="#14A7D6"
							/>
							<path
								d="M18.2053 11.8195L17.3728 11.392C16.3659 10.8802 15.5616 10.0758 15.0497 9.06891L14.6222 8.23641C14.2453 7.49391 13.4916 7.03266 12.6591 7.03266C11.8266 7.03266 11.0728 7.49391 10.6959 8.23641L10.2684 9.06891C9.75656 10.0758 8.95219 10.8802 7.94531 11.392L7.11281 11.8195C6.37031 12.1964 5.90906 12.9502 5.90906 13.7827C5.90906 14.6152 6.37031 15.3689 7.11281 15.7458L7.94531 16.1733C8.95219 16.6852 9.75656 17.4895 10.2684 18.4964L10.6959 19.3289C11.0728 20.0714 11.8266 20.5327 12.6591 20.5327C13.4916 20.5327 14.2453 20.0714 14.6222 19.3289L15.0497 18.4964C15.5616 17.4895 16.3659 16.6852 17.3728 16.1733L18.2053 15.7458C18.9478 15.3689 19.4091 14.6152 19.4091 13.7827C19.4091 12.9502 18.9478 12.1964 18.2053 11.8195ZM17.4403 14.2439L16.6078 14.6714C15.2803 15.3464 14.2228 16.4039 13.5478 17.7314L13.1203 18.5639C12.9909 18.817 12.7547 18.8452 12.6591 18.8452C12.5634 18.8452 12.3272 18.817 12.1978 18.5639L11.7703 17.7314C11.0953 16.4039 10.0378 15.3464 8.71031 14.6714L7.87781 14.2439C7.62469 14.1145 7.59656 13.8783 7.59656 13.7827C7.59656 13.687 7.62469 13.4508 7.87781 13.3214L8.71031 12.8939C10.0378 12.2189 11.0953 11.1614 11.7703 9.83391L12.1978 9.00141C12.3272 8.74828 12.5634 8.72016 12.6591 8.72016C12.7547 8.72016 12.9909 8.74828 13.1203 9.00141L13.5478 9.83391C14.2228 11.1614 15.2803 12.2189 16.6078 12.8939L17.4403 13.3214C17.6934 13.4508 17.7216 13.687 17.7216 13.7827C17.7216 13.8783 17.6934 14.1145 17.4403 14.2439Z"
								fill="#14A7D6"
							/>
						</svg>
						GENERATE RECOMMENDATIONS
					</Button>
				</>
			);
		}
	};

	const exportTranscript = (exportType) => {
		const { data, ext } = editorRef.current.getEditorContent(exportType);
		let tmpData = data;
		if (ext === 'json') {
			tmpData = JSON.stringify(data, null, 2);
		}

		if (ext !== 'docx') {
			download(tmpData, `${episodeName}-transcript.${ext}`);
		}
	};

	const download = (content, filename, contentType) => {
		const type = contentType || 'application/octet-stream';
		const link = document.createElement('a');
		const blob = new Blob([content], { type: type });

		link.href = window.URL.createObjectURL(blob);
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Col className="d-flex transcript-nav">
			<Row className="d-flex justify-content-between w-100">
				<Col className="d-flex">
					<DropdownButton
						title="OPTIONS"
						autoclose="outside"
						className="options-dropdown"
					>
						{featureClipSuggestionAccess && hasSuggestions && (
							<Dropdown.ItemText>
								<Form.Check
									type="switch"
									id="toggle-show-suggestions"
									label={
										<span>
											Show
											<img src={aiIcon} id="ai-sparkle" />
											Recommendations
										</span>
									}
									checked={showSuggestions}
									onChange={() => {
										dispatch(setShowSuggestions(!showSuggestions));
									}}
								/>
							</Dropdown.ItemText>
						)}
						<Dropdown.ItemText>
							<Form.Check
								type="switch"
								id="toggle-highlight-clips"
								label={'Highlight Clips in Transcript'}
								checked={showHighlights}
								onChange={() => {
									dispatch(setShowHighlights(!showHighlights));
								}}
							/>
						</Dropdown.ItemText>
						<Dropdown.ItemText>
							<Form.Check
								type="switch"
								id="toggle-show-transcript"
								label={'Show Transcript on Players'}
								checked={showTranscript}
								onChange={() => {
									updateEpisode({
										variables: {
											episodeId,
											episode: {
												showTranscript: !showTranscript,
											},
										},
									});
									setShowTranscript(!showTranscript);
								}}
							/>
						</Dropdown.ItemText>
					</DropdownButton>
					{transcriptPermission.canEdit && (
						<>
							<Button
								onClick={() => {
									setIsEditing(true);
								}}
								disabled={isEditing}
								variant="link"
							>
								{isEditing ? 'EDITING' : 'EDIT'} <FaPencilAlt size={14} />
							</Button>
							<OverlayTrigger
								placement="top"
								delay={{ show: 100, hide: 100 }}
								overlay={
									<Tooltip id="transcript-tooltip">
										Have this transcription redone by a human using premium
										transcription
									</Tooltip>
								}
							>
								<Button
									hidden={humanTransOrderNumber ? true : false}
									className="transcript--improve-btn"
									onClick={() => {
										dispatch(showPremiumTranscriptionModal());
									}}
									variant="link"
								>
									UPGRADE
									<svg
										width="15"
										height="14"
										viewBox="0 0 15 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.93867 16H0V0H6.79267C8.90133 0 13.3333 4.81533 13.3333 6.40933V7.788C12.9187 7.59067 12.4713 7.45333 12 7.386V6.97067C12 4.23333 8 5.33333 8 5.33333C8 5.33333 9.012 1.33333 6.24133 1.33333H1.33333V14.6667H7.50667C7.884 15.2067 8.37267 15.66 8.93867 16ZM10.4433 10.2207C10.7113 10.0833 11.0113 10 11.3333 10C12.3033 10 13.1133 10.6947 13.294 11.612L14.6007 11.3467C14.2967 9.81933 12.9507 8.66667 11.3333 8.66667C10.6953 8.66667 10.1033 8.852 9.59733 9.16333L8.66667 8L8 11.3333H11.3333L10.4433 10.2207ZM14 16L13.0693 14.8367C12.5633 15.148 11.9713 15.3333 11.3333 15.3333C9.716 15.3333 8.37 14.1807 8.066 12.6533L9.37267 12.388C9.55333 13.3053 10.3633 14 11.3333 14C11.6553 14 11.9553 13.9167 12.2233 13.7793L11.3333 12.6667H14.6667L14 16Z"
											fill="#323232"
										/>
									</svg>
								</Button>
							</OverlayTrigger>
						</>
					)}
					<DropdownButton
						title={
							<span>
								DOWNLOAD
								<BsCloudDownload size={14} />
							</span>
						}
						className="export-menu"
						disabled={disableExport || isEditing}
					>
						<Dropdown.Item onClick={() => exportTranscript('txt')}>
							Text File
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => exportTranscript('txtspeakertimecodes')}
						>
							Text file w/ Speakers and Timecodes
						</Dropdown.Item>
						<Dropdown.Item onClick={() => exportTranscript('docx')}>
							MS Word
						</Dropdown.Item>
					</DropdownButton>
				</Col>
				<Col className="d-flex justify-content-end align-items-center">
					{isEditing && (
						<>
							<Button
								className="discard-changes"
								onClick={() => {
									onCancel();
								}}
								variant="link"
							>
								<FaTimes size={14} /> DISCARD CHANGES
							</Button>
							<Button
								className="save-changes"
								onClick={() => {
									onSave();
								}}
								variant="link"
							>
								<FaCheck size={14} /> SAVE CHANGES
							</Button>
						</>
					)}
					{!clipLoading &&
						!isEditing &&
						!hasSuggestions &&
						takeawaysPermission.canEdit &&
						featureClipSuggestionAccess &&
						Object.keys(currentTranscript).length > 0 &&
						renderGenerateActions()}
				</Col>
			</Row>
		</Col>
	);
};

const mapStateToProps = (state) => ({
	episodeId: state.episode.episodeId,
	permissions: state.auth.permissions,
	showHighlights: state.transcript.showHighlights,
	showSuggestions: state.transcript.showSuggestions,
	currentTranscript: state.transcript.currentTranscript,
});

export default connect(mapStateToProps)(TranscriptNav);
