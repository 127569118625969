import React from 'react';
import { Card, Row, Col, Spinner } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

import MetricCircle from './circle';
import MetricEmptyState from 'components/shared/metricEmptyState';
import { lowerCaseAndRemoveSpaces } from 'utils';

const MetricCircles = (props) => {
	const {
		className = null,
		total = 100,
		label = '',
		children,
		title = '',
		loading = true,
		unit = '',
		showIcon = true,
	} = props;

	const circles = React.Children.map(children, (child, i) => {
		const radius = 95 - i * 15;
		return React.cloneElement(child, { total, radius });
	});

	if (!loading && total === 0) {
		return <MetricEmptyState metricName={title} />;
	}

	return (
		<Card className={`metric-circles ${className}`}>
			<Card.Body>
				{showIcon && (
					<Row>
						{title && <p className="metric-circles--title">{title}</p>}
						<FaInfoCircle
							className="info-icon"
							id={`help-${lowerCaseAndRemoveSpaces(title)}`}
						/>
					</Row>
				)}
				{loading ? (
					<Row className="loading">
						<Col>
							<Spinner animation="border" variant="info" />
						</Col>
					</Row>
				) : (
					<>
						<Row>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
								{circles}
							</svg>
						</Row>
						<Row>
							<div className="metric-circles--total">
								<span className="circles-total--value">{total}</span>
								<span className="circles-total--label">{label}</span>
							</div>
						</Row>
					</>
				)}
				{!loading &&
					children.map((c, i) => (
						<Row className="metric-circle--legend-row" noGutters key={i}>
							<Col xs="3">
								<div className={`metric-circles--circle-value color-${i}`}>
									{Math.round((c.props.value / total) * 100)}%
								</div>
							</Col>
							<Col>
								<div className="metric-circles--circle-label">
									{c.props.label}
								</div>
							</Col>
							<Col>
								<div className="metric-circles--circle-total">{`( ${c.props.value} ${unit})`}</div>
							</Col>
						</Row>
					))}
			</Card.Body>
		</Card>
	);
};

MetricCircles.Circle = MetricCircle;

export default MetricCircles;
