import { combineReducers } from 'redux';

import episodes from './episodes';
import modals from './modals';
import themes from './themes';
import playlists from './playlists';

export default combineReducers({
	episodes,
	modals,
	themes,
	playlists,
});
