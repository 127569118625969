import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';
import queries from 'services/graphql/queries';
import { getSelectedAccount } from 'store/selectors/accounts';
import { METRICS_CONTEXTS } from 'components/constants';

import MetricsScreen from 'components/shared/metricsScreen';

const AccountMetrics = (props) => {
	const { match } = props;
	const { params } = match;
	const { accountId: rawAccountId } = params;

	const accountId = parseInt(rawAccountId, 10);

	// Determine if account has Hosted shows
	const { data } = useQuery(queries.podcastsByAccountId, {
		variables: { accountId },
	});
	const { podcasts } = data || {};
	const isHosted =
		podcasts && podcasts.some((p) => p.externalId) ? true : false;

	const contextQueries = {
		integrations: queries.accountIntegrations,
		totalAndUniqueAudience: queries.getAccountListenSummary,
		newAndLoyalAudience: queries.getAccountNewAndLoyalListeners,
		averageCompletion: queries.getAccountAverageCompletion,
		listensOverTimeHosted: queries.getDailyAccountDownloadAndPlayCount,
		listensOverTimeUnhosted: queries.getDailyAccountListens,
	};

	return (
		<MetricsScreen
			accountId={accountId}
			metricsContext={METRICS_CONTEXTS.account}
			isHosted={isHosted}
			contextIdVariable={{ accountId: accountId }}
			contextQueries={contextQueries}
			className={'account-metrics'}
		/>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
});

export default withRouter(connect(mapStateToProps)(AccountMetrics));
