import React from 'react';
import { Facet } from '@elastic/react-search-ui';

import Checkbox from '../../checkbox';

import { RECORD_TYPES } from '../../../constants';

import styles from './styles.scss';

const ContentTypeFilter = (props) => {
	const {
		allowedRecordTypes = [
			RECORD_TYPES.EPISODE_TRANSCRIPT,
			RECORD_TYPES.EPISODE,
			RECORD_TYPES.CLIP,
		],
		field,
		fieldLabel,
	} = props;

	return (
		<div className={styles.clipTypeFilter}>
			<Facet
				field={field}
				label={fieldLabel}
				filterType="any"
				view={({ label, onRemove, onSelect, options }) => {
					return (
						<fieldset>
							<h3>{label}</h3>
							<ul>
								{options &&
									options
										.sort((a, b) => {
											if (a.value < b.value) {
												return -1;
											}
											if (a.value > b.value) {
												return 1;
											}
											return 0;
										})
										.map((option, key) => {
											const checked = option.selected;

											let checkboxLabel = option.value;

											// Only display allowed record types
											if (
												!allowedRecordTypes.some(
													(recordType) => recordType === checkboxLabel
												)
											) {
												return null;
											}

											switch (checkboxLabel) {
												case RECORD_TYPES.EPISODE:
													checkboxLabel = 'Episodes';
													break;
												case RECORD_TYPES.CLIP:
													checkboxLabel = 'Clips';
													break;
												case RECORD_TYPES.EPISODE_TRANSCRIPT:
													checkboxLabel = 'Episode Transcripts';
													break;
											}

											return (
												<li key={`record_type-checkbox-${key}`}>
													<Checkbox
														id={`has_audiogram-${key}`}
														label={checkboxLabel}
														checked={checked || false}
														onChange={() =>
															checked
																? onRemove(option.value)
																: onSelect(option.value)
														}
													/>
												</li>
											);
										})}
							</ul>
						</fieldset>
					);
				}}
			/>
		</div>
	);
};

export default ContentTypeFilter;
