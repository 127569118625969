export const ACTIONS = {
	SET_THEME: 'CASTED/SET_THEME',
	SET_THEME_UPDATES: 'CASTED/SET_THEME_UPDATES',
	CLEAR_THEME_STORE: 'CASTED/CLEAR_THEME_STORE',
	SET_THEME_KEYWORD_LIMIT: 'CASTED/SET_THEME_KEYWORD_LIMIT',
};

const initialState = {
	theme: {},
	themeUpdates: {},
	themeKeywordLimit: 100,
};

export const setTheme = (theme) => {
	return {
		type: ACTIONS.SET_THEME,
		theme,
	};
};

export const setThemeUpdates = (themeUpdates) => {
	return {
		type: ACTIONS.SET_THEME_UPDATES,
		themeUpdates,
	};
};

export const clearThemeStore = () => {
	return {
		type: ACTIONS.CLEAR_THEME_STORE,
	};
};

export const setThemeKeywordLimit = (themeKeywordLimit) => {
	return {
		type: ACTIONS.SET_THEME_KEYWORD_LIMIT,
		themeKeywordLimit,
	};
};

const themeReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SET_THEME:
			return {
				...state,
				theme: action.theme,
			};
		case ACTIONS.SET_THEME_UPDATES:
			return {
				...state,
				themeUpdates: action.themeUpdates,
			};
		case ACTIONS.CLEAR_THEME_STORE:
			return {
				...state,
				theme: initialState.theme,
				themeUpdates: initialState.themeUpdates,
			};
		case ACTIONS.SET_THEME_KEYWORD_LIMIT:
			return {
				...state,
				themeKeywordLimit: action.themeKeywordLimit,
			};
		default:
			return state;
	}
};

export default themeReducer;
