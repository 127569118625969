import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import convert from 'htmr';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

// External Components
import {
	Dropdown,
	ProgressBar,
	Modal,
	Button,
	Badge,
	Row,
	Col,
} from 'react-bootstrap';
import { FaEllipsisH, FaVideo, FaMicrophone } from 'react-icons/fa';

// Internal Components
import { ShareContentModal, ConfirmationModal } from 'components/shared';
import FeatureEpisode from 'components/shows/episodes/featureEpisode';
import DeleteEpisode from 'components/shows/episodes/deleteEpisode';

// Internal Libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import {
	showUploadWizardOnLoad,
	hideUploadWizardOnLoad,
} from 'store/reducers/ui/modals/uploadWizardModal';
import { dispatch } from 'store/store';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';
import {
	EPISODE_UPLOAD_TYPES,
	EPISODE_PUBLIC_STATUS,
	SHARE_MODAL_TYPE,
	PODCAST_COLLECTION_TYPE,
} from 'components/constants';
import { formatDuration } from 'utils';

const EpisodeListRow = (props) => {
	// Props
	const {
		history,
		episode,
		accountId,
		showId,
		featured,
		showSlug = 0,
		showThumbnail,
		showIsHosted,
		permissions,
		refreshPage = () => {},
		customDomain,
		videoEnabled,
		showExternalId,
		youtubeIntegration,
		collectionType,
		disableLinkSharing,
	} = props;

	const {
		id: episodeId,
		name,
		thumbnail,
		status: _status,
		publicStatus: _publicStatus,
		hostedUrl,
		duration,
		slug: episodeSlug,
		videoLink,
		externalId,
		storageLink,
		uploadType,
		type,
		rssAudioStorageLink,
		description,
		showNotes,
	} = episode;

	const episodePermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.EPISODES, showId)
				.rolePermission
		: {};
	// END Props

	// FF Hooks
	const { featureLandingPageRedesign } = useFlags();
	// END FF Hooks

	// State Hooks
	const [status, setStatus] = useState(_status);
	const [publicStatus, setPublicStatus] = useState(_publicStatus);
	const [shareEpisode, setShareEpisode] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [featureEpisode, setFeatureEpisode] = useState(false);
	const [hoverFeature, setHoverFeature] = useState(false);
	const [confirmUnfeature, setConfirmUnfeature] = useState(false);
	// END state hooks

	/**
	 * Refs
	 */
	// Hold actions in a DOM ref so we can protect it's click from row click
	const episodeActions = useRef(null);

	/**
	 * Queries
	 */
	// Get Episode Status
	// If episode is syncing, poll for status changes
	const { data: epData } = useQuery(queries.getEpisodeStatus, {
		variables: { episodeId },
		skip: ['transcribing', 'indexing', 'active'].includes(
			status ? status : _status
		),
		pollInterval: 5000,
		fetchPolicy: 'no-cache',
		status: {
			not: 'inactive'
		  }
	});
	const { basicEpisode: epUpdate = {} } = epData || {};
	const {
		status: newStatus,
		publicStatus: newPublicStatus,
		id: checkedId,
	} = epUpdate || {};

	// Get Transcription Progress
	// If episode is transcribing, poll for progress
	const { data: transData } = useQuery(queries.getTranscriptProgress, {
		variables: { episodeId, accountId },
		skip: status !== 'transcribing',
		pollInterval: 20000,
		fetchPolicy: 'no-cache',
	});
	const { getTranscriptProgress = {} } = transData || {};
	const { progress: transProgress = 0, status: transStatus = null } =
		getTranscriptProgress || {};

	/**
	 * Mutations
	 */
	// Download Episode
	//Regression - For episodes that have not been synced, let user start
	const [startSync, { loading: startSyncLoading }] = useMutation(
		mutations.downloadEpisode,
		{
			variables: { hostedUrl, accountId, episodeId },
			refetchQueries: ['getEpisodeStatus'],
			onCompleted: () => {
				setStatus('loading');
			},
		}
	);

	// Retry Transcription
	// If episode runs out of attempts, give user an option to retry
	const [retrySync, { loading: retrySyncLoading }] = useMutation(
		mutations.retryFailedTranscription,
		{
			variables: { episodeId, accountId },
			refetchQueries: ['getEpisodeStatus'],
		}
	);

	// Delete Episode, unfeature if featured
	const [deleteEp, {}] = useMutation(mutations.deleteEpisodeById, {
		variables: { episodeId },
		onComplete: () => {
			if (featured) {
				unfeatureEp();
			}
			refreshPage();
		},
	});

	//Unfeature Episode
	const [unfeatureEp, {}] = useMutation(mutations.updatePodcastById, {
		variables: {
			podcastId: showId,
			podcast: {
				accountId,
				featuredEpisode: null,
			},
		},
	});

	/* -- End Queries and Mutations -- */

	/**
	 * Effects
	 */
	// Update initial episode status when sync step changes
	useEffect(() => {
		if (newStatus && newStatus !== status) setStatus(newStatus);
		if (newPublicStatus && newPublicStatus !== publicStatus)
			setPublicStatus(newPublicStatus);
	}, [newStatus, newPublicStatus]);

	// Once Transcription Reports done, ask for rerender
	useEffect(() => {
		if (transProgress === 1) {
			refreshPage();
		}
	}, [transProgress]);
	/* -- End Effects -- */

	/**
	 * Render Helpers
	 */
	// Hack for making whole row a conditional link
	const [episodeClick, setepisodeClick] = useState(false);
	const handleEpisodeClick = (e) => {
		// If click came from actions drop downdown, skip
		if (
			e.target == episodeActions.current ||
			episodeActions.current.contains(e.target)
		)
			return;
		// Don't allow click if episode has not been downloaded or is in an error status
		if (status !== 'inactive' && status !== 'error') {
			setepisodeClick(episodeId);
		}
	};
	if (episodeClick) {
		// If no storageLink or rssAudioStorageLink, display uploadWizard on load
		if (!storageLink && !rssAudioStorageLink) {
			dispatch(showUploadWizardOnLoad());
		} else {
			dispatch(hideUploadWizardOnLoad());
		}

		return (
			<Redirect
				to={`/account/${accountId}/shows/${showId}/episodes/${episodeId}/info`}
			/>
		);
	}

	//Copy Featured Custom info

	// Sharing urls
	const publicUrl = customDomain
		? `https://${customDomain}`
		: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;
	const shareUrl = `${publicUrl}/public/${accountId}/${showSlug}/${episodeSlug}`;

	const cardText =
		episode.metaDescription || episode.showNotes || episode.description;

	// Handle all the different possible episode statuses
	const renderStatus = () => {
		let episodeBadges = [];
		let episodeBadgeClass = '';

		switch (status) {
			case 'queued':
				if (!storageLink) {
					episodeBadges.push(
						<Badge
							className="episode--featured"
							pill
							variant="warning"
						>
							Missing Media
						</Badge>
					);
				}
				episodeBadges.push(
					<Badge
						className="episode--public-status"
						pill
						variant="light"
					>
						Queued
					</Badge>
				);

				break;
			case 'loading':
				episodeBadgeClass = 'status--syncing';

				episodeBadges.push(
					<span className="status--sync-step sync-step--downloading">
						Downloading
					</span>,
					<ProgressBar animated now={25} variant="info"></ProgressBar>
				);

				break;
			case 'converting':
				episodeBadgeClass = 'status--syncing';

				episodeBadges.push(
					<span className="status--sync-step sync-step--converting">
						Processing
					</span>,
					<ProgressBar animated now={50} variant="info"></ProgressBar>
				);

				break;
			case 'transcribing':
				episodeBadgeClass = 'status--syncing';

				episodeBadges.push(
					<span className="status--sync-step sync-step--transcribing">
						Transcribing
					</span>,
					<ProgressBar
						animated
						now={transProgress * 50 + 50}
						variant="info"
					></ProgressBar>
				);

				break;
			case 'inactive':
				episodeBadges.push(
					<Badge
						className="episode--public-status"
						pill
						variant="light"
					>
						Not Linked
					</Badge>,
					<Button
						onClick={startSync}
						disabled={startSyncLoading}
						className="episode--sync-action"
						variant="outline-primary"
					>
						Sync
					</Button>
				);

				break;
			case 'error':
				episodeBadges.push(
					<Badge
						className="episode--public-status"
						pill
						variant="danger"
					>
						Error Syncing
					</Badge>
				);

				if (!showIsHosted) {
					episodeBadges.push(
						<Button
							onClick={retrySync}
							disabled={retrySyncLoading}
							className="episode--sync-action"
							variant="outline-danger"
						>
							Retry
						</Button>
					);
				}

				break;
			default:
				//if the show is featured, show pill. On hover, user can unfeature episode.
				if (featured) {
					episodeBadges.push(
						<Badge
							className={`episode--featured mr-2 ${
								hoverFeature && 'hover'
							}`}
							pill
							variant="info"
							onClick={(e) => {
								e.stopPropagation();
								setConfirmUnfeature(true);
							}}
							onMouseEnter={(e) => {
								setHoverFeature(true);
							}}
							onMouseLeave={(e) => {
								setHoverFeature(false);
							}}
						>
							{hoverFeature ? 'Clear?' : 'Featured'}
						</Badge>
					);
				}

				episodeBadges.push(
					<Badge
						className="episode--public-status"
						pill
						variant="light"
					>
						{publicStatus}
					</Badge>
				);

				break;
		}

		episodeBadges.push(
			<Badge variant="light" pill className="upload-type">
				{uploadType === 'video' ? <FaVideo /> : <FaMicrophone />}
			</Badge>
		);

		return (
			<div className={`episode--status ${episodeBadgeClass}`}>
				{episodeBadges.map((episodeBadge, i) => {
					return episodeBadge;
				})}
			</div>
		);
	};
	// Render
	return (
		<div
			className={`episode-list--episode episode-status--${status} ${
				showExternalId ? 'hosted' : ''
			}`}
		>
			<Row className="episode--row" onClick={handleEpisodeClick}>
				<Col xs={1} className="episode-row--thumbnail">
					<img
						className="episode--thumbnail"
						src={thumbnail || showThumbnail}
						alt="Episode Thumbnail"
					/>
				</Col>

				<Col className="episode-row--info">
					<h5 className="episode--name">{name}</h5>
					<span className="episode--duration">
						{formatDuration(duration)} Mins
					</span>
					{type !== 'full' ? (
						<span className="episode-type">
							{` | ` +
								type.charAt(0).toUpperCase() +
								type.slice(1)}
						</span>
					) : null}
					{publicStatus === EPISODE_PUBLIC_STATUS.PUBLIC ? (
						<p>
							<span className="episode--pub-date">
								Published on{' '}
								{moment(episode?.publishedAt).format(
									'MMM D, YYYY'
								)}
							</span>
						</p>
					) : (
						<p>
							<span className="episode--pub-date">
								Drafted on{' '}
								{moment(episode?.createdAt).format(
									'MMM D, YYYY'
								)}
							</span>
						</p>
					)}
				</Col>

				<Col xs="auto" className="episode-row--status">
					{renderStatus()}
				</Col>

				<Col
					xs="auto"
					className="episode-row--actions"
					ref={episodeActions}
				>
					<Dropdown className="episode--actions">
						<Dropdown.Toggle id="episode-actions" as="div">
							<FaEllipsisH />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									setShareEpisode(true);
								}}
							>
								Share
							</Dropdown.Item>
							{episodePermission.canEdit && (
								<Dropdown.Item
									onClick={() => {
										featured
											? setConfirmUnfeature(true)
											: setFeatureEpisode(true);
									}}
								>
									{featured
										? 'Clear Featured Item?'
										: 'Feature'}
								</Dropdown.Item>
							)}
							{episodePermission.canEdit && (
								<Dropdown.Item
									onClick={() => {
										setConfirmDelete(true);
									}}
								>
									Delete
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</Col>
			</Row>

			<ShareContentModal
				isOpen={shareEpisode}
				onClose={() => {
					setShareEpisode(false);
				}}
				shareContentName={name}
				shareContentDuration={`${formatDuration(duration)} Mins`}
				shareContentThumbnail={thumbnail || showThumbnail}
				shareUrl={`${shareUrl}`}
				videoEnabled={videoEnabled && videoLink ? true : false}
				baseUrl={publicUrl}
				contentSlug={episodeSlug}
				renderMoreContent={() => (
					<div>{cardText ? convert(cardText) : ''}</div>
				)}
				accountId={accountId}
				showSlug={showSlug}
				publicStatus={publicStatus}
				name={name}
				description={
					PODCAST_COLLECTION_TYPE.serial
						? description?.replace(/(<([^>]+)>)/gi, '') || ''
						: episode.showNotes?.replace(/(<([^>]+)>)/gi, '') || ''
				}
				showPublishToYoutube={
					youtubeIntegration &&
					youtubeIntegration.status === 1 &&
					episode.uploadType === EPISODE_UPLOAD_TYPES.VIDEO &&
					episode.youtubeId === null
				}
				shareId={episodeId}
				thumbnail={thumbnail}
				showThumbnail={showThumbnail}
				shareModalType={SHARE_MODAL_TYPE.EPISODE}
				linkText="This link can be shared on social media, see the preview below."
				embedText="Embed an episode player on your website. There are three different types of Embeds for this episode. Select an embed type and options below and view the embed preview on the right:"
				socialText="Utilize the Social media embed feature to share your episodes directly onto your LinkedIn timeline"
				youtubeText="This link can be shared on social media, see the preview below."
				disableLinkSharing={disableLinkSharing}
			/>

			<Modal
				show={featureEpisode}
				size="lg"
				onHide={() => {
					setFeatureEpisode(false);
				}}
				centered
			>
				<FeatureEpisode
					podcastId={showId}
					accountId={accountId}
					episodeId={episodeId}
					featured={featured}
					onHide={() => {
						setFeatureEpisode(false);
					}}
					onConfirm={() => {
						setFeatureEpisode(false);
						refreshPage();
					}}
				/>
			</Modal>

			<Modal
				show={confirmDelete}
				onHide={() => {
					setConfirmDelete(false);
				}}
				centered
			>
				<DeleteEpisode
					onHide={() => {
						setConfirmDelete(false);
					}}
					onConfirm={async () => {
						await deleteEp();
						setConfirmDelete(false);
						refreshPage();
					}}
				/>
			</Modal>

			<ConfirmationModal
				isOpen={confirmUnfeature}
				title="Clear Featured Item?"
				confirmationText={`Are you sure you want to clear this featured item? All custom feature text will be lost.`}
				confirmationButtonLabel="Clear Item"
				onCancel={() => {
					setConfirmUnfeature(false);
				}}
				onConfirm={() => {
					if (featured) {
						unfeatureEp();
						setConfirmUnfeature(false);
						setFeatureEpisode(true);
					}
					unfeatureEp();
					setConfirmUnfeature(false);
					setFeatureEpisode(false);
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(EpisodeListRow);
