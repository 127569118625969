import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { METRICS_CONTEXTS } from 'components/constants';

// Internal Components
import { PromotionListWithRouter } from 'components/shared/promotions/promotionList';

const ItemPromotions = (props) => {
	return (
		<>
			<PromotionListWithRouter context={METRICS_CONTEXTS.item} />
		</>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
});

export const ItemPromotionsWithRouter = withRouter(
	connect(mapStateToProps)(ItemPromotions)
);
