import React from 'react';
import { Button, Form, Container, Col } from 'react-bootstrap';

import CastedModal from 'components/shared/modal';

const IntegrationScopesModal = (props) => {
	const {
		isOpen,
		title,
		modalDialouge,
		confirmationButtonLabel,
		scopes,
		changeScopes = () => {},
		onConfirm = () => {},
		onCancel = () => {},
	} = props;

	const onSubmitClick = () => onConfirm();
	const renderScopes = () => {
		const scopeRows = scopes.map((scope, index) => {
			return (
				<Form.Row className='scope-form-row' key={index}>
					<Col>
						<div className='scope-details-container'>
							<Form.Text className='scope-label form-label'>{scope.name}</Form.Text>
							<Form.Check
								type='checkbox'
								id={`${scope.name}-${index}`}
								checked={scope.checked}
								className='casted-form scope--check'
								onChange={() => {
									changeScopes(
										scopes.map((s) =>
											s.name === scope.name
												? { ...s, checked: !scope.checked }
												: s
										)
									);
								}}
							/>
						</div>
					</Col>
				</Form.Row>
			);
		});
		return scopeRows;
	};

	return (
		<CastedModal
			show={isOpen}
			handleClose={onCancel}
			title={title}
			modalSize='xsm'
			headerStyle={{
				justifyContent: 'center',
			}}
		>
			<Container className='integration-scopes-modal'>
				<p className='form-label scope-modal-dialouge'>{modalDialouge}</p>
				<Form>{renderScopes()}</Form>
				<div className='d-flex justify-content-center'>
					<Button
						variant='success'
						className='submit-key scope-submit-button label-btn-text'
						onClick={onSubmitClick}
					>
						{confirmationButtonLabel}
					</Button>
				</div>
			</Container>
		</CastedModal>
	);
};

export default IntegrationScopesModal;
