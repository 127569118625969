import React from 'react';
import RCCheckbox from 'rc-checkbox';

const Checkbox = (props) => {
	const { checked, onChange = () => {}, className, disabled } = props;

	return (
		<RCCheckbox
			{...props}
			checked={checked}
			className={`checkbox ${disabled ? 'disabled' : ''} ${className}`}
			onChange={onChange}
			disabled={disabled}
		/>
	);
};

export default Checkbox;
