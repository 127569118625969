import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client/react/hooks';

// External Components
import { Spinner } from 'react-bootstrap';

// Internal Components
import { PromotionCardWithRouter } from 'components/shared/promotions/promotionCard';

import podcat from 'assets/images/podcat-playing.svg';

// Internal Libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';
import { METRICS_CONTEXTS, PODCAST_COLLECTION_TYPE } from 'components/constants';
import { getCanEdit, ObjectPermissionSlugs } from 'utils/permissionsManager';

const PromotionList = (props) => {
	const { accountId, match, context, permissions } = props;

	const { params } = match;
	const { showId: collectionId, episodeId: itemId } = params;

	const canEditPromotions =
		permissions &&
		getCanEdit(permissions, ObjectPermissionSlugs.EPISODES, collectionId);

	/** Queries & Mutations **/
	const { data, loading, error } = useQuery(queries.getPromotions, {
		variables: { accountId, collectionId, itemId },
		fetchPolicy: 'no-cache',
	});
	const promotions = data?.promotions || [];

	const {
		data: collectionData,
		loading: collectionLoading,
		error: collectionError,
	} = useQuery(queries.podcastById, {
		variables: { podcastId: collectionId }
	});
	const collection = collectionData?.podcast || {};
	const { collectionType } = collection;

	// if we're in a one-off collection, get the item ID to pass to card for links
	const {
		data: standaloneItemData,
		loading: standaloneItemLoading,
		error: standaloneItemError,
	} = useQuery(queries.episodesByPodcastId, {
		variables: { podcastId: collectionId },
		enabled: collectionType === PODCAST_COLLECTION_TYPE.one_off
	});
	const standaloneItemArray = standaloneItemData?.episodes || {};
	const standaloneItemId = standaloneItemArray[0]?.id;

	const [createPromotion] = useMutation(mutations.createPromotion);

	const newPromotionOnClick = async () => {
		try {
			let variables = {
				accountId,
			};
			if (itemId) {
				variables.itemId = itemId;
			} else {
				variables.collectionId = collectionId;
			}
			let res = await createPromotion({
				variables,
			});
			const { data } = res;
			const { createPromotion: createdPromotion } = data;

			if (createdPromotion && createdPromotion.id) {
				trackEvent('add-promotion', {
					...variables,
					context: context,
				});
				if (context === METRICS_CONTEXTS.collection) {
					props.history.push(
						`/account/${accountId}/shows/${collectionId}/promotions/${createdPromotion.id}`
					);
				} else {
					props.history.push(
						`/account/${accountId}/shows/${collectionId}/episodes/${itemId}/promotions/${createdPromotion.id}`
					);
				}
			}
		} catch (e) {
			console.error(e);
			Toast.error('Unable to create promotion');
		}
	};

	const renderAddNewPromotion = () => {
		return (
			<div className="add-promotion" onClick={newPromotionOnClick}>
				+ Add A New Promotion
			</div>
		);
	};

	const currentPromotions = promotions.filter((promotion) => {
		const now = new Date();
		const startDate = new Date(promotion.startDate);
		return now >= startDate;
	});

	const upcomingPromotions = promotions.filter((promotion) => {
		const now = new Date();
		const startDate = new Date(promotion.startDate);
		return now < startDate;
	});

	return (
		<div className="promotions-list-container">
			{loading ? (
				<div className="promotion-loading-container">
					<Spinner
						className="promotion-loading"
						animation="grow"
						variant="info"
					/>
				</div>
			) : (
				<div className="promotions-body">
					{currentPromotions.length > 0 ? (
						<>
							<h3>Active promotions</h3>
							<div className="promotions-list">
								{currentPromotions.map((promotion, i) => (
									<PromotionCardWithRouter
										key={i}
										promotion={promotion}
										collectionType={collectionType}
										standaloneItemId={standaloneItemId}
									/>
								))}
							</div>
							{canEditPromotions && renderAddNewPromotion()}
						</>
					) : (
						<div className="promotions-list-empty w-60 p-2 d-flex justify-content-flex-start align-items-center">
							<img
								src={podcat}
								alt="Podcat"
								className="empty-podcat"
							/>
							<div className="pl-2 d-flex flex-column justify-content-space-between">
								<p>You don't currently have any promotions.</p>
								<p>
									<strong>Add a new promotion</strong> to
									begin monitoring promotional metrics for
									your content.
								</p>
								{renderAddNewPromotion()}
							</div>
						</div>
					)}
					{upcomingPromotions.length > 0 && (
						<>
							<h3>Upcoming promotions</h3>
							<div className="promotions-list">
								{upcomingPromotions.map((promotion, i) => (
									<PromotionCardWithRouter
										key={i}
										promotion={promotion}
										canEditPromotions={canEditPromotions}
										collectionType={collectionType}
										standaloneItemId={standaloneItemId}
										showMetrics={false}
									/>
								))}
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
});

export const PromotionListWithRouter = withRouter(
	connect(mapStateToProps)(PromotionList)
);
