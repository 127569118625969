export const ACTIONS = {
	SHOW_MODAL: 'CASTED/DELETE_IMPORTED_CONTENT_MODAL/SHOW_MODAL',
	HIDE_MODAL: 'CASTED/DELETE_IMPORTED_CONTENT_MODAL/HIDE_MODAL',
};

export const initialState = {
	showModal: false,
};

export const hideDeleteImportedContentModal = () => {
	return {
		type: ACTIONS.HIDE_MODAL,
	};
};

export const showDeleteImportedContentModal = () => {
	return {
		type: ACTIONS.SHOW_MODAL,
	};
};

const deleteImportedContentModalReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ACTIONS.HIDE_MODAL:
			return {
				...state,
				showModal: false,
			};
		case ACTIONS.SHOW_MODAL:
			return {
				...state,
				showModal: true,
			};
		default:
			return state;
	}
};

export default deleteImportedContentModalReducer;
