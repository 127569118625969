import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { addDays, format, startOfDay } from 'date-fns';
import { Spinner } from 'react-bootstrap';

import EngagementTrackerOptions from 'components/shared/engagementTracker/engagementTrackerOptions';
import EngagementTracker from 'components/shared/engagementTracker';
import MetricDateRangePicker from 'components/shared/metricDateRange';
import { getSelectedAccount } from 'store/selectors/accounts';
import ClipEngagements from './clipEngagements';
import EngagedCompanies from './engagedCompanies';

import { trackEvent } from 'services/vitally';

const ItemEngagementInsights = (props) => {
	const { match, selectedAccount } = props;
	const { params } = match;
	const { episodeId } = params;

	const { featureInsights, featureHeatmap } = useFlags();

	const today = startOfDay(new Date());

	const [selectedOption, setSelectedOption] = useState('listenTime');
	const [selectedClip, setSelectedClip] = useState({});
	const [dateRange, setDateRange] = useState({
		start: format(addDays(today, -8), 'yyyy-MM-dd'),
		end: format(addDays(today, -1), 'yyyy-MM-dd'),
	});

	const { data, loading } = useQuery(queries.getEpisodeBasic, {
		variables: { episodeId },
		fetchPolicy: 'cache-and-network',
	});
	const { basicEpisode: item = {} } = data || {};

	const { data: episodeByIdData } = useQuery(queries.episodeById, {
		variables: { podcastId: item.podcastId, episodeId },
	});
	const episodeById = episodeByIdData;

	const { data: heatmapData, loading: heatmapLoading } = useQuery(
		queries.getHeatmap,
		{
			variables: {
				accountId: selectedAccount.id,
				itemId: episodeId,
				startDate: dateRange.start,
				endDate: dateRange.end,
			},
			fetchPolicy: 'cache-and-network',
		}
	);
	const { getHeatmap: trackerData = [] } = heatmapData || {};

	const handleDateChange = (newRange) => {
		setDateRange({
			start: newRange.start,
			end: newRange.end,
		});
	};

	const handleOnChange = (selectedOption) => {
		setSelectedOption(selectedOption);
		if (episodeId && episodeById.episode.name && selectedOption) {
			trackEvent('view-engagement-type', {
				itemId: episodeId,
				name: episodeById.episode.name,
				engagementType: selectedOption,
			});
		}
	};

	return (
		<div className="engagements-body">
			<div className="engagements-header">
				<div className="header">
					<div className="title">Engagement Tracker</div>
					<div className="datepicker">
						<MetricDateRangePicker
							accountCreatedOn={selectedAccount.createdOn}
							dateRange={dateRange}
							onChange={handleDateChange}
						/>
					</div>
					<EngagementTrackerOptions
						setSelectedOption={setSelectedOption}
						selectedOption={selectedOption}
						onChange={handleOnChange}
					/>
				</div>
				<div className="description">
					Interact with the heatmap to see which companies are engaging with
					your content
				</div>
			</div>
			{loading || heatmapLoading ? (
				<div className="tracker-loading">
					<Spinner animation="border" variant="info" />
				</div>
			) : (
				<div className="engagement-tracker">
					<EngagementTracker
						selectedOption={selectedOption}
						trackerData={trackerData}
						duration={item.duration}
						loading={heatmapLoading}
					/>
					<ClipEngagements
						accountId={selectedAccount.id}
						itemId={episodeId}
						startDate={dateRange.start}
						endDate={dateRange.end}
						duration={item.duration}
						selectedOption={selectedOption}
						selectedClip={selectedClip}
						setSelectedClip={setSelectedClip}
					/>
					{featureHeatmap && featureInsights && (
						<EngagedCompanies
							accountId={selectedAccount.id}
							itemId={episodeId}
							startDate={dateRange.start}
							endDate={dateRange.end}
							duration={item.duration}
							selectedOption={selectedOption}
						/>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
});

export const ItemEngagementInsightsWithRouter = withRouter(
	connect(mapStateToProps)(ItemEngagementInsights)
);
