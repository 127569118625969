import React from 'react';

const ProgressBar = (props) => {
    const {
        uploadProgress = 50
    } = props;

    return (
        <div className="upload-progress">
            <div className="bar" style={{ width: `${uploadProgress}%` }} />
        </div>
    );
};

export default ProgressBar;