import React from 'react';
import { connect } from 'react-redux';

import podcat from 'assets/images/podcat-playing.svg';

import { FaGripLines, FaTimes } from 'react-icons/fa';

const ContentItem = (props) => {
	const { item, editPlaylist, onRemove } = props;

	return (
		<div className="playlist-item">
			<div className="item-left">
				{editPlaylist && <FaGripLines className="drag-icon" />}
				<div className="item-thumbnail">
					<img
						src={item.thumbnail || podcat}
						alt="Content Thumbnail"
						className={item.thumbnail ? 'thumbnail' : 'no-thumbnail'}
					/>
				</div>
				<h5
					className="name"
					dangerouslySetInnerHTML={{
						__html: item.name,
					}}
				></h5>
			</div>
			{editPlaylist && (
				<FaTimes className="remove-icon" onClick={() => onRemove(item.id)} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	editPlaylist: state.ui.playlists.editPlaylist,
});

export default connect(mapStateToProps)(ContentItem);
