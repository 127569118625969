import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { formatDuration } from 'utils';
import { Row, Col } from 'react-bootstrap';
import { FaPlay, FaPause, FaBackward, FaForward } from 'react-icons/fa';
import * as Sentry from '@sentry/browser';

const AudioPlayer = (props) => {
	const {
		url = '',
		startTime,
		endTime,
		id = '',
		className = '',
		isVideo,
	} = props;

	const audio = useRef(null);

	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const [duration, setDuration] = useState(0);
	const [labels, setLabels] = useState({});

	const onReady = () => {
		if (audio.current) {
			const dur = audio.current.getDuration();
			setDuration(dur);

			if (startTime) audio.current.seekTo(startTime);

			if (startTime && endTime) {
				setLabels({
					[(startTime / dur) * 100]: '',
					[(endTime / dur) * 100]: '',
				});
			}
		}
	};

	const onProgress = (p) => {
		setProgress(p.played * 100);
		if (endTime && p.played * duration >= endTime) {
			setIsPlaying(false);
		}
	};

	const onSeek = (time) => {
		if (audio.current) {
			audio.current.seekTo(time, 'seconds');
		}
	};

	const onTrackClick = (e) => {
		const { offsetX, target } = e.nativeEvent;
		const { offsetWidth } = target;
		const percentage = offsetX / offsetWidth;
		const skipTo = Math.round(duration * percentage);
		onSeek(skipTo);
	};

	const onQuickSkip = (value) => {
		if (audio.current) {
			audio.current.seekTo(audio.current.getCurrentTime() + value);
		}
	};

	return (
		<div className={`audio-player ${startTime ? 'clip-player' : ''}`}>
			<ReactPlayer
				ref={audio}
				id={`${id}`}
				className={`${className}`}
				url={url}
				playing={isPlaying}
				onReady={onReady}
				onProgress={onProgress}
				controls={false}
				height={isVideo ? '100%' : 0}
				width={isVideo ? '100%' : 0}
			/>

			<Row style={{ alignItems: 'center' }} noGutters>
				<Col xs="auto">
					<div className="audio-controls">
						<button className="control" onClick={() => onQuickSkip(-15)}>
							<FaBackward focusable="false" />
						</button>
						<button
							className="control"
							onClick={() => {
								setIsPlaying(!isPlaying);
							}}
						>
							{isPlaying ? (
								<FaPause className="pause" />
							) : (
								<FaPlay className="play" />
							)}
						</button>
						<button className="control" onClick={() => onQuickSkip(15)}>
							<FaForward focusable="false" />
						</button>
					</div>
				</Col>
				<Col xs="auto" className="w-75">
					<div className="d-flex flex-row justify-content-space-between align-items-center">
						<div>{formatDuration(duration * (progress / 100))}</div>
						<div className="media-progress">
								<div className="bar d-flex flex-row w-100 align-items-center" onClick={onTrackClick}>
									<div
										className="progress"
										style={{ width: `${progress}%` }}
									></div>
								</div>
						</div>
						<div>{formatDuration(duration)}</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default AudioPlayer;
