import React, { useRef } from 'react';
import styles from './styles.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { WithSearch } from '@elastic/react-search-ui';

import { Pagination } from '../base';

import { PAGINATION_POSITIONS } from '../constants';

const SearchResults = (props) => {
	const {
		history = null,
		onResultClick = (clipId, startTime, endTime) => {},
		displayResultsTime = true,
		paginationPosition = PAGINATION_POSITIONS.CENTER,
		renderRowLayout = null,
		selectedTheme = null,
	} = props;

	// Setup spelled out months
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	let loadingStartTime = new Date();
	let resultTime = 0;
	const resultViewTopElm = useRef(null);

	// Send to corresponding episode on result click
	const resultClick = (
		recordType,
		accountId,
		podcastId,
		episodeId,
		startTime,
		endTime,
		clipId
	) => {
		if (history && recordType && accountId && podcastId && episodeId) {
			switch (recordType) {
				case 'clip':
				case 'episode_transcript':
					history.push(
						`/account/${accountId}/shows/${podcastId}/episodes/${episodeId}/clips`
					);
					break;
				case 'episode':
				default:
					history.push(
						`/account/${accountId}/shows/${podcastId}/episodes/${episodeId}/info`
					);
					break;
			}
			onResultClick(clipId, startTime, endTime);
			return true;
		}
		return false;
	};

	// Scrolls user to top of results when paging
	const scrollToTop = () => resultViewTopElm.current.scrollIntoView();

	return (
		<WithSearch
			mapContextToProps={({
				current,
				results,
				resultsPerPage,
				setCurrent,
				totalResults,
				isLoading,
				searchTerm,
				setSearchTerm,
			}) => ({
				current,
				results,
				resultsPerPage,
				setCurrent,
				totalResults,
				isLoading,
				searchTerm,
				setSearchTerm,
			})}
		>
			{({
				current,
				results,
				resultsPerPage,
				setCurrent,
				totalResults,
				isLoading,
				searchTerm,
				setSearchTerm,
			}) => {
				if (isLoading) {
					loadingStartTime = new Date();
				} else {
					let loadingEndTime = new Date();
					resultTime =
						loadingEndTime.getTime() - loadingStartTime.getTime();
				}

				return (
					<div className={styles.resultView}>
						<div
							ref={resultViewTopElm}
							className={styles.resultViewTop}
						></div>
						{displayResultsTime && (
							<h2 className={styles.resultMetrics}>
								{totalResults} results found in {resultTime}ms
							</h2>
						)}
						<div className={styles.resultList}>
							{results
								.filter(
									(r) =>
										r.record_type.raw !== 'clip_transcript'
								)
								.map((r) => {
									// The following result values have either a `raw` or `snippet` property.
									// These properties are determined based on the specified values for the
									// repsctive fields in the `searchQuery` property for the search config
									let recordTypeName = '';
									const accountId = r.account_id
										? r.account_id.raw
										: null;
									const podcastId = r.podcast_id
										? r.podcast_id.raw
										: null;
									const episodeId = r.episode_id
										? r.episode_id.raw
										: null;
									const seasonNum = r.episode_season
										? r.episode_season.raw
										: null;
									const episodeNum = r.episode_episode_number
										? r.episode_episode_number.raw
										: null;
									const publishedDate =
										r.episode_published_at &&
										r.episode_published_at.raw
											? new Date(
													r.episode_published_at.raw
											  )
											: null;
									const startTime = r.start_time
										? r.start_time.raw
										: null;
									const endTime = r.end_time
										? r.end_time.raw
										: null;
									const episodeThumbnail = r.episode_thumbnail
										? r.episode_thumbnail.raw
										: '';
									const clipId = r.clip_id
										? r.clip_id.raw
										: null;
									let resultTitle = r?.episode_name?.snippet
										? r.episode_name.snippet
										: '';
									let resultDescription = '';

									switch (r.record_type.raw) {
										case 'episode_transcript':
											recordTypeName =
												'Found in transcript';
											if (r.episode_object) {
												const episodeObject = JSON.parse(
													r.episode_object.raw
												);

												if (episodeObject.name) {
													resultTitle =
														episodeObject.name;
												}
											}
											resultDescription = r.transcript?.snippet
												? r.transcript.snippet
												: '';

											break;
										case 'clip':
											recordTypeName = 'Podcast Clip';
											resultTitle = r.clip_name?.snippet
												? r.clip_name.snippet
												: '';
											resultTitle += r.episode_name?.snippet
												? ' - ' + r.episode_name.snippet
												: '';
											break;
										case 'episode':
										default:
											recordTypeName = 'Podcast Episode';
											resultDescription = r.episode_description
												? r.episode_description.snippet
												: '';
											break;
									}

									return (
										(renderRowLayout &&
											renderRowLayout(r)) || (
											<div
												className={styles.result}
												key={r.id.raw}
												onClick={() =>
													resultClick(
														r.record_type.raw,
														accountId,
														podcastId,
														episodeId,
														startTime,
														endTime,
														clipId
													)
												}
											>
												<div
													className={styles.resultNav}
												>
													<div>
														<h4
															className={
																styles.resultType
															}
														>
															{recordTypeName}
														</h4>
														<span>
															Click to go to
															episode page
														</span>
													</div>
													<FontAwesomeIcon
														icon={faArrowRight}
														size="sm"
													/>
												</div>
												<div
													className={
														styles.resultContent
													}
												>
													<div
														className={
															styles.resultImage
														}
													>
														<img
															src={
																episodeThumbnail
															}
														/>
													</div>
													<div>
														<h3
															className={
																styles.resultTitle
															}
															dangerouslySetInnerHTML={{
																__html: resultTitle,
															}}
														></h3>
														<div
															className={
																styles.resultInfo
															}
														>
															{seasonNum && (
																<span>
																	Season{' '}
																	{seasonNum}{' '}
																	|{' '}
																</span>
															)}
															{episodeNum && (
																<span>
																	Episode{' '}
																	{episodeNum}{' '}
																	|{' '}
																</span>
															)}
															{publishedDate ? (
																<span>
																	{
																		months[
																			publishedDate.getMonth()
																		]
																	}{' '}
																	{publishedDate.getDate()}
																	,{' '}
																	{publishedDate.getFullYear()}
																</span>
															) : (
																<span>
																	Draft
																</span>
															)}
														</div>
														{resultDescription && (
															<div
																className={
																	styles.resultText
																}
																dangerouslySetInnerHTML={{
																	__html: resultDescription,
																}}
															></div>
														)}
													</div>
												</div>
											</div>
										)
									);
								})}
						</div>
						{totalResults > 0 && (
							<Pagination
								current={current}
								onChange={(newPage) => {
									setCurrent(newPage);
									scrollToTop();
								}}
								resultsPerPage={resultsPerPage}
								totalResults={totalResults}
								paginationPosition={paginationPosition}
							/>
						)}
					</div>
				);
			}}
		</WithSearch>
	);
};

export default SearchResults;
