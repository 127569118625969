import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import MetricEmptyState from '../metricEmptyState';
import BaseTable from '../baseTable';
import { format } from 'date-fns';
import { numberSort } from 'utils';

const CompanyProfileContentAggregateTable = (props) => {
	const {
		accountId,
		companyId,
		dateRange,
		collectionId = '',
		episodeId = '',
		themeId = '',
	} = props;

	const [currentMetrics, setCurrentMetrics] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const variables = {
		accountId: accountId,
		companyId: companyId,
		startDate: dateRange.start,
		endDate: dateRange.end,
		...(collectionId && { collectionId: collectionId }),
		...(episodeId && { episodeId: episodeId }),
		...(themeId && { themeId: themeId }),
	};

	const { loading } = useQuery(queries.getCompanyProfileMetricsContentTable, {
		variables,
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			if (data.getCompanyProfileMetricsContentTable.length > 0) {
				setCurrentMetrics(data.getCompanyProfileMetricsContentTable);
			} else {
				setCurrentMetrics([]);
			}
		},
	});

	useEffect(() => {
		setResetPaginationToggle(!resetPaginationToggle);
	}, [currentMetrics]);

	const onItemClick = (itemRow) => {
		window.open(
			`/account/${accountId}/shows/${itemRow.podcast}/episodes/${itemRow.episodeId}/analytics`,
			'_blank'
		);
	}

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.episode_name,
			format: (row) => (
				<>
					<img
						alt={row.episode_name}
						src={row.thumbnail}
						className="table-logo"
					/>{' '}
					{row.episode_name}
				</>
			),
			sortable: true,
		},
		{
			name: 'Collection Name',
			selector: (row) => row.collection_name,
			sortable: true,
		},
		{
			name: 'Unique Contacts',
			selector: (row) => row.unique_contacts,
			sortable: true,
			sortFunction: numberSort((row) => row.unique_contacts),			
		},
		{
			name: 'Last Engagement',
			selector: (row) =>
				format(new Date(row.last_engagement.value), 'MM/dd/yyyy'),
			sortable: true,
		},
	];

	return (
		<BaseTable
			values={currentMetrics}
			columns={columns}
			loading={loading}
			pagination={currentMetrics ? currentMetrics.length > 5 : false}
			paginationPerPage={5}
			resetPaginationToggle={resetPaginationToggle}
			noDataComponent={<MetricEmptyState metricName={'Content'} />}
			clickable={true}
			className={'clickable'}
			onClick={onItemClick}
		/>
	);
};

export default CompanyProfileContentAggregateTable;
