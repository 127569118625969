import React from 'react';
import { ITEM_TYPES, METRICS_CONTEXTS } from 'components/constants';
import { connect } from 'react-redux';
import MetricsScreen from 'components/shared/metricsScreen';
import { getSelectedAccount } from 'store/selectors/accounts';
import queries from 'services/graphql/queries';

const EpisodeAnalytics = (props) => {
	const { podcast, episode } = props;

	const { id: episodeId } = episode || {};

	const { id: podcastId, externalId } = podcast || {};

	const isHosted = externalId ? true : false;

	const contextQueries = {
		integrations: queries.podcastIntegrations,
		totalAndUniqueAudience: queries.getEpisodeListenSummary,
		newAndLoyalAudience: queries.getEpisodeNewAndLoyalListeners,
		averageCompletion: queries.getEpisodeAverageCompletion,
		listensOverTimeHosted: queries.getDailyEpisodeDownloadAndPlayCount,
		listensOverTimeUnhosted: queries.getDailyEpisodeListens,
	};

	if (!episodeId) {
		return null;
	}

	return (
		<MetricsScreen
			metricsContext={METRICS_CONTEXTS.item}
			isHosted={isHosted}
			contextIdVariable={{
				episodeId: episodeId,
				podcastId: podcastId || episode.podcastId,
			}}
			contextQueries={contextQueries}
			className={'episode-metrics'}
			duration={episode.duration}
		/>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
});

export default connect(mapStateToProps)(EpisodeAnalytics);
