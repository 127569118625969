import url from 'url';
import groupBy from 'lodash.groupby';
import moment from 'moment-timezone';
import hexToRgba from 'hex-to-rgba';

const arrayOfObjectsToMap = (arr = [], keyField = null) =>
	arr.reduce((obj, item, idx) => {
		const o = obj;
		const key = keyField ? item[keyField] : idx;
		o[key] = item;
		return o;
	}, {});

const getOxfordComma = (arr = []) => {
	if (arr.length < 2) {
		return '';
	}

	if (arr.length === 2) {
		return ' and ';
	}

	return ', and ';
};
const commaSeparatedWithAnd = (arr = []) =>
	[arr.slice(0, -1).join(', '), arr.slice(-1)[0]].join(getOxfordComma(arr));

const getDurationFromDate = (fromDate = new Date(), toDate = new Date()) => {
	const diff = moment(toDate).diff(moment(fromDate), 'days');

	if (diff < 1) {
		return 'Less than a day ago';
	} else if (diff > 7) {
		return `${diff % 7} weeks ago`;
	} else {
		return `${diff} ${diff === 1 ? 'day' : 'days'} ago`;
	}
};

const getDomainFromUrl = (urlToParse) => {
	if (!urlToParse.includes('.com')) {
		return null;
	}

	let modified = urlToParse;
	if (modified.includes('www.')) {
		modified = modified.split('www.')[1];
	}

	let parsedUrl = null;
	if (modified.includes('https://')) {
		parsedUrl = url.parse(modified);
	} else {
		parsedUrl = url.parse(`https://${modified}`);
	}
	return parsedUrl ? parsedUrl.hostname : null;
};

const parseQuery = (queryString) => {
	const query = {};
	const pairs = (
		queryString[0] === '?' ? queryString.substr(1) : queryString
	).split('&');
	for (let i = 0; i < pairs.length; i += 1) {
		const pair = pairs[i].split(/=(.+)/);
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
	}
	return query;
};

const getPercentage = (value, total, precision = 2) => {
	if (total === 0) {
		return 0;
	}
	return ((value / total) * 100).toFixed(precision);
};

const dateOrdinal = (n) => {
	const rem = n < 10 || n > 20 ? n % 10 : 0;
	if (rem === 1) {
		return `${n}st`;
	}

	if (rem === 2) {
		return `${n}nd`;
	}

	if (rem === 3) {
		return `${n}rd`;
	}

	return `${n}th`;
};

const isMobile = () => window.innerWidth < 415;
const isTablet = () => window.innerWidth <= 1024 && window.innerWidth > 415;

const obfuscateEmail = (email) =>
	email.substring(0, email.indexOf('@') + 1) +
	'*****' +
	email.substring(email.lastIndexOf('.'));

const groupDatesByDay = (dates = [], key = 'startTime') => {
	if (!dates || dates.length < 1) {
		return {};
	}

	const grouped = groupBy(dates, (date) => {
		return moment.unix(date[key]).date();
	});

	return grouped;
};

const keyMirror = (obj = null) => {
	if (!obj) {
		return {};
	}

	const mirror = {};
	const keys = Object.keys(obj);
	keys.map((key) => {
		mirror[key] = key;
		return null;
	});

	return mirror;
};

const normalizeEventDates = (event) => {
	const tz = event.timezone;
	let start = moment(event.start);
	let end = moment(event.end);
	if (tz) {
		const normalizedTz = tz === 'EDT' ? 'EST' : tz;
		start = moment(event.start).tz(normalizedTz);
		end = moment(event.end).tz(normalizedTz);
	}
	return Object.assign(event, { start, end });
};

// eslint-disable-next-line
const EMAIL_REGEX =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateEmail = (email) => {
	return EMAIL_REGEX.test(String(email).toLowerCase());
};
const isEmailValid = (email) => {
	return email && email.length > 0 && validateEmail(email);
};

const isUrlValid = (url) => {
	try {
		new URL(url);
		return true;
	} catch (err) {
		return false;
	}
};

const isActiveLink =
	(routeToMatch = null) =>
	(match, location) => {
		if (!routeToMatch) {
			return false;
		}

		const { pathname } = location;
		return pathname.indexOf(routeToMatch) > -1;
	};

export const flatMap = (a, cb = (v) => v) => [].concat(...a.map(cb));

const formatDuration = (val, valFormat = 'seconds', humanReadable = false) => {
	const v = val > 0 ? val : 0;

	const seconds = moment.duration(v, valFormat).seconds();
	const minutes = moment.duration(v, valFormat).minutes();
	const hours = Math.trunc(moment.duration(v, valFormat).asHours());

	const normalizedSeconds = seconds < 10 ? `0${seconds}` : seconds;
	const normalizedMinutes = minutes < 10 ? `0${minutes}` : minutes;

	if (hours && hours > 0) {
		const normalizedHours = hours < 10 ? `0${hours}` : hours;
		const built = `${normalizedHours}:${normalizedMinutes}:${normalizedSeconds}`;
		return humanReadable ? `${built} hours` : built;
	} else {
		const built = `${normalizedMinutes}:${normalizedSeconds}`;
		return humanReadable ? `${built} min` : built;
	}
};

const capitalize = (string) => {
	if (typeof string !== 'string') return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const lowerCaseAndRemoveSpaces = (string) => {
	if (typeof string !== 'string') return '';
	return string.replace(/\s+/g, '-').toLowerCase();
};

/**
 * Looks upwards in a DOM tree for the first parent containing class "cls"
 * https://stackoverflow.com/questions/22119673/find-the-closest-ancestor-element-that-has-a-specific-class
 */
const findAncestor = (el, cls) => {
	while ((el = el.parentElement) && !el.classList.contains(cls));
	return el;
};

/**
 * Checks everything after last '/'. Handles trailing slash as well
 *
 * @param {string} url
 * @param {string} match
 *
 * checkLastSegment('http://localhost:3000/account/2/shows', 'shows') === true
 * checkLastSegment('http://localhost:3000/account/2/shows/', 'shows') === true
 * checkLastSegment('http://localhost:3000/account/2/shows/some-id', 'shows') === false
 */
const checkLastUrlSegment = (url = '', match = '') => {
	return url.match(/([^/]*)\/*$/)[1] === match;
};

/**
 * Returns a URL for a generated avatar from http://avatars.adorable.io/
 * that is consistent based on a hash of the provided identifier
 *
 * @param {integer} size
 * @param {string} identifier
 *
 * generateDefaultAvatar({ size: 40, identifier: 'person@klowd.com' })
 */
const generateDefaultAvatar = ({ size = 30, identifier }) => {
	// return `https://api.adorable.io/avatars/${size}/${identifier}`
	return `https://avatars.dicebear.com/v2/initials/${identifier}.svg`;
};

const formatEpisodeNumber = (episodeNumber) => {
	return episodeNumber < 10 ? `0${episodeNumber}` : episodeNumber;
};

const getShareModalSubtitle = (season, episodeName, episodeNumber) => {
	if (season) {
		return `Season ${season} | ${episodeName} - E${formatEpisodeNumber(
			episodeNumber
		)}`;
	}

	return `${episodeName} - E${formatEpisodeNumber(episodeNumber)}`;
};

const isColorLight = (hex) => {
	const hexParts = hex.slice(1).match(/[a-zA-Z0-9]{2}/g);
	if (!hexParts || !Array.isArray(hexParts)) {
		return false;
	}
	const rgb = hexParts.map((hex) => parseInt(hex, 16));
	const val = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
	return val > 125;
};

const formatValue = (value) => {
	if (isNaN(value)) {
		value = 0;
	}

	const duration = new Date(value * 1000).toISOString().substr(14, 5);
	return duration;
};

const isEmpty = (obj) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

const compareStrings = (a, b) => {
	var stringA = a.toUpperCase();
	var stringB = b.toUpperCase();

	if (stringA < stringB) {
		return -1;
	}

	if (stringA > stringB) {
		return 1;
	}

	return 0;
};

const formatLargeIntegers = (value) => {
	if (!value) {
		return '0';
	}
	// if larger than 1,000 shorten to [n]K
	if (Math.ceil(Math.log10(value + 1)) > 7) {
		return Math.round(value / 1000000, 0.1).toString() + 'm';
	}
	if (Math.ceil(Math.log10(value + 1)) > 4) {
		return Math.round(value / 1000, 0.1).toString() + 'k';
	}
	return `${value.toString()}`;
};

const getDecimalHundredth = (num) => {
	return Math.round(num * 100) / 100;
};

const convertToNumber = (value) => {
	if (typeof value === 'string') {
		if (value.endsWith('k')) {
			const num = parseFloat(value.replace('k', '')) * 1000;
			return isNaN(num) ? 0 : num;
		} else {
			return parseInt(value, 10);
		}
	} else {
		return value;
	}
};

const numberSort = (selector) => (rowA, rowB) => {
	const a = convertToNumber(selector(rowA));
	const b = convertToNumber(selector(rowB));

	if (a > b) {
		return 1;
	}

	if (b > a) {
		return -1;
	}

	return 0;
};

const getBucketPrefix = () => {
	if (process.env.REACT_APP_RELEASE === 'DEV') {
		return 'local-';
	} else if (process.env.REACT_APP_RELEASE === 'QA') {
		return 'qa-';
	} else {
		return '';
	}
};

export {
	arrayOfObjectsToMap,
	capitalize,
	checkLastUrlSegment,
	commaSeparatedWithAnd,
	compareStrings,
	convertToNumber,
	dateOrdinal,
	findAncestor,
	formatDuration,
	formatEpisodeNumber,
	formatLargeIntegers,
	formatValue,
	generateDefaultAvatar,
	getBucketPrefix,
	getDecimalHundredth,
	getDomainFromUrl,
	getDurationFromDate,
	getPercentage,
	getShareModalSubtitle,
	groupDatesByDay,
	hexToRgba,
	isActiveLink,
	isColorLight,
	isEmailValid,
	isEmpty,
	isMobile,
	isTablet,
	isUrlValid,
	keyMirror,
	lowerCaseAndRemoveSpaces,
	normalizeEventDates,
	numberSort,
	obfuscateEmail,
	parseQuery,
	validateEmail,
};
