import React from 'react';
import { Modal } from 'react-bootstrap';

import { SearchAIO } from '@casted-app/casted-react-search';
import '@casted-app/casted-react-search/dist/index.css';

import { history, dispatch } from 'store/store';
import { setLinkedClip, setLinkedTranscript } from 'store/reducers/data/studio';

const CastedSearchModal = (props) => {
	const { show, onHide = () => {}, accountId } = props;

	const onResultClick = (clipId, startTime, endTime) => {
		if (startTime && endTime) {
			// Presence of clipId indicates clip to link to
			if (clipId) {
				dispatch(
					setLinkedClip({
						id: clipId,
						startTime: parseFloat(startTime) / 1000,
						endTime: parseFloat(endTime) / 1000,
						isPlaying: false,
					})
				);
				dispatch(setLinkedTranscript(null));
			}
			// Else it's a transcript to link to
			else {
				dispatch(
					setLinkedTranscript({
						startTime: parseFloat(startTime) / 1000,
						endTime: parseFloat(endTime) / 1000,
					})
				);
				dispatch(setLinkedClip(null));
			}
		}

		onHide();
	};

	return (
		<Modal show={show} className="casted-search-modal" onHide={onHide}>
			<div className="fake-sidebar"></div>
			<div className="search-container">
				<SearchAIO
					searchKey={process.env.REACT_APP_ELASTIC_SEARCH_KEY}
					engineName={process.env.REACT_APP_ELASTIC_ENGINE_NAME}
					endpointBase={process.env.REACT_APP_ELASTIC_ENDPOINT_BASE}
					history={history}
					onResultClick={onResultClick}
					accountId={accountId}
					showClose={true}
					onClose={onHide}
				/>
			</div>
		</Modal>
	);
};

export default CastedSearchModal;
