import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { getSelectedAccount } from 'store/selectors/accounts';

import Button from 'components/shared/button';
import Toast from 'services/toast';

import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import queries from 'services/graphql/queries';

import {
	Card,
	Form,
	Container,
	Row,
	Col,
    Dropdown
} from 'react-bootstrap';


import YouTubeLogo from 'assets/logos/youtube-logo.svg';


const YoutubeIntegrationSettings = (props) => {
	const { match,
            selectedAccount,
            autoPublishEnabled,
            channelId:_channelId,
            playlistId:_playlistId,
            setChannelId: _setChannelId,
            setPlaylistId: _setPlaylistId,
            setAutoPublishEnabled: _setAutoPublishEnabled,
            integration = {} 
    } = props;
	const { params } = match;
	const { showId, accountId } = params;
	// END Props
    
	const [autoPublishYoutube, setAutoPublishYoutube] = useState(autoPublishEnabled);
    const [channelId, setChannelId] = useState(_channelId);
    const [playlistId, setPlaylistId] = useState(_playlistId);
    const [youtubeIntegration, setYoutubeIntegration] = useState({});
    const [channels, setChannels] = useState([]);
    const [playlists, setPlaylists] = useState([]);

    
    const [selectedChannel, setSelectedChannel] = useState("None");
    const [selectedPlaylist, setSelectedPlaylist] = useState("None");

	const {
		featureYoutubeIntegration,
	} = useFlags();

    const { data:gyicData, loading:gyicLoading } = useQuery(queries.getYoutubeIntegrationChannels, {
		variables: {
			// accountId: parseInt(accountId, 10),
			podcastId: showId,
		},
		fetchPolicy: 'no-cache',
	});

    const { data:gyipData, loading:gyipLoading, refetch:gyipRefetch } = useQuery(queries.getYoutubeIntegrationPlaylists, {
		variables: {
			// accountId: parseInt(accountId, 10),
			podcastId: showId,
            channelId: channelId,
		},
		fetchPolicy: 'no-cache',
	});

    useEffect(()=> {
        setYoutubeIntegration(integration)
    }, [integration])

    useEffect(()=> {
        if(!gyicLoading && gyicData != undefined) {
            setChannels(gyicData.getYoutubeIntegrationChannels)
            return;
        }

        setChannels([]);
    }, [gyicLoading, gyicData])

    useEffect(() => {
        gyipRefetch();
    }, [
        channelId
    ])

    useEffect(()=> {
        if(!gyipLoading && gyipData != undefined) {
            setPlaylists(gyipData.getYoutubeIntegrationPlaylists)
            return;
        }

        setPlaylists([]);
    }, [gyipLoading, gyipData])

    useEffect(() => {
        if(channelId && channels.length)  {
            const channel = channels.find(channel => channel.id == channelId);
            if(channel) {
                setSelectedChannel(channel.title);
                return;
            }
        }

        setSelectedChannel('None');
    }, [channelId, channels])

    useEffect(() => {
        if(playlistId && playlists.length)  {
            const channel = playlists.find(channel => channel.id == playlistId);
            if(channel) {
                setSelectedPlaylist(channel.title);
                return;
            }
        }

        setSelectedPlaylist('None');
    }, [playlistId, playlists])

    const youtubeIntegrationEnabled = youtubeIntegration && youtubeIntegration.status === 1;

    useEffect(() => { 
        _setAutoPublishEnabled(autoPublishYoutube)
        _setChannelId(channelId)
        _setPlaylistId(playlistId)
    }, [
        autoPublishYoutube,
        channelId,
        playlistId
    ])



	const [deleteYoutubeIntegration] = useMutation(
		mutations.deleteYoutubeIntegration
	);

    const [
		updatePodcast
	] = useMutation(mutations.updatePodcastById);

	const [getYoutubeAuthUrl] = useMutation(mutations.getYoutubeAuthUrl, {
		variables: { podcastId: showId },
		onCompleted: (data) => {
			window.location = data.getYoutubeAuthUrl;
		},
	});

    const toggleYoutubeIntegration = async () => {
		try {
			if (youtubeIntegration && youtubeIntegration.status === 1) {
				// integration already exists, so we are deleting it
				await deleteYoutubeIntegration({
					variables: { integrationId: youtubeIntegration.id },
				});
				setYoutubeIntegration({});
				if (autoPublishYoutube) {
					await updatePodcast({
						variables: {
							podcastId: showId,
							podcast: {
								accountId: parseInt(accountId, 10),
								autoPublishYoutube: false,
                                youtubeChannelId: null,
                                youtubePlaylistId: null
							},
						},
					});
					setAutoPublishYoutube(false);
				}
				Toast.success('YouTube Integration Removed');
			} else {
				getYoutubeAuthUrl();
			}
		} catch (err) {
			Toast.error('Unable to update integration with YouTube');
			console.log(err);
			Sentry.captureException(err);
		}
	};

    if(!featureYoutubeIntegration || !selectedAccount.enableVideo )
        return <></>
    
    return <section className="show-settings--integration show-settings--section">
        <Container>
            <Row>
                <Col xs={5}>
                    <img src={YouTubeLogo} alt="YouTube" className='mb-3'/>
                    <p>
                        When uploading to
                        Casted, you may also
                        upload content
                        directly to YouTube
                        via our YouTube
                        Integration feature.{' '}
                        <a
                            target="_blank"
                            className="ext-link"
                            id="tooltip-youtube-help"
                            href="https://get.help.casted.us/servicedesk/customer/portal/3/article/2092957703"
                        >
                            Click here for
                            instructions
                        </a>
                        .
                    </p>
                </Col>
                <Col xs={7}>
                    <Card>
                        <Card.Body>
                            <Form>

                                <Form.Label className="label">
                                    Manage
                                    Integration
                                </Form.Label>
                                <Row className="ml-0 mb-3">
                                    {youtubeIntegrationEnabled? (
                                        <Button
                                            variant="danger"
                                            onClick={
                                                toggleYoutubeIntegration
                                            }
                                        >
                                            Disable
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="success"
                                            onClick={
                                                toggleYoutubeIntegration
                                            }
                                        >
                                            Enable
                                        </Button>
                                    )}
                                </Row>

                                {youtubeIntegrationEnabled && (
                                    <>


                                        <Form.Group>
                                            <Form.Label>Channel</Form.Label>
                                            <Dropdown
                                                onSelect={(id) => setChannelId(id)}
                                                className="channel-id-select"
                                            >
                                                <Dropdown.Toggle
                                                    variant="outline-dark"
                                                >
                                                   {selectedChannel}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey={""}>
                                                        <p className="channel--name">
                                                            None
                                                        </p>
                                                    </Dropdown.Item>
                                                    {channels.map((channel) => (
                                                        <Dropdown.Item
                                                            eventKey={channel.id}
                                                            key={channel.id}
                                                        >
                                                            <p className="channel--name">
                                                                {channel.title}
                                                            </p>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Playlist</Form.Label>
                                            <Dropdown
                                                onSelect={(id) => setPlaylistId(id)}
                                                className="channel-id-select"
                                            >
                                                <Dropdown.Toggle
                                                    variant="outline-dark"
                                                >
                                                    {selectedPlaylist}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey={""}>
                                                        <p className="channel--name">
                                                            None
                                                        </p>
                                                    </Dropdown.Item>
                                                    {playlists.map((playlist) => (
                                                        <Dropdown.Item
                                                            eventKey={playlist.id}
                                                            key={playlist.id}
                                                        >
                                                            <p className="playlist--name">
                                                                {playlist.title}
                                                            </p>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>

                                        <Form.Label className="label">
                                            Auto-publish
                                            future
                                            content
                                        </Form.Label>
                                        <Form.Group>
                                            <div className="youtube-auto-publish">
                                                <Form.Text className="helper-text auto-publish-description mt-0 mb-1">
                                                    When
                                                    publishing
                                                    new
                                                    content
                                                    to
                                                    this
                                                    collection,
                                                    Casted
                                                    will
                                                    automatically
                                                    upload
                                                    and
                                                    publish
                                                    those
                                                    items
                                                    directly
                                                    to
                                                    your
                                                    connected
                                                    YouTube
                                                    account.
                                                </Form.Text>
                                                <Form.Check
                                                    type="switch"
                                                    id="autoPublishYoutube"
                                                    checked={
                                                        autoPublishYoutube
                                                    }
                                                    className="casted-form"
                                                    disabled={
                                                        !youtubeIntegration
                                                    }
                                                    onChange={() =>
                                                        setAutoPublishYoutube(
                                                            !autoPublishYoutube
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Form.Group>

                                    </>
                                )}

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </section>
}

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
});

export default withRouter(connect(mapStateToProps)(YoutubeIntegrationSettings));