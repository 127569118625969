import React, { useState, useRef } from 'react';

import { Overlay, Tooltip } from 'react-bootstrap';

const EngagementTrackerSegment = (props) => {
	const {
		level,
		width,
		values,
		index,
		engagementType,
		engagementValue,
		isClip = false,
		selectedClip = {},
	} = props;

	const target = useRef(null);

	const [show, setShow] = useState(false);

	const getMarker = (value) => {
		const length = value / 60;
		let minutes = Math.floor(length);

		let seconds = value % 60;

		if (seconds === 0) {
			seconds = '00';
		}

		if (minutes === 0) {
			minutes = '00';
		}

		if (seconds.toString().length === 1) {
			seconds = `${seconds}0`;
		}

		return (
			<span>
				{minutes}:{seconds}
			</span>
		);
	};

	return (
		<>
			<div
				key={index}
				onMouseEnter={() => setShow(true)}
				onMouseOut={() => setShow(false)}
				ref={target}
				className={`tracker-segment ${level}  ${show ? 'hover' : ''}`}
				style={{ width: `${width}%` }}
			></div>
			<Overlay
				target={target.current}
				show={
					isClip &&
					(values[1] < selectedClip.startTime ||
						values[0] > selectedClip.endTime)
						? false
						: show
				}
				placement="top"
			>
				<Tooltip className="graph-tooltip">
					<div className={`engagement-level ${level}`}>
						{level[0].toUpperCase() + level.substring(1)} Engagement
					</div>
					<div className="tooltip-time">
						Between {getMarker(Math.floor(values[0]))} and{' '}
						{getMarker(Math.floor(values[values.length - 1]))}
					</div>
					<p className="tooltip-numbers">
						{engagementType}: {engagementValue}
					</p>
				</Tooltip>
			</Overlay>
		</>
	);
};

export default EngagementTrackerSegment;
