// Dialog Constants
const DIALOGS = {
    EPISODE_UNSAVED_CHANGES: 'EPISODE_UNSAVED_CHANGES',
    THEME_UNSAVED_CHANGES: 'THEME_UNSAVED_CHANGES',
    PLAYLIST_UNSAVED_CHANGES: 'PLAYLIST_UNSAVED_CHANGES'
};

export {
    DIALOGS
};

export { default as EpisodeUnsavedChanges } from './episodeUnsavedChanges';
export { default as ThemeUnsavedChanges } from './themeUnsavedChanges';
export { default as PlaylistUnsavedChanges } from './playlistUnsavedChanges';