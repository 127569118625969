import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import { Card } from 'react-bootstrap';

import { history } from 'store/store';

import EngagementTrackerOptions from 'components/shared/engagementTracker/engagementTrackerOptions';
import EngagementTracker from 'components/shared/engagementTracker';
import { trackEvent } from 'services/vitally';

const ItemEngagementTracker = (props) => {
	const { variables } = props;
	const { accountId, collectionId, itemId } = variables;

	const [selectedOption, setSelectedOption] = useState('listenTime');

	const { data, loading } = useQuery(queries.getHeatmap, {
		variables,
		fetchPolicy: 'cache-and-network',
	});
	const { getHeatmap: trackerData = [] } = data || {};

	const { data: episodeByIdData, loading: episodeByIdLoading } = useQuery(
		queries.episodeById,
		{
			variables: { podcastId: variables.collectionId, episodeId: itemId },
		}
	);
	const episodeById = episodeByIdData;

	const handleViewMoreClick = () => {
		if (itemId && episodeById.episode.name) {
			trackEvent('view-engagement-tracker', {
				itemId: itemId,
				name: episodeById.episode.name,
			});
		}
	};

	const rootPath = `/account/${accountId}/shows/${collectionId}/episodes/${itemId}`;

	return (
		<Card className="tracker-card">
			<Card.Body>
				<Card.Title>
					<h4 className="tracker-title">ENGAGEMENT TRACKER</h4>
				</Card.Title>

				<>
					<EngagementTrackerOptions
						setSelectedOption={setSelectedOption}
						selectedOption={selectedOption}
					/>
					<EngagementTracker
						selectedOption={selectedOption}
						trackerData={trackerData}
						duration={variables.duration}
						loading={loading}
					/>
					<Link
						className="view-insights"
						to={{
							pathname: `${rootPath}/analytics/engagementInsights`,
							state: { from: history.location.pathname },
						}}
						onClick={handleViewMoreClick}
					>
						View More
					</Link>
				</>
			</Card.Body>
		</Card>
	);
};

export default ItemEngagementTracker;
