import React, { useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import Toast from 'services/toast';
import Button from 'components/shared/button';
import TextInput from 'components/shared/textInput';
import PasswordStrength from 'components/shared/passwordStrength';
import { getAuthSingleton } from 'services/auth';
import { parseQuery } from 'utils/';

import passwordRating from 'zxcvbn';

const isPasswordValid = (pw) => {
	const pwRating = passwordRating(pw);
	return pw.length >= 10 && pwRating.score >= 3 && !pwRating.feedback.warning;
};

const PasswordReset = (props) => {
	// HOOKS
	const [newPassword, setNewPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	// END HOOKS

	const authSvc = getAuthSingleton();
	const params = parseQuery(props.location.search);
	const { code, identifier } = params;

	let messages = [''];

	// if there is no code or identifier in the URL then just take them to root
	if (!code || !identifier) {
		return <Redirect to="/" />;
	}

	const canSet =
		isPasswordValid(newPassword) && newPassword === passwordConfirmation;

	if (newPassword !== passwordConfirmation && passwordConfirmation.length) {
		messages.push('Passwords must match.');
	}

	return (
		<div className="password-reset">
			<div className="password-reset-box">
				<h2 className="password-reset-header">Reset Password</h2>
				<p className="pw-minimum-length-msg">
					Passwords must match and be at least 10 characters
				</p>
				<label className="password-reset-label">New Password</label>
				<TextInput
					name="new-password"
					label="********"
					value={newPassword}
					type="password"
					onChange={(e) => setNewPassword(e.target.value)}
					inputClassName="password-reset-form-input"
					rootStyles={{ margin: '0 0 10px 0' }}
				/>
				<PasswordStrength password={newPassword} messages={messages} />
				<label className="password-reset-label">Confirm Password</label>
				<TextInput
					name="new-password-confirmation"
					label="********"
					value={passwordConfirmation}
					type="password"
					onChange={(e) => setPasswordConfirmation(e.target.value)}
					inputClassName="password-reset-form-input"
					rootStyles={{ margin: 0 }}
				/>
				<Button
					name="password-reset-button"
					onClick={async () => {
						try {
							await authSvc.resetPassword(identifier, newPassword, code);
							props.history.push('/login');
						} catch (e) {
							console.error(e);
							Toast.error(
								'Oops, something went wrong! Unable to reset password.'
							);
							Sentry.captureException(e);
						}
					}}
					className="password-reset-btn"
					variant="success"
					title="Reset Password"
					disabled={!canSet}
				>
					Reset Password
				</Button>
				<Button
					name="password-reset-goto-login-btn"
					onClick={() => props.history.push('/login')}
					className="password-reset-goto-login-btn"
					variant="flat"
					title="Back to login"
				>
					Back to Login
				</Button>
			</div>
		</div>
	);
};

export default withRouter(PasswordReset);
