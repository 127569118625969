import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';

import GuestInfo from './guestInfo';

const EpisodeGuests = (props) => {
	// Props
	const { guests: _guests = {}, onChange = () => {}, canEdit } = props;

	// State Hooks
	const [editGuests, setEditGuests] = useState(_guests ? _guests : {});

	const [guestsUpdated, setGuestsUpdated] = useState(false);

	// Effect Hooks
	useEffect(() => {
		if (guestsUpdated) {
			onChange('guests', editGuests);
		}
	}, [editGuests]);

	const updateGuest = (guest, i) => {
		if (!guestsUpdated) {
			setGuestsUpdated(true);
		}
		setEditGuests(Object.assign({}, editGuests, { [i]: guest }));
	};

	const addGuest = (guest) => {
		if (!guestsUpdated) {
			setGuestsUpdated(true);
		}
		let uid = uuid();
		setEditGuests(Object.assign({}, editGuests, { [uid]: guest }));
	};

	const removeGuest = (i) => {
		if (!guestsUpdated) {
			setGuestsUpdated(true);
		}
		let updatedGuest = delete editGuests[i];
		setEditGuests(Object.assign({}, editGuests, updatedGuest));
	};

	return (
		<>
			<div className="episode-guests w-100">
				{Object.keys(editGuests).map((i) => (
					<GuestInfo
						guest={editGuests[i]}
						key={i}
						onDelete={() => removeGuest(i)}
						onEdit={(guestData) => updateGuest(guestData, i)}
						disabled={!canEdit}
					/>
				))}
				{canEdit && (
					<Row className="add-guests">
						<Col>
							<Card className="p-4">
								<div className="add-item guest w-100">
									<div
										className="add-item--inner d-flex justify-content-center m-2"
										onClick={() => addGuest({})}>
										<div className="py-4">
											<FaPlus className="add-item--icon mb-1" />
											<p>ADD GUEST</p>
										</div>
									</div>
								</div>
							</Card>
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};

export default EpisodeGuests;
