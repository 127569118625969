import React from 'react';

const MockBrowser = (props) => {
	const { children, favIconUri, pageTitle = '' } = props;

	return (
		<div className="mock-browser">
			<div className="mock-browser--top-bar">
				<span className="mock-browser--button" />
				<span className="mock-browser--button" />
				<span className="mock-browser--button" />
				{favIconUri && (
					<img src={favIconUri} className="mock-browser--favicon" />
				)}
				<span className="mock-browser--label">{`Example ${pageTitle ? `| ${pageTitle}` : ''}`}</span>
			</div>
			<div className="mock-browser--body">{children}</div>
		</div>
	);
};

export default MockBrowser;
