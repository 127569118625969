import React from 'react';
import { toast } from 'react-toastify';
import { FaCheckCircle, FaInfoCircle, FaExclamationTriangle, FaTimes } from 'react-icons/fa';

const DEFAULT_TOAST_OPTS = {
	autoClose: 2000,
	position: toast.POSITION.BOTTOM_CENTER,
	pauseOnFocusLoss: false,
	pauseOnHover: true,
	draggable: false,
	closeButton: <FaTimes />
};

const toastBuilder = (variant, title, message) => {
	let messageIcon = (<FaInfoCircle className="fa-icon" />);

	switch(variant) {
		case 'success':
			messageIcon = (<FaCheckCircle className="fa-icon" />);
			break;
		case 'error': 
			messageIcon = (<FaExclamationTriangle className="fa-icon" />);
			break;
		case 'warn':
		case 'info':
			default:
			break;
	}

	return (
		<div>
			<p>{messageIcon} {title}</p>
			{message && (<p>{message}</p>)}
		</div>
	);
};

export default {
	success: (title = 'Success!', content = '', opts = {}) => {
		const toastOpts = {
			...DEFAULT_TOAST_OPTS,
			className: 'toast-success',
			closeButton: opts.closeOnClick === false ? opts.closeOnClick : DEFAULT_TOAST_OPTS.closeButton,
			...opts
		};

		return toast.success(toastBuilder('success', title, content), toastOpts);
	},

	error: (title = 'Error!', content = '', opts = {}) => {
		const toastOpts = {
			...DEFAULT_TOAST_OPTS,
			className: 'toast-error',
			closeButton: opts.closeOnClick === false ? opts.closeOnClick : DEFAULT_TOAST_OPTS.closeButton,
			...opts
		};

		return toast.error(toastBuilder('error', title, content), toastOpts);
	},

	warn: (title = 'Warning!', content = '', opts = {}) => {
		const toastOpts = {
			...DEFAULT_TOAST_OPTS,
			className: 'toast-warning',
			closeButton: opts.closeOnClick === false ? opts.closeOnClick : DEFAULT_TOAST_OPTS.closeButton,
			...opts
		};

		return toast.warn(toastBuilder('warn', title, content), toastOpts);
	},

	info: (title = 'Info!', content = '', opts = {}) => {
		const toastOpts = {
			...DEFAULT_TOAST_OPTS,
			className: 'toast-info',
			closeButton: opts.closeOnClick === false ? opts.closeOnClick : DEFAULT_TOAST_OPTS.closeButton,
			...opts
		};

		return toast.info(toastBuilder('info', title, content), toastOpts);
	}
}
