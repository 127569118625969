import React from 'react';
import WaveSurfer from 'wavesurfer.js';
import WSCursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';
import WSRegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import axios from 'axios';
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import { setIsPlaying } from 'store/reducers/ui/episodes/studio';

import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaPlay, FaPause, FaInfoCircle } from 'react-icons/fa';
import { formatDuration } from 'utils';

import podcat from '../../../../../../assets/images/podcat-playing.svg';

const WAVESURFER_EVENTS = [
	'audioprocess',
	'error',
	'finish',
	'loading',
	'mouseup',
	'pause',
	'play',
	'ready',
	'scroll',
	'seek',
	'zoom',
];

const WAVESURFER_REGIONS_EVENTS = {
	onRegionCreated: 'region-created',
	onRegionUpdated: 'region-updated',
	onRegionUpdateEnd: 'region-update-end',
	onRegionRemoved: 'region-removed',
	onRegionMouseEnter: 'region-mouseenter',
	onRegionClick: 'region-click',
	onRegionIn: 'region-in',
	onRegionOut: 'region-out',
};

const capitaliseFirstLetter = (string) =>
	string
		.split('-')
		.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
		.join('');

/**
 * Waveform
 *
 * needs to be a class based component instead of a function so we can use refs
 */
class Waveform extends React.Component {
	state = {
		waveSurfer: null,
		isReady: false,
		hasRegion: false,
		localIsPlaying: false,
		mediaFile: '',
	};

	componentDidMount = async () => {
		const {
			id,
			height,
			options: optsFromProps,
			cursor,
			cursorColor = '#64B889',
			cursorShowTime = false,
			cursorShowTimeStyle = {},
			cursorWidth = '1px',
			useRegions,
			regions = [],
			regionsOpts,
			currentSelection,
			onSelectionEnd,
			audioFile,
			videoFile,
			waveformFile = null,
			playing = false,
			onReady = () => {},
			allComponentsAreReady = false,
			studio,
		} = this.props;

		const plugins = [];
		const customRegionsOpts = regionsOpts || {};
		let peaks = null;

		if (cursor) {
			plugins.push(
				WSCursorPlugin.create({
					width: cursorWidth,
					showTime: cursorShowTime,
					opacity: 1,
					color: cursorColor,
					customStyle: {},
					customShowTimeStyle: {
						...cursorShowTimeStyle,
					},
					formatTimeCallback: (cursorTime) => {
						return [cursorTime].map((time) =>
							[
								Math.floor((time % 3600) / 60), // minutes
								('00' + Math.floor(time % 60)).slice(-2), // seconds
							].join(':')
						);
					},
					deferInit: true,
				})
			);
		}

		if (useRegions) {
			regions.forEach((region, i) => {
				region.attributes = {
					label: `CLIP ${i + 1}`,
				};
			});

			plugins.push(
				WSRegionsPlugin.create({
					dragSelection: true,
					regions,
					...customRegionsOpts,
				})
			);
		}

		const isVideo = videoFile && videoFile !== 'processing';

		let defaultOptions = {
			container: `#${id}`,
			barWidth: 3,
			barHeight: 1.5,
			height: height || 128,
			responsive: false,
			autoCenter: false,
			plugins,
			waveColor: '#838e93',
			progressColor: '#64B889',
			cursorColor: null,
			cursorWidth: '0',
			mediaType: isVideo ? 'video' : 'audio',
		};

		if (waveformFile || isVideo) {
			defaultOptions.backend = 'MediaElement';
		}

		const waveSurfer = WaveSurfer.create({
			...defaultOptions,
			...optsFromProps,
		});
		this.props.waveSurfer.current = waveSurfer;

		let videoElement = document.querySelector('video');

		const mediaFile = isVideo ? videoFile : audioFile;
		this.setState({ mediaFile });

		if (mediaFile) {
			if (waveformFile) {
				const response = await axios.get(waveformFile);
				const peaks = response?.data;
				waveSurfer.load(isVideo ? videoElement : mediaFile, peaks.data);
			} else {
				waveSurfer.load(isVideo ? videoElement : mediaFile);
			}
		}

		waveSurfer.on('ready', () => {
			if (playing) {
				waveSurfer.play();
			}

			if (cursor) {
				waveSurfer.initPlugin('cursor');
			}

			// Add remove button to Region
			if (useRegions) {
				waveSurfer.on('region-created', (region) => {
					this.state.hasRegion = true;

					// Ensure custom options are included
					region.update({ ...customRegionsOpts });

					const removeBtn = region.element.appendChild(
						document.createElement('span')
					);
					removeBtn.className = 'wavesurfer-remove';
					removeBtn.addEventListener('click', () => {
						region.remove();
						if (!waveSurfer.regions.length) {
							this.state.hasRegion = false;
						}
					});
				});
			}

			// waveSurfer.on('region-created', (region) => { this.props.onRegionCreated(region) })
			// registerEventsFromProps(waveSurfer, this.props)
			WAVESURFER_EVENTS.forEach((event) => {
				if (this.props[`on${capitaliseFirstLetter(event)}`]) {
					waveSurfer.on(event, (e) => {
						this.props[`on${capitaliseFirstLetter(event)}`](e);
					});
				}
			});

			// check for WSRegions plugins event handlers
			if (this.props.useRegions) {
				Object.keys(WAVESURFER_REGIONS_EVENTS).forEach((handler) => {
					if (this.props[handler]) {
						waveSurfer.on(WAVESURFER_REGIONS_EVENTS[handler], (region) => {
							this.props[handler](region);
						});
					}
				});
			}

			this.setState({ waveSurfer, isReady: true });
			onReady(waveSurfer);
		});

		this.setState({ waveSurfer, isReady: this.state.isReady });
	};

	componentWillUnmount = () => {
		// const newWaveSurfer = Object.assign(this.state.waveSurfer)
		// newWaveSurfer.destroy()
		if (this.state.waveSurfer) this.state.waveSurfer.destroy();
		this.setState({ waveSurfer: null, isReady: false });
	};

	getDuration = () => {
		if (this.state.waveSurfer) {
			return this.state.waveSurfer.getDuration();
		}
		return 0;
	};

	playAtTime = (playTime) => {
		if (this.state.waveSurfer) {
			this.state.waveSurfer.play(
				playTime || this.state.waveSurfer.getCurrentTime()
			);
		}
	};

	playPause = () => {
		if (this.state.waveSurfer) {
			this.state.waveSurfer.playPause();
			dispatch(setIsPlaying(!this.props.studio.isPlaying));
		}
	};

	addRegion = (regionOpts) => {
		if (this.state.waveSurfer) {
			this.state.waveSurfer.addRegion(regionOpts);
		}
	};

	clearRegions = () => {
		if (this.state.waveSurfer) {
			this.state.waveSurfer.clearRegions();
			this.state.hasRegion = false;
		}
	};

	disableDragSelection = () => {
		if (this.state.waveSurfer) {
			this.state.waveSurfer.disableDragSelection();
		}
	};

	setZoom = (zoomLevel) => {
		if (this.state.waveSurfer) {
			this.state.waveSurfer.zoom(zoomLevel);
		}
	};

	renderProcessingTooltip = (props) => {
		return (
			<Tooltip id="video-processing" {...props}>
				Podcat is processing your video file. While he works, your podcast audio
				is ready to be used in the studio.
			</Tooltip>
		);
	};

	render() {
		const { id, className, videoFile, studio } = this.props;
		const { isReady, waveSurfer, hasRegion, mediaFile } = this.state;

		return (
			<>
				{videoFile ? (
					videoFile === 'processing' ? (
						<OverlayTrigger
							placement="top"
							overlay={this.renderProcessingTooltip}
						>
							<div className="casted-video-processing">
								<img src={podcat} />
								<FaInfoCircle className="info-circle" />
							</div>
						</OverlayTrigger>
					) : (
						<video
							src={mediaFile}
							style={{
								height: '100%',
								width: '160px',
								margin: 0,
								boxShadow: 'none',
							}}
						/>
					)
				) : (
					''
				)}
				<div className="casted-waveform-wrapper pl-3">
					<div
						className="casted-waveform--play"
						onClick={() => {
							this.playPause();
						}}
					>
						{studio.isPlaying ? (
							<FaPause className="pause" />
						) : (
							<FaPlay className="play" />
						)}
					</div>

					<div className="casted-waveform--waveform">
						<div
							id={id}
							className={`casted-waveform ${className} ${
								hasRegion ? 'has-region' : ''
							}`}
						>
							{!isReady && (
								<Spinner
									className="waveform-loading"
									animation="border"
									variant="light"
								/>
							)}
						</div>
					</div>

					<div className="casted-waveform--total-length">
						{formatDuration(this.getDuration())} MIN
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return state.ui.episodes;
}

export default connect(mapStateToProps)(Waveform);
