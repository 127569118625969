import ReactQuill from 'react-quill';

const QuillEditor = (props) => {
	const {
		disabled,
		onChange,
		style,
		type,
		placeholder,
		defaultValue
	} = props;

	const toolbarDefaults = [
		[{ header: [1, 2, 3, 4, 5, 6, false] }],

		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		['blockquote', 'code-block', 'link'],

		[{ list: 'ordered' }, { list: 'bullet' }],
	];

	const formats = [
		'link',
		'bold',
		'italic',
		'header',
		'underline',
		'strike',
		'list',
		'code-block',
		'blockquote',
	];

	if (type === 'meta') {
		return (
			<ReactQuill
				className="meta-quill"
				disabled={disabled}
				defaultValue={defaultValue}
				onChange={onChange}
				style={{ height: '100%' }}
				modules={{
					toolbar: false,
					clipboard: {
						allowed: {
							tags: [],
						},
					},
				}}
				placeholder={placeholder}
			/>
		);
	} else {
		return (
			<ReactQuill
				disabled={disabled}
				defaultValue={defaultValue}
				value={defaultValue}
				preserveWhitespace={true}
				onChange={(value, delta, source, editor) => {
					if(source == 'user') {
						onChange(value, delta, source, editor)
					}
				}}
				style={style}
				modules={{
					toolbar: toolbarDefaults,
				}}
				formats={formats}
				placeholder={placeholder}
			/>
		);
	}
};

export default QuillEditor;
