import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import moment from 'moment';

// External Components
import { FaSearch } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

// Internal Components
import PlaylistActions from './playlistActions';
import { Button, BaseTable, ShareContentModal } from 'components/shared/';

// Internal Libraries
import Toast from 'services/toast';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import { dispatch } from 'store/store';
import { getSelectedAccount } from 'store/selectors/accounts';
import {
	hidePlaylistsShareModal,
	showPlaylistsShareModal,
} from 'store/reducers/ui/modals/playlistShareModal';
import { setEditPlaylist } from 'store/reducers/ui/playlists';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';
import { SHARE_MODAL_TYPE } from 'components/constants';

const Playlists = (props) => {
	const { match, permissions, selectedAccount, modals } = props;
	const { params } = match;
	const { accountId } = params;

	const playlistsPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.PLAYLISTS)
				.rolePermission
		: {};

	const [filteredPlaylists, setFilteredPlaylists] = useState([]);
	const [searchString, setSearchString] = useState('');

	/** Queries & Mutations **/
	const { data, loading, error } = useQuery(queries.getPlaylistsByAccountId, {
		variables: { accountId: parseInt(accountId, 10) },
		fetchPolicy: 'no-cache',
	});
	const { playlists = [] } = data || {};

	const [createPlaylist] = useMutation(mutations.createPlaylist);
	const [deletePlaylist] = useMutation(mutations.deletePlaylistById, {
		refetchQueries: ['playlists'],
	});

	const newPlaylistOnClick = async () => {
		try {
			const res = await createPlaylist({
				variables: {
					accountId: parseInt(accountId),
				},
			});
			const { data } = res;
			const { createPlaylist: createdPlaylist } = data;

			if (createdPlaylist && createdPlaylist.id) {
				dispatch(setEditPlaylist(true));
				trackEvent('add-playlist');
				props.history.push(
					`/account/${accountId}/allContent/playlists/${createdPlaylist.id}`
				);
			}
		} catch (e) {
			console.error(e);
			Toast.error('Unable to create playlist');
		}
	};

	const onRemove = async (row) => {
		try {
			await deletePlaylist({
				variables: {
					accountId: parseInt(accountId),
					playlistId: row.id,
				},
			});
			Toast.success('Playlist sucessfully removed');
			trackEvent('delete-playlist', { name: row.name });
		} catch (e) {
			console.error(e);
			Toast.error('Unable to delete playlist');
		}
	};

	const onShare = async (row) => {
		if (row.content && row.content.length > 0) {
			dispatch(showPlaylistsShareModal(row));
		}
	};

	useEffect(() => {
		if (searchString) {
			let value = searchString.toLowerCase();
			let result = [];

			result = playlists.filter((playlist) => {
				const playlistName = playlist.name.toLowerCase();
				let createdBy =
					playlist.user.firstName + ' ' + playlist.user.lastName;
				createdBy = createdBy.toLowerCase();
				return (
					playlistName.search(value) != -1 ||
					createdBy.search(value) != -1
				);
			});
			setFilteredPlaylists(result);
		} else {
			setFilteredPlaylists(playlists);
		}
	}, [loading, searchString, playlists]);

	const playlistColumns = [
		{
			name: 'Playlist Name',
			cell: (row) => <div>{row.name}</div>,
			selector: (row) => row.name,
			sortable: true,
			minWidth: '300px',
		},
		{
			name: 'Date Created',
			cell: (row) => <div>{moment(row.createdAt).format('M/D/YY')}</div>,
			selector: (row) => moment(row.createdAt).format('M/D/YY'),
			sortable: true,
			maxWidth: '250px',
		},
		{
			name: 'Created By',
			cell: (row) => (
				<div>{row.user.firstName + ' ' + row.user.lastName}</div>
			),
			selector: (row) => row.user.firstName + ' ' + row.user.lastName,
			sortable: true,
			maxWidth: '250px',
		},
		{
			name: 'Actions',
			cell: (row) => (
				<PlaylistActions
					row={row}
					accountId={accountId}
					onRemove={onRemove}
					canEdit={playlistsPermission.canEdit}
					onShare={onShare}
				/>
			),
			style: { cursor: 'pointer' },
		},
	];

	let emptyState = (
		<div className="playlists-empty-container">
			No playlists have been created.
		</div>
	);

	if (playlists.length > 0) {
		emptyState = (
			<div className="playlists-empty-container">
				Nothing matches your search.
			</div>
		);
	}

	if (loading) {
		return (
			<div className="playlists-loading-container">
				<Spinner
					className="playlists-loading"
					animation="grow"
					variant="info"
				/>
			</div>
		);
	}

	// Sharing Urls
	const publicUrl = modals?.playlistShareModal?.playlistToShare?.customDomain
		? `https://${modals.playlistShareModal.playlistToShare.customDomain}`
		: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;
	const shareUrl = `${publicUrl}/public/${accountId}/playlist/${modals?.playlistShareModal?.playlistToShare?.slug}`;

	return (
		<>
			<div className="playlists">
				<div className="playlists-header-row">
					<h2 className="playlisits-header">Playlist Management</h2>
					{playlistsPermission.canEdit && (
						<Button className="mt-3" onClick={newPlaylistOnClick}>
							Create New Playlist
						</Button>
					)}
				</div>
				<div className="playlists-search">
					<FaSearch className="search-icon" />
					<input
						placeholder="Search your playlists"
						onChange={(e) => setSearchString(e.target.value)}
					/>
				</div>
				<BaseTable
					values={filteredPlaylists}
					columns={playlistColumns}
					loading={loading}
					clickable={false}
					pagination={true}
					paginationPerPage={8}
					resetPaginationToggle={false}
					noDataComponent={emptyState}
				/>
			</div>
			<ShareContentModal
				isOpen={modals.playlistShareModal.showPlaylistsModal}
				onClose={() => {
					dispatch(hidePlaylistsShareModal());
				}}
				shareContentName={'Share Playlist'}
				shareModalType={SHARE_MODAL_TYPE.PLAYLIST}
				baseUrl={publicUrl}
				shareUrl={shareUrl}
				contentSlug={modals?.playlistShareModal?.playlistToShare?.slug}
				shareId={modals?.playlistShareModal?.playlistToShare?.id}
				title="Share Playlist"
				videoEnabled={selectedAccount.enableVideo}
				name={modals?.playlistShareModal?.playlistToShare?.name}
				linkText="Create a direct link to your playlist via a specialized URL"
				embedText="Embed a playlist on your website. The Playlist Name will be displayed with your playlist."
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	permissions: state.auth.permissions,
	modals: state.ui.modals,
});

export default withRouter(connect(mapStateToProps)(Playlists));
