import React from 'react';
import { Card, Spinner, OverlayTrigger } from 'react-bootstrap';
import {
	FaInfoCircle,
	FaCaretUp,
	FaCaretDown,
	FaCaretRight,
} from 'react-icons/fa';
import { lowerCaseAndRemoveSpaces } from 'utils';

const MetricCard = (props) => {
	const {
		value = 0,
		tooltip = null,
		comparisonValue = 0,
		loading = false,
		label = '',
		className = null,
		hideTooltip = false,
		preferDecrease = false,
		valueLabel = '',
		customFormat = null,
		showPercents = false,
	} = props;

	let comparisonPercent = null;
	if (comparisonValue === value) {
		comparisonPercent = 0;
	}
	if (comparisonValue) {
		comparisonPercent = value / comparisonValue - 1.0;
	}

	const positiveClass = preferDecrease ? 'decrease' : 'increase';
	const negativeClass = preferDecrease ? 'increase' : 'decrease';

	const formattedValue = () => {
		if (!value) {
			return '0';
		}
		// use custom format if passed in
		if (customFormat) {
			return customFormat(value);
		}

		// if larger than 1,000 shorten to [n]K
		if (Math.ceil(Math.log10(value + 1)) > 6) {
			return (
				parseFloat(value / 1000000)
					.toFixed(2)
					.toString() + 'M'
			);
		}
		if (Math.ceil(Math.log10(value + 1)) > 4) {
			return (
				parseFloat(value / 1000)
					.toFixed(1)
					.toString() + 'K'
			);
		}
		return `${value.toString()}${valueLabel}`;
	};

	const getClass = () => {
		if (comparisonPercent === 0) {
			return 'neutral';
		} else {
			return comparisonPercent >= 0 ? positiveClass : negativeClass;
		}
	};

	return (
		<Card className={`metric-card ${className}`}>
			{!hideTooltip && (
				<FaInfoCircle
					className="info-icon"
					id={`help-${lowerCaseAndRemoveSpaces(label)}`}
				/>
			)}
			<Card.Body>
				{loading ? (
					<div className="metric-card--loading">
						<Spinner animation="border" variant="info" />
					</div>
				) : (
					<OverlayTrigger
						overlay={tooltip}
						trigger={tooltip ? ['hover', 'focus'] : false}
					>
						<Card.Title
							className={`metric-card--value ${tooltip && 'has-tooltip'}`}
						>
							{label && (
								<p className="metric-card--label form-label">{label}</p>
							)}
						</Card.Title>
					</OverlayTrigger>
				)}
				{loading && (
					<div className="metric-card--comparison">
						<p className="empty-comparison">- -</p>
					</div>
				)}
				{!loading && showPercents && (
					<div className={`metric-card--comparison ${getClass()}`}>
						<FaCaretUp
							className={`comparison-icon ${
								comparisonPercent > 0 ? '' : 'hidden'
							}`}
						/>
						<FaCaretDown
							className={`comparison-icon ${
								comparisonPercent < 0 ? '' : 'hidden'
							}`}
						/>
						<FaCaretRight
							className={`comparison-icon ${
								comparisonPercent === 0 ? '' : 'hidden'
							}`}
						/>

						<p
							className={`${
								comparisonPercent || comparisonPercent === 0 ? '' : 'hidden'
							}`}
						>
							{(comparisonPercent * 100).toFixed(1)}%
						</p>
						<p
							className={`empty-comparison ${
								comparisonPercent || comparisonPercent === 0 ? 'hidden' : ''
							}`}
						>
							<span className="metric-value">
								{!loading && formattedValue()}
							</span>
						</p>
					</div>
				)}
				{!loading && !showPercents && (
					<div className="metric-card--comparison">
						<div className="metric-value">{formattedValue()}</div>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};

export default MetricCard;
