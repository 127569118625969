import React, { useRef } from 'react'

import { Overlay, Tooltip } from 'react-bootstrap'

const MetricCircle = (props) => {
  const {
    className = '',
    total,
    radius,
    value = 0,
    active = false,
    onMouseEnter,
    onMouseLeave
  } = props;
  
  const toolTipTarget = useRef(null)
  
  const percentage = value/total;
  const perimeter = Math.round(Math.PI * radius * 2)
  
  const fillPerc = {
    strokeDashoffset: perimeter - (percentage * perimeter),
    strokeDasharray: perimeter,
  }
  
  return (
    <g className={`metric-circle ${className} ${active ? 'is-active' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <circle className="metric-circle--background" r={radius} cx="100" cy="100" fill="none" />
      <circle ref={toolTipTarget} className="metric-circle--fill" r={radius} cx="100" cy="100" style={fillPerc} fill="none" />
      
      <Overlay 
        target={toolTipTarget.current}
        show={active}>
        { props => (
          <Tooltip id={percentage} {...props}> { Math.floor(percentage * 100) + "%" } </Tooltip>
        )}
      </Overlay>
    </g>
  )
}

export default MetricCircle
