import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import * as Sentry from '@sentry/browser';

// External Components
import { Dropdown } from 'react-bootstrap';
import {
	FaFolder,
	FaListOl,
	FaPlus,
	FaPodcast,
	FaRegCommentDots,
	FaRss,
	FaVideo,
	FaHeadphones,
} from 'react-icons/fa';

// Internal Components
import CastedButton from 'components/shared/button';
import CastedDropdownButton from 'components/shared/dropdownButton';
import CastedModal from 'components/shared/modal';
import NewShowManualStep from 'components/shows/newShow/newShowManualStep';
import NewShowRssStep from 'components/shows/newShow/newShowRssStep';
import NewShowCollectionPermissions from 'components/shows/newShow/newShowCollectionPermissions';
import ContentCard from '../shared/contentCard';
import ContentGroup from '../shared/contentGroup';
import { ContentPage } from '../shared/contentPage';
import NewContentChoiceModal from './newContentChoiceModal';

// Internal Assets
import noRssIcon from 'assets/icons/no-rss.svg';

// Internal Libraries
import {
	EPISODE_UPLOAD_TYPES,
	PODCAST_COLLECTION_TYPE,
} from 'components/constants';
import mutations from 'services/graphql/mutations';
import queries from 'services/graphql/queries';

// Redux
import {
	ContentModalChoicesEnum,
	ContentModalConstants,
	setNewContentModalState,
} from 'store/reducers/ui/modals/newContentModal';
import { dispatch } from 'store/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

// modal step constants
const ManagedContent = (props) => {
	const { match, history, permissions, newContentModal, userProfile } = props;
	const { params } = match;
	const { accountId } = params;

	const showsPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.SHOWS).rolePermission
		: {};

	// HOOKS
	const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);
	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [searchString, setSearchString] = useState(null);
	const [contentGroups, setContentGroups] = useState([]);

	const { featureCollectionLevelPermissions } = useFlags();

	const [createItem, {}] = useMutation(mutations.createEpisode, {
		variables: {
			accountId,
			episode: {},
		},
	});

	const [createPodcast, { podcastData, podcastLoading, podcastError }] =
		useMutation(mutations.createHostedPodcast);
	// END HOOKS

	// QUERIES
	let podcastsByAccountIdQueryVariables = {
		accountId: parseInt(accountId, 10),
		includeEpisodes: true,
		episodeLimit: 1,
	};

	if (featureCollectionLevelPermissions) {
		podcastsByAccountIdQueryVariables.userPermissions = userProfile.id;
	}

	const { data, loading, error } = useQuery(queries.podcastsByAccountId, {
		variables: {
			...podcastsByAccountIdQueryVariables,
		},
		fetchPolicy: 'no-cache',
	});

	const { podcasts: shows = [] } = data || {};

	/**
	 * Returns an element with an icon correlated to the type of content being displayed
	 * @param {*} show  - Show to render icon for
	 * @returns HTMLElement
	 */
	const renderIcon = (show) => {
		switch (show.collectionType) {
			case PODCAST_COLLECTION_TYPE.serial:
				return (
					<div className="contentCard-type-icon">
						<FaRss></FaRss>
					</div>
				);
			case PODCAST_COLLECTION_TYPE.non_serial:
				return (
					<div className="contentCard-type-icon">
						<FaFolder></FaFolder>
					</div>
				);
			case PODCAST_COLLECTION_TYPE.one_off:
				if (show.episodes && show.episodes.length > 0) {
					switch (show.episodes[0].uploadType) {
						case EPISODE_UPLOAD_TYPES.VIDEO:
							return (
								<div className="contentCard-type-icon">
									<FaVideo></FaVideo>
								</div>
							);
						case EPISODE_UPLOAD_TYPES.AUDIO:
						default: {
							return (
								<div className="contentCard-type-icon">
									<FaHeadphones></FaHeadphones>
								</div>
							);
						}
					}
				}
			default:
				return '';
		}
	};

	/**
	 * Returns a string that would route to an items detail page if a collection is a one off,
	 * or to the collection detail page otherwise
	 * @param {*} show - Show to create a link to
	 * @returns String
	 */
	const getLinkUrl = (show) => {
		let linkUrl = `/account/${accountId}/shows/${show.id}/episodes`;

		if (
			show.collectionType === PODCAST_COLLECTION_TYPE.one_off &&
			show.episodes?.length > 0
		) {
			linkUrl = `/account/${accountId}/shows/${show.id}/episodes/${show.episodes[0].id}`;
		}

		return linkUrl;
	};

	const renderContentThumbnail = (show) => {
		if (
			show.collectionType === PODCAST_COLLECTION_TYPE.one_off &&
			show.episodes?.length > 0 &&
			show.episodes[0].thumbnail
		) {
			return show.episodes[0].thumbnail;
		} else {
			return show.thumbnail;
		}
	};

	useEffect(() => {
		// Split all content into groups (collections, items) for rendering
		let collectionsGroup = [];
		let itemsGroup = [];
		let newContentGroups = [];

		shows.forEach((show) => {
			// Filter out content that dont have a name matching searchString
			if (
				searchString &&
				searchString.length >= 3 &&
				show.name.toLowerCase().search(searchString.toLowerCase()) == -1
			) {
				return;
			}

			const cardIcon = renderIcon(show);
			const cardLink = getLinkUrl(show);
			const cardThumbnail = renderContentThumbnail(show);

			const contentCard = (
				<ContentCard
					id={show.id}
					name={show.name}
					icon={cardIcon}
					link={cardLink}
					thumbnail={cardThumbnail}
				/>
			);

			if (show.collectionType !== 'one-off') {
				collectionsGroup.push(contentCard);
			} else {
				itemsGroup.push(contentCard);
			}
		});

		if (collectionsGroup.length > 0) {
			newContentGroups.push(
				<ContentGroup
					contentGroupTitle={'Collections'}
					contentGroupItems={collectionsGroup}
				></ContentGroup>
			);
		}

		if (itemsGroup.length > 0) {
			newContentGroups.push(
				<ContentGroup
					contentGroupTitle={'Items'}
					contentGroupItems={itemsGroup}
				></ContentGroup>
			);
		}

		setContentGroups(newContentGroups);
	}, [loading, searchString]);

	if (error) {
		console.error(error);
		Sentry.captureException(error);
		return <div>Error: Could Not Fetch Shows</div>;
	}

	if (podcastError) {
		console.error(podcastError);
		Sentry.captureException(podcastError);
		return <div>Error Creating content</div>;
	}

	const onCloseClick = () => {
		setShowAddCollectionModal(false);
		setShowAddItemModal(false);
	};

	const showNewCollectionModal = () => {
		dispatch(
			setNewContentModalState({
				modal: ContentModalChoicesEnum.COLLECTION,
			})
		);
		setShowAddCollectionModal(true);
	};

	const showNewOneOffModal = () => {
		dispatch(
			setNewContentModalState({
				modal: ContentModalChoicesEnum.ONE_OFF_MANUAL,
			})
		);
		setShowAddItemModal(true);
	};

	const createItemAndNavigate = async (
		uploadType = EPISODE_UPLOAD_TYPES.AUDIO
	) => {
		try {
			let collection = await createPodcast({
				variables: {
					podcast: {
						accountId: parseInt(accountId, 10),
						name: '',
						description: '',
						thumbnail: '',
					},
					imageURI: '',
					collectionType: PODCAST_COLLECTION_TYPE.one_off,
				},
			});

			const { data: podcastData } = collection;
			const { createHostedPodcast: createdPodcast } = podcastData;

			let item = await createItem({
				variables: {
					accountId: parseInt(accountId),
					episode: {
						podcastId: createdPodcast.id,
						name: '',
						storageLink: '',
						description: '',
						makePublic: true, // TODO: this should probably be configurable in the UI,
						keywords: [],
						uploadType: uploadType,
						type: 'full',
					},
				},
			});
			const { data: itemData } = item;
			const { createEpisode: createdItem } = itemData;

			if (createdItem) {
				props.history.push(
					`/account/${accountId}/shows/${createdPodcast.id}/episodes/${createdItem.id}/info`
				);
			}
		} catch (e) {
			console.error(e);
			Sentry.captureException(e);
		}
	};

	const renderModal = () => {
		switch (newContentModal.state) {
			case ContentModalConstants[
				ContentModalChoicesEnum.COLLECTION_NON_SERIES
			].state:
				return (
					<NewShowManualStep
						hidden={false}
						nonSeries={true}
						collectionType={PODCAST_COLLECTION_TYPE.non_serial}
						onCloseClick={(show) => {
							if (show) {
								history.push(
									`/account/${accountId}/shows/${show.id}/episodes`
								);
							}
							onCloseClick();
						}}
					/>
				);
			case ContentModalConstants[
				ContentModalChoicesEnum.COLLECTION_SERIES_IMPORT
			].state:
				return (
					<NewShowRssStep
						hidden={false}
						onProcessRssClick={(show) => {
							if (show) {
								history.push(
									`/account/${accountId}/shows/${show.id}/episodes?newShow=true`
								);
							}
							onCloseClick();
						}}
						onManualClick={() =>
							dispatch(
								setNewContentModalState({
									modal: ContentModalChoicesEnum.COLLECTION_SERIES_MANUAL,
								})
							)
						}
					/>
				);
			case ContentModalConstants[
				ContentModalChoicesEnum.COLLECTION_SERIES_MANUAL
			].state:
				return (
					<NewShowManualStep
						hidden={false}
						onRSSClick={() =>
							dispatch(
								setNewContentModalState({
									modal: ContentModalChoicesEnum.COLLECTION_SERIES_IMPORT,
								})
							)
						}
						onCloseClick={(show) => {
							if (show) {
								history.push(
									`/account/${accountId}/shows/${show.id}/episodes`
								);
							}
							onCloseClick();
						}}
					/>
				);
			case ContentModalConstants[
				ContentModalChoicesEnum.COLLECTION_PERMISSIONS
			].state:
				return (
					<NewShowCollectionPermissions
						hidden={false}
						onCloseClick={(show) => {
							if (show) {
								history.push(
									`/account/${accountId}/shows/${show.id}/episodes`
								);
							}
							onCloseClick();
						}}
					/>
				);
			// Both ONE_OFF_MANUAL_ and COLLECTION states show a choice of two options for a suer to select
			case ContentModalConstants[ContentModalChoicesEnum.ONE_OFF_MANUAL]
				.state:
				return (
					<NewContentChoiceModal
						optionInfo={{
							optionOne: {
								title: 'Video',
								detailOne: (
									<span>
										Upload a single piece of video content
										without adding to a Collection. Create a
										landing page, amplify your content, and
										measure your engagement all in one
										place.
									</span>
								),
								optionButton: (
									<CastedButton
										name="new-series-btn "
										onClick={() =>
											createItemAndNavigate(
												EPISODE_UPLOAD_TYPES.VIDEO
											)
										}
										className="new-collection-btn"
										variant="success"
										title="Create a Video"
									>
										Create Video
									</CastedButton>
								),
							},
							optionTwo: {
								title: 'Audio',
								detailOne: (
									<span>
										Upload a single piece of audio content
										without adding to a Collection. Create a
										landing page, amplify your content, and
										measure your engagement all in one
										place.
									</span>
								),

								optionButton: (
									<CastedButton
										name="new-series-btn "
										onClick={() => createItemAndNavigate()}
										className="new-collection-btn"
										title="Create Audio"
									>
										Create Audio
									</CastedButton>
								),
							},
						}}
						closeCallback={() => setShowAddCollectionModal(false)}
					/>
				);
			case ContentModalConstants[ContentModalChoicesEnum.COLLECTION]
				.state:
			default:
				return (
					<NewContentChoiceModal
						optionInfo={{
							optionOne: {
								title: 'Series',
								detailOne: (
									<>
										<FaRss className="icon"></FaRss>
										<span> Uses RSS Feeds </span>
									</>
								),
								detailTwo: (
									<>
										<FaListOl className="icon"></FaListOl>
										<span> Seasons and episodes </span>
									</>
								),
								detailThree: (
									<>
										<FaPodcast className="icon"></FaPodcast>
										<span>
											{' '}
											Good for podcasts, ordered web
											series, and information{' '}
										</span>
									</>
								),
								optionButton: (
									<CastedButton
										name="new-series-btn "
										onClick={() =>
											dispatch(
												setNewContentModalState({
													modal: ContentModalChoicesEnum.COLLECTION_SERIES_IMPORT,
												})
											)
										}
										className="new-collection-btn"
										title="Create a new Collection"
									>
										Create Series
									</CastedButton>
								),
							},
							optionTwo: {
								title: 'Not Ordered',
								detailOne: (
									<>
										<img src={noRssIcon} className="icon" />
										<span> No RSS Feeds </span>
									</>
								),
								detailTwo: (
									<>
										<FaFolder className="icon"></FaFolder>
										<span> No specific order </span>
									</>
								),
								detailThree: (
									<>
										<FaRegCommentDots className="icon"></FaRegCommentDots>
										<span>
											{' '}
											Good for organizing content of
											various different topics and media
											types{' '}
										</span>
									</>
								),
								optionButton: (
									<CastedButton
										name="new-series-btn "
										onClick={() =>
											dispatch(
												setNewContentModalState({
													modal: ContentModalChoicesEnum.COLLECTION_NON_SERIES,
												})
											)
										}
										className="new-collection-btn"
										title="Create a new Collection"
									>
										Create Non-Series
									</CastedButton>
								),
							},
						}}
						closeCallback={() => setShowAddCollectionModal(false)}
					/>
				);
		}
	};

	const renderCreateButton = (
		<CastedDropdownButton
			title={
				<>
					<FaPlus className="plus-icon" />
					<span className="content-page-button-text">NEW</span>
				</>
			}
			classType="round"
			displayDropArrow={false}
			size={'lg'}
		>
			<Dropdown.Item onClick={() => showNewCollectionModal()}>
				<div>
					<h5>New Collection</h5>
					<p>
						Collections are used for managing multiple content
						items. Collections can be series or non-sequential.
					</p>
				</div>
			</Dropdown.Item>
			<Dropdown.Item onClick={() => showNewOneOffModal()}>
				<div>
					<h5>New Item</h5>
					<p>
						Items are individual pieces of audio or video that you
						don’t want in a collection.
					</p>
				</div>
			</Dropdown.Item>
		</CastedDropdownButton>
	);

	const renderNoContent = <div className="no-content">No content</div>;

	return (
		<>
			<ContentPage
				className="managed-content"
				pageTitle={'Managed Content'}
				loading={loading}
				renderNoContent={renderNoContent}
				renderCreateButton={
					showsPermission.canEdit ? renderCreateButton : <div></div>
				}
				onSearch={setSearchString}
				contentGroups={contentGroups}
				totalContentCount={shows.length}
			/>
			<CastedModal
				show={showAddCollectionModal || showAddItemModal}
				handleClose={() => onCloseClick()}
				wideModal={false}
				headerStyle={{
					display: 'flex',
					justifyContent: 'center',
					padding: '.375rem 0 1.875rem 0',
					marginBottom: 0,
				}}
				title={
					showAddCollectionModal
						? newContentModal.state ===
						  ContentModalConstants[
								ContentModalChoicesEnum.COLLECTION_PERMISSIONS
						  ].state
							? 'Collection Permissions'
							: 'New Collection'
						: 'New Item'
				}
			>
				{renderModal()}
			</CastedModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
	newContentModal: state.ui.modals.newContentModal,
	userProfile: state.auth.profile,
});

export default withRouter(connect(mapStateToProps)(ManagedContent));
