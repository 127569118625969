import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import sanitizeHtml from 'sanitize-html';

import { getAuthSingleton } from 'services/auth';
import { ToastContainer } from 'react-toastify';
import Toast from 'services/toast';
import Button from 'components/shared/button';
import TextInput from 'components/shared/textInput';
import { isEmailValid } from 'utils/';

const EMAIL_TAB = 1;
const PASSWORD_TAB = 2;

const isPasswordValid = (pw) => {
	return pw.length > 4;
};

const LoginAndSignUpBox = (props) => {
	const authSvc = getAuthSingleton();

	// HOOKS
	const [currentStep, setCurrentStep] = useState(EMAIL_TAB);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [validatingEmail, setValidatingEmail] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [shouldShowResetPassword, setShouldShowResetPassword] = useState(false);
	const [resetPasswordEmail, setResetPasswordEmail] = useState('');
	const [userId, setUserId] = useState('');
	const [confirmCode, setConfirmCode] = useState('');
	const [ssoUrl, setSsoUrl] = useState('');
	const [forceSso, setForceSso] = useState(false);
	// END HOOKS

	function validateInputs() {
		if (currentStep === EMAIL_TAB) {
			return isEmailValid(email);
		}

		if (currentStep === PASSWORD_TAB) {
			return isPasswordValid(password);
		}
	}

	function handleLoginErrors(err, defaultMsg) {
		if (err.data && err.data.auth_code) {
			switch (err.data.auth_code) {
				case 'REQUIRES_CONFIRMATION':
					setErrorMsg('User email address is not confirmed');
					if (err.data.confirm) {
						setUserId(err.data.user_id);
						setConfirmCode(err.data.confirm);
					}
					break;

				case 'DUPLICATE_USER':
					setErrorMsg('A user already exists with this email.');
					break;

				case 'ACCOUNT_DOES_NOT_EXIST':
					setErrorMsg('Domain is not registered.  Contact help@casted.us.');
					break;

				case 'USER_DOES_NOT_EXIST':
					setErrorMsg('No user found');
					break;

				default:
					if (err.data && err.data.message) {
						setErrorMsg(err.data.message);
					} else {
						setErrorMsg(defaultMsg);
					}
					break;
			}
		} else {
			if (err.data && err.data.message) {
				setErrorMsg(err.data.message);
			} else {
				setErrorMsg(defaultMsg);
			}
		}
	}

	// CLICK HANDLERS
	const onKeyPress = (e) => {
		if (e.which === 13 && validateInputs()) {
			onSignInClick();
		}
	};

	const onSignInClick = async () => {
		try {
			const emailLower = email.toLowerCase();
			if (currentStep === EMAIL_TAB) {
				const ssoResponse = await authSvc.getSsoUrl(emailLower);
				setSsoUrl(ssoResponse.data.ssoUrl);
				setForceSso(ssoResponse.data.forceSso || false);
				setCurrentStep(PASSWORD_TAB);
			} else if (currentStep === PASSWORD_TAB) {
				const tokenResponse = await authSvc.signInLocal(emailLower, password);
				const { accessToken, refreshToken, user } = tokenResponse;

				if (accessToken && refreshToken && user) {
					props.onSuccessfulLogin(accessToken, refreshToken, null, user);
				}
			}
		} catch (e) {
			const errMsg =
				currentStep === EMAIL_TAB
					? 'An error has occurred, contact support for assistance.'
					: 'Invalid Email or Password';
			handleLoginErrors(e.response, errMsg);
			console.error(e);
			Sentry.captureException(e);
		}
	};

	const onSendResetPassword = async () => {
		try {
			const emailLower = resetPasswordEmail.toLowerCase();
			const res = await authSvc.sendResetEmail(emailLower);
			if (res.data == 'Account not confirmed') {
				Toast.error(
					'The user account associated with this email has not been activated. Please reach out to your Admin to resend your user account invitation.'
				);
			} else {
				Toast.success("Confirmed",'Your password reset request has been sent successfully. Please check your email for instructions to reset your password.');
				setShouldShowResetPassword(false);
			}
		} catch (e) {
			console.error(e);
			Sentry.captureException(e);
			Toast.error(
				'Something went wrong! Unable to send reset password. Please contact our support.'
			);
		}
	}

	const onResendPassword = async () => {
		try {
			await authSvc.sendConfirmEmail(email, userId, confirmCode);
			setConfirmCode('');
		} catch (e) {
			console.error(e);
			Toast.error(
				'Something went wrong! Unable to send confirmation email. Please contact our support.'
			);
			Sentry.captureException(e);
		}
	}

	// END CLICK HANDLERS

	// RENDER METHODS
	const renderOrSection = () => {
		return (
			<div className="sign-in-up-or-section">
				<div className="sign-in-up-or-line" />
				<p className="sign-in-up-or">or</p>
				<div className="sign-in-up-or-line" />
			</div>
		);
	};

	const renderErrMsg = () => {
		return <div className="error-message">{errorMsg}</div>;
	};

	const renderEmail = () => {
		return (
			<React.Fragment>
				<label className="sign-in-up-label">Email</label>
				<TextInput
					name="email"
					label="name@company.com"
					value={email}
					type="email"
					onChange={(e) => {
						setEmail(e.target.value);
						setConfirmCode('');
					}}
					onKeyPress={(e) => onKeyPress(e)}
					inputClassName="sign-in-up-form-input"
					rootStyles={{ margin: '0 0 10px 0' }}
					inputProps={{ onBlur: () => setValidatingEmail(true) }}
					showValidation={validatingEmail}
					validateFn={isEmailValid}
					validityErrorMessage="Must be a valid email"
					autoFocus={true}
				/>
			</React.Fragment>
		);
	};

	const renderPassword = () => {
		return (
			<React.Fragment>
				<label className="sign-in-up-label">Password</label>
				<TextInput
					name="password"
					label="password"
					value={password}
					type="password"
					onChange={(e) => setPassword(e.target.value)}
					onKeyPress={(e) => onKeyPress(e)}
					inputClassName="sign-in-up-form-input"
					rootStyles={{ margin: 0 }}
					autoFocus={true}
				/>
				<Button
					name="forgot-password-button"
					onClick={() => {
						setShouldShowResetPassword(true);
						setResetPasswordEmail(email);
					}}
					className="forgot-password-btn"
					variant="flat"
					title="Forgot Password"
				>
					Forgot Password?
				</Button>
			</React.Fragment>
		);
	};

	const renderSsoButton = () => {
		return (
			<Button
				className="sign-in-up-btn mt-3"
				variant="success"
				href={`${
					process.env.REACT_APP_AUTH_HOST
				}/auth/login/sso?email=${sanitizeHtml(email)}`}
				target="_self"
			>
				Log in with SSO
			</Button>
		);
	};

	const renderSignIn = () => {
		return (
			<React.Fragment>
				{currentStep === EMAIL_TAB && renderEmail()}
				{currentStep === PASSWORD_TAB && !forceSso && renderPassword()}

				{!forceSso && (
					<Button
						name="sign-in-button"
						onClick={onSignInClick}
						className="sign-in-up-btn mt-3"
						variant="success"
						title="Sign in"
						disabled={
							(currentStep === PASSWORD_TAB && !isPasswordValid(password)) ||
							(currentStep === EMAIL_TAB && !isEmailValid(email))
						}
					>
						Sign In
					</Button>
				)}

				{currentStep === PASSWORD_TAB &&
					ssoUrl &&
					!forceSso &&
					renderOrSection()}
				{currentStep === PASSWORD_TAB && ssoUrl && renderSsoButton()}

				{renderErrMsg()}
				{confirmCode != '' && renderResendConfirmEmail()}

				<hr />
			</React.Fragment>
		);
	};

	const renderResetPassword = () => {
		return (
			<React.Fragment>
				<h3 className="reset-password-header">Forgot Password</h3>
				<label className="sign-in-up-label">Email</label>
				<TextInput
					name="reset-password-email"
					label="name@company.com"
					value={resetPasswordEmail}
					type="email"
					onChange={(e) => setResetPasswordEmail(e.target.value)}
					inputClassName="sign-in-up-form-input"
					rootStyles={{ margin: '0 0 10px 0' }}
					autoFocus={true}
				/>
				<Button
					name="send-reset-password-button"
					onClick={onSendResetPassword}
					className="send-reset-password-btn"
					variant="success"
					title="Send Reset Password"
					disabled={!isEmailValid(resetPasswordEmail)}
				>
					Send Reset Password
				</Button>
				<Button
					name="reset-password-go-back-button"
					onClick={() => setShouldShowResetPassword(false)}
					className="reset-password-go-back-btn"
					variant="flat"
					title="Back"
				>
					Back to Sign In
				</Button>
			</React.Fragment>
		);
	};

	const renderResendConfirmEmail = () => {
		return (
			<React.Fragment>
				<Button
					name="resend-confirm-email"
					className="send-confirm-btn"
					variant="flat"
					onClick={onResendPassword}
				>
					Resend Confirmation Email
				</Button>
			</React.Fragment>
		);
	};

	return (
		<div className="login-and-signup-container">
			<div className="lasc-box">
				<div className="lasc-box-tabs">
					{!shouldShowResetPassword && (
						<React.Fragment>
							<Button
								name="sign-in-tab-button"
								onClick={() => setCurrentStep(EMAIL_TAB)}
								variant="flat"
								title="Sign In"
							>
								Sign In
							</Button>
						</React.Fragment>
					)}
				</div>
				<div className="lasc-box-content">
					{!shouldShowResetPassword && renderSignIn()}
					{shouldShowResetPassword && renderResetPassword()}
				</div>
			</div>
			<ToastContainer autoClose={false} />
		</div>
	);
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(LoginAndSignUpBox);
