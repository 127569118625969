import React, { useState, useEffect } from 'react';
import { trackEvent } from 'services/vitally';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import { setSelection, setHasSelection } from 'store/reducers/data/transcript';

// Apollo/GraphQL
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';

// External components
import { Card, Form, Badge, Row, Col, Spinner } from 'react-bootstrap';
import { FaTimes, FaCut } from 'react-icons/fa';
import MaskedInput from 'react-text-mask';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Internal components
import Button from 'components/shared/button';
import TagsInput from 'components/shared/tagsInput';

// Internal utils
import { formatDuration, formatValue } from 'utils';
import { PODCAST_COLLECTION_TYPE, CLIP_TYPES } from 'components/constants';

const EditClipCard = (props) => {
	/** Props **/
	const {
		onClose = () => {},
		clip = {},
		canCreateTakeaway,
		collectionType,
		isSuggestedClip = false,
		isRejectedClip = false,
		className = '',
		/* Redux props start */
		selection,
		hasSelection,
		/* Redux props end */
	} = props;

	const { featureClipImprovements, featurePromotions } = useFlags();

	const {
		id: clipId,
		name: _clipName = '',
		description: _clipDescription = '',
		keywords: _clipTags = [],
		type: _clipType = '',
		applyPromo: _applyPromo = false,
	} = clip || {};

	/** State **/
	const [clipName, setClipName] = useState(_clipName || '');
	const [clipDescription, setClipDescription] = useState(
		_clipDescription || ''
	);
	const [clipTags, setClipTags] = useState(_clipTags || []);
	const [isTakeaway, setIsTakeaway] = useState(_clipType || '');
	const [applyPromo, setApplyPromo] = useState(_applyPromo || false);
	const [showFullReason, setShowFullReason] = useState(false);

	useEffect(() => {
		if (_clipName) setClipName(_clipName);
		if (_clipDescription) setClipDescription(_clipDescription);
		if (_clipTags) setClipTags(_clipTags);
		if (_clipType) setIsTakeaway(_clipType === 'takeaway');
		if (_applyPromo) setApplyPromo(_applyPromo);
	}, [clip]);

	/** Queries & Mutations **/
	const [updateClip, { data: updatedClipData }] = useMutation(
		mutations.updateClipById,
		{
			refetchQueries: ['ClipsInEpisode'],
			options: {
				awaitRefetchQueries: true,
			},
		}
	);

	/** Methods **/
	const submitUpdate = async (event) => {
		const form = event.currentTarget;
		event.preventDefault();

		if (form.checkValidity() === false) {
			event.stopPropagation();
			return false;
		}

		const clipType =
			isTakeaway || clip.type === CLIP_TYPES.suggested ? 'takeaway' : 'promo';
		const clipData = {
			name: clipName,
			description: clipDescription,
			keywords: clipTags,
			episodeId: clip.episodeId,
			type: clipType,
			applyPromo: applyPromo,
			startTime: selection.startTime || 0,
			endTime: selection.endTime || 0,
		};
		if (clip.type === CLIP_TYPES.suggested) {
			clipData.status = 'active';
		}
		await updateClip({ variables: { clipId, clip: clipData } });
		trackEvent('edit-clip', { clipName, itemId: clip.episodeId, clipType });
		onClose();
	};

	const removeTag = (tagToRemove) => {
		setClipTags(clipTags.filter((tag) => tag != tagToRemove));
	};

	const renderTag = (tag) => (
		<Badge className="tag" key={tag.tag + tag.key}>
			{tag.tag}
			<a onClick={() => removeTag(tag.tag)}>
				<FaTimes size={10} className="remove-tag" />
			</a>
		</Badge>
	);

	const handleStartInput = (input) => {
		input.persist();
		if (input.target.id === document.activeElement.id) {
			const timeArray = input.target.value.replaceAll(' ', '0').split(':');

			let timeInSeconds = +timeArray[0] * 60 + +timeArray[1];

			if (timeInSeconds < 0) timeInSeconds = 0;
			if (selection.endTime && !hasSelection) dispatch(setHasSelection(true));
			dispatch(
				setSelection({
					startTime: timeInSeconds,
					startIndex: null,
					endTime: selection.endTime,
					endIndex: selection.endIndex,
				})
			);
		}
	};

	const handleEndInput = (input) => {
		input.persist();

		if (input.target.id === document.activeElement.id) {
			const timeArray = input.target.value.replaceAll(' ', '0').split(':');

			let timeInSeconds = +timeArray[0] * 60 + +timeArray[1];

			if (timeInSeconds < selection.startTime)
				timeInSeconds = selection.startTime;
			if (selection.startTime && timeInSeconds) dispatch(setHasSelection(true));
			dispatch(
				setSelection({
					startTime: selection.startTime,
					startIndex: selection.startIndex,
					endTime: timeInSeconds,
					endIndex: null,
				})
			);
		}
	};

	const hideSuggestedClip = async () => {
		const clipData = {
			episodeId: clip.episodeId,
			type: 'rejected',
		};
		await updateClip({ variables: { clipId, clip: clipData } });
		trackEvent('edit-clip', {
			clipName,
			itemId: clip.episodeId,
			clipType: 'rejected',
		});
		onClose();
	};

	const unHideSuggestedClip = async () => {
		const clipData = {
			episodeId: clip.episodeId,
			type: 'suggested',
		};
		await updateClip({ variables: { clipId, clip: clipData } });
		trackEvent('edit-clip', {
			clipName,
			itemId: clip.episodeId,
			clipType: 'suggested',
		});
		onClose();
	};

	const renderSuggestedReason = () => {
		return (
			<Row className="clip-reason">
				<b>Why did we choose this clip?</b>
				<div className={showFullReason ? 'full-reason' : 'reason'}>
					{clip.suggestionReason}
				</div>
				<div onClick={() => setShowFullReason(!showFullReason)}>
					{showFullReason ? 'Read Less' : 'Read More'}
				</div>
			</Row>
		);
	};

	if (isRejectedClip) {
		return (
			<div className="hidden-clip">
				<div className="header-container">
					<h4 className="header">HIDDEN</h4>
					<p>This clip suggestion was declined by your team</p>
				</div>
				{renderSuggestedReason()}
				<div className="unhide">
					<Button variant="light" onClick={unHideSuggestedClip}>
						UNHIDE
					</Button>
				</div>
			</div>
		);
	}
	/** Render **/
	return (
		<Card className={`clip-card-modal p-3 ${className}`}>
			<Form onSubmit={submitUpdate}>
				{!isSuggestedClip && (
					<Row className="py-2">
						<Col xs={9} className="d-flex align-items-center">
							<FaCut />
							<h5 className="my-0 mx-2">Edit Clip</h5>
							<p className="my-0 mx-2">
								{formatDuration(clip.endTime - clip.startTime)} min
							</p>
						</Col>
						<Col xs={3} className="d-flex justify-content-end">
							<FaTimes className="clip-close" onClick={onClose} />
						</Col>
					</Row>
				)}
				<Row>
					<Col className="edit-clip-form">
						<Form.Group>
							<Form.Label>Clip Time</Form.Label>
							<div className="d-flex">
								<MaskedInput
									id={'clipStartTimeInput' + clip.id}
									className="ticker--time form-control"
									mask={[/[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
									placeholderChar=" "
									guide={true}
									value={formatValue(selection.startTime)}
									onChange={(input) => handleStartInput(input)}
								/>
								<div className="d-flex align-items-center">
									<p className="m-0 px-1 font-weight-bold">-</p>
								</div>
								<MaskedInput
									id={'clipEndTimeInput' + clip.id}
									className="ticker--time form-control"
									mask={[/[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
									placeholderChar=" "
									guide={true}
									value={formatValue(selection.endTime)}
									onChange={(input) => handleEndInput(input)}
								/>
							</div>
						</Form.Group>
						<Form.Group>
							<Form.Label>Clip Title</Form.Label>
							<Form.Control
								required
								value={clipName}
								onChange={(e) => {
									setClipName(e.target.value);
								}}
								type="text"
							/>
						</Form.Group>
						{featureClipImprovements && (
							<Form.Group>
								<Form.Label>Clip Description</Form.Label>
								<Form.Control
									required
									value={clipDescription}
									onChange={(e) => {
										setClipDescription(e.target.value);
									}}
									type="text"
									placeholder="This clip is about ..."
								/>
							</Form.Group>
						)}
						<Form.Group>
							<Form.Label>Clip Tags</Form.Label>
							<TagsInput
								className="clip-tags"
								tags={clipTags}
								onChange={(tags) => setClipTags(tags)}
								renderTag={renderTag}
							/>
						</Form.Group>
						<Form.Group>
							{featureClipImprovements && featurePromotions && (
								<Form.Check
									label={`Play promotions on this clip embed`}
									onChange={() => setApplyPromo(!applyPromo)}
									checked={applyPromo}
								/>
							)}
							<Form.Check
								label={`Display on ${
									collectionType === PODCAST_COLLECTION_TYPE.serial
										? 'episode'
										: 'casted'
								} page`}
								onChange={() => setIsTakeaway(!isTakeaway)}
								checked={isTakeaway}
								disabled={!canCreateTakeaway}
							/>
						</Form.Group>
					</Col>
				</Row>
				{isSuggestedClip && renderSuggestedReason()}
				<Row className="py-2">
					{isSuggestedClip && (
						<Col className="d-flex justify-content-start py-2 px-0">
							<Button variant="light" onClick={hideSuggestedClip}>
								<FaTimes /> Ignore
							</Button>
						</Col>
					)}
					<Col className="d-flex justify-content-end py-2 px-0">
						<Button
							variant="success"
							className="save-clip-button"
							type="submit"
						>
							Save Clip <FaCut />
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	selection: state.transcript.selection,
	hasSelection: state.transcript.hasSelection,
});

export default connect(mapStateToProps)(EditClipCard);
