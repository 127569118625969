import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import UserListRow from 'components/users/userListRow';
import UserModal from 'components/users/userModal';
import { ThreeHorizontalLines } from 'components/shared/loadingSkeletons/skeleton';
import Visible from 'react-visibility-sensor';
import Toast from 'services/toast';
import { getSelectedAccount } from 'store/selectors/accounts';
import Button from 'components/shared/button';
import {
	getPermission,
	ObjectPermissionSlugs,
} from 'utils/permissionsManager.js';

import { Container, Row, Col, Spinner } from 'react-bootstrap';

const UserList = (props) => {
	// props
	const {
		selectedAccountId: accountId,
		selectedAccount,
		permissions,
		modals,
	} = props;

	// Hooks
	const [moreUsersAvail, setMoreUsersAvail] = useState(true);
	const [addNewUser, setAddNewUser] = useState(false);

	const queryVars = {
		variables: {
			accountId: accountId,
			sortField: 'firstName',
			sortOrder: 'ASC',
			limit: 50,
			offset: 0,
			includeInternalUsers: selectedAccount.name === 'Casted',
		},
		fetchPolicy: 'cache-and-network',
	};

	const {
		data: { accountUsers = [] } = {},
		loading,
		error,
		fetchMore,
		refetch: refreshUsers,
	} = useQuery(queries.accountUsers, queryVars);
	// End Hooks

	const userPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.USERS)
		: null;

	// Helper Functions
	// Infinite scroll - get more users and add to data layer
	const getMoreUsers = () => {
		const more = fetchMore({
			variables: {
				offset: accountUsers.length,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult.accountUsers.length) {
					setMoreUsersAvail(false);
					return prev;
				}
				return Object.assign(
					{},
					{
						accountUsers: [
							...prev.accountUsers,
							...fetchMoreResult.accountUsers,
						],
					}
				);
			},
		});
	};
	// End Helper Functions

	// Render
	if (loading && !accountUsers.length) {
		return <ThreeHorizontalLines className="loading-users" />;
	}

	if (error) {
		Toast.error('Error fetching users');
		return null;
	}

	return (
		<Container className="users-screen p-5">
			<Row className="mb-5">
				<Col>
					<h2 className="title">Manage Users</h2>
				</Col>
				{userPermission && userPermission.rolePermission.canEdit && (
				<Col className="d-flex justify-content-end">
					<Button
						variant="success"
						className="add-new-user"
						onClick={() => setAddNewUser(true)}
					>
						ADD NEW
					</Button>
				</Col>
				)}
			</Row>
			<div className="users-list">
				{accountUsers.map((u) => (
					<UserListRow
						key={u.id}
						accountId={accountId}
						user={u}
						currentRoles={u.roles}
						accountUserData={u.accountUsers}
						refreshPage={() => {
							refreshUsers();
						}}
						permissions={permissions}
					/>
				))}
			</div>

			<Visible
				onChange={(isVisible) => {
					if (isVisible && moreUsersAvail) getMoreUsers();
				}}
			>
				<div style={{ width: '100%', textAlign: 'center' }}>
					{moreUsersAvail ? (
						<Spinner animation="border" variant="info" />
					) : (
						<p className="infinite-scroll--bottom">
							That's everyone!
						</p>
					)}
				</div>
			</Visible>

			<UserModal
				title={'Add New User'}
				edit={false}
				isOpen={addNewUser}
				onHide={() => {
					setAddNewUser(false);
				}}
				accountId={accountId}
				refreshUsers={refreshUsers}
			/>
		</Container>
	);
	// end Render
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
	selectedAccount: getSelectedAccount(state),
});

export default connect(mapStateToProps)(UserList);
