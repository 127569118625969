import React, { useState, useEffect } from 'react';
import queries from 'services/graphql/queries';

import { Modal, Container, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import MetricDateRangePicker from 'components/shared/metricDateRange';

import { ClipCards, ClipTraffic, ClipType, ListensOverTime } from './cards';

const ClipModal = (props) => {
	const {
		isOpen,
		onHide = () => {},
		clipData,
		parentDateRange,
		episodeNumber,
		episodeSeason,
		shareSlug,
	} = props;

	const [dateRange, setDateRange] = useState({});

	useEffect(() => {
		setDateRange(parentDateRange);
	}, [parentDateRange]);

	return (
		<Modal
			show={isOpen}
			onHide={onHide}
			dialogClassName="modal-extrawide"
			centered
		>
			<Container className="metric-clip-modal">
				<Row className="my-2">
					<Col className="d-flex justify-content-end">
						<FaTimes onClick={onHide} />
					</Col>
				</Row>
				<Row className="mb-5">
					<Col>
						<MetricDateRangePicker
							dateRange={dateRange}
							onChange={setDateRange}
						/>
					</Col>
				</Row>
				<Row className="mb-5">
					<Col>
						<Container className="card top-card">
							<Row>
								<Col
									xs={12}
									md={6}
									className="d-flex flex-column justify-content-center align-items-start"
								>
									<div className="clip-title">
										<h4>{clipData.name}</h4>
										<p>
											{episodeSeason ? 'Season' + episodeSeason : ''}{' '}
											{episodeNumber ? 'Episode ' + episodeNumber : ''}
										</p>
									</div>
								</Col>
								<Col xs={12} md={6}>
									<ClipCards
										clipId={clipData.id}
										shareSlug={shareSlug}
										dateRange={dateRange}
									/>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row className="mb-5">
					<Col>
						<ListensOverTime
							clipId={clipData.id}
							shareSlug={shareSlug}
							dateRange={dateRange}
							hideToolTip={true}
						/>
					</Col>
				</Row>
				<Row className="mb-5">
					<Col xs={12} md={6}>
						<ClipTraffic
							clipId={clipData.id}
							shareSlug={shareSlug}
							dateRange={dateRange}
						/>
					</Col>
					<Col xs={12} md={6}></Col>
				</Row>
			</Container>
		</Modal>
	);
};

export default ClipModal;
