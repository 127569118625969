import React from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../button';

const CastedModal = (props) => {
	const {
		className = '',
		show = false,
		handleClose = () => {},
		children,
		closeButton = true,
		title = '',
		// TODO: Remove Legacy way to widen modal.
		// modalSize replaces wideModal
		wideModal = false,
		modalSize = 'sm',
		dialogOptions = [],
		headerStyle = {},
		backdrop = true,
	} = props;

	return (
		<Modal
			show={show}
			onHide={handleClose}
			className={`casted-modal 
				${wideModal ? 'wide' : ''} 
				${modalSize}
				${className}
			`}
			backdrop={backdrop}
		>
			{title !== '' && (
				<Modal.Header closeButton={closeButton} style={headerStyle}>
					{title && (
						<Modal.Title>
							<h4>{title}</h4>
						</Modal.Title>
					)}
				</Modal.Header>
			)}
			<Modal.Body>{children}</Modal.Body>
			{dialogOptions.length > 0 && (
				<Modal.Footer>
					{dialogOptions.map((dialogOption, i) => {
						return (
							<Button
								key={`modal-dialog-${i}`}
								variant={dialogOption.variant}
								onClick={() => {
									dialogOption.onClick();
								}}
							>
								{dialogOption.text}
							</Button>
						);
					})}
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default CastedModal;
