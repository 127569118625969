import React, { useState, useRef } from 'react';

import { Overlay, Popover, Nav, Row, Col } from 'react-bootstrap';
import { FaChevronUp } from 'react-icons/fa';

import Avatar from './avatar.js';
import { generateDefaultAvatar, compareStrings } from 'utils/';
import {
	getPermission,
	ObjectPermissionSlugs,
} from 'utils/permissionsManager.js';

const LoggedInUserPopover = (props) => {
	const {
		permissions,
		authProfile,
		selectedAccountId,
		accounts,
		logout = () => {},
		onAccountSwitchClick = () => {},
		onUsersClick = () => {},
		onApiKeysClick = () => {},
		onSsoClick = () => {},
		onAccountIntegrationsClick = () => {},
	} = props;
	const { fullName, avatar_url: avatarUrl } = authProfile;

	const [show, setShow] = useState(false);
	const target = useRef(null);

	const userPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.USERS)
		: null;
	const samlPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.SAML)
		: null;

	/**
	 * User Popover
	 * Create account navigation
	 * and logout
	 */
	const popover = (popperProps) => (
		<Popover id="user-popover" className="account-nav" {...popperProps}>
			<Popover.Content>
				<Nav className="flex-column">
					<Nav.Item className="account-nav--label">
						<strong>Accounts</strong>
					</Nav.Item>
					<div className="accounts-nav--list">
						{accounts &&
							Object.values(accounts)
								.sort((a, b) => compareStrings(a.name, b.name))
								.map((account) => {
									const { id, name } = account;
									const isSelectedAccount =
										parseInt(id, 10) === parseInt(selectedAccountId, 10);

									return (
										<Nav.Item key={id}>
											<Nav.Link
												onClick={() => {
													onAccountSwitchClick(id);
													setShow(false);
												}}
												className={`logged-in-user-popover-opt-row-btn ${
													isSelectedAccount ? 'selected' : ''
												}`}
											>
												{name}
											</Nav.Link>
										</Nav.Item>
									);
								})}
					</div>
					<Nav.Item className="account-nav--label account-nav--settings">
						<strong>Account Settings</strong>
					</Nav.Item>
					{userPermission && userPermission.rolePermission.canView && (
						<Nav.Item className="account-nav--users">
							<Nav.Link onClick={() => onUsersClick()}>Manage Users</Nav.Link>
						</Nav.Item>
					)}
					{samlPermission && samlPermission.rolePermission.canEdit && (
						<Nav.Item className="account-nav--users">
							<Nav.Link onClick={() => onSsoClick()}>Configure SSO</Nav.Link>
						</Nav.Item>
					)}
					<Nav.Item className="account-nav--apiKeys">
						<Nav.Link onClick={() => onApiKeysClick()}>API Keys</Nav.Link>
					</Nav.Item>
					<Nav.Item className="account-nav--accountIntegrations">
						<Nav.Link onClick={() => onAccountIntegrationsClick()}>
							Account Integrations
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="account-nav--logout">
						<Nav.Link onClick={logout}>Logout</Nav.Link>
					</Nav.Item>
				</Nav>
			</Popover.Content>
		</Popover>
	);

	/**
	 * Render Account Nav and Avatar
	 */
	const accountNavButton = (
		<Row
			noGutters
			style={{ alignItems: 'center' }}
			ref={target}
			onClick={() => {
				setShow(!show);
			}}
		>
			<Col xs={2}>
				<Avatar
					srcUrl={
						avatarUrl ||
						generateDefaultAvatar({ size: 31, identifier: fullName })
					}
					alt={`${fullName}'s avatar`}
					name={fullName}
					rootClassname="ab-action-center-avatar-root"
					imgClassName="ab-action-center-avatar-img"
					initialsClassName="ab-action-center-avatar-initials"
				/>
			</Col>
			<Col xs={8}>
				<div className="logged-in-user-info">
					<p className="users-name" title={fullName}>
						{fullName}
					</p>
					{accounts[selectedAccountId] && (
						<p
							className="users-company"
							title={accounts[selectedAccountId].name}
						>
							{accounts[selectedAccountId].name}
						</p>
					)}
				</div>
			</Col>
			<Col xs={2}>
				<FaChevronUp className="account-navigation--icon" />
			</Col>
		</Row>
	);

	/**
	 * Render everthing together
	 */
	return (
		<div className="account-navigation">
			{accountNavButton}

			<Overlay
				container={() => {
					return document.getElementById('sidebar');
				}}
				target={target.current}
				placement="top-end"
				show={show}
				onHide={() => {
					setShow(false);
				}}
				rootClose={true}
			>
				{(props) => {
					return popover(props);
				}}
			</Overlay>
		</div>
	);
};

export default LoggedInUserPopover;
