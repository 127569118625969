import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import uniqueSlug from 'unique-slug';

// External Components
import { FaPlay, FaForward, FaBackward } from 'react-icons/fa';

// Internal Components
import { SHARE_MODAL_TYPE } from 'components/constants';
import ShareForm from 'components/shared/shareForm';
import GenerateButton from '../../generateButton';

const LinkTab = (props) => {
	const {
		shareContentSubtitle = '',
		shareContentName = '',
		shareContentDuration = null,
		shareContentThumbnail = null,
		renderMoreContent = null,
		audioOnly,
		shareUrl,
		shareModalType,
		name,
		selectedAccountId,
		shareId,
	} = props;

	const [shareDetails, setShareDetails] = useState({
		title: `${name} Link ${new Date().toLocaleDateString()}`,
	});
	const { title, destination, description } = shareDetails;

	const [createShare] = useMutation(mutations.createShare);

	const createShareLink = async () => {
		const shareSlug = uniqueSlug();
		await navigator.clipboard.writeText(`${shareUrl}/share/${shareSlug}`);

		let share = await createShare({
			variables: {
				share: {
					accountId: parseInt(selectedAccountId, 10),
					itemId:
						shareModalType === SHARE_MODAL_TYPE.EPISODE
							? shareId
							: null,
					collectionId:
						shareModalType === SHARE_MODAL_TYPE.COLLECTION
							? shareId
							: null,
					playlistId:
						shareModalType === SHARE_MODAL_TYPE.PLAYLIST
							? shareId
							: null,
					title: title,
					destination: destination,
					description: description,
					shareType: 'link',
					contentType:
						shareModalType === SHARE_MODAL_TYPE.EPISODE
							? 'item'
							: shareModalType,
					shareOption: {},
					shareSlug,
				},
			},
		});
		share = share.data.createShare;

		if (share && share.id) {
			trackEvent('create-share-link', { shareId: share.id });
		}
	};

	const renderEpisodeEmbedSocial = (renderAsOGCard) => {
		return (
			<>
				{shareContentThumbnail && (
					<img
						src={shareContentThumbnail}
						alt="Episode Thumbnail"
						className={
							audioOnly || renderAsOGCard
								? 'scm-content-thumbnail'
								: 'scm-content-video'
						}
					/>
				)}

				<div className="scm-share-content-info">
					{shareContentSubtitle && (
						<p className="scm-share-content-subtitle">
							{shareContentSubtitle}
						</p>
					)}
					{shareContentName && (
						<h4 className="scm-share-content-name">
							{shareContentName}
						</h4>
					)}
					{shareContentDuration && (
						<p className="scm-share-content-duration">
							{shareContentDuration}
						</p>
					)}
					{!renderAsOGCard && (
						<div className="share-content-player">
							<div className="audio-controls left">
								<FaBackward className="control" />
								<FaPlay className="control" />
								<FaForward className="control" />
							</div>
							<div className="audio-progress">
								<div className="current-time">03:25</div>
								<div className="bar">
									<div
										className="progress"
										style={{ width: `${33}%` }}
									></div>
								</div>
								<div className="current-time">12:55</div>
							</div>
						</div>
					)}
					{renderAsOGCard && renderMoreContent && renderMoreContent()}
				</div>
			</>
		);
	};

	return (
		<div className="share-via-link-content">
			{shareModalType === SHARE_MODAL_TYPE.EPISODE && (
				<div className="scm-share-content-container">
					{renderEpisodeEmbedSocial(true)}
				</div>
			)}
			<ShareForm
				share={shareDetails}
				setShareProperty={setShareDetails}
			/>
			<GenerateButton
				buttonText="Generate Link"
				createShare={createShareLink}
				disabled={title.trim() === ''}
				tooltipText="Link Copied"
				title={title}
				isLinkTab={true}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(LinkTab);
