const RECORD_TYPES = {
	EPISODE: 'episode',
	EPISODE_TRANSCRIPT: 'episode_transcript',
	CLIP: 'clip',
	SHARE: 'share',
};

const PAGINATION_POSITIONS = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right',
};

export { RECORD_TYPES, PAGINATION_POSITIONS };
