import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { FaLaptop, FaUserFriends, FaArrowAltCircleUp } from 'react-icons/fa'

import Button from 'components/shared/button'
import { TRANSCRIPTION_OPTIONS } from 'components/constants'


const TranscriptionOptions = (props) => {
    const {
        stageName,
        currentStage,
        onContinueClick,
        premierOnly
    } = props

    // HOOKS
    const [selectedOption, setSelectedOption] = useState(premierOnly ? TRANSCRIPTION_OPTIONS.HUMAN : TRANSCRIPTION_OPTIONS.AUTO)


    if (currentStage !== stageName) {
        return null
    }

    return (
        <div>
            <h2 className="option-header">
                Choose a Transcription Option
            </h2>
            <Row>
                <Col xs={`${premierOnly ? '0' : '6'}`}>
                    <Card className={`trans-option ${premierOnly ? 'hidden' : ''}`}
                        onClick={() => {
                            setSelectedOption(TRANSCRIPTION_OPTIONS.AUTO)
                        }}
                        border={`${selectedOption === TRANSCRIPTION_OPTIONS.AUTO ? 'success' : ''}`}>
                        <Card.Body>
                            <FaLaptop className="option-icon" />
                            <Card.Title>Basic</Card.Title>
                            <Card.Text>Use our free transcription service to transcribe this episode. This will result in a transcription that is roughly 90% accurate in a matter of minutes. <br /><br />You will still have the option to improve the transcription later in the episode detail screen.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="6">
                    <Card className="trans-option"
                        onClick={() => {
                            setSelectedOption(TRANSCRIPTION_OPTIONS.HUMAN)
                        }}
                        border={`${selectedOption === TRANSCRIPTION_OPTIONS.HUMAN ? 'success' : ''}`}>
                        <Card.Body>
                            <FaUserFriends className="option-icon" />
                            <Card.Title>Premium</Card.Title>
                            <Card.Text>Use our Premium service to transcribe this episode. A human will listen and transcribe the episode, resulting in a transcript that is 99% accurate. This service will also identify multiple speakers. Please allow 24 hours for the transcription to be processed.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="6">
                    <Card className={`trans-option ${premierOnly ? '' : 'hidden'}`}
                        onClick={() => {
                            setSelectedOption(TRANSCRIPTION_OPTIONS.IMPORT)
                        }}
                        border={`${selectedOption === TRANSCRIPTION_OPTIONS.IMPORT ? 'success' : ''}`}>
                        <Card.Body>
                            <FaArrowAltCircleUp className="option-icon" />
                            <Card.Title>Import</Card.Title>
                            <Card.Text>Import an existing transcription for this episode.<br /><strong>IMPORTANT: </strong>This will only be used on the public episode page. The basic/premium transcription will continue to be used for creating clips and audiograms.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <span className={`trans-option-text ${premierOnly ? 'hidden' : ''}`}>If you choose Basic Transcription, you will still have the option to improve the transcription later in the episode detail screen.</span>
                </Col>
            </Row>
            <div className="options-footer">
                <Button
                    onClick={() => {
                        if (onContinueClick) {
                            onContinueClick(selectedOption)
                        }
                    }}
                    className="options-next-btn"
                    variant="contained" >
                    CONTINUE
                </Button>
            </div>
        </div>
    )
}

export default withRouter(TranscriptionOptions)
