import React, { useState, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import { history, dispatch } from 'store/store';
import { setLinkedTranscript } from 'store/reducers/data/studio';
import {
	setThemeContentView,
	THEME_CONTENT_VIEWS,
} from 'store/reducers/ui/themes';

// Apollo/GraphQL
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

// External components
import { Link } from 'react-router-dom';
import { FaCircle, FaArrowRight } from 'react-icons/fa';

const ThemeDrawerCard = (props) => {
	const {
		themeMatch,
		/* Redux props start */
		accountId,
		/* Redux props end */
	} = props;

	const {
		id: themeId,
		name: themeName,
		color: themeColor,
	} = themeMatch.theme;

	const {
		loading: documentLoading,
		error: documentError,
		data: documentData,
	} = useQuery(queries.getTranscriptDocumentById, {
		variables: {
			documentId: themeMatch.documentId,
		},
	});

	const { getTranscriptDocumentById: document } = documentData || {};

	const trimmedExcerpt = document?.transcript?.raw ? (
		<div>
			"{document.transcript.raw.trim()}..."
			<span className="link-text">
				View in transcript <FaArrowRight />
			</span>
		</div>
	) : (
		'No excerpt'
	);

	const linkOnClick = () => {
		dispatch(setThemeContentView(THEME_CONTENT_VIEWS.METRICS));
	};

	return (
		<div className="theme-drawer-card">
			<Link
				to={{
					pathname: `/account/${accountId}/themes/${themeId}`,
					state: { from: history.location.pathname },
				}}
				onClick={() => linkOnClick()}
			>
				<div className="theme-link">
					<div className="link-text">
						Go to theme <FaArrowRight />
					</div>
					<FaCircle
						className="theme-color"
						style={{ color: themeColor }}
					/>
					<span className="theme-name">{themeName}</span>
				</div>
			</Link>
			<div
				className="matched-excerpt"
				onClick={() => {
					document?.start_time?.raw &&
						document?.end_time?.raw &&
						dispatch(
							setLinkedTranscript({
								startTime:
									parseFloat(document?.start_time?.raw) /
									1000,
								endTime:
									parseFloat(document?.end_time?.raw) / 1000,
							})
						);
				}}
			>
				{documentLoading ? 'Loading' : trimmedExcerpt}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(ThemeDrawerCard);
