import React, { useState, useEffect, useRef } from 'react';

import { OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa';

import Button from '../button';

import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {
	addDays,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfDay,
	startOfWeek,
	endOfWeek,
	startOfYear,
	differenceInDays,
	isSameDay,
	format,
	parse,
} from 'date-fns';

/**
 * DateRangePicker
 * Props
 * @param {object} dateRange  state of parent for start and end date, looks like {start: 'YYYY-MM-DD', end: 'YYYY-MM-DD'}
 * @param {Date}   minDate    How far back the user can select / query, defaults to beginning of time
 * @param {Date}   maxDate    How far in the future a user can select / query, defaults to yesterday
 * @param {func}   onChange   Callback to update the parent's state with the start, end date selections
 */
const MetricDateRangePicker = (props) => {
	/* Props */
	const {
		dateRange = {},
		minDate = new Date(0),
		maxDate = addDays(new Date(), -1),
		onChange = () => {},
		accountCreatedOn,
		showDatesText = true,
	} = props;

	/* State */
	const [selectedRange, setSelectedRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	});
	const [show, setShow] = useState(false);
	const [startDateDisplay, setStartDateDisplay] = useState(null);
	const target = useRef(null);
	const today = startOfDay(new Date());

	/* On Mount */
	// Set a default date range of today on load
	useEffect(() => {
		if (!dateRange) {
			onChange({
				start: format(addDays(today, -8), 'yyyy-MM-dd'),
				end: format(addDays(today, -1), 'yyyy-MM-dd'),
				comparableStart: format(addDays(today, -16), 'yyyy-MM-dd'),
				comparableEnd: format(addDays(today, -9), 'yyyy-MM-dd'),
			});
		}
	}, []);

	/* Change Handlers */
	// Parse parent (prop) format of {start: 'yyyy-mm-dd', end...} to Date object
	useEffect(() => {
		const { start, end } = dateRange || {};
		setSelectedRange({
			startDate: start
				? parse(start, 'yyyy-MM-dd', new Date())
				: addDays(today, -8),
			endDate: end ? parse(end, 'yyyy-MM-dd', new Date()) : addDays(today, -1),
			key: 'selection',
		});
	}, [dateRange]);

	useEffect(() => {
		if (
			selectedRange.startDate.getFullYear() !==
			selectedRange.endDate.getFullYear()
		) {
			setStartDateDisplay(format(selectedRange.startDate, 'EEE MMM d, yyyy'));
		} else {
			setStartDateDisplay(format(selectedRange.startDate, 'EEE MMM d'));
		}
	}, [selectedRange]);

	// on change, format to parent format of {start: 'yyyy-mm-dd', end...}
	// call onChange to update parent
	const changeHandler = (e) => {
		const { startDate, endDate } = e.selection || {};
		const dateDiff = differenceInDays(startDate, endDate);
		onChange({
			start: format(startDate, 'yyyy-MM-dd'),
			end: format(endDate, 'yyyy-MM-dd'),
			comparableStart: format(addDays(startDate, dateDiff), 'yyy-MM-dd'),
			comparableEnd: format(addDays(endDate, dateDiff), 'yyyy-MM-dd'),
		});
	};

	/**
	 * Possible quick selections
	 */
	const staticRanges = [
		{
			label: 'Yesterday',
			range: () => ({
				startDate: addDays(today, -1),
				endDate: addDays(today, -1),
			}),
		},
		{
			label: 'Last 7 Days',
			range: () => ({
				startDate: addDays(today, -8),
				endDate: addDays(today, -1),
			}),
		},
	];

	if (differenceInDays(today, minDate) > 7) {
		staticRanges.push(
			{
				label: 'Last 14 Days',
				range: () => ({
					startDate: addDays(today, -14),
					endDate: addDays(today, -1),
				}),
			},
			{
				label: 'Last 30 Days',
				range: () => ({
					startDate: addDays(today, -30),
					endDate: addDays(today, -1),
				}),
			},
			{
				label: 'This Month',
				range: () => ({
					startDate: startOfMonth(today),
					endDate: addDays(today, -1),
				}),
			}
		);
	}

	if (differenceInDays(today, minDate) > 30) {
		staticRanges.push(
			{
				label: 'Last Month',
				range: () => ({
					startDate: startOfMonth(addMonths(today, -1)),
					endDate: endOfMonth(addMonths(today, -1)),
				}),
			},
			{
				label: 'Last 90 Days',
				range: () => ({
					startDate: addDays(today, -90),
					endDate: addDays(today, -1),
				}),
			}
		);
	}

	if (differenceInDays(today, minDate) > 90) {
		staticRanges.push(
			{
				label: 'Year to Date',
				range: () => ({
					startDate: startOfYear(today),
					endDate: addDays(today, -1),
				}),
			},
			{
				label: 'All Time',
				range: () => ({
					startDate: new Date(accountCreatedOn),
					endDate: addDays(today, -1),
				}),
			}
		);
	}

	/* Display Shorthand Name in Dropdown */
	const getShorthand = () => {
		let found = staticRanges.find((range) => {
			return (
				isSameDay(range.range().startDate, selectedRange.startDate) &&
				isSameDay(range.range().endDate, selectedRange.endDate)
			);
		});
		if (found) {
			return found.label;
		} else {
			return 'Custom';
		}
	};

	/* Create Date Selector in Popover */
	const popover = (
		<Popover className="metric-date-range-popover">
			<Popover.Content>
				<DateRangePicker
					className="metric-date-range"
					ranges={[selectedRange]}
					onChange={changeHandler}
					staticRanges={createStaticRanges(staticRanges)}
					inputRanges={[]}
					maxDate={maxDate}
					minDate={minDate}
				/>
			</Popover.Content>
		</Popover>
	);

	/* Render! */
	return (
		<Row className="metric-date-range">
			<Col>
				{showDatesText && (
					<strong className="date-range--dates">
						{startDateDisplay} &nbsp; - &nbsp;{' '}
						{format(selectedRange.endDate, 'EEE MMM d, yyyy')}
					</strong>
				)}
			</Col>
			<Col>
				<OverlayTrigger
					trigger="click"
					placement="bottom"
					overlay={popover}
					rootClose={true}
				>
					<Button className="metric-date-range-trigger" variant="light">
						{' '}
						{getShorthand()}
						<FaCaretDown />
					</Button>
				</OverlayTrigger>
			</Col>
		</Row>
	);
};

export default MetricDateRangePicker;
