import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Form } from 'react-bootstrap';

import API from 'services/api';
import ThumbnailUploader from 'components/shared/thumbnailUploader';
import RemoveItem from '../removeItem';
import Toast from 'services/toast';

const Resource = (props) => {
	const {
		resource = {},
		onDelete = () => {},
		updateResource = () => {},
		index,
		disabled
	} = props;

	const {
		name: _name = '',
		url: _url = '',
		thumbnail: _thumbnail = ''
	} = resource;

	// Hooks
	const [editName, setEditName] = useState(_name);
	const [editUrl, setEditUrl] = useState(_url);
	const [editThumbnail, setEditThumbnail] = useState(_thumbnail);

	const updateResourceImage = async (imgData, fileExt) => {
		try {
			const gcsUrl = await API.uploadPublicFile({
				dataURI: imgData,
				fileExt: fileExt,
			});
			setEditThumbnail(gcsUrl);
		} catch (e) {
			setEditThumbnail('');
			Toast.error(
				typeof e.message  === 'string' ? e.message : 'Something went wrong!'
			);
			
		}

	};

	useEffect(() => {
		if(
			editName !== _name ||
			editUrl !== _url ||
			editThumbnail !== _thumbnail
		) {
			updateResource({
				name: editName,
				url: editUrl,
				thumbnail: editThumbnail
			}, index);
		}
	}, [editName, editUrl, editThumbnail]);
	// End Hooks

	const renderInfoInputs = () => {
		return (
			<Col xs={9} className="name-and-url-container">
				<div className="input-group pb-4">
					<Form.Label>Resource Name</Form.Label>
					<Form.Control
						className="guest-info-text"
						onChange={(e) => setEditName(e.target.value)}
						value={editName}
						disabled={disabled}
					/>
				</div>
				<div className="input-group">
					<Form.Label>Resource Url</Form.Label>
					<Form.Control
						className="guest-info-text"
						onChange={(e) => setEditUrl(e.target.value)}
						value={editUrl}
						disabled={disabled}
					/>
				</div>
			</Col>
		);
	};
	// End Render funcs

	return (
		<Card className="resource p-4">
			<Container className="p-0">
				<Row>
					<Col xs={3} className="thumbnail-input-container">
						<ThumbnailUploader
							overlayMsg="Upload"
							className="guest-image-upload"
							thumbnailImgSrc={editThumbnail}
							onUpload={(result, fileExt) => updateResourceImage(result, fileExt)}
							disabled={disabled}
						/>
					</Col>
					{renderInfoInputs()}
					{!disabled && (
						<RemoveItem item={resource} onDelete={() => onDelete(index)} />
					)}
				</Row>
			</Container>
		</Card>
	);
};

export default Resource;
