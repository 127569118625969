import React from 'react';
import ReactTagsInput from 'react-tagsinput';

import { Badge } from 'react-bootstrap';

const TagsInput = React.forwardRef((props, ref) => {
	const defaultTagRender = (tag) => (
		<Badge
			className="tag"
			className="mr-2 p-1"
			variant="info"
			key={tag.tag + tag.key}
		>
			{tag.tag}
		</Badge>
	);

	const {
		tags = [],
		onChange = () => {},
		onlyUnique = false,
		addOnBlur = false,
		addKeys = [9, 13, 188],
		placeholder = 'Type tag and hit tab/enter to create',
		className = '',
		inputClassName = '',
		tagClassName = '',
		removeTagClassName = '',
		renderTag = defaultTagRender,
	} = props;

	return (
		<ReactTagsInput
			ref={ref}
			value={tags}
			onChange={onChange}
			onlyUnique={onlyUnique}
			addOnBlur={addOnBlur}
			addKeys={addKeys}
			className={`tags-input ${className}`}
			inputProps={{
				className: `tags-input-input ${inputClassName}`,
				placeholder,
			}}
			tagProps={{
				className: `tags-input-tag ${tagClassName}`,
				classNameRemove: `tags-input-remove-tag ${removeTagClassName}`,
			}}
			renderTag={renderTag}
		/>
	);
});

export default TagsInput;
