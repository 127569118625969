import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client/react/hooks';

// External Libraries
import { addDays, format, startOfDay } from 'date-fns';
import { DropdownButton, Dropdown } from 'react-bootstrap';

// Internal Libraries
import { getSelectedAccount } from 'store/selectors/accounts';
import { trackEvent } from 'services/vitally';
import queries from 'services/graphql/queries';

// Internal Components
import MetricDateRangePicker from 'components/shared/metricDateRange';
import { SegmentsTableWithRouter } from 'components/shared/insights';
import { AudienceTableWithRouter } from 'components/shared/insights';
import MetricDownloadModal from 'components/shared/metricDownloadModal';

// Internal Assets
import insightsLogo from 'assets/logos/casted-insights.svg';

const Insights = (props) => {
	const { selectedAccount } = props;

	const today = startOfDay(new Date());

	const [dateRange, setDateRange] = useState({
		start: format(addDays(today, -8), 'yyyy-MM-dd'),
		end: format(addDays(today, -1), 'yyyy-MM-dd'),
	});
	const [comparisonDateRange, setComparisonDateRange] = useState({
		start: format(addDays(today, -16), 'yyyy-MM-dd'),
		end: format(addDays(today, -9), 'yyyy-MM-dd'),
	});
	const [isExportModalOpen, setIsExportModalOpen] = useState(false);
	const [exportType, setExportType] = useState('');

	const [getSegmentsExportData] = useLazyQuery(queries.exportSegmentsMetrics, {
		variables: {
			accountId: selectedAccount.id,
			startDate: dateRange.start,
			endDate: dateRange.end,
		},
		fetchPolicy: 'no-cache',
	});

	const [getAudienceExportData] = useLazyQuery(queries.exportAudienceMetrics, {
		variables: {
			accountId: selectedAccount.id,
			startDate: dateRange.start,
			endDate: dateRange.end,
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		trackEvent('view-insights');
	}, []);

	const handleDateChange = (newRange) => {
		setDateRange({
			start: newRange.start,
			end: newRange.end,
		});

		setComparisonDateRange({
			start: newRange.comparableStart,
			end: newRange.comparableEnd,
		});
	};

	const handleSegmentExport = () => {
		setExportType('Segment');
		setIsExportModalOpen(true);
		getSegmentsExportData();
	};

	const handleAudienceExport = () => {
		setExportType('Audience');
		setIsExportModalOpen(true);
		getAudienceExportData();
	};

	return (
		<div className={`insights-page`}>
			<div className="insights-page-header-row">
				<div className="insights-row">
					<img src={insightsLogo} width={212} />
					<DropdownButton
						title={'DOWNLOAD CSV FILE'}
						className="export-menu insights"
						variant="success"
					>
						<Dropdown.Item onClick={() => handleSegmentExport()}>
							Segments
						</Dropdown.Item>
						<Dropdown.Item onClick={() => handleAudienceExport()}>
							Audience
						</Dropdown.Item>
					</DropdownButton>
				</div>
				<MetricDateRangePicker
					accountCreatedOn={selectedAccount.createdOn}
					dateRange={dateRange}
					onChange={handleDateChange}
				/>
			</div>
			<div className="insights-page-body">
				<SegmentsTableWithRouter dateRange={dateRange} />
				<hr></hr>
				<AudienceTableWithRouter dateRange={dateRange} />
			</div>

			<MetricDownloadModal
				title={`Exporting ${exportType} Metrics`}
				isOpen={isExportModalOpen}
				onClose={() => {
					setIsExportModalOpen(false);
					setExportType('');
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions.accountRole,
	modals: state.ui.modals,
});

export const InsightsWithRouter = withRouter(
	connect(mapStateToProps)(Insights)
);
