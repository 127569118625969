import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';

// External Components
import { FaChevronLeft } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

// Internal Components
import PlaylistHeader from './playlistHeader';
import PlaylistList from './playlistList';
import { PlaylistSearchWithRouter } from './playlistSearch';
import { ShareContentModal } from 'components/shared';

// Internal Libraries
import queries from 'services/graphql/queries';
import { dispatch } from 'store/store';
import { getSelectedAccount } from 'store/selectors/accounts';
import { setPlaylist, clearPlaylistStore } from 'store/reducers/data/playlist';
import { DIALOGS } from 'store/dialogs';
import { hidePlaylistShareModal } from 'store/reducers/ui/modals/playlistShareModal';
import { SHARE_MODAL_TYPE } from 'components/constants';

const Playlist = (props) => {
	const { match, history, selectedAccount, playlist, editPlaylist, modals } =
		props;

	const { params } = match;
	const { accountId, playlistId } = params;

	/** Queries & Mutations **/
	const { data, loading } = useQuery(queries.getPlaylistById, {
		variables: {
			accountId: parseInt(accountId, 10),
			playlistId: playlistId,
		},
		fetchPolicy: 'no-cache',
	});
	const playlistData = data?.playlist || {};

	useEffect(() => {
		if (!loading) {
			if (Object.keys(playlistData).length > 0) {
				dispatch(setPlaylist(playlistData));
			}
		}
	}, [loading, playlistData]);

	useEffect(() => {
		dispatch(clearPlaylistStore());
	}, []);

	let breadcrumbText = 'Playlist Management';

	if (history.location?.state?.from) {
		if (history.location.state.from.indexOf('shows') !== -1) {
			breadcrumbText = `Content ${history.location?.state?.from
				?.split('/')
				.pop()}`;
		}
		if (history.location.state.from.indexOf('insights') !== -1) {
			breadcrumbText = `${history.location?.state?.from
				?.split('/')
				.pop()}`;
		}
	}

	if (loading && Object.keys(playlist).length > 0) {
		return (
			<div className="playlist-loading-container">
				<Spinner
					className="playlist-loading"
					animation="grow"
					variant="info"
				/>
			</div>
		);
	}

	// Sharing Urls
	const publicUrl = playlistData?.customDomain
		? `https://${playlistData.customDomain}`
		: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;
	const shareUrl = `${publicUrl}/public/${accountId}/playlist/${playlistData?.slug}`;

	if (loading) {
		return (
			<div className="playlist-loading-container">
				<Spinner
					className="playlist-loading"
					animation="grow"
					variant="info"
				/>
			</div>
		);
	}

	return (
		<>
			<Prompt
				when={!loading && editPlaylist}
				message={JSON.stringify({
					dialog: DIALOGS.PLAYLIST_UNSAVED_CHANGES,
				})}
			/>
			<div className="playlist-container">
				<div className="playlist-nav">
					<h2 onClick={() => history.goBack()}>
						<FaChevronLeft className="arrow-left" />{' '}
						{breadcrumbText}
					</h2>
				</div>
				<div className="playlist-body">
					<PlaylistHeader />
					<PlaylistList
						accountId={accountId}
						playlistId={playlistId}
					/>
					{editPlaylist && <PlaylistSearchWithRouter />}
				</div>
			</div>
			<ShareContentModal
				isOpen={modals.playlistShareModal.showPlaylistModal}
				onClose={() => {
					dispatch(hidePlaylistShareModal());
				}}
				baseUrl={publicUrl}
				title="Share Playlist"
				shareContentName={'Share Playlist'}
				shareModalType={SHARE_MODAL_TYPE.PLAYLIST}
				contentSlug={playlistData?.slug}
				shareUrl={shareUrl}
				videoEnabled={selectedAccount.enableVideo}
				name={playlist.name}
				shareId={playlist.id}
				linkText="Create a direct link to your playlist via a specialized URL"
				embedText="Embed a playlist on your website. The Playlist Name will be displayed with your playlist."
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	playlist: state.playlistData.playlist,
	playlistUpdates: state.playlistData.playlistUpdates,
	editPlaylist: state.ui.playlists.editPlaylist,
	modals: state.ui.modals,
});

export default withRouter(connect(mapStateToProps)(Playlist));
