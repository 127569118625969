import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { Sticky } from 'react-sticky';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { showUploadWizardOnLoad } from 'store/reducers/ui/modals/uploadWizardModal';
import { dispatch } from 'store/store';

import { Modal } from 'react-bootstrap';

import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import SyncingEpisodes from 'components/shows/syncingEpisodes';
import ShowDetailHeader from './showDetailHeader';
import EpisodesList from 'components/shows/episodes/episodesList';
import LoadingShow from 'components/shared/loadingSkeletons/loadingShow';
import Toast from 'services/toast';
import { PODCAST_COLLECTION_TYPE } from 'components/constants';
import poweredByCasted from 'assets/images/poweredByCasted.svg';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const Episodes = (props) => {
	const { match, location, permissions } = props;
	const { params } = match;
	const { showId, accountId } = params;
	const { newShow } = queryString.parse(location.search);

	const episodePermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.EPISODES, showId)
				.rolePermission
		: {};

	// HOOKS
	const [episodeFilter, setEpisodeFilter] = useState(null);
	const [isSyncing, setIsSyncing] = useState(false);
	const [isSyncingModalOpen, setIsSyncingModalOpen] = useState(false);
	const [refetchEpisodes, setRefetchEpisodes] = useState(0);
	const [youtubeIntegration, setYoutubeIntegration] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	// END HOOKS

	// Queries
	const {
		data,
		loading: podcastLoading,
		error: podcastError,
		startPolling,
		stopPolling,
		refetch,
	} = useQuery(queries.podcastById, { variables: { podcastId: showId } });
	const { podcast: show } = data || {};
	const {
		name,
		episodeCount,
		status,
		feedUrl,
		featuredEpisode,
		slug,
		externalId,
		customDomain,
		collectionType,
		disableLandingPages
	} = show || {};

	let {thumbnail = ''} = show || {};


	if(!thumbnail){
		thumbnail = poweredByCasted;
	   }

	const { loading: integrationLoading, error: integrationError } = useQuery(
		queries.podcastIntegrations,
		{
			variables: { podcastId: showId, integrationName: 'youtube' },
			skip: !showId,
			onCompleted: (data) => {
				const { podcastIntegrations = [] } = data || [];
				setYoutubeIntegration(podcastIntegrations[0]);
			},
		}
	);

	const [createEpisode, { data: newEpisodeData }] = useMutation(
		mutations.createEpisode,
		{
			variables: {
				accountId,
				episode: {},
			},
		}
	);

	const isShowHostedWithCasted = externalId ? true : false;
	// END Queries

	const openSyncingModal = () => {
		startPolling(500);
		setIsSyncing(true);
		setIsSyncingModalOpen(true);
	};

	// If url param is present on page load, this is a new show
	// If from RSS we need to buy time to load episodes
	useEffect(() => {
		if (newShow) openSyncingModal();
	}, []);

	// Handle Syncing Modal
	useEffect(() => {
		// If podcast has state loading, open modal
		if (status === 'loading') {
			openSyncingModal();
		}
		// done = all episodes have been indexed and are starting download, transcribe
		if (status === 'active') {
			stopPolling();
			setTimeout(() => {
				if (isSyncing) {
					setIsSyncing(false);
					// refetch so we get an accurate episode count
					if (refetch) refetch();
				}
			}, 1500);
			// remove ?newShow param from url
			if (newShow)
				props.history.push(
					`/account/${accountId}/shows/${show.id}/episodes`
				);
		}
	}, [status]);

	useEffect(() => {
		if (podcastLoading || integrationLoading) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}
	}, [podcastLoading, integrationLoading]);

	if (!accountId) {
		return null;
	}

	if (isLoading) {
		return <LoadingShow />;
	}

	if (podcastError) {
		Sentry.captureException(podcastError);
	}

	if (integrationError) {
		Sentry.captureException(integrationError);
	}

	const createEpisodeAndNavigate = async (uploadType = 'audio') => {
		try {
			let res = await createEpisode({
				variables: {
					accountId: parseInt(accountId),
					episode: {
						podcastId: showId,
						name: '',
						storageLink: '',
						description: '',
						makePublic: true, // TODO: this should probably be configurable in the UI,
						keywords: [],
						uploadType: uploadType,
						type: 'full',
					},
				},
			});
			const { data } = res;
			const { createEpisode: createdEpisode } = data;

			if (createdEpisode) {
				dispatch(showUploadWizardOnLoad());

				props.history.push(
					`/account/${accountId}/shows/${show.id}/episodes/${createdEpisode.id}/info`
				);
			}
		} catch (e) {
			console.error(e);
			Toast.error('Unable to upload episode');
		}
	};

	if (show.collectionType === PODCAST_COLLECTION_TYPE.one_off) {
		return (
			<Redirect
				to={{
					pathname: `/account/${accountId}/allContent/managedContent`,
				}}
			/>
		);
	} else {
		return (
			<div className="show-details">
				<ShowDetailHeader
					podcast={show}
					filter={episodeFilter}
					setFilter={setEpisodeFilter}
					episodeCount={episodeCount}
					canEdit={episodePermission.canEdit}
					addEpisodeButtonAction={async (uploadType) => {
						createEpisodeAndNavigate(uploadType);
					}}
					isShowHostedWithCasted={isShowHostedWithCasted}
				/>
				<Sticky relative>
					{({ style, isSticky }) => {
						if (!isSticky) {
							return <span></span>;
						}

						return (
							<div
								style={style}
								className="show-details-sticky-header"
							>
								<img
									src={thumbnail}
									alt={`${name} thumbnail`}
									className="show-details-thumbnail"
								/>
								<span className="show-details-name">
									{name}
								</span>
							</div>
						);
					}}
				</Sticky>

				<EpisodesList
					showId={showId}
					showStatus={status}
					showSlug={slug}
					showIsHosted={isShowHostedWithCasted}
					filter={episodeFilter}
					featuredEpisode={featuredEpisode}
					showThumbnail={thumbnail}
					refetchEpisodes={refetchEpisodes}
					customDomain={customDomain}
					showExternalId={externalId}
					youtubeIntegration={youtubeIntegration}
					collectionType={collectionType}
					disableLinkSharing={disableLandingPages}
				/>

				<Modal
					show={isSyncingModalOpen}
					onHide={() => {
						setIsSyncingModalOpen(false);
					}}
					centered
				>
					<SyncingEpisodes
						syncing={isSyncing}
						closeCallback={() => setIsSyncingModalOpen(false)}
					/>
				</Modal>
			</div>
		);
	}
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
});

export default withRouter(connect(mapStateToProps)(Episodes));
