export const ACTIONS = {
	SHOW_MODAL: 'CASTED/DISCONNECT_ACCOUNT_INTEGRATION_MODAL/SHOW_MODAL',
	HIDE_MODAL: 'CASTED/DISCONNECT_ACCOUNT_INTEGRATION_MODAL/HIDE_MODAL',
};

export const initialState = {
	showModal: false,
	integrationToDisconnect: null,
};

export const hideDisconnectAccountIntegrationModal = () => {
	return {
		type: ACTIONS.HIDE_MODAL,
	};
};

export const showDisconnectAccountIntegrationModal = (
	integrationToDisconnect
) => {
	return {
		type: ACTIONS.SHOW_MODAL,
		payload: integrationToDisconnect,
	};
};

const disconnectAccountIntegrationModalReducer = (
	state = initialState,
	action
) => {
	const { type, payload } = action;

	switch (type) {
		case ACTIONS.HIDE_MODAL:
			return {
				...state,
				showModal: false,
				integrationToDisconnect: null,
			};
		case ACTIONS.SHOW_MODAL:
			return {
				...state,
				showModal: true,
				integrationToDisconnect: payload,
			};
		default:
			return state;
	}
};

export default disconnectAccountIntegrationModalReducer;
