import React from 'react'

import { SmallBox, HorizontalLine, ThreeHorizontalLines  } from 'components/shared/loadingSkeletons/skeleton'

const LoadingShowHeader = (/* props */) => {

  return (
    <div className="show-details">
      
      <div className="show-detail-header-container" style={{marginBottom: '3rem'}}>
        <div className="show-details-row" >
          <div className="show-details-thumbnail">
           <SmallBox  style={{width: '177px', height: '177px', backgroundSize: "800px 100%"}}/>
           </div>
          <div className="show-details-info">
            <div className="show-details-info-left">
              <HorizontalLine style={{height: '1.5rem', width: '100%'}}/>
              <ThreeHorizontalLines/>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default LoadingShowHeader
