export const ACTIONS = {
	SET_PLAYLIST: 'CASTED/SET_PLAYLIST',
	SET_PLAYLIST_CONTENT: 'CASTED/SET_PLAYLIST_CONTENT',
	SET_PLAYLIST_UPDATES: 'CASTED/SET_PLAYLIST_UPDATES',
	SET_SHOW_SHARE_MODAL_ON_SAVE: 'CASTED/SET_SHOW_SHARE_MODAL_ON_SAVE',
	CLEAR_PLAYLIST_STORE: 'CASTED/CLEAR_PLAYLIST_STORE',
};

const initialState = {
	playlist: {},
	playlistContent: [],
	playlistUpdates: {},
	showShareModalOnSave: false,
};

export const setPlaylist = (playlist) => {
	return {
		type: ACTIONS.SET_PLAYLIST,
		playlist,
	};
};

export const setPlaylistContent = (playlistContent) => {
	return {
		type: ACTIONS.SET_PLAYLIST_CONTENT,
		playlistContent,
	};
};

export const setPlaylistUpdates = (playlistUpdates) => {
	return {
		type: ACTIONS.SET_PLAYLIST_UPDATES,
		playlistUpdates,
	};
};

export const setShowShareModalOnSave = (showShareModalOnSave) => {
	return {
		type: ACTIONS.SET_SHOW_SHARE_MODAL_ON_SAVE,
		showShareModalOnSave,
	};
};

export const clearPlaylistStore = () => {
	return {
		type: ACTIONS.CLEAR_PLAYLIST_STORE,
	};
};

const playlistReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SET_PLAYLIST:
			return {
				...state,
				playlist: action.playlist,
			};
		case ACTIONS.SET_PLAYLIST_CONTENT:
			return {
				...state,
				playlistContent: action.playlistContent,
			};
		case ACTIONS.SET_PLAYLIST_UPDATES:
			return {
				...state,
				playlistUpdates: action.playlistUpdates,
			};
		case ACTIONS.SET_SHOW_SHARE_MODAL_ON_SAVE:
			return {
				...state,
				showShareModalOnSave: action.showShareModalOnSave,
			};
		case ACTIONS.CLEAR_PLAYLIST_STORE:
			return {
				...state,
				playlist: initialState.playlist,
				playlistContent: initialState.playlistContent,
				playlistUpdates: initialState.playlistUpdates,
			};
		default:
			return state;
	}
};

export default playlistReducer;
