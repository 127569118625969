import React, { useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';

import queries from 'services/graphql/queries';
import ShowNav from 'components/sidebar/nav/showNav';
import { getContentType, CONTENT_TYPE } from 'components/constants';

const NavShowElement = (props) => {
	const { permissions, match } = props;

	const { params, path } = match;
	const { showId, accountId } = params;

	const podcastResult = useQuery(queries.podcastById, {
		variables: { podcastId: showId },
	});
	const { podcast = {} } = podcastResult.data || {};

	const episodesResult = useQuery(queries.episodesByPodcastId, {
		variables: {
			podcastId: showId,
			limit: 1,
			offset: 0,
		},
	});
	const { episodes = [] } = episodesResult.data || {};

	const contentType =
		getContentType(podcast.collectionType) || CONTENT_TYPE.episode;

	let navLinkUrl = '';

	switch (contentType) {
		case CONTENT_TYPE.item:
			if (episodes.length > 0) {
				navLinkUrl = `/account/${accountId}/shows/${showId}/episodes/${episodes[0].id}`;
			}
			break;
		case CONTENT_TYPE.episode:
		default:
			navLinkUrl = `/account/${accountId}/shows/${showId}/episodes`;
			break;
	}

	if (podcastResult.error || episodesResult.error) {
		return null;
	}

	return podcast ? (
		<>
			<NavLink to={navLinkUrl} className="sidebar-option-link">
				{contentType !== CONTENT_TYPE.episode
					? podcast.name
						? podcast.name
						: `${contentType} Name`
					: podcast.name}
			</NavLink>
			<ShowNav
				accountId={parseInt(accountId, 10)}
				podcastId={showId}
				location={path}
				permissions={permissions}
			/>
		</>
	) : (
		<span></span>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
});

export default withRouter(connect(mapStateToProps)(NavShowElement));
