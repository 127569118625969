import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import MetricEmptyState from 'components/shared/metricEmptyState';
import BaseTable from 'components/shared/baseTable';
import { numberSort } from 'utils';

const ResourceMetrics = (props) => {
	const { variables } = props;

	const { data, loading } = useQuery(queries.getEpisodeTopResources, {
		variables,
		fetchPolicy: 'cache-and-network',
	});
	const { getTopResources: resources = [] } = data || {};

	const values = resources.map((resource) => {
		return {
			id: resource.id,
			name: resource.resource_name,
			data: [resource.total_clicks],
		};
	});

	const columns = [
		{
			name: 'Resource',
			selector: (row) => row.name,
			sortable: false,
		},
		{
			name: 'Clicks',
			selector: (row) => parseInt(row.data[0]),
			sortable: true,
			sortFunction: numberSort((row) => row.data[0]),
		},
	];

	return (
		<BaseTable
			values={values || []}
			columns={columns}
			loading={loading}
			noDataComponent={<MetricEmptyState metricName="Top Resources" />}
			title="Top Resources"
		/>
	);
};

export default ResourceMetrics;
