import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import { NavLink } from 'react-router-dom';
import { checkLastUrlSegment } from 'utils/';
import { ListGroup } from 'react-bootstrap';
import {
	FaMicrophone,
	FaPencilRuler,
	FaCog,
	FaRegWindowMaximize,
	FaShare,
	FaChartLine,
	FaBullhorn,
} from 'react-icons/fa';
import * as Sentry from '@sentry/browser';
import { PODCAST_COLLECTION_TYPE } from 'components/constants';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ShowNav = (props) => {
	const { accountId, podcastId, location = null, permissions } = props;

	const { featurePromotions } = useFlags();

	const showPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.SHOWS, podcastId)
				.rolePermission
		: {};
	const metricPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.METRICS, podcastId)
				.rolePermission
		: {};
	const episodePermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.EPISODES, podcastId)
				.rolePermission
		: {};

	const { data, error } = useQuery(queries.podcastById, {
		variables: { podcastId },
	});
	const { podcast = {} } = data || {};

	if (error) {
		console.log(error);
		Sentry.captureException(error);
		return null;
	}

	const url = podcast.customDomain
		? `https://${podcast.customDomain}/public/${accountId}/${podcast.slug}/`
		: `${process.env.REACT_APP_PUBLIC_APP_ROOT}/public/${accountId}/${podcast.slug}/`;

	return (
		<ListGroup className="show-nav" variant="flush">
			{podcast.collectionType !== PODCAST_COLLECTION_TYPE.one_off && (
				<ListGroup.Item
					className={`${
						checkLastUrlSegment(location, 'episodes')
							? 'show-nav--active'
							: ''
					}`}
				>
					<NavLink
						to={`/account/${accountId}/shows/${podcastId}/episodes`}
					>
						<FaMicrophone /> Items
					</NavLink>
				</ListGroup.Item>
			)}

			{metricPermission.canView &&
				podcast.collectionType !== PODCAST_COLLECTION_TYPE.one_off && (
					<ListGroup.Item
						className={`${
							checkLastUrlSegment(location, 'metrics')
								? 'show-nav--active'
								: ''
						}`}
					>
						<NavLink
							to={`/account/${accountId}/shows/${podcastId}/metrics`}
						>
							<FaChartLine /> Metrics
						</NavLink>
					</ListGroup.Item>
				)}

			<ListGroup.Item className={`show-nav--external`}>
				<a href={url} target="_blank">
					<FaRegWindowMaximize /> View Page{' '}
					<FaShare className="hover-icon" />
				</a>
			</ListGroup.Item>

			{showPermission.canEdit && (
				<ListGroup.Item
					className={`${
						checkLastUrlSegment(location, 'settings')
							? 'show-nav--active'
							: ''
					}`}
				>
					<NavLink
						to={`/account/${accountId}/shows/${podcastId}/settings`}
					>
						<FaPencilRuler /> Page Design
					</NavLink>
				</ListGroup.Item>
			)}

			{featurePromotions && episodePermission.canView && (
				<ListGroup.Item
					className={`${
						checkLastUrlSegment(location, 'promotions')
							? 'show-nav--active'
							: ''
					}`}
				>
					<NavLink
						to={`/account/${accountId}/shows/${podcastId}/promotions`}
					>
						<FaBullhorn /> Promotions
					</NavLink>
				</ListGroup.Item>
			)}

			{showPermission.canEdit && (
				<ListGroup.Item
					className={`${
						checkLastUrlSegment(location, 'admin')
							? 'show-nav--active'
							: ''
					}`}
				>
					<NavLink
						to={`/account/${accountId}/shows/${podcastId}/admin`}
					>
						<FaCog /> Manage Collection
					</NavLink>
				</ListGroup.Item>
			)}
		</ListGroup>
	);
};

export default ShowNav;
