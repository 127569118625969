import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import moment from 'moment-timezone';
import mutations from 'services/graphql/mutations';
import { getAuthSingleton } from 'services/auth';
import { Dropdown, Row, Col } from 'react-bootstrap';
import { AiOutlineEllipsis } from 'react-icons/ai';
import initials from 'initials';
import Toast from 'services/toast';

import ConfirmationModal from 'components/shared/confirmationModal';
import UserModal from 'components/users/userModal';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const UserListRow = (props) => {
	const authSvc = getAuthSingleton();
	// Props
	const {
		user,
		accountId,
		permissions,
		currentRoles,
		accountUserData,
		refreshUsers = () => {},
	} = props;

	const { id: userId, firstName, lastName } = user;

	const {
		inviteCode,
		invitedOn,
		status: confirmedStatus,
	} = accountUserData[0];

	const roleName = currentRoles ? currentRoles[0].name : 'Unassigned';
	const roleIdValue = currentRoles ? currentRoles[0].id : '';

	const userPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.USERS).rolePermission
		: {};

	let confirmationStatus = '';
	let inviteExpired = false;
	if (inviteCode && confirmedStatus === 2) {
		if (
			moment(accountUserData[0].invitedOn) < moment().subtract(14, 'days')
		) {
			confirmationStatus = 'Invitation expired';
			inviteExpired = true;
		} else {
			confirmationStatus = 'Invitation pending';
		}
	}

	const loggedInUser = authSvc.getUserId();
	const allowUserRemove = parseInt(loggedInUser.userId) !== userId;

	// State Hooks
	const [editUser, setEditUser] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	/**
	 * Refs
	 */
	// Hold actions in a DOM ref so we can protect it's click from row click
	const userActions = useRef(null);

	// Mutations
	const [disableAccountUser] = useMutation(mutations.disableAccountUser, {
		refetchQueries: ['accountUsers'],
	});

	// Helpers
	const resendInvite = async (user, accountId) => {
		try {
			await authSvc.inviteUser(user, accountId);
			Toast.success('Invitation sent');
		} catch (e) {
			console.log(e);
			Toast.error('Error sending invitation, please try again');
		}
	};

	const removeUser = async () => {
		await disableAccountUser({
			variables: {
				userId: user.id,
				accountId,
			},
		});
		setConfirmDelete(false);
		Toast.success('User removed');
	};

	// Render
	return (
		<div className="user-list--user">
			<Row className="user--row">
				<Col xs="1" className="user-row--thumbnail">
					<div className="user-list--avatar-initials">
						{user.avatarUrl ? (
							<div
								className="user-list--avatar-thumbnail"
								style={{
									backgroundImage: `url(${user.avatarUrl})`,
								}}
							></div>
						) : (
							<div className="user-list--avatar-thumbnail initials d-flex justify-content-center align-items-center">
								{initials(`${firstName} ${lastName}`)}
							</div>
						)}
					</div>
				</Col>
				<Col xs="3" className="user-row--name pl-3">
					<div>{`${firstName} ${lastName}`}</div>
				</Col>
				<Col className="user-row--role">
					<div>{roleName}</div>
				</Col>
				<Col>
					<div className={inviteExpired ? 'expired' : ''}>
						{confirmationStatus}
					</div>
				</Col>
				<Col xs="auto" className="user-row--actions" ref={userActions}>
					<Dropdown
						className={`user--actions ${
							userPermission.canEdit ? '' : 'hidden'
						}`}
					>
						<Dropdown.Toggle id="user-actions" as="div">
							<AiOutlineEllipsis fontSize={20} />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									setEditUser(true);
								}}
							>
								Edit
							</Dropdown.Item>
							{inviteCode && (
								<Dropdown.Item
									onClick={() => {
										resendInvite(user, accountId);
									}}
								>
									Resend invitation
								</Dropdown.Item>
							)}
							{allowUserRemove && (
								<Dropdown.Item
									onClick={() => {
										setConfirmDelete(true);
									}}
								>
									Remove
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</Col>
			</Row>

			<UserModal
				title={'Edit User Information'}
				edit={true}
				isOpen={editUser}
				userData={user}
				onHide={() => {
					setEditUser(false);
				}}
				accountId={accountId}
				roleIdValue={roleIdValue}
				refreshUsers={refreshUsers}
			/>

			<ConfirmationModal
				isOpen={confirmDelete}
				title="Remove User"
				confirmationText="Are you sure you want to remove this user? They will no longer be able to access this account."
				confirmationButtonLabel="REMOVE USER"
				onConfirm={removeUser}
				onCancel={() => {
					setConfirmDelete(false);
				}}
			/>
		</div>
	);
};

export default UserListRow;
