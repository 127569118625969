export const ACTIONS = {
	SET_STATE: 'CASTED/NEW_CONTENT_MODAL/SET_STATE',
};

export const ContentModalChoicesEnum = Object.freeze({
	COLLECTION: 1,
	COLLECTION_NON_SERIES: 2,
	COLLECTION_SERIES_IMPORT: 3,
	COLLECTION_SERIES_MANUAL: 4,
	ONE_OFF_MANUAL: 5,
	COLLECTION_PERMISSIONS: 6,
});

export const ContentModalConstants = {
	[ContentModalChoicesEnum.COLLECTION]: {
		title: 'New Collection',
		state: 'CASTED/CREATE_COLLECTION',
	},
	[ContentModalChoicesEnum.COLLECTION_NON_SERIES]: {
		title: 'New Non-series Collection',
		state: 'CASTED/COLLECTION_NON_SERIES',
	},
	[ContentModalChoicesEnum.COLLECTION_SERIES_IMPORT]: {
		title: 'Import Existing Collection',
		state: 'CASTED/COLLECTION_SERIES_IMPORT',
	},
	[ContentModalChoicesEnum.COLLECTION_SERIES_MANUAL]: {
		title: 'New Series collection',
		state: 'CASTED/COLLECTION_SERIES_MANUAL',
	},
	[ContentModalChoicesEnum.ONE_OFF_MANUAL]: {
		title: 'New Item',
		state: 'CASTED/ONE_OFF_MANUAL',
	},
	[ContentModalChoicesEnum.COLLECTION_PERMISSIONS]: {
		title: 'Collection Permissions',
		state: 'CASTED/COLLECTION_PERMISSIONS',
	},
};

const initialState = {
	...ContentModalConstants.COLLECTION,
};

export const setNewContentModalState = (newState) => {
	return {
		type: ACTIONS.SET_STATE,
		payload: newState,
	};
};

const newContentModalReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ACTIONS.SET_STATE:
			return {
				...state,
				...ContentModalConstants[payload.modal],
				...payload.extra,
			};
		default:
			return state;
	}
};

export default newContentModalReducer;
