import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { FaCog, FaShare } from 'react-icons/fa';

import TableConfirmationButton from 'components/shared/tableConfirmationButton';

const PlaylistActions = (props) => {
	const { row, accountId, onRemove, onShare, canEdit, history } = props;

	return (
		<>
			<Link
				to={{
					pathname: `/account/${accountId}/allContent/playlists/${row.id}`,
					state: { from: history.location.pathname },
				}}
				className="playlist-action"
			>
				<FaCog icon="fa-solid fa-cog" className="toggle-icon" />
				View Playlist
			</Link>
			<div
				className={`playlist-action ${
					!row.content || row.content.length === 0 ? 'disabled' : ''
				}`}
				onClick={() => onShare(row)}
			>
				<FaShare icon="fa-solid fa-share" className="toggle-icon" />
				Share
			</div>
			{canEdit && (
				<TableConfirmationButton
					row={row}
					onRemove={onRemove}
					action={'Delete'}
					dialog={
						<span>
							Deleting <strong>"{row.name}"</strong> playlist will remove it
							permanently.
						</span>
					}
					rowId={row.id}
				/>
			)}
		</>
	);
};

export default withRouter(PlaylistActions);
