import React, { useEffect, useState, useRef } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.scss';

const SelectList = (props) => {
    const {
        name = '',
        options = [{
            value: {
                name: 'Select a value'
            },
            selected: false
        }],
        onSelect = (value) => {},
        onRemove = (value) => {},
    } = props;

    const selectRef = useRef(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);

    // Detects clicks outside select in order to close select list
    useEffect(() => {
        function handleClickOutside(event) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                if(isDropdownOpen) {
                    setIsDropdownOpen(false);
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectRef, isDropdownOpen]);

    const onOptionClick = (option) => {
        if(selectedOption && options.find((x) => {
            return (x.value.name === selectedOption.value.name && x.selected);
        })){
            onRemove(selectedOption.value);
        }
        setSelectedOption(option);
        setIsDropdownOpen(false);
        onSelect(option.value);
    };

	return (
        <div className={styles.filterSelect}>
            {name && (<h3>{name}</h3>)}
            <div ref={selectRef} className={styles.selectDropdown}>
                <div className={styles.selectedValue} onClick={() => setIsDropdownOpen(true)}>{selectedOption.value.name} <FontAwesomeIcon icon={faCaretDown} size="sm" /></div>
                <ul className={`${styles.selectOptions} ${!isDropdownOpen ? styles.hide : ''}`}>
                    {
                        options && options.map((option, key) => {
                            return (
                                <li 
                                    key={key} 
                                    onClick={() => onOptionClick(option)}
                                >{option.value.name}</li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
	);
};

export default SelectList;