import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/shared/button';
import fourOhFourLogo from 'assets/404.png';

const FourOhFour = (props) => {
	return (
		<div className="four-oh-four">
			<div className="fof-topbar">
				<NavLink to="/" className="ab-fof-home-btn">
					<Button
						name="404-go-home-logo-btn"
						className="fof-go-home-logo-btn"
						variant="flat"
					>
						Casted
					</Button>
				</NavLink>
			</div>

			<div className="fof-content">
				<div className="fof-content-left">
					<h1 className="fof-headline">Oops!</h1>
					<p className="fof-message">
						Podcat can't seem to find the page you're looking for.
					</p>
					<NavLink to="/" className="ab-fof-home-btn">
						<Button
							name="404-go-home-btn"
							className="fof-go-home-btn"
							variant="contained"
						>
							<span className="fof-go-home-text">Return to the homepage</span>
						</Button>
					</NavLink>
				</div>
				<div className="fof-content-right">
					<img
						src={fourOhFourLogo}
						alt="404 Illustration"
						className="fof-illustration"
					/>
				</div>
			</div>
		</div>
	);
};

export default FourOhFour;
