import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import mutations from 'services/graphql/mutations';
import ThumbnailUploader from 'components/shared/thumbnailUploader';
import API from 'services/api';
import TextArea from 'components/shared/textArea';
import TextInput from 'components/shared/textInput';
import Button from 'components/shared/button';
import queries from 'services/graphql/queries';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';
import { PODCAST_COLLECTION_TYPE } from 'components/constants';

const NewShowManualStep = (props) => {
	const {
		match,
		hidden,
		onRSSClick,
		onCloseClick,
		collectionType = PODCAST_COLLECTION_TYPE.serial,
	} = props;
	const { params } = match;
	const { accountId } = params;

	// HOOKS
	const [thumbnailSrc, setThumbnailSrc] = useState(null);
	const [showThumbnailUrl, setShowThumbnailUrl] = useState(null);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');

	// const [keywords, setKeywords] = useState([])
	// END HOOKS

	// nonSeries removes rss related UI and creates podcast records with no rss feed
	const nonSeries = !(collectionType === PODCAST_COLLECTION_TYPE.serial);

	const onThumbnailUpload = async (imgDataUrl, fileExt) => {
		setThumbnailSrc(imgDataUrl);

		try {
			const gcsUrl = await API.uploadPublicFile({
				dataURI: imgDataUrl,
				fileExt: fileExt,
			});
			setShowThumbnailUrl(gcsUrl);
		} catch (e) {
			console.error(e);
			setShowThumbnailUrl('');
			setThumbnailSrc('');
			Toast.error(
				typeof e.message  === 'string' ? e.message : 'Something went wrong!'
			);
			Sentry.captureException(e);
		}
	};

	return (
		<Mutation
			mutation={mutations.createHostedPodcast}
			refetchQueries={() => {
				return [
					{
						query: queries.podcastsByAccountId,
						variables: { accountId: parseInt(accountId, 10) },
					},
				];
			}}
		>
			{(createPodcast, { data, loading, error }) => {
				const saveAndQuit = async () => {
					try {
						const showToCreate = {
							name,
							description,
							accountId: parseInt(accountId, 10),
							thumbnail: showThumbnailUrl,
						};

						const podcast = await createPodcast({
							variables: {
								podcast: showToCreate,
								imageURI: thumbnailSrc,
								collectionType: collectionType,
							},
						});

						trackEvent('new-collection', {
							name,
							method: 'manual',
							type: collectionType,
						});

						if (onCloseClick) {
							onCloseClick(podcast.data.createHostedPodcast);
						}
					} catch (err) {
						console.error(err);
						Toast.error(
							'Oops! An error occurred saving the collection!'
						);
						Sentry.captureException(err);
					}
				};

				const gotoRss = () => {
					if (onRSSClick) {
						onRSSClick();
					}
				};

				return (
					<div
						className={`new-show-manual-step ${
							hidden ? 'hidden' : ''
						}`}
					>
						<div className="new-show-details-header-row">
							<div className="new-show-details-header">
								Add your collection information
							</div>
							<div className="new-show-details-description">
								Adding your collection manually will let you
								upload collection items individually.
							</div>
						</div>
						<div className="new-show-details-container">
							<div className="new-show-details-row">
								<div className="new-show-details-row-col">
									<label className="new-show-details-input-label">
										Collection Image
									</label>
									<div className="new-show-details-help-text">
										Image must be between 1400x1400 and
										3000x3000 pixels with a maximum size of
										2 MB.
									</div>
									<ThumbnailUploader
										thumbnailImgSrc={thumbnailSrc}
										onUpload={onThumbnailUpload}
										overlayMsg="Upload"
									/>
								</div>
								<div className="new-show-details-row-col row-col-large">
									<label className="new-show-details-input-label">
										Collection Name
									</label>
									<label
										className={`new-show-details-input-label ${
											name.length > 140 ? 'error' : null
										} `}
									>
										{name.length >= 140
											? '• Must be 140 characters or less'
											: null}
									</label>
									<TextInput
										name="new-show-details-name"
										label="Collection Name"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										onBlur={(e) =>
											setName(e.target.value.trim())
										}
										inputClassName="new-show-details-form-input"
										rootStyles={{ margin: '0 0 15px 0' }}
									/>
									<label className="new-show-details-input-label">
										Collection Description
									</label>
									<TextArea
										placeholder="Add a short description"
										onChange={(e) =>
											setDescription(e.target.value)
										}
										onBlur={(e) =>
											setDescription(
												e.target.value.trim()
											)
										}
										minRows={4}
										value={description}
										containerClassName="new-show-details-description-textarea"
									/>
								</div>
							</div>
						</div>
						<div className="new-show-actions-container">
							<div className="new-show-actions-row">
								<Button
									name="new-show-save-btn"
									onClick={saveAndQuit}
									className="new-show-save-btn"
									variant="light"
									title="Add Collection & Save"
									disabled={!name || name.length > 140}
								>
									Add Collection & Save
								</Button>
							</div>
							{!nonSeries && (
								<div className="new-show-actions-row">
									<Button
										name="new-show-rss-btn"
										onClick={gotoRss}
										className="new-show-rss-btn"
										variant="flat"
										title="I want to link my RSS feed"
									>
										I want to link my RSS feed
									</Button>
								</div>
							)}
						</div>
					</div>
				);
			}}
		</Mutation>
	);
};

export default withRouter(NewShowManualStep);
