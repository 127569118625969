import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';

import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { getAuthSingleton } from 'services/auth';
import { getSelectedAccount } from 'store/selectors/accounts';

import { Card, Form, Container, Row, Col } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import BaseTable from 'components/shared/baseTable';
import TablePermissionBadge from 'components/shared/tablePermissionBadge';
import TableRolesDropdown from 'components/shared/tableRolesDropdown';

const Permissions = (props) => {
	const { podcast, updatePodcast, collectionId, accountId, selectedAccount } =
		props;

	const authSvc = getAuthSingleton();
	const loggedInUser = authSvc.getUserId();
	const currentUserId = parseInt(loggedInUser.userId);

	const [collectionPermissions, setCollectionPermissions] = useState([]);
	const [filteredCollectionPermissions, setFilteredCollectionPermissions] =
		useState([]);
	const [collectionPermission, setCollectionPermission] = useState({});
	const [hasCollectionPermission, setHasCollectionPermission] =
		useState(false);

	const { loadingPermission, refetchPermission } = useQuery(
		queries.getCollectionPermissionByCollectionId,
		{
			variables: {
				accountId: parseInt(accountId, 10),
				collectionId,
				userId: null,
			},
			fetchPolicy: 'cache-and-network',
			onCompleted: (data) => {
				const permission = data.getCollectionPermissionByCollectionId;
				if (permission.length > 0) {
					setCollectionPermission(permission[0]);
					setHasCollectionPermission(true);
				}
			},
		}
	);

	const [getAccountUsers, { loadingUsers, refetch }] = useLazyQuery(
		queries.accountUsers,
		{
			variables: {
				accountId: parseInt(accountId, 10),
				sortField: 'firstName',
				sortOrder: 'ASC',
				includeInternalUsers: selectedAccount.name === 'Casted',
				includeCollectionPermissions: true,
				podcastId: collectionId,
			},
			fetchPolicy: 'cache-and-network',
			onCompleted: (data) => {
				const accountUsers = data.accountUsers;
				const accountUserPermissions = [];

				if (accountUsers.length > 0) {
					accountUsers.map((user) => {
						const permissions = user.collectionPermissions;
						const userPermission =
							permissions && permissions.length > 0
								? permissions[0]
								: null;

						let userObject = {
							id: userPermission ? userPermission.id : null,
							userId: user.id,
							name: user.firstName + ' ' + user.lastName,
							collectionId,
							email: user.email,
							accountRoleId: user.roles[0].id,
							roleId: userPermission
								? userPermission.roleId
								: collectionPermission.roleId,
						};

						accountUserPermissions.push(userObject);
					});
				}
				setCollectionPermissions(accountUserPermissions);
				setFilteredCollectionPermissions(accountUserPermissions);
			},
		}
	);

	const {
		data: userRolesData,
		loading: userRolesLoading,
		error: userRolesError,
	} = useQuery(queries.userRoles, {
		variables: { accountId: parseInt(accountId, 10) },
	});
	const { userRoles } = userRolesData || [];

	let rolesList = [];
	if (userRoles) {
		rolesList = userRoles.map((role) => {
			return role;
		});
	}

	useEffect(() => {
		if (!loadingPermission) {
			getAccountUsers();
		}
	}, [collectionPermission]);

	const [createCollectionPermission] = useMutation(
		mutations.createCollectionPermission
	);

	const [updateCollectionPermissionById] = useMutation(
		mutations.updateCollectionPermissionById
	);

	const [deleteCollectionPermissionById] = useMutation(
		mutations.deleteCollectionPermissionById
	);

	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let result = [];

		result = collectionPermissions.filter((user) => {
			const userName = user.name.toLowerCase();
			const userEmail = user.email.toLowerCase();
			return (
				userName.search(value) != -1 || userEmail.search(value) != -1
			);
		});
		setFilteredCollectionPermissions(result);
	};

	const updatePermissionSettings = async () => {
		const { data } = await updatePodcast({
			variables: {
				podcastId: collectionId,
				podcast: {
					accountId: parseInt(accountId, 10),
					collectionLevelPermissions:
						!podcast.collectionLevelPermissions,
				},
			},
		});

		const updatedCollectionPermission = data.updatePodcastById;
		if (updatedCollectionPermission.collectionLevelPermissions) {
			if (!hasCollectionPermission) {
				await createDefaultPermission();
				await createDefaultUserPermission();
			} else {
				const currentUser = collectionPermissions.find(
					(user) => user.userId === currentUserId
				);
				if (!currentUser.id) {
					await createDefaultUserPermission();
				}
			}
		}
	};

	const createDefaultPermission = async () => {
		const viewerRole = rolesList.find((role) => role.name === 'Viewer');

		const { data } = await createCollectionPermission({
			variables: {
				accountId: parseInt(accountId, 10),
				collectionPermission: {
					accountId: parseInt(accountId, 10),
					collectionId,
					roleId: viewerRole.id,
				},
			},
		});
		setCollectionPermission(data.createCollectionPermission);
		setHasCollectionPermission(true);
	};

	const createDefaultUserPermission = async () => {
		const adminRole = rolesList.find((role) => role.name === 'Admin');

		await createCollectionPermission({
			variables: {
				accountId: parseInt(accountId, 10),
				collectionPermission: {
					accountId: parseInt(accountId, 10),
					collectionId,
					roleId: adminRole.id,
					userId: currentUserId,
				},
			},
		});
		refetch();
	};

	const onUpdateRole = async (roleId, row) => {
		let permissions = [...collectionPermissions];
		let deletedPermission = false;
		let permissionId = row.id;

		if (permissionId) {
			if (roleId !== collectionPermission.roleId) {
				await updateCollectionPermissionById({
					variables: {
						accountId: parseInt(accountId, 10),
						collectionPermissionId: permissionId,
						updates: { roleId },
					},
				});
			} else {
				await deleteCollectionPermissionById({
					variables: {
						accountId: parseInt(accountId, 10),
						collectionPermissionId: permissionId,
					},
				});
				deletedPermission = true;
				permissionId = null;
			}
		} else {
			if (roleId !== collectionPermission.roleId) {
				const { data } = await createCollectionPermission({
					variables: {
						accountId: parseInt(accountId, 10),
						collectionPermission: {
							accountId: parseInt(accountId, 10),
							collectionId,
							roleId,
							userId: row.userId,
						},
					},
				});
				permissionId = data.createCollectionPermission.id;
			}
		}

		if (deletedPermission || roleId !== collectionPermission.roleId) {
			permissions = permissions.map((permission) => {
				if (permission.userId === row.userId) {
					permission.roleId = roleId;
					permission.id = permissionId;
				}
				return permission;
			});
			setCollectionPermissions(permissions);
		}
	};

	const permissionColumns = [
		{
			name: 'Name',
			cell: (row) => <div>{row.name}</div>,
			selector: (row) => row.name,
			sortable: true,
			minWidth: '300px',
		},
		{
			name: 'Email',
			cell: (row) => <div>{row.email}</div>,
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Role',
			cell: (row) => (
				<TablePermissionBadge
					roleId={row.roleId}
					isAccountAdmin={rolesList.find(
						(r) => r.id === row.accountRoleId && r.slug === 'admin'
					)}
					id={row.userId}
					rolesList={rolesList}
				/>
			),
			selector: (row) => {
				const role = rolesList.find((r) => r.id === row.roleId);
				const roleName = role ? role.name : 'No Access';
				return roleName;
			},
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<TableRolesDropdown
					row={row}
					rolesList={rolesList}
					onUpdateRole={onUpdateRole}
					disabled={
						currentUserId === row.userId ||
						rolesList.find(
							(r) =>
								r.id === row.accountRoleId && r.slug === 'admin'
						)
					}
				/>
			),
			style: { cursor: 'pointer' },
		},
	];

	const customStyles = {
		header: {
			style: {
				backgroundColor: '#FCFCFC',
			},
		},
		subHeader: {
			style: {
				backgroundColor: '#FCFCFC',
				paddingLeft: '16px',
			},
		},
		head: {
			style: {
				borderTop: '1px solid rgba(0, 0, 0, 0.125)',
			},
		},
	};

	const emptyState = (
		<h5 className="user-permissions-empty">
			<span>
				{hasCollectionPermission
					? 'No team members match your search.'
					: 'Collection level permissions have not been enabled.'}
			</span>
		</h5>
	);

	return (
		<Container className="manage-collection-permissions">
			<Row>
				<Col>
					<Card>
						<Card.Body>
							<div className="collection-container">
								<div className="details">
									<img
										className="show-thumbnail"
										src={podcast.thumbnail}
										alt="Show Thumbnail"
									/>
									<div>
										<h4 className="podcast-name">
											{podcast.name}
										</h4>
										<div>{`${podcast.episodeCount} Episodes`}</div>
									</div>
								</div>
								<Form.Check
									type="switch"
									id="collectionLevelPermission"
									label="Permissions"
									checked={podcast.collectionLevelPermissions}
									className="casted-form permission-toggle"
									onChange={() => updatePermissionSettings()}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col>
					<BaseTable
						title="Manage Permissions"
						values={
							hasCollectionPermission
								? filteredCollectionPermissions
								: []
						}
						columns={permissionColumns}
						loading={loadingUsers}
						clickable={false}
						pagination={true}
						paginationPerPage={8}
						customStyle={customStyles}
						subHeader={true}
						subHeaderComponent={
							<div className="user-permission-search">
								<FaSearch className="search-icon" />
								<input
									placeholder="Search for team members"
									onChange={(event) => handleSearch(event)}
								/>
							</div>
						}
						subHeaderAlign={'left'}
						noDataComponent={emptyState}
						disabled={!podcast.collectionLevelPermissions}
						resetPaginationToggle={false}
					/>
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
});

export default connect(mapStateToProps)(Permissions);
