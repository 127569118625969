import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import uniqueSlug from 'unique-slug';

// External Components
import { Button, Form, Spinner } from 'react-bootstrap';

// Internal Components
import ShareForm from 'components/shared/shareForm';
import { EPISODE_EMBED_TYPE, SHARE_CONTENT_TYPE } from 'components/constants';
import GenerateButton from '../../generateButton';

const SocialTab = (props) => {
	const { baseUrl, videoEnabled, episodeSlug, accountId, itemId, itemName } =
		props;

	// Episode Embed URLs
	const episodeSmallPlayerURL = `${baseUrl}/embed/v2/smallPlayer/${episodeSlug}`;
	const episodeRegularPlayerURL = `${baseUrl}/embed/v2/regularPlayer/${episodeSlug}`;

	// if video is disabled, then audio is only option
	const [audioOnly, setAudioOnly] = useState(!videoEnabled);
	const [selectedEmbedType, setSelectedEmbedType] = useState(
		EPISODE_EMBED_TYPE.PLAYER
	);
	const [embedIsLoading, setEmbedIsLoading] = useState(false);
	const [shareEmbedURL, setShareEmbedURL] = useState(null);
	const [showMessage, setShowMessage] = useState(false);
	const [socialDetails, setSocialDetails] = useState({
		title: `${itemName} Link ${new Date().toLocaleDateString()}`,
	});

	const { title, destination, description } = socialDetails;

	const [createShare] = useMutation(mutations.createShare);

	useEffect(() => {
		setSelectedEmbedType(EPISODE_EMBED_TYPE.PLAYER);
	}, []);

	// Embed Type OnChange
	const onTypeChange = (newValue) => {
		setSelectedEmbedType(newValue);
	};

	useEffect(() => {
		let episodeEmbedURL = '';

		switch (selectedEmbedType) {
			case EPISODE_EMBED_TYPE.SMALL_PLAYER:
				episodeEmbedURL = episodeSmallPlayerURL;
				break;
			case EPISODE_EMBED_TYPE.PLAYER:
			default:
				episodeEmbedURL = episodeRegularPlayerURL;
				if (!audioOnly) {
					episodeEmbedURL += '/video';
				}
				break;
		}

		setEmbedIsLoading(true);

		setShareEmbedURL(episodeEmbedURL);
	}, [selectedEmbedType, audioOnly]);

	useEffect(() => {
		// Listen for preview embed messages
		window.addEventListener(
			'message',
			function (message) {
				// Ensure event is coming from Casted
				if (message.data.event) {
					// Handle events
					switch (message.data.event) {
						case 'castedPlayerReady':
							setEmbedIsLoading(false);
							break;
						case 'castedSizeUpdate':
							document.getElementById(
								`casted-preview-player`
							).height = message.data.payload.height;
							break;
						default:
							break;
					}
				}
			},
			false
		);
	}, []);

	useEffect(() => {
		if (showMessage) {
			setTimeout(() => {
				setShowMessage(false);
			}, 5000);
		}
	}, [showMessage]);

	const createShareEpisode = async () => {
		const shareSlug = uniqueSlug();
		const shareUrl = `${baseUrl}/api/v2/oembed?url=${baseUrl}/share/${shareSlug}&format=json`;
		await navigator.clipboard.writeText(shareUrl);

		const embedType =
			selectedEmbedType === EPISODE_EMBED_TYPE.PLAYER
				? 'regularPlayer'
				: 'smallPlayer';
		let share = await createShare({
			variables: {
				share: {
					accountId: parseInt(accountId, 10),
					itemId,
					title: title,
					destination: destination,
					description: description,
					shareType: 'link',
					contentType: 'item',
					shareSlug,
					shareOption: {
						embedType,
						audioOnly:
							audioOnly ||
							selectedEmbedType !== EPISODE_EMBED_TYPE.PLAYER,
					},
					contentType: SHARE_CONTENT_TYPE.item,
				},
			},
		});
		share = share.data.createShare;

		if (share && share.id) {
			trackEvent('create-share-episode', share);
		}
	};

	return (
		<div className="social-layout">
			<div className="embed-options">
				<div className="share-content-options">
					<Form.Group>
						<Form className="edit-theme">
							<ShareForm
								share={socialDetails}
								setShareProperty={setSocialDetails}
							/>
						</Form>
					</Form.Group>
					<Form>
						<Form.Group>
							<Form.Label>Embed Type</Form.Label>
							<div className="share-type-buttons">
								<Button
									className={`share-type-button mt-2 ${
										selectedEmbedType ===
											EPISODE_EMBED_TYPE.PLAYER &&
										'active'
									}`}
									onClick={() =>
										onTypeChange(EPISODE_EMBED_TYPE.PLAYER)
									}
									variant="outline-primary"
								>
									Standard Player
								</Button>
								<Button
									className={`share-type-button mt-2 ml-2 ${
										selectedEmbedType ===
											EPISODE_EMBED_TYPE.SMALL_PLAYER &&
										'active'
									}`}
									onClick={() =>
										onTypeChange(
											EPISODE_EMBED_TYPE.SMALL_PLAYER
										)
									}
									variant="outline-primary"
								>
									Small Player
								</Button>
							</div>
						</Form.Group>
						{videoEnabled &&
							selectedEmbedType === EPISODE_EMBED_TYPE.PLAYER && (
								<Form.Group>
									<Form.Label>Embed Options</Form.Label>
									<div className="share-audio-option">
										<Form.Check
											id="switch-audio-only"
											type="checkbox"
											className="share--switch"
											label="Audio Only"
											onChange={() =>
												setAudioOnly(!audioOnly)
											}
											checked={
												audioOnly ||
												selectedEmbedType !==
													EPISODE_EMBED_TYPE.PLAYER
											}
										/>
									</div>
								</Form.Group>
							)}
						<GenerateButton
							buttonText="Generate Link"
							createShare={createShareEpisode}
							disabled={title.trim() === ''}
							tooltipText="Link Copied"
							title={title}
						/>
					</Form>
				</div>
			</div>
			<div className="embed-preview">
				<h5>Embed Preview</h5>
				<div className="scm-share-content-container">
					<iframe
						src={shareEmbedURL}
						id="casted-preview-player"
						width="100%"
						style={{
							maxHeight: '500px',
						}}
					/>
					{embedIsLoading && (
						<div className="embed-loading">
							<Spinner
								className="content-detail--loading"
								animation="grow"
								variant="info"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(SocialTab);
