import { createSelector } from 'reselect';

const getUserProfileFromState = (state) => state?.auth?.profile;

export const getUserProfile = createSelector(
	getUserProfileFromState,
	(profile) => {
		return profile || {};
	}
);
