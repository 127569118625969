import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Row, Col } from 'react-bootstrap';
import { FiChevronRight } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

// Internal constants
import { ACTIVE_STATUS } from 'components/constants';

const EpisodeNav = (props) => {
	const { rootPath, steps = [], episodeStatus } = props;
	const { pathname } = useLocation();

	return (
		<Nav className={`episode-detail--nav`}>
			<Row className="align-items-center">
				{steps.map((step, i) => {
					return (
						<Col xs="auto" key={step.path}>
							{step.disabled ? (
								<Nav.Item disabled={true} className="episode-nav--item">
									<div className="nav-link">
										{episodeStatus === ACTIVE_STATUS.transcribing ? (
											<a className="in-progress">(TRANSCRIPT IN PROGRESS)</a>
										) : (
											<a className="disabled">{step.name} </a>
										)}
									</div>
									{i + 1 !== steps.length && (
										<FiChevronRight className="episode-nav--separator" />
									)}
								</Nav.Item>
							) : (
								<Nav.Item className="episode-nav--item">
									<NavLink
										exact={false}
										className="nav-link"
										to={`${rootPath}/${step.path}`}
										onClick={step.onClick}
									>
										{step.name}
									</NavLink>
									{i + 1 !== steps.length && (
										<FiChevronRight className="episode-nav--separator" />
									)}
								</Nav.Item>
							)}
						</Col>
					);
				})}
			</Row>
		</Nav>
	);
};

export default EpisodeNav;
