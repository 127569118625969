import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/shared/button';
import fourOhThreeLogo from 'assets/403.png';

const FourOhThree = (props) => {
	return (
		<div className="four-oh-three">
			<div className="fot-topbar">
				<NavLink to="/" className="ab-fot-home-btn">
					<Button
						name="403-go-home-logo-btn"
						className="fot-go-home-logo-btn"
						variant="flat"
					>
						Casted
					</Button>
				</NavLink>
			</div>

			<div className="fot-content">
				<div className="fot-content-left">
					<h1 className="fot-headline">Oops!</h1>
					<p className="fot-message">It seems you aren't allowed here.</p>
					<p className="fot-message">
						Podcat is just waiting on you to get permission.
					</p>
					<NavLink to="/" className="ab-fot-home-btn">
						<Button
							name="403-go-home-btn"
							className="fot-go-home-btn"
							variant="contained"
						>
							<span className="fot-go-home-text">Return to the homepage</span>
						</Button>
					</NavLink>
				</div>
				<div className="fot-content-right">
					<img
						src={fourOhThreeLogo}
						alt="403 Illustration"
						className="fot-illustration"
					/>
				</div>
			</div>
		</div>
	);
};

export default FourOhThree;
