import React from 'react';
import ReactDOM from 'react-dom';
import CastedModal from 'components/shared/modal';

const PlaylistUnsavedChanges = (props, callback) => {
    const {
        title = 'Unsaved Changes',
        messageText = 'Any unsaved changes will be lost. Are you sure you want to leave?',
    } = props;

    const container = document.createElement('div');
    container.setAttribute('casted-confirmation-navigation', '');
    document.body.appendChild(container);

    const closeModal = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
        callback(callbackState);
    };

    const dialogOptions = [
        {
            text: 'Cancel',
            variant: 'cancel',
            onClick: () => closeModal(false)
        },
        {
            text: 'Leave',
            variant: 'danger',
            onClick: () => closeModal(true)
        }
    ];

    ReactDOM.render(
        <CastedModal
            show={true}
            closeButton={false}
            title={title}
            dialogOptions={dialogOptions}
        >{messageText}</CastedModal>,
        container
    );
};

export default PlaylistUnsavedChanges;