import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client/react/hooks';

import PercentageList from 'components/shared/metricPercentageList';

import {
	FaLinkedinIn,
	FaTwitter,
	FaFacebookF,
	FaSearch,
	FaCode,
	FaLink,
} from 'react-icons/fa';
import tracking from 'assets/icons/trackinglink.svg';

const TrafficReferrals = (props) => {
	const { variables, query } = props;

	const [data, setData] = useState(0);
	const [total, setTotal] = useState(0);
	const [referrers, setReferrers] = useState({});

	useEffect(() => {
		if (!loading) {
			const { total, __typename, ...referrers } =
				data[query?.definitions[0]?.name?.value];

			const valuesTotal = Object.values(referrers).reduce((acc, value) => {
				return acc + value;
			}, 0);
			setTotal(valuesTotal);
			setReferrers(referrers);
		}
	}, [data]);

	const { loading } = useQuery(query, {
		variables,
		fetchPolicy: 'cache-and-network',
		onCompleted: (trafficData) => {
			setData(trafficData);
		},
	});
	const values = Object.entries(referrers).map(([key, value]) => {
		switch (key) {
			case 'twitter':
				return {
					label: 'Twitter',
					key: key,
					value: value,
					icon: <FaTwitter />,
				};
			case 'linkedin':
				return {
					label: 'LinkedIn',
					key: key,
					value: value,
					icon: <FaLinkedinIn />,
				};
			case 'facebook':
				return {
					label: 'Facebook',
					key: key,
					value: value,
					icon: <FaFacebookF />,
				};
			case 'search':
				return {
					label: 'Search',
					key: key,
					value: value,
					icon: <FaSearch />,
				};
			case 'tracking':
				return {
					label: 'Tracking',
					key: key,
					value: value || 0,
					icon: <img src={tracking} alt="Tracking" />,
				};
			case 'direct':
				return {
					label: 'Direct',
					key: key,
					value: value,
					icon: <FaLink />,
				};
			default:
				return {
					label: key,
					key: key,
					value: value,
					icon: <FaLink />,
				};
		}
	});

	return (
		<PercentageList
			values={total ? values : []}
			total={total}
			loading={loading}
			title="Traffic Sources"
		/>
	);
};

export default TrafficReferrals;
