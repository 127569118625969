import React from 'react'

import { HorizontalLine } from 'components/shared/loadingSkeletons/skeleton'

const LoadingEpisodes = () => {

  return (
    <div  className="episodes">
      <div className="episodes-list" >
        <HorizontalLine style={{height: "105px", display: "flex"}}/>
        <HorizontalLine style={{height: "105px", display: "flex"}}/>
        <HorizontalLine style={{height: "105px", display: "flex"}}/>
        <HorizontalLine style={{height: "105px", display: "flex"}}/>
        <HorizontalLine style={{height: "105px", display: "flex"}}/>
      </div>
    </div>
  )
}

export default LoadingEpisodes
