import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

import styles from './styles.scss';

const Checkbox = (props) => {
	const { 
		id = 'checkbox-1',
		checked = false,
        label = 'Checkbox Label',
		onChange
	} = props;

	const [isChecked, setIsChecked] = useState(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);
	
	const onCheckChange = (e) => {
		e.stopPropagation();

		const newChecked = !isChecked;
		setIsChecked(!isChecked);

		if(onChange !== null && onChange !== undefined) {
			onChange({
				checked: newChecked
			})
		}
	}

    return (
		<div className={styles.checkbox}>
			<FontAwesomeIcon
				className={`${styles.icon} ${(isChecked ? styles.checked  : styles.unchecked)}`}
				icon={isChecked ? faCheckSquare : faSquare}
				onClick={onCheckChange}
				size="sm"
			/>
			{label && (
				<label
					className={styles.label}
					onClick={onCheckChange}
					htmlFor={id}
				>
					{label}
				</label>
			)}
			<input
				type="checkbox"
				id={id}
				name={id}
				checked={isChecked}
				readOnly
			/>
		</div>
	);
}

export default Checkbox;