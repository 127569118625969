import React, { useState } from 'react';

// External Components
import { FaSearch } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

const ContentPage = (props) => {
	const {
		className = '',
		pageTitle = 'Content Page',
		loading = false,
		renderCreateButton = <div></div>,
		renderNoContent = <div>No Content</div>,
		onSearch = (value) => {},
		contentGroups = [],
		totalContentCount = 0,
	} = props;

	const renderContent = () => {
		if (loading) {
			return (
				<div className="content-page-loading-container">
					<Spinner
						className="content-page-loading"
						animation="grow"
						variant="info"
					/>
				</div>
			);
		}

		if (totalContentCount > 0) {
			let returnContent = [];

			returnContent.push(
				<div className="content-page-search">
					<FaSearch />
					<input
						placeholder="Search your content here."
						onChange={(e) => onSearch(e.target.value)}
					/>
				</div>
			);

			return returnContent.concat(contentGroups);
		}

		return renderNoContent;
	};

	return (
		<div className={`content-page ${className}`}>
			<div className="content-page-header-row">
				<h2 className="content-page-header">{pageTitle}</h2>
				{renderCreateButton}
			</div>
			<div className="content-page-body">{renderContent()}</div>
		</div>
	);
};

export { ContentPage };
