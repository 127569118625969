import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	useQuery,
	useMutation,
	useLazyQuery,
} from '@apollo/client/react/hooks';

import {
	SearchCore,
	SearchInput,
	SearchSidebar,
	SearchResults,
	ThemeListFilter,
	TagListFilter,
	CheckboxList,
	RECORD_TYPES,
} from '@casted-app/casted-react-search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import queries from 'services/graphql/queries';

import { history, dispatch } from 'store/store';
import { setPlaylistContent } from 'store/reducers/data/playlist';

import podcat from 'assets/images/podcat-playing.svg';

const PlaylistSearch = (props) => {
	const { accountId, playlistContent } = props;

	const [selectedTheme, setSelectedTheme] = useState(null);

	const themeListOnChange = (value) => {
		setSelectedTheme(value);
	};

	/** Queries & Mutations **/
	const {
		data,
		loading: themesLoading,
		error,
	} = useQuery(queries.getThemesByAccountId, {
		variables: { accountId: parseInt(accountId, 10) },
		fetchPolicy: 'no-cache',
	});
	const { themes = [] } = data || {};

	const [
		getMatchData,
		{ data: matchesData, loading: matchesLoading, error: matchesError },
	] = useLazyQuery(queries.getThemeMatches);

	const { themeMatches = [] } = matchesData || {};

	useEffect(() => {
		if (selectedTheme !== null) {
			getMatchData({
				variables: {
					accountId: parseInt(accountId),
					themeId: selectedTheme.value.id,
					matchThreshold: selectedTheme.value.matchThreshold,
				},
			});
		}
	}, [selectedTheme]);

	const themeMatchEpisodeIds = selectedTheme?.value.id
		? themeMatches.map((themeMatch) => themeMatch?.episodeId)
		: null;

	// Get items that only occur in the left array,
	const onlyInLeft = (left, right) =>
		left.filter(
			(leftValue) =>
				!right.some((rightValue) => leftValue.id === rightValue.id)
		);

	const addThemeToPlaylist = () => {
		const themeEpisodes = themeMatches.map(
			(themeMatch) => themeMatch.episode
		);
		const content = onlyInLeft(themeEpisodes, playlistContent);

		dispatch(setPlaylistContent([...playlistContent, ...content]));
	};

	const addContentToPlaylist = (content) => {
		dispatch(setPlaylistContent([...playlistContent, content]));
	};

	const renderRowLayout = (result) => {
		const content = {
			id: result?.id?.raw,
			name: result?.episode_name?.snippet
				? result.episode_name.snippet
				: '',
			thumbnail: result?.episode_thumbnail?.raw
				? result.episode_thumbnail.raw
				: podcat,
		};

		const addButton = playlistContent?.find(
			(item) => item.id === content.id
		) ? (
			<button type="button" disabled>
				Added
			</button>
		) : (
			<button
				type="buton"
				onClick={() => {
					addContentToPlaylist(content);
				}}
			>
				+ Add to playlist
			</button>
		);

		return (
			<div className="playlist-search-row" key={content.id}>
				<div className="row-info">
					<img src={content.thumbnail} />
					<h5
						dangerouslySetInnerHTML={{
							__html: content.name,
						}}
					></h5>
				</div>
				{addButton}
			</div>
		);
	};

	return (
		<div className="playlist-search-container">
			<SearchCore
				searchKey={process.env.REACT_APP_ELASTIC_SEARCH_KEY}
				engineName={process.env.REACT_APP_ELASTIC_ENGINE_NAME}
				endpointBase={process.env.REACT_APP_ELASTIC_ENDPOINT_BASE}
				accountId={accountId}
				recordTypes={[RECORD_TYPES.EPISODE]}
				themeMatches={themeMatchEpisodeIds}
				resultsPerPage={5}
			>
				<div className="search-header">
					<FontAwesomeIcon icon={faSearch} size="sm" />
					<SearchInput
						showClose={false}
						placeholder="Search your content here."
					/>
				</div>
				<div className="search-body">
					<div className="search-sidebar">
						<SearchSidebar
							filters={[
								<ThemeListFilter
									fieldLabel="Themes"
									themes={themes}
									selectedTheme={selectedTheme}
									onChange={themeListOnChange}
								/>,
								<TagListFilter
									fieldLabel="Tags"
									field="episode_keywords"
								/>,
								<CheckboxList
									fieldLabel="Collection"
									field="podcast_name"
								/>,
							]}
						/>
					</div>
					<div className="search-results">
						{selectedTheme && (
							<>
								<h5>Themes</h5>
								<div className="search-selected-theme">
									<h5>{selectedTheme.label}</h5>
									<button
										type="buton"
										onClick={() => {
											addThemeToPlaylist();
										}}
									>
										+ Add entire theme to playlist
									</button>
								</div>
							</>
						)}
						<h5>Items</h5>
						<SearchResults
							history={history}
							displayResultsTime={false}
							selectedTheme={selectedTheme}
							paginationPosition={'right'}
							renderRowLayout={renderRowLayout}
						/>
					</div>
				</div>
			</SearchCore>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	playlistContent: state.playlistData.playlistContent,
});

export const PlaylistSearchWithRouter = withRouter(
	connect(mapStateToProps)(PlaylistSearch)
);
