import React, { useRef, useState } from 'react';

import { FaCog } from 'react-icons/fa';
import { Overlay, Tooltip, ListGroup } from 'react-bootstrap';

const TableRolesDropDown = (props) => {
	const { rolesList, row, onUpdateRole, disabled = false } = props;

	const [show, setShow] = useState(false);
	const target = useRef(null);

	const updateRole = (roleId, row) => {
		setShow(!show);
		onUpdateRole(roleId, row);
	};

	return (
		<div className="collection-permission-roles">
			<div
				className={`collection-permissions-toggle ${
					disabled ? 'disabled' : ''
				}`}
				ref={target}
				onClick={() => setShow(disabled ? false : !show)}
			>
				<FaCog icon="fa-solid fa-cog" className="toggle-icon" />
				Modify Role
			</div>
			<Overlay
				show={show}
				target={target.current}
				placement="right-start"
				rootClose={true}
				onHide={() => setShow(false)}
			>
				<Tooltip className="collection-permissions-tooltip">
					<ListGroup>
						{rolesList.map((role) => (
							<ListGroup.Item
								className="collection-permissions-list"
								key={`role-${role.id}`}
								onClick={() => updateRole(role.id, row)}
								disabled={role.id === row.roleId}
							>
								<p className="role--name">{role.name}</p>
								{role.description && (
									<p className="role--description">{role.description}</p>
								)}
							</ListGroup.Item>
						))}
					</ListGroup>
				</Tooltip>
			</Overlay>
		</div>
	);
};

export default TableRolesDropDown;
