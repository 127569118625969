import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';

// Internal libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import Toast from 'services/toast';

// External Components
import { Spinner } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';

// Internal Components
import Button from 'components/shared/button';
import ConfirmationModal from 'components/shared/confirmationModal';

// Redux
import { dispatch } from 'store/store';
import {
	hideDisconnectAccountIntegrationModal,
	showDisconnectAccountIntegrationModal,
} from 'store/reducers/ui/modals/disconnectAccountIntegrationModal';

// Internal Assets
import wordpressLogo from 'assets/logos/wordpress-logotype-standard.png';
import { ACCOUNT_INTEGRATION_TYPES } from 'components/constants';

const AccountIntegrations = (props) => {
	const {
		history,
		modals,
		permissions,
		selectedAccountId: accountId,
	} = props;

	const {
		data: apiKeyData,
		loading: apiKeyLoading,
		error: apiKeyError,
	} = useQuery(queries.apiKeys, {
		variables: { accountId },
	});

	const [revokeApiKey] = useMutation(mutations.revokeApiKey, {
		refetchQueries: ['apiKeys'],
	});

	const { apiKeys = [] } = apiKeyData || {};

	const accountLevelKeys = apiKeys.filter((apiKey) => apiKey.isAccountLevel);

	const wordpressIntegrations =
		accountLevelKeys.filter(
			(accountLevelKey) =>
				accountLevelKey.integrationName ===
				ACCOUNT_INTEGRATION_TYPES.WORDPRESS
		) || [];

	const disconnectIntegration = async () => {
		try {
			const res = await revokeApiKey({
				variables: {
					id: modals.disconnectAccountIntegrationModal
						.integrationToDisconnect?.id,
				},
			});

			const { data } = res;
			const { revokeApiKey: keyRevoked } = data;

			if (keyRevoked) {
				Toast.success('Integration disconnected');
				trackEvent('delete-api-key', {
					keyName:
						modals.disconnectAccountIntegrationModal
							.integrationToDisconnect?.name,
				});
			}
		} catch (e) {
			console.error(e);
			Toast.error('Unable to disconnect integration');
		} finally {
			dispatch(hideDisconnectAccountIntegrationModal());
		}
	};

	return (
		<div className="account-integrations-page">
			<div className="account-integrations-header-row">
				<h2
					className="account-integrations-header"
					onClick={() => history.goBack()}
				>
					<FaChevronLeft className="arrow-left" /> Account
					Integrations
				</h2>
			</div>
			<div className="account-integrations-content">
				<div className="integration wordpress-integration">
					<div className="description">
						<img src={wordpressLogo} />
						<p>
							Linking with WordPress allows Casted to provide you
							with insights and user engagement analytics
							surrounding your blogs and other written content on
							your platform.
						</p>
					</div>
					<div className="actions">
						<div className="actions-help">
							<h5>WordPress Integration</h5>
							<p>
								Connect your Casted account to your WordPress
								site
							</p>
							<div>
								<Button
									href={
										'https://help.casted.us/en/wordpress-integration'
									}
								>
									Connect With WordPress
								</Button>
							</div>
						</div>
						{wordpressIntegrations.length > 0 && (
							<div className="active-integrations">
								{wordpressIntegrations.map(
									(accountLevelKey) => {
										const index =
											accountLevelKey.name.indexOf(
												'wp-admin'
											);
										if (index !== -1) {
											return (
												<div className="active-integration">
													<div className="integration-name">
														<h5>Site:</h5>{' '}
														{accountLevelKey.name}
													</div>
													<div>
														<Button
															variant="danger"
															onClick={() => {
																dispatch(
																	showDisconnectAccountIntegrationModal(
																		accountLevelKey
																	)
																);
															}}
														>
															Disconnect with
															WordPress
														</Button>
													</div>
												</div>
											);
										}
									}
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<ConfirmationModal
				isOpen={modals.disconnectAccountIntegrationModal.showModal}
				title="Disconnect Integration"
				confirmationText={`Are you sure you want to disconnect the integration named "${
					modals.disconnectAccountIntegrationModal
						.integrationToDisconnect?.name || ''
				}"?`}
				confirmationButtonLabel="Disconnect"
				onCancel={() => {
					dispatch(hideDisconnectAccountIntegrationModal());
				}}
				onConfirm={disconnectIntegration}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions.accountRole,
	modals: state.ui.modals,
});

export const AccountIntegrationsWithRouter = withRouter(
	connect(mapStateToProps)(AccountIntegrations)
);
