import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import {
	setSelection,
	setHasSelection,
	setIsEditingClip,
} from 'store/reducers/data/transcript';

import EditClipCard from '../clips/clipModals/editClip';
import { CLIP_TYPES } from 'components/constants';

const SuggestedCard = (props) => {
	const { clip, getClosestNode, canCreateTakeaway, collectionType, transcriptReady } = props;

	useEffect(() => {
		if (clip !== null && transcriptReady) {
			const start = getClosestNode(clip.startTime);
			const end = getClosestNode(clip.endTime);

			dispatch(
				setSelection({
					startTime: Number(start.getAttribute('data-start')),
					endTime: Number(end.getAttribute('data-end')),
					startIndex: Number(start.getAttribute('data-entity-index')),
					endIndex: Number(end.getAttribute('data-entity-index')),
				})
			);
			dispatch(setHasSelection(true));
			dispatch(setIsEditingClip(true));
		}
	}, [clip]);

	return (
		<EditClipCard
			clip={clip}
			canCreateTakeaway={canCreateTakeaway}
			collectionType={collectionType}
			isSuggestedClip={clip.type === CLIP_TYPES.suggested}
			isRejectedClip={clip.type === CLIP_TYPES.rejected}
			className="suggested-card"
		/>
	);
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SuggestedCard);
