import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	useQuery,
	useMutation,
	useLazyQuery,
} from '@apollo/client/react/hooks';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

// External Components
import { Spinner } from 'react-bootstrap';
import { FaChevronLeft, FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-date-picker';

// Internal Components
import {
	Button,
	CastedToggleButtonGroup,
	Form,
	ConfirmationModal,
	AudioPlayer,
} from 'components/shared';

// Internal Libraries
import { history, dispatch } from 'store/store';
import {
	setPromotion,
	setPromotionUpdates,
} from 'store/reducers/data/promotion';
import {
	hideConfirmPromotionOverwriteModal,
	showConfirmPromotionOverwriteModal,
} from 'store/reducers/ui/modals/confirmPromotionOverwriteModal';
import {
	hideConfirmPromotionDeleteModal,
	showConfirmPromotionDeleteModal,
} from 'store/reducers/ui/modals/confirmPromotionDeleteModal';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import {
	PROMOTION_POSITION,
	METRICS_CONTEXTS,
	PROMOTION_STATUS,
} from 'components/constants';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';
import { PromotionUploadWithRouter } from '../promotionUpload';

const todayDate = new Date();
const fileReader = new FileReader();

const Promotion = (props) => {
	const { accountId, match, promotionUpdates, context, modals } = props;

	const { params } = match;
	const { showId: collectionId, episodeId: itemId, promotionId } = params;

	/** Queries & Mutations **/
	const { data, loading, error, startPolling, stopPolling } = useQuery(
		queries.getPromotionById,
		{
			variables: { accountId, promotionId },
			fetchPolicy: 'no-cache',
		}
	);
	const { data: fileExtData, loading: fileExtLoading } = useQuery(
		queries.getPromotionFileExt,
		{
			variables: { accountId, promotionId },
			fetchPolicy: 'no-cache',
		}
	);

	// API call to update promotion. We want to refetch the promotion
	const [checkForOverlappingPromotions] = useMutation(
		mutations.checkForOverlappingPromotions
	);
	const [updatePromotion] = useMutation(mutations.updatePromotionById, {
		refetchQueries: ['promotion'],
	});
	const [deletePromotion] = useMutation(mutations.deletePromotionById);

	const promotionData = data?.promotion || {};
	const promotionFileExt = fileExtData?.getPromotionFileExt || undefined;

	const convertedPromoUrlBase = `${process.env.REACT_APP_HOSTED_MEDIA_BASE}/promo/account-${accountId}/converted/${promotionId}`;

	// localPromotion is an aggregate of the original promotion data and
	// any new data present in promotionUpdates
	const [localPromotion, setLocalPromotion] = useState(promotionData || {});
	const [promotionFileName, setPromotionFileName] = useState(null);
	const [promotionFileType, setPromotionFileType] = useState('audio');
	const [localPromotionFileUrl, setLocalPromotionFileUrl] = useState(null);
	const [promoLoading, setPromoLoading] = useState(false);
	const [localPromotionFileExt, setLocalPromotionFileExt] = useState(
		promotionFileExt || undefined
	);
	const [promosToOverwrite, setPromosToOverwrite] = useState('');

	// Deconstructing promotion to get values
	const {
		id,
		title,
		description,
		startDate = null,
		endDate = null,
		position,
		url,
	} = localPromotion || {};

	const [showPromoPeriodDates, setShowPromoPeriodDates] = useState(
		startDate !== null && endDate !== null
	);
	const [startDateObject, setStartDateObject] = useState(
		startDate !== null ? startDate : todayDate
	);
	const [endDateObject, setEndDateObject] = useState(
		endDate !== null ? endDate : todayDate
	);

	useEffect(() => {
		if (startDate && new Date(startDate) >= todayDate)
			setStartDateObject(startDate);
		if (endDate && new Date(endDate) >= todayDate) setEndDateObject(endDate);
		if (startDate && endDate) setShowPromoPeriodDates(true);
	}, [startDate, endDate]);

	useEffect(() => {
		if (!loading) {
			setLocalPromotion(promotionData);
		}

		if (
			promotionData?.status === PROMOTION_STATUS.converting ||
			promotionData?.status === PROMOTION_STATUS.transcribing
		) {
			startPolling(5000);
		} else {
			stopPolling();
		}
	}, [loading, promotionData]);

	useEffect(() => {
		if (!fileExtLoading) {
			setLocalPromotionFileExt(promotionFileExt);
		}
	}, [fileExtLoading, promotionFileExt]);

	// Updates the provided promotion property to the value provided
	const updatePromotionProperty = (property, value) => {
		// Make sure property exists
		if (
			promotionData.hasOwnProperty(property) ||
			property === 'clearDates' ||
			property === 'date'
		) {
			let newPromotionUpdates = {
				...promotionUpdates,
			};
			switch (property) {
				case 'clearDates':
					// Clear out start and end dates if switching to always active
					newPromotionUpdates['startDate'] = null;
					newPromotionUpdates['endDate'] = null;
					break;
				case 'date':
					newPromotionUpdates['startDate'] = value.startDate;
					newPromotionUpdates['endDate'] = value.endDate;
					break;
				default:
					// Remove update if it matches the original property value.
					// Else, add it to unsaved updates
					if (
						value === promotionData[property] &&
						newPromotionUpdates.hasOwnProperty(property)
					) {
						delete newPromotionUpdates[property];
					} else {
						newPromotionUpdates[property] = value;
					}
					// Update localPromotion to include unsaved edits
					setLocalPromotion({
						...localPromotion,
						[property]: value,
					});
					break;
			}

			dispatch(setPromotionUpdates(newPromotionUpdates));
		}
	};

	const continuePromotionUpdate = async () => {
		try {
			Toast.info(
				'Saving promotion',
				<span>Closing your browser will result in losing your edits!</span>,
				{ autoClose: false, closeOnClick: false }
			);

			let res = await updatePromotion({
				variables: {
					promotionId,
					accountId,
					promotion: {
						...promotionUpdates,
					},
					promotionFileName,
				},
			});

			const { data, error } = res;

			const { updatePromotionById: updatedPromotion } = data;

			trackEvent('edit-promotion', {
				...promotionUpdates,
				promotionId,
			});

			// Clear out promotionUpdates
			dispatch(setPromotionUpdates({}));
			setPromotionFileName(null);

			toast.dismiss();
			if (context === METRICS_CONTEXTS.collection) {
				Toast.success(
					'Your changes have been saved successfully, but only applied to the last 100 items. To change this on historical episodes, update the settings on the item level.',
					'',
					{ autoClose: true }
				);
			} else {
				Toast.success('Promotion saved!', '', { autoClose: true });
			}
		} catch (e) {
			toast.dismiss();
			Toast.error('Unable to save promotion', '', {
				autoClose: false,
				closeOnClick: true,
			});
			Sentry.captureException(e);
		} finally {
			dispatch(hideConfirmPromotionOverwriteModal());
		}
	};

	// Function executed on click of delete button
	const deletePromotionOnClick = async () => {
		try {
			await deletePromotion({
				variables: {
					accountId,
					promotionId,
				},
			});
		} catch (e) {
			Toast.error('Unable to delete promotion', '', {
				autoClose: true,
				closeOnClick: true,
			});
			Sentry.captureException(e);
		} finally {
			dispatch(hideConfirmPromotionDeleteModal());
			if (context === METRICS_CONTEXTS.collection) {
				history.push(`/account/${accountId}/shows/${collectionId}/promotions`);
			} else {
				history.push(
					`/account/${accountId}/shows/${collectionId}/episodes/${itemId}/promotions`
				);
			}
			Toast.success('Promotion deleted', '', { autoClose: true });
		}
	};

	// Function executed on click of save button
	const savePromotionOnClick = async () => {
		if (Object.keys(promotionUpdates).includes('startDate')) {
			if (
				localPromotion.endDate &&
				new Date(localPromotion.endDate) < promotionUpdates.startDate
			) {
				Toast.error(
					'Unable to save promotion. Please check the scheduled dates.',
					'',
					{
						autoClose: true,
						closeOnClick: true,
					}
				);
				return;
			}
		}

		if (Object.keys(promotionUpdates).length > 0 || promotionFileName) {
			try {
				if (
					localPromotion.status === PROMOTION_STATUS.missing_file &&
					!promotionFileName
				) {
					continuePromotionUpdate();
				} else {
					let res = await checkForOverlappingPromotions({
						variables: {
							promotionId,
							promotion: {
								...promotionUpdates,
							},
						},
					});

					const { data, error } = res;
					let { checkForOverlappingPromotions: overlappingPromos } = data || [];
					if (overlappingPromos?.length > 0) {
						let overlappingPromosText = '';
						for (const promo of overlappingPromos) {
							overlappingPromosText += `<span class="promotion-name">${promo.title}</span><br/>`;
						}
						overlappingPromosText +=
							'<br/><span class="promotion-warning">Are you sure you want to replace the above promotions with this promotion?</span>';
						setPromosToOverwrite(overlappingPromosText);
						dispatch(showConfirmPromotionOverwriteModal());
					} else {
						continuePromotionUpdate();
					}
				}
			} catch (e) {
				toast.dismiss();
				Toast.error('Unable to save promotion', '', {
					autoClose: true,
					closeOnClick: true,
				});
				Sentry.captureException(e);
			}
		}
	};

	// Read in the uploaded file's data
	const getUploadedFileUrl = (fileData) => {
		fileReader.readAsDataURL(fileData);
	};

	// Once read, save it to state!
	fileReader.addEventListener(
		'load',
		function () {
			setLocalPromotionFileUrl(fileReader.result);
		},
		false
	);

	const renderUploadOrPlayer = () => {
		let promotionUpload = '';
		let promotionPlayer = '';

		if (
			localPromotion.status !== PROMOTION_STATUS.converting &&
			localPromotion.status !== PROMOTION_STATUS.transcribing
		) {
			promotionUpload = (
				<>
					<h3>Add promotional content to this {context}</h3>
					<p>
						Promotional content can be placed at the beginning and/or end of
						your audio or video content.
					</p>
					<Form.Group>
						<PromotionUploadWithRouter
							promotion={localPromotion}
							collectionId={collectionId}
							onUpload={() => setPromoLoading(true)}
							onUploaded={(fileName, fileType, file) => {
								getUploadedFileUrl(file.data);
								setPromoLoading(false);
								setPromotionFileName(fileName);
								setPromotionFileType(fileType);
							}}
							onRemoved={() => {
								setLocalPromotionFileUrl(null);
								setPromotionFileName(null);
							}}
						/>
					</Form.Group>
				</>
			);

			if (!promoLoading && localPromotionFileUrl) {
				promotionPlayer = (
					<AudioPlayer
						url={localPromotionFileUrl}
						isVideo={promotionFileType === 'video'}
						className={`${promotionFileType === 'video' ? 'video-player' : ''}`}
					/>
				);
			} else if (localPromotionFileExt) {
				promotionPlayer = (
					<AudioPlayer
						url={`${convertedPromoUrlBase}.${localPromotionFileExt}`}
						isVideo={localPromotionFileExt === 'mp4'}
						className={`${
							localPromotionFileExt === 'mp4' ? 'video-player' : ''
						}`}
					/>
				);
			}
		} else {
			promotionPlayer = (
				<>
					<div className="promotion-conversion">
						<div>
							<span>Conversion in progress</span>
							<Spinner animation="border" variant="info" size="sm" />
						</div>
						<p>
							Your uploaded promotion file will display here once the conversion
							is completed.
						</p>
					</div>
				</>
			);
		}

		return (
			<>
				{promotionUpload}
				{promotionPlayer}
			</>
		);
	};

	const positionToggleButtonList = [
		{
			text: 'Intro',
			value: PROMOTION_POSITION.INTRO,
		},
		{
			text: 'Outro',
			value: PROMOTION_POSITION.OUTRO,
		},
		{
			text: 'Both',
			value: PROMOTION_POSITION.BOTH,
		},
	];

	const periodToggleButtonList = [
		{
			text: 'Always Active',
			value: 'active',
		},
		{
			text: 'Set Dates',
			value: 'schedule',
		},
	];

	const periodOnChange = (value) => {
		if (value === 'schedule') {
			setShowPromoPeriodDates(true);

			updatePromotionProperty('date', {
				startDate: todayDate,
				endDate: todayDate,
			});
		} else {
			setShowPromoPeriodDates(false);
			updatePromotionProperty('clearDates', null);
		}
	};

	return (
		<div className="add-edit-promotion">
			<div className="promotion-nav">
				<h2 onClick={() => history.goBack()}>
					<FaChevronLeft className="arrow-left" /> Manage Promotions
				</h2>
			</div>
			{!localPromotion.id || loading ? (
				<div className="promotion-loading-container">
					<Spinner animation="grow" variant="info" />
				</div>
			) : (
				<div className="promotion-body">
					{renderUploadOrPlayer()}
					<Form.Group>
						<Form.Label>Promotion Title</Form.Label>
						<Form.Control
							value={title || ''}
							onChange={(e) => updatePromotionProperty('title', e.target.value)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Promotion Description</Form.Label>
						<Form.Control
							value={description || ''}
							onChange={(e) =>
								updatePromotionProperty('description', e.target.value)
							}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Banner URL (Optional)</Form.Label>
						<Form.Control
							value={url || ''}
							onChange={(e) => updatePromotionProperty('url', e.target.value)}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Position</Form.Label>
						<CastedToggleButtonGroup
							toggleButtonList={positionToggleButtonList}
							defaultValue={position || PROMOTION_POSITION.INTRO}
							onChange={(value) => updatePromotionProperty('position', value)}
							className="green"
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Promotional Period</Form.Label>
						<CastedToggleButtonGroup
							toggleButtonList={periodToggleButtonList}
							key={showPromoPeriodDates ? 'schedule' : 'active'}
							defaultValue={showPromoPeriodDates ? 'schedule' : 'active'}
							onChange={periodOnChange}
							className="green"
						/>
						{showPromoPeriodDates && (
							<div className="promotion-dates">
								<DatePicker
									calendarIcon={<FaCalendarAlt />}
									className="pem-schedule-episode-date-picker form-control"
									value={new Date(startDateObject)}
									minDate={todayDate}
									format="MM/dd/yy"
									calendarType="US"
									clearIcon={null}
									onChange={(value) => {
										setStartDateObject(new Date(value));
										updatePromotionProperty('startDate', value);
									}}
								/>
								<span>_</span>
								<DatePicker
									calendarIcon={<FaCalendarAlt />}
									className="pem-schedule-episode-date-picker form-control"
									value={new Date(endDateObject)}
									minDate={todayDate}
									format="MM/dd/yy"
									calendarType="US"
									clearIcon={null}
									onChange={(value) => {
										if (value >= startDateObject) {
											setEndDateObject(value);
											updatePromotionProperty('endDate', value);
										}
									}}
								/>
							</div>
						)}
					</Form.Group>
					<Button onClick={() => savePromotionOnClick()} type="button">
						Save Changes
					</Button>
					<Button
						onClick={() => dispatch(showConfirmPromotionDeleteModal())}
						type="button"
						variant="danger"
						className="ml-3"
					>
						Delete Promotion
					</Button>
				</div>
			)}
			<ConfirmationModal
				isOpen={modals.confirmPromotionOverwriteModal.showModal}
				title="Confirm Promotion Changes"
				confirmationText="This change will overwrite the following promotions:"
				confirmationButtonLabel="Replace"
				onCancel={() => {
					toast.dismiss();
					setPromosToOverwrite('');
					dispatch(hideConfirmPromotionOverwriteModal());
				}}
				onConfirm={() => continuePromotionUpdate()}
				className="overwrite-promotion-modal"
				closeButton={false}
				additionalConfirmationText={promosToOverwrite}
			/>
			<ConfirmationModal
				isOpen={modals.confirmPromotionDeleteModal.showModal}
				title="Confirm Delete"
				confirmationText="Are you sure you want to delete this promotion?"
				confirmationButtonLabel="Delete"
				onCancel={() => {
					dispatch(hideConfirmPromotionDeleteModal());
				}}
				onConfirm={() => deletePromotionOnClick()}
				className="overwrite-promotion-modal"
				closeButton={false}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	promotionUpdates: state.promotionData.promotionUpdates,
	modals: state.ui.modals,
});

export const PromotionWithRouter = withRouter(
	connect(mapStateToProps)(Promotion)
);
