import React, {useState} from 'react'
import { hexToRgba } from 'utils'
import { FaPlusSquare, FaRegMinusSquare } from 'react-icons/fa'

const Drawer = (props) => {
  const {
    maxHeight = '100px',
    fadeColor = '#f7f9fa',
    toggleColor = '#343a40',
    className = '',
    children
  } = props
  
  const [collapsed, setCollapsed] = useState(true)
  
  const drawerStyle = {
    maxHeight: maxHeight
  }
  const fadeStyle = {
    background: `linear-gradient(${hexToRgba(fadeColor,0)},${hexToRgba(fadeColor,1)})`,
  }
  const toggleStyle = {
    color: toggleColor
  }
  
  if(children.length < 375) {
    return <div dangerouslySetInnerHTML={{__html: children}}></div>
  }

  return (
    <div className={`content-drawer ${className} ${collapsed ? 'collapsed' : 'expanded'}`} >
      <div className="content-drawer--content" style={drawerStyle} dangerouslySetInnerHTML={{__html: `${children}<div className="content-drawer--fade" style=${fadeStyle}></div>`}}></div>
      <div className="content-drawer--toggle" onClick={e => setCollapsed(!collapsed)}>
        {collapsed &&
          <span style={toggleStyle}><FaPlusSquare /> More</span>
        }
        {!collapsed &&
          <span style={toggleStyle}><FaRegMinusSquare /> Less</span>
        }
      </div>
    </div>
  )
}

export default Drawer