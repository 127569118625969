import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import { Badge, Card } from 'react-bootstrap';
import MetricEmptyState from '../metricEmptyState';
import BaseTable from '../baseTable';
import { Link } from 'react-router-dom';
import { history } from 'store/store';
import { numberSort } from 'utils';

const CompanyProfileThemeAggregateTable = (props) => {
	const {
		accountId,
		companyId,
		dateRange,
		collectionId = '',
		episodeId = '',
		themeId = '',
	} = props;

	const [currentMetrics, setCurrentMetrics] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const variables = {
		accountId: accountId,
		companyId: companyId,
		startDate: dateRange.start,
		endDate: dateRange.end,
		...(collectionId && { collectionId: collectionId }),
		...(episodeId && { episodeId: episodeId }),
		...(themeId && { themeId: themeId }),
	};

	const { loading } = useQuery(
		queries.getCompanyProfileMetricsThemeInsights,
		{
			variables,
			fetchPolicy: 'cache-and-network',
			onCompleted: (data) => {
				if (data.getCompanyProfileMetricsThemeInsights.length > 0) {
					setCurrentMetrics(
						data.getCompanyProfileMetricsThemeInsights
					);
				} else {
					setCurrentMetrics([]);
				}
			},
		}
	);

	useEffect(() => {
		setResetPaginationToggle(!resetPaginationToggle);
	}, [currentMetrics]);

	const themeAggregateColumns = [
		{
			name: 'Top Theme',
			selector: (row) => {
				return (
					<Link
						to={{
							pathname: `/account/${accountId}/themes/${row.theme_id}`,
							state: { from: history.location.pathname },
						}}
					>
						<Badge
							className={`insights-theme-badge ${
								!row.color ? 'no-color' : ''
							}`}
							style={{ backgroundColor: row.color }}
						>
							{row.theme_name}
						</Badge>
					</Link>
				);
			},
			sortable: true,
		},
		{
			name: 'Last Engagement',
			selector: (row) => {
				let dateArray = row.last_engagement.value.split('-');
				return (
					dateArray[1] +
					'/' +
					dateArray[2].split('T')[0] +
					'/' +
					dateArray[0]
				);
			},
			sortable: true,
		},
		{
			name: 'Time Spent',
			selector: 'listen_time',
			format: (row) => {
				const timeSpent = Math.floor(row.listen_time / 60);

				if (timeSpent < 60) {
					return `${Math.round(timeSpent)} min`;
				} else {
					return `${(timeSpent / 60).toFixed(2)} Hours`;
				}
			},
			sortable: true,
		},
		{
			name: 'Unique Contacts',
			selector: (row) => row.unique_listeners,
			sortable: true,
			sortFunction: numberSort((row) => row.unique_listeners),
		},
		{
			name: 'Engagements',
			selector: (row) => row.total_audience,
			sortable: true,
			sortFunction: numberSort((row) => row.total_audience),
		},
		{
			name: 'Impressions',
			selector: (row) => row.impressions,
			sortable: true,
			sortFunction: numberSort((row) => row.impressions),
		},
	];

	const onClickHandler = (themeRow) => {
		window.open(`/account/${accountId}/themes/${themeRow.theme_id}`, '_blank');
	};

	return (
		<BaseTable
			values={currentMetrics}
			columns={themeAggregateColumns}
			loading={loading}
			clickable={true}
			className={'clickable'}
			onClick={onClickHandler}
			pagination={currentMetrics ? currentMetrics.length > 4 : false}
			paginationPerPage={4}
			resetPaginationToggle={resetPaginationToggle}
			noDataComponent={
				<MetricEmptyState metricName={'Theme Aggregate Data'} />
			}
		/>
	);
};

export default CompanyProfileThemeAggregateTable;
