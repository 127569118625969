import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { parseQuery } from 'utils/';
import AccountListRow from 'components/oauth/accountListRow';
import logo from 'assets/logos/casted-vert.svg';

const Authorize = (props) => {
	const { accounts, authProfile } = props;

	// get callback url from query string
	const params = parseQuery(props.location.search);
	const { callbackUrl } = params;

	return (
		<div className="authorize-container">
			<div className="left">
				<div className="authorize-left-content">
					<div className="authorize-welcome-to-casted-section">
						<h1 className="authorize-welcome-headline">
							<img src={logo} alt="Casted" className="login-casted-logo" />
						</h1>
					</div>
				</div>
			</div>
			<div className="right">
				<h2 className="authorize--header">Select an Account</h2>
				<div className="authorize--text">
					<span>
						This will be the account that will be linked. The app requesting
						access will be given read-only permissions to podcasts, episodes,
						clips, and transcripts for all shows in the account.
					</span>
				</div>
				<div className="authorize--account-list">
					{accounts &&
						Object.values(accounts).map((account) => {
							return (
								<AccountListRow
									key={account.id}
									account={account}
									callbackUrl={callbackUrl}
									userId={authProfile.id}
								/>
							);
						})}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accounts: state.accounts.accounts,
	authProfile: state.auth.profile,
});

export default withRouter(connect(mapStateToProps)(Authorize));
