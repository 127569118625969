import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import mutations from 'services/graphql/mutations';
import queries from 'services/graphql/queries';
import TextInput from 'components/shared/textInput';
import Button from 'components/shared/button';
import { trackEvent } from 'services/vitally';

const NewShowRssStep = (props) => {
	const { match, hidden, onProcessRssClick, onManualClick } = props;
	const { params } = match;
	const { accountId } = params;

	// HOOKS
	const [rssFeedUrl, setRssUrlFeed] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	// END HOOKS

	return (
		<Mutation
			mutation={mutations.createPodcastFromRssFeedUrl}
			refetchQueries={() => {
				return [
					{
						query: queries.podcastsByAccountId,
						variables: { accountId: parseInt(accountId, 10) },
					},
				];
			}}
		>
			{(createPodcastFromRssFeedUrl, { data, loading, error }) => {
				const saveAndGotoEpisode = async () => {
					try {
						setIsLoading(true);

						const podcastRSS = {
							accountId: parseInt(accountId, 10),
							rssFeedUrl,
						};

						const podcast = await createPodcastFromRssFeedUrl({
							variables: { podcastRSS },
						});

						trackEvent('new-collection', {
							name: podcast.name,
							rssFeedUrl: podcastRSS,
							method: 'import',
							type: 'serial',
						});

						if (onProcessRssClick) {
							onProcessRssClick(
								podcast.data.createPodcastFromRssFeedUrl
							);
						}
					} catch (err) {
						console.error(err);
						Sentry.captureException(err);
						setIsLoading(false);
						setHasError(true);
					}
				};

				const gotoManual = () => {
					if (onManualClick) {
						onManualClick();
					}
				};

				return (
					<div
						className={`new-show-rss-step ${
							hidden ? 'hidden' : ''
						}`}
					>
						<div className="new-show-details-header-row">
							<div className="new-show-details-header">
								What's the link to your collection's RSS feed?
							</div>
							<div className="new-show-details-description">
								Adding your collection with the RSS feed will
								automatically pull in your collection and
								collection item information. Only add an RSS
								feed you own the rights to.
							</div>
						</div>
						<div className="new-show-details-container">
							<div className="new-show-details">
								<div className="new-show-details-input-wrapper">
									<div>
										<label className="new-show-details-input-label">
											link to rss feed
										</label>
									</div>
									<TextInput
										name="new-show-rss-feed"
										label="http://mypodcast.com/feed.rss"
										value={rssFeedUrl}
										onChange={(e) => {
											setRssUrlFeed(e.target.value);
										}}
										onFocus={(e) => {
											setHasError(false);
										}}
										inputClassName="new-show-details-form-input"
										rootStyles={{ margin: 0 }}
									/>
								</div>
								{hasError && (
									<div className="errorMsg">
										Make sure you're linking the RSS feed
										created by your hosting provider. Casted
										can't use feeds from apps like Apple
										Podcasts, Spotify, or Google. Refer to
										your hosting provider to locate your RSS
										feed url.
									</div>
								)}
								<div className="new-show-rss-button">
									<Button
										name="new-show-save-btn"
										onClick={saveAndGotoEpisode}
										className={
											hasError
												? 'button-error new-show-save-btn '
												: 'new-show-save-btn '
										}
										variant="success"
										title="Add Collection"
										disabled={
											!rssFeedUrl ||
											isLoading ||
											!!hasError
										}
										showLoading={isLoading}
									>
										{hasError ? 'Error' : 'Add Show'}
									</Button>
								</div>
							</div>
						</div>
						<div className="new-show-actions-container">
							<div className="new-show-actions-row">
								<Button
									name="new-show-manual-btn"
									onClick={gotoManual}
									className="new-show-manual-btn"
									variant="flat"
									title="I want to upload manually"
								>
									I want to create this collection from
									scratch
								</Button>
							</div>
						</div>
					</div>
				);
			}}
		</Mutation>
	);
};

export default withRouter(NewShowRssStep);
