export const ACTIONS = {
    GET_SELECTION: 'CASTED/GET_SELECTION',
    SET_SELECTION: 'CASTED/SET_SELECTION',
    GET_HAS_SELECTION: 'CASTED/GET_HAS_SELECTION',
    SET_HAS_SELECTION: 'CASTED/SET_HAS_SELECTION',
    GET_SIDEBAR_VISIBLE: 'CASTED/GET_SIDEBAR_VISIBLE',
    SET_SIDEBAR_VISIBLE: 'CASTED/SET_SIDEBAR_VISIBLE',
    GET_SELECTED_CLIP: 'CASTED/GET_SELECTED_CLIP',
    SET_SELECTED_CLIP: 'CASTED/SET_SELECTED_CLIP',
    GET_IS_CREATING_CLIP: 'CASTED/GET_IS_CREATING_CLIP',
    SET_IS_CREATING_CLIP: 'CASTED/SET_IS_CREATING_CLIP',
    GET_IS_EDITING_CLIP: 'CASTED/GET_IS_EDITING_CLIP',
    SET_IS_EDITING_CLIP: 'CASTED/SET_IS_EDITING_CLIP',
    GET_CURRENT_TIME: 'CASTED/GET_CURRENT_TIME',
    SET_CURRENT_TIME: 'CASTED/SET_CURRENT_TIME',
    GET_IS_PLAYING: 'CASTED/GET_IS_PLAYING',
    SET_IS_PLAYING: 'CASTED/SET_IS_PLAYING',
    GET_SHOW_HIGHLIGHTS: 'CASTED/GET_SHOW_HIGHLIGHTS',
    SET_SHOW_HIGHLIGHTS: 'CASTED/SET_SHOW_HIGHLIGHTS',
    GET_IS_EDITING: 'CASTED/GET_IS_EDITING',
    SET_IS_EDITING: 'CASTED/SET_IS_EDITING',
    GET_CURRENT_TRANSCRIPT: 'CASTED/GET_CURRENT_TRANSCRIPT',
    SET_CURRENT_TRANSCRIPT: 'CASTED/SET_CURRENT_TRANSCRIPT',
    GET_SHOW_SUGGESTIONS: 'CASTED/GET_SHOW_SUGGESTIONS',
    SET_SHOW_SUGGESTIONS: 'CASTED/SET_SHOW_SUGGESTIONS',
};

const initialState = {
    selection: {},
    hasSelection: false,
    sidebarVisible: false,
    selectedClip: {},
    isCreatingClip: false,
    isEditingClip: false,
    currentTime: 0,
    isPlaying: false,
    showHighlights: false,
    isEditing: false,
    currentTranscript: {},
    showSuggestions: false,
};

export const getSelection = () => {
    return {
        type: ACTIONS.GET_SELECTION
    };
};

export const setSelection = (selection) => {
    return {
        type: ACTIONS.SET_SELECTION,
        selection
    };
};

export const getHasSelection = () => {
    return {
        type: ACTIONS.GET_HAS_SELECTION
    };
};

export const setHasSelection = (hasSelection) => {
    return {
        type: ACTIONS.SET_HAS_SELECTION,
        hasSelection
    };
};

export const getSidebarVisible = () => {
    return {
        type: ACTIONS.GET_SIDEBAR_VISIBLE
    };
};

export const setSidebarVisible = (sidebarVisible) => {
    return {
        type: ACTIONS.SET_SIDEBAR_VISIBLE,
        sidebarVisible
    };
};

export const getSelectedClip = () => {
    return {
        type: ACTIONS.GET_SELECTED_CLIP
    };
};

export const setSelectedClip = (selectedClip) => {
    return {
        type: ACTIONS.SET_SELECTED_CLIP,
        selectedClip
    };
};

export const getIsCreatingClip = () => {
    return {
        type: ACTIONS.GET_IS_CREATING_CLIP
    };
};

export const setIsCreatingClip = (isCreatingClip) => {
    return {
        type: ACTIONS.SET_IS_CREATING_CLIP,
        isCreatingClip
    };
};

export const getIsEditingClip = () => {
    return {
        type: ACTIONS.GET_IS_EDITING_CLIP
    };
};

export const setIsEditingClip = (isEditingClip) => {
    return {
        type: ACTIONS.SET_IS_EDITING_CLIP,
        isEditingClip
    };
};

export const getCurrentTime = () => {
    return {
        type: ACTIONS.GET_CURRENT_TIME
    };
};

export const setCurrentTime = (currentTime) => {
    return {
        type: ACTIONS.SET_CURRENT_TIME,
        currentTime
    };
};

export const getIsPlaying = () => {
    return {
        type: ACTIONS.GET_IS_PLAYING
    };
};

export const setIsPlaying = (isPlaying) => {
    return {
        type: ACTIONS.SET_IS_PLAYING,
        isPlaying
    };
};

export const getShowHighlights = () => {
    return {
        type: ACTIONS.GET_SHOW_HIGHLIGHTS
    };
};

export const setShowHighlights = (showHighlights) => {
    return {
        type: ACTIONS.SET_SHOW_HIGHLIGHTS,
        showHighlights
    };
};

export const getIsEditing = () => {
    return {
        type: ACTIONS.GET_IS_EDITING
    }
};

export const setIsEditing = (isEditing) => {
    return {
        type: ACTIONS.SET_IS_EDITING,
        isEditing
    }
};

export const getCurrentTranscript = () => {
	return {
		type: ACTIONS.GET_CURRENT_TRANSCRIPT,
	};
};

export const setCurrentTranscript = (currentTranscript) => {
	return {
		type: ACTIONS.SET_CURRENT_TRANSCRIPT,
		currentTranscript,
	};
};

export const getShowSuggestions = () => {
	return {
		type: ACTIONS.GET_SHOW_SUGGESTIONS,
	};
};

export const setShowSuggestions = (showSuggestions) => {
	return {
		type: ACTIONS.SET_SHOW_SUGGESTIONS,
		showSuggestions,
	};
};

const transcriptReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.GET_SELECTION:
            return state.selection;
        case ACTIONS.SET_SELECTION:
            return {
                ...state,
                selection: action.selection
            };
        case ACTIONS.GET_HAS_SELECTION:
            return state.hasSelection;
        case ACTIONS.SET_HAS_SELECTION:
            return {
                ...state,
                hasSelection: action.hasSelection
            };
        case ACTIONS.GET_SIDEBAR_VISIBLE:
            return state.sidebarVisible;
        case ACTIONS.SET_SIDEBAR_VISIBLE:
            return {
                ...state,
                sidebarVisible: action.sidebarVisible
            };
        case ACTIONS.GET_SELECTED_CLIP:
            return state.selectedClip;
        case ACTIONS.SET_SELECTED_CLIP:
            return {
                ...state,
                selectedClip: action.selectedClip
            };
        case ACTIONS.GET_IS_CREATING_CLIP:
            return state.isCreatingClip;
        case ACTIONS.SET_IS_CREATING_CLIP:
            return {
                ...state,
                isCreatingClip: action.isCreatingClip
            };
        case ACTIONS.GET_IS_EDITING_CLIP:
            return state.isEditingClip;
        case ACTIONS.SET_IS_EDITING_CLIP:
            return {
                ...state,
                isEditingClip: action.isEditingClip
            };
        case ACTIONS.GET_CURRENT_TIME:
            return state.currentTime;
        case ACTIONS.SET_CURRENT_TIME:
            return {
                ...state,
                currentTime: action.currentTime
            };
        case ACTIONS.GET_IS_PLAYING:
            return state.isPlaying;
        case ACTIONS.SET_IS_PLAYING:
            return {
                ...state,
                isPlaying: action.isPlaying
            };
        case ACTIONS.GET_SHOW_HIGHLIGHTS:
            return state.showHighlights;
        case ACTIONS.SET_SHOW_HIGHLIGHTS:
            return {
                ...state,
                showHighlights: action.showHighlights
            };
        case ACTIONS.GET_IS_EDITING:
            return state.isEditing;
        case ACTIONS.SET_IS_EDITING:
            return {
                ...state,
                isEditing: action.isEditing
            };
        case ACTIONS.GET_CURRENT_TRANSCRIPT:
            return state.currentTranscript;
        case ACTIONS.SET_CURRENT_TRANSCRIPT:
            return {
                ...state,
                currentTranscript: action.currentTranscript
            };
        case ACTIONS.GET_SHOW_SUGGESTIONS:
            return state.showSuggestions;
        case ACTIONS.SET_SHOW_SUGGESTIONS:
            return {
                ...state,
                showSuggestions: action.showSuggestions
            };
        default:
            return state;
    }
};

export default transcriptReducer;