import React from 'react'
import classnames from 'classnames'
import initials from 'initials'

import { DEFAULT_AVATAR } from 'components/constants'

const Avatar = ({
  srcUrl,
  rootStyles = {},
  imgStyles = {},
  imgProps = {},
  alt,
  rootClassname = '',
  imgClassName = '',
  initialsClassName = '',
  name = ''
}) => {
  return (
    <div
      className={classnames('avatar', rootClassname)}
      style={rootStyles}
    >
      {
        srcUrl &&
        <img
          onError={(e)=>{ e.target.onerror = null; e.target.src=DEFAULT_AVATAR }}
          src={srcUrl}
          style={imgStyles}
          className={imgClassName}
          alt={alt || 'user_img'}
          {...imgProps}
        />
      }
      {
        !srcUrl &&
        <span className={classnames('avatar-initials', initialsClassName)}>
          {initials(name)}
        </span>
      }
    </div>
  )
}

export default Avatar
