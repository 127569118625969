import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Presents a modal with options when a user needs to pick between two things.
 *
 * Options objects should include a string .title,
 * up to three option details elements (.detailOne, .detailTwo, .detailThree)
 * and a .optionButton element
 *
 * @example
 * optionOne: {
 *    title: 'String'
 *    detailOne: <></>
 *    detailTwo: <></>
 *    detailThree: <></>
 *    optionButton: <></>
 * },
 * optionTwo ...
 *
 * @param {*} props.optionInfo - Contains the details for the two options. Should have an optionOne and optionTwo
 * @returns a component with option one and two represented as two side by side columns
 */
const NewContentChoiceModal = (props) => {
	const {
		optionInfo: { optionOne },
		optionInfo: { optionTwo },
	} = props;

	return (
		<div className="new-collection-wrapper">
			<div className="collection-types-wrapper">
				<div className="collection-type border-right">
					<div className="collection-type-details">
						<div className="d-flex justify-content-center">
							<div className="header">{optionOne.title}</div>
						</div>
						<div className="collection-type-detail">{optionOne.detailOne}</div>
						{optionOne.detailTwo && (
							<div className="collection-type-detail">
								{optionOne.detailTwo}
							</div>
						)}
						{optionOne.detailThree && (
							<div className="collection-type-detail">
								{optionOne.detailThree}
							</div>
						)}
					</div>
					<div className="collection-type-button">{optionOne.optionButton}</div>
				</div>
				<div className="collection-type border-left">
					<div className="collection-type-details">
						<div className="d-flex justify-content-center">
							<div className="header">{optionTwo.title}</div>
						</div>
						<div className="collection-type-detail">{optionTwo.detailOne}</div>
						{optionTwo.detailTwo && (
							<div className="collection-type-detail">
								{optionTwo.detailTwo}
							</div>
						)}
						{optionTwo.detailThree && (
							<div className="collection-type-detail option-three">
								{optionTwo.detailThree}
							</div>
						)}
					</div>
					<div className="collection-type-button">{optionTwo.optionButton}</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export default withRouter(connect(mapStateToProps)(NewContentChoiceModal));
