import React, { useState, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import TimePicker from 'rc-time-picker';
import moment from 'moment-timezone';

import { Form, Modal, Row, Col } from 'react-bootstrap';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';

import Button from '../button';

import 'rc-time-picker/assets/index.css';

const PublishEpisodeModal = (props) => {
	// Props
	const {
		episodeId,
		isOpen,
		onClose = () => {},
		className = '',
		title = 'Schedule Publish Date',
		publishDateTime: _publishDateTime = new Date(),
		onSchedule,
		autoPublishYoutube = false,
	} = props;

	// Hooks
	const [scheduleDate, setScheduleDate] = useState(new Date(_publishDateTime));
	const [scheduleTime, setScheduleTime] = useState(moment(_publishDateTime));
	const [publishDateTime, setPublishDateTime] = useState(
		moment(_publishDateTime, moment.tz.guess()).utc().toISOString()
	);
	const [visibility, setVisibility] = useState('private');

	// Format Date/Time data
	useEffect(() => {
		if (!scheduleDate || !scheduleTime) return;

		/**
		 * Pull Day, Month, Year from date picker
		 * and hour and minute from time picker
		 */
		const date = moment(scheduleDate);
		const dateObj = {
			year: date.year(),
			month: date.month(),
			day: date.date(),
			hours: scheduleTime.hour(),
			minutes: scheduleTime.minute(),
		};

		/**
		 * Parse date object into moment object
		 * Moment automatically assumes assumes time is in local time
		 * Format to ISO String for DB, method auto converts to UTC
		 */
		setPublishDateTime(moment(dateObj).toISOString());
	}, [scheduleDate, scheduleTime]);

	return (
		<Modal
			show={isOpen}
			className={`publish-episode-modal ${className}`}
			centered
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col className="pem-schedule-episode-date">
						<DatePicker
							calendarIcon={<FaCalendarAlt />}
							className="pem-schedule-episode-date-picker form-control"
							value={scheduleDate}
							format="MMM d, yyyy"
							minDate={new Date(Date.now())}
							calendarType="US"
							clearIcon={null}
							onChange={(value) => {
								setScheduleDate(value);
							}}
						/>
					</Col>
					<Col className="pem-schedule-episode-time" id="time-picker-container">
						<TimePicker
							className="form-control"
							inputIcon={<FaClock />}
							value={scheduleTime}
							onChange={(value) => {
								setScheduleTime(value);
							}}
							format="h:mm a"
							allowEmpty={false}
							showSecond={false}
							use12Hours
						/>
						<span className="pem-schedule-episode-label">
							{moment.tz(scheduleTime, moment.tz.guess()).zoneAbbr()}
						</span>
					</Col>
				</Row>
				{autoPublishYoutube && (
					<div className="youtube-visibility-input">
						<Form.Label className="label">YouTube Visibility</Form.Label>
						<Form.Check
							className="youtube-visibility-radio"
							type="radio"
							label={
								<div className="radio-option">
									Public
									<div className="radio-description">
										Everyone can watch your video
									</div>
								</div>
							}
							checked={visibility === 'public'}
							onChange={() => setVisibility('public')}
						/>
						<Form.Check
							className="youtube-visibility-radio"
							type="radio"
							label={
								<div className="radio-option">
									Unlisted
									<div className="radio-description">
										Anyone with the video link can watch your video
									</div>
								</div>
							}
							checked={visibility === 'unlisted'}
							onChange={() => setVisibility('unlisted')}
						/>
						<Form.Check
							className="youtube-visibility-radio"
							type="radio"
							label={
								<div className="radio-option">
									Private
									<div className="radio-description">
										Only you and people you choose can watch your video
									</div>
								</div>
							}
							checked={visibility === 'private'}
							onChange={() => setVisibility('private')}
						/>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onClose} variant="cancel">
					Cancel
				</Button>
				<Button
					onClick={() => {
						const youtubeVisibility = autoPublishYoutube ? visibility : null;
						onSchedule(publishDateTime, youtubeVisibility);
						setVisibility('private');
					}}
					variant="success"
				>
					Schedule
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PublishEpisodeModal;
