import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Internal Libraries
import queries from 'services/graphql/queries';

import MetricDateRangePicker from 'components/shared/metricDateRange';
import CompanyProfileMetricCards from './metricCards';
import { addDays, startOfDay, format } from 'date-fns';
import { FaChevronLeft } from 'react-icons/fa';
import CompanyProfileMetricsChart from './metricsChart';
import { getSelectedAccount } from 'store/selectors/accounts';
import CompanyProfileContentMixChart from './contentMixChart';
import CompanyProfileThemeAggregateTable from './themeAggregateTable';
import CompanyProfileContentAggregateTable from './contentAggregateTable';

const CompanyProfile = (props) => {
	const {
		match,
		className = '',
		selectedAccountId: accountId,
		parentDateRange = {
			start: format(addDays(startOfDay(new Date()), -30), 'yyyy-MM-dd'),
			end: format(addDays(startOfDay(new Date()), -1), 'yyyy-MM-dd'),
		},
		compDateRange = {
			start: format(addDays(startOfDay(new Date()), -61), 'yyyy-MM-dd'),
			end: format(addDays(startOfDay(new Date()), -31), 'yyyy-MM-dd'),
		},
		logo = '',
		description = '',
		collectionId = '',
		episodeId = '',
		themeId = '',
		selectedAccount,
		isRenderedInModal = false,
	} = props;
	const { params } = match;
	const companyId = props.companyId || params.companyId;

	const { featureCompanyProfileThemeAggregateTable } = useFlags();

	const { data } = useQuery(queries.getCompanyData, {
		variables: { companyId },
	});
	const { getCompanyData = [] } = data || {};
	const [companyData, setCompanyData] = useState({});

	useEffect(() => {
		getCompanyData.length > 0 && setCompanyData(getCompanyData[0]);
	}, [getCompanyData]);

	const [dateRange, setDateRange] = useState(parentDateRange);
	const [comparisonDateRange, setComparisonDateRange] =
		useState(compDateRange);

	const handleDateChange = (newRange) => {
		setDateRange({
			start: newRange.start,
			end: newRange.end,
		});

		setComparisonDateRange({
			start: newRange.comparableStart,
			end: newRange.comparableEnd,
		});
	};

	return (
		<div>
			{!isRenderedInModal && (
				<div className="row pt-3 pl-5">
					<Link
						to={{
							pathname: `/account/${accountId}/insights`,
						}}
					>
						<h2>
							<FaChevronLeft className="arrow-left pr-2" fontSize={20} />
							Insights
						</h2>
					</Link>
				</div>
			)}
			<div className={`company-profile-container p-3 container${className}`}>
				<div className="ml-4 mr-4 mt-2 ">
					<div className="row mb-3">
						<div className="col">
							<img
								src={logo ? logo : companyData.logo ? companyData.logo : ''}
								className="company-logo"
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<p>
								{description
									? description
									: companyData.description
									? companyData.description
									: ''}
							</p>
						</div>
						{!isRenderedInModal && (
							<div className="col-12 col-md-6">
								<MetricDateRangePicker
									accountCreatedOn={selectedAccount.createdOn}
									dateRange={dateRange}
									onChange={handleDateChange}
									showDatesText={false}
								/>
							</div>
						)}
					</div>

					<CompanyProfileMetricCards
						accountId={accountId}
						companyId={companyId}
						dateRange={dateRange}
						comparisonDateRange={comparisonDateRange}
						collectionId={collectionId}
						episodeId={episodeId}
						themeId={themeId}
					/>

					<div className="row">
						<CompanyProfileMetricsChart
							accountId={accountId}
							companyId={companyId}
							dateRange={dateRange}
							collectionId={collectionId}
							episodeId={episodeId}
							themeId={themeId}
						/>
					</div>
					<div className="content-mix-and-aggregate-container row">
						<div className="content-mix-container col col-md-3">
							<CompanyProfileContentMixChart
								accountId={accountId}
								companyId={companyId}
								dateRange={dateRange}
								collectionId={collectionId}
								episodeId={episodeId}
								themeId={themeId}
							/>
						</div>
						<div className="content-aggregate-container col col-md-9">
							<CompanyProfileContentAggregateTable
								accountId={accountId}
								companyId={companyId}
								dateRange={dateRange}
								collectionId={collectionId}
								episodeId={episodeId}
								themeId={themeId}
							/>
						</div>
					</div>
					{featureCompanyProfileThemeAggregateTable && (
						<div className="row">
							<div className="theme-aggregate-container mb-2">
								<CompanyProfileThemeAggregateTable
									accountId={accountId}
									companyId={companyId}
									dateRange={dateRange}
									collectionId={collectionId}
									episodeId={episodeId}
									themeId={themeId}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
});

export const CompanyProfileWithRouter = withRouter(
	connect(mapStateToProps)(CompanyProfile)
);
