import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import Toast from 'services/toast';
import * as Sentry from '@sentry/browser';

import ConfirmationModal from 'components/shared/confirmationModal';
import Button from 'components/shared/button';
import Checkbox from 'components/shared/checkbox';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

const SsoSettings = (props) => {
	const { selectedAccountId: accountId } = props;

	const [id, setId] = useState(0);
	const [issuer, setIssuer] = useState('');
	const [ssoUrl, setSsoUrl] = useState('');
	const [signatureAlgorithm, setSignatureAlgorithm] = useState('sha256');
	const [certificate, setCertificate] = useState('');
	const [forceSso, setForceSso] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useQuery(queries.getAccountSaml, {
		variables: { accountId },
		onCompleted: (data) => {
			const samlData = data.getAccountSaml;
			if (samlData) {
				setId(samlData.id);
				setIssuer(samlData.issuer);
				setSsoUrl(samlData.ssoUrl);
				setSignatureAlgorithm(samlData.signatureAlgorithm);
				setCertificate(samlData.cert);
				setForceSso(samlData.forceSso);
			}
		},
	});

	const [updateSaml] = useMutation(mutations.saveAccountSaml, {
		onCompleted: (data) => {
			setId(data.saveAccountSaml.id);
		},
	});

	const [deleteSaml] = useMutation(mutations.deleteAccountSaml);

	const saveSettings = async () => {
		if (!issuer || !ssoUrl || !certificate) {
			Toast.warn('Please enter values for all fields before saving.');
			return;
		}

		const samlSettings = {
			issuer,
			ssoUrl,
			signatureAlgorithm,
			cert: certificate,
			forceSso,
		};

		const variables = { accountId, samlSettings };

		if (id) {
			variables.samlId = id;
		}

		await updateSaml({ variables });

		Toast.success('SSO Settings Saved');
		trackEvent('sso-enabled', { isRequired: forceSso });
	};

	const deleteSamlSettings = async () => {
		try {
			await deleteSaml({ variables: { samlId: id } });

			setId(0);
			setIssuer('');
			setSsoUrl('');
			setSignatureAlgorithm('sha256');
			setCertificate('');
			setForceSso(false);
			setIsModalOpen(false);

			Toast.success('SSO Settings Deleted');
			trackEvent('delete-sso');
		} catch (e) {
			console.log(e);
			Sentry.captureException(e);
			Toast.error('Unable to delete settings');
		}
	};

	return (
		<>
			<Container className="sso-settings-screen p-5">
				<Row className="mb-5">
					<Col>
						<h2>SSO Settings</h2>
					</Col>
					<Col className="d-flex justify-content-end">
						<Button
							variant="link"
							className="sso-docs--view"
							target="_blank"
							href="https://help.casted.us/en/sso"
						>
							Read the SSO Setup Docs
						</Button>
						<Button
							variant="danger"
							className={`ml-5 btn-delete ${id ? '' : 'hidden'}`}
							onClick={() => setIsModalOpen(true)}
						>
							DELETE
						</Button>
						<Button
							variant="success"
							className="ml-3 btn-save"
							onClick={() => saveSettings(true)}
						>
							SAVE
						</Button>
					</Col>
				</Row>
				<Card>
					<Card.Body>
						<Row className="mb-3">
							<Col xs={12}>
								<Form.Label>Issuer URL</Form.Label>
								<Form.Control
									className="sso-settings-text"
									onChange={(e) => setIssuer(e.target.value)}
									onBlur={(e) =>
										setIssuer(e.target.value.trim())
									}
									value={issuer}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col xs={12}>
								<Form.Label>SSO URL</Form.Label>
								<Form.Control
									className="sso-settings-text"
									onChange={(e) => setSsoUrl(e.target.value)}
									onBlur={(e) =>
										setSsoUrl(e.target.value.trim())
									}
									value={ssoUrl}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<Form.Label>
									Certificate Signature Algorithm
								</Form.Label>
								<Form.Control
									as="select"
									className="algorithm-select"
									onChange={(e) => {
										setSignatureAlgorithm(e.target.value);
									}}
									value={signatureAlgorithm}
									defaultValue="sha256"
								>
									<option value="sha1">
										SHA-1 (Not Recommended)
									</option>
									<option value="sha256">SHA-256</option>
									<option value="sha512">SHA-512</option>
								</Form.Control>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<Form.Label>Certificate</Form.Label>
								<Form.Control
									as="textarea"
									className="sso-settings-text"
									onChange={(e) =>
										setCertificate(e.target.value)
									}
									onBlur={(e) =>
										setCertificate(e.target.value.trim())
									}
									value={certificate}
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<Form.Label>Force SSO</Form.Label>
								<div className="sso-settings-checkbox">
									<Checkbox
										id="check-force-sso"
										checked={forceSso}
										onChange={(e) =>
											setForceSso(e.target.checked)
										}
									/>
									<p>
										If checked, only Admin users will be
										able to login with a password.
									</p>
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>

			<ConfirmationModal
				isOpen={isModalOpen}
				title="Delete SSO"
				confirmationText="Are you sure you want to delete the Single Sign-on Settings? This will cause SSO to be disabled for all users."
				confirmationButtonLabel="DELETE"
				onCancel={() => setIsModalOpen(false)}
				onConfirm={deleteSamlSettings}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(SsoSettings);
