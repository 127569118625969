import React from 'react';
import { Facet } from '@elastic/react-search-ui';

import Checkbox from '../../checkbox';

import styles from './styles.scss';

const CheckboxList = (props) => {
	const { field, fieldLabel } = props;

	return (
		<Facet
			field={field}
			label={fieldLabel}
			view={({ label, onRemove, onSelect, options, showMore, onMoreClick }) => {
				return (
					<fieldset className={styles.filterCheckboxList}>
						<h3>{label}</h3>
						<ul>
							{options &&
								options.map((option, key) => {
									const checked = option.selected;

									return (
										<li key={`checkbox-${key}`}>
											<Checkbox
												id={
													option.id ||
													option.value.replace(/\s+/g, '-').toLowerCase() + key
												}
												label={option.value}
												checked={checked || false}
												onChange={() =>
													checked
														? onRemove(option.value)
														: onSelect(option.value)
												}
											/>
										</li>
									);
								})}
						</ul>
						{showMore && (
							<div className={`${styles.seeMore}`} onClick={onMoreClick}>
								See More
							</div>
						)}
					</fieldset>
				);
			}}
		/>
	);
};

export default CheckboxList;
