import React, { useCallback, useState } from 'react';

// External Components
import { Cell, Legend, Pie, PieChart, Sector } from 'recharts';

// Internal Libraries
import { hexToRgba } from 'utils';

// Labels for the chart will only work if the component is declared as a PureComponent.
// React.memo accomplishes this.
const ContentMixChart = React.memo((props) => {
	const {
		filterChange,
		contentBreakdown,
		interactive = true,
		verticalLegend = false,
	} = props;

	// These variables may need to be put into Redux to control what content matches
	// are displayed in the content rows.
	const [hoverIndex, setHoverIndex] = useState(null);
	const [clickIndex, setClickIndex] = useState(-1);

	const onItemEnter = useCallback(
		(index) => {
			setHoverIndex(index);
		},
		[setHoverIndex]
	);

	const onItemLeave = useCallback(
		(index) => {
			setHoverIndex(null);
		},
		[setHoverIndex]
	);

	const onItemClick = useCallback(
		(index) => {
			setClickIndex(index);
		},
		[setClickIndex]
	);

	const onSetFilter = useCallback(
		(filterValue) => {
			filterChange(filterValue);
		},
		[clickIndex]
	);

	let pieData = [];

	const chartWidth = verticalLegend ? 180 : 650;
	const chartHeight = verticalLegend ? 230 : 112;

	const chartColors = ['#8F76E3', '#4D91A0', '#4F4375'];

	if (contentBreakdown) {
		for (let i = 0; i < Object.keys(contentBreakdown).length; i++) {
			const contentType = Object.keys(contentBreakdown)[i];
			if (contentType === 'total') {
				continue;
			}
			let pieSlice = {};
			switch (contentType) {
				case 'audio':
					pieSlice['name'] = 'Audio Content';
					break;
				case 'video':
					pieSlice['name'] = 'Video Content';
					break;
				case 'written':
					pieSlice['name'] = 'Written Content';
					break;
				default:
					pieSlice['name'] = 'Other Content';
			}
			pieSlice['value'] =
				contentBreakdown[contentType] / contentBreakdown.total;
			pieSlice['color'] = chartColors[i % chartColors.length];
			pieSlice['contentType'] = contentType;

			pieData.push(pieSlice);
		}
	}

	const originalStartAngle = 90;
	const originalEndAngle = 450;
	const originalInnerRadius = 19;
	const originalOuterRadius = 49;
	const activeOuterRadius = originalOuterRadius + 7;
	const originalCornerRadius = 0;

	const renderActiveShape = (props) => {
		const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
			props;

		return (
			<g>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={innerRadius}
					outerRadius={activeOuterRadius}
					fill={fill}
					cornerRadius={originalCornerRadius}
				/>
			</g>
		);
	};

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		index,
	}) => {
		const radius = innerRadius + (49 - innerRadius) * 0.5;
		let x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		if (percent >= 0.1)
			return (
				<text
					style={{
						fontSize: '0.625rem',
						fontWeight: '700',
						lineHeight: '11.28px',
					}}
					textAnchor="middle"
					x={x}
					y={y}
					fill="white"
					dominantBaseline="central"
				>
					{`${(percent * 100).toFixed(0)}%`}
				</text>
			);
	};

	const renderCustomizedLegend = ({ payload }) => {
		return (
			<ul className="legend">
				<li
					key={`item-all`}
					className={
						interactive
							? clickIndex === -1 || hoverIndex === -1
								? 'active'
								: ''
							: ''
					}
					onMouseEnter={interactive ? () => onItemEnter(-1) : null}
					onMouseLeave={interactive ? () => onItemLeave(-1) : null}
					onClick={
						interactive
							? () => {
									onItemClick(-1);
									onSetFilter('all');
							  }
							: null
					}
				>
					<PieChart width={24} height={24}>
						<Pie
							cx={7}
							cy={7}
							data={pieData}
							startAngle={originalStartAngle}
							endAngle={originalEndAngle}
							innerRadius={6}
							outerRadius={12}
							labelLine={false}
							dataKey="value"
							isAnimationActive={false}
							paddingAngle={1}
						>
							{pieData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={entry.color} />
							))}
						</Pie>
					</PieChart>
					All Content - 100%
				</li>
				{payload.map((entry, index) => (
					<li
						key={`item-${index}`}
						className={
							interactive ? (clickIndex === index ? 'active' : '') : ''
						}
						onMouseEnter={interactive ? () => onItemEnter(index) : null}
						onMouseLeave={interactive ? () => onItemLeave(index) : null}
						onClick={
							interactive
								? () => {
										onItemClick(index);
										onSetFilter(entry.payload.contentType);
								  }
								: null
						}
					>
						<span
							className="item-color"
							style={{ backgroundColor: entry.color }}
						></span>
						{entry.value} - {(entry.payload.percent * 100).toFixed(0)}%
					</li>
				))}
			</ul>
		);
	};

	return (
		<div
			className={`content-mix-chart ${verticalLegend ? 'legend-vertical' : ''}`}
		>
			<PieChart width={chartWidth} height={chartHeight}>
				<Pie
					activeIndex={hoverIndex !== null ? hoverIndex : clickIndex}
					activeShape={renderActiveShape}
					cx={verticalLegend ? 85 : 52}
					cy={verticalLegend ? 49 : 51}
					data={pieData}
					startAngle={originalStartAngle}
					endAngle={originalEndAngle}
					innerRadius={originalInnerRadius}
					outerRadius={
						hoverIndex === -1 ? activeOuterRadius : originalOuterRadius
					}
					labelLine={false}
					label={renderCustomizedLabel}
					cornerRadius={originalCornerRadius}
					dataKey="value"
					isAnimationActive={false}
					paddingAngle={1}
				>
					{pieData.map((entry, index) => {
						let fillColor;
						let solidColor = entry.color;
						let transparentColor = hexToRgba(solidColor, 0.5);

						// Only highlight the sectors of the pie chart that are either clicked or hovered.
						// Clicked/Hovered sectors will use the solidColor variable.
						if (hoverIndex !== null) {
							if (hoverIndex === -1) {
								fillColor = solidColor;
							} else if (hoverIndex === index) {
								fillColor = solidColor;
							} else {
								fillColor = transparentColor;
							}
						} else {
							if (clickIndex === -1) {
								fillColor = solidColor;
							} else if (clickIndex === index) {
								fillColor = solidColor;
							} else {
								fillColor = transparentColor;
							}
						}

						return <Cell key={`cell-${index}`} fill={fillColor} />;
					})}
				</Pie>
				{contentBreakdown && <Legend content={renderCustomizedLegend} />}
			</PieChart>
		</div>
	);
});

export { ContentMixChart };
