import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { Tooltip } from 'react-bootstrap';
import MetricCard from 'components/shared/metricCard';

const AverageCompletion = (props) => {
	const { variables, comparisonVariables, query, showPercents } = props;

	const [value, setValue] = useState(0);
	const [comparisonValue, setComparisonValue] = useState(0);

	const { loading } = useQuery(query, {
		variables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const { avg_completion_time } = data[query?.definitions[0]?.name?.value];
			setValue(Math.round(avg_completion_time));
		},
	});

	const { compLoading } = useQuery(query, {
		variables: comparisonVariables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const { avg_completion_time } = data[query?.definitions[0]?.name?.value];
			setComparisonValue(Math.round(avg_completion_time));
		},
	});

	const tooltip = (
		<Tooltip className="metric-card-tooltip">
			<p className="label tooltip-stat">
				This Period: <span className="value">{value}%</span>
			</p>

			{comparisonVariables.startDate && (
				<>
					<hr />
					<p className="label label-date previous-period">
						Previously: <span className="value">{comparisonValue}%</span>
					</p>
				</>
			)}
		</Tooltip>
	);

	return (
		<MetricCard
			value={value || 0}
			comparisonValue={comparisonValue}
			loading={loading || compLoading}
			tooltip={tooltip}
			label="Avg Completion Time"
			valueLabel="%"
			showPercents={showPercents}
			hideTooltip={true}
		/>
	);
};

export default AverageCompletion;
