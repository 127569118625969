export const ACTIONS = {
	SHOW_MODAL: 'CASTED/DELETE_CLIP_MODAL/SHOW_MODAL',
	HIDE_MODAL: 'CASTED/DELETE_CLIP_MODAL/HIDE_MODAL',
};

export const initialState = {
	showModal: false,
  clipToDelete: null
};

export const hideDeleteClipModal = () => {
	return {
		type: ACTIONS.HIDE_MODAL,
	};
};

export const showDeleteClipModal = (clipToDelete) => {
	return {
		type: ACTIONS.SHOW_MODAL,
    payload: clipToDelete
	};
};

const deleteClipModalReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ACTIONS.HIDE_MODAL:
			return {
				...state,
				showModal: false,
        clipToDelete: null
			};
		case ACTIONS.SHOW_MODAL:
			return {
				...state,
				showModal: true,
        clipToDelete: payload
			};
		default:
			return state;
	}
};

export default deleteClipModalReducer;