import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaSearchPlus, FaSearchMinus } from 'react-icons/fa';

const WaveformTools = (props) => {
	const { region = {}, duration = 1, onZoom = () => {} } = props;

	/**
	 * Hold Zoom level state
	 * fire callback when zoom level changes
	 */
	const [zoomLevel, setZoomLevel] = useState(0);
	useEffect(() => {
		onZoom(zoomLevel);
	}, [zoomLevel]);

	/**
	 * Calculate the pixels per second of audio
	 * Watch for window changes which will change our element width
	 * When parent finishes it's work, duration will be set
	 */
	const [zoomRatio, setZoomRatio] = useState(0);
	const calcZoomRatio = () => {
		const w = document.querySelector('.casted-waveform--waveform').scrollWidth;
		setZoomRatio(w / duration);
	};
	useEffect(() => {
		window.addEventListener('resize', calcZoomRatio);
		return () => window.removeEventListener('resize', calcZoomRatio);
	}, []);
	useEffect(() => {
		calcZoomRatio();
	}, [duration]);

	const renderZoomRange = () => {
		const max = 900;
		const min = 0;
		const step = 100;

		const zoomIn = () => {
			setZoomLevel(Math.min(zoomLevel + step, max));
		};
		const zoomOut = () => {
			setZoomLevel(Math.max(zoomLevel - step, min));
		};

		return (
			<div className="d-flex flex-column waveform--tool mr-3 ml-4 my-1 h-100 py-2">
				<FaSearchPlus
					className="zoom--icon zoom--plus"
					onClick={zoomIn}
					size={16}
				/>
				<div onClick={() => setZoomLevel(0)}>
					<p className="m-0 py-2">{zoomLevel + 100}%</p>
				</div>
				<FaSearchMinus
					className="zoom--icon zoom--minus"
					onClick={zoomOut}
					size={16}
				/>
			</div>
		);
	};

	return (
		<div className="waveform-tools h-100">
			<Row noGutters>
				<Col xs="auto">{renderZoomRange()}</Col>
			</Row>
		</div>
	);
};

export default WaveformTools;
