import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import { ContentMixChart } from '../contentMixChart';
import { Card, Spinner } from 'react-bootstrap';
import MetricEmptyState from '../metricEmptyState';

const CompanyProfileContentMixChart = (props) => {
	const {
		accountId,
		companyId,
		dateRange,
		collectionId = '',
		episodeId = '',
		themeId = '',
	} = props;

	const [currentMetrics, setCurrentMetrics] = useState(null);

	const variables = {
		accountId: accountId,
		companyId: companyId,
		startDate: dateRange.start,
		endDate: dateRange.end,
		...(collectionId && { collectionId: collectionId }),
		...(episodeId && { episodeId: episodeId }),
		...(themeId && { themeId: themeId }),
	};

	const { loading } = useQuery(queries.getCompanyProfileMetricsContentMix, {
		variables,
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			let contentBreakdown = {};
			if (data.getCompanyProfileMetricsContentMix.length > 0) {
				data.getCompanyProfileMetricsContentMix.forEach((element) => {
					contentBreakdown['total'] =
						contentBreakdown['total'] + element.count ||
						element.count;
					if (element.isVideo) {
						contentBreakdown['video'] =
							contentBreakdown['video'] + element.count ||
							element.count;
					} else {
						contentBreakdown['audio'] =
							contentBreakdown['audio'] + element.count ||
							element.count;
					}
				});
				setCurrentMetrics(contentBreakdown);
			} else {
				setCurrentMetrics(null);
			}
		},
	});

	if (!loading && !currentMetrics) {
		return <MetricEmptyState metricName={'Content Mix'} />;
	}

	return (
		<Card>
			<Card.Body>
				<div>
					<Card.Title className="chart-title text-center">
						Content Mix
					</Card.Title>
				</div>

				{loading ? (
					<div className="content-mix--loading">
						<Spinner animation="border" variant="info" />
					</div>
				) : (
					<div className="">
						<ContentMixChart
							contentBreakdown={currentMetrics}
							verticalLegend={true}
							interactive={false}
						/>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};

export default CompanyProfileContentMixChart;
