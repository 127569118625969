import React, { useEffect, useRef } from 'react';

import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchProvider } from '@elastic/react-search-ui';

import { RECORD_TYPES } from '../constants';

const SearchCore = (props) => {
	const {
		searchKey = '',
		engineName = '',
		endpointBase = '',
		accountId = '',
		recordTypes = [
			RECORD_TYPES.EPISODE_TRANSCRIPT,
			RECORD_TYPES.EPISODE,
			RECORD_TYPES.CLIP,
			RECORD_TYPES.SHARE,
		],
		// includeArray determines what collections users have access to.
		// A null value will return all collections.
		// An empty array will return no collections.
		includeArray = null,
		themeMatches = null,
		resultsPerPage = 20,
		children,
	} = props;

	const themeMatchesRef = useRef(null);

	useEffect(() => {
		themeMatchesRef.current = themeMatches;
	}, [themeMatches]);

	const connector = new AppSearchAPIConnector({
		searchKey: searchKey,
		engineName: engineName,
		endpointBase: endpointBase,
		cacheResponses: false,
		beforeSearchCall: (existingSearchOptions, next) => {
			if (recordTypes && recordTypes.length > 0) {
				const recordTypeArray = {
					any: [{ record_type: recordTypes }],
				};

				existingSearchOptions.filters.all.push(recordTypeArray);
			}

			// Alter filters object to only include podcast_ids that the user
			// has access to
			if (includeArray && includeArray.length > 0) {
				const podcastArray = {
					any: [{ podcast_id: includeArray }],
				};

				existingSearchOptions.filters.all.push(podcastArray);
			}

			// Alter filters object to only include episode_ids that the theme
			// has matched with
			if (themeMatchesRef.current && themeMatchesRef.current.length > 0) {
				const themeArray = {
					any: [{ episode_id: themeMatchesRef.current }],
				};

				existingSearchOptions.filters.all.push(themeArray);
			}

			return next({
				...existingSearchOptions,
			});
		},
	});

	// Create Now
	let currentDate = new Date();
	const todayDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T23:59:59.999Z';

	// Create Now - 1 week
	currentDate = new Date(currentDate);
	currentDate.setDate(currentDate.getDate() - 7);
	const lastWeekDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T00:00:00.000Z';

	// Create Now - 1 month
	currentDate = new Date(currentDate);
	currentDate.setMonth(currentDate.getMonth() - 1);
	const lastMonthDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T00:00:00.000Z';

	// Create Now - 1 year
	currentDate = new Date(currentDate);
	currentDate.setFullYear(currentDate.getFullYear() - 1);
	const lastYearDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T00:00:00.000Z';

	// accountId needs to be a string in order to be used when querying
	const stringAccountId = new String(accountId);

	// Don't let those hackers have access to other accounts
	// Hard coding the user's account_id
	const resetUserAccountFilter = (filters) => {
		let updatedFilters = [];

		if (filters.some((filter) => filter.field === 'account_id')) {
			filters.map((filter) => {
				if (
					filter.field === 'account_id' &&
					filter.values[0] !== stringAccountId
				) {
					const newFilter = {
						...filter,
						values: [stringAccountId],
					};
					updatedFilters = [...filters, newFilter];
				}
			});
		} else {
			const newFilter = {
				field: 'account_id',
				type: 'all',
				values: [stringAccountId],
			};
			updatedFilters = [...filters, newFilter];
		}

		return updatedFilters;
	};

	const onSearchCallback = (state, queryConfig, next) => {
		let filters = resetUserAccountFilter(state.filters);

		const returnState = {
			...state,
			filters,
		};
		return next(returnState, queryConfig);
	};

	return (
		<SearchProvider
			config={{
				apiConnector: connector,
				alwaysSearchOnInitialLoad: true,
				initialState: {
					resultsPerPage: resultsPerPage,
				},
				trackUrlState: false,
				onSearch: (state, queryConfig, next) =>
					onSearchCallback(state, queryConfig, next),
				searchQuery: {
					search_fields: {
						episode_name: {
							weight: 10,
						},
						episode_description: {
							weight: 5,
						},
						transcript: {
							weight: 1,
						},
						share_title: {
							weight: 10,
						},
						share_description: {
							weight: 5,
						},
					},
					result_fields: {
						record_type: {
							raw: {},
						},
						account_id: {
							raw: {},
						},
						podcast_id: {
							raw: {},
						},
						episode_id: {
							raw: {},
						},
						episode_name: {
							snippet: {
								size: 300,
								fallback: true,
							},
						},
						episode_description: {
							snippet: {
								size: 300,
								fallback: true,
							},
						},
						episode_episode_number: {
							raw: {},
						},
						episode_season: {
							raw: {},
						},
						transcript: {
							snippet: {
								size: 300,
								fallback: true,
							},
						},
						episode_published_at: {
							raw: {},
						},
						clip_id: {
							raw: {},
						},
						clip_name: {
							snippet: {
								size: 300,
								fallback: true,
							},
						},
						episode_thumbnail: {
							raw: {},
						},
						episode_object: {
							raw: {},
						},
						start_time: {
							raw: {},
						},
						end_time: {
							raw: {},
						},
						share_id: {
							raw: {},
						},
						share_share_slug: {
							raw: {},
						},
						share_share_type: {
							raw: {},
						},
						share_content_type: {
							raw: {},
						},
						share_title: {
							snippet: {
								size: 300,
								fallback: true,
							},
						},
						share_description: {
							snippet: {
								size: 300,
								fallback: true,
							},
						},
						share_destination: {
							snippet: {
								size: 300,
								fallback: true,
							},
						},
						share_created_by: {
							raw: {},
						},
						share_updated_at: {
							raw: {},
						},
						share_created_at: {
							raw: {},
						},
						share_status: {
							raw: {},
						},
					},
					disjunctiveFacets: [
						'podcast_name',
						'record_type',
						'clip_type',
						'clip_has_audiogram',
						'episode_keywords',
						'clip_keywords',
						'episode_published_at',
						'share_share_type',
						'share_status',
						'share_created_at',
					],
					facets: {
						podcast_name: {
							type: 'value',
							size: 100,
						},
						record_type: {
							type: 'value',
							size: 100,
						},
						clip_type: {
							type: 'value',
						},
						clip_has_audiogram: {
							type: 'value',
						},
						episode_keywords: {
							type: 'value',
							size: 250,
						},
						clip_keywords: {
							type: 'value',
						},
						episode_published_at: {
							type: 'range',
							ranges: [
								{ to: todayDate, name: 'Any Date' },
								{
									from: lastWeekDate,
									to: todayDate,
									name: 'Past Week',
								},
								{
									from: lastMonthDate,
									to: todayDate,
									name: 'Past Month',
								},
								{
									from: lastYearDate,
									to: todayDate,
									name: 'Past Year',
								},
							],
						},
						share_share_type: {
							type: 'value',
						},
						share_status: {
							type: 'value',
						},
						share_created_at: {
							type: 'range',
							ranges: [
								{ to: todayDate, name: 'Any Date' },
								{
									from: lastWeekDate,
									to: todayDate,
									name: 'Past Week',
								},
								{
									from: lastMonthDate,
									to: todayDate,
									name: 'Past Month',
								},
								{
									from: lastYearDate,
									to: todayDate,
									name: 'Past Year',
								},
							],
						},
					},
				},
			}}
		>
			{children}
		</SearchProvider>
	);
};

export default SearchCore;
