import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import Toast from 'services/toast';
import { v4 as uuid } from 'uuid';
import crypto from 'crypto';
import { Container, Col, Modal, Form } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import Button from 'components/shared/button';
import CopyToClipboardButton from 'components/shared/copyToClipboardButton';
import { trackEvent } from 'services/vitally';

const ApiKeyModal = (props) => {
	const {
		accountId,
		title,
		edit,
		isOpen,
		keyData = {},
		onHide = () => {},
		refreshKeys = () => {},
	} = props;

	const [clientId, setClientId] = useState(uuid());
	const [clientSecret, setClientSecret] = useState(
		crypto.randomBytes(32).toString('hex')
	);
	const [name, setName] = useState('');
	const [saveKeyLoading, setSaveKeyLoading] = useState(false);

	const copyIdRef = useRef(null);
	const copySecretRef = useRef(null);

	const [updateKey] = useMutation(mutations.updateApiKey);
	const [createKey] = useMutation(mutations.createApiKey);

	useEffect(() => {
		if (keyData) {
			if (keyData.clientId) {
				setClientId(keyData.clientId);
			}

			if (keyData.name) {
				setName(keyData.name);
			}
		}
	}, [keyData]);

	const onSubmitClick = async () => {
		setSaveKeyLoading(true);
		if (edit) {
			await updateKey({ variables: { id: keyData.id, name } });
		} else {
			await createKey({
				variables: { accountId, name, clientId, clientSecret },
			});
			trackEvent('add-api-key', { keyName: name });
		}
		Toast.success('Key Saved');
		setSaveKeyLoading(false);
		refreshKeys();
		onHide(true);
		refreshItems();
	};

	const refreshItems = () => {
		setName('');
		setClientId(uuid());
		setClientSecret(crypto.randomBytes(32).toString('hex'));
	};

	return (
		<Modal show={isOpen} onHide={onHide} centered backdrop="static">
			<Container className="key-modal">
				<Form.Row className="my-3">
					<Col>
						<h4 className="text-center title">{title}</h4>
					</Col>
					<FaTimes onClick={onHide} />
				</Form.Row>
				<Form.Row className="mb-3">
					<Col>
						<Form.Label>Name</Form.Label>
						<Form.Control
							className="key-description-text"
							required
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Col>
				</Form.Row>
				<Form.Row className="mb-3">
					<Col>
						<Form.Label>Client ID</Form.Label>
						<Form.Control
							className="key-client-id-text"
							readOnly
							value={clientId}
							ref={copyIdRef}
						/>
					</Col>
					<Col xs="2">
						<CopyToClipboardButton
							className="copy-client-btn"
							copyRef={copyIdRef}
							btnText="Copy"
						/>
					</Col>
				</Form.Row>
				{!edit && (
					<Form.Row className="mb-3">
						<Col>
							<Form.Label>Client Secret</Form.Label>
							<Form.Control
								className="key-client-secret-text"
								readOnly
								value={clientSecret}
								ref={copySecretRef}
							/>
							<Form.Text className="helper-text text-danger">
								Be sure to save the Client Secret. It cannot be
								viewed or retrieved again.
							</Form.Text>
						</Col>
						<Col xs="2">
							<CopyToClipboardButton
								className="copy-client-btn"
								copyRef={copySecretRef}
								btnText="Copy"
							/>
						</Col>
					</Form.Row>
				)}
				<Form.Row>
					<Col className="d-flex justify-content-center">
						<Button
							variant="success"
							className="submit-key"
							onClick={onSubmitClick}
							disabled={saveKeyLoading}
						>
							{saveKeyLoading ? 'SAVING...' : 'SAVE'}
						</Button>
					</Col>
				</Form.Row>
			</Container>
		</Modal>
	);
};

export default ApiKeyModal;
