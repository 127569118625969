import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';

// Internal Libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { dispatch } from 'store/store';
import {
	showDeleteImportedContentModal,
	hideDeleteImportedContentModal,
} from 'store/reducers/ui/modals/deleteImportedContentModal';
import { trackEvent } from 'services/vitally';
import Toast from 'services/toast';

// External Components
import { Spinner } from 'react-bootstrap';
import {
	FaChevronLeft,
	FaEllipsisH,
	FaExternalLinkAlt,
	FaSearch,
} from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';

// Internal Components
import { ContentRow } from '../../shared/contentRow';
import Pagination from 'components/shared/pagination';
import Modal from 'components/shared/modal';

// Internal Assets
import podcat from 'assets/images/podcat-playing-v2.png';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const ImportedItems = (props) => {
	const {
		history,
		match,
		permissions,
		selectedAccountId: accountId,
		modals,
	} = props;
	const { params } = match;
	const { externalObjectId } = params;

	const importedContentPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.IMPORTED_CONTENT)
				.rolePermission
		: {};

	const importedItemsContainerRef = useRef(null);

	const [searchString, setSearchString] = useState('');

	// Pagination setup
	const resultsPerPage = 10;
	const [currentPage, setCurrentPage] = useState(1);

	/** Queries & Mutations **/
	const {
		data: externalObjectData,
		loading: externalObjectLoading,
		error: externalObjectError,
		refetch: refetchExternalObjects,
	} = useQuery(queries.getExternalObjectById, {
		variables: { externalObjectId },
		fetchPolicy: 'no-cache',
	});

	const {
		data: childExternalObjectData,
		loading: childExternalObjectLoading,
		error: childExternalObjectError,
		refetch: refetchChildExternalObjects,
	} = useQuery(queries.getExternalObjects, {
		variables: {
			accountId,
			parentId: externalObjectId,
			order: 'externalUpdatedAt',
		},
		fetchPolicy: 'no-cache',
	});

	const [deleteExternalObjectById] = useMutation(
		mutations.deleteExternalObjectById,
		{
			variables: { externalObjectId },
		}
	);

	const { externalObject = {} } = externalObjectData || {};
	const { externalObjects = {} } = childExternalObjectData || {};

	// Scrolls user to top of results when paging
	const scrollToTop = () => {
		importedItemsContainerRef.current.scrollIntoView();
	};

	const renderContent = () => {
		if (externalObjectLoading || childExternalObjectLoading) {
			return (
				<div className="imported-content-loading-container">
					<Spinner
						className="imported-content-loading"
						animation="grow"
						variant="info"
					/>
				</div>
			);
		}

		let returnContent = [];
		let childExternalObjectContent;
		let podcatMessage;

		if (externalObjects.length > 0) {
			returnContent.push(
				<div className="imported-items-search">
					<FaSearch />
					<input
						placeholder="Search your content here."
						onChange={(e) => setSearchString(e.target.value)}
					/>
				</div>
			);

			let filteredChildObjects = externalObjects.filter(
				(childExternalObject) =>
					searchString === '' ||
					childExternalObject.name
						.toLowerCase()
						.search(searchString.toLowerCase()) !== -1
			);

			if (filteredChildObjects.length > 0) {
				childExternalObjectContent = (
					<>
						<div className="content-rows">
							{filteredChildObjects.map(
								(childExternalObject, i) => {
									const maxResult =
										currentPage * resultsPerPage;
									const minResult =
										maxResult - resultsPerPage;

									if (minResult <= i && i < maxResult) {
										return (
											<ContentRow
												key={i}
												objectTypeName={
													childExternalObject
														.externalObjectType
														?.objectName
												}
												name={childExternalObject.name}
												image={
													childExternalObject
														.externalObjectType
														?.objectImage
												}
											>
												<div className="content-actions">
													<Dropdown className="episode--actions">
														<Dropdown.Toggle
															id="episode-actions"
															as="div"
														>
															<FaEllipsisH />
														</Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item
																href={
																	childExternalObject.url
																}
																target="_blank"
															>
																View Content{' '}
																<FaExternalLinkAlt className="icon" />
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</ContentRow>
										);
									}
								}
							)}
						</div>
						<Pagination
							current={currentPage}
							onChange={(newPage) => {
								setCurrentPage(newPage);
								scrollToTop();
							}}
							resultsPerPage={resultsPerPage}
							totalResults={filteredChildObjects.length}
						/>
					</>
				);
			} else {
				podcatMessage =
					"Podcat wasn't able to find any content based on your search.";
			}
		} else {
			podcatMessage = 'Podcat will display related child content here.';
		}

		if (podcatMessage) {
			childExternalObjectContent = (
				<div className="imported-content-empty">
					<img className="podcat" src={podcat} />
					<p>{podcatMessage}</p>
				</div>
			);
		}

		returnContent.push(
			<div className="imported-items-content">
				<div className="content-sidebar">
					<div className="content-info">
						<div className="content-thumbnail">
							<img
								src={
									externalObject.externalObjectType
										.objectImage
								}
							/>
						</div>
						<div className="content-name">
							{externalObject.name}
						</div>
					</div>
					{importedContentPermission.canEdit && (
						<div
							className="remove-content"
							onClick={() =>
								dispatch(showDeleteImportedContentModal())
							}
						>
							Remove Content
						</div>
					)}
				</div>
				<div className="imported-content-body">
					{childExternalObjectContent && childExternalObjectContent}
				</div>
			</div>
		);

		return returnContent;
	};

	return (
		<div
			className="imported-items-container"
			ref={importedItemsContainerRef}
		>
			<div className="imported-items-nav">
				<h2 onClick={() => history.goBack()}>
					<FaChevronLeft className="arrow-left" /> Imported Content
				</h2>
			</div>
			{renderContent()}
			<Modal
				show={modals.deleteImportedContentModal.showModal}
				handleClose={() => {
					dispatch(hideDeleteImportedContentModal());
				}}
				title="Delete Imported Content"
				dialogOptions={[
					{
						variant: 'light',
						text: 'Cancel',
						onClick: () => {
							dispatch(hideDeleteImportedContentModal());
						},
					},
					{
						variant: 'danger',
						text: 'Delete',
						onClick: async () => {
							await deleteExternalObjectById({
								variables: {
									externalObjectId: externalObject?.id,
								},
							});
							trackEvent('delete-imported-content', {
								name: externalObject?.name,
							});
							dispatch(hideDeleteImportedContentModal());

							try {
								const res = await deleteExternalObjectById({
									variables: {
										externalObjectId: externalObject?.id,
									},
								});

								const { data } = res;
								const {
									deleteExternalObjectById:
										deletedExternalObject,
								} = data;

								if (
									deletedExternalObject &&
									deletedExternalObject.id
								) {
									trackEvent('delete-imported-content', {
										name: externalObject?.name,
									});
								}
								dispatch(hideDeleteImportedContentModal());

								history.push(
									`/account/${accountId}/allContent/importedContent`
								);
							} catch (e) {
								console.error(e);
								Toast.error('Unable to delete content');
							}
						},
					},
				]}
			>
				<p>
					Are you sure you want to delete{' '}
					{`${externalObject?.name || ''}`}?
				</p>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
	modals: state.ui.modals,
});

export const ImportedItemsWithRouter = withRouter(
	connect(mapStateToProps)(ImportedItems)
);
