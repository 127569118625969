import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client/react/hooks';

import Button from 'components/shared/button';

import queries from 'services/graphql/queries';
import ApiKeyListRow from 'components/settings/apiKeys/apiKeyListRow';
import ApiKeyModal from 'components/settings/apiKeys/apiKeyModal';

const ApiKeyList = (props) => {
	const { selectedAccountId: accountId } = props;

	const [addNewKey, setAddNewKey] = useState(false);

	const {
		data: { apiKeys = [] } = {},
		loading,
		error,
		refetch: refreshKeys,
	} = useQuery(queries.apiKeys, { variables: { accountId } });

	const nonAccountLevelKeys =
		apiKeys.filter((apiKey) => !apiKey.isAccountLevel) || [];

	return (
		<Container className="api-keys-screen p-5">
			<Row className="mb-5">
				<Col>
					<h2 className="title">Manage API Keys</h2>
				</Col>
				<Col className="d-flex justify-content-end">
					<Button
						variant="link"
						className="api-docs--view"
						target="_blank"
						href="https://help.casted.us/en/api"
					>
						Read the API Docs
					</Button>
					<Button
						variant="success"
						className="add-new-key ml-5"
						onClick={() => setAddNewKey(true)}
					>
						ADD NEW
					</Button>
				</Col>
			</Row>
			{nonAccountLevelKeys.length > 0 && (
				<div className="keys-list">
					<Row>
						<Col xs="3">
							<h5 className="keys-list--header">Name</h5>
						</Col>
						<Col>
							<h5 className="keys-list--header">Client ID</h5>
						</Col>
					</Row>
					{nonAccountLevelKeys.map((a) => (
						<ApiKeyListRow
							key={a.id}
							accountId={accountId}
							apiKey={a}
						/>
					))}
				</div>
			)}
			<ApiKeyModal
				title={'Add New Key'}
				edit={false}
				isOpen={addNewKey}
				onHide={() => {
					setAddNewKey(false);
				}}
				accountId={accountId}
				refreshKeys={refreshKeys}
			/>
		</Container>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(ApiKeyList);
