import React from 'react';
import classnames from 'classnames';
import { Button } from 'react-bootstrap';

const CastedButton = (props) => {
	const {
		name = '',
		onClick = () => {},
		rootStyles = {},
		children = null,
		variant = 'contained',
		disabled,
		className = '',
		link = false,
		href = '',
		target = '_blank',
		title = '',
		showLoading = false,
		type = 'button',
	} = props;

	let btnTypeClass = 'casted-button';
	let btnVariant = 'success';

	switch (variant) {
		case 'flat':
			btnTypeClass = 'button-flat';
			break;
		case 'link-no-href':
			btnVariant = 'link';
			break;
		case 'light':
			btnVariant = 'light';
			break;
		case 'dark':
			btnVariant = 'dark';
			break;
		case 'danger':
			btnVariant = 'danger';
			break;
		case 'primary':
			btnVariant = 'primary';
			break;
		case 'cancel':
			btnTypeClass = 'btn-cancel';
		case 'outline-light':
			btnVariant = 'outline-light';
			break;
		case 'outline-dark':
			btnVariant = 'outline-dark';
			break;
		case 'success':
		default:
			btnVariant = 'success';
			break;
	}

	const disabledClass = disabled ? 'disabled' : '';

	if (link || variant === 'link') {
		return (
			<a
				name={name}
				href={href}
				className={classnames(
					'button',
					'button-link',
					disabledClass,
					showLoading ? 'button-loading' : '',
					className
				)}
				style={{ ...rootStyles }}
				target={target}
				rel="noopener noreferrer"
				title={title}
				onClick={onClick}
			>
				{!showLoading && children}
			</a>
		);
	}

	return (
		<Button
			className={`casted-button ${className} ${btnTypeClass}`}
			variant={btnVariant}
			name={name}
			onClick={onClick}
			style={{ ...rootStyles }}
			disabled={disabled || showLoading}
			title={title}
			type={type}
			href={href}
			target={target}
		>
			{!showLoading && children}
		</Button>
	);
};

export default CastedButton;
