import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import PercentageList from 'components/shared/metricPercentageList';
import MetricEmptyState from 'components/shared/metricEmptyState';
import { FaFacebook, FaLinkedin, FaTwitter, FaLink } from 'react-icons/fa';
import tracking from 'assets/icons/trackinglink.svg';

const ClipTraffic = (props) => {
	const variables = {
		clipId: props.clipId,
		shareSlug: props.shareSlug,
		startDate: props.dateRange.start,
		endDate: props.dateRange.end,
	};

	const { data, loading } = useQuery(queries.getTrafficReferrals, {
		variables,
	});
	const { getTrafficReferrals } = data || {};
	const { total, __typename, ...trafficReferrals } = getTrafficReferrals || {};
	const valuesTotal = Object.values(trafficReferrals).reduce((acc, value) => {
		return acc + value;
	}, 0);

	let body = null;

	const values = Object.entries(trafficReferrals).map(([key, value]) => {
		switch (key) {
			case 'direct':
				return {
					label: 'Direct link',
					key: key,
					value: value,
					icon: <FaLink />,
				};
			case 'tracking':
				return {
					label: 'Tracking',
					key: key,
					value: value || 0,
					icon: <img src={tracking} alt="Tracking" />,
				};
			case 'facebook':
				return {
					label: 'Facebook',
					key: key,
					value: value,
					icon: <FaFacebook />,
				};
			case 'linkedin':
				return {
					label: 'LinkedIn',
					key: key,
					value: value,
					icon: <FaLinkedin />,
				};
			case 'twitter':
				return {
					label: 'Twitter',
					key: key,
					value: value,
					icon: <FaTwitter />,
				};
		}
	});

	if (values.length < 1) {
		body = <MetricEmptyState metricName="Traffic Referral" />;
	}

	if (values.length > 0) {
		body = (
			<PercentageList
				values={values || {}}
				total={valuesTotal}
				loading={loading}
				title="Traffic Referrals"
			/>
		);
	}

	return body;
};

export default ClipTraffic;
