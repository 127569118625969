import React, { useState, useEffect } from 'react';

// LaunchDarkly
import { useFlags } from 'launchdarkly-react-client-sdk';

// Redux
import { connect } from 'react-redux';
import { DRAWER_TABS } from 'store/reducers/ui/episodes/studio';

// Internal Components
import ClipList from '../clips/clipList';
import ThemeDrawer from '../themeDrawer';
import ClipSuggestions from '../clipSuggestions';

const Drawer = (props) => {
	const {
		accountId = 0,
		podcast,
		episode = {},
		getClosestNode,
		highlightSelection,
		clips,
		generatedClips,
		onPauseClip,
		onPlayClip,
		onClipHover,
		onClipHoverLeave,
		updateRegionClass,
		handleCreateClipCardClose,
		linkedClip,
		setLinkedClip,
		clipsPermission,
		takeawaysPermission,
		isReady,
		/* Redux props start */
		episodesUI,
		/* Redux props end */
	} = props;

	const { featureThemes, featureClipSuggestionAccess } = useFlags();

	const renderDrawer = () => {
		let currentDrawerTab = episodesUI.studio.drawerTab;

		// Default to clips tab if themes (and clip suggestions) is disabled
		if (!featureThemes || (!featureClipSuggestionAccess && !featureThemes)) {
			currentDrawerTab = DRAWER_TABS.CLIPS;
		}

		switch (currentDrawerTab) {
			case DRAWER_TABS.THEMES:
				return (
					<>
						<h4>Theme Content</h4>
						<ThemeDrawer />
					</>
				);
			case DRAWER_TABS.CLIP_SUGGESTIONS:
				return (
					<ClipSuggestions
						canCreateTakeaway={takeawaysPermission.canEdit}
						getClosestNode={getClosestNode}
						clips={generatedClips}
						collectionType={podcast.collectionType}
						noAiClips={
							clips.filter((clip) => clip.source === 'ai').length === 0
						}
						transcriptReady={isReady}
					/>
				);
			case DRAWER_TABS.CLIPS:
			default:
				return (
					<ClipList
						accountId={accountId}
						podcastSlug={podcast.slug}
						podcastName={podcast.name}
						podcastThumbnail={podcast.thumbnail}
						episode={episode}
						customDomain={podcast.customDomain}
						canEdit={clipsPermission.canEdit}
						canCreateTakeaway={takeawaysPermission.canEdit}
						getClosestNode={getClosestNode}
						transcriptReady={isReady}
						highlightSelection={highlightSelection}
						clips={clips}
						inTranscript={false}
						onPauseClip={onPauseClip}
						onPlayClip={onPlayClip}
						onClipHover={onClipHover}
						onClipHoverLeave={onClipHoverLeave}
						updateRegionClass={updateRegionClass}
						handleCreateClipCardClose={handleCreateClipCardClose}
						linkedClip={linkedClip}
						setLinkedClip={setLinkedClip}
						collectionType={podcast.collectionType}
						disableLinkSharing={podcast.disableLandingPages}
					/>
				);
		}
	};

	return renderDrawer();
};

const mapStateToProps = (state) => ({
	episodesUI: state.ui.episodes,
});

export default connect(mapStateToProps)(Drawer);
