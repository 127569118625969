import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Upload from '@casted-app/casted-gcs-browser-upload';

import Button from 'components/shared/button';
import FileUploader from 'components/shared/fileUploader';
import Toast from 'services/toast';
import API from 'services/api';

// must be an multiple of 262144
// https://github.com/QubitProducts/gcs-browser-upload#config
const CHUNK_SIZE = 524288;

const ImportTranscription = (props) => {
	const { stageName, currentStage, onContinueClick, episodeId, accountId } =
		props;

	// HOOKS
	const [uploadProgress, setUploadProgress] = useState(null);
	const [uploadInProgress, setUploadInProgress] = useState(false);
	const [uploadComplete, setUploadComplete] = useState(false);

	const onFileUpload = ({ fileData }) => {
		const reader = new FileReader();
		const { type } = fileData;

		reader.onloadstart = () => {
			setUploadInProgress(true);
			setUploadProgress(1);
		};

		reader.onloadend = async () => {
			try {
				setUploadInProgress(true);

				const fileName = `${episodeId}-landing.txt`;

				const { writeUrl } = await API.getTranscriptSignedUrls({
					filename: fileName,
					accountId,
					contentType: type,
				});

				const uploadOptions = {
					id: fileData.name,
					url: writeUrl,
					file: fileData,
					contentType: type,
					chunkSize: CHUNK_SIZE * 2,
					onChunkUpload: (uploadInfo) => {
						const { totalBytes, uploadedBytes } = uploadInfo;
						const percentage = ((uploadedBytes / totalBytes) * 100).toFixed(0);

						setUploadProgress(percentage);

						if (percentage > 99) {
							setTimeout(() => {
								setUploadInProgress(false);
								setUploadComplete(true);
							}, 1800);
						}
					},
				};

				const upload = new Upload(uploadOptions);

				await upload.start();
			} catch (err) {
				console.error(err);
				Toast.error('Unable to upload file');
			}
		};

		reader.readAsArrayBuffer(fileData);
	};

	if (currentStage !== stageName) {
		return null;
	}

	return (
		<div>
			<h2 className="option-header">Import Transcription</h2>

			<p>
				If this episode has already been transcribed, you can upload a text file
				containing the transcription.{' '}
				<strong>
					The transcript will only be displayed in the public episode page.
				</strong>
			</p>

			<div className="transcript-upload">
				<p className="upload-label">Upload Transcription File</p>
				<FileUploader
					accept=".txt"
					overlayMsg={uploadComplete ? 'Upload Complete!' : null}
					className={uploadComplete ? null : 'upload-complete'}
					uploadProgress={uploadProgress}
					uploadInProgress={uploadInProgress}
					onUpload={(fileData) => onFileUpload({ fileData })}
				/>
			</div>

			<div className="options-footer">
				<Button
					onClick={async () => {
						if (onContinueClick) {
							onContinueClick();
						}
					}}
					id="confirm-transcription-upload"
					className="options-next-btn"
					variant="contained"
					disabled={!uploadComplete}
				>
					CONTINUE
				</Button>
			</div>
		</div>
	);
};

export default withRouter(ImportTranscription);
