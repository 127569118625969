import React, { useState, useEffect } from 'react';
import API from 'services/api';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSelectedAccount } from 'store/selectors/accounts';
import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/browser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import sanitizeHtml from 'sanitize-html';

import { useQuery, useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import queries from 'services/graphql/queries';

import QuillEditor from 'components/shared/quillEditor';
import ThumbnailUploader from 'components/shared/thumbnailUploader';
import Toast from 'services/toast';
import ColorPicker from 'components/shared/colorPicker';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import { trackEvent } from 'services/vitally';

import YoutubeIntegrationSettings from './youtubeIntegrationSettings';

import {
	Card,
	ButtonToolbar,
	Form,
	FormGroup,
	Container,
	Row,
	Col,
	Tab,
	Nav,
	InputGroup,
} from 'react-bootstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Button from 'components/shared/button';
import IntegrationScopesModal from 'components/shared/integrationScopesModal';
import MockBrowser from 'components/shared/mock/browser';
import MockPlayer from 'components/shared/mock/player';
import MockNavbar from 'components/shared/mock/navbar';
import NavItem from './navItem';

import HubSpotLogo from 'assets/logos/hubspot-logo.svg';
import YouTubeLogo from 'assets/logos/youtube-logo.svg';

import {
	getCollectionTypeName,
	PODCAST_COLLECTION_TYPE,
} from 'components/constants';

const ShowSettings = (props) => {
	const { match, selectedAccount } = props;
	const { params } = match;
	const { showId, accountId } = params;
	// END Props

	// Router
	const history = useHistory();

	// Queries
	const { data, loading, error } = useQuery(queries.podcastById, {
		variables: { podcastId: showId },
	});
	const { podcast = {} } = data || {};
	const {
		id,
		name,
		slug,
		status,
		thumbnail: _thumbnail = '',
		brandSettings: _brandSettings = {},
		author: _author = {},
		description: _description = '',
		syndicationLinks: _syndicationLinks = {},
		customHtmlTags: _customHtmlTags = '',
		customDomain: _customDomain = '',
		customNavItems: _customNavItems = [],
		autoPublishYoutube: _autoPublishYoutube,
		youtubeChannelId: _youtubeChannelId,
		youtubePlaylistId: _youtubePlaylistId,
	} = podcast || {};

	const {
		featureHubSpotIntegration,
		featureCustomDomains,
	} = useFlags();

	const collectionTypeName = getCollectionTypeName(podcast.collectionType);

	const {
		data: integrationData,
	} = useQuery(queries.podcastIntegrations, {
		variables: { podcastId: showId },
	});
	const { podcastIntegrations = [] } = integrationData || {};

	// END Queries

	const [
		updatePodcast
	] = useMutation(mutations.updatePodcastById);

	const [savePodcastIntegration] = useMutation(
		mutations.savePodcastIntegration
	);

	const [deleteHubspotIntegration] = useMutation(
		mutations.deleteHubspotIntegration
	);
	const [getYoutubeAuthUrl] = useMutation(mutations.getYoutubeAuthUrl, {
		variables: { podcastId: showId },
		onCompleted: (data) => {
			window.location = data.getYoutubeAuthUrl;
		},
	});

	const [deleteYoutubeIntegration] = useMutation(
		mutations.deleteYoutubeIntegration
	);

	// States
	const [tab, setTab] = useState('global');

	// Fields
	const [thumbnail, setThumbnail] = useState(_thumbnail);
	const [brandSettings, setBrandSettings] = useState(
		_brandSettings ? _brandSettings : {}
	);
	const [confirmColorReset, setConfirmColorReset] = useState(
		brandSettings !== _brandSettings &&
			Object.keys(brandSettings).length !== 0
	); // stages of confirmation needed

	const [author, setAuthor] = useState(_author ? _author : {});
	const [editDescription, setEditDescription] = useState(_description);
	const [syndicationLinks, setSyndicationLinks] = useState(
		_syndicationLinks ? _syndicationLinks : {}
	);
	const [driftAppId, setDriftAppId] = useState('');
	const [driftIdChanged, setDriftIdChanged] = useState(false);
	const [customHtmlTags, setCustomHtmlTags] = useState(_customHtmlTags);
	const [customDomain, setCustomDomain] = useState(_customDomain);
	const [customNavItems, setCustomNavItems] = useState(
		_customNavItems ? _customNavItems : []
	);
	const [autoPublishYoutube, setAutoPublishYoutube] = useState(_autoPublishYoutube);
	const [youtubeChannelId, setYoutubeChannelId] = useState(_youtubeChannelId);
	const [youtubePlaylistId, setYoutubePlaylistId] = useState(_youtubePlaylistId);

	const [hubspotIntegration, setHubspotIntegration] = useState({});
	const [youtubeIntegration, setYoutubeIntegration] = useState({});

	const [editScopesModal, setEditScopesModal] = useState({});
	const [scopes, setScopes] = useState([]);

	const [getHubspotAuthUrl] = useMutation(mutations.getHubspotAuthUrl, {
		variables: { podcastId: showId, scopes },
		onCompleted: (data) => {
			window.location = data.getHubspotAuthUrl;
		},
	});

	useEffect(() => {
		if (_thumbnail) setThumbnail(_thumbnail);
		if (_author) setAuthor(_author);
		if (_description) setEditDescription(_description);
		if (_brandSettings) setBrandSettings(_brandSettings);
		if (_syndicationLinks) setSyndicationLinks(_syndicationLinks);
		if (_customHtmlTags) setCustomHtmlTags(_customHtmlTags);
		if (_customDomain) setCustomDomain(_customDomain);
		if (_customNavItems) setCustomNavItems(_customNavItems);
		if (_autoPublishYoutube) setAutoPublishYoutube(_autoPublishYoutube);
		if (_youtubeChannelId) setYoutubeChannelId(_youtubeChannelId);
		if (_youtubePlaylistId) setYoutubePlaylistId(_youtubePlaylistId);
	}, [podcast]);

	useEffect(() => {
		if (podcastIntegrations) {
			setDriftSettings();
			checkYoutubeIntegration();

			const integration = podcastIntegrations.find(
				(x) => x.integrationName === 'hubspot'
			);
			if (integration) {
				setHubspotIntegration(integration);
			}
		}
	}, [podcastIntegrations]);

	const checkYoutubeIntegration = () => {
		const integration = podcastIntegrations.find(
			(x) => x.integrationName === 'youtube'
		);
		if (integration) {
			setYoutubeIntegration(integration);
		}
	};

	const onResetClick = () => {
		setAuthor(_author || {});
		setEditDescription(_description);
		setBrandSettings(_brandSettings || {});
		setSyndicationLinks(_syndicationLinks || {});
		setCustomHtmlTags(_customHtmlTags);
		setCustomDomain(_customDomain);
		setCustomNavItems(_customNavItems);
		setAutoPublishYoutube(_autoPublishYoutube);
		setYoutubeChannelId(_youtubeChannelId);
		setYoutubePlaylistId(_youtubePlaylistId);

		if (driftIdChanged) {
			setDriftIdChanged(false);
			setDriftAppId('');
			setDriftSettings();
		}
	};

	const onResetColors = () => {
		if (!confirmColorReset) return setConfirmColorReset(true);
		setBrandSettings({
			...brandSettings,
			primaryColor: '',
			secondaryColor: '',
			backgroundColor: '',
			headingTextColor: '',
			bodyTextColor: '',
		});

		setConfirmColorReset(false);

		return;
	};

	const setDriftSettings = () => {
		const driftIntegration = podcastIntegrations.find(
			(x) => x.integrationName === 'drift'
		);

		if (
			driftIntegration &&
			driftIntegration.integrationSettings &&
			driftIntegration.integrationSettings.length > 0 &&
			driftIntegration.integrationSettings[0].settingValue
		) {
			setDriftAppId(
				driftIntegration.integrationSettings[0].settingValue.appId
			);
		}
	};

	const updateAuthor = async (i, field, val) => {
		let gcsAuthorUrl = '';
		if (field === 'image') {
			gcsAuthorUrl = await API.uploadPublicFile({
				dataURI: val.img,
				fileExt: val.fileExt,
			});
			val = gcsAuthorUrl;
		}
		let host = author[i];
		let updatedHost = Object.assign({}, host, { [field]: val });
		setAuthor(Object.assign({}, author, { [i]: updatedHost }));
	};
	const addHost = (host) => {
		let uid = uuid();
		setAuthor(Object.assign({}, author, { [uid]: host }));
	};
	const removeHost = (i) => {
		let updatedAuthor = delete author[i];
		setAuthor(Object.assign({}, author, updatedAuthor));
	};
	const updateBrandSettings = (field, val) => {
		setBrandSettings(Object.assign({}, brandSettings, { [field]: val }));
	};
	const updateBrandLogo = async (imgData, fileExt) => {
		const gcsUrl = await API.uploadPublicFile({
			dataURI: imgData,
			fileExt: fileExt,
		});
		setBrandSettings(Object.assign({}, brandSettings, { logoUri: gcsUrl }));
	};
	const updateLink = (e) => {
		setSyndicationLinks(
			Object.assign({}, syndicationLinks, {
				[e.target.name]: e.target.value,
			})
		);
	};
	const updateFavicon = async (imgData, fileExt) => {
		const gcsUrl = await API.uploadPublicFile({
			dataURI: imgData,
			fileExt: fileExt,
		});
		setBrandSettings(
			Object.assign({}, brandSettings, { faviconUri: gcsUrl })
		);
	};
	const updateThumbnail = async (imgData, fileExt) => {
		const gcsUrl = await API.uploadPublicFile({
			dataURI: imgData,
			fileExt: fileExt,
		});
		setThumbnail(gcsUrl);
	};
	const updateDomain = (e) => {
		setCustomDomain(e.target.value);
	};
	const addCustomNavItem = (item) => {
		setCustomNavItems([...customNavItems, item]);
	};
	const removeCustomNavItem = (index) => {
		let localCustomNavItems = [...customNavItems];
		localCustomNavItems.splice(index, 1);
		setCustomNavItems([...localCustomNavItems]);
	};
	const updateCustomNavItem = (index, item) => {
		let localCustomNavItems = [...customNavItems];
		localCustomNavItems[index] = item;
		setCustomNavItems([...localCustomNavItems]);
	};
	const updateDriftAppId = (e) => {
		setDriftIdChanged(true);
		setDriftAppId(e.target.value);
	};

	const updateHubspotIntegration = async () => {
		try {
			if (hubspotIntegration && hubspotIntegration.status === 1) {
				// integration already exists, so we are deleting it
				await deleteHubspotIntegration({
					variables: { integrationId: hubspotIntegration.id },
				});
				setHubspotIntegration({});
				Toast.success('HubSpot Integration Removed');
			} else {
				setScopes([
					{
						name: 'Timeline Events',
						envName: 'HUBSPOT_OPTIONAL_TIMELINE_SCOPES',
						checked: false,
					},
					{
						name: 'Contact Properties',
						envName: 'HUBSPOT_OPTIONAL_CONTACT_PROPERTIES_SCOPES',
						checked: false,
					},
					{
						name: 'Media Bridge',
						envName: 'HUBSPOT_OPTIONAL_MEDIA_BRIDGE_SCOPES',
						checked: false,
					},
				]);

				setEditScopesModal({
					isOpen: true,
					title: <img src={HubSpotLogo} alt="HubSpot logo" />,
					modalDialouge:
						'What scopes would you like to allow for your HubSpot Integration?',
					confirmationButtonLabel: 'CONTINUE TO HUBSPOT',
					getOauthURL: getHubspotAuthUrl,
				});
			}
		} catch (err) {
			Toast.error('Unable to update integration with HubSpot');
			console.log(err);
			Sentry.captureException(err);
		}
	};

	const submitForms = async () => {
		const updates = {};
		if (author !== _author) updates.author = author;
		if (thumbnail !== _thumbnail) updates.thumbnail = thumbnail;
		if (editDescription !== _description)
			updates.description = editDescription;
		if (brandSettings !== _brandSettings)
			updates.brandSettings = brandSettings;
		if (syndicationLinks !== _syndicationLinks)
			updates.syndicationLinks = syndicationLinks;
		if (customHtmlTags !== _customHtmlTags)
			updates.customHtmlTags = customHtmlTags;
		if (customDomain !== _customDomain) updates.customDomain = customDomain;
		if (customNavItems !== _customNavItems)
			updates.customNavItems = customNavItems;
		if (autoPublishYoutube !== _autoPublishYoutube)
			updates.autoPublishYoutube = autoPublishYoutube;
		if (youtubeChannelId !== _youtubeChannelId)
			updates.youtubeChannelId = !autoPublishYoutube ? null : youtubeChannelId || null
		if (youtubePlaylistId !== _youtubePlaylistId)
			updates.youtubePlaylistId = !autoPublishYoutube ? null : youtubePlaylistId || null
		await updatePodcast({
			variables: {
				podcastId: showId,
				podcast: {
					accountId: parseInt(accountId, 10),
					...updates,
				},
			},
		});

		if (driftIdChanged) {
			await savePodcastIntegration({
				variables: {
					podcastId: showId,
					integrationName: 'drift',
					integrationSettings: {
						settingName: 'drift_app_id',
						settingValue: {
							appId: driftAppId,
						},
					},
				},
			});
			setDriftIdChanged(false);
			trackEvent('add-integration', { integrationName: 'drift' });
		}
		Toast.success('Settings Saved!');
	};

	const reorder = async (list, startIndex, endIndex) => {
		let result = Array.from(list);
		let [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		setCustomNavItems(result);
	};

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		if (!result.destination) return;

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		)
			return;

		reorder(customNavItems, result.source.index, result.destination.index);
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',

		// styles we need to apply on draggables
		...draggableStyle,
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? '#f6f9fa' : '#ffffff',
	});

	const handleDescription = (content, delta, source, editor) => {
		if (source === 'api') return;

		if (content !== _description && (content || _description)) {
			setEditDescription(content);
		}
	};

	// Render
	return (
		<div className="show-settings">
			<Tab.Container
				defaultActiveKey="global"
				activeKey={tab}
				onSelect={(k) => setTab(k)}
			>
				<header className="show-settings--header">
					<Container>
						<Row className="show-header">
							<Col className="show-header--nav">
								<Row>
									<Nav className="flex-row">
										<Nav.Item>
											<Nav.Link eventKey="global">
												<p className="show-nav--title">
													Branding
												</p>
											</Nav.Link>
										</Nav.Item>

										<Nav.Item>
											<Nav.Link eventKey="show">
												<p className="show-nav--title">
													About
												</p>
											</Nav.Link>
										</Nav.Item>

										<Nav.Item>
											<Nav.Link eventKey="navigation">
												<p className="show-nav--title">
													Navigation
												</p>
											</Nav.Link>
										</Nav.Item>

										<Nav.Item>
											<Nav.Link eventKey="links">
												<p className="show-nav--title">
													Links & Integrations
												</p>
											</Nav.Link>
										</Nav.Item>
									</Nav>
								</Row>
							</Col>

							<Col className="show-nav--actions">
								<ButtonToolbar>
									<Button
										href={`${
											customDomain
												? sanitizeHtml(
														`https://${customDomain}`
												  )
												: process.env
														.REACT_APP_PUBLIC_APP_ROOT
										}/public/${accountId}/${slug}/`}
										variant="outline-light"
										target="_blank"
										className="d-flex align-items-center"
									>
										Go to Landing Page
									</Button>
									<Button
										variant="outline-light"
										onClick={onResetClick}
									>
										Reset
									</Button>
									<Button
										variant="success"
										onClick={submitForms}
									>
										Update
									</Button>
								</ButtonToolbar>
							</Col>
						</Row>
					</Container>
				</header>

				<div>
					<Col xs={12}>
						<Tab.Content>
							<Tab.Pane eventKey="global">
								<section className="show-settings--about show-settings--section">
									<Container>
										<h4 className="settings-section--title">
											Visual Settings
										</h4>
										<Row>
											<Col xs={5}>
												<Card>
													<Card.Header className="color-picker--title">
														<h5>Colors</h5>
														<Button
															variant={
																confirmColorReset
																	? 'danger'
																	: 'outline-dark'
															}
															onClick={
																onResetColors
															}
															disabled={
																!brandSettings.primaryColor &&
																!brandSettings.secondaryColor &&
																!brandSettings.backgroundColor &&
																!brandSettings.headingTextColor &&
																!brandSettings.bodyTextColor
															}
														>
															{confirmColorReset
																? 'Are You Sure?'
																: 'Clear'}
														</Button>
													</Card.Header>
													<Card.Body>
														<div className="color-picker-input-group">
															<label className="ctrl-label">
																Primary Color
															</label>
															<ColorPicker
																color={
																	brandSettings.primaryColor
																}
																onColorChange={(
																	color
																) => {
																	updateBrandSettings(
																		'primaryColor',
																		color
																	);
																}}
															/>
														</div>
														<div className="color-picker-input-group">
															<label className="ctrl-label">
																Secondary Color
															</label>
															<ColorPicker
																color={
																	brandSettings.secondaryColor
																}
																onColorChange={(
																	color
																) => {
																	updateBrandSettings(
																		'secondaryColor',
																		color
																	);
																}}
															/>
														</div>
														<div className="color-picker-input-group">
															<label className="ctrl-label">
																Background Color
															</label>
															<ColorPicker
																color={
																	brandSettings.backgroundColor
																}
																onColorChange={(
																	color
																) => {
																	updateBrandSettings(
																		'backgroundColor',
																		color
																	);
																}}
															/>
														</div>
														<div className="color-picker-input-group">
															<label className="ctrl-label">
																Heading Text
																Color
															</label>
															<ColorPicker
																color={
																	brandSettings.headingTextColor
																}
																onColorChange={(
																	color
																) => {
																	updateBrandSettings(
																		'headingTextColor',
																		color
																	);
																}}
															/>
														</div>
														<div className="color-picker-input-group">
															<label className="ctrl-label">
																Body Text Color
															</label>
															<ColorPicker
																color={
																	brandSettings.bodyTextColor
																}
																onColorChange={(
																	color
																) => {
																	updateBrandSettings(
																		'bodyTextColor',
																		color
																	);
																}}
															/>
														</div>
													</Card.Body>
												</Card>
											</Col>

											<Col xs={7}>
												<MockBrowser>
													<MockPlayer
														brandSettings={
															brandSettings
														}
													/>
												</MockBrowser>
											</Col>
										</Row>
									</Container>
								</section>

								<section className="show-settings--about show-settings--section">
									<Container>
										<h4 className="settings-section--title">
											Nav Bar
										</h4>
										<Row>
											<Col xs={5}>
												<Card>
													<Card.Header as="h5">
														Logo
													</Card.Header>
													<Card.Body>
														<Form>
															<Form.Group>
																<ThumbnailUploader
																	className="show-settings--logo-upload"
																	overlayMsg="Upload Logo"
																	thumbnailImgSrc={
																		brandSettings.logoUri
																	}
																	onUpload={(
																		result,
																		fileExt
																	) =>
																		updateBrandLogo(
																			result,
																			fileExt
																		)
																	}
																/>
															</Form.Group>
															<Form.Group>
																<Form.Label>
																	Logo Link
																</Form.Label>
																<Form.Control
																	type="url"
																	value={
																		brandSettings.logoLink
																	}
																	onChange={(
																		e
																	) => {
																		updateBrandSettings(
																			'logoLink',
																			e
																				.target
																				.value
																		);
																	}}
																	onBlur={(
																		e
																	) => {
																		updateBrandSettings(
																			'logoLink',
																			e.target.value.trim()
																		);
																	}}
																	placeholder="http://website.com"
																></Form.Control>
																<Form.Check
																	type="checkbox"
																	className="show-settings--checkbox"
																	label="Open In Same Window"
																	onChange={() =>
																		updateBrandSettings(
																			'logoLinkInWindow',
																			!brandSettings.logoLinkInWindow
																		)
																	}
																	checked={
																		brandSettings.logoLinkInWindow
																	}
																/>
															</Form.Group>
														</Form>
													</Card.Body>
												</Card>
											</Col>

											<Col xs={7}>
												<MockBrowser>
													<MockNavbar>
														{brandSettings.logoUri && (
															<a
																href={sanitizeHtml(
																	brandSettings.logoLink
																)}
															>
																<img
																	className="mocked-logo"
																	src={
																		brandSettings.logoUri
																	}
																/>
															</a>
														)}
													</MockNavbar>
												</MockBrowser>
											</Col>
										</Row>
									</Container>
								</section>

								<section className="show-settings--about show-settings--section">
									<Container>
										<h4 className="settings-section--title">
											Browser Settings
										</h4>
										<Row>
											<Col xs={5}>
												<Card>
													<Card.Header as="h5">
														Page Branding
													</Card.Header>
													<Card.Body>
														<Form>
															<Form.Group>
																<Form.Label>
																	Favicon
																</Form.Label>
																<ThumbnailUploader
																	className="show-settings--logo-upload"
																	overlayMsg="Upload Favicon"
																	thumbnailImgSrc={
																		brandSettings.faviconUri
																	}
																	onUpload={(
																		result,
																		fileExt
																	) =>
																		updateFavicon(
																			result,
																			fileExt
																		)
																	}
																/>
															</Form.Group>
															<Form.Group>
																<Form.Label>
																	Page Title
																</Form.Label>
																<p className="helper-text">
																	This text
																	will be
																	appended to
																	the title of
																	all landing
																	pages.
																	Defaults to
																	Account
																	Name.
																</p>
																<Form.Control
																	type="text"
																	value={
																		brandSettings.pageTitle
																	}
																	onChange={(
																		e
																	) => {
																		updateBrandSettings(
																			'pageTitle',
																			e
																				.target
																				.value
																		);
																	}}
																	onBlur={(
																		e
																	) => {
																		updateBrandSettings(
																			'pageTitle',
																			e.target.value.trim()
																		);
																	}}
																	placeholder={
																		selectedAccount.name
																	}
																></Form.Control>
															</Form.Group>
														</Form>
													</Card.Body>
												</Card>
											</Col>

											<Col xs={7}>
												<MockBrowser
													favIconUri={
														brandSettings.faviconUri ||
														`${process.env.REACT_APP_ROOT_URL}/favicon.ico`
													}
													pageTitle={
														brandSettings.pageTitle ||
														selectedAccount.name
													}
												></MockBrowser>
											</Col>
										</Row>
									</Container>
								</section>

								<section className="show-settings--about show-settings--section">
									<Container>
										<h4 className="settings-section--title">
											{collectionTypeName} Art
										</h4>
										<Row>
											<Col xs={5}>
												<Card>
													<Card.Header as="h5">
														Change{' '}
														<span className="text-capitalize">
															{collectionTypeName}
														</span>{' '}
														Art
													</Card.Header>
													<Card.Body>
														<Form>
															<Form.Group>
																<p className="helper-text">
																	Image must
																	be between
																	1400x1400
																	and
																	3000x3000
																	pixels with
																	a maximum
																	size of 4
																	MB.
																</p>
																<ThumbnailUploader
																	className="show-settings--artwork-upload"
																	overlayMsg="Upload Show Art"
																	thumbnailImgSrc={
																		thumbnail
																	}
																	onUpload={(
																		result,
																		fileExt
																	) =>
																		updateThumbnail(
																			result,
																			fileExt
																		)
																	}
																/>
															</Form.Group>
														</Form>
													</Card.Body>
												</Card>
											</Col>

											<Col xs={7}>
												<MockBrowser>
													<MockPlayer
														thumbnail={thumbnail}
													/>
												</MockBrowser>
											</Col>
										</Row>
									</Container>
								</section>
							</Tab.Pane>

							<Tab.Pane eventKey="show">
								<section className="show-settings--about show-settings--section">
									<Container>
										<Row>
											<Col xs={5}>
												<h4 className="settings-section--title">
													{collectionTypeName}{' '}
													Description
												</h4>
												<p>
													This manages the description
													for your podcast that's
													shown on public landing
													pages.
												</p>
												<p>
													<strong>
														Casted Hosting
													</strong>
													<br />
													If you're using Casted's
													hosting service and use the
													Casted RSS feed to syndicate
													on listening platforms like
													Apple Podcasts, this will
													change the description shown
													to users there *and* the
													description shown on Casted
													pages.
												</p>
												<p>
													<strong>
														3rd Party Hosting
													</strong>
													<br />
													If you've linked another
													host to Casted, this field
													will only change what users
													see on the Casted landing
													page(s). Descriptions on
													other listening platforms
													will not change.
												</p>
											</Col>

											<Col xs={7}>
												<Card>
													<Card.Body>
														<div className="show-settings--description">
															<QuillEditor
																defaultValue={
																	editDescription
																}
																onChange={
																	handleDescription
																}
																style={{
																	height: '85%',
																}}
															/>
														</div>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Container>
								</section>

								<section className="show-settings--about show-settings--section">
									<Container className="resources">
										<Row>
											<Col xs={5}>
												<h4 className="settings-section--title">
													{podcast.collectionType ===
													PODCAST_COLLECTION_TYPE.serial
														? 'Show'
														: ''}{' '}
													Host
												</h4>
												{podcast.collectionType ===
													PODCAST_COLLECTION_TYPE.serial && (
													<>
														<p>
															This section is to
															tell listeners about
															the host of the show
															and is displayed on
															your show's public
															landing page.
														</p>
														<p>
															Feel free to include
															information about
															your brand, your
															host, or leave this
															section blank to
															show nothing at all.
														</p>
													</>
												)}
											</Col>

											<Col xs={7}>
												<Form>
													{Object.keys(author).map(
														(i) => (
															<Card
																key={i}
																className="host-row p-3"
															>
																<Container>
																	<Row className="d-flex justify-content-end">
																		<div className="delete-host">
																			<div
																				className="icon-circle-container"
																				onClick={() =>
																					removeHost(
																						i
																					)
																				}
																			>
																				<FaTimes className="icon-trash" />
																			</div>
																		</div>
																	</Row>
																	<Row>
																		<Col
																			xs={
																				12
																			}
																			lg={
																				5
																			}
																		>
																			<FormGroup>
																				<Form.Label>
																					Host
																					Headshot
																					/
																					Avatar
																				</Form.Label>
																				<ThumbnailUploader
																					className="host-photo-upload"
																					thumbnailImgSrc={
																						author[
																							i
																						]
																							.image
																					}
																					onUpload={(
																						img,
																						fileExt
																					) =>
																						updateAuthor(
																							i,
																							'image',
																							{
																								img: img,
																								fileExt:
																									fileExt,
																							}
																						)
																					}
																					overlayMsg={
																						'Upload'
																					}
																					inputName={
																						'image'
																					}
																				/>
																			</FormGroup>
																		</Col>
																		<Col
																			xs={
																				12
																			}
																			lg={
																				7
																			}
																		>
																			<FormGroup>
																				<Form.Label>
																					Host
																					Name
																				</Form.Label>
																				<Form.Control
																					type="text"
																					name="name"
																					onChange={(
																						e
																					) => {
																						updateAuthor(
																							i,
																							'name',
																							e
																								.target
																								.value
																						);
																					}}
																					value={
																						author[
																							i
																						]
																							.name
																					}
																				/>
																			</FormGroup>
																			<FormGroup>
																				<Form.Label>
																					Host
																					Title
																				</Form.Label>
																				<Form.Control
																					type="text"
																					name="title"
																					onChange={(
																						e
																					) => {
																						updateAuthor(
																							i,
																							'title',
																							e
																								.target
																								.value
																						);
																					}}
																					value={
																						author[
																							i
																						]
																							.title
																					}
																				/>
																			</FormGroup>
																		</Col>
																	</Row>
																</Container>
															</Card>
														)
													)}
												</Form>
												{Object.keys(author).length <
													4 && (
													<Row className="add-guests mt-2">
														<Col>
															<Card className="p-4">
																<div className="add-item resource w-100">
																	<div
																		className="add-item--inner d-flex justify-content-center m-2"
																		onClick={() =>
																			addHost(
																				{}
																			)
																		}
																	>
																		<div className="py-4">
																			<FaPlus className="add-item--icon mb-1" />
																			<p>
																				Add
																				Another
																				Host
																			</p>
																		</div>
																	</div>
																</div>
															</Card>
														</Col>
													</Row>
												)}
											</Col>
										</Row>
									</Container>
								</section>
							</Tab.Pane>

							<Tab.Pane eventKey="navigation">
								<section className="show-settings--section">
									<Container className="resources">
										<Row>
											<Col>
												<h4 className="settings-section--title mb-0">
													Custom Navigation
												</h4>
												<p>
													Create a custom navigation
													for your show's landing
													pages to send users anywhere
													on the internet you'd like
													them to go.
												</p>
											</Col>
										</Row>
										<Row className="resource-list">
											<Col xs={12}>
												<DragDropContext
													onDragEnd={(result) =>
														onDragEnd(result)
													}
												>
													<Droppable droppableId="droppable">
														{(
															provided,
															snapshot
														) => (
															<div
																{...provided.droppableProps}
																ref={
																	provided.innerRef
																}
																style={getListStyle(
																	snapshot.isDraggingOver
																)}
															>
																{customNavItems.map(
																	(
																		navItem,
																		i
																	) => (
																		<Draggable
																			key={
																				navItem.name
																			}
																			draggableId={
																				navItem.name
																					? navItem.name
																					: 'id'
																			}
																			index={
																				i
																			}
																		>
																			{(
																				provided,
																				snapshot
																			) => (
																				<div
																					ref={
																						provided.innerRef
																					}
																					// deepcode ignore DOMXSS: Snyk has misanalyzed, `provided` is not user input controlled
																					{...provided.draggableProps}
																					{...provided.dragHandleProps}
																					style={getItemStyle(
																						snapshot.isDragging,
																						provided
																							.draggableProps
																							.style
																					)}
																				>
																					<NavItem
																						navItem={
																							navItem
																						}
																						key={
																							i
																						}
																						onRemove={() => {
																							removeCustomNavItem(
																								i
																							);
																						}}
																						onChange={(
																							updatedItem
																						) => {
																							updateCustomNavItem(
																								i,
																								updatedItem
																							);
																						}}
																					/>
																				</div>
																			)}
																		</Draggable>
																	)
																)}
																{
																	provided.placeholder
																}
															</div>
														)}
													</Droppable>
												</DragDropContext>
											</Col>
										</Row>
										{customNavItems.length < 4 && (
											<Row className="add-guests mt-2">
												<Col>
													<Card className="p-4">
														<div className="add-item resource w-100">
															<div
																className="add-item--inner d-flex justify-content-center m-2"
																onClick={() =>
																	addCustomNavItem(
																		{}
																	)
																}
															>
																<div className="py-4">
																	<FaPlus className="add-item--icon mb-1" />
																	<p>
																		New
																		Navigation
																		Item
																	</p>
																</div>
															</div>
														</div>
													</Card>
												</Col>
											</Row>
										)}
									</Container>
								</section>
							</Tab.Pane>

							<Tab.Pane eventKey="links">
								{featureCustomDomains && (
									<section className="show-settings--custom-domain show-settings--section">
										<Container>
											<Row>
												<Col xs={5}>
													<h4 className="settings-section--title">
														Custom Domain
													</h4>
													<p>
														Our Support team will
														need to assist with
														configuring a custom
														domain. Please review
														our{' '}
														<a
															target="_blank"
															className="ext-link"
															id="tooltip-custom-domain-help"
															href="https://help.casted.us/en/custom-domains"
														>
															set-up instructions
														</a>{' '}
														before proceeding.
													</p>
												</Col>
												<Col xs={7}>
													<Card>
														<Card.Body>
															<Form>
																<Form.Group>
																	<Form.Label>
																		Custom
																		Domain
																	</Form.Label>
																	<Form.Text className="helper-text mt-0 mb-1">
																		Enter
																		the
																		custom
																		domain
																		for the
																		show
																		landing
																		page.
																	</Form.Text>
																	<InputGroup>
																		<InputGroup.Prepend>
																			<InputGroup.Text id="customDomainPrepend">
																				https://
																			</InputGroup.Text>
																		</InputGroup.Prepend>
																		<Form.Control
																			type="text"
																			name="domain"
																			aria-describedby="customDomainPrepend"
																			value={
																				customDomain
																			}
																			onChange={
																				updateDomain
																			}
																		></Form.Control>
																	</InputGroup>
																</Form.Group>
															</Form>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Container>
									</section>
								)}
								{podcast.collectionType ===
									PODCAST_COLLECTION_TYPE.serial && (
									<section className="show-settings--syndication show-settings--section">
										<Container>
											<Row>
												<Col xs={5}>
													<h4 className="settings-section--title">
														Subscribe Links
													</h4>
													<p>
														You can include links on
														Casted landing pages for
														users to subscribe on
														popular listening
														platforms. Each platform
														has different ways to
														create a subscribe link,
														so make sure you
														reference the
														appropriate
														documentation.
													</p>
													<p>
														<strong>
															Heads Up
														</strong>
														<br />
														You don't have to
														include every platform.
														Any field left blank
														will not show up on
														landing pages.
													</p>
												</Col>
												<Col xs={7}>
													<Card>
														<Card.Body>
															<Form>
																<Form.Group>
																	<Form.Label>
																		Apple
																		Podcasts{' '}
																	</Form.Label>
																	<Form.Text className="helper-text mt-0 mb-1">
																		Paste
																		the
																		subscribe
																		link to
																		your
																		podcast
																		on
																		iTunes /
																		Apple
																		Podcasts.{' '}
																		<a
																			target="_blank"
																			href="https://help.apple.com/itc/podcasts_connect/#/itcd8a8db770"
																		>
																			Get
																			Help
																		</a>
																	</Form.Text>
																	<Form.Control
																		type="text"
																		name="apple"
																		value={
																			syndicationLinks.apple
																		}
																		onChange={
																			updateLink
																		}
																	></Form.Control>
																</Form.Group>
																<Form.Group>
																	<Form.Label>
																		Google
																		Podcasts
																	</Form.Label>
																	<Form.Text className="helper-text mt-0 mb-1">
																		Paste
																		the
																		subscribe
																		link to
																		your
																		podcast
																		on
																		Google
																		Play or
																		Podcasts.{' '}
																		<a
																			target="_blank"
																			href="https://search.google.com/devtools/podcast/preview"
																		>
																			Get
																			Help
																		</a>
																	</Form.Text>
																	<Form.Control
																		type="text"
																		name="google"
																		value={
																			syndicationLinks.google
																		}
																		onChange={
																			updateLink
																		}
																	></Form.Control>
																</Form.Group>
																<Form.Group>
																	<Form.Label>
																		Spotify
																		Podcasts
																	</Form.Label>
																	<Form.Text className="helper-text mt-0 mb-1">
																		Paste
																		the show
																		link to
																		your
																		podcast
																		on
																		Spotify.{' '}
																		<a
																			target="_blank"
																			href="https://podcasters.spotify.com/faq"
																		>
																			Get
																			Help
																		</a>
																	</Form.Text>
																	<Form.Control
																		type="text"
																		name="spotify"
																		value={
																			syndicationLinks.spotify
																		}
																		onChange={
																			updateLink
																		}
																	></Form.Control>
																</Form.Group>
															</Form>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Container>
									</section>
								)}

								<section className="show-settings--integration show-settings--section">
									<Container>
										<Row>
											<Col xs={5}>
												<div
													style={{ margin: '1em 0' }}
												>
													<svg
														version="1.1"
														id="Layer_1"
														x="0px"
														y="0px"
														height="20px"
														viewBox="0 0 176.4 40"
													>
														<path
															d="M64,39.6l-5.2-12.1h-4.4v12.1H42.7V-0.1h20.7c9.1,0,13.9,6.2,13.9,13.6c0,6.8-3.9,10.5-7,12l7,14H64z M65.4,14.3
                        	c0-2.7-1.9-3.9-3.8-3.9h-7.1v7.9h7.1C63.6,18.3,65.4,17.1,65.4,14.3z"
														/>
														<path d="M110.2,39.6V-0.1h29.5v10.5h-17.7v5.3h17.3v10.5h-17.3v13.4H110.2z" />
														<path
															d="M0,39.6V-0.1h17.4c12.3,0,21.4,7.1,21.4,19.8c0,12.7-9.1,19.9-21.3,19.9H0z M26.8,19.7c0-5.4-3-9.3-9.3-9.3h-5.7v18.7h5.6
                        	C23.3,29.1,26.8,24.7,26.8,19.7z"
														/>
														<path d="M154.3,39.6V10.4h-10.4V-0.1h32.6v10.5H166v29.2H154.3z" />
														<path
															d="M106.5-0.1c0.2,0,0.3,0,0.5,0c0.6,0.1,0.8,0.7,0.5,1.1c-0.1,0.1-0.1,0.2-0.2,0.3l-10,11.3c0,0-0.2,0.2-0.3,0.3
                        	c-0.4,0.5,0,1.1,0.7,1.2c0.1,0,0.3,0,0.3,0h3.4c0,0,0.2,0,0.3,0c0.7,0.1,1,0.7,0.7,1.2c-0.1,0.1-0.3,0.3-0.3,0.3l-7.3,8.3
                        	c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.5,0.1,1.1,0.7,1.1c0.2,0,2.5,0,2.7,0c0.4,0,0.7,0.2,0.9,0.5c0.1,0.3,0.1,0.6-0.2,0.8
                        	c-0.1,0.1-0.2,0.2-0.2,0.2l-17,12.8c0,0-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.1-0.5,0c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1,0.1-0.3,0.1-0.3
                        	l4.7-9.6l0.1-0.3c0.3-0.6,0-1.2-0.7-1.3c-0.1,0-0.3,0-0.3,0h-3.3c-0.1,0-0.3,0-0.4,0c-0.6-0.1-0.9-0.6-0.7-1
                        	c0.1-0.1,0.2-0.3,0.2-0.3l5.8-9.7c0,0,0.1-0.1,0.1-0.2c0.3-0.6-0.1-1.1-0.8-1.1c-0.3,0-0.9,0-0.9,0s-1.6,0-2.6,0h-0.3
                        	c-0.5,0-0.8-0.3-0.9-0.7c0-0.2,0-0.4,0.2-0.6l1.8-3.1L88.4,1l0.1-0.2c0.3-0.5,0.8-0.8,1.5-0.8L106.5-0.1L106.5-0.1z"
														/>
													</svg>
												</div>
												<p>
													You can include your Drift
													chatbot on your show's
													landing page by copying your
													Drift site ID here.{' '}
													<a
														target="_blank"
														className="ext-link"
														id="tooltip-drift-help"
														href="https://help.casted.us/en/integrations/drift"
													>
														Click here for
														instructions
													</a>
													.
												</p>
											</Col>
											<Col xs={7}>
												<Card>
													<Card.Body>
														<Form>
															<Form.Group>
																<Form.Label>
																	Drift ID
																</Form.Label>
																<Form.Text className="helper-text mt-0 mb-1">
																	Paste your
																	Drift site
																	ID here.
																</Form.Text>
																<Form.Control
																	type="text"
																	name="drift"
																	value={
																		driftAppId
																	}
																	onChange={
																		updateDriftAppId
																	}
																></Form.Control>
															</Form.Group>
														</Form>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Container>
								</section>
								<YoutubeIntegrationSettings 
									autoPublishEnabled={autoPublishYoutube} 
									channelId={youtubeChannelId}
									playlistId={youtubePlaylistId}
									integration={youtubeIntegration} 
									setChannelId={setYoutubeChannelId}
									setPlaylistId={setYoutubePlaylistId}
									setAutoPublishEnabled={setAutoPublishYoutube}
								/>

								{featureHubSpotIntegration && (
									<section className="show-settings--integration show-settings--section">
										<Container>
											<Row>
												<Col xs={5}>
													<div
														style={{
															margin: '1em 0',
														}}
													>
														<img
															src={HubSpotLogo}
															alt="HubSpot logo"
														/>
													</div>
													<p>
														Link your HubSpot
														account with Casted to
														populate listening
														behavior as activities
														and aggregates within
														contact properties.
														Please note, a{' '}
														<a
															target="_blank"
															className="ext-link"
															id="tooltip-custom-domain-help"
															href="https://help.casted.us/en/custom-domains"
														>
															custom domain
														</a>{' '}
														is required for this
														integration to work.{' '}
														<a
															target="_blank"
															className="ext-link"
															id="tooltip-hubspot-help"
															href="https://help.casted.us/en/integrations/hubspot"
														>
															Click here for
															instructions
														</a>
														.
													</p>
												</Col>
												<Col xs={7}>
													<Card>
														<Card.Body>
															<Form>
																<Form.Group>
																	<Form.Label>
																		HubSpot
																		Connection
																	</Form.Label>
																	<Form.Text className="helper-text mt-0 mb-1">
																		Click
																		here to
																		manage
																		the link
																		with
																		HubSpot
																	</Form.Text>
																</Form.Group>
																<Button
																	variant={`${
																		hubspotIntegration &&
																		hubspotIntegration.status ===
																			1
																			? 'danger'
																			: 'success'
																	}`}
																	onClick={
																		updateHubspotIntegration
																	}
																>
																	{hubspotIntegration &&
																	hubspotIntegration.status ===
																		1
																		? 'Delete Integration'
																		: 'Enable'}
																</Button>
															</Form>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Container>
									</section>
								)}

								<section className="show-settings--custom-tags show-settings--section">
									<Container>
										<Row>
											<Col xs={5}>
												<h4 className="settings-section--title">
													Custom Scripts & Styles
												</h4>
												<p>
													You can add custom tags and
													scripts to your landing
													pages for this show. Make
													sure if you're using
													javascript, your code is
													enclosed in{' '}
													<code>
														{' '}
														&lt;script&gt;{' '}
													</code>
													tags and if using CSS, you
													wrap your code in
													<code>
														{' '}
														&lt;style&gt;{' '}
													</code>{' '}
													tags.
												</p>
												<p>
													<strong>Warning</strong>
													<br />
													Any code you add here is not
													monitored by Casted; ensure
													you are not adding malicious
													scripts and that your code
													is semantic, following the
													latest web standards.
												</p>
											</Col>
											<Col xs={7}>
												<Card>
													<Card.Body>
														<Form>
															<Form.Group>
																<Form.Label>
																	Tag(s)
																</Form.Label>
																<AceEditor
																	placeholder="Custom HTML / JS"
																	mode="html"
																	theme="tomorrow"
																	name="customHtmlTags"
																	fontSize={
																		14
																	}
																	height="300px"
																	width="100%"
																	wrapEnabled={
																		true
																	}
																	showPrintMargin={
																		false
																	}
																	showGutter={
																		false
																	}
																	highlightActiveLine={
																		true
																	}
																	value={
																		customHtmlTags ||
																		''
																	}
																	onChange={(
																		code
																	) =>
																		setCustomHtmlTags(
																			code
																		)
																	}
																/>
															</Form.Group>
														</Form>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Container>
								</section>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</div>
				<IntegrationScopesModal
					isOpen={editScopesModal?.isOpen}
					title={editScopesModal?.title}
					modalDialouge={editScopesModal?.modalDialouge}
					confirmationButtonLabel={
						editScopesModal?.confirmationButtonLabel
					}
					scopes={scopes}
					changeScopes={setScopes}
					onConfirm={editScopesModal?.getOauthURL}
					onCancel={() => setEditScopesModal({ isOpen: false })}
				/>
			</Tab.Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
});

export default withRouter(connect(mapStateToProps)(ShowSettings));
