import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';

import MetricLineChart from 'components/shared/metricLineChart';

const ListensOverTime = (props) => {
	const { variables, query, showWatches, isHosted } = props;

	const datasets = [
		{ dataKey: 'total_count', color: '#B8D3EB', name: 'Impressions' },
		{ dataKey: 'audio_count', color: '#895AFB', name: 'Listens' },
	];

	if (showWatches) {
		datasets.push({
			dataKey: 'video_count',
			color: '#3EBB85',
			name: 'Watches',
		});
	}

	const { data, loading } = useQuery(query, {
		variables,
		fetchPolicy: 'cache-and-network',
	});

	let values = [];

	if (data) {
		if (isHosted) {
			data[query?.definitions[0]?.name?.value].forEach((item) => {
				let value = values.find((x) => x.date == item.date);

				if (!value) {
					value = {
						...item,
						total_count: 0,
						audio_count: 0,
						video_count: 0,
					};

					values.push(value);
				}

				if (item.is_video) {
					value.video_count += item.play_count + item.download_count;
				} else {
					value.audio_count += item.play_count + item.download_count;
				}

				value.total_count +=
					item.play_count + item.download_count + item.visit_count;
			});
		} else {
			const queryData = data[query?.definitions[0]?.name?.value] || {};
			values = queryData.map((value) => {
				return {
					...value,
					audio_listens: value.total_views,
				};
			});
		}
	}

	return (
		<MetricLineChart
			values={values || []}
			loading={loading}
			datasets={datasets}
			showHelp={false}
		/>
	);
};

export default ListensOverTime;
