import React from 'react';

import ThemeCard from '../themeCard';

const ThemeGroup = (props) => {
	const { 
        groupHeader = '',
        themeList = [],
    } = props;

    const sortThemesByName = (a, b) => {
        if(a.name < b.name) {
            return -1;
        }
        if(a.name > b.name) {
            return 1;
        }
        return 0;
    }

	return (
        <div className="theme-group">
            <h4 className="themes-list-header">{groupHeader}</h4>
            <div className="themes-cards">
                {themeList.map((theme, i) => (
                    <ThemeCard 
                        key={i}
                        theme={theme} 
                    />
                )).sort(sortThemesByName)}
            </div>
        </div>
	);
};

export default ThemeGroup;
