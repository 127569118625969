import React, { useEffect, useState } from 'react';
import { NavLink, withRouter, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ContentList = (props) => {
	const { accountId, history } = props;

	const { featureImportedContent, featurePlaylists } = useFlags();

	const location = useLocation();

	const contentBaseRoute = `/account/${accountId}/allContent`;
	const managedContentBaseRoute = `${contentBaseRoute}/managedContent`;
	const importedContentBaseRoute = `${contentBaseRoute}/importedContent`;
	const playlistBaseRoute = `${contentBaseRoute}/playlists`;

	const [isExpanded, setIsExpanded] = useState(
		location.pathname.indexOf(contentBaseRoute) !== -1
	);

	useEffect(() => {
		setIsExpanded(location.pathname.indexOf(contentBaseRoute) !== -1);
	}, [location.pathname]);

	return (
		<Nav>
			<div
				className="nav-header"
				onClick={() => {
					if (
						!featureImportedContent &&
						location?.pathname !== managedContentBaseRoute
					) {
						history.push(managedContentBaseRoute);
					}
					setIsExpanded(!isExpanded);
				}}
			>
				Content
				{isExpanded ? <FaChevronDown /> : <FaChevronRight />}
			</div>
			{isExpanded && (
				<>
					<Nav.Item className="nav-link">
						<NavLink
							to={managedContentBaseRoute}
							activeClassName="nav-active"
							isActive={(match, location) => {
								if (location.pathname.indexOf(managedContentBaseRoute) !== -1) {
									return true;
								}

								if (
									location.pathname.indexOf(`/account/${accountId}/shows`) !==
									-1
								) {
									return true;
								}

								if (!match) {
									return false;
								}
							}}
						>
							<div className="nav-option">Managed Content</div>
						</NavLink>
					</Nav.Item>
					{featureImportedContent && (
						<Nav.Item className="nav-link">
							<NavLink
								to={importedContentBaseRoute}
								activeClassName="nav-active"
							>
								<div className="nav-option">Imported Content</div>
							</NavLink>
						</Nav.Item>
					)}
					{featurePlaylists && (
						<Nav.Item className="nav-link">
							<NavLink to={playlistBaseRoute} activeClassName="nav-active">
								<div className="nav-option">Playlists</div>
							</NavLink>
						</Nav.Item>
					)}
				</>
			)}
		</Nav>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions.accountRole,
});

export default withRouter(connect(mapStateToProps)(ContentList));
