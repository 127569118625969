import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { getAuthSingleton } from 'services/auth';
import Toast from 'services/toast';
import API from 'services/api';
import { trackEvent } from 'services/vitally';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ThumbnailUploader from 'components/shared/thumbnailUploader';
import Button from 'components/shared/button';
import UserCollectionPermissions from 'components/users/userCollectionPermissions';

import { Container, Row, Col, Modal, Form, Dropdown } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { isEmailValid } from 'utils/';

const UserModal = (props) => {
	const authSvc = getAuthSingleton();
	const { featureCollectionLevelPermissions } = useFlags();

	const {
		title,
		edit,
		isOpen,
		userData = {},
		onHide = () => {},
		accountId,
		roleIdValue,
		refreshUsers = () => {},
	} = props;

	const isCurrentUser = parseInt(authSvc.getUserId().userId) === userData.id;

	const { data, loading, error } = useQuery(queries.userRoles, {
		variables: { accountId },
	});
	const { userRoles } = data || [];

	let rolesList = [];
	if (userRoles)
		rolesList = userRoles.map((role) => {
			return role;
		});

	const { loadingPermissions, refetch } = useQuery(
		queries.getCollectionPermissionsForUserId,
		{
			variables: {
				accountId,
				userId: userData ? userData.id : null,
			},
			fetchPolicy: 'cache-and-network',
			onCompleted: (data) => {
				const collectionPermissionsData =
					data.getCollectionPermissionsForUserId.map(
						(permission) => ({
							id: permission.id,
							accountId: permission.accountId,
							userId: permission.userId,
							roleId: permission.roleId,
							collectionName: permission.collection.name,
							collectionId: permission.collection.id,
						})
					);

				const collectionIds = [];
				const individualPermissions = [];
				let defaultPermissions = [];

				collectionPermissionsData.map((permission) => {
					if (permission.userId) {
						individualPermissions.push(permission);
						collectionIds.push(permission.collectionId);
					} else {
						defaultPermissions.push(permission);
					}
				});
				defaultPermissions = defaultPermissions.filter(
					(permission) =>
						!collectionIds.includes(permission.collectionId)
				);
				setDefaultPermissions(defaultPermissions);
				setUserPermissions(individualPermissions);
				setFilteredDefaultPermissions(defaultPermissions);
			},
		}
	);

	const [
		updateUser,
		{
			data: updateUserData,
			loading: updateUserLoading,
			error: updateUserError,
		},
	] = useMutation(mutations.updateUser, { refetchQueries: ['accountUsers'] });

	const [
		updateUserPermissions,
		{
			data: updateUserPermissionData,
			loading: updateUserPermissionsLoading,
			error: updateUserPermissionsError,
		},
	] = useMutation(mutations.updateUserPermissions);

	const roles = userData.roles || [];
	const roleObj = roles[0] || {};
	const currentRoleId = roleObj.id || '';
	const getCurrentRoleName = (id) => {
		const role = rolesList.find((r) => r.id === id);
		if (role) return role.name;
		return undefined;
	};

	const [firstName, setFirstName] = useState(
		userData.firstName ? userData.firstName : ''
	);
	const [lastName, setLastName] = useState(
		userData.lastName ? userData.lastName : ''
	);
	const [email, setEmail] = useState(userData.email ? userData.email : '');
	const [avatarUrl, setAvatarUrl] = useState(
		userData.avatarUrl ? userData.avatarUrl : ''
	);
	const [roleId, setRoleId] = useState(currentRoleId);
	const [saveUserLoading, setSaveUserLoading] = useState(false);
	const [shouldValidateEmail, setShouldValidateEmail] = useState(false);
	const [isEmailInvalid, setIsEmailInvalid] = useState(false);
	const [invalidForm, setInvalidForm] = useState(
		!firstName || !lastName || !email || !roleId || (shouldValidateEmail && isEmailInvalid)
	);
	const [userPermissions, setUserPermissions] = useState([]);
	const [permissionsToUpdate, setPermissionsToUpdate] = useState([]);
	const [permissionsToRemove, setPermissionsToRemove] = useState([]);
	const [defaultPermissions, setDefaultPermissions] = useState([]);
	const [filteredDefaultPermissions, setFilteredDefaultPermissions] =
		useState([]);

	useEffect(() => {
		setInvalidForm(!firstName || !lastName || !email || !roleId || (shouldValidateEmail && isEmailInvalid));
	}, [firstName, lastName, email, roleId, shouldValidateEmail, isEmailInvalid]);

	const updateUserAvatar = async (imgData, fileExt) => {
		try {
			const gcsUrl = await API.uploadPublicFile({
				dataURI: imgData,
				fileExt: fileExt,
			});
			setAvatarUrl(gcsUrl);
		} catch (e) {
			setAvatarUrl('');
			Toast.error(
				typeof e.message  === 'string' ? e.message : 'Something went wrong!'
			);
		}

	};

	const handleEmailUpdate = (email) => {
		setEmail(email);
		setShouldValidateEmail(true);
		if(!isEmailValid(email)) {
			setIsEmailInvalid(true)
			return;
		}

		setIsEmailInvalid(false);
	}

	useEffect(() => {
		if (props.isOpen && !edit) {
		  // Clear out the form fields when the modal is opened
		  setFirstName('');
		  setLastName('');
		  setEmail('');
		  setAvatarUrl('');
		  setRoleId('');
		  setShouldValidateEmail(false);
		  setUserPermissions([]);
		  setPermissionsToUpdate([]);
		  setPermissionsToRemove([]);
		  setDefaultPermissions([]);
		  setFilteredDefaultPermissions([]);
		}
	  }, [props.isOpen]);

	const onSubmitClick = async () => {
		const emailLower = email.toLowerCase();
		if(!isEmailValid(emailLower)) {
			setIsEmailInvalid(true);
			return;
		}
		
		if (userData.id) {
			await updateUser({
				variables: {
					userId: userData.id,
					accountId,
					userSettings: {
						firstName,
						lastName,
						email: emailLower,
						avatarUrl,
						roleId,
					},
				},
			});
			await submitPermissions(userData.id);
			setSaveUserLoading(
				updateUserLoading || updateUserPermissionsLoading
			);
			if (!updateUserError && !updateUserPermissionsError)
				Toast.success('User saved');
				onHide(true);
		} else {
			let user = {
				firstName,
				lastName,
				email: emailLower,
				avatarUrl,
				roleId,
			};
			try {
				const newUserId = await authSvc.inviteUser(user, accountId);
				await submitPermissions(newUserId);
				Toast.success('User invitation sent');
				refreshUsers();
				trackEvent('add-user', {
					name: `${firstName} ${lastName}`,
					email,
					role: getCurrentRoleName(roleId),
				});
				onHide(true);
			} catch (e) {
				console.log(e);
			}
		}
	};

	const submitPermissions = async (userId) => {
		const userPermissionsUpdate = [];
		userPermissions.forEach((permission) => {
			if (permissionsToUpdate.includes(permission.collectionId)) {
				const { collectionName, ...permissionUpdate } = permission;
				userPermissionsUpdate.push(permissionUpdate);
			}
		});

		if (
			userPermissionsUpdate.length > 0 ||
			permissionsToRemove.length > 0
		) {
			await updateUserPermissions({
				variables: {
					userId,
					accountId,
					permissionsToUpdate: userPermissionsUpdate,
					permissionsToRemove,
				},
			});
		}
	};

	return (
		<Modal
			show={isOpen}
			onHide={onHide}
			dialogClassName="modal-wide"
			centered
			backdrop="static"
		>
			<Container className="user-modal">
				
				<Row className="my-3">
					<Col>
						<h3 className="text-center">{title}</h3>
					</Col>
					<FaTimes onClick={onHide} />
				</Row>

				<Row className="mb-3">
					<Col className="" sm={8}>
						<Row className="mb-5">
							<Col className="">
								<Form.Label>First Name</Form.Label>
								<Form.Control
									className="shared-episode-link-text"
									onChange={(e) => setFirstName(e.target.value)}
									onBlur={(e) =>
										setFirstName(e.target.value.trim())
									}
									value={firstName}
								/>
							</Col>
							<Col className="">
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									className="shared-episode-link-text"
									onChange={(e) => setLastName(e.target.value)}
									onBlur={(e) =>
										setLastName(e.target.value.trim())
									}
									value={lastName}
								/>
							</Col>

						</Row>
						<Row className="mt-5">
							<Col className="">
								<Form.Label>Email</Form.Label>
								<Form.Control
									className="shared-episode-link-text"
									onChange={(e) => setEmail(e.target.value.trim())}
									onBlur={(e) => handleEmailUpdate(e.target.value.trim())}
									value={email}
									disabled={edit}
									isInvalid={shouldValidateEmail && isEmailInvalid}
								/>
								{shouldValidateEmail && isEmailInvalid && (<Form.Control.Feedback type='invalid'>
									Must be a valid email
								</Form.Control.Feedback>)}
							</Col>
							<Col className=" role-container" >
								<Form.Label>Role</Form.Label>
								<Dropdown
									onSelect={(id) => setRoleId(id)}
									className="user-role-select"
								>
									<Dropdown.Toggle
										variant="outline-dark"
										disabled={isCurrentUser}
									>
										{getCurrentRoleName(roleId) ||
											'Select a Role'}
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{rolesList.map((role) => (
											<Dropdown.Item
												eventKey={role.id}
												key={role.id}
											>
												<p className="role--name">
													{role.name}
												</p>
												{role.description && (
													<p className="role--description">
														{role.description}
													</p>
												)}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
					</Col>
					<Col className="profile-thumbnail d-flex justify-content-center" sm={4}>
						<Form.Label>Profile Thumbnail</Form.Label>
						<>
							<ThumbnailUploader
								className="user-avatar-upload"
								overlayMsg="Upload"
								thumbnailImgSrc={avatarUrl}
								onUpload={(result, fileExt) =>
									updateUserAvatar(result, fileExt)
								}
							/>
						</>
					</Col>
				</Row>
				
				{featureCollectionLevelPermissions &&
					(userPermissions.length > 0 ||
						defaultPermissions.length > 0) && (
						<Row>
							<Col>
								<UserCollectionPermissions
									rolesList={rolesList}
									userPermissions={userPermissions}
									setUserPermissions={setUserPermissions}
									permissionsToUpdate={permissionsToUpdate}
									setPermissionsToUpdate={
										setPermissionsToUpdate
									}
									permissionsToRemove={permissionsToRemove}
									setPermissionsToRemove={
										setPermissionsToRemove
									}
									defaultPermissions={defaultPermissions}
									setDefaultPermissions={
										setDefaultPermissions
									}
									filteredDefaultPermissions={
										filteredDefaultPermissions
									}
									setFilteredDefaultPermissions={
										setFilteredDefaultPermissions
									}
									loadingPermissions={loadingPermissions}
									isCurrentUser={isCurrentUser}
									isAdmin={
										getCurrentRoleName(roleId) === 'Admin'
									}
								/>
							</Col>
						</Row>
					)}
				<Row className="mt-5">
					<Col className="d-flex justify-content-center">
						<Button
							variant="success"
							className="submit-user"
							onClick={onSubmitClick}
							disabled={saveUserLoading || invalidForm}
						>
							{saveUserLoading ? 'SAVING...' : 'SAVE CHANGES'}
						</Button>
					</Col>
				</Row>
			</Container>
		</Modal>
	);
};

export default UserModal;
