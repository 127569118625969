export const ACTIONS = {
	SET_PROMOTION_METRICS_DATE: 'CASTED/SET_PROMOTION_METRICS_DATE',
};

const initialState = {
	promotionMetricsDate: {},
};

export const setPromotionMetricsDate = (promotionDate) => {
	return {
		type: ACTIONS.SET_PROMOTION_METRICS_DATE,
		promotionDate,
	};
};

const metricsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SET_PROMOTION_METRICS_DATE:
			return {
				...state,
				promotionMetricsDate: action.promotionDate,
			};
		default:
			return state;
	}
};

export default metricsReducer;
