import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { CSVLink } from 'react-csv';
import { v4 as uuidv4 } from 'uuid';

import queries from 'services/graphql/queries';

import { Col, Dropdown, DropdownButton, Row, Spinner } from 'react-bootstrap';
import { METRICS_CONTEXTS } from 'components/constants';

/**
 * ExportMetrics
 * Props
 * @param {UUID}   podcastId  Required
 * @param {UUID}   episodeId  Required only if export type is Episode
 * @param {Object} dateRange  Object containing start date and end date
 */
const ExportMetrics = (props) => {
	const {
		accountId,
		podcastId,
		episodeId,
		shareSlug,
		dateRange,
		getSegmentsExportData,
		getAudienceExportData,
		context = '',
		setIsExportModalOpen,
		contextIdVariable,
	} = props;

	// Queries
	const [getItemExportData] = useLazyQuery(queries.exportItemMetrics, {
		variables: { ...contextIdVariable, accountId: accountId },
	});

	// State
	const [exportData, setExportData] = useState([]);

	// Queries
	const [getShareExportData, { loading }] = useLazyQuery(
		queries.getShareExportData,
		{
			variables: {
				podcastId,
				episodeId,
				shareSlug,
				startDate: dateRange.start,
				endDate: dateRange.end,
			},
			fetchPolicy: 'cache-and-network',
			notifyOnNetworkStatusChange: true,
			onCompleted: (data) => {
				setExportData(data.getShareExportData);
			},
		}
	);

	// Effects
	useEffect(() => {
		if (dateRange.start && dateRange.end && shareSlug) {
			getShareExportData();
		}
	}, [dateRange]);

	return (
		<Row>
			<Col
				className={`d-flex justify-content-end  ${
					context === METRICS_CONTEXTS.item && 'mb-4'
				}`}
			>
				<DropdownButton
					title={
						loading ? (
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						) : (
							'DOWNLOAD CSV FILE'
						)
					}
					className="export-menu insights"
					variant="success"
					disabled={loading}
				>
					{shareSlug && (
						<CSVLink
							filename={`${uuidv4()}.csv`}
							data={exportData}
							className={`export-link ${loading ? 'disabled' : ''}`}
						>
							<span className="export-type">Episode Metrics</span>
						</CSVLink>
					)}
					{!shareSlug && (
						<Dropdown.Item
							onClick={() => {
								getItemExportData({
									variables: {
										startDate: dateRange.start,
										endDate: dateRange.end,
									},
									fetchPolicy: 'no-cache',
								});
								setIsExportModalOpen(true);
							}}
						>
							Episode Metrics
						</Dropdown.Item>
					)}
					<span className="export-type"></span>
					<Dropdown.Item onClick={() => getSegmentsExportData()}>
						Segments
					</Dropdown.Item>
					<Dropdown.Item onClick={() => getAudienceExportData()}>
						Audience
					</Dropdown.Item>
				</DropdownButton>
			</Col>
		</Row>
	);
};

export default ExportMetrics;
