import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';

import queries from 'services/graphql/queries';
import { getSelectedAccount } from 'store/selectors/accounts';
import { METRICS_CONTEXTS } from 'components/constants';

import MetricsScreen from 'components/shared/metricsScreen';

const ThemeMetrics = (props) => {
	const { selectedAccount, themeId } = props;

	const accountId = selectedAccount.id;

	// Determine if account has Hosted shows
	const { data, loading } = useQuery(queries.podcastsByAccountId, {
		variables: { accountId },
	});
	const { podcasts } = data || {};
	const isHosted =
		podcasts && podcasts.some((p) => p.externalId) ? true : false;

	const contextQueries = {
		integrations: queries.accountIntegrations,
		totalAndUniqueAudience: queries.getThemeListenSummary,
		newAndLoyalAudience: queries.getThemeNewAndLoyalListeners,
		averageCompletion: queries.getThemeAverageCompletion,
		listensOverTimeHosted: queries.getDailyThemeDownloadAndPlayCount,
		listensOverTimeUnhosted: queries.getDailyThemeListens,
	};

	return (
		<MetricsScreen
			accountId={accountId}
			metricsContext={METRICS_CONTEXTS.theme}
			isHosted={isHosted}
			contextIdVariable={{ themeId: themeId }}
			contextQueries={contextQueries}
			className={'theme-metrics'}
		/>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	themeId: state.themeData.theme.id,
});

export default connect(mapStateToProps)(ThemeMetrics);
