import React from 'react';

const Pagination = (props) => {
	const {
		current = 1,
		resultsPerPage,
		onChange = () => {},
		totalResults,
	} = props;

	let minPage = 1;
	let maxPage = Math.ceil(totalResults / resultsPerPage);
	let pageRange = 2;
	let pageItems = [];

	for (let i = minPage; i <= maxPage; i++) {
		if (i >= current - pageRange && i <= current + pageRange) {
			pageItems.push(
				<li
					className={`pagination-item ${
						current === i ? 'pagination-active' : ''
					}`}
					key={`pagination-item-${i}`}
					onClick={() => changePage(i)}
				>
					{i}
				</li>
			);
		}
	}

	const changePage = (page) => {
		onChange(page);
	};

	const prevPage = () => {
		let minPage = 1;
		let newPage = current - 1;

		if (newPage >= minPage) {
			changePage(newPage);
		}
	};

	const nextPage = () => {
		let newPage = current + 1;

		if (newPage <= maxPage) {
			changePage(newPage);
		}
	};

	return (
		<div className="casted-pagination">
			<ul className="pagination-list">
				<li
					className={`pagination-item ${
						current === minPage ? 'pagination-disabled' : ''
					}`}
					onClick={() => prevPage()}
				>
					Prev
				</li>
				{current > minPage + pageRange && (
					<React.Fragment>
						<li
							className={`pagination-item ${
								current === minPage ? 'pagination-disabled' : ''
							}`}
							onClick={() => changePage(minPage)}
						>
							{minPage}
						</li>
						<li className="pagination-item">...</li>
					</React.Fragment>
				)}
				{pageItems}
				{current < maxPage - pageRange && (
					<React.Fragment>
						<li className="pagination-item">...</li>
						<li className="pagination-item" onClick={() => changePage(maxPage)}>
							{maxPage}
						</li>
					</React.Fragment>
				)}
				<li
					className={`pagination-item ${
						current === maxPage ? 'pagination-disabled' : ''
					}`}
					onClick={() => nextPage()}
				>
					Next
				</li>
			</ul>
		</div>
	);
};

export default Pagination;
