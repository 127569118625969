import React from 'react';

const ClipRange = (props) => {
	const { duration, startTime, endTime } = props;

	const getRange = (time) => {
		const range = (time / duration) * 100;

		if (time === endTime) {
			return `${100 - range}%`;
		} else {
			return `${range}%`;
		}
	};

	const getWidth = () => {
		if (endTime - startTime < 100) {
			return '5px';
		} else if (endTime - startTime < 200) {
			return '10px';
		} else if (endTime - startTime < 300) {
			return '15px';
		} else {
			return '30px';
		}
	};

	return (
		<>
			<div
				className="clip-border top"
				style={{ left: getRange(startTime), width: getWidth() }}
			></div>
			<div
				className="clip-border height"
				style={{ left: getRange(startTime) }}
			></div>
			<div
				className="clip-border bottom"
				style={{ left: getRange(startTime), width: getWidth() }}
			></div>

			<div
				className="clip-border top"
				style={{ right: getRange(endTime), width: getWidth() }}
			></div>
			<div
				className="clip-border height"
				style={{ right: getRange(endTime) }}
			></div>
			<div
				className="clip-border bottom"
				style={{ right: getRange(endTime), width: getWidth() }}
			></div>
		</>
	);
};

export default ClipRange;
