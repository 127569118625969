import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { history, dispatch } from 'store/store';

import ShareCard from '../shareCard';
import { showArchiveShareModal } from 'store/reducers/ui/modals/archiveShareModal';
import { SHARE_STATUS } from 'components/constants';

import {
	SearchCore,
	SearchInput,
	SearchSidebar,
	SearchResults,
	CheckboxList,
	RECORD_TYPES,
	DateFilter,
	ToggleFilter,
	SortFilter,
} from '@casted-app/casted-react-search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import podcat from 'assets/images/podcat-playing.svg';

const ShareSearch = (props) => {
	const {
		accountId,
		selectedShares,
		selectShareHandler,
		setSelectedShare,
		setModalOpen,
		setSelectedShares,
		users,
		archivedShares,
		setArchivedShares,
		updatedShares,
	} = props;

	const renderRowLayout = (result) => {
		let share;
		let disabled = false;
		if (archivedShares.some((share) => share.id === result?.share_id?.raw)) {
			disabled = true;
		}

		if (updatedShares.some((share) => share.id === result?.share_id?.raw)) {
			share = updatedShares.find((share) => share.id === result?.share_id?.raw);
		} else {
			share = {
				id: result?.share_id?.raw,
				title: result?.share_title?.snippet,
				description: result?.share_description?.snippet,
				shareType: result?.share_share_type?.raw,
				createdUser: result?.share_created_by?.raw
					? users.find((user) => user.id === result.share_created_by.raw)
					: '',
				contentType: result?.share_content_type?.raw,
				shareSlug: result?.share_share_slug?.raw,
				status: result?.share_status?.raw,
				createdAt: result?.share_created_at?.raw,
				thumbnail: result?.episode_thumbnail?.raw
					? result.episode_thumbnail.raw
					: podcat,
				collectionId: result?.podcast_id?.raw,
			};
		}

		return (
			<ShareCard
				share={share}
				selectedShares={selectedShares}
				selectShareHandler={selectShareHandler}
				setSelectedShare={setSelectedShare}
				setModalOpen={setModalOpen}
				disabled={disabled}
				archivedShares={archivedShares}
				setArchivedShares={setArchivedShares}
			/>
		);
	};

	return (
		<div className="share-search-container">
			<SearchCore
				searchKey={process.env.REACT_APP_ELASTIC_SEARCH_KEY}
				engineName={process.env.REACT_APP_ELASTIC_ENGINE_NAME}
				endpointBase={process.env.REACT_APP_ELASTIC_ENDPOINT_BASE}
				accountId={accountId}
				recordTypes={[RECORD_TYPES.SHARE]}
				resultsPerPage={5}
			>
				<div className="share-search-header">
					<div className="search-icon">
						<FontAwesomeIcon icon={faSearch} size="sm" />
					</div>
					<SearchInput
						showClose={false}
						placeholder="Search your content here."
					/>
				</div>
				<div className="share-search-body">
					<div className="share-search-sidebar">
						<SearchSidebar
							filters={[
								<DateFilter fieldLabel="Item Date" field="share_created_at" />,
								<SortFilter
									label="Sort By"
									sortOptions={[
										{
											name: 'Most Recent',
											value: 'share_updated_at',
											direction: 'desc',
										},
										{
											name: 'Least Recent',
											value: 'share_updated_at',
											direction: 'asc',
										},
									]}
								/>,
								<CheckboxList fieldLabel="Collections" field="podcast_name" />,
								<CheckboxList
									fieldLabel="Share Type"
									field="share_share_type"
								/>,
								<ToggleFilter
									toggledOnValue="hidden"
									toggledOffValue="active"
									fieldLabel="Show Archived Shares"
									field="share_status"
									onChange={() => setSelectedShares([])}
								/>,
							]}
						/>
					</div>
					<div className="share-search-results">
						{selectedShares.length > 0 && (
							<div className="selected-shares">
								<h5 className="shares-selected">
									{selectedShares.length}{' '}
									{selectedShares.length > 1 ? 'Items' : 'Item'} selected
								</h5>
								<span
									className="archive-shares"
									onClick={() => dispatch(showArchiveShareModal())}
								>
									{selectedShares[0].status === SHARE_STATUS.ACTIVE
										? 'Archive Selected Items'
										: 'Unarchive Selected Items'}
								</span>
							</div>
						)}
						<SearchResults
							history={history}
							displayResultsTime={false}
							renderRowLayout={renderRowLayout}
							paginationPosition="right"
						/>
					</div>
				</div>
			</SearchCore>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
});

export const ShareSearchWithRouter = withRouter(
	connect(mapStateToProps)(ShareSearch)
);
