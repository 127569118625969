import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { useHistory, Link } from 'react-router-dom';
import queries from 'services/graphql/queries';

import { capitalize } from 'utils';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import BaseTable from 'components/shared/baseTable';
import MetricEmptyState from 'components/shared/metricEmptyState';
import { numberSort } from 'utils';

const TopClips = (props) => {
	const { variables } = props;
	const history = useHistory();
	const episodePath = history.location.pathname.replace('metrics', '');

	const { data, loading, error } = useQuery(queries.getTopClips, {
		variables,
		fetchPolicy: 'cache-and-network',
	});
	const { getTopClips: clips = [] } = data || {};

	const values = clips.map((clip) => {
		return {
			id: clip.id,
			clipId: clip.clip_id,
			name: clip.clip_name,
			parentName: clip.episode_name,
			type: clip.clip_type,
			data: [clip.total_listens, clip.total_likes],
			episodeId: clip.episode_id
		};
	});

	const popover = (name, type) => {
		return (
			<Popover className="long-title-popover">
				<Popover.Content>
					<strong>{type} Name: </strong>
					<br />
					{name}
				</Popover.Content>
			</Popover>
		);
	};

	const popoverCell = (name, type, episodeId, clipId) => {
		return (
			<OverlayTrigger
				trigger={['hover', 'focus']}
				placement="right-end"
				overlay={popover(name, type)}
				show={name.length > 140}
			>
				<Link to={{pathname: `${episodePath}episodes/${episodeId}/clips?clip=${clipId}`}} target="_blank">
					{name.length > 140 ? name.substr(0, 139).concat('...') : name}
				</Link>
			</OverlayTrigger>
		);
	};

	const columns = [
		{
			name: 'Clip',
			selector: (row) => row.name,
			sortable: true,
			cell: (row) => popoverCell(row.name, 'Clip', row.episodeId, row.clipId)
		},
		{
			name: 'Item',
			selector: (row) => row.parentName,
			sortable: true,
			cell: (row) => popoverCell(row.parentName, 'Item', row.episodeId, row.clipId)
		},
		{
			name: 'Type',
			selector: (row) => capitalize(row.type),
			sortable: false,
		},
		{
			name: 'Listens',
			selector: (row) => row.data[0],
			sortable: true,
			sortFunction: numberSort((row) => row.data[0]),
		},
		{
			name: 'Likes',
			selector: (row) => row.data[1],
			sortable: true,
			sortFunction: numberSort((row) => row.data[1]),
		},
	];

	const onClickHandler = (clip) => {
		window.open(
			`${episodePath}episodes/${clip.episodeId}/clips?clip=${clip.clipId}`,
			'_blank'
		);
	};

	return (
		<>
			<h4>Top Clips</h4>
			<BaseTable
				values={values || []}
				columns={columns}
				loading={loading}
				noDataComponent={<MetricEmptyState metricName="Top Clips" />}
				clickable={true}
				className="clickable"
				onClick={onClickHandler}
			/>
		</>
	);
};

export default TopClips;
