import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import uniqueSlug from 'unique-slug';

import { Card } from 'react-bootstrap';
import ShareForm from 'components/shared/shareForm';
import GenerateButton from 'components/shared/shareContentModal/generateButton';

const SocialPreview = (props) => {
	const { name = '', thumbnail = '', link = '', clipId, accountId } = props;

	const url = new URL(link);
	const domain = url.hostname;

	const [shareDetails, setShareDetails] = useState({
		title: `${name} Link ${new Date().toLocaleDateString()}`,
	});
	const { title, destination, description } = shareDetails;

	const [createShare] = useMutation(mutations.createShare);

	const createShareLink = async () => {
		const shareSlug = uniqueSlug();
		await navigator.clipboard.writeText(`${link}/share/${shareSlug}`);

		let share = await createShare({
			variables: {
				share: {
					accountId: accountId,
					clipId: clipId,
					title: title,
					destination: destination,
					description: description,
					shareType: 'link',
					contentType: 'clip',
					shareOption: {},
					shareSlug,
				},
			},
		});
		share = share.data.createShare;

		if (share && share.id) {
			trackEvent('create-share-link', { shareId: share.id });
		}
	};

	return (
		<div className="social-card-preview">
			<div className="preview-instructions">
				<h4 className="preview-title">Shareable Link</h4>
				<p>This link can be shared on social media, see the preview below:</p>
			</div>

			<Card>
				<Card.Body className="mock-header">
					<div className="mock-profile">
						<div className="mock-profile-img"></div>
						<div className="mock-profile-name">
							<div></div>
							<div></div>
						</div>
					</div>
					<div className="mock-description">
						Something you want to say about this clip / episode
					</div>
				</Card.Body>
				<div
					className="card-img-top"
					style={{ backgroundImage: `url(${thumbnail})` }}
				></div>
				<Card.Body className="link-details">
					<p className="socialCard--episode-name">
						<strong>{name}</strong>
					</p>
					<p className="socialCard--episode-domain">{domain}</p>
				</Card.Body>
			</Card>

			<div className="share-copy share">
				<ShareForm share={shareDetails} setShareProperty={setShareDetails} />
				<GenerateButton
					buttonText="Generate Link"
					createShare={createShareLink}
					disabled={title.trim() === ''}
					tooltipText="Link Copied"
					title={title}
				/>
			</div>
		</div>
	);
};

export default SocialPreview;
