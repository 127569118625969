import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { Tooltip } from 'react-bootstrap';
import MetricCard from 'components/shared/metricCard';

const UniqueListens = (props) => {
	const { variables, comparisonVariables, query, showPercents } = props;

	const [value, setValue] = useState(0);
	const [comparisonValue, setComparisonValue] = useState(0);

	const { loading } = useQuery(query, {
		variables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const {
				total_views = 0,
				play_count,
				download_count,
				total_unique_views,
			} = data[query?.definitions[0]?.name?.value];
			// Calculate the unique visitors of all listens based on unique visitors to casted
			// if no downloads are present (non-hosted) just show the unique_views
			setValue(
				download_count
					? Math.round(
							(download_count + play_count) * (total_unique_views / total_views)
					  )
					: total_unique_views
			);
		},
	});

	const { compLoading } = useQuery(query, {
		variables: comparisonVariables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const {
				total_views = 0,
				play_count,
				download_count,
				total_unique_views,
			} = data[query?.definitions[0]?.name?.value];
			// Calculate the unique visitors of all listens based on unique visitors to casted
			// if no downloads are present (non-hosted) just show the unique_views
			setComparisonValue(
				download_count
					? Math.round(
							(download_count + play_count) * (total_unique_views / total_views)
					  )
					: total_unique_views
			);
		},
	});

	const tooltip = (
		<Tooltip className="metric-card-tooltip">
			<p className="label tooltip-stat">
				This Period: <span className="value">{value}</span>
			</p>

			{comparisonVariables.startDate && (
				<>
					<hr />
					<p className="label label-date previous-period">
						Previously: <span className="value">{comparisonValue}</span>
					</p>
				</>
			)}
		</Tooltip>
	);

	return (
		<MetricCard
			value={value || 0}
			comparisonValue={comparisonValue}
			loading={loading || compLoading}
			tooltip={tooltip}
			label="Unique Audience"
			showPercents={showPercents}
			hideTooltip={true}
		/>
	);
};

export default UniqueListens;
