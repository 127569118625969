import CastedDropdownButton from 'components/shared/dropdownButton';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { Button, Dropdown } from 'react-bootstrap';
import { FaChevronDown, FaPlus, FaSearch } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import mutations from 'services/graphql/mutations';
import queries from 'services/graphql/queries';
import { getSelectedAccount } from 'store/selectors/accounts';

const NewShowCollectionPermissions = (props) => {
	// Props
	const {
		collection,
		onCloseClick,
		selectedAccountId,
		selectedAccount,
		userProfile,
	} = props;

	// Hooks
	const [searchUsers, setSearchUsers] = useState('');
	const [showSearchResults, setShowSearchResults] = useState(false);
	const [userPermissions, setUserPermissions] = useState([]);

	//Mutation hooks
	const [updateUserPermissions] = useMutation(mutations.updateUserPermissions);

	const [createCollectionPermission] = useMutation(
		mutations.createCollectionPermission
	);

	const [updateCollectionPermissionById] = useMutation(
		mutations.updateCollectionPermissionById
	);

	// Query hooks
	const queryVars = {
		variables: {
			accountId: selectedAccountId,
			includeInternalUsers: selectedAccount.name === 'Casted',
		},
		fetchPolicy: 'cache-and-network',
	};

	const {
		data: { accountUsers = [] } = {},
		loadingUsers,
		errorUsers,
	} = useQuery(queries.accountUsers, queryVars);

	const { data, loadingRoles, errorRoles } = useQuery(queries.userRoles, {
		variables: { accountId: selectedAccountId },
	});
	const { userRoles } = data || [];

	const nullRoleInfo = {
		name: 'Has No Access',
		description: 'Will be unable to see or interact with collection',
		status: 'active',
		slug: 'no_access',
	};

	const everyoneRolesPermissionsChoices = [
		{
			...userRoles?.filter((role) => role.slug === 'viewer')[0],
		},
		{
			...nullRoleInfo,
		},
	];

	// Set userPermissions for base case
	useEffect(() => {
		async function setDefaultPermissions() {
			if (userProfile && userRoles) {
				const adminRole = userRoles.filter((role) => role.slug === 'admin')[0];

				// Create everyone default role
				let everyone = {
					accountId: selectedAccountId,
					collectionId: collection.id,
					role: null,
					user: null,
				};

				const { data: everyoneData } = await createCollectionPermission({
					variables: {
						accountId: selectedAccountId,
						collectionPermission: {
							accountId: selectedAccountId,
							collectionId: collection.id,
						},
					},
				});

				everyone.id = everyoneData.createCollectionPermission.id;

				// Create admin role
				let admin = {
					accountId: selectedAccountId,
					collectionId: collection.id,
					role: adminRole,
					user: userProfile,
				};

				const { data: adminData } = await createCollectionPermission({
					variables: {
						accountId: selectedAccountId,
						collectionPermission: {
							accountId: selectedAccountId,
							collectionId: collection.id,
							roleId: adminRole.id,
							userId: userProfile.id,
						},
					},
				});

				admin.id = adminData.createCollectionPermission.id;

				// Set default everyone has none and creator has admin case
				const newUserPermissions = [everyone, admin];

				setUserPermissions(newUserPermissions);
			}
		}

		setDefaultPermissions();
	}, [userProfile, userRoles]);

	// Loading & fetch error handling
	if (loadingUsers || loadingRoles) {
		return <div>Loading...</div>;
	}

	if (errorUsers || errorRoles) {
		return (
			<div>
				Error loading content. Please try again or reach out to support if error
				persists.
			</div>
		);
	}

	const addUserRole = (user) => {
		// Clear out search results after add
		setSearchUsers('');

		// If we dont have user roles from API yet we cant really do much here
		if (!userRoles) {
			return;
		}

		const viewerRole = userRoles.filter((role) => role.slug === 'viewer')[0];
		const accountRole = userRoles.find((role) => role.id === user.roles[0].id);

		setUserPermissions(
			userPermissions.concat({
				accountId: selectedAccountId,
				collectionId: collection.id,
				role: viewerRole,
				user: user,
				isAccountAdmin: accountRole?.slug === 'admin',
			})
		);
	};

	const userSearchResults = () => {
		// If we've got no search string hide the search results and dont bother filtering
		if (searchUsers.length === 0 && showSearchResults) {
			setShowSearchResults(false);
			return;
		}

		// get users already in the permission list
		const usersToExclude = userPermissions.map(
			(permission) => permission.user?.id
		);

		// Only return users that aren't in the permission list already
		let filteredUsers = accountUsers.filter((user) => {
			return !usersToExclude.includes(user.id);
		});

		// Filter based on search
		if (searchUsers.length > 0) {
			const searchString = searchUsers.toLowerCase();
			filteredUsers = filteredUsers.filter(
				(user) =>
					user.firstName?.toLowerCase()?.includes(searchString) ||
					user.lastName?.toLowerCase()?.includes(searchString) ||
					user.email?.toLowerCase()?.includes(searchString)
			);
		}

		// Handle no result case
		if (filteredUsers.length === 0) {
			return (
				<div className="user-result-row">
					<div className="user-result-empty">No matching users found</div>
				</div>
			);
		}

		return filteredUsers.map((user) => {
			return (
				<div
					className="user-result-row"
					onClick={() => {
						addUserRole(user);
						setShowSearchResults(false);
					}}
				>
					<div className="user-info">
						<div className="user-name">
							{user.firstName} {user.lastName}
						</div>
						<div className="user-email helper-text">{user.email}</div>
					</div>
					<div className="user-search-action">
						<FaPlus className="fa-icon" />
						<div className="action-label">Add</div>
					</div>
				</div>
			);
		});
	};

	const updatePermissionList = async (userPermissionIndex, role) => {
		let updatedUserPermissions = [...userPermissions];

		// No access case is a null role.
		if (role?.slug === 'no_access') {
			role = null;
		}

		// Have to handle everyone (aka default) role in
		// a special way by updating it as it changes since its used in the
		// rest of the creation process
		if (userPermissionIndex === 0) {
			const roleId = role ? role.id : null;
			await updateCollectionPermissionById({
				variables: {
					accountId: selectedAccountId,
					collectionPermissionId: updatedUserPermissions[0].id,
					updates: { roleId },
				},
			});
		}

		if (role?.slug === 'remove') {
			updatedUserPermissions.splice(userPermissionIndex, 1);
		} else {
			updatedUserPermissions[userPermissionIndex] = {
				...updatedUserPermissions[userPermissionIndex],
				role: role,
			};
		}

		setUserPermissions(updatedUserPermissions);
	};

	const userPermissionsList = () => {
		return userPermissions.map((permission, userPermissionIndex) => {
			let user = permission.user;
			let roleName = permission.role?.name;
			// 0th index of userPermissions is the everyone case, which uses different role options
			const renderingEveryoneRow = userPermissionIndex === 0;

			// userRoles for all roles but the 'Everyone' row
			let roleOptions = userRoles;

			// Only need the remove option for userRoles, not everyone role cases
			roleOptions = roleOptions.concat({
				name: 'Revoke access to this collection.',
				description: 'Remove collection level permissions.',
				status: 'active',
				slug: 'remove',
			});

			// If this is the 'Everyone' case (we have account ID and collection ID
			// but no user or role set), set it up for displaying that
			if (renderingEveryoneRow) {
				user = { firstName: 'Everyone', lastName: '', email: '' };
				roleName = !roleName ? 'Has No Access' : roleName;
				roleOptions = everyoneRolesPermissionsChoices;
			}

			if (user) {
				return (
					<div className="user-result-row" id={userPermissionIndex}>
						<div className="user-info">
							<div className="user-name">
								{user.firstName} {user.lastName}
							</div>
							<div className="user-email helper-text">{user.email}</div>
						</div>
						<div className="user-list-action">
							<CastedDropdownButton
								className="dropdown-role-action"
								classType="plain-arrow"
								displayDropArrow={false}
								title={
									<>
										<div className="action-label">
											{permission.isAccountAdmin ? 'Account Admin' : roleName}
										</div>
										<FaChevronDown className="action-icon" />
									</>
								}
								disabled={
									user.id === userProfile.id || permission.isAccountAdmin
								}
							>
								{roleOptions.map((role, userRoleIndex) => {
									let itemContents = (
										<>
											<div className={`role-choice-name form-label`}>
												{role.name}
											</div>
											<div className="role-choice-description helper-text-sm">
												{role.description}
											</div>
										</>
									);
									// GOtta tweak the rendining for the revoke access option case
									if (role.slug === 'remove') {
										itemContents = (
											<>
												<div
													className={`role-choice-name form-label remove-role`}
												>
													{role.name}
												</div>
											</>
										);
									}
									if (role.status === 'active') {
										return (
											<Dropdown.Item
												className="dropdown-role-choice"
												onClick={() => {
													updatePermissionList(userPermissionIndex, role);
												}}
												id={userRoleIndex}
											>
												{itemContents}
											</Dropdown.Item>
										);
									}
								})}
							</CastedDropdownButton>
						</div>
					</div>
				);
			}
		});
	};

	const savePermissions = async () => {
		let permissionsToUpdate = [];
		userPermissions.forEach((permission) => {
			if (!permission || !permission?.role || permission?.isAccountAdmin) {
				return;
			} else {
				permissionsToUpdate.push({
					id: permission?.id ? permission?.id : null,
					accountId: selectedAccountId,
					collectionId: permission?.collectionId,
					roleId: permission?.role?.id ? permission?.role?.id : null,
					userId: permission?.user?.id ? permission?.user?.id : null,
				});
			}
		});

		const updatePayload = {
			variables: {
				userId: userProfile.id,
				accountId: selectedAccountId,
				permissionsToUpdate: permissionsToUpdate,
				permissionsToRemove: [],
			},
		};

		const permissionsUpdated = await updateUserPermissions(updatePayload);

		onCloseClick(collection);
	};

	return (
		<div className="new-show-collection-permissions-modal">
			<div className="user-search-container">
				<div className="user-search-input">
					<FaSearch />
					<input
						placeholder="Search for team members."
						value={searchUsers}
						onChange={(e) => {
							setSearchUsers(e.target.value);
							setShowSearchResults(true);
						}}
					/>
				</div>
				<div
					className={`user-search-results ${showSearchResults ? '' : 'hidden'}`}
				>
					{userSearchResults()}
				</div>
			</div>
			<div className="user-permissions-list-container">
				{userPermissionsList()}
			</div>
			<div className="user-permissions-actions-row">
				<Button
					name="user-permissions-save-btn"
					onClick={async () => {
						await savePermissions();
					}}
					className="user-permissions-save-btn label-btn-text"
					variant="success"
					title="Add Collection & Save"
					disabled={userPermissions.length < 2}
				>
					Save permissions
				</Button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	collection: state.ui.modals.newContentModal.collection,
	selectedAccountId: state.accounts.selectedAccountId,
	selectedAccount: getSelectedAccount(state),
	userProfile: state.auth.profile,
});

export default withRouter(
	connect(mapStateToProps)(NewShowCollectionPermissions)
);
