import React from 'react'
import {Modal, ButtonToolbar, Badge} from 'react-bootstrap'

import Button from '../../shared/button';

const DeleteEpisode = (props) => {
  const {
    onHide = () => {},
    onConfirm = () => {}
  } = props
  
  return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>
					<h4>Delete Episode?</h4>
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div>
					<Badge variant="danger" className="form-label mb-1">Your embeds will break!</Badge>
					<p>
						If your episode has been embedded anywhere, that embed will no
						longer work!
					</p>
				</div>
				<div>
					<Badge variant="warning" className="form-label mb-1">This is permanent</Badge>
					<p>
						Deleting an episode cannot be undone. Any links to clips or the
						episode will be redirected to the show's main page.
					</p>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<ButtonToolbar>
					<Button variant="light" onClick={onHide} className="mr-2">
						Cancel
					</Button>
					<Button variant="danger" onClick={onConfirm}>
						Delete
					</Button>
				</ButtonToolbar>
			</Modal.Footer>
		</>
	);
  
}

export default DeleteEpisode