import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import sanitizeHtml from 'sanitize-html';

import GenerateInfoButton from './generateInfoButton';

const MetaAndSearchResultsSection = (props) => {
	const {
		seoTitle: _seoTitle,
		metaDescription: _metaDescription,
		onChange = () => {},
		canEdit,
		episodeId,
		accountId,
		generatingAll,
		editsMade,
		setMetaSectionAllGenerated,
		disableGenerateButton
	} = props;

	const { featureAiSummarySuggestionAccess } = useFlags();

    const [seoTitle, setSeoTitle] = useState(_seoTitle);
    const [editMetaDescription, setMetaDescription] = useState(_metaDescription || '');
	const [metaEditsMade, setMetaEditsMade] = useState(false);

	// generating states
	const [isGeneratingSeoMeta, setIsGeneratingSeoMeta] = useState(false);
	const [seoMetaIsGenerated, setSeoMetaIsGenerated] = useState(false);
	const [showSeoMetaGenerateError, setShowSeoMetaGenerateError] = useState(false);
	const [customErrorMessage, setCustomErrorMessage] = useState(false);

	const [generateMetaDescription] = useMutation(mutations.generateSeoMeta, {
		onError: (err) => {
			console.error(err);
			if(err?.message === 'RateLimitError'){
				setCustomErrorMessage('Our AI is a bit busy right now, try again in a few minutes')
			}
			setShowSeoMetaGenerateError(true);
		},
	});

	useEffect(() => {
		if (!editsMade) {
			setMetaEditsMade(false);
		}
	}, [editsMade]);

	useEffect(() => {
		if (generatingAll) {
			generateMeta();
		}
	}, [generatingAll]);

	useEffect(() => {
		if(seoTitle !== _seoTitle) {
			onChange('seoTitle', seoTitle);
		}
	}, [seoTitle]);

	useEffect(() => {
		if (showSeoMetaGenerateError) {
			setTimeout(() => {
				setShowSeoMetaGenerateError(false);
			}, 5000);
		}
	}, [showSeoMetaGenerateError]);

	const generateMeta = async () => {
		setIsGeneratingSeoMeta(true);
		setSeoMetaIsGenerated(false);
		const { data } = await generateMetaDescription({
			variables: {
				episodeId,
				accountId,
			}
		});
		const { generateSeoMeta } = data || {};
		if (generateSeoMeta) {
			handleMetaDescription(generateSeoMeta);
			setIsGeneratingSeoMeta(false);
			setSeoMetaIsGenerated(true);
		} else {
			setIsGeneratingSeoMeta(false);
			setShowSeoMetaGenerateError(true);
		}
		setMetaSectionAllGenerated(true);
	}

    const handleMetaDescription = (value) => {
			let sanitizedContent = sanitizeHtml(value, {
				allowedTags: [],
			});
			if (
				sanitizedContent !== _metaDescription && 
				(sanitizedContent || _metaDescription)
			) {
				setMetaDescription(sanitizedContent);
				onChange('metaDescription', sanitizedContent);
				setMetaEditsMade(true);
			}
		};

	return (
		<Container className="px-2 pb-2">
			<Row className="mb-3">
				<Col>
					<Form.Label className="label">SEO Title</Form.Label>
					<Form.Control
						disabled={!canEdit}
						value={seoTitle}
						onChange={(e) => {
							setSeoTitle(e.target.value);
						}}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Form.Label className="label">Meta Description</Form.Label>
					<div className="meta--editor">
						<Form.Control
							as="textarea"
							rows="8"
							disabled={!canEdit}
							value={editMetaDescription}
							onChange={(e) => handleMetaDescription(e.target.value)}
							placeholder={
								'This field lets you control exactly what text shows up in search results. Meta descriptions only support plain text.'
							}
						/>
						{featureAiSummarySuggestionAccess && (
							<GenerateInfoButton
								editsMade={metaEditsMade}
								isGeneratingInfo={isGeneratingSeoMeta}
								infoIsGenerated={seoMetaIsGenerated}
								showInfoGenerateError={showSeoMetaGenerateError}
								funcToCall={generateMeta}
								className="simple-editor"
								disabled={disableGenerateButton}
								customErrorMessage={customErrorMessage}
								generateMessage = 'Generate a Meta Description'
							/>
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default MetaAndSearchResultsSection;
