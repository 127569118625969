import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { getAuthSingleton } from 'services/auth';
import { parseQuery } from 'utils/';
import { setLoggedIn } from 'store/reducers/data/auth';
import { setSelectedAccountId } from 'store/reducers/data/accounts';
import Button from 'components/shared/button';
import LoginAndSignUpBox from 'components/login/loginAndSignUpBox';
import logo from 'assets/logos/casted-vert.svg';

class Login extends React.Component {
	authSvc = getAuthSingleton();

	componentDidMount = () => {
		const search = window.location.search;
		const params = parseQuery(search);

		if (params.code && Number(params.code) > 300) {
			this.props.logout();
		}

		if (params.accessToken && params.refreshToken) {
			try {
				const decoded = jwtDecode(params.accessToken);
				if (decoded) {
					const { user } = decoded;
					this.onSuccessfulLoginOrRedirect(
						params.accessToken,
						params.refreshToken,
						params.clientId,
						user
					);
				} else {
					this.props.logout();
					return;
				}
			} catch (e) {
				console.error(e);
				Sentry.captureException(e);
				this.props.logout();
				return;
			}
		} else {
		}
	};

	onSuccessfulLoginOrRedirect = async (
		accessToken,
		refreshToken,
		clientId,
		user
	) => {
		this.authSvc.setAccessToken(accessToken);
		this.authSvc.setRefreshToken(refreshToken);
		this.authSvc.setUserId(user.id);
		if (clientId) {
			this.authSvc.setClientId(clientId);
		}
		if (user.account_id) {
			this.props.dispatch(setSelectedAccountId(user.account_id));
		}
		this.props.dispatch(setLoggedIn(user));
	};

	renderTermsAndPolicies = (isSignIn = false) => {
		return (
			<div className="terms-and-policies">
				<Button
					name="Terms and Conditions"
					href="https://casted.us/terms"
					link
					className="t-and-c-btn"
				>
					<p className={`t-and-c-text ${isSignIn ? 'sign-in' : ''}`}>TERMS</p>
				</Button>
				<Button
					name="Privacy Policy"
					href="https://casted.us/privacy-policy"
					link
					className="t-and-c-btn"
				>
					<p className={`t-and-c-text ${isSignIn ? 'sign-in' : ''}`}>PRIVACY</p>
				</Button>
			</div>
		);
	};

	renderSignUp = () => {
		return (
			<div className="login-container-sign-up">
				<div className="left">
					<div className="sign-up-left-content">
						<div className="sign-up-welcome-to-casted-section">
							<h1 className="sign-up-welcome-headline">
								<img src={logo} alt="Casted" className="login-casted-logo" />
							</h1>
						</div>
					</div>
				</div>
				<div className="right">
					<LoginAndSignUpBox
						onSuccessfulLogin={this.onSuccessfulLoginOrRedirect}
					/>
					{this.renderTermsAndPolicies(false)}
				</div>
			</div>
		);
	};

	render() {
		const { isAuthed, authProfile } = this.props;

		const search = window.location.search;
		const params = parseQuery(search);

		if (isAuthed && authProfile) {
			if (params && params.callbackUrl) {
				return (
					<Redirect
						to={{
							pathname: '/authorize',
							search: `?callbackUrl=${params.callbackUrl}`,
						}}
					/>
				);
			}
			return <Redirect to={{ pathname: '/' }} />;
		}

		return this.renderSignUp();
	}
}

const mapStateToProps = (state) => ({
	isAuthed: state.auth.isAuthed,
	authProfile: state.auth.profile,
});

export default withRouter(connect(mapStateToProps)(Login));
