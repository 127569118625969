import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { FaAngleDown } from 'react-icons/fa';

import CastedDropdownButton from 'components/shared/dropdownButton';
import { ENGAGEMENT_TRACKER_OPTIONS } from 'components/constants';

const EngagementTrackerOptions = (props) => {
	const { setSelectedOption = () => {}, selectedOption, onChange } = props;

	return (
		<div className="tracker-dropdown">
			<CastedDropdownButton
				title={
					<div className="tracker-value">
						{ENGAGEMENT_TRACKER_OPTIONS[selectedOption]}
						<FaAngleDown className="tracker-carrot" />
					</div>
				}
				size={'sm'}
				classType={'light-pill'}
				className={'tracker-options'}
				displayDropArrow={false}
			>
				{Object.keys(ENGAGEMENT_TRACKER_OPTIONS).map((option, i) => (
					<Dropdown.Item key={i} onClick={() => onChange(option)}>
						{ENGAGEMENT_TRACKER_OPTIONS[option]}
					</Dropdown.Item>
				))}
			</CastedDropdownButton>
		</div>
	);
};

export default EngagementTrackerOptions;
