import React from 'react'
import {Row,Col} from 'react-bootstrap'

const MockNavbar = (props) => { 
  const {
    children
  } = props
  
  return (
    <div className="mock-navbar">
      <Row>
        <Col xs={3}>{children}</Col>
        <Col xs={9} style={{textAlign: 'right'}}>
          <div className="mock-navbar--nav-item"></div>
          <div className="mock-navbar--nav-item"></div>
          <div className="mock-navbar--nav-item"></div>
        </Col>
      </Row>
    </div>
  )
}

export default MockNavbar