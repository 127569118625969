import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { METRICS_CONTEXTS } from 'components/constants';
import { PromotionWithRouter } from 'components/shared/promotions/promotion';

const CollectionPromotion = (props) => {
	return (
		<>
            <PromotionWithRouter
                context={METRICS_CONTEXTS.collection}
            />
        </>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
});

export const CollectionPromotionWithRouter = withRouter(
	connect(mapStateToProps)(CollectionPromotion)
);
