import React from 'react';
import { Card } from 'react-bootstrap';

const MetricEmptyState = (props) => {
	const { metricName = '', message = '' } = props;

	return (
		<Card className="metric-empty">
			<Card.Body>
				<div className="d-flex flex-row justify-content-center align-items-center h-100">
					<h5 className="column-title empty">
						{message || `No metrics for ${metricName} yet`}
					</h5>
				</div>
			</Card.Body>
		</Card>
	);
};

export default MetricEmptyState;
