import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client/react/hooks';

import queries from 'services/graphql/queries';

import { dispatch } from 'store/store';
import { setPlaylistContent } from 'store/reducers/data/playlist';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Spinner } from 'react-bootstrap';

import ContentItem from './contentItem';

const PlaylistList = (props) => {
	const { content, editPlaylist, playlistId, accountId } = props;

	const { data, loading } = useQuery(queries.getPlaylistContent, {
		variables: {
			accountId: parseInt(accountId, 10),
			playlistId: playlistId,
		},
		fetchPolicy: 'no-cache',
	});
	const playlistContentData = data?.getPlaylistContent || [];

	useEffect(() => {
		if (!loading) {
			if (playlistContentData.length > 0) {
				dispatch(setPlaylistContent(playlistContentData));
			}
		}
	}, [loading, playlistContentData]);

	const reorder = async (list, startIndex, endIndex) => {
		let result = Array.from(list);
		let [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.forEach((item) => {
			if (result.indexOf(item) !== item.order) {
				item.order = result.indexOf(item);
			}
		});
		dispatch(setPlaylistContent(result));
	};

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		if (!result.destination) return;

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		)
			return;

		reorder(content, result.source.index, result.destination.index);
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		flex: 1,

		// styles we need to apply on draggables
		...draggableStyle,
	});

	const onRemove = (id) => {
		let newContent = [...content];

		newContent = newContent.filter((content) => content.id !== id);
		dispatch(setPlaylistContent(newContent));
	};

	return (
		<div className="playlist-list-container">
			{loading ? (
				<div className="playlist-loading-container">
					<Spinner
						className="playlist-loading"
						animation="grow"
						variant="info"
					/>
				</div>
			) : (
				<DragDropContext onDragEnd={(result) => onDragEnd(result)}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{content.map((contentItem, index) => (
									<Draggable
										key={contentItem.id}
										draggableId={contentItem.id}
										index={index}
										isDragDisabled={!editPlaylist}
									>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps
														.style
												)}
											>
												<ContentItem
													item={contentItem}
													key={contentItem.id}
													onRemove={onRemove}
												/>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
	content: state.playlistData.playlistContent,
	editPlaylist: state.ui.playlists.editPlaylist,
});

export default connect(mapStateToProps)(PlaylistList);
