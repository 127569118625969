export const ACTIONS = {
	SHOW_DRAWER: 'CASTED/SHOW_DRAWER',
	HIDE_DRAWER: 'CASTED/HIDE_DRAWER',
	SET_DRAWER_TAB: 'CASTED/SET_DRAWER_TAB',
	RESET_DRAWER: 'CASTED/RESET_DRAWER',
	SET_IS_PLAYING: 'CASTED/SET_IS_PLAYING',
};

export const DRAWER_TABS = {
	CLIPS: 'clips',
	THEMES: 'themes',
	CLIP_SUGGESTIONS: 'clipSuggestions',
};

const initialState = {
	showDrawer: false,
	drawerTab: DRAWER_TABS.CLIPS,
	isPlaying: false,
};

export const showDrawer = () => {
	return {
		type: ACTIONS.SHOW_DRAWER,
	};
};

export const hideDrawer = () => {
	return {
		type: ACTIONS.HIDE_DRAWER,
	};
};

export const setDrawerTab = (newTab) => {
	if (!Object.values(DRAWER_TABS).includes(newTab)) {
		newTab = DRAWER_TABS.CLIPS;
	}

	return {
		type: ACTIONS.SET_DRAWER_TAB,
		payload: newTab,
	};
};

export const resetDrawer = () => {
	return {
		type: ACTIONS.RESET_DRAWER,
	};
};

 export const setIsPlaying = (isPlaying) => {
	return {
		type: ACTIONS.SET_IS_PLAYING,
		payload: isPlaying,
	}
 }

const studioReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SHOW_DRAWER:
			return {
				...state,
				showDrawer: true,
			};
		case ACTIONS.HIDE_DRAWER:
			return {
				...state,
				showDrawer: false,
			};
		case ACTIONS.SET_DRAWER_TAB:
			// If new tab === old tab, close drawer
			let showDrawer = state.showDrawer;
			if (state.drawerTab === action.payload && showDrawer) {
				showDrawer = false;
				// If new tab !== old tab, open drawer
			} else {
				showDrawer = true;
			}

			return {
				...state,
				showDrawer,
				drawerTab: action.payload,
			};
		case ACTIONS.RESET_DRAWER:
			return {
				...state,
				showDrawer: false,
				drawerTab: DRAWER_TABS.CLIPS,
			};
		case ACTIONS.SET_IS_PLAYING:
			return {
				...state,
				isPlaying: action.payload,
			}
		default:
			return state;
	}
};

export default studioReducer;
