import { createSelector } from 'reselect'

const getAccountsFromState = state => state.accounts.accounts
const getSelectedAccountIdFromState = state => state.accounts.selectedAccountId

export const getAccountsArr = createSelector(
  getAccountsFromState,
  (accounts) => {
    if (!accounts) {
      return []
    }

    return Object.values(accounts)
  }
)

export const getSelectedAccount = createSelector(
  getSelectedAccountIdFromState,
  getAccountsFromState,
  (accountId, accounts) => {
    if (!accountId || !accounts || Object.values(accounts).length < 1) {
      return {}
    }

    return accounts[accountId] || {}
  }
)
