import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Form } from 'react-bootstrap';

import API from 'services/api';
import ThumbnailUploader from 'components/shared/thumbnailUploader';
import RemoveItem from '../removeItem';
import Toast from 'services/toast';

const GuestInfo = (props) => {
	const {
		guest = {},
		onDelete = () => {},
		onEdit = () => {},
		disabled,
	} = props;

	const {
		image: _image,
		name: _name,
		title: _title,
		description: _description,
		linkText: _linkText,
		linkUrl: _linkUrl,
	} = guest;

	const [editName, setEditName] = useState(_name);
	const [editTitle, setEditTitle] = useState(_title);
	const [editImage, setEditImage] = useState(_image);
	const [editDescription, setEditDescription] = useState(_description);
	const [editLinkText, setEditLinkText] = useState(_linkText);
	const [editLinkUrl, setEditLinkUrl] = useState(_linkUrl);

	const updateGuestImage = async (imgData, fileExt) => {
		try {
			const gcsUrl = await API.uploadPublicFile({
				dataURI: imgData,
				fileExt: fileExt,
			});
			setEditImage(gcsUrl);
		} catch (e) {
			setEditImage('');
			Toast.error(
				typeof e.message  === 'string' ? e.message : 'Something went wrong!'
			);
		}
	};

	useEffect(() => {
		if (
			editName !== _name ||
			editTitle !== _title ||
			editImage !== _image ||
			editDescription !== _description ||
			editLinkText !== _linkText ||
			editLinkUrl !== _linkUrl
		)
			onEdit({
				name: editName,
				title: editTitle,
				image: editImage,
				description: editDescription,
				linkText: editLinkText,
				linkUrl: editLinkUrl,
			});
	}, [
		editName,
		editTitle,
		editImage,
		editDescription,
		editLinkText,
		editLinkUrl,
	]);

	return (
		<Card className="p-4 mb-2">
			<Container className="guest-info p-0">
				<Row className="mb-3">
					<Col xs="5" md="3">
						<ThumbnailUploader
							className="guest-image-upload"
							overlayMsg="Upload"
							thumbnailImgSrc={editImage}
							onUpload={(result, fileExt) => updateGuestImage(result, fileExt)}
							disabled={disabled}
						/>
					</Col>
					<Col xs="7" md="9">
						<Form.Label>Guest Name</Form.Label>
						<Form.Control
							className="guest-info-text mb-3"
							onChange={(e) => setEditName(e.target.value)}
							value={editName}
							disabled={disabled}
						/>

						<Form.Label>Guest Title</Form.Label>
						<Form.Control
							className="guest-info-text"
							onChange={(e) => setEditTitle(e.target.value)}
							value={editTitle}
							disabled={disabled}
						/>
					</Col>
					<RemoveItem item={guest} onDelete={onDelete} />
				</Row>
				<Row className="mb-3">
					<Col>
						<Form.Label>Guest Description</Form.Label>
						<Form.Control
							as="textarea"
							rows="4"
							className="guest-info-text"
							onChange={(e) => setEditDescription(e.target.value)}
							value={editDescription}
							disabled={disabled}
						/>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col>
						<Form.Label>Link Title</Form.Label>
						<Form.Control
							className="guest-info-text"
							onChange={(e) => setEditLinkText(e.target.value)}
							value={editLinkText}
							disabled={disabled}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>Link URL</Form.Label>
						<Form.Control
							className="guest-info-text"
							onChange={(e) => setEditLinkUrl(e.target.value)}
							value={editLinkUrl}
							disabled={disabled}
						/>
					</Col>
				</Row>
			</Container>
		</Card>
	);
};

export default GuestInfo;
