import { keyMirror } from 'utils/';

const DEFAULT_AVATAR = 'https://www.gravatar.com/avatar?f=y&d=mp';

const LoadingSections = keyMirror({
	DIGESTS: '',
	CALENDAR_SIDEBAR: '',
});

const LINKED_ACCOUNT_TYPES = {
	GOOGLE: 'google',
	WINDOWS: 'windowslive',
	MICROSOFT: 'windowslive',
};

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;

const ACTIVE_STATUS = {
	active: 'active',
	inactive: 'inactive',
	loading: 'loading',
	transcribing: 'transcribing',
};

const NEW_EPISODE_STAGES = {
	BASIC_INFO: 'basic_info',
	TRANSCRIPTION_CHOICE: 'trans_choice',
	CREDITS_REMAINING: 'credits_remaining',
	PURCHASE_CREDITS: 'purchase_credits',
	IMPORT_TRANSCRIPTION: 'import_trans',
};

const TRANSCRIPTION_OPTIONS = {
	AUTO: 'auto',
	HUMAN: 'human',
	IMPORT: 'import',
};

const ITEM_TYPES = {
	EPISODE: 'episode',
	PODCAST: 'podcast',
	PODCAST_EPISODES: 'podcast_episodes',
	ACCOUNT: 'account',
	ACCOUNT_PODCASTS: 'account_podcasts',
};

const EPISODE_UPLOAD_TYPES = {
	AUDIO: 'audio',
	VIDEO: 'video',
};

const EPISODE_PUBLIC_STATUS = {
	PUBLIC: 'published',
	PRIVATE: 'private',
	SCHEDULED: 'scheduled',
	DRAFT: 'draft',
};

const PODCAST_COLLECTION_TYPE = {
	serial: 'serial',
	non_serial: 'non-serial',
	one_off: 'one-off',
};

const getCollectionTypeName = (podcastCollectionType) => {
	switch (podcastCollectionType) {
		case PODCAST_COLLECTION_TYPE.non_serial:
			return 'collection';
		case PODCAST_COLLECTION_TYPE.one_off:
			return 'item';
		case PODCAST_COLLECTION_TYPE.serial:
		default:
			return 'show';
	}
};

const CONTENT_TYPE = {
	episode: 'episode',
	item: 'item',
	written: 'written content',
};

const getContentType = (podcastCollectionType) => {
	switch (podcastCollectionType) {
		case PODCAST_COLLECTION_TYPE.non_serial:
		case PODCAST_COLLECTION_TYPE.one_off:
			return CONTENT_TYPE.item;
		case PODCAST_COLLECTION_TYPE.serial:
		default:
			return CONTENT_TYPE.episode;
	}
};

const THEME_STATUS = {
	active: 'active',
	processing: 'processing',
	deleted: 'deleted',
};

const THEME_PUBLIC_STATUS = {
	priority: 'priority',
	other: 'other',
};

const UPLOAD_WIZARD_STEPS = {
	upload_content: 'upload_content',
	publish_to_youtube: 'publish_to_youtube',
};

const SHARE_TABS = {
	link: 'link',
	embed: 'embed',
	youtube: 'youtube',
	social: 'social',
};

const REV_PRICE = 1.25;

const SHARE_MODAL_TYPE = {
	EPISODE: 'episode',
	PLAYLIST: 'playlist',
	CLIP: 'clip',
	COLLECTION: 'collection',
};

const SHARE_STATUS = {
	ACTIVE: 'active',
	HIDDEN: 'hidden',
};

const SHARE_CONTENT_TYPE = {
	collection: 'collection',
	item: 'item',
	clip: 'clip',
	playlist: 'playlist',
};

const EPISODE_EMBED_TYPE = {
	SMALL_PLAYER: 'xxs',
	SMALL_PLAYER_TABS: 'xs',
	PLAYER: 'sm',
	PLAYER_TABS: 'md',
	FULL_PAGE: 'lg',
};

const PLAYLIST_EMBED_TYPE = {
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',
};

const ACCOUNT_INTEGRATION_TYPES = {
	WORDPRESS: 'WordPress',
};

const CONTENT_INTEGRATION_TYPES = {
	HUBSPOT: 'hubspot',
	MARKETO: 'marketo',
	PARDOT: 'pardot',
};

const EXTERNAL_OBJECT_TYPES = {
	WORDPRESS_BLOG: 'WordPress Blog',
	WORDPRESS_POST: 'WordPress Post',
};

const PLAYLIST_STATUS = {
	deleted: 'deleted',
	active: 'active',
};

const PROMOTION_POSITION = {
	INTRO: 'intro',
	OUTRO: 'outro',
	BOTH: 'both',
};

const PROMOTION_STATUS = {
	active: 'active',
	inactive: 'inactive',
	deleted: 'deleted',
	converting: 'converting',
	transcribing: 'transcribing',
	error: 'error',
	missing_file: 'missing-file',
};

const METRICS_CONTEXTS = {
	account: 'account',
	collection: 'collection',
	item: 'item',
	theme: 'theme',
	share: 'share',
};

const ENGAGEMENT_TRACKER_OPTIONS = {
	listenTime: 'Listen Time',
	likes: 'Likes',
	shares: 'Shares',
	pauses: 'Pauses',
	dropoffs: 'Dropoffs',
};

const ENGAGEMENT_TRACKER_LEVELS = {
	low: 'Low',
	medium: 'Medium',
	high: 'High',
};

const ENAGAGEMENT_LEVEL_VALUES = {
	lower: 33,
	higher: 66,
};

const ENGAGEMENT_GROUP_TIME = {
	seconds: 20,
};

const CLIP_STANDALONE_STATUS = {
	processing: 'processing',
	error: 'error',
};

const CLIP_TYPES = {
	takeaway: 'takeaway',
	promo: 'promo',
	suggested: 'suggested',
	rejected: 'rejected',
}

export {
	ACCOUNT_INTEGRATION_TYPES,
	ACTIVE_STATUS,
	CLIP_STANDALONE_STATUS,
	CLIP_TYPES,
	CONTENT_INTEGRATION_TYPES,
	CONTENT_TYPE,
	DEFAULT_AVATAR,
	ENAGAGEMENT_LEVEL_VALUES,
	ENGAGEMENT_GROUP_TIME,
	ENGAGEMENT_TRACKER_LEVELS,
	ENGAGEMENT_TRACKER_OPTIONS,
	EPISODE_EMBED_TYPE,
	EPISODE_PUBLIC_STATUS,
	EPISODE_UPLOAD_TYPES,
	EXTERNAL_OBJECT_TYPES,
	getCollectionTypeName,
	getContentType,
	HOUR,
	ITEM_TYPES,
	LINKED_ACCOUNT_TYPES,
	LoadingSections,
	METRICS_CONTEXTS,
	MINUTE,
	NEW_EPISODE_STAGES,
	PLAYLIST_EMBED_TYPE,
	PLAYLIST_STATUS,
	PODCAST_COLLECTION_TYPE,
	PROMOTION_POSITION,
	PROMOTION_STATUS,
	REV_PRICE,
	SHARE_CONTENT_TYPE,
	SHARE_MODAL_TYPE,
	SHARE_STATUS,
	SHARE_TABS,
	THEME_PUBLIC_STATUS,
	THEME_STATUS,
	TRANSCRIPTION_OPTIONS,
	UPLOAD_WIZARD_STEPS,
};
