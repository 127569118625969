export const ACTIONS = {
	SET_PROMOTION: 'CASTED/SET_PROMOTION',
	SET_PROMOTION_UPDATES: 'CASTED/SET_PROMOTION_UPDATES',
	CLEAR_PROMOTION_STORE: 'CASTED/CLEAR_PROMOTION_STORE',
	ADD_PROMOTION_UPLOAD: 'CASTED/ADD_PROMOTION_UPLOAD',
	UPDATE_PROMOTION_UPLOAD: 'CASTED/UPDATE_PROMOTION_UPLOAD',
	REMOVE_PROMOTION_UPLOAD: 'CASTED/REMOVE_PROMOTION_UPLOAD',
	REMOVE_ALL_PROMOTION_UPLOADS: 'CASTED/REMOVE_ALL_PROMOTION_UPLOADS',
};

const initialState = {
	promotion: {},
	promotionUpdates: {},
	promotionUploads: [],
};

export const setPromotion = (promotion) => {
	return {
		type: ACTIONS.SET_PROMOTION,
		promotion,
	};
};

export const setPromotionUpdates = (promotionUpdates) => {
	return {
		type: ACTIONS.SET_PROMOTION_UPDATES,
		promotionUpdates,
	};
};

export const clearPromotionStore = () => {
	return {
		type: ACTIONS.CLEAR_PROMOTION_STORE,
	};
};

export const addPromotionUpload = (promotionUpload) => {
	return {
		type: ACTIONS.ADD_PROMOTION_UPLOAD,
		promotionUpload,
	};
};

export const updatePromotionUpload = (promotionUpload) => {
	return {
		type: ACTIONS.UPDATE_PROMOTION_UPLOAD,
		promotionUpload,
	};
};

export const removePromotionUpload = (promotionUpload) => {
	return {
		type: ACTIONS.REMOVE_PROMOTION_UPLOAD,
		promotionUpload,
	};
};

export const removeAllPromotionUploads = () => {
	return {
		type: ACTIONS.REMOVE_ALL_PROMOTION_UPLOADS,
	};
};

const promotionReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SET_PROMOTION:
			return {
				...state,
				promotion: action.promotion,
			};
		case ACTIONS.SET_PROMOTION_UPDATES:
			return {
				...state,
				promotionUpdates: action.promotionUpdates,
			};
		case ACTIONS.CLEAR_PROMOTION_STORE:
			return {
				...state,
				promotion: initialState.promotion,
				promotionUpdates: initialState.promotionUpdates,
				promotionUploads: initialState.promotionUploads,
			};
		case ACTIONS.ADD_PROMOTION_UPLOAD:
			const newPromotionUploads = state.promotionUploads.concat({
				id: action.promotionUpload.id,
			});

			return {
				...state,
				promotionUploads: newPromotionUploads,
			};
		case ACTIONS.UPDATE_PROMOTION_UPLOAD:
			return {
				...state,
				promotionUploads: state.promotionUploads.map((promotionUpload) => {
					if (promotionUpload.id !== action.promotionUpload.id) {
						return promotionUpload;
					}
				}),
			};
		case ACTIONS.REMOVE_PROMOTION_UPLOAD:
			return {
				...state,
				promotionUploads: state.promotionUploads.filter(
					(promotionUpload) => promotionUpload.id !== action.promotionUpload.id
				),
			};
		case ACTIONS.REMOVE_ALL_PROMOTION_UPLOADS:
			return {
				...state,
				promotionUploads: [],
			};
		default:
			return state;
	}
};

export default promotionReducer;
