import React, { useRef, useState } from 'react';

import { FaTimes } from 'react-icons/fa';
import { Overlay, Tooltip, Modal } from 'react-bootstrap';

import Button from 'components/shared/button';

const TableConfirmationButton = (props) => {
	const { row, onRemove, action, dialog, rowId } = props;

	const [show, setShow] = useState(false);
	const target = useRef(null);

	const onConfirm = (row) => {
		onRemove(row);
		setShow(false);
	};

	return (
		<div key={`revoke-${rowId}`}>
			<div
				className="table-confirmation-toggle"
				ref={target}
				onClick={() => setShow(!show)}
			>
				<FaTimes icon="fa-solid fa-cog" className="toggle-icon" />
				{action}
			</div>

			<Overlay show={show} target={target.current} placement="top-end">
				<Tooltip className="table-confirmation-tooltip">
					<Modal.Header className="table-confirmation-header">
						<Modal.Title>
							<h4>Are you sure?</h4>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="table-confirmation-body">{dialog}</Modal.Body>
					<Modal.Footer className="table-confirmation-footer">
						{[
							{
								variant: 'danger',
								text: action.toUpperCase(),
								onClick: () => onConfirm(row),
							},
							{
								variant: 'light',
								text: 'CANCEL',
								onClick: () => setShow(false),
							},
						].map((dialogOption, i) => {
							return (
								<Button
									key={`modal-dialog-${i}`}
									variant={dialogOption.variant}
									onClick={() => {
										dialogOption.onClick();
									}}
								>
									{dialogOption.text}
								</Button>
							);
						})}
					</Modal.Footer>
				</Tooltip>
			</Overlay>
		</div>
	);
};

export default TableConfirmationButton;
