import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FaPlay, FaForward, FaBackward } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import uniqueSlug from 'unique-slug';

import { formatDuration, getBucketPrefix } from 'utils';
import { trackEvent } from 'services/vitally';

import ShareForm from 'components/shared/shareForm';
import GenerateButton from 'components/shared/shareContentModal/generateButton';

const EmbedPreview = (props) => {
	const {
		episode = {},
		selectedClip = {},
		audioEmbedUrl,
		videoEmbedUrl,
		podcastThumbnail,
		baseUrl,
		accountId,
	} = props;

	const { name: episodeName, publishedAt: date = '', thumbnail } = episode;

	const { startTime = 0, endTime = 0 } = selectedClip || {};

	const { featureCastedPlayer } = useFlags();

	const bucketEnv = getBucketPrefix();

	const barRef = useRef();

	// if video is disabled, then audio is only option
	const [audioOnly, setAudioOnly] = useState(
		episode && episode.videoLink ? false : true
	);
	const [clipWidth, setClipWidth] = useState({});
	const [shareDetails, setShareDetails] = useState({
		title: `${selectedClip.name} Embed ${new Date().toLocaleDateString()}`,
	});
	const { title, destination, description } = shareDetails;

	const [createShare] = useMutation(mutations.createShare);

	const createShareEmbed = async () => {
		const shareSlug = uniqueSlug();
		await copyEmbed(shareSlug);

		let share = await createShare({
			variables: {
				share: {
					accountId: accountId,
					clipId: selectedClip.id,
					title: title,
					destination: destination,
					description: description,
					shareType: 'embed',
					contentType: 'clip',
					shareSlug,
					shareOption: {
						audioOnly: audioOnly,
					},
				},
			},
		});
		share = share.data.createShare;

		if (share && share.id) {
			trackEvent('create-share-link', { shareId: share.id });
		}
	};

	const copyEmbed = async (slug) => {
		if (featureCastedPlayer && !audioOnly) {
			await navigator.clipboard.writeText(
				`<div><casted-player account-id="${accountId}" slug="${episode.slug}" share-slug="${slug}" clip-id="${selectedClip?.id}"></casted-player>
				<script type="module" src="https://${bucketEnv}player.casted.us/casted-player.js"></script></div>`
			);
		} else {
			let videoShareUrl = videoEmbedUrl.search('/share');
			videoShareUrl = [
				videoEmbedUrl.slice(0, videoShareUrl + 6),
				`/${slug}`,
				videoEmbedUrl.slice(videoShareUrl + 6),
			].join('');

			let shareUrl = audioOnly
				? `${audioEmbedUrl}/${slug}`
				: videoShareUrl;

			await navigator.clipboard.writeText(
				`<div><iframe allow="fullscreen"  id="casted-embed-${
					selectedClip?.slug
				}" width="100%" height="${
					audioOnly ? 230 : 588
				}px" scrolling="no" style="border: none" src="${shareUrl}"></iframe>` +
					`<script type="text/javascript">` +
					`window.addEventListener("message", function(message){` +
					`if(message.origin === "${baseUrl}" ) {` +
					`if( message.data.event) { ` +
					`if(message.data.event === "castedSizeUpdate") {` +
					`var casted_episode_player = document.getElementById('casted-embed-' + message.data.payload.slug);` +
					`if(casted_episode_player) { ` +
					`casted_episode_player.height = message.data.payload.height;` +
					`if(casted_episode_player.contentWindow) {` +
					`casted_episode_player.contentWindow.postMessage({ event: "castedStopUpdate" }, "${baseUrl}");` +
					`}}}}}}, false)</script></div>`
			);
		}
	};

	useEffect(() => {
		updateClipHandles();
	}, [audioOnly]);

	useEffect(() => {
		updateClipHandles();
	}, []);

	const updateClipHandles = () => {
		if (!barRef || !barRef.current) return {};
		const fullWidth = barRef.current.offsetWidth;
		const percentageStart = startTime / episode.duration;
		const offsetXStart = Math.round(percentageStart * fullWidth);
		const percentageEnd = endTime / episode.duration;
		const offsetXEnd = Math.round(percentageEnd * fullWidth);
		const width = offsetXEnd - offsetXStart + 8;
		setClipWidth({ width, offsetXStart });
	};

	const renderEpisodeEmbed = () => {
		return (
			<div className="embed-content-container">
				<img
					src={thumbnail || podcastThumbnail}
					alt="Clip Thumbnail"
					className={audioOnly ? 'embed-thumbnail' : 'embed-video'}
				/>

				<div className="embed-content-info">
					<p className="embed-meta">{`Episode ${
						episode.episode
					} | ${formatDuration(episode.duration, 'seconds', true)} ${
						date ? `| ${date}` : ''
					}`}</p>

					<h4 className="embed-name">{episodeName}</h4>

					<div className="clip-player">
						<div className="audio-controls left">
							<FaBackward className="control" />
							<FaPlay className="control" />
							<FaForward className="control" />
						</div>
						<div className="audio-progress">
							<div className="current-time">
								{formatDuration(startTime)}
							</div>
							<div className="bar" ref={barRef}>
								<div
									className="progress"
									style={{
										width: `${
											(startTime / episode.duration) * 100
										}%`,
									}}
								></div>
								<div
									className="clip-bound"
									style={{
										left: `${clipWidth.offsetXStart}px`,
										width: `${clipWidth.width}px`,
									}}
								></div>
							</div>
							<div className="current-time">
								{formatDuration(episode.duration)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="embed-preview">
			<div className="preview-instructions">
				<h4 className="preview-title">Embeddable Player</h4>
				<p>
					Embed a player on your website.{' '}
					{episode.videoLink && 'You can also embed with audio only.'}
				</p>
			</div>

			{episode.videoLink && (
				<Form>
					<Form.Check
						className="share--checkbox"
						label="Embed with audio-only player"
						onChange={() => setAudioOnly(!audioOnly)}
						checked={audioOnly}
					></Form.Check>
				</Form>
			)}

			{renderEpisodeEmbed()}

			<div className="share-copy share">
				<ShareForm
					share={shareDetails}
					setShareProperty={setShareDetails}
				/>
				<GenerateButton
					buttonText="Generate Embed Code"
					createShare={createShareEmbed}
					disabled={title.trim() === ''}
					tooltipText="Embed Copied"
					title={title}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedClip: state.transcript.selectedClip,
});

export default connect(mapStateToProps)(EmbedPreview);
