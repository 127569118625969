import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { Form, Modal } from 'react-bootstrap';

// Internal Libraries
import { dispatch } from 'store/store';
import {
	showAddCollectionPermissionModal,
	hideAddCollectionPermissionModal,
} from 'store/reducers/ui/modals/addCollectionPermissionModal';

import Button from 'components/shared/button';

const AddCollectionPermissionModal = (props) => {
	const {
		modals,
		defaultPermissions,
		filteredDefaultPermissions,
		setFilteredDefaultPermissions,
		onAdd,
		disabled,
	} = props;

	const [permissionsToAdd, setPermissionsToAdd] = useState([]);

	const addPermission = (collectionId) => {
		setPermissionsToAdd([collectionId, ...permissionsToAdd]);
	};

	const removePermission = (collectionId) => {
		const permissions = [...permissionsToAdd];
		setPermissionsToAdd(permissions.filter((id) => id !== collectionId));
	};

	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let result = [];

		result = defaultPermissions.filter((permission) => {
			const collectionName = permission.collectionName.toLowerCase();
			return collectionName.search(value) != -1;
		});

		setFilteredDefaultPermissions(result);
	};

	const onSubmit = () => {
		onAdd(permissionsToAdd);
		setPermissionsToAdd([]);
		dispatch(hideAddCollectionPermissionModal());
	};

	const filteredList = filteredDefaultPermissions.map((permission) => (
		<div
			className="permissions-list-item"
			key={`collection-${permission.collectionId}`}
		>
			<div className="collection-name">{permission.collectionName}</div>
			{permissionsToAdd.includes(permission.collectionId) ? (
				<Button
					className="new-permission-button remove"
					variant="flat"
					title="Remove"
					onClick={() => removePermission(permission.collectionId)}
				>
					<FaTimes />
					<div className="permission-button-text">Remove</div>
				</Button>
			) : (
				<Button
					className="new-permission-button"
					variant="flat"
					title="Add"
					onClick={() => addPermission(permission.collectionId)}
				>
					<FaPlus />
					<div className="permission-button-text">Add</div>
				</Button>
			)}
		</div>
	));

	const emptyState = (
		<div className="permission-list-empty">No matches found</div>
	);

	return (
		<>
			<Button
				className="new-permission-button"
				variant="flat"
				title="Add New Permission"
				onClick={() => dispatch(showAddCollectionPermissionModal())}
				disabled={defaultPermissions.length < 1 || disabled}
			>
				<FaPlus />
				<div className="permission-button-text">Add New Permissions</div>
			</Button>
			<Modal
				show={modals.addCollectionPermissionModal.showModal}
				size="sm"
				onHide={() => dispatch(hideAddCollectionPermissionModal())}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h4>Collection Permissions</h4>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="add-permission-body">
					<Form.Control
						type="search"
						placeholder="Search for collections"
						onChange={(event) => handleSearch(event)}
					/>
					<div className="permissions-list">
						{filteredDefaultPermissions.length > 0 ? filteredList : emptyState}
					</div>
				</Modal.Body>
				<Modal.Footer className="add-permission-footer">
					<Button
						variant="success"
						onClick={onSubmit}
						className="add-permission-button"
					>
						Add Permissions
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	modals: state.ui.modals,
});

export default connect(mapStateToProps)(AddCollectionPermissionModal);
