import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import axios from 'axios';

// Internal libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import Toast from 'services/toast';

// External Components
import { FaPlus, FaRegStickyNote } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

// Interncal Components
import Button from 'components/shared/button';
import Checkbox from 'components/shared/checkbox';
import ContentGroup from '../shared/contentGroup';
import ContentCard from '../shared/contentCard';
import { ContentPage } from '../shared/contentPage';
import { ContentRow } from '../shared/contentRow';
import Modal from 'components/shared/modal';
import Form from 'components/shared/form';

// Internal Assets
import podcat from 'assets/images/podcat-playing-v2.png';

// Redux
import { dispatch } from 'store/store';
import {
	hideImportContentModal,
	showImportContentModal,
} from 'store/reducers/ui/modals/importContentModal';
import {
	ACCOUNT_INTEGRATION_TYPES,
	CONTENT_TYPE,
	EXTERNAL_OBJECT_TYPES,
} from 'components/constants';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const ImportedContent = (props) => {
	const { selectedAccountId: accountId, permissions, modals } = props;

	const importedContentPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.IMPORTED_CONTENT)
				.rolePermission
		: {};

	const [searchString, setSearchString] = useState('');
	const [contentGroups, setContentGroups] = useState([]);
	const [availableBlogs, setAvailableBlogs] = useState([]);
	const [selectedBlogs, setSelectedBlogs] = useState([]);

	/** Queries & Mutations **/
	const {
		data: externalObjectData,
		loading: externalObjectLoading,
		error: externalObjectError,
		refetch: refetchExternalObjects,
	} = useQuery(queries.getExternalObjects, {
		variables: { accountId },
		fetchPolicy: 'no-cache',
	});

	const {
		data: externalObjectTypeData,
		loading: externalObjectTypeLoading,
		error: externalObjectTypeError,
	} = useQuery(queries.getExternalObjectTypes, {
		fetchPolicy: 'no-cache',
	});

	const {
		data: apiKeyData,
		loading: apiKeyLoading,
		error: apiKeyError,
	} = useQuery(queries.apiKeys, {
		variables: { accountId },
	});

	const [createExternalObject] = useMutation(mutations.createExternalObject, {
		variables: { accountId },
	});

	const { externalObjects = [] } = externalObjectData || {};
	const { externalObjectTypes = [] } = externalObjectTypeData || {};
	const { apiKeys = [] } = apiKeyData || {};

	const accountLevelKeys = apiKeys.filter((apiKey) => apiKey.isAccountLevel);

	useEffect(() => {
		let newContentGroups = [];

		if (externalObjects.length > 0) {
			let externalObjectTypeGroups = [];

			externalObjects
				// Filtering out child external objects for now
				.filter((externalObject) => !externalObject.parentId)
				.filter(
					(externalObject) =>
						searchString === '' ||
						externalObject.name
							.toLowerCase()
							.search(searchString.toLowerCase()) !== -1
				)
				.forEach((externalObject) => {
					let groupIndex = externalObjectTypeGroups.findIndex(
						(externalObjectTypeGroup) =>
							externalObjectTypeGroup.id ===
							externalObject.objectTypeId
					);

					const contentCard = (
						<ContentCard
							id={externalObject.id}
							name={externalObject.name}
							link={`/account/${accountId}/allContent/importedContent/${externalObject.id}`}
							thumbnail={
								externalObject.externalObjectType.objectImage
							}
						/>
					);

					if (groupIndex !== -1) {
						externalObjectTypeGroups[
							groupIndex
						].externalObjects.push(contentCard);
					} else {
						externalObjectTypeGroups.push({
							id: externalObject.externalObjectType.id,
							name: externalObject.externalObjectType
								.objectNamePlural,
							externalObjects: [contentCard],
						});
					}
				});

			externalObjectTypeGroups.forEach((externalObjectTypeGroup) => {
				newContentGroups.push(
					<ContentGroup
						contentGroupTitle={externalObjectTypeGroup.name}
						contentGroupItems={
							externalObjectTypeGroup.externalObjects
						}
					></ContentGroup>
				);
			});
		}

		setContentGroups(newContentGroups);
	}, [externalObjects, externalObjectLoading, searchString]);

	useEffect(() => {
		if (modals.importContentModal.showModal) {
			// Get list of all available integrations to import
			Promise.all(
				accountLevelKeys.map(async (accountLevelKey) => {
					const index = accountLevelKey.name.indexOf('wp-admin');
					if (index !== -1) {
						// We must use the `rest_route` parameter to accommodate sites without pretty permalinks
						// https://developer.wordpress.org/rest-api/extending-the-rest-api/routes-and-endpoints/#routes-vs-endpoints
						const blogEndpoint =
							accountLevelKey.name.substring(0, index) +
							'?rest_route=/casted/v1/blog/';

						return await axios({
							url: blogEndpoint,
							method: 'GET',
							responseType: 'json',
						})
							.catch((error) => {
								console.error(error);
							})
							.then((response) => {
								if (response?.data?.response) {
									return response.data.response;
								}
							})
							.then((data) => {
								if (
									!data ||
									externalObjects.some(
										(externalObject) =>
											externalObject.name === data.name &&
											externalObject.url === data.url
									)
								) {
									// Blog already imported
									return undefined;
								} else {
									return {
										...data,
										integrationName:
											accountLevelKey.integrationName,
									};
								}
							});
					}
				})
			).then((newAvailableBlogs) => {
				setAvailableBlogs(
					newAvailableBlogs.filter(
						(newAvailableBlog) => newAvailableBlog !== undefined
					)
				);
			});
		} else {
			setSelectedBlogs([]);
		}
	}, [modals.importContentModal.showModal]);

	const importExternalContent = async (newObjectData) => {
		switch (newObjectData.integrationName) {
			case ACCOUNT_INTEGRATION_TYPES.WORDPRESS:
				const externalObjectType = externalObjectTypes.find(
					(externalObjectType) =>
						externalObjectType.objectName ===
						EXTERNAL_OBJECT_TYPES.WORDPRESS_BLOG
				);

				if (externalObjectType) {
					try {
						const res = await createExternalObject({
							variables: {
								accountId,
								externalObject: {
									name: newObjectData.name,
									url: newObjectData.url,
									objectTypeId: externalObjectType.id,
								},
							},
						});

						const { data } = res;
						const { createExternalObject: createdExternalObject } =
							data;

						if (createdExternalObject && createdExternalObject.id) {
							trackEvent('add-external-object', {
								name: newObjectData.name,
								url: newObjectData.url,
								type: externalObjectType.objectName,
							});
						}
					} catch (e) {
						console.error(e);
						Toast.error('Unable to import content');
					}
				} else {
					// No external object type
					console.error('Unable to find a matching import type');
					Toast.error('Import failed');
				}
				break;
			default:
				// Probably throw error uncaught integration name
				console.error('Unable to find a matching integration type');
				Toast.error('Import failed');
				break;
		}
	};

	const importContentOnClick = () => {
		Promise.all(
			selectedBlogs.map(async (selectedBlog) => {
				await importExternalContent(selectedBlog);
			})
		).then(() => {
			dispatch(hideImportContentModal());
			refetchExternalObjects();
		});
	};

	const renderCreateButton = (
		<Button
			className="content-page-button"
			variant="flat"
			title="Import new content"
			onClick={() => {
				dispatch(showImportContentModal());
			}}
		>
			<FaPlus className="plus-icon" />
			<div className="content-page-button-text">Import</div>
		</Button>
	);

	const renderNoContent = (
		<div className="no-content">
			<p className="bold">
				You do not currently have any imported content.
			</p>
			<p>
				To import content, you must link your WordPress sites to your
				Casted account. Learn more about this process via the Casted
				Help Center.
			</p>
			<div>
				<Button
					variant="dark"
					href={'https://help.casted.us/en/wordpress-integration'}
				>
					Go To Casted Help Center
				</Button>
			</div>
			<img className="podcat" src={podcat} width={230} />
		</div>
	);

	const renderConnectedNoContent = (
		<div className="connected-no-content">
			<p className="bold">
				You do not currently have any imported content.
			</p>
			<div>
				<Button
					variant="success"
					title="Import new content"
					onClick={() => {
						dispatch(showImportContentModal());
					}}
				>
					<FaPlus /> Import Content
				</Button>
			</div>
			<img className="podcat" src={podcat} width={230} />
		</div>
	);

	const blogRowHandler = (blog) => {
		if (
			selectedBlogs.some((selectedBlog) => selectedBlog.url === blog.url)
		) {
			setSelectedBlogs(
				selectedBlogs.filter(
					(selectedBlog) => selectedBlog.url !== blog.url
				)
			);
		} else {
			setSelectedBlogs([...selectedBlogs, blog]);
		}
	};

	return (
		<>
			<ContentPage
				className="imported-content"
				pageTitle={'Imported Content'}
				loading={externalObjectLoading}
				renderNoContent={
					importedContentPermission.canEdit &&
					accountLevelKeys.length > 0
						? renderConnectedNoContent
						: renderNoContent
				}
				renderCreateButton={
					importedContentPermission.canEdit &&
					accountLevelKeys.length > 0 &&
					externalObjects.length > 0 ? (
						renderCreateButton
					) : (
						<div></div>
					)
				}
				onSearch={setSearchString}
				contentGroups={contentGroups}
				totalContentCount={externalObjects.length}
			/>
			<Modal
				show={modals.importContentModal.showModal}
				handleClose={() => {
					dispatch(hideImportContentModal());
				}}
				title="Import New Content"
				wideModal={true}
				className="import-content-modal"
			>
				<p>
					By selecting a WordPress Blog, you agree to import all
					available written content available on that WordPress Blog.
					This content may be excluded at any time.
				</p>
				<h4>Available WordPress Blogs</h4>
				{externalObjectTypeLoading || apiKeyLoading ? (
					<div className="available-blogs-loading">
						<Spinner animation="grow" variant="info" />
					</div>
				) : availableBlogs.length > 0 ? (
					<>
						<div className="blog-list">
							{availableBlogs.map((blog, i) => {
								return (
									<ContentRow
										key={i}
										objectTypeName={
											EXTERNAL_OBJECT_TYPES.WORDPRESS_BLOG
										}
										name={blog.name}
										image={
											externalObjectTypes.find(
												(externalObjectType) =>
													externalObjectType.objectName ===
													EXTERNAL_OBJECT_TYPES.WORDPRESS_BLOG
											).objectImage
										}
										onClick={() => blogRowHandler(blog)}
									>
										<Checkbox
											id={`blog-checkbox-${i}`}
											className="blog-checkbox"
											aria-label="Select Blog"
											onChange={() =>
												blogRowHandler(blog)
											}
											checked={selectedBlogs.some(
												(selectedBlog) =>
													selectedBlog.url ===
													blog.url
											)}
										/>
									</ContentRow>
								);
							})}
						</div>
						<Button
							className="import-content-submit"
							onClick={importContentOnClick}
						>
							Import Selected Content
						</Button>
					</>
				) : (
					<p>No available WordPress Blogs to import</p>
				)}
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
	modals: state.ui.modals,
});

export const ImportedContentWithRouter = withRouter(
	connect(mapStateToProps)(ImportedContent)
);
