export const ACTIONS = {
	SET_AUDIOGRAM_STEP: 'CASTED/CLIP_SHARE_MODAL/SET_AUDIOGRAM_STEP',
	HIDE_CLIP_MODAL: 'CASTED/CLIP_SHARE_MODAL/HIDE_CLIP_MODAL',
	SHOW_CLIP_MODAL: 'CASTED/CLIP_SHARE_MODAL/SHOW_CLIP_MODAL',
};

export const AudiogramStepsEnum = Object.freeze({
	TYPE: 1,
	OEMBED_DETAILS: 2,
	LOADING: 3,
	PREVIEW: 4,
});

export const initialState = {
	showModal: false,
};

export const hideClipShareModal = () => {
	return {
		type: ACTIONS.HIDE_CLIP_MODAL,
	};
};

export const showClipShareModal = () => {
	return {
		type: ACTIONS.SHOW_CLIP_MODAL,
	};
};

export const setAudiogramStep = (step) => {
	return {
		type: ACTIONS.SET_AUDIOGRAM_STEP,
		payload: step,
	};
};


const clipShareModalReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ACTIONS.HIDE_CLIP_MODAL:
			return {
				...state,
				showModal: false,
				audiogramStep: null,
			};
		case ACTIONS.SHOW_CLIP_MODAL:
			return {
				...state,
				audiogramStep: AudiogramStepsEnum.TYPE,
				showModal: true,
			};
		case ACTIONS.SET_AUDIOGRAM_STEP:
			return {
				...state,
				audiogramStep: payload,
			};
		default:
			return state;
	}
};
export default clipShareModalReducer;