import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { useDebouncedCallback } from 'use-debounce';
import { trackEvent } from 'services/vitally';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import {
	setSelection,
	setHasSelection,
	setIsCreatingClip,
	setShowHighlights,
	setCurrentTranscript,
} from 'store/reducers/data/transcript';

// Apollo/GraphQL
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';

// External components
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { TimedTextEditor } from '@casted-app/casted-transcript-editor';

// Internal components
import LoadingTranscript from 'components/shared/loadingSkeletons/loadingTranscript';
import ClipList from '../clips/clipList';
import Drawer from '../drawer';

// Internal constants
import { ACTIVE_STATUS, PODCAST_COLLECTION_TYPE } from 'components/constants';

// Internal utils
import { findAncestor } from 'utils';

// Declaring vars once saves on memory and garbage collection
let transcriptNodes = [],
	allNodes = [],
	i = 0,
	len = 0;

const getClosestNode = (checkTime) => {
	i = 0;
	len = transcriptNodes.length;
	if (transcriptNodes[0]) {
		if (transcriptNodes[1].dataset.start >= checkTime) {
			return transcriptNodes[0];
		}
	}
	for (i; i < len; i++) {
		if (
			transcriptNodes[i].dataset.start <= checkTime &&
			transcriptNodes[i].dataset.end >= checkTime
		) {
			return transcriptNodes[i];
		}
	}
};

const Transcript = (props) => {
	const {
		accountId,
		format = 'json',
		currentTime = 0,
		onWordDblClick = () => {},
		className = '',
		onReady = () => {},
		episodeStatus,
		showTranscript: _showTranscript,
		canEdit,
		podcast,
		episode,
		clips,
		generatedClips,
		suggestedClips,
		clipsPermission,
		takeawaysPermission,
		onPauseClip,
		onPlayClip,
		onClipHover,
		onClipHoverLeave,
		showAllClipsOnWaveform,
		hideAllClipsOnWaveform,
		updateRegionClass,
		findRegion = (region) => {
			return null;
		},
		linkedTranscript,
		linkedClip,
		setLinkedTranscript,
		setLinkedClip,
		isWaveformReady,
		linkedSeekTo,
		editorRef,
		setDisableExport,
		canSave,
		setCanSave,
		isEditing,
		/* Redux props start */
		selection,
		hasSelection,
		isCreatingClip,
		showHighlights,
		showSuggestions,
		episodesUI,
		episodeId,
		currentTranscript,
		/* Redux props end */
	} = props;

	// Local State
	const [currentDragHandle, setCurrentDragHandle] = useState(null);
	const [isReady, setIsReady] = useState(false);

	// Refs
	const transcriptContainer = useRef(null);
	const selectionHandleContainer = useRef(null);
	const startSelectHandle = useRef(null);
	const clearSelectionHandle = useRef(null);
	const endSelectHandle = useRef(null);

	const {
		loading: queryLoading,
		error,
		data,
	} = useQuery(queries.getTranscript, {
		variables: {
			accountId: parseInt(accountId, 10),
			episodeId,
			format,
		},
		fetchPolicy: 'network-only', // need to use no-cache so we capture any edits that have been made
		onCompleted: (data) => {
			dispatch(setCurrentTranscript(data.getTranscript.transcript));
		},
	});

	const [saveModifiedTranscript] = useMutation(
		mutations.saveModifiedTranscript
	);
	const [updateEpisode] = useMutation(mutations.updateEpisodeById);
	// END HOOKS

	/**
	 * Wait for Editor to mount and finish processing transcript
	 * then save all dom nodes in var that won't change on render
	 *
	 * TODO: This has been updating after a single iteration,
	 * so an interval may not be necessary, but for now ensures
	 * the transcriptNodes will always be set if there is a delay
	 */
	const editorRefCb = useCallback((editor) => {
		readyEditor(editor);
	}, [currentTranscript]);

	const readyEditor = (editor) => {
		const waitForEditorReady = setInterval(() => {
			if (
				editor &&
				editor.state &&
				editor.state.originalState &&
				editor.state.originalState.blocks
			) {
				isReady && setIsReady(false);

				clearInterval(waitForEditorReady);

				transcriptNodes = document.querySelectorAll(
					'.public-DraftStyleDefault-block .Word'
				);
				allNodes = document.querySelectorAll(
					'.public-DraftStyleDefault-block > span'
				);

				allNodes.forEach((span, i) => {
					span.dataset.entityIndex = i;
					span.classList.add('select-layer');
					if (!span.dataset.start) span.classList.add('space');
				});

				editorRef.current = editor;
				setIsReady(true);
				onReady();
			}
		}, 500);
	};

	/**
	 * Update selection if selection range changes
	 * This can happen from other components like the waveform
	 * We have to debounce this because scrubbing on the waveform
	 * can fire setSelection too often to handle node lookup
	 */
	const [handleSelectionChange] = useDebouncedCallback(
		() => {
			if (!selection.startTime || !selection.endTime) {
				if (linkedClip || linkedTranscript) {
					return;
				}
				return clearHighlight();
			}

			// if startNode and endNode are set, use those, otherwise find them
			// These can be empty if the user dropped a handle on a space instead of a word
			// or if the change came from a different component
			let startIndex = selection.startIndex;
			let endIndex = selection.endIndex;
			let startNode = null;
			let endNode = null;
			if (!startIndex) {
				startNode = getClosestNode(selection.startTime);
				startIndex = startNode
					? Number(startNode.getAttribute('data-entity-index'))
					: Number(
							startSelectHandle.current.nextSibling.getAttribute(
								'data-entity-index'
							)
					  );
			}
			if (!endIndex) {
				endNode = getClosestNode(selection.endTime);
				endIndex = endNode
					? Number(endNode.getAttribute('data-entity-index'))
					: Number(
							endSelectHandle.current.previousSibling.getAttribute(
								'data-entity-index'
							)
					  );
			}
			if (startNode || endNode)
				dispatch(
					setSelection({
						startTime: selection.startTime,
						endTime: selection.endTime,
						startIndex,
						endIndex,
					})
				);

			// Once we have the element indexes we want to select,
			// highlight those words and move the selection handles
			highlightSelection(startIndex, endIndex);
			moveHandles(startIndex, endIndex);
		},
		250,
		{ maxWait: 400 }
	);

	useEffect(() => {
		if (!queryLoading) {
			if (data && data.getTranscript) {
				setDisableExport(false);
			} else {
				setDisableExport(true);
			}
		}
	}, [queryLoading]);

	useEffect(() => {
		handleSelectionChange();
	}, [selection]);

	useEffect(() => {
		if (selection.startTime) {
			scrollTranscript();
		}
	}, [selection.startTime]);

	const clearSelection = () => {
		dispatch(
			setSelection({
				startTime: null,
				endTime: null,
				startIndex: null,
				endIndex: null,
			})
		);
		dispatch(setHasSelection(false));
		clearHighlight();
		const region = findRegion('newRegion');
		if (region) {
			region.remove();
		}
		dispatch(setIsCreatingClip(false));
	};

	const [scrollTranscript] = useDebouncedCallback(() => {
		let startTimeNode = getClosestNode(selection.startTime);
		if (startTimeNode)
			transcriptContainer.current.scrollTop = startTimeNode.offsetTop - 100;
	}, 1000);

	const addTranscriptHighlight = (clip, classNames) => {
		let startNode = getClosestNode(clip.startTime);
		let startIndex = Number(startNode.getAttribute('data-entity-index'));
		let endNode = getClosestNode(clip.endTime);
		let endIndex = Number(endNode.getAttribute('data-entity-index'));
		for (var i = startIndex; i <= endIndex; i++) {
			if (allNodes[i]) allNodes[i].classList.add(...classNames);
		}
	};

	const [handleShowHighlights] = useDebouncedCallback(
		() => {
			if (isReady) {
				if (showHighlights) {
					clips.forEach((clip) => {
						addTranscriptHighlight(clip, ['highlighted']);
					});
					showAllClipsOnWaveform(clips);
					if (showSuggestions) {
						suggestedClips.forEach((clip) => {
							addTranscriptHighlight(clip, ['highlighted', 'suggested']);
						});
						showAllClipsOnWaveform(suggestedClips);
					}
				} else {
					document
						.querySelectorAll('.select-layer.highlighted')
						.forEach((el) => {
							el.classList.remove('highlighted', 'suggested');
						});
					hideAllClipsOnWaveform();
				}
			}
		},
		250,
		{ maxWait: 400 }
	);

	useEffect(() => {
		handleShowHighlights();
	}, [showHighlights, showSuggestions]);

	/* --- Selection and Drag Handlers --- */

	/**
	 * Handle Initial User Selection
	 * This let's the user use the browser's native selection
	 * to create an initial selection, after that they can edit
	 * the selection using the drag handlers
	 */
	const onTranscriptHighlight = (e) => {
		e.persist();
		if (findAncestor(e.target, 'create-clip-card')) {
			return;
		}
		const range = window.getSelection();
		if (range.rangeCount < 1 || isEditing) {
			return;
		}

		// if there's already a selection,
		// prevent browser's native highlighting
		if (hasSelection) {
			e.preventDefault();
			range.removeAllRanges();
			setTimeout(() => {
				range.removeAllRanges();
			}, 0);
			return;
		}

		// Get the closest select-layer of start and end of the range
		const { startContainer, endContainer } = range.getRangeAt(0);
		let startElem = findAncestor(startContainer, 'select-layer');
		let endElem = findAncestor(endContainer, 'select-layer');

		// if the element at start or end is a space, get the sibling .Word
		if (startElem && startElem.classList.contains('space'))
			startElem = startElem.nextSibling;
		if (endElem && endElem.classList.contains('space'))
			endElem = endElem.previousSibling;

		// If this doesn't work, void the user's selection so they can try again
		if (!startElem || !endElem || startElem === endElem) {
			return setTimeout(() => {
				range.removeAllRanges();
			}, 0);
		}

		if (!hasSelection) {
			dispatch(setHasSelection(true));
		}
		if (!isCreatingClip) {
			dispatch(setIsCreatingClip(true));
		}
		if (showHighlights) {
			dispatch(setShowHighlights(false));
		}

		// Pull the data attributes from start and end words and update selection
		dispatch(
			setSelection({
				startTime: Number(startElem.getAttribute('data-start')),
				endTime: Number(endElem.getAttribute('data-end')),
				startIndex: Number(startElem.getAttribute('data-entity-index')),
				endIndex: Number(endElem.getAttribute('data-entity-index')),
			})
		);

		// remove browser's native selection now that we created our own
		range.removeAllRanges();
		setTimeout(() => {
			range.removeAllRanges();
		}, 0);
	};

	const handleCreateClipCardClose = () => {
		dispatch(setIsCreatingClip(false));
		if (findRegion('newRegion')) findRegion('newRegion').remove();
		hideAllClipsOnWaveform();
		clearSelection();
	};

	const renderClipList = (inTranscript) => {
		return isReady ? (
			<ClipList
				accountId={accountId}
				podcastSlug={podcast.slug}
				podcastName={podcast.name}
				podcastThumbnail={podcast.thumbnail}
				episode={episode}
				customDomain={podcast.customDomain}
				canEdit={clipsPermission.canEdit}
				canCreateTakeaway={takeawaysPermission.canEdit}
				getClosestNode={getClosestNode}
				transcriptReady={isReady}
				highlightSelection={highlightSelection}
				clips={clips}
				suggestedClips={suggestedClips}
				inTranscript={inTranscript}
				onPauseClip={onPauseClip}
				onPlayClip={onPlayClip}
				onClipHover={onClipHover}
				onClipHoverLeave={onClipHoverLeave}
				updateRegionClass={updateRegionClass}
				handleCreateClipCardClose={handleCreateClipCardClose}
				linkedClip={linkedClip}
				setLinkedClip={setLinkedClip}
				collectionType={podcast.collectionType}
				disableLinkSharing={podcast.disableLandingPages}
			/>
		) : (
			<div className="d-flex justify-content-center pt-3">
				<Spinner
					animation="border"
					role="status"
					aria-hidden="true"
					variant="dark"
				/>
			</div>
		);
	};

	/*
	 * Set "selected" class between start and end indexes
	 */
	const highlightSelection = (
		startIndex,
		endIndex,
		linkedSelection = false,
		suggested = false
	) => {
		// reset current selection
		document.querySelectorAll('.select-layer.selected').forEach((span) => {
			span.classList.remove('selected');
		});
		document.querySelectorAll('.select-layer.suggested').forEach((span) => {
			span.classList.remove('suggested');
		});
		document
			.querySelectorAll('.select-layer.linked-selected')
			.forEach((span) => {
				span.classList.remove('linked-selected');
			});

		// Add css selectors to words between user selection
		for (var i = startIndex; i <= endIndex; i++) {
			if (allNodes[i]) {
				if (suggested) {
					allNodes[i].classList.add('suggested');
				} else if (linkedSelection) {
					allNodes[i].classList.add('linked-selected');
				} else {
					allNodes[i].classList.add('selected');
				}
			}
		}
	};

	const moveHandles = (startIndex, endIndex) => {
		const startElement = document.querySelector(
			`[data-entity-index="${startIndex}"]`
		);
		if (startElement) {
			startElement.insertAdjacentElement(
				'beforebegin',
				startSelectHandle.current
			);
		}

		const endElement = document.querySelector(
			`[data-entity-index="${endIndex}"]`
		);
		if (endElement) {
			endElement.insertAdjacentElement('afterend', endSelectHandle.current);
		}

		if (startSelectHandle && startSelectHandle.current && isCreatingClip) {
			startSelectHandle.current.insertAdjacentElement(
				'beforebegin',
				clearSelectionHandle.current
			);
		}
	};

	const clearHighlight = () => {
		document.querySelectorAll('.select-layer.selected').forEach((span) => {
			span.classList.remove('selected');
		});
		document.querySelectorAll('.select-layer.suggested').forEach((span) => {
			span.classList.remove('suggested');
		});
		document
			.querySelectorAll('.select-layer.linked-selected')
			.forEach((span) => {
				span.classList.remove('linked-selected');
			});
		document.querySelectorAll('.select-layer.highlighted').forEach((span) => {
			span.classList.remove('faded');
		});
		if (selectionHandleContainer.current) {
			selectionHandleContainer.current.appendChild(startSelectHandle.current);
			selectionHandleContainer.current.appendChild(endSelectHandle.current);
			selectionHandleContainer.current.appendChild(
				clearSelectionHandle.current
			);
		}
	};

	/**
	 * onDragStart
	 * tell the browser we're dragging
	 * Sets state for which handle user is dragging
	 */
	const onDragStart = (e) => {
		e.dataTransfer.dropEffect = 'move';
		setCurrentDragHandle(e.target.getAttribute('data-handle'));
	};

	/**
	 * onDragEnd
	 * After user releases drop handle
	 */
	const onDragEnd = (e) => {
		// if user drops in invalid location reset highlight to existing state
		if (e.dataTransfer.dropEffect === 'none') {
			highlightSelection(selection.startIndex, selection.endIndex);
		}
	};

	/**
	 * onDragEnter
	 * when a user hovers over a valid span where they can drop a handle
	 * set in a debounce so we don't dump a unneccsary number of calls into the
	 * event queue when moving the drag handle quickly across many words
	 */
	const [onDragEnter] = useDebouncedCallback(
		(e) => {
			const target = e.target.closest('.select-layer');
			if (!target) return;
			const targetIndex = Number(target.getAttribute('data-entity-index'));

			// check which handle is dragging and that it's not been dragged past its sibling
			if (currentDragHandle === 'start' && targetIndex < selection.endIndex) {
				// preventDefault tells the browser the drag can continue
				e.preventDefault();
				e.dataTransfer.dropEffect = 'move';
				highlightSelection(targetIndex, selection.endIndex);
			} else if (
				currentDragHandle === 'end' &&
				targetIndex > selection.startIndex
			) {
				e.preventDefault();
				e.dataTransfer.dropEffect = 'move';
				highlightSelection(selection.startIndex, targetIndex);
			}
		},
		5,
		{ maxWait: 400, leading: true }
	);

	/**
	 * onDrop
	 * handles when the user drops the marker
	 * Does the same stuff as dragEnter, but also updates selection state
	 * so that other components will update
	 */
	const onDrop = (e) => {
		e.persist();
		let target = e.target.closest('.select-layer');

		if (
			target &&
			currentDragHandle === 'start' &&
			Number(target.getAttribute('data-entity-index')) < selection.endIndex
		) {
			e.preventDefault();
			// if dropped on a space, update target to closest .Word
			if (target.classList.contains('space')) target = target.nextSibling;
			dispatch(
				setSelection({
					startTime: Number(target.getAttribute('data-start')),
					endTime: selection.endTime,
					startIndex: Number(target.getAttribute('data-entity-index')),
					endIndex: selection.endIndex,
				})
			);
		} else if (
			target &&
			currentDragHandle === 'end' &&
			Number(target.getAttribute('data-entity-index')) > selection.startIndex
		) {
			e.preventDefault();
			if (target.classList.contains('space')) target = target.previousSibling;
			dispatch(
				setSelection({
					startTime: selection.startTime,
					endTime: Number(target.getAttribute('data-end')),
					startIndex: selection.startIndex,
					endIndex: Number(target.getAttribute('data-entity-index')),
				})
			);
		} else {
			// if user drops in invalid location reset highlight to existing state
			e.dataTransfer.dropEffect = 'none';
			highlightSelection(selection.startIndex, selection.endIndex);
		}

		setCurrentDragHandle(null);
	};

	const onSaveChanges = async (updated) => {
		if (canSave) {
			setIsReady(false);
			saveModifiedTranscript({
				variables: {
					accountId: parseInt(accountId, 10),
					episodeId,
					transcript: updated,
				},
			}).then(() => {
				trackEvent('edit-transcript', {
					itemName: episode.name,
					collectionName: podcast.name,
				});
				setCanSave(false);
				readyEditor(editorRef.current);
			});
		}
	};

	// Set play head, highlight selection, and scroll to linked item
	useEffect(() => {
		if (isReady) {
			let linkedStartNode = null;
			let linkedEndNode = null;
			let linkedStartTime = null;

			// Setup nodes and start time for corresponding linkted item
			if (linkedClip) {
				linkedStartNode = getClosestNode(parseFloat(linkedClip.startTime));
				linkedEndNode = getClosestNode(parseFloat(linkedClip.endTime));
				linkedStartTime = linkedClip.startTime;
			} else if (linkedTranscript) {
				linkedStartNode = getClosestNode(
					parseFloat(linkedTranscript.startTime)
				);
				linkedEndNode = getClosestNode(parseFloat(linkedTranscript.endTime));
				linkedStartTime = linkedTranscript.startTime;
			}

			if (linkedStartNode && linkedEndNode) {
				// Get position in transcript
				const linkedStartIndex = parseInt(
					linkedStartNode.getAttribute('data-entity-index'),
					10
				);
				const linkedEndIndex = parseInt(
					linkedEndNode.getAttribute('data-entity-index'),
					10
				);

				if (
					Number.isInteger(linkedStartIndex) &&
					Number.isInteger(linkedEndIndex)
				) {
					// Scroll to position in transcript and highlight
					linkedStartNode.scrollIntoView();
					highlightSelection(linkedStartIndex, linkedEndIndex, true);

					// When waveform is ready, seek to position in audio file
					if (isWaveformReady) {
						linkedSeekTo(linkedStartTime);

						// Clear highlight for linkedTranscript after 5 seconds
						if (linkedTranscript) {
							setTimeout(() => {
								setLinkedTranscript(null);
								clearHighlight();
							}, 5000);
						}
					}
				}
			}
		}
	}, [isReady, linkedClip, linkedTranscript, isWaveformReady]);

	/* --- Rendering --- */
	if (queryLoading) return <LoadingTranscript />;
	if (error || !data || !data.getTranscript) {
		if (error) {
			Sentry.captureException(error);
		}
		return (
			<div className="transcript-container">Transcript does not exist.</div>
		);
	}
	if (episodeStatus === ACTIVE_STATUS.transcribing) {
		return (
			<div className="transcript-container">Transcription in progress.</div>
		);
	}

	return (
		<>
			<Container
				fluid
				className={`studio--transcript ${isEditing ? 'is-editing' : ''}`}
			>
				{canSave ? (
					<Spinner
						animation="grow"
						role="status"
						aria-hidden="true"
						variant="light"
					/>
				) : (
					<Row>
						<Col
							className={`transcript-actions ${canEdit ? '' : 'hidden'} ${
								!episodesUI.studio.showDrawer ? 'sidebar-hidden' : ''
							}`}
							xs={episodesUI.studio.showDrawer ? 12 : 9}
						></Col>
						<Col
							xs={3}
							className={`clip-list-column in-transcript ${
								episodesUI.studio.showDrawer ? 'hide' : ''
							}`}
						></Col>
						<Col
							xs={3}
							className={`ml-3 clip-list-column in-sidebar ${
								episodesUI.studio.showDrawer ? 'visible' : ''
							}`}
						></Col>
					</Row>
				)}
				<Row
					ref={transcriptContainer}
					className={`transcript-container has-speakers ${
						hasSelection ? 'prevent-select' : ''
					} ${className} `}
				>
					<Col
						className={!episodesUI.studio.showDrawer ? 'sidebar-hidden' : ''}
						xs={episodesUI.studio.showDrawer ? 12 : 9}
						onSelect={onTranscriptHighlight}
						onDragEnter={(e) => {
							e.persist();
							onDragEnter(e);
						}}
						onDrop={(e) => onDrop(e)}
						onDragOver={(e) => e.preventDefault()}
					>
						<TimedTextEditor
							ref={editorRefCb}
							transcriptData={data.getTranscript.transcript}
							sttJsonType={'draftjs'}
							currentTime={currentTime}
							isEditable={isEditing}
							handleEditingComplete={onSaveChanges}
							isAutoUpdateOn={false}
							showSpeakers={true}
							isScrollIntoViewOn={false}
							showTimecodes={false}
							onWordClick={!isEditing ? onWordDblClick : () => {}}
							handleDrop={() => 'handled'}
						/>
						<div ref={selectionHandleContainer} style={{ display: 'none' }}>
							<span
								ref={startSelectHandle}
								draggable="true"
								onDragStart={(e) => {
									onDragStart(e);
								}}
								onDragEnd={(e) => {
									onDragEnd(e);
								}}
								className="select-handle select-handle--start"
								data-handle="start"
							></span>
							<span
								ref={endSelectHandle}
								draggable="true"
								onDragStart={(e) => {
									onDragStart(e);
								}}
								onDragEnd={(e) => {
									onDragEnd(e);
								}}
								className="select-handle select-handle--end"
								data-handle="end"
							></span>
							<span
								ref={clearSelectionHandle}
								onClick={clearSelection}
								className="select-clear"
							></span>
						</div>
					</Col>
					<Col
						xs={3}
						className={`clip-list-column in-transcript ${
							episodesUI.studio.showDrawer ? 'hide' : ''
						}`}
					>
						{renderClipList(true)}
					</Col>
					<Col
						xs={3}
						className={`ml-3 clip-list-column in-sidebar clip-container ${
							episodesUI.studio.showDrawer ? 'visible' : ''
						}`}
					>
						<Drawer
							accountId={accountId}
							podcast={podcast}
							episode={episode}
							customDomain={podcast.customDomain}
							canEdit={clipsPermission.canEdit}
							canCreateTakeaway={takeawaysPermission.canEdit}
							getClosestNode={getClosestNode}
							transcriptReady={isReady}
							highlightSelection={highlightSelection}
							clips={clips}
							generatedClips={generatedClips}
							inTranscript={false}
							onPauseClip={onPauseClip}
							onPlayClip={onPlayClip}
							onClipHover={onClipHover}
							onClipHoverLeave={onClipHoverLeave}
							updateRegionClass={updateRegionClass}
							handleCreateClipCardClose={handleCreateClipCardClose}
							linkedClip={linkedClip}
							setLinkedClip={setLinkedClip}
							collectionType={podcast.collectionType}
							clipsPermission={clipsPermission}
							takeawaysPermission={takeawaysPermission}
							isReady={isReady}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};

/**
 * Memoize Component
 * Prevent unintentional rerenders to improve performance
 * Only rerender if selection bounds have change, currentTime has changed,
 * linkedClip has changed, linkedTranscript has changed, or isWaveformReady
 * has changed.
 */
const areEqual = (prevProps, nextProps) => {
	// If we haven't captured and saved the transcript data yet, we need a render
	if (!transcriptNodes.length) return false;
	// If parent declares all components ready, trigger a render

	const { start: prevStart = 0, end: prevEnd = 0 } =
		prevProps.currentSelection || {};
	const { start: nextStart = 0, end: nextEnd = 0 } =
		nextProps.currentSelection || {};

	if (
		prevStart !== nextStart ||
		prevEnd !== nextEnd ||
		prevProps.currentTime !== nextProps.currentTime ||
		prevProps.clips !== nextProps.clips ||
		prevProps.linkedClip !== nextProps.linkedClip ||
		prevProps.linkedTranscript !== nextProps.linkedTranscript ||
		prevProps.selection !== nextProps.selection ||
		prevProps.isWaveformReady !== nextProps.isWaveformReady ||
		prevProps.hasSelection !== nextProps.hasSelection ||
		prevProps.isCreatingClip !== nextProps.isCreatingClip ||
		prevProps.showHighlights !== nextProps.showHighlights ||
		prevProps.episodesUI.studio.showDrawer !==
			nextProps.episodesUI.studio.showDrawer ||
		prevProps.episodesUI.studio.drawerTab !==
			nextProps.episodesUI.studio.drawerTab ||
		prevProps.isEditing !== nextProps.isEditing ||
		prevProps.canSave !== nextProps.canSave
	) {
		// something has changed, rerender
		return false;
	}

	// props are the same, dont rerender
	return true;
};

const mapStateToProps = (state) => ({
	selection: state.transcript.selection,
	hasSelection: state.transcript.hasSelection,
	isCreatingClip: state.transcript.isCreatingClip,
	showHighlights: state.transcript.showHighlights,
	showSuggestions: state.transcript.showSuggestions,
	episodesUI: state.ui.episodes,
	episodeId: state.episode.episodeId,
	currentTranscript: state.transcript.currentTranscript,
});

export default connect(mapStateToProps)(React.memo(Transcript, areEqual));
