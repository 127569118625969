import React from 'react';

import EngagementTrackerSegment from './engagementTrackerSegment';
import {
	ENGAGEMENT_TRACKER_OPTIONS,
	ENGAGEMENT_TRACKER_LEVELS,
} from 'components/constants';
import ClipRange from './clipRange';

const EngagementTrackerGraph = (props) => {
	const {
		data,
		duration,
		highValues,
		mediumValues,
		lowValues,
		selectedOption,
		selectedClip,
		isClip,
	} = props;

	const getLevel = (value) => {
		if (highValues.includes(value)) {
			return ENGAGEMENT_TRACKER_LEVELS.high;
		} else if (mediumValues.includes(value)) {
			return ENGAGEMENT_TRACKER_LEVELS.medium;
		} else if (lowValues.includes(value)) {
			return ENGAGEMENT_TRACKER_LEVELS.low;
		}
	};

	const getSegmentWidth = (data, keyValues, index, level) => {
		// if it is the last item in array skip logic

		if (index < data.length - 1) {
			// get next object and get level (high medium or low)
			const nextSegment = data[index + 1];
			const nextKey = Object.keys(nextSegment)[0];
			const nextSegmentLevel = getLevel(nextKey);

			// if it is equal to the current object level then we
			// need to combine the data to display in one segment
			if (
				(level === nextSegmentLevel && !isClip) ||
				(isClip &&
					level === nextSegmentLevel &&
					index > selectedClip.startTime &&
					index < selectedClip.endTime)
			) {
				// keys will give us the range of engagement values
				const newKeyValues = [...keyValues, nextKey];
				return getSegmentWidth(data, newKeyValues, index + 1, nextSegmentLevel);
			} else {
				return { keyValues, index };
			}
		} else {
			return { keyValues, index };
		}
	};

	const getEngagementRange = (values) => {
		if (values.length > 1) {
			let sortedArray = values.sort((a, b) => a - b);
			sortedArray = [sortedArray[0], sortedArray[sortedArray.length - 1]];

			return (
				<span className="engagement-range">
					{sortedArray[0]}-{sortedArray[1]}
				</span>
			);
		} else {
			return values[0];
		}
	};

	const getSegments = (data) => {
		if (data.length > 0) {
			let previousPercentage = 0;
			let currentIndex = -1;

			return (
				<div className="graph-container">
					{data.map((segment, i) => {
						if (i > currentIndex) {
							let keyValues = Object.keys(segment);
							const level = getLevel(keyValues[0]);
							const firstValues = Object.values(segment)[0];

							const combinedSegment = getSegmentWidth(
								data,
								keyValues,
								i,
								level
							);

							// for listen times if objects are combined we need the range of listens
							let engagementValues = combinedSegment['keyValues'];
							engagementValues = getEngagementRange(engagementValues);

							// if objects are combined due to being  the same level (high, medium or low)
							// we need to keep track of the objects that need to be skipped in the iteration
							//  so we are setting the currentIndex to the last index of the combined segments
							currentIndex = combinedSegment['index'];

							// only need the last segment to know the width of the segment
							const lastSegment = data[currentIndex];
							keyValues = Object.keys(lastSegment)[0];
							const lastValues = Object.values(lastSegment)[0];
							const width = lastValues[lastValues.length - 1];
							const percentWidth = (width / duration) * 100;
							let divWidth = percentWidth - previousPercentage;
							previousPercentage = percentWidth;

							// if widths are smaller than .05 they are really hard to see on the  graph
							if (divWidth < 0.1) {
								divWidth = 0.1;
							}

							return (
								<>
									<EngagementTrackerSegment
										key={i}
										engagementValue={engagementValues}
										engagementType={
											ENGAGEMENT_TRACKER_OPTIONS[selectedOption] ===
											ENGAGEMENT_TRACKER_OPTIONS.listenTime
												? 'Listens'
												: ENGAGEMENT_TRACKER_OPTIONS[selectedOption]
										}
										width={divWidth}
										level={level}
										values={[firstValues[0], width]}
										index={i}
										isClip={isClip}
										selectedClip={selectedClip}
									/>
									{isClip && (
										<ClipRange
											duration={duration}
											startTime={selectedClip.startTime}
											endTime={selectedClip.endTime}
										/>
									)}
								</>
							);
						}
					})}
				</div>
			);
		} else if (isClip) {
			return (
				<div className="empty-clip">
					<div
						className="empty-clip-border"
						style={{
							left: `${(selectedClip.startTime / duration) * 100}%`,
							borderRight: 'none',
						}}
					></div>
					<div
						className="empty-clip-border"
						style={{
							left: `${(selectedClip.endTime / duration) * 100}%`,
							borderLeft: 'none',
						}}
					></div>
				</div>
			);
		} else {
			return <div className="empty-graph"></div>;
		}
	};

	return (
		<div className="tracker-graph">
			<div>{getSegments(data)}</div>
		</div>
	);
};

export default EngagementTrackerGraph;
