import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { FaThumbsUp } from 'react-icons/fa';

const MetricDownloadModal = (props) => {
	const {  isOpen, title, onClose = () => {} } = props;

	return (
		<Modal
				show={isOpen}
				onHide={onClose}
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="syncing-episodes inner-modal">
					<div className="success-icon">
						{' '}
						<FaThumbsUp />
					</div>
					<h3 className="text-capitalize">{title}</h3>
					<p>
						A link to download the metrics will be emailed to you. Please allow
						a few minutes for the file to be ready.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="success" onClick={onClose}>
						Got it
					</Button>
				</Modal.Footer>
			</Modal>
	);
};

export default MetricDownloadModal;