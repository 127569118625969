import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import moment from 'moment';

import BaseTable from 'components/shared/baseTable';
import MetricEmptyState from 'components/shared/metricEmptyState';

const TopCompanies = (props) => {
	const { variables } = props;

	const { data, loading, error } = useQuery(queries.getTopCompanies, {
		variables,
		fetchPolicy: 'cache-and-network',
	});
	const { getTopCompanies: companies = [] } = data || {};

	const columns = [
		{
			name: 'Company',
			selector: (row) => row.name,
		},
		{
			name: 'Listens',
			selector: (row) => row.data[0],
		},
		{
			name: 'Unique Contacts',
			selector: (row) => row.data[1],
		},
		{
			name: 'Impressions',
			selector: (row) => row.data[2],
		},
		{
			name: 'Last Visit',
			selector: (row) => row.data[3],
		},
	];

	const values = companies.map((company) => {
		return {
			id: company._id,
			name: company.integration_company,
			data: [
				company.total_listens,
				company.unique_contacts,
				company.total_impressions,
				company.last_listen_date
					? moment(company.last_listen_date).format('M-D-YY')
					: '',
			],
		};
	});

	return (
		<BaseTable
			values={values || []}
			columns={columns}
			loading={loading}
			noDataComponent={<MetricEmptyState metricName="Top Companies" />}
			clickable={false}
		/>
	);
};

export default TopCompanies;
