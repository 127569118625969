import React, { useState } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';

import ThumbnailUploader from 'components/shared/thumbnailUploader';
import QuillEditor from 'components/shared/quillEditor';
import { Modal, Form, ButtonToolbar } from 'react-bootstrap';
import { FaRegTimesCircle } from 'react-icons/fa';

import Button from 'components/shared/button';

const FeatureEpisode = (props) => {
	const {
		podcastId,
		accountId,
		episodeId,
		featured: _featured = {},
		onHide = () => {},
		onConfirm = () => {},
	} = props;

	const [headerImage, setHeaderImage] = useState(_featured.headerImage);
	const [title, setTitle] = useState(_featured.title);
	const [description, setDescription] = useState(_featured.description);

	const [uploadFile, { loading: uploadLoading }] = useMutation(
		mutations.uploadPublicFile
	);
	const [updatePodcast, { loading, error }] = useMutation(
		mutations.updatePodcastById
	);

	const saveFeatured = async () => {
		await updatePodcast({
			variables: {
				podcastId,
				podcast: {
					accountId,
					featuredEpisode: {
						episodeId,
						title,
						description,
						headerImage,
					},
				},
			},
		});
		onConfirm();
	};

	const handleDescription = (content, delta, source, editor) => {
		if (source === 'api') return;

		if (
			content !== _featured.description &&
			(content || _featured.description)
		) {
			setDescription(content);
		}
	};

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>
					<h4>Feature Episode on Home Page</h4>
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className="feature-episode-modal p-5">
				<Form>
					<Form.Group className="feature-episode--header-image">
						<Form.Label>Header Image</Form.Label>
						<p>
							This image is featured at the top of the show's home
							page. It should be as close to{' '}
							<strong>1440px x 562px</strong> as possible.
						</p>
						<div style={{ position: 'relative' }}>
							<ThumbnailUploader
								overlayMsg="Upload"
								loading={uploadLoading}
								thumbnailImgSrc={headerImage}
								onUpload={async (file, fileExt) => {
									const { data } = await uploadFile({
										variables: {
											dataURI: file,
											fileExt: fileExt,
										},
									});
									const { uploadPublicFile = '' } =
										data || {};
									setHeaderImage(uploadPublicFile);
								}}
							/>
							<div
								className={`clear-container ${
									headerImage ? 'show' : ''
								}`}
							>
								<FaRegTimesCircle
									onClick={() => setHeaderImage('')}
									className="featured-image-clear"
									size={18}
								/>
							</div>
						</div>
					</Form.Group>

					<Form.Group>
						<Form.Label>Feature Title</Form.Label>
						<Form.Control
							type="text"
							value={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
						></Form.Control>
					</Form.Group>

					<Form.Group>
						<Form.Label>Feature Description</Form.Label>
						<QuillEditor
							defaultValue={description}
							onChange={handleDescription}
							style={{ height: '200px' }}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<ButtonToolbar>
					<Button variant="cancel" onClick={onHide} className="mr-2">
						Cancel
					</Button>
					<Button
						variant="success"
						onClick={saveFeatured}
						disabled={loading || uploadLoading}
					>
						Save as Featured
					</Button>
				</ButtonToolbar>
			</Modal.Footer>
		</>
	);
};

export default FeatureEpisode;
