import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

import aiIcon from 'assets/icons/ai-icon.svg';
import SuggestedCard from './suggestedCard';

const ClipSuggestionsDrawer = (props) => {
	const {
		clips,
		canCreateTakeaway,
		collectionType,
		getClosestNode,
		noAiClips,
		transcriptReady,
	} = props;

	const [selectedSuggestedClip, setSelectedSuggestedClip] = useState(null);

	useEffect(() => {
		if (clips.length > 0) {
			if (selectedSuggestedClip == null) {
				setSelectedSuggestedClip(clips[0]);
			} else {
				onNext();
			}
		}
	}, [clips]);

	const onNext = () => {
		if (selectedSuggestedClip) {
			const index = clips.findIndex(
				(clip) => clip.id === selectedSuggestedClip.id
			);

			if (index + 1 === clips.length) {
				setSelectedSuggestedClip({ ...clips[0] });
			} else {
				setSelectedSuggestedClip(clips[index + 1]);
			}
		}
	};

	const onPrevious = () => {
		const index = clips.findIndex(
			(clip) => clip.id === selectedSuggestedClip.id
		);

		if (index === 0) {
			setSelectedSuggestedClip(clips[clips.length - 1]);
		} else {
			setSelectedSuggestedClip(clips[index - 1]);
		}
	};

	if (selectedSuggestedClip === null && clips.length > 0) {
		return (
			<div className="loading-clip-suggestions">
				<Spinner animation="border" variant="info" />
			</div>
		);
	}

	const renderView = () => {
		if (noAiClips && clips.length === 0) {
			return (
				<div className="empty-state">
					<b>NO RECOMMENDATIONS</b>
					<p>Click “Generate Recommendations” to generate some</p>
				</div>
			);
		} else if (clips.length === 0) {
			return (
				<div className="empty-state">
					<div className="title">NO MORE RECOMMENDATIONS</div>
					<div>All recommended clips were accepted</div>
				</div>
			);
		} else {
			return (
				<SuggestedCard
					clip={selectedSuggestedClip}
					canCreateTakeaway={canCreateTakeaway}
					getClosestNode={getClosestNode}
					collectionType={collectionType}
					transcriptReady={transcriptReady}
				/>
			);
		}
	};

	return (
		<div className="clip-suggestions-drawer">
			<div className="suggested-clips-header-container">
				<img src={aiIcon} className="icon" />
				<div className="suggested-clips-header">
					<div>Recommended Clips</div>
					<div className="header-controls">
						{clips.length > 1 && (
							<FaChevronLeft className="chevron" onClick={onPrevious} />
						)}
						{clips.findIndex((clip) => clip.id === selectedSuggestedClip.id) +
							1}{' '}
						/ {clips.length}
						{clips.length > 1 && (
							<FaChevronRight className="chevron" onClick={onNext} />
						)}
					</div>
				</div>
			</div>
			<div>{renderView()}</div>
		</div>
	);
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ClipSuggestionsDrawer);
