import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import uniqueSlug from 'unique-slug';

import { dispatch } from 'store/store';
import {
	setAudiogramStep,
	AudiogramStepsEnum,
} from 'store/reducers/ui/modals/clipShareModal';
import {
	useQuery,
	useMutation,
	useLazyQuery,
} from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatDuration } from 'utils';

import { ProgressBar, Spinner } from 'react-bootstrap';

import AudiogramSelection from 'components/shared/audiogram/audiogramSelection';
import SocialEmbedDetails from 'components/shared/audiogram/socialEmbedDetails';
import PreviewAudiogram from 'components/shared/audiogram/previewAudiogram';
import SelectedTemplate from 'components/shared/audiogram/selectedTemplate';
import CopyToClipboardButton from 'components/shared/copyToClipboardButton';
import { SHARE_CONTENT_TYPE } from 'components/constants';

const CreateAudiogram = (props) => {
	const {
		accountId = '',
		podcastName = '',
		episode = {},
		podcastThumbnail,
		selectedClip,
		modals,
		baseUrl,
	} = props;

	const copyRef = useRef(null);

	const { id: episodeId, thumbnail, name: episodeName } = episode;

	const { featureOembeds } = useFlags();

	const { id: clipId = null, name: clipName } = selectedClip || {};

	/** State **/
	const [embedType, setEmbedType] = useState('standard');
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [progress, setProgress] = useState(0);
	const [socialDetails, setSocialDetails] = useState({
		title: `${clipName} Audiogram ${new Date().toLocaleDateString()}`,
	});
	const [shareLink, setShareLink] = useState('');

	/** API **/
	// Get Clip
	const { data, loading, error, startPolling, stopPolling, networkStatus } =
		useQuery(queries.clipById, {
			variables: { clipId },
			skip: !clipId,
			notifyOnNetworkStatusChange: true,
		});
	const { clip = {} } = data || {};
	const { storageLink: audiogramSource } = clip || {};

	// Get Templates
	const { data: templatesData, loading: templatesLoading } = useQuery(
		queries.getAudiogramTemplates,
		{
			variables: {
				accountId,
				includeVideo:
					episode.videoLink && episode.videoLink !== 'processing'
						? true
						: false,
			},
		}
	);
	const { audiogramTemplates: templates = [] } = templatesData || {};

	// Get Public File
	const [getSignedUrl, { data: audiogramFileData }] = useLazyQuery(
		queries.getAudiogramSignedUrl
	);
	const { getAudiogramSignedUrl: audiogramSignedUrl } = audiogramFileData || {};

	// Create Audiogram
	const [createClipAudiogram] = useMutation(mutations.createClipAudiogram);
	const [createShare] = useMutation(mutations.createShare);

	// Update Clip
	const [updateClip] = useMutation(mutations.updateClipById);

	/** Methods **/
	// Create new audiogram
	const createAudiogram = async () => {
		createClipAudiogram({
			variables: {
				options: {
					clipId,
					accountId,
					templateId: selectedTemplate,
					captionHeader: podcastName,
					captionBody: episodeName,
					thumbnailUrl: thumbnail || podcastThumbnail,
				},
			},
		});
		await updateClip({
			variables: {
				clipId,
				clip: { storageLink: 'loading', episodeId: episodeId },
			},
		});
		trackEvent('create-audiogram', {
			clipName,
			itemName: episode.name,
			collectionName: podcastName.name,
		});
		setProgress(0);
		startPolling(1000);
	};

	const createShareAudiogram = async () => {
		const shareSlug = uniqueSlug();

		let share = await createShare({
			variables: {
				share: {
					accountId,
					title: socialDetails.title,
					destination: socialDetails.destination,
					description: socialDetails.description,
					itemId: episode.id,
					clipId,
					shareType: 'audiogram',
					shareOption: {
						embedType: 'regularPlayer',
						audioOnly: false,
					},
					contentType: SHARE_CONTENT_TYPE.clip,
					shareSlug,
				},
			},
		});
		share = share.data.createShare;

		if (share && share.id) {
			trackEvent('create-share-audiogram', share);
			setShareLink(
				`${baseUrl}/api/v2/oembed?url=${baseUrl}/share/${share.shareSlug}&format=json`
			);
		}
		createAudiogram();
	};

	/** Watchers **/
	// watch for audiogram to finish
	useEffect(() => {
		if (audiogramSource !== 'loading' && audiogramSource != null) {
			stopPolling();
			getSignedUrl({
				variables: { filename: audiogramSource },
				fetchPolicy: 'cache-and-network',
			});
			dispatch(setAudiogramStep(AudiogramStepsEnum.PREVIEW));
		}
	}, [audiogramSource]);

	// watch for polls to update progress bar
	useEffect(() => {
		if (networkStatus === 6) {
			setProgress(progress + 1);
		}
	}, [networkStatus]);

	// on component mount
	useEffect(() => {
		if (audiogramSource === 'loading') {
			startPolling(1000);
		}
		// before component unmount
		return () => {
			stopPolling();
		};
	}, []);

	// Render Loading States
	if (audiogramSource === 'loading') {
		const { startTime, endTime } = selectedClip || {};
		const clipLength = endTime - startTime;

		return (
			<div className="audiogram-loading audiogram-creating-audiogram">
				<div className="preview-instructions">
					<h4 className="preview-title">Building Audiogram</h4>
					{embedType !== 'standard' ? (
						<p>
							Copy the link below to embed your audiogram using Casted's social
							media embed feature.
							<b>
								{' '}
								This link is unique to this audiogram, and will need to be
								rebuilt in order to access it again.{' '}
							</b>
							Upon copying the link, you may safely close this window (even if
							the building is still in progress)
						</p>
					) : (
						<p>
							You don't need to keep this modal open while you video is being
							created. You can return anytime by sharing this clip.
						</p>
					)}
				</div>
				<div className="audiogram-progress">
					<ProgressBar
						variant="info"
						animated
						now={(progress / (clipLength + 30)) * 100}
					/>
					<p className="progressbar--label">
						Estimated Time Remaining:{' '}
						{formatDuration(clipLength + 30 - progress)}
					</p>
				</div>
				{embedType !== 'standard' && (
					<div className="share-copy">
						<input
							ref={copyRef}
							className="share-copy--text"
							value={shareLink}
							readOnly
						/>
						<CopyToClipboardButton
							className="share-copy--button"
							copyRef={copyRef}
							btnText="Copy Link"
						/>
					</div>
				)}
			</div>
		);
	}

	if (loading || templatesLoading)
		return (
			<Spinner
				animation="border"
				variant="info"
				style={{ display: 'block', margin: '3em auto' }}
			/>
		);

	switch (modals.clipShareModal.audiogramStep) {
		case AudiogramStepsEnum.TYPE:
			if (featureOembeds) {
				return (
					<AudiogramSelection
						{...props}
						embedType={embedType}
						setEmbedType={setEmbedType}
						selectedTemplate={selectedTemplate}
						setSelectedTemplate={setSelectedTemplate}
						createAudiogram={createAudiogram}
						templates={templates}
					/>
				);
			} else {
				return (
					<SelectedTemplate
						templates={templates}
						selectedTemplate={selectedTemplate}
						setSelectedTemplate={setSelectedTemplate}
						createAudiogram={createAudiogram}
					/>
				);
			}
		case AudiogramStepsEnum.OEMBED_DETAILS:
			return (
				<SocialEmbedDetails
					{...props}
					socialDetails={socialDetails}
					clipName={clipName}
					setSocialDetails={setSocialDetails}
					createEmbedShare={createShareAudiogram}
				/>
			);
		case AudiogramStepsEnum.PREVIEW:
			return (
				<PreviewAudiogram {...props} audiogramSignedUrl={audiogramSignedUrl} />
			);
		default:
			return <></>;
	}
};

const mapStateToProps = (state) => ({
	selectedClip: state.transcript.selectedClip,
	modals: state.ui.modals,
});

export default connect(mapStateToProps)(CreateAudiogram);
