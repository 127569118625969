import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import * as Sentry from '@sentry/browser';
import { getSelectedAccount } from 'store/selectors/accounts';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client/react/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

// External Components
import { Spinner } from 'react-bootstrap';

// Internal Components
import FourOhFour from 'components/shared/404';
import EpisodeNav from './episodeHeader/episodeNav';
import EpisodeStatus from './episodeHeader/episodeStatus';
import EpisodeEdit from './episodeHeader/episodeEdit';
import EpisodeInfo from './episodeInfo';
import EpisodeAnalytics from './episodeAnalytics';
import EpisodeStudio from './episodeStudio';
import { ItemPromotionsWithRouter } from './itemPromotions';

// Internal Libraries
import { dispatch } from 'store/store';
import { setEpisodeId, setEpisode } from 'store/reducers/data/episode';
import { setLinkedClip, setLinkedTranscript } from 'store/reducers/data/studio';
import { resetDrawer, showDrawer } from 'store/reducers/ui/episodes/studio';
import queries from 'services/graphql/queries.js';
import { getContentType } from 'components/constants';
import { getCanView, ObjectPermissionSlugs } from 'utils/permissionsManager';
import { ItemPromotionWithRouter } from './itemPromotions/itemPromotion';
import { ItemEngagementInsightsWithRouter } from './itemEngagementInsights';

const EpisodeDetail = (props) => {
	const {
		match,
		location,
		permissions,
		userId,
		selectedAccount,
		linkedClip,
		linkedTranscript,
		history,
		/* Redux props start */
		episodeId,
		/* Redux props end */
	} = props;

	const { params } = match;
	const { accountId, showId: podcastId, promotionId } = params;
	const { search } = location;
	const { featurePromotions } = useFlags();

	useEffect(() => {
		if (!episodeId || episodeId !== params.episodeId) {
			dispatch(setEpisodeId(params.episodeId));
		}
	}, [params.episodeId, episodeId]);

	/**
	 * Fetch Data
	 * Get episode and podcast to pass to different episode views
	 */
	const {
		data: episodeData,
		loading: episodeLoading,
		error: episodeError,
		refetch: refetchEpisode,
	} = useQuery(queries.episodeById, {
		variables: {
			episodeId: params.episodeId,
			podcastId,
			includeThemeMatches: true,
		},
		fetchPolicy: 'cache-and-network',
	});

	const {
		data: podcastData,
		loading: podcastLoading,
		error: podcastError,
		refetch: refetchPodcast,
	} = useQuery(queries.podcastById, { variables: { podcastId } });

	const {
		data: resourceData,
		loading: resourceLoading,
		error: resourceError,
		refetch: refetchResources,
	} = useQuery(queries.getEpisodeRelatedResources, {
		variables: { episodeId: params.episodeId },
		fetchPolicy: 'cache-and-network',
	});

	const { loading: integrationLoading, error: integrationError } = useQuery(
		queries.podcastIntegrations,
		{
			variables: { podcastId: podcastId, integrationName: 'youtube' },
			skip: !podcastId,
			onCompleted: (data) => {
				const { podcastIntegrations = [] } = data || [];
				setYoutubeIntegration(podcastIntegrations[0]);
			},
		}
	);

	const {
		data: searchClipData,
		loading: searchClipLoading,
		error: searchClipError,
		refetch: searchClipRefetch,
	} = useQuery(queries.clipById, {
		variables: { clipId: search.split('=')[1] },
		skip: !search,
		onCompleted: (data) => {
			const { clip } = data || {};
			dispatch(setLinkedClip({
				id: clip.id,
				startTime: clip.startTime,
				endTime: clip.endTime,
				isPlaying: false,
			}));
			dispatch(showDrawer());
		}
	});

	const { episode = {} } = episodeData || {};
	const { podcast = {} } = podcastData || {};
	const { getEpisodeRelatedResources: resources } = resourceData || [];

	const episodeContentType = getContentType(podcast.collectionType);

	if (episodeError) {
		Sentry.captureException(episodeError);
	}

	if (podcastError) {
		Sentry.captureException(podcastError);
	}

	if (resourceError) {
		Sentry.captureException(resourceError);
	}

	if (integrationError) {
		Sentry.captureException(integrationError);
	}

	/**
	 * Combine loading states from parallel queries
	 */
	const [isLoading, setIsLoading] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const [youtubeIntegration, setYoutubeIntegration] = useState({});

	useEffect(() => {
		if (
			episodeLoading ||
			podcastLoading ||
			resourceLoading ||
			integrationLoading
		) {
			setIsLoading(true);
		} else {
			setIsLoading(false);

			// Only store episode value if there are properties to store
			if (Object.keys(episode).length > 0) {
				if (episode.id === params.episodeId && episode.status != 'deleted') {
					dispatch(setEpisode(episode));
					setNotFound(false);
				} else {
					setNotFound(true);
				}
			} else {
				setNotFound(true);
			}
		}
	}, [
		episodeLoading,
		podcastLoading,
		resourceLoading,
		integrationLoading,
		episode,
	]);

	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (
			episodeData &&
			episodeData.episode.status !== 'active' &&
			episodeData.episode.status !== 'indexing'
		) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [episodeData]);

	useEffect(
		// Unset values on component unload
		() => () => {
			dispatch(setEpisode({}));
			dispatch(setEpisodeId(null));
		},
		[]
	);

	// Dismiss auto-saving episode toast
	history.listen((location, action) => {
		toast.dismiss();
	});

	/**
	 * Dynamic Component Map
	 * Will use this map for nav
	 * and based on path, dynamically swap out the body
	 * https://reacttraining.com/react-router/web/api/Switch
	 */
	const rootPath = `/account/${accountId}/shows/${podcastId}/episodes/${params.episodeId}`;
	const canViewMetricsPromotions =
		permissions &&
		getCanView(permissions, ObjectPermissionSlugs.EPISODES, podcastId);
	const steps = [
		{
			name: `${episodeContentType} Info`,
			path: 'info',
			disabled: false,
			component: EpisodeInfo,
			onClick: () => {},
		},
		{
			name: 'Clips',
			path: 'clips',
			disabled: disabled,
			component: EpisodeStudio,
			onClick: () => {
				dispatch(resetDrawer());
			},
		},
	];

	if (featurePromotions && canViewMetricsPromotions) {
		steps.push({
			name: 'Promotions',
			path: 'promotions',
			disabled: false,
			component: ItemPromotionsWithRouter,
			onClick: () => {},
		});
	}

	if (canViewMetricsPromotions) {
		steps.push({
			name: 'Metrics',
			path: 'analytics',
			disabled: false,
			component: EpisodeAnalytics,
			onClick: () => {},
		});
	}

	const renderDetailPane = () => {
		if(notFound) {
			return <FourOhFour />;
		}

		switch (location.pathname) {
			case `${rootPath}/clips`:
				return (
					<EpisodeStudio
						accountId={parseInt(accountId, 10)}
						podcast={podcast}
						episode={episode}
						permissions={permissions}
						userId={userId}
						selectedAccount={selectedAccount}
						// Since the studio is using hookedOnRedux, we have to pass in all search-linked
						// functionality. This is temporary until we convert to full redux
						linkedClip={linkedClip}
						linkedTranscript={linkedTranscript}
						setLinkedClip={(clip) => {
							dispatch(setLinkedClip(clip));
						}}
						setLinkedTranscript={(transcript) => {
							dispatch(setLinkedTranscript(transcript));
						}}
					/>
				);
			case `${rootPath}/analytics`:
				return canViewMetricsPromotions ? (
					<EpisodeAnalytics
						accountId={parseInt(accountId, 10)}
						podcast={podcast}
						episode={episode}
						permissions={permissions}
						userId={userId}
						showVideoMetrics={selectedAccount.enableVideo}
					/>
				) : (
					<FourOhFour />
				);
			case `${rootPath}/promotions`:
				return featurePromotions ? (
					<ItemPromotionsWithRouter />
				) : (
					<FourOhFour />
				);
			case `${rootPath}/promotions/${promotionId}`:
				return featurePromotions ? <ItemPromotionWithRouter /> : <FourOhFour />;
			case `${rootPath}/analytics/engagementInsights`:
				return canViewMetricsPromotions ? (
					<ItemEngagementInsightsWithRouter
						accountId={parseInt(accountId, 10)}
					/>
				) : (
					<FourOhFour />
				);
			case `${rootPath}/info`:
			default:
				return (
					<EpisodeInfo
						accountId={parseInt(accountId, 10)}
						podcast={podcast}
						episode={episode}
						resources={resources}
						permissions={permissions}
						userId={userId}
						enableVideo={selectedAccount.enableVideo}
						refetch={refetchEpisode}
						youtubeIntegration={youtubeIntegration}
					/>
				);
		}
	};
	/**
	 * Render
	 * Build page layout
	 * Dynamically swap body using Switch
	 * If no step is present in path, redirect as fallback
	 */
	return (
		<div className="episode-detail">
			{isLoading ? (
				<Spinner
					className="episode-detail--loading"
					animation="grow"
					variant="info"
				/>
			) : (
				<>
					{!notFound && (<header className="episode-detail--header">
						<EpisodeNav
							rootPath={rootPath}
							steps={steps}
							episodeStatus={episode.status}
						/>
						<EpisodeEdit
							accountId={accountId}
							episodeId={episodeId}
							podcast={podcast}
							episode={episode}
						/>
						<EpisodeStatus
							accountId={accountId}
							episodeId={episodeId}
							podcast={podcast}
							episode={episode}
							youtubeIntegration={youtubeIntegration}
						/>
					</header>)}

					<section className="episode-body">
						<SwitchTransition>
							<CSSTransition
								key={location}
								addEndListener={(node, done) =>
									node.addEventListener('transitionend', done, false)
								}
								classNames="swap"
								timeout={300}
							>
								{renderDetailPane(location)}
							</CSSTransition>
						</SwitchTransition>
					</section>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
	userId: state.auth.profile.id,
	selectedAccount: getSelectedAccount(state),
	linkedClip: state.studio.linkedClip,
	linkedTranscript: state.studio.linkedTranscript,
	episodeId: state.episode.episodeId,
});

export default withRouter(connect(mapStateToProps)(EpisodeDetail));
