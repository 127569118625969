import React, { useRef, useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

// External Components
import { Alert, Nav, Tab } from 'react-bootstrap';
import { FaCode, FaLink, FaUsers } from 'react-icons/fa';

// Internal Components
import { CastedModal, YoutubeForm } from 'components/shared';
import { EmbedTab, LinkTab, SocialTab } from './tabs';

// Internal Libraries
import { SHARE_MODAL_TYPE, SHARE_TABS } from 'components/constants';

// assets
import youtubeLogo from 'assets/logos/youtube-logo.svg';

const ShareContentModal = (props) => {
	const {
		isOpen,
		onClose = () => {},
		className = '',
		title = 'Share Content',
		shareUrl = 'Oops something went wrong!',
		shareContentSubtitle = '',
		shareContentName = '',
		shareContentDuration = null,
		shareContentThumbnail = null,
		renderMoreContent = null,
		publicStatus = 'published',
		videoEnabled = false,
		showPublishToYoutube = false,
		name = '',
		description = '',
		showThumbnail = null,
		thumbnail = null,
		baseUrl,
		contentSlug,
		accountId,
		shareId,
		shareModalType = SHARE_MODAL_TYPE.EPISODE,
		linkText = '',
		embedText = '',
		socialText = '',
		youtubeText = '',
		disableLinkSharing = false,
	} = props;

	const { featureOembeds } = useFlags();

	const [activeKey, setActiveKey] = useState(SHARE_TABS.link);

	return (
		<CastedModal
			show={isOpen}
			handleClose={onClose}
			className={`share-content-modal ${className}`}
			modalSize={'lg'}
			closeButton={false}
		>
			{publicStatus === 'draft' && activeKey !== SHARE_TABS.youtube && (
				<Alert variant="info" className="scm-share-status-banner">
					<strong>Heads Up!</strong>
					<br />
					This episode is not published yet, you can share via the options
					below, but users will not be able to see it until the episode is
					published.
				</Alert>
			)}
			<div className="share-content-via-container">
				<Tab.Container
					defaultActiveKey={!disableLinkSharing ? SHARE_TABS.link : SHARE_TABS.embed}
					onSelect={(eventKey) => setActiveKey(eventKey)}
				>
					<div className="share-header-container">
						<div className="share-header-info">
							<h5 className="share-header-text">
								<span className="share-header-title">Share Center</span> /{' '}
								{title}
							</h5>
							<Nav className="share-via-header">
								<div className="share-via-options">
									{!disableLinkSharing &&
										<Nav.Item className="share-via-option">
											<Nav.Link eventKey={SHARE_TABS.link}>
												<h5>
													<FaLink className="icon-link" /> Link
												</h5>
											</Nav.Link>
										</Nav.Item>
									}
									{shareModalType !== SHARE_MODAL_TYPE.COLLECTION && (
											<Nav.Item className="share-via-option">
												<Nav.Link eventKey={SHARE_TABS.embed}>
													<h5>
														<FaCode className="icon-link" /> Embed
													</h5>
												</Nav.Link>
											</Nav.Item>
										)}
									{showPublishToYoutube && (
										<Nav.Item className="share-via-option">
											<Nav.Link eventKey={SHARE_TABS.youtube}>
												<img src={youtubeLogo} alt="YouTube" />
											</Nav.Link>
										</Nav.Item>
									)}
									{featureOembeds &&
										shareModalType === SHARE_MODAL_TYPE.EPISODE && (
											<Nav.Item className="share-via-option">
												<Nav.Link eventKey={SHARE_TABS.social}>
													<h5>
														<FaUsers className="icon-link" /> Social
													</h5>
												</Nav.Link>
											</Nav.Item>
										)}
								</div>
							</Nav>
						</div>
						{activeKey === SHARE_TABS.link && <p>{linkText}</p>}
						{activeKey === SHARE_TABS.embed && <p>{embedText}</p>}
						{activeKey === SHARE_TABS.social && <p>{socialText}</p>}
						{activeKey === SHARE_TABS.youtube && <p>{youtubeText}</p>}
					</div>
					<Tab.Content className="share-via-content">
						{!disableLinkSharing && 
							<Tab.Pane
								eventKey={SHARE_TABS.link}
								className="share-via-link-content"
							>
								<LinkTab
									shareContentSubtitle={shareContentSubtitle}
									shareContentName={shareContentName}
									shareContentDuration={shareContentDuration}
									shareContentThumbnail={shareContentThumbnail}
									renderMoreContent={renderMoreContent}
									audioOnly={!videoEnabled}
									baseUrl={baseUrl}
									shareModalType={shareModalType}
									name={name}
									shareId={shareId}
									shareUrl={shareUrl}
								/>
							</Tab.Pane>
						}
						<Tab.Pane
							eventKey={SHARE_TABS.embed}
							className="share-via-embed-content"
						>
							<EmbedTab
								videoEnabled={videoEnabled}
								embedHeight={250}
								baseUrl={baseUrl}
								contentSlug={contentSlug}
								isOpen={isOpen}
								shareModalType={shareModalType}
								name={name}
								shareId={shareId}
							/>
						</Tab.Pane>
						<Tab.Pane
							eventKey={SHARE_TABS.youtube}
							className="share-via-link-content"
						>
							<YoutubeForm
								episodeId={shareId}
								accountId={accountId}
								smallFooter={true}
								includeTitle={false}
								title={name}
								description={description}
								thumbnail={thumbnail}
								showThumbnail={showThumbnail}
								onClose={onClose}
							/>
						</Tab.Pane>
						<Tab.Pane
							eventKey={SHARE_TABS.social}
							className="share-via-social-content"
						>
							<SocialTab
								videoEnabled={videoEnabled}
								baseUrl={baseUrl}
								episodeSlug={contentSlug}
								accountId={accountId}
								itemId={shareId}
								itemName={name}
							/>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</div>
		</CastedModal>
	);
};

export { ShareContentModal };
