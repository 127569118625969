import React, { useState } from 'react';
import { Facet } from '@elastic/react-search-ui';

import Select from 'react-select';
import chroma from 'chroma-js';

import styles from './styles.scss';

const TagListFilter = (props) => {
	const { field, fieldLabel } = props;

	const [selectedValues, setSelectedValues] = useState([]);

	const colourStyles = {
		control: (styles) => ({ ...styles, backgroundColor: 'white' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: isDisabled
					? undefined
					: isSelected
					? data.color
					: isFocused
					? color.alpha(0.1).css()
					: undefined,
				color: isDisabled
					? '#ccc'
					: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.color,
				cursor: isDisabled ? 'not-allowed' : 'default',

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled
						? isSelected
							? data.color
							: color.alpha(0.3).css()
						: undefined,
				},
			};
		},
		multiValue: (styles, { data }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: color.alpha(0.1).css(),
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			':hover': {
				backgroundColor: data.color,
				color: 'white',
			},
		}),
	};

	return (
		<div className={styles.filterTags}>
			<Facet
				// Hardcoding the show value because the component doesn't elastic no play well
				show={999999}
				field={field}
				label={fieldLabel}
				view={({ label, onRemove, onSelect, options }) => {
					const colourOptions = options.map((option) => {
						return {
							value: option.value,
							label: option.value,
							color: '#231f70',
						};
					});

					return (
						<fieldset>
							<h3>{label}</h3>
							<Select
								value={selectedValues}
								closeMenuOnSelect={false}
								isMulti
								options={colourOptions}
								styles={colourStyles}
								onChange={(values) => {
									const originalValues = selectedValues || [];
									const updatedValues = values || [];
									const newValues = updatedValues.filter(
										(value) => !originalValues.includes(value)
									);
									const removedValues = originalValues.filter(
										(value) => !updatedValues.includes(value)
									);

									if (newValues.length > 0) {
										onSelect(newValues[0].value);
									}

									if (removedValues.length > 0) {
										onRemove(removedValues[0].value);
									}

									setSelectedValues(values);
								}}
							/>
						</fieldset>
					);
				}}
			/>
		</div>
	);
};

export default TagListFilter;
