import React, { useState, useRef, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';

import { Card, Form, Container, Row, Col, FormGroup } from 'react-bootstrap';

import CopyToClipboardButton from 'components/shared/copyToClipboardButton';
import LoadingShow from 'components/shared/loadingSkeletons/loadingShow';
import Button from 'components/shared/button';
import { PODCAST_COLLECTION_TYPE } from 'components/constants';
import { isUrlValid } from '../../../utils';

/**
 * Component Collection Settings
 * Screen for administering show
 */
const CollectionSettings = (props) => {
	const { match, history, podcast, updatePodcast, showId, accountId } = props;
	// END Props

	const {
		rssAuthor: _rssAuthor = '',
		rssEmail: _rssEmail = '',
		rssCopyright: _rssCopyright = '',
		rssLanguageId: _rssLanguageId = null,
		rssCategoryId1: _rssCategoryId1 = '',
		rssCategoryId2: _rssCategoryId2 = '',
		rssCategoryId3: _rssCategoryId3 = '',
		alternateRssLink: _alternateRssLink = '',
		trackingPrefix: _trackingPrefix = '',
		explicit: _explicit = false,
		disablePremiumTranscript: _disablePremiumTranscript = false,
		disableLandingPages: _disableLandingPages = false,
	} = podcast || {};

	// Queries

	const {
		data: catData,
		loading: catLoading,
		error: catError,
	} = useQuery(queries.getShowCategories);
	const { showCategories } = catData || {};

	const {
		data: langData,
		loading: langLoading,
		error: langError,
	} = useQuery(queries.getLanguages);
	const { languages } = langData || {};

	const [
		deletePodcast,
		{ data: deleteData, loading: deleteLoading, error: deleteError },
	] = useMutation(mutations.deletePodcastById);
	// END Queries

	// State Hooks
	const [confirmDelete, setConfirmDelete] = useState(false);

	const [feedUrl, setFeedUrl] = useState('');
	const [rssAuthor, setRssAuthor] = useState(_rssAuthor);
	const [rssEmail, setRssEmail] = useState(_rssEmail);
	const [rssCopyright, setRssCopyright] = useState(_rssCopyright);
	const [rssLanguageId, setRssLanguageId] = useState(_rssLanguageId);
	const [rssCategoryId1, setRssCategoryId1] = useState(_rssCategoryId1);
	const [rssCategoryId2, setRssCategoryId2] = useState(_rssCategoryId2);
	const [rssCategoryId3, setRssCategoryId3] = useState(_rssCategoryId3);
	const [alternateRssLink, setAlternateRSSLink] = useState(_alternateRssLink);
	const [trackingPrefix, setTrackingPrefix] = useState(_trackingPrefix);
	const [explicit, setExplicit] = useState(_explicit);
	const [disablePremiumTranscript, setDisablePremiumTranscript] = useState(
		_disablePremiumTranscript
	);
	const [disableLandingPages, setDisableLandingPages] =
		useState(_disableLandingPages);

	const copyLinkRef = useRef(null);
	// END State Hooks

	useEffect(() => {
		if (podcast) {
			setFeedUrl(
				podcast.rssDomain
					? `https://${podcast.rssDomain}/${podcast.accountId}/${podcast.slug}/feed`
					: podcast.feedUrl
			);
		}

		if (_rssAuthor) setRssAuthor(_rssAuthor);
		if (_rssEmail) setRssEmail(_rssEmail);
		if (_rssCopyright) setRssCopyright(_rssCopyright);
		if (_rssLanguageId) setRssLanguageId(_rssLanguageId);
		if (_rssCategoryId1) setRssCategoryId1(_rssCategoryId1);
		if (_rssCategoryId2) setRssCategoryId2(_rssCategoryId2);
		if (_rssCategoryId3) setRssCategoryId3(_rssCategoryId3);
		if (_alternateRssLink) setAlternateRSSLink(_alternateRssLink);
		if (_trackingPrefix) setTrackingPrefix(_trackingPrefix);
		if (_explicit) setExplicit(_explicit);
		if (_disablePremiumTranscript)
			setDisablePremiumTranscript(_disablePremiumTranscript);
		if (_disableLandingPages) setDisableLandingPages(_disableLandingPages);
	}, [podcast]);

	const updateRssSettings = async () => {
		await updatePodcast({
			variables: {
				podcastId: showId,
				podcast: {
					accountId: parseInt(accountId, 10),
					rssAuthor,
					rssEmail,
					rssCopyright,
					rssLanguageId,
					rssCategoryId1: rssCategoryId1 || null,
					rssCategoryId2: rssCategoryId2 || null,
					rssCategoryId3: rssCategoryId3 || null,
					trackingPrefix,
					explicit,
				},
			},
		});
		if (_trackingPrefix !== trackingPrefix) {
			trackEvent(`${trackingPrefix ? 'add' : 'delete'}-tracking-prefix`, {
				collectionName: podcast.name,
			});
		}
		Toast.success('Settings Saved!');
	};

	// Creating new updatePodcast call for non-rss fields. Will rework with future Manage Show redesign
	const updateShowSettings = async () => {
		await updatePodcast({
			variables: {
				podcastId: showId,
				podcast: {
					accountId: parseInt(accountId, 10),
					disablePremiumTranscript: disablePremiumTranscript,
					disableLandingPages: disableLandingPages,
					alternateRssLink: alternateRssLink,
				},
			},
		});
		if (disablePremiumTranscript) {
			trackEvent('disable-premium-transcription', {
				collectionName: podcast.name,
			});
		}
		Toast.success('Settings Saved!');
	};

	const deleteShow = async () => {
		if (!confirmDelete) return setConfirmDelete(true);
		await deletePodcast({
			variables: {
				podcastId: showId,
			},
		});
		Toast.success('Show Deleted');
		trackEvent('delete-collection', { collectionName: podcast.name });
		history.push(`/account/${accountId}/allContent/managedContent/`);
	};

	if (catLoading) {
		return <LoadingShow />;
	}
	// END Helper Funcs

	// Render

	return (
		<>
			<section className="show-admin--rss show-admin--section">
				<Container>
					<Row className="mb-3">
						<Col>
							<Row>
								<Col>
									<h5 className="mb-0">
										Collection Settings
									</h5>
									<p className="helper-text">
										Update the collection's settings.
									</p>
								</Col>
							</Row>
							<Card>
								<Card.Body>
									<Row>
										<Col>
											<FormGroup>
												<Form.Label htmlFor="disablePremiumTranscript">
													Disable Premium Transcript
												</Form.Label>
												<Form.Check
													className="admin-section--checkbox"
													id="disablePremiumTranscript"
													label="Disable premium transcription for all future content. If checked, Casted’s AI transcription will be used by default."
													onChange={() =>
														setDisablePremiumTranscript(
															!disablePremiumTranscript
														)
													}
													checked={
														disablePremiumTranscript
													}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormGroup>
												<Form.Label htmlFor="disableLandingPages">
													Disable Landing Pages
												</Form.Label>
												<Form.Check
													className="admin-section--checkbox"
													id="disableLandingPages"
													label="Disable Casted landing pages for this collection and all of its associated items"
													onChange={() =>
														setDisableLandingPages(
															!disableLandingPages
														)
													}
													checked={
														disableLandingPages
													}
												/>
												{disableLandingPages && (
													<FormGroup>
														<Form.Label>
															Alternate Show Page
														</Form.Label>
														<p className="admin-section--checkbox">
															RSS feeds typically
															have a link to the
															show's page. Since
															we are disabling
															landing pages, where
															do you want this
															link to go?
														</p>
														<Form.Control
															className="shared-episode-link-text"
															id="alternateShowPage"
															placeholder="Please enter a valid URL. (e.g. https://www.casted.us)"
															onChange={(e) =>
																setAlternateRSSLink(
																	e.target
																		.value
																)
															}
															onBlur={(e) =>
																setAlternateRSSLink(
																	e.target.value.trim()
																)
															}
															value={
																alternateRssLink
															}
															isInvalid={
																alternateRssLink !=
																	'' &&
																!isUrlValid(
																	alternateRssLink
																)
															}
															isValid={isUrlValid(
																alternateRssLink
															)}
														/>
													</FormGroup>
												)}
											</FormGroup>
										</Col>
									</Row>
									<Row className="justify-content-end">
										<Col className="text-right">
											<Button
												variant="primary"
												onClick={updateShowSettings}
											>
												UPDATE
											</Button>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>

					{podcast.collectionType ===
						PODCAST_COLLECTION_TYPE.serial && (
						<Row>
							<Col xs={12} lg={6} className="mb-3">
								<Row>
									<Col>
										<h5 className="mb-0">RSS Settings</h5>
										<p className="helper-text">
											Update the author, email, copyright,
											and category in your show's RSS
											feed.
										</p>
									</Col>
								</Row>
								<Card>
									<Card.Body>
										<Row>
											<Col className="mb-3">
												<Form.Label>Author</Form.Label>
												<Form.Control
													className="shared-episode-link-text"
													onChange={(e) =>
														setRssAuthor(
															e.target.value
														)
													}
													onBlur={(e) =>
														setRssAuthor(
															e.target.value.trim()
														)
													}
													value={rssAuthor}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="mb-3">
												<Form.Label>Email</Form.Label>
												<Form.Control
													className="shared-episode-link-text"
													onChange={(e) =>
														setRssEmail(
															e.target.value
														)
													}
													onBlur={(e) =>
														setRssEmail(
															e.target.value.trim()
														)
													}
													value={rssEmail}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="mb-3">
												<Form.Label>
													Copyright
												</Form.Label>
												<Form.Control
													className="shared-episode-link-text"
													onChange={(e) =>
														setRssCopyright(
															e.target.value
														)
													}
													onBlur={(e) =>
														setRssCopyright(
															e.target.value.trim()
														)
													}
													value={rssCopyright}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="mb-3">
												<Form.Label>
													Language
												</Form.Label>
												<Form.Control
													as="select"
													className="shared-episode-link-text"
													onChange={(e) => {
														let index =
															e.nativeEvent.target
																.selectedIndex;
														setRssLanguageId(
															e.target.value
														);
													}}
													value={rssLanguageId}
												>
													{languages &&
														languages.map(
															(language) => {
																if (
																	rssLanguageId ===
																		null &&
																	language.code ===
																		'en'
																) {
																	return (
																		<option
																			value={
																				language.id
																			}
																			selected="selected"
																		>
																			{
																				language.name
																			}
																		</option>
																	);
																}
																return (
																	<option
																		value={
																			language.id
																		}
																	>
																		{
																			language.name
																		}
																	</option>
																);
															}
														)}
												</Form.Control>
											</Col>
										</Row>
										<Row>
											<Col className="mb-3">
												<Form.Label>
													Categories
												</Form.Label>
												<p className="admin-section--instructions">
													Select up to 3 categories
													for your show.
												</p>
												<Form.Control
													as="select"
													className="shared-episode-link-text mb-3"
													onChange={(e) => {
														setRssCategoryId1(
															e.target.value
														);
													}}
													value={rssCategoryId1}
												>
													<option></option>
													{showCategories &&
														showCategories.map(
															(category) => {
																const name =
																	category.level ===
																	2
																		? '- ' +
																		  category.name
																		: category.name;
																return (
																	<option
																		value={
																			category.id
																		}
																	>
																		{name}
																	</option>
																);
															}
														)}
												</Form.Control>
												<Form.Control
													as="select"
													className="shared-episode-link-text mb-3"
													onChange={(e) => {
														setRssCategoryId2(
															e.target.value
														);
													}}
													value={rssCategoryId2}
													disabled={!rssCategoryId1}
												>
													<option></option>
													{showCategories &&
														showCategories.map(
															(category) => {
																const name =
																	category.level ===
																	2
																		? '- ' +
																		  category.name
																		: category.name;
																return (
																	<option
																		value={
																			category.id
																		}
																	>
																		{name}
																	</option>
																);
															}
														)}
												</Form.Control>
												<Form.Control
													as="select"
													className="shared-episode-link-text"
													onChange={(e) => {
														setRssCategoryId3(
															e.target.value
														);
													}}
													value={rssCategoryId3}
													disabled={!rssCategoryId2}
												>
													<option></option>
													{showCategories &&
														showCategories.map(
															(category) => {
																const name =
																	category.level ===
																	2
																		? '- ' +
																		  category.name
																		: category.name;
																return (
																	<option
																		value={
																			category.id
																		}
																	>
																		{name}
																	</option>
																);
															}
														)}
												</Form.Control>
											</Col>
										</Row>
										<Row>
											<Col className="mb-3">
												<Form.Label>
													Explicit
												</Form.Label>
												<Form.Check
													className="admin-section--checkbox"
													label="Check this box if the show contains explicit material"
													onChange={() =>
														setExplicit(!explicit)
													}
													checked={explicit}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="mb-3">
												<Form.Label>
													Tracking URL Prefix
												</Form.Label>
												<p className="admin-section--instructions">
													Warning: Changing this field
													may break your RSS feed if
													it's not set correctly.
													Please check the tracking
													service's documentation for
													the value to put here.
												</p>
												<Form.Control
													className="shared-episode-link-text"
													onChange={(e) =>
														setTrackingPrefix(
															e.target.value
														)
													}
													onBlur={(e) =>
														setTrackingPrefix(
															e.target.value.trim()
														)
													}
													value={trackingPrefix}
												/>
											</Col>
										</Row>
										<Row className="justify-content-end">
											<Col className="text-right">
												<Button
													variant="primary"
													onClick={updateRssSettings}
												>
													UPDATE
												</Button>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
							<Col xs={12} lg={6} className="mb-3">
								<Row>
									<Col>
										<h5 className="mb-0">RSS Feed</h5>
										<p className="helper-text">
											This is the RSS feed associated with
											your show.
										</p>
									</Col>
								</Row>
								<Card>
									<Card.Body>
										<Row>
											<Col>
												<p className="form-label mb-1">
													Casted Hosting
												</p>
												<p className="helper-text">
													This feed was created for
													your show and can be used to
													syndicate to listening
													platforms like Apple
													Podcasts.{' '}
												</p>
												<p className="helper-text pb-2">
													If you want to customize the
													domain of your feed, please
													reach out to us!
												</p>
												<p className="form-label mb-1">
													3rd Party Hosting
												</p>
												<p className="helper-text pb-2">
													This is the feed you
													provided Casted to import
													your show. If you need to
													change the feed or need
													support, please reach out to
													us!
												</p>
											</Col>
										</Row>
										<hr />
										<Row
											style={{ alignItems: 'center' }}
											className="pt-3"
										>
											<Col>
												<Form.Control
													ref={copyLinkRef}
													className="shared-episode-link-text"
													value={feedUrl}
													readOnly
												/>
											</Col>
											<Col xs="auto">
												<CopyToClipboardButton
													className="copy-share-link"
													copyRef={copyLinkRef}
													btnText="Copy"
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					)}
				</Container>
			</section>

			<section className="show-admin--danger-zone show-admin--section">
				<Container>
					<Row>
						<Col>
							<Card>
								<Card.Body>
									<Row className="pb-3">
										<Col>
											<h4 className="admin-section--title text-danger">
												Danger Zone
											</h4>
											<p>
												Any actions taken in this
												section are permanent and cannot
												be reversed. Venture at your own
												risk!
											</p>
										</Col>
									</Row>
									<hr />
									<Row className="align-items-center justify-content-between pt-2">
										<Col>
											<h5 className="admin-section--title">
												Delete Show
											</h5>
											<p>
												This will remove the show and
												delete all episodes,
												transcriptions, and clips!
											</p>
										</Col>
										<Col xs={4} className="col-action">
											<Button
												variant="danger"
												onClick={deleteShow}
											>
												{confirmDelete
													? 'Are You Sure?'
													: 'Delete Show'}
											</Button>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default CollectionSettings;
