import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, Router, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { withApollo } from '@apollo/client/react/hoc';
import { StickyContainer } from 'react-sticky';
import * as Sentry from '@sentry/browser';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';

// Redux
import { persistor, history, dispatch } from 'store/store';
import { unsetLoggedIn, setPermissions } from 'store/reducers/data/auth';
import { getSelectedAccount } from 'store/selectors/accounts';
import {
	setSelectedAccountId,
	getAccounts,
	unsetAccounts,
} from 'store/reducers/data/accounts';
import { setThemeKeywordLimit } from 'store/reducers/data/theme';

// Internal Libraries
import { getApolloClient } from 'services/graphql/apolloClient';
import { AuthSvc, initAuthSingleton } from 'services/auth';
import API from 'services/api';
import {
	getCanEdit,
	getCanView,
	getPermission,
	ObjectPermissionSlugs,
} from 'utils/permissionsManager';

// Internal Components
import Sidebar from 'components/sidebar';
import Login from 'components/login';
import FourOhThree from 'components/shared/403';
import FourOhFour from 'components/shared/404';
import ManagedContent from 'components/allContent/managedContent';
import { ImportedContentWithRouter } from 'components/allContent/importedContent';
import { ImportedItemsWithRouter } from 'components/allContent/importedContent/importedItems';
import Themes from 'components/themes';
import Theme from 'components/themes/theme';
import Episodes from 'components/shows/episodes';
import ShowMetrics from 'components/shows/metrics';
import ShowSettings from 'components/shows/settings/showSettings';
import ShowAdmin from 'components/shows/settings/showAdmin';
import EpisodeDetail from 'components/shows/episodes/episodeDetail';
import PasswordReset from 'components/passwordReset';
import SetPassword from 'components/setPassword';
import Users from 'components/users/users';
import MigrateShow from 'components/shows/migrateShow';
import HubspotCallback from 'components/oauth/hubspotCallback';
import YoutubeCallback from 'components/oauth/youtubeCallback';
import Authorize from 'components/oauth/authorize';
import ApiKeys from 'components/settings/apiKeys';
import { AccountIntegrationsWithRouter } from 'components/settings/accountIntegrations';
import SsoSettings from 'components/settings/sso';
import AccountMetrics from 'components/accountMetrics';
import CastedSearchModal from 'components/shared/searchModal';
import { InsightsWithRouter } from './insights';
import { CompanyProfileWithRouter } from 'components/shared/companyProfile';
import Playlists from 'components/allContent/playlists';
import Playlist from 'components/allContent/playlists/playlist';
import ShareCenter from 'components/shareCenter';
import ShareMetrics from 'components/shareCenter/metrics';
import { CollectionPromotionListWithRouter } from './shows/collectionPromotions/collectionPromotionList';
import { CollectionPromotionWithRouter } from './shows/collectionPromotions/collectionPromotion';

// External Components
import { SearchTrigger } from '@casted-app/casted-react-search';

// External CSS
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'rc-checkbox/assets/index.css';
import 'react-tagsinput/react-tagsinput.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';

const AuthSingleton = new AuthSvc({ storagePrefix: 'casted_' });
initAuthSingleton(AuthSingleton);

const CloseToastBtn = (props) => null;

const IS_PROD = process.env.REACT_APP_RELEASE === 'PROD';

const App = (props) => {
	const {
		authProfile,
		isAuthed,
		selectedAccount,
		selectedAccountId,
		accounts,
		permissions,
	} = props;

	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const [isSearchOpen, setIsSearchOpen] = useState(false);

	// Account data for Vitally
	const [currentRole, setCurrentRole] = useState({});
	const [podcastCount, setPodcastCount] = useState(null);
	const [episodeCount, setEpisodeCount] = useState(null);
	const [episodeDuration, setEpisodeDuration] = useState(null);

	const ldClient = useLDClient();

	useEffect(() => {
		async function getAccountInfo() {
			const myAccounts = await API.getMyAccounts();

			dispatch(getAccounts(myAccounts));
		}

		if (isAuthed) {
			getAccountInfo();
		}
	}, [isAuthed]);

	useEffect(() => {
		// Wait until all user profile data is loaded before identifying with integrations
		if (
			currentRole &&
			podcastCount !== null &&
			episodeCount !== null &&
			episodeDuration !== null &&
			isAuthed &&
			authProfile.id
		) {
			sendUserIdentifyAnalytics();
		}
	}, [episodeCount, podcastCount, episodeDuration, currentRole, authProfile]);

	useEffect(() => {
		if (!isAuthed) {
			return;
		}

		const locationAccountId = history.location.pathname
			.replace('/account/', '')
			.split('/')[0];

		if (
			accounts &&
			Object.keys(accounts).length &&
			history.location &&
			history.location.pathname &&
			history.location.pathname.startsWith('/account')
		) {
			if (
				!selectedAccount ||
				locationAccountId !== selectedAccount.id.toString()
			) {
				const newAccount = accounts[locationAccountId];

				if (newAccount) {
					dispatch(setSelectedAccountId(newAccount.id));
				}
			}
		}
	}, [accounts, history.location]);

	useEffect(() => {
		if (window.Appcues) {
			window.Appcues.page();
		}

		if (window.userpilot) {
			window.userpilot.reload();
		}
	}, [history.location]);

	useEffect(() => {
		async function getItemCounts() {
			const accountPodcastCount = await API.podcastCount(selectedAccountId);
			const accountEpisodeCount = await API.episodeCount(selectedAccountId);
			const accountEpisodeDuration = await API.totalEpisodeDuration(
				selectedAccountId
			);

			setPodcastCount(accountPodcastCount);
			setEpisodeCount(accountEpisodeCount);
			setEpisodeDuration(accountEpisodeDuration);
		}

		async function setAccountPermissions() {
			const rolePermissions = await API.getMyAccountRole(selectedAccountId);
			const role = await API.getUserRole(selectedAccountId, authProfile.id);
			const collectionPermissions =
				role.slug === 'admin'
					? []
					: await API.getMyCollectionPermissionsRoles(
							selectedAccountId,
							authProfile.id
					  );

			const permissions = {
				accountRole: rolePermissions,
				collectionRoles: { ...collectionPermissions },
			};
			dispatch(setPermissions(permissions));
			setCurrentRole(role);

			await getItemCounts();
		}

		async function getAccountLimits() {
			const themeKeywordLimit = await API.getThemeKeywordLimit();

			dispatch(setThemeKeywordLimit(themeKeywordLimit));
		}

		if (selectedAccountId && isAuthed) {
			// Reset account details before fetching new values
			setPodcastCount(null);
			setEpisodeCount(null);
			setEpisodeDuration(null);

			setAccountPermissions();
			getAccountLimits();
		}
	}, [selectedAccountId, isAuthed]);

	const sendUserIdentifyAnalytics = () => {
		ldClient.identify({
			key: authProfile.id,
			firstName: authProfile.first_name,
			lastName: authProfile.last_name,
			email: authProfile.email,
			custom: {
				accountId: selectedAccount.id,
				accountName: selectedAccount.name,
				videoEnabled: selectedAccount.enableVideo,
			},
		});

		LogRocket.identify(authProfile.id, {
			name: `${authProfile.first_name} ${authProfile.last_name}`,
			email: authProfile.email,
			accountName: selectedAccount.name,
			accountId: selectedAccount.id,
		});

		LogRocket.getSessionURL((sessionUrl) => {
			Sentry.configureScope(function (scope) {
				scope.setUser({
					email: authProfile.email,
					accountName: selectedAccount.name,
					accountId: selectedAccount.id,
				});
				scope.setExtra('sessionUrl', sessionUrl);
			});
		});

		// Only use Vitally in Prod and ignore the Casted and Casted Demo accounts
		// Also ignore all Casted employees
		if (
			window.Vitally &&
			IS_PROD &&
			![5, 20].includes(selectedAccount.id) &&
			!authProfile.email.includes('@casted.us')
		) {
			const vitallyAccount = {
				accountId: selectedAccount.id,
				traits: {
					name: selectedAccount.name,
					domain: selectedAccount.domain,
					videoEnabled: selectedAccount.enableVideo,
					collectionCount: podcastCount,
					itemCount: episodeCount,
					totalItemMinutes: episodeDuration,
				},
			};

			const vitallyUser = {
				userId: authProfile.email,
				accountId: selectedAccount.id,
				traits: {
					name: authProfile.fullName,
					email: authProfile.email,
					createdAt: new Date(authProfile.created_on),
					castedId: authProfile.id,
				},
			};

			if (currentRole) {
				vitallyUser.traits.role = currentRole.name;
			}

			window.Vitally.account(vitallyAccount);
			window.Vitally.user(vitallyUser);
			window.Vitally.nps('survey');
		}

		if (window.userpilot) {
			window.userpilot.identify(authProfile.id, {
				name: authProfile.fullName,
				email: authProfile.email,
				created_at: authProfile.created_on,
				company: { id: selectedAccount.id, name: selectedAccount.name },
			});
		}
	};

	const logout = async () => {
		AuthSingleton.signOut();
		dispatch(unsetLoggedIn());
		dispatch(unsetAccounts());
		// await getApolloClient().clearStore()
		await getApolloClient().cache.reset();
	};

	const renderAuthedSection = (
		Component,
		{ match, location, pathname },
		opts = {}
	) => {
		if (isAuthed) {
			if (
				match.params &&
				!accounts[match.params.accountId] &&
				!opts.ignoreAccountId
			) {
				return (
					<Redirect to={{ pathname: `/account/${selectedAccountId}/shows` }} />
				);
			}

			return (
				<div className="app-container">
					<main id="app-main" className="app-main">
						<Sidebar
							isOpen={isSidebarOpen}
							onClose={() => setIsSidebarOpen(false)}
							authProfile={authProfile}
							logout={logout}
						/>

						<div id="app-content" className="app-content">
							<div className="search-help-content">
								<SearchTrigger onClick={() => setIsSearchOpen(true)} />
								<a href="https://help.casted.us" target="_blank">
									<FaRegQuestionCircle />
								</a>
							</div>
							{isSearchOpen && (
								<CastedSearchModal
									show={isSearchOpen}
									onHide={() => setIsSearchOpen(false)}
									accountId={selectedAccountId}
								/>
							)}
							<StickyContainer
								id="app-page-content"
								className="app-page-content"
							>
								{Component}
							</StickyContainer>
						</div>
					</main>
					<ToastContainer
						closeButton={<CloseToastBtn />}
						className="toastify"
						hideProgressBar
					/>
				</div>
			);
		}

		return <Redirect to={{ pathname: '/login' }} />;
	};

	const renderLogin = (navProps) => {
		return <Login logout={logout} {...navProps} />;
	};

	const render403 = (navProps) => {
		return <FourOhThree {...navProps} />;
	};

	const render404 = (navProps) => {
		return <FourOhFour {...navProps} />;
	};

	const renderRoot = () => {
		if (!isAuthed) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!permissions) {
			// auth set but no permissions yet,
			// need to wait while permissions are loaded
			return null;
		}

		if (!selectedAccountId || !selectedAccount.id) {
			if (!accounts) {
				return;
			}

			const accountsArr = Object.values(accounts);

			if (accountsArr && accountsArr.length > 0) {
				const a = accountsArr[0];
				dispatch(setSelectedAccountId(a.id));

				return (
					<Redirect
						to={{
							pathname: `/account/${a.id}/allContent/managedContent`,
						}}
					/>
				);
			} else {
				return <Redirect to={{ pathname: '/login' }} />;
			}
		}

		const aId = selectedAccountId || selectedAccount.id;

		return (
			<Redirect
				to={{ pathname: `/account/${aId}/allContent/managedContent` }}
			/>
		);
	};

	const renderShows = () => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.SHOWS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		const aId = selectedAccountId || selectedAccount?.id;

		if (!aId) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		return (
			<Redirect
				to={{ pathname: `/account/${aId}/allContent/managedContent` }}
			/>
		);
	};

	const renderManagedContent = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.SHOWS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.SHOWS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ManagedContent />, navProps);
	};

	const renderImportedContent = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.IMPORTED_CONTENT)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.IMPORTED_CONTENT)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ImportedContentWithRouter />, navProps);
	};

	const renderImportedItems = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.IMPORTED_CONTENT)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.IMPORTED_CONTENT)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ImportedItemsWithRouter />, navProps);
	};

	const renderThemes = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.THEMES)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.THEMES)) {
			return render403(navProps);
		}

		return renderAuthedSection(<Themes />, navProps);
	};

	const renderTheme = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.THEMES)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.THEMES)) {
			return render403(navProps);
		}

		return renderAuthedSection(<Theme />, navProps);
	};

	const renderPlaylists = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.PLAYLISTS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.PLAYLISTS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<Playlists />, navProps);
	};

	const renderPlaylist = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.PLAYLISTS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.PLAYLISTS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<Playlist />, navProps);
	};

	const renderEpisodes = (navProps) => {
		const collectionId = navProps.match.params.showId;
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.SHOWS, collectionId)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.SHOWS, collectionId)) {
			return render403(navProps);
		}

		return renderAuthedSection(<Episodes />, navProps);
	};

	const renderShowMetrics = (navProps) => {
		const collectionId = navProps.match.params.showId;
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.METRICS, collectionId)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.METRICS, collectionId)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ShowMetrics />, navProps);
	};

	const renderShowSettings = (navProps) => {
		const collectionId = navProps.match.params.showId;
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.SHOWS, collectionId)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanEdit(permissions, ObjectPermissionSlugs.SHOWS, collectionId)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ShowSettings />, navProps);
	};

	const renderShowAdmin = (navProps) => {
		const collectionId = navProps.match.params.showId;
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.SHOWS, collectionId)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanEdit(permissions, ObjectPermissionSlugs.SHOWS, collectionId)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ShowAdmin />, navProps);
	};

	const renderCollectionPromotionList = (navProps) => {
		const collectionId = navProps.match.params.showId;
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.SHOWS, collectionId)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.SHOWS, collectionId)) {
			return render403(navProps);
		}

		return renderAuthedSection(<CollectionPromotionListWithRouter />, navProps);
	};

	const renderCollectionPromotion = (navProps) => {
		const collectionId = navProps.match.params.showId;
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.SHOWS, collectionId)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanEdit(permissions, ObjectPermissionSlugs.SHOWS, collectionId)) {
			return render403(navProps);
		}

		return renderAuthedSection(<CollectionPromotionWithRouter />, navProps);
	};

	const renderEpisodeDetail = (navProps) => {
		const collectionId = navProps.match.params.showId;
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.EPISODES, collectionId)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (
			!getCanView(permissions, ObjectPermissionSlugs.EPISODES, collectionId)
		) {
			return render403(navProps);
		}
		return renderAuthedSection(<EpisodeDetail />, navProps);
	};

	const renderPasswordReset = () => {
		if (isAuthed) {
			return <Redirect to={{ pathname: '/' }} />;
		}

		return <PasswordReset />;
	};

	const renderSetPassword = () => {
		if (isAuthed) {
			return <Redirect to={{ pathname: '/' }} />;
		}

		return <SetPassword />;
	};

	const renderMigrate = (navProps) => {
		return renderAuthedSection(<MigrateShow />, navProps);
	};

	const renderUsers = (navProps) => {
		if (!getCanView(permissions, ObjectPermissionSlugs.USERS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<Users />, navProps);
	};

	const renderOAuthHub = (navProps) => {
		return renderAuthedSection(<HubspotCallback />, navProps, {
			ignoreAccountId: true,
		});
	};

	const renderOAuthYoutube = (navProps) => {
		return renderAuthedSection(<YoutubeCallback />, navProps, {
			ignoreAccountId: true,
		});
	};

	const renderAuthorize = (navProps) => {
		if (!isAuthed) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		return <Authorize {...navProps} />;
	};

	const renderApiKeys = (navProps) => {
		return renderAuthedSection(<ApiKeys />, navProps);
	};

	const renderAccountIntegrations = (navProps) => {
		return renderAuthedSection(<AccountIntegrationsWithRouter />, navProps);
	};

	const renderSsoSettings = (navProps) => {
		if (
			!permissions ||
			!getCanEdit(permissions, ObjectPermissionSlugs.SAML)
		) {
			return render403(navProps);
		}
		
		return renderAuthedSection(<SsoSettings />, navProps);
	};

	const renderAccountMetrics = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.METRICS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.METRICS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<AccountMetrics />, navProps);
	};

	const renderInsights = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.METRICS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.METRICS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<InsightsWithRouter />, navProps);
	};

	const renderCompanyProfile = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.METRICS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.METRICS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<CompanyProfileWithRouter />, navProps);
	};

	const renderShareCenter = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.METRICS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.METRICS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ShareCenter />, navProps);
	};

	const renderShareMetrics = (navProps) => {
		if (
			!permissions ||
			!getPermission(permissions, ObjectPermissionSlugs.METRICS)
		) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		if (!getCanView(permissions, ObjectPermissionSlugs.METRICS)) {
			return render403(navProps);
		}

		return renderAuthedSection(<ShareMetrics />, navProps);
	};

	return (
		<PersistGate loading={null} persistor={persistor}>
			<Router history={history}>
				<div className="app">
					<Switch>
						<Route exact path="/" render={renderRoot} />
						<Route path="/login" render={renderLogin} />
						<Route path="/password-reset" render={renderPasswordReset} />
						<Route path="/setPassword" render={renderSetPassword} />
						<Route
							exact
							path="/account/:accountId/themes"
							render={renderThemes}
						/>
						<Route
							path="/account/:accountId/themes/:themeId"
							render={renderTheme}
						/>
						<Route
							exact
							path="/account/:accountId/allContent/managedContent"
							render={renderManagedContent}
						/>
						<Route
							exact
							path="/account/:accountId/allContent/importedContent"
							render={renderImportedContent}
						/>
						<Route
							path="/account/:accountId/allContent/importedContent/:externalObjectId"
							render={renderImportedItems}
						/>
						<Route
							exact
							path="/account/:accountId/allContent/playlists"
							render={renderPlaylists}
						/>
						<Route
							path="/account/:accountId/allContent/playlists/:playlistId"
							render={renderPlaylist}
						/>
						<Route
							exact
							path="/account/:accountId/shows"
							render={renderShows}
						/>
						<Route exact path="/account/:accountId" render={renderRoot} />
						<Route
							path={[
								'/account/:accountId/shows/:showId/episodes/:episodeId/promotions/:promotionId',
								'/account/:accountId/shows/:showId/episodes/:episodeId',
							]}
							render={renderEpisodeDetail}
						/>
						<Route
							path="/account/:accountId/shows/:showId/promotions/:promotionId"
							render={renderCollectionPromotion}
						/>
						<Route
							path="/account/:accountId/shows/:showId/promotions"
							render={renderCollectionPromotionList}
						/>
						<Route
							path="/account/:accountId/shows/:showId/episodes"
							render={renderEpisodes}
						/>
						<Route
							path="/account/:accountId/shows/:showId/metrics"
							render={renderShowMetrics}
						/>
						<Route
							path="/account/:accountId/shows/:showId/settings"
							render={renderShowSettings}
						/>
						<Route
							path="/account/:accountId/shows/:showId/admin"
							render={renderShowAdmin}
						/>
						<Route
							path="/account/:accountId/shows/:showId/migrate"
							render={renderMigrate}
						/>
						<Route path="/account/:accountId/users" render={renderUsers} />
						<Route path="/account/:accountId/apiKeys" render={renderApiKeys} />
						<Route
							path="/account/:accountId/ssoSettings"
							render={renderSsoSettings}
						/>
						<Route
							path="/account/:accountId/accountIntegrations"
							render={renderAccountIntegrations}
						/>
						<Route path="/oauthHub" render={renderOAuthHub} />
						<Route path="/oauthYoutube" render={renderOAuthYoutube} />
						<Route path="/authorize" render={renderAuthorize} />
						<Route
							path="/account/:accountId/metrics"
							render={renderAccountMetrics}
						/>
						<Route
							path="/account/:accountId/insights"
							render={renderInsights}
						/>
						<Route
							path="/account/:accountId/company/:companyId"
							render={renderCompanyProfile}
						/>
						<Route
							exact
							path="/account/:accountId/shares"
							render={renderShareCenter}
						/>
						<Route
							exact
							path="/account/:accountId/shares/:shareId/metrics"
							render={renderShareMetrics}
						/>
						<Route render={render404} />
					</Switch>
				</div>
			</Router>
		</PersistGate>
	);
};

const mapStateToProps = (state) => ({
	isAuthed: state.auth.isAuthed,
	authProfile: state.auth.profile,
	location: state.router.location,
	userMetadata: state.auth.metadata || {},
	selectedAccountId: state.accounts.selectedAccountId,
	selectedAccount: getSelectedAccount(state),
	accounts: state.accounts.accounts,
	permissions: state.auth.permissions,
});

export default withRouter(withApollo(connect(mapStateToProps)(App)));
