import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';

import queries from 'services/graphql/queries';
import MetricCircles from 'components/shared/metricCircles';

const KnownContacts = (props) => {
	const { variables } = props;

	const [hover, setHover] = useState(null);

	const { data, loading } = useQuery(queries.getKnownContactCount, {
		variables,
		fetchPolicy: 'cache-and-network',
	});

	const { getKnownContactCount: values = [] } = data || {};

	return (
		<MetricCircles
			total={values.total_count || 0}
			label="Total Unique Contacts"
			loading={loading}
			title="Known Contacts"
			showIcon={false}
		>
			<MetricCircles.Circle
				value={values.known_count}
				key="known"
				active={hover === 'known'}
				onMouseEnter={() => setHover('known')}
				onMouseLeave={() => setHover(null)}
				label="Known"
				showIcon={false}
			/>
			<MetricCircles.Circle
				value={values.unknown_count}
				key="unknown"
				active={hover === 'unknown'}
				onMouseEnter={() => setHover('unknown')}
				onMouseLeave={() => setHover(null)}
				label="Unknown"
				showIcon={false}
			/>
		</MetricCircles>
	);
};

export default KnownContacts;
