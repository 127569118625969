import React from 'react';
import ReactDOM from 'react-dom';
import CastedModal from 'components/shared/modal';

import { getApolloClient } from 'services/graphql/apolloClient';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

const ThemeUnsavedChanges = (props, callback) => {
    const {
        title = 'Unsaved Changes',
        messageText = 'You have unsaved changes. Are you sure you want to leave?',
        deleteThemeId = null,
    } = props;

    const container = document.createElement('div');
    container.setAttribute('casted-confirmation-navigation', '');
    document.body.appendChild(container);

    const closeModal = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
        callback(callbackState);
    };

    const dialogOptions = [
        {
            text: 'Cancel',
            variant: 'cancel',
            onClick: () => closeModal(false)
        },
        {
            text: 'Leave',
            variant: 'danger',
            onClick: async () => {
                if (deleteThemeId) {
                    await getApolloClient().mutate({
                        mutation: mutations.deleteThemeById,
                        variables: {
                            themeId: deleteThemeId,
                        },
                    });
                    trackEvent('delete-theme');
                }

                closeModal(true);
            }
        }
    ];

    ReactDOM.render(
        <CastedModal
            show={true}
            closeButton={false}
            title={title}
            dialogOptions={dialogOptions}
        >{messageText}</CastedModal>,
        container
    );
};

export default ThemeUnsavedChanges;