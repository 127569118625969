import { getApolloClient } from 'services/graphql/apolloClient';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';

const ApolloClient = getApolloClient();

class API {
	static getMyAccounts = async () => {
		try {
			const res = await ApolloClient.query({
				query: queries.myAccounts,
				fetchPolicy: 'no-cache',
			});
			const {
				data: { myAccounts: accounts },
			} = res;

			return accounts || [];
		} catch (e) {
			throw e;
		}
	};

	static getMyAccountRole = async (accountId) => {
		try {
			const res = await ApolloClient.query({
				query: queries.myAccountRole,
				fetchPolicy: 'no-cache',
				variables: { accountId },
			});
			const {
				data: { myAccountRole },
			} = res;

			return myAccountRole || {};
		} catch (e) {
			throw e;
		}
	};

	static getUserRole = async (accountId, userId) => {
		try {
			const res = await ApolloClient.query({
				query: queries.userRole,
				variables: { accountId, userId },
			});

			const {
				data: { userRole },
			} = res;

			return userRole || {};
		} catch (e) {
			throw e;
		}
	};

	static getMyCollectionPermissionsRoles = async (accountId, userId) => {
		try {
			const res = await ApolloClient.query({
				query: queries.getMyCollectionPermissionsRoles,
				variables: { accountId, userId },
			});

			const {
				data: { getMyCollectionPermissionsRoles },
			} = res;

			return getMyCollectionPermissionsRoles || {};
		} catch (e) {
			throw e;
		}
	};

	static getAccountsByDomain = async (domain) => {
		try {
			const res = await ApolloClient.query({
				query: queries.accountsByDomain,
				variables: { domain },
			});
			const {
				data: { accounts },
			} = res;

			return accounts || [];
		} catch (e) {
			throw e;
		}
	};

	static getSignedUrls = async ({ filename, accountId, contentType }) => {
		try {
			const res = await ApolloClient.query({
				query: queries.getSignedUrls,
				variables: { accountId, filename, contentType },
			});
			const {
				data: { signedUrls },
			} = res;

			return signedUrls || {};
		} catch (e) {
			throw e;
		}
	};

	static getTranscriptSignedUrls = async ({
		filename,
		accountId,
		contentType,
	}) => {
		try {
			const res = await ApolloClient.query({
				query: queries.getTranscriptSignedUrls,
				variables: { accountId, filename, contentType },
			});
			const {
				data: { transcriptSignedUrls },
			} = res;

			return transcriptSignedUrls || {};
		} catch (e) {
			throw e;
		}
	};

	static uploadPublicFile = async ({ dataURI, fileExt }) => {
		try {
			const res = await ApolloClient.mutate({
				mutation: mutations.uploadPublicFile,
				variables: { dataURI, fileExt },
			});
			const {
				data: { uploadPublicFile },
			} = res;

			// returns a URL in GCS to access the public file
			return uploadPublicFile || '';
		} catch (e) {
			throw e;
		}
	};

	static podcastCount = async (accountId) => {
		try {
			const res = await ApolloClient.query({
				query: queries.podcastCount,
				variables: { accountId },
			});
			const {
				data: { podcastCount },
			} = res;

			return podcastCount || 0;
		} catch (e) {
			throw e;
		}
	};

	static episodeCount = async (accountId) => {
		try {
			const res = await ApolloClient.query({
				query: queries.episodeCount,
				variables: { accountId },
			});
			const {
				data: { episodeCount },
			} = res;

			return episodeCount || 0;
		} catch (e) {
			throw e;
		}
	};

	static totalEpisodeDuration = async (accountId) => {
		try {
			const res = await ApolloClient.query({
				query: queries.totalEpisodeDuration,
				variables: { accountId },
			});
			const {
				data: { totalEpisodeDuration },
			} = res;

			return totalEpisodeDuration || 0;
		} catch (e) {
			throw e;
		}
	};

	static getThemeKeywordLimit = async () => {
		try {
			const res = await ApolloClient.query({
				query: queries.getThemeKeywordLimit,
			});
			const {
				data: { getThemeKeywordLimit },
			} = res;

			return getThemeKeywordLimit || 0;
		} catch (e) {
			throw e;
		}
	};
}

export default API;
