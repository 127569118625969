import React, { useEffect, useRef, useState } from 'react';
import { Sorting } from '@elastic/react-search-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.scss';

const SortFilter = (props) => {
	const { label, sortOptions } = props;

	const selectRef = useRef(null);

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(sortOptions[0]);

	// Detects clicks outside select in order to close select list
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				selectRef.current &&
				!selectRef.current.contains(event.target)
			) {
				if (isDropdownOpen) {
					setIsDropdownOpen(false);
				}
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [selectRef, isDropdownOpen]);

	const onOptionClick = (option) => {
		setSelectedOption(option);
		setIsDropdownOpen(false);
	};

	return (
		<Sorting
			label={label}
			sortOptions={sortOptions}
			className={styles.selectDropdown}
			view={({ className, label, onChange, options, value }) => {
				const onClick = (option) => {
					onOptionClick(option);
					onChange(option.value);
				};

				return (
					<fieldset className={styles.sortFilter}>
						<h3>{label}</h3>

						<div ref={selectRef} className={styles.selectDropdown}>
							<div
								className={styles.selectedValue}
								onClick={() => setIsDropdownOpen(true)}
							>
								{selectedOption.name || selectedOption.label}{' '}
								<FontAwesomeIcon icon={faCaretDown} size="sm" />
							</div>
							<ul
								className={`${styles.selectOptions} ${
									!isDropdownOpen ? styles.hide : ''
								}`}
							>
								{options &&
									options.map((option, key) => {
										return (
											<li
												key={key}
												onClick={() => onClick(option)}
											>
												{option.label}
											</li>
										);
									})}
							</ul>
						</div>
					</fieldset>
				);
			}}
		/>
	);
};

export default SortFilter;
