import React from 'react'

const LoadingLineChart = () => {
  return (
    <svg 
     	className="stacked-area-load-state--css-draw" 
     	xmlns="http://www.w3.org/2000/svg" 
    	viewBox="0 0 711 325"
      style={{display: "block", width: "50%", margin: "1em auto 0"}}
    >
      <polyline 
  	  	id="chart-line" 
    		className="chart-line--static" 
    		style={{stroke: "#EFF2F5", strokeWidth: 4, fill: "none"}}
    		points="8.8,175.8 62.4,237.7 116.1,184.7 169.7,175.8 223.3,57 277,176.8 330.6,176.8 384.3,122.5 437.9,176.8 491.6,176.8 545.2,218.4 598.8,122.5 652.5,184.7 706.1,135.1 "
    	/>
     <polyline 
  	  	id="chart-line" 
    		className="chart-line" 
    		points="8.8,175.8 62.4,237.7 116.1,184.7 169.7,175.8 223.3,57 277,176.8 330.6,176.8 384.3,122.5 437.9,176.8 491.6,176.8 545.2,218.4 598.8,122.5 652.5,184.7 706.1,135.1 "
    	/>
    </svg>
  )
}

export default LoadingLineChart