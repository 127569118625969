import React, { useRef } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';
import { trackEvent } from 'services/vitally';
import { dispatch } from 'store/store';
import {
	setAudiogramStep,
	AudiogramStepsEnum,
} from 'store/reducers/ui/modals/clipShareModal';

import ReactPlayer from 'react-player';
import CopyToClipboardButton from 'components/shared/copyToClipboardButton';
import { Row, Col } from 'react-bootstrap';
import { FaAngleLeft } from 'react-icons/fa';

import Button from 'components/shared/button';

const PreviewAudiogram = (props) => {
	const {
		podcastName = '',
		episode = {},
		link = '',
		audiogramSignedUrl,
		selectedClip,
	} = props;

	const { name: clipName } = selectedClip || {};

	/** Refs **/
	const copyRef = useRef(null);

	const downloadVideo = () => {
		trackEvent('share', {
			itemType: 'clip',
			shareType: 'audiogram',
			itemName: episode.name,
			collectionName: podcastName,
			clipName,
		});

		axios({
			url: audiogramSignedUrl,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${clipName}.mp4`);
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className="create-audiogram audiogram-preview">
			<div className="preview-instructions">
				<h4 className="preview-title">Shareable Audiogram</h4>
				<p>Download this video to share on the web.</p>
				<p>
					<strong>Note</strong> Be sure to also copy and share the
					link to bring viewers back to the episode page and capture
					click-through behavior.
				</p>
			</div>

			<ReactPlayer
				className="audiogram-player"
				url={audiogramSignedUrl}
				controls={true}
				width="1280"
				height="720"
			/>

			<Row className="audiogram-actions">
				<Col md={7}>
					<Button
						className="audiogram-start-over"
						variant="link-no-href"
						onClick={() => {
							dispatch(setAudiogramStep(AudiogramStepsEnum.TYPE));
						}}
					>
						<FaAngleLeft /> Create Different Audiogram
					</Button>
				</Col>
				<Col md={5}>
					<Button
						className="audiogram-download"
						variant="success"
						onClick={downloadVideo}
						disabled={!audiogramSignedUrl}
					>
						Download Video
					</Button>
				</Col>
			</Row>

			<div className="share-copy">
				<input
					ref={copyRef}
					className="share-copy--text"
					value={link}
					readOnly
				/>
				<CopyToClipboardButton
					className="share-copy--button"
					copyRef={copyRef}
					btnText="Copy Link"
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedClip: state.transcript.selectedClip,
});

export default connect(mapStateToProps)(PreviewAudiogram);
