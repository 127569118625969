import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { getPercentage } from 'utils';
import MetricEmptyState from 'components/shared/metricEmptyState';

const PercentageList = (props) => {
	const {
		values = [],
		total = null,
		title = '',
		className = null,
		loading = false,
	} = props;

	if (!loading && values.length < 1) {
		return <MetricEmptyState metricName={title} />;
	}

	return (
		<Card className={`metric-percentage-list ${className}`}>
			<Card.Body>
				<Container>
					<Row>
						<Col className="d-flex flex-row justify-content-between">
							<h5 className="column-title">{title}</h5>
							<h5 className="column-title">TOTAL</h5>
						</Col>
					</Row>

					{loading
						? Array(5)
								.fill(0)
								.map((i, k) => (
									<Row className="list-row loading" key={k}>
										<Col>
											<Spinner animation="border" variant="info" />
										</Col>
									</Row>
								))
						: values
								.sort((a, b) => b.value - a.value)
								.map((item) => {
									const { label, key, icon, value } = item;
									return (
										<Row className="list-row" key={key}>
											<Col className="d-flex flex-row justify-content-between">
												<CSSTransition
													timeout={200}
													appear={true}
													in={true}
													classNames="fill"
												>
													<div
														className="list-row--percentage-fill"
														style={{
															width: getPercentage(value, total, 0) + '%',
														}}
													></div>
												</CSSTransition>
												<div className="d-flex flex-row list-row--title align-items-center">
													{icon}
													<p className="list-row--text">{label}</p>
												</div>
												<p className="list-row--text align-self-center list-row--percentage">
													{value}
												</p>
											</Col>
										</Row>
									);
								})}
				</Container>
			</Card.Body>
		</Card>
	);
};

export default PercentageList;
