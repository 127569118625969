import React from 'react';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import {
	setSelection,
	setHasSelection,
	setIsCreatingClip,
	setIsEditingClip,
	setSelectedClip,
} from 'store/reducers/data/transcript';

// External components
import { Card } from 'react-bootstrap';

// Internal components
import { CLIP_TYPES } from 'components/constants';
import ClipCard from './clipCard';
import DeleteClipModal from './clipModals/deleteClip';
import EditClipCard from './clipModals/editClip';
import CreateClipCard from './clipModals/createClip';
import ShareClipModal from './clipModals/shareClip';

const ClipList = (props) => {
	const {
		accountId = 0,
		podcastSlug = '',
		podcastName = '',
		podcastThumbnail,
		episode = {},
		customDomain,
		canEdit,
		canCreateTakeaway,
		getClosestNode,
		transcriptReady,
		highlightSelection,
		clips,
		suggestedClips = [],
		inTranscript,
		onPauseClip,
		onPlayClip,
		onClipHover,
		onClipHoverLeave,
		updateRegionClass,
		handleCreateClipCardClose,
		linkedClip,
		linkedTranscript,
		setLinkedClip,
		collectionType,
		disableLinkSharing,
		/* Redux props start */
		selection,
		hasSelection,
		isCreatingClip,
		isEditingClip,
		selectedClip,
		showSuggestions,
		episodesUI,
		/* Redux props end */
	} = props;

	const { id: episodeId, duration: episodeDuration = 0 } = episode;

	let allClips = clips
		.concat(suggestedClips)
		.sort((a, b) => a.startTime - b.startTime);
	let clipList = [];

	const onCloseEditCard = () => {
		updateRegionClass(selectedClip.id, 'hide', true);
		dispatch(setIsEditingClip(false));
		dispatch(setHasSelection(false));
		dispatch(setIsCreatingClip(false));
		dispatch(setSelectedClip({}));
		dispatch(
			setSelection({
				startTime: null,
				endTime: null,
				startIndex: null,
				endIndex: null,
			})
		);
	};

	const addOffset = (index) => {
		let clipClone = {};
		if (Number.isInteger(index)) {
			clipClone = { ...clips[index] };
		} else {
			const clipIndex = clips.indexOf(selectedClip);
			clipClone = { ...clips[clipIndex] };
		}
		let previousClip = index === 0 ? null : clipList[index - 1];
		if (inTranscript) {
			let startNode = getClosestNode(clipClone.startTime);
			let endNode = getClosestNode(clipClone.endTime);
			let topOffset;

			if (startNode) {
				topOffset = startNode.offsetTop;
			} else if (endNode) {
				topOffset = endNode.offsetTop - 250;
			}

			if (previousClip && topOffset <= previousClip.top + 175) {
				topOffset = previousClip.top + 150;

				if (previousClip.type === CLIP_TYPES.takeaway) {
					topOffset += 25;
				}
			}

			return {
				top: topOffset + 'px',
				position: 'absolute',
			};
		}
	};

	const addCreateOffset = (inTranscript) => {
		if (inTranscript) {
			let startNode = getClosestNode(selection.startTime);
			let endNode = getClosestNode(selection.endTime);
			let topOffset;
			if (startNode) {
				topOffset = startNode.offsetTop;
			} else if (endNode) {
				topOffset = endNode.offsetTop - 250;
			}
			return {
				top: topOffset + 'px',
				position: 'absolute',
			};
		}
	};

	const renderClipList = () => {
		if (
			isEditingClip &&
			canEdit &&
			(selectedClip.type === CLIP_TYPES.promo || canCreateTakeaway)
		) {
			return (
				<div
					className="clip-card active editing"
					style={addOffset(selectedClip)}
				>
					<EditClipCard
						onClose={onCloseEditCard}
						clip={selectedClip}
						canCreateTakeaway={canCreateTakeaway}
						collectionType={collectionType}
					/>
				</div>
			);
		}

		if (isCreatingClip && hasSelection && canEdit) {
			return (
				<div
					className={`clip-card active ${
						isCreatingClip ? 'visible' : 'invisible'
					}`}
					style={addCreateOffset(inTranscript)}
				>
					<CreateClipCard
						onClose={() => {
							handleCreateClipCardClose();
						}}
						episodeId={episodeId}
						selection={selection}
						canCreateTakeaway={canCreateTakeaway}
						collectionType={collectionType}
					/>
				</div>
			);
		}

		if (!clips.length && !showSuggestions) {
			return (
				<Card className="clip-list--no-clips">
					<Card.Body>
						<p>
							Highlight an area in the transcript or along the waveform to
							create a clip. Your clips will show up here.
						</p>
					</Card.Body>
				</Card>
			);
		}

		return (
			<div className="clip-list">
				{allClips.map((clip, i) => {
					return (
						<div key={clip.id}>
							<ClipCard
								clip={clip}
								canEdit={canEdit}
								getClosestNode={getClosestNode}
								transcriptReady={transcriptReady}
								highlightSelection={highlightSelection}
								onPauseClip={onPauseClip}
								onPlayClip={onPlayClip}
								onClipHover={onClipHover}
								onClipHoverLeave={onClipHoverLeave}
								updateRegionClass={updateRegionClass}
								addedOffset={addOffset(i)}
								isSelected={linkedClip && clip.id === linkedClip.id}
								linkedClip={linkedClip}
								linkedTranscript={linkedTranscript}
								setLinkedClip={setLinkedClip}
							/>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<>
			{episodesUI.studio.showDrawer && (
				<h4 className="drawer-header">All Clips</h4>
			)}
			{renderClipList()}
			{inTranscript && <DeleteClipModal />}
			{inTranscript && (
				<ShareClipModal
					accountId={accountId}
					podcastName={podcastName}
					episode={episode}
					customDomain={customDomain}
					podcastSlug={podcastSlug}
					podcastThumbnail={podcastThumbnail}
					disableLinkSharing={disableLinkSharing}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	selection: state.transcript.selection,
	hasSelection: state.transcript.hasSelection,
	isCreatingClip: state.transcript.isCreatingClip,
	isEditingClip: state.transcript.isEditingClip,
	selectedClip: state.transcript.selectedClip,
	showSuggestions: state.transcript.showSuggestions,
	episodesUI: state.ui.episodes,
});

export default connect(mapStateToProps)(ClipList);
