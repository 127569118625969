import React from 'react';

import { DropdownButton } from 'react-bootstrap';

const CastedDropdownButton = (props) => {
	// Here's a link to react boostrap's DropdownButton API docs:
	// https://react-bootstrap.github.io/components/dropdowns/#dropdown-button-props
	const {
		id,
		title,
		children,
		displayDropArrow = true,
		classType = 'outline-dark',
		size = '',
		className = '',
		disabled = false,
		onToggle = () => {},
	} = props;

	let btnClassType;

	switch (classType) {
		case 'light-pill':
			btnClassType = 'btn-outline-light';
			break;
		case 'green':
			btnClassType = 'btn-green';
			break;
		case 'round':
			btnClassType = 'btn-round';
			break;
		case 'plain-arrow':
			btnClassType = 'btn-plain-arrow';
			break;
		case 'outline-dark':
		default:
			btnClassType = 'btn-outline-dark';
			break;
	}

	return (
		<DropdownButton
			id={id}
			title={title}
			variant={btnClassType === 'btn-green' ? 'success' : 'flat'}
			className={`
				casted-dropdown-button
				${className}
        ${!displayDropArrow ? 'no-arrow' : ''}
        ${btnClassType} 
				${size}
			`}
			disabled={disabled}
			onToggle={onToggle}
		>
			{children}
		</DropdownButton>
	);
};

export default CastedDropdownButton;
