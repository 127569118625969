exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".Mxao5fiyLJbGe--JJtVvN{background:black}.iiNP-9nhZWHSzo4pmQLjk{display:inline-flex;align-items:flex-start;width:100%}._27_v6Y9PMZDRN5D7dnzm0d{text-align:center;width:100%;margin:auto;padding:1em;position:relative}.I-rcBeRSMpuXgw8WBTYGV{color:white;line-height:1.2em;width:60%;margin-top:0;margin-bottom:0.5em;font-size:1.2em;overflow:hidden;display:inline-block;white-space:nowrap;text-overflow:ellipsis}@media (max-width: 768px){.I-rcBeRSMpuXgw8WBTYGV{text-align:center;padding-top:1em;white-space:normal}}\n", "",{"version":3,"sources":["/home/runner/work/react-transcript-editor/react-transcript-editor/packages/components/media-player/index.module.scss"],"names":[],"mappings":"AAAA,uBACE,gBAAiB,CAClB,uBAGC,mBAAoB,CACpB,sBAAuB,CACvB,UAAW,CACZ,yBAGC,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,WAAY,CACZ,iBAAkB,CAGnB,uBAGC,WAAY,CACZ,iBAAkB,CAClB,SAAU,CACV,YAAa,CACb,mBAAoB,CACpB,eAAgB,CAChB,eAAgB,CAChB,oBAAqB,CACrB,kBAAmB,CACnB,sBAAuB,CACxB,0BAIC,uBACE,iBAAkB,CAClB,eAAgB,CAChB,kBAAmB,CACpB","file":"index.module.scss","sourcesContent":[".topSection {\n  background: black;\n}\n\n.playerSection {\n  display: inline-flex;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.controlsSection {\n  text-align: center;\n  width: 100%;\n  margin: auto;\n  padding: 1em;\n  position: relative;\n  // overflow-x: auto;\n  // overflow-y: auto;\n}\n\n.title {\n  color: white;\n  line-height: 1.2em;\n  width: 60%;\n  margin-top: 0;\n  margin-bottom: 0.5em;\n  font-size: 1.2em;\n  overflow: hidden;\n  display: inline-block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n@media (max-width: 768px) {\n\n  .title {\n    text-align: center;\n    padding-top: 1em;\n    white-space: normal;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"topSection": "Mxao5fiyLJbGe--JJtVvN",
	"playerSection": "iiNP-9nhZWHSzo4pmQLjk",
	"controlsSection": "_27_v6Y9PMZDRN5D7dnzm0d",
	"title": "I-rcBeRSMpuXgw8WBTYGV"
};