import React, { useState } from 'react';

// Redux
import { connect } from 'react-redux';

// External components
import { Card, Dropdown } from 'react-bootstrap';

// Internal components
import DropDownButton from 'components/shared/dropdownButton';
import ThemeDrawerCard from './themeDrawerCard';

const ThemeDrawer = (props) => {
	const {
		/* Redux props start */
		themeMatches,
		/* Redux props end */
	} = props;

	// Default value for themes dropdown
	const allThemes = {
		id: null,
		name: 'All Themes',
	};

	const [activeTheme, setActiveTheme] = useState(allThemes);

	if (!themeMatches?.length) {
		return (
			<Card className="clip-list--no-clips">
				<Card.Body>
					<p>No related themes.</p>
				</Card.Body>
			</Card>
		);
	}

	let themes = [];

	// Get unique theme id/name to display in themes dropdown
	themeMatches.map((themeMatch) => {
		if (!themes.find((theme) => theme.id === themeMatch.themeId)) {
			themes.push({ id: themeMatch.themeId, name: themeMatch.theme.name });
			return true;
		}
	});

	return (
		<div className="theme-drawer">
			<DropDownButton
				title={activeTheme.name}
				className="theme-dropdown"
				classType="plain-arrow"
				size="lg"
			>
				<Dropdown.Item
					onClick={() => setActiveTheme(allThemes)}
					active={activeTheme.id === null ? true : false}
				>
					All Themes
				</Dropdown.Item>
				{themes.map((theme, i) => (
					<Dropdown.Item
						key={i}
						onClick={() => setActiveTheme(theme)}
						active={activeTheme.id === theme.id ? true : false}
					>
						{theme.name}
					</Dropdown.Item>
				))}
			</DropDownButton>
			<div className="theme-drawer-cards">
				{themeMatches
					.filter((themeMatch) => {
						// Return all themeMatches if "All Themes" is selected
						return (
							activeTheme.id === null || themeMatch.themeId === activeTheme.id
						);
					})
					.sort((a, b) => {
						// Order matches based on transcript group number
						// When upserting ElasticSearch transcript documents, we append
						// the group number to the end of the document id.
						const documentA = a.documentId.split('-');
						const documentB = b.documentId.split('-');

						const groupA = parseInt(documentA[documentA.length - 1], 10);
						const groupB = parseInt(documentB[documentB.length - 1], 10);

						if (groupA < groupB) {
							return -1;
						}
						if (groupA > groupB) {
							return 1;
						}
						return 0;
					})
					.map((themeMatch, i) => {
						return <ThemeDrawerCard key={i} themeMatch={themeMatch} />;
					})}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	themeMatches: state.episode.episode.episodeThemeMatches,
});

export default connect(mapStateToProps)(ThemeDrawer);
