import React, { useRef } from 'react';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchProvider, WithSearch, Facet } from '@elastic/react-search-ui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import { RECORD_TYPES } from '../constants';

import {
	CheckboxList,
	TagListFilter,
	ClipTypeFilter,
	ContentTypeFilter,
	DateFilter,
	Pagination,
} from '../base';

import styles from './styles.scss';

const SearchAIO = (props) => {
	const {
		searchKey = '',
		engineName = '',
		endpointBase = '',
		history = null,
		accountId = '',
		onResultClick = (clipId, startTime, endTime) => {},
		showClose = false,
		onClose = () => {},
		recordTypes = [
			RECORD_TYPES.EPISODE_TRANSCRIPT,
			RECORD_TYPES.EPISODE,
			RECORD_TYPES.CLIP,
			RECORD_TYPES.SHARE,
		],
		// includeArray determines what collections users have access to.
		// A null value will return all collections.
		// An empty array will return no collections.
		includeArray = null,
	} = props;

	const connector = new AppSearchAPIConnector({
		searchKey: searchKey,
		engineName: engineName,
		endpointBase: endpointBase,
		cacheResponses: false,
	});

	// Setup spelled out months
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	let loadingStartTime = new Date();
	let resultTime = 0;
	const resultViewTopElm = useRef(null);

	// Create Now
	let currentDate = new Date();
	const todayDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T23:59:59.999Z';

	// Create Now - 1 week
	currentDate = new Date(currentDate);
	currentDate.setDate(currentDate.getDate() - 7);
	const lastWeekDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T00:00:00.000Z';

	// Create Now - 1 month
	currentDate = new Date(currentDate);
	currentDate.setMonth(currentDate.getMonth() - 1);
	const lastMonthDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T00:00:00.000Z';

	// Create Now - 1 year
	currentDate = new Date(currentDate);
	currentDate.setFullYear(currentDate.getFullYear() - 1);
	const lastYearDate =
		currentDate.getFullYear() +
		'-' +
		('0' + (currentDate.getMonth() + 1)).slice(-2) +
		'-' +
		('0' + currentDate.getDate()).slice(-2) +
		'T00:00:00.000Z';

	// Send to corresponding episode on result click
	const resultClick = (
		recordType,
		accountId,
		podcastId,
		episodeId,
		startTime,
		endTime,
		clipId
	) => {
		if (history && recordType && accountId && podcastId && episodeId) {
			switch (recordType) {
				case 'clip':
				case 'episode_transcript':
					history.push(
						`/account/${accountId}/shows/${podcastId}/episodes/${episodeId}/clips`
					);
					break;
				case 'episode':
				default:
					history.push(
						`/account/${accountId}/shows/${podcastId}/episodes/${episodeId}/info`
					);
					break;
			}
			onResultClick(clipId, startTime, endTime);
			return true;
		}
		return false;
	};

	// accountId needs to be a string in order to be used when querying
	const stringAccountId = new String(accountId);

	// Scrolls user to top of results when paging
	const scrollToTop = () => resultViewTopElm.current.scrollIntoView();

	// Don't let those hackers have access to other accounts
	// Hard coding the user's account_id
	const resetUserAccountFilter = (filters) => {
		let updatedFilters = [];

		if (filters.some((filter) => filter.field === 'account_id')) {
			filters.map((filter) => {
				if (
					filter.field === 'account_id' &&
					filter.values[0] !== stringAccountId
				) {
					const newFilter = {
						...filter,
						values: [stringAccountId],
					};
					updatedFilters = [...filters, newFilter];
				}
			});
		} else {
			const newFilter = {
				field: 'account_id',
				type: 'all',
				values: [stringAccountId],
			};
			updatedFilters = [...filters, newFilter];
		}

		return updatedFilters;
	};

	return (
		<div className={styles.searchContainer}>
			<SearchProvider
				config={{
					apiConnector: connector,
					alwaysSearchOnInitialLoad: true,
					initialState: {
						resultsPerPage: 20,
					},
					onSearch: (state, queryConfig, next) => {
						// Alter filters object to only include podcast_ids that the user
						// has access to
						const includeFilter = {
							field: 'podcast_id',
							type: 'any',
							values: [includeArray],
						};

						const recordTypeFilters = {
							field: 'record_type',
							type: 'any',
							values: recordTypes,
						};

						let filters = resetUserAccountFilter(state.filters).concat(
							recordTypeFilters
						);

						if (includeArray) {
							filters = filters.concat(includeFilter);
						}

						const returnState = {
							...state,
							filters,
						};
						return next(returnState, queryConfig);
					},
					searchQuery: {
						search_fields: {
							episode_name: {
								weight: 10,
							},
							episode_description: {
								weight: 5,
							},
							transcript: {
								weight: 1,
							},
							share_title: {
								weight: 10,
							},
							share_description: {
								weight: 5,
							},
						},
						result_fields: {
							record_type: {
								raw: {},
							},
							account_id: {
								raw: {},
							},
							podcast_id: {
								raw: {},
							},
							episode_id: {
								raw: {},
							},
							episode_name: {
								snippet: {
									size: 300,
									fallback: true,
								},
							},
							episode_description: {
								snippet: {
									size: 300,
									fallback: true,
								},
							},
							episode_episode_number: {
								raw: {},
							},
							episode_season: {
								raw: {},
							},
							transcript: {
								snippet: {
									size: 300,
									fallback: true,
								},
							},
							episode_published_at: {
								raw: {},
							},
							clip_id: {
								raw: {},
							},
							clip_name: {
								snippet: {
									size: 300,
									fallback: true,
								},
							},
							episode_thumbnail: {
								raw: {},
							},
							episode_object: {
								raw: {},
							},
							start_time: {
								raw: {},
							},
							end_time: {
								raw: {},
							},
							share_id: {
								raw: {},
							},
							share_share_slug: {
								raw: {},
							},
							share_share_type: {
								raw: {},
							},
							share_content_type: {
								raw: {},
							},
							share_title: {
								snippet: {
									size: 300,
									fallback: true,
								},
							},
							share_description: {
								snippet: {
									size: 300,
									fallback: true,
								},
							},
							share_destination: {
								snippet: {
									size: 300,
									fallback: true,
								},
							},
							share_created_by: {
								raw: {},
							},
							share_updated_at: {
								raw: {},
							},
							share_created_at: {
								raw: {},
							},
							share_status: {
								raw: {},
							},
						},
						disjunctiveFacets: [
							'podcast_name',
							'record_type',
							'clip_type',
							'clip_has_audiogram',
							'episode_keywords',
							'clip_keywords',
							'episode_published_at',
							'share_share_type',
							'share_status',
						],
						facets: {
							podcast_name: {
								type: 'value',
								size: 100,
							},
							record_type: {
								type: 'value',
								size: 100,
							},
							clip_type: {
								type: 'value',
							},
							clip_has_audiogram: {
								type: 'value',
							},
							episode_keywords: {
								type: 'value',
							},
							clip_keywords: {
								type: 'value',
							},
							episode_published_at: {
								type: 'range',
								ranges: [
									{ to: todayDate, name: 'Any Date' },
									{
										from: lastWeekDate,
										to: todayDate,
										name: 'Past Week',
									},
									{
										from: lastMonthDate,
										to: todayDate,
										name: 'Past Month',
									},
									{
										from: lastYearDate,
										to: todayDate,
										name: 'Past Year',
									},
								],
							},
							share_share_type: {
								type: 'value',
							},
							share_status: {
								type: 'value',
							},
						},
					},
				}}
			>
				<WithSearch
					mapContextToProps={({
						current,
						facets,
						isLoading,
						results,
						resultsPerPage,
						searchTerm,
						setCurrent,
						setSearchTerm,
						totalResults,
						reset,
					}) => ({
						current,
						facets,
						isLoading,
						results,
						resultsPerPage,
						searchTerm,
						setCurrent,
						setSearchTerm,
						totalResults,
						reset,
					})}
				>
					{({
						current,
						facets,
						isLoading,
						results,
						resultsPerPage,
						searchTerm,
						setCurrent,
						setSearchTerm,
						totalResults,
						reset,
					}) => {
						if (isLoading) {
							loadingStartTime = new Date();
						} else {
							let loadingEndTime = new Date();
							resultTime =
								loadingEndTime.getTime() - loadingStartTime.getTime();
						}

						return (
							<div className={styles.searchContainer}>
								<div className={styles.searchInputContainer}>
									<input
										className={styles.searchInput}
										value={searchTerm}
										onChange={(e) =>
											setSearchTerm(e.target.value, {
												shouldClearFilters: false,
											})
										}
										placeholder="Enter search terms here"
										autoFocus
									/>
									<button
										className={styles.clearButton}
										onClick={() =>
											setSearchTerm('', {
												shouldClearFilters: true,
											})
										}
									>
										Clear
									</button>
									{showClose && (
										<div
											className={styles.closeButton}
											onClick={() => {
												reset();
												onClose();
											}}
										>
											<FontAwesomeIcon icon={faTimes} size="sm" />
										</div>
									)}
								</div>
								<div className={styles.resultContainer}>
									<div className={styles.resultView}>
										<div
											ref={resultViewTopElm}
											className={styles.resultViewTop}
										></div>
										<h2 className={styles.resultMetrics}>
											{totalResults} results found in {resultTime}ms
										</h2>
										<div className={styles.resultList}>
											{results
												.filter((r) => r.record_type.raw !== 'clip_transcript')
												.map((r) => {
													// The following result values have either a `raw` or `snippet` property.
													// These properties are determined based on the specified values for the
													// repsctive fields in the `searchQuery` property for the search config
													let recordTypeName = '';
													const accountId = r.account_id
														? r.account_id.raw
														: null;
													const podcastId = r.podcast_id
														? r.podcast_id.raw
														: null;
													const episodeId = r.episode_id
														? r.episode_id.raw
														: null;
													const seasonNum = r.episode_season
														? r.episode_season.raw
														: null;
													const episodeNum = r.episode_episode_number
														? r.episode_episode_number.raw
														: null;
													const publishedDate =
														r.episode_published_at && r.episode_published_at.raw
															? new Date(r.episode_published_at.raw)
															: null;
													const startTime = r.start_time
														? r.start_time.raw
														: null;
													const endTime = r.end_time ? r.end_time.raw : null;
													const episodeThumbnail = r.episode_thumbnail
														? r.episode_thumbnail.raw
														: '';
													const clipId = r.clip_id ? r.clip_id.raw : null;
													let resultTitle = r.episode_name?.snippet
														? r.episode_name.snippet
														: '';
													let resultDescription = '';

													switch (r.record_type.raw) {
														case 'episode_transcript':
															recordTypeName = 'Found in transcript';
															if (r.episode_object) {
																const episodeObject = JSON.parse(
																	r.episode_object.raw
																);

																if (episodeObject.name) {
																	resultTitle = episodeObject.name;
																}
															}
															resultDescription = r.transcript?.snippet
																? r.transcript.snippet
																: '';

															break;
														case 'clip':
															recordTypeName = 'Podcast Clip';
															resultTitle = r.clip_name?.snippet
																? r.clip_name.snippet
																: '';
															resultTitle += r.episode_name?.snippet
																? ' - ' + r.episode_name.snippet
																: '';
															break;
														case 'episode':
														default:
															recordTypeName = 'Podcast Episode';
															resultDescription = r.episode_description
																? r.episode_description.snippet
																: '';
															break;
													}

													return (
														<div
															className={styles.result}
															key={r.id.raw}
															onClick={() =>
																resultClick(
																	r.record_type.raw,
																	accountId,
																	podcastId,
																	episodeId,
																	startTime,
																	endTime,
																	clipId
																)
															}
														>
															<div className={styles.resultNav}>
																<div>
																	<h4 className={styles.resultType}>
																		{recordTypeName}
																	</h4>
																	<span>Click to go to episode page</span>
																</div>
																<FontAwesomeIcon
																	icon={faArrowRight}
																	size="sm"
																/>
															</div>
															<div className={styles.resultContent}>
																<div className={styles.resultImage}>
																	<img src={episodeThumbnail} />
																</div>
																<div>
																	<h3
																		className={styles.resultTitle}
																		dangerouslySetInnerHTML={{
																			__html: resultTitle,
																		}}
																	></h3>
																	<div className={styles.resultInfo}>
																		{seasonNum && (
																			<span>Season {seasonNum} | </span>
																		)}
																		{episodeNum && (
																			<span>Episode {episodeNum} | </span>
																		)}
																		{publishedDate ? (
																			<span>
																				{months[publishedDate.getMonth()]}{' '}
																				{publishedDate.getDate()},{' '}
																				{publishedDate.getFullYear()}
																			</span>
																		) : (
																			<span>Draft</span>
																		)}
																	</div>
																	{resultDescription && (
																		<div
																			className={styles.resultText}
																			dangerouslySetInnerHTML={{
																				__html: resultDescription,
																			}}
																		></div>
																	)}
																</div>
															</div>
														</div>
													);
												})}
										</div>
										{totalResults > 0 && (
											<Pagination
												current={current}
												onChange={(newPage) => {
													setCurrent(newPage);
													scrollToTop();
												}}
												resultsPerPage={resultsPerPage}
												totalResults={totalResults}
											/>
										)}
									</div>
									<div className={styles.sidebarFilters}>
										<div className={styles.floatingContent}>
											<h4>Filter your search results.</h4>
											<CheckboxList
												fieldLabel="Collection"
												field="podcast_name"
											/>
											<TagListFilter
												field="episode_keywords"
												fieldLabel="Item Tags"
											/>
											<TagListFilter
												field="clip_keywords"
												fieldLabel="Clip Tags"
											/>
											<ContentTypeFilter
												field="record_type"
												fieldLabel="Content Type"
												allowedRecordTypes={recordTypes}
											/>
											<DateFilter
												fieldLabel="Item Date"
												field="episode_published_at"
											/>
											<ClipTypeFilter
												fieldLabel="Clip Type"
												field="clip_has_audiogram"
											/>
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</WithSearch>
			</SearchProvider>
		</div>
	);
};

export default SearchAIO;
