import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import MetricCard from 'components/shared/metricCard';

const CompanyProfileMetricCards = (props) => {
	const {
		accountId,
		companyId,
		dateRange,
		comparisonDateRange,
		collectionId = '',
		episodeId = '',
		themeId = '',
	} = props;

	const variables = {
		accountId: accountId,
		companyId: companyId,
		startDate: dateRange.start,
		endDate: dateRange.end,
		...(collectionId && { collectionId: collectionId }),
		...(episodeId && { episodeId: episodeId }),
		...(themeId && { themeId: themeId }),
	};

	const comparisonVariables = {
		accountId: accountId,
		companyId: companyId,
		startDate: comparisonDateRange.start,
		endDate: comparisonDateRange.end,
	};

	const [currentMetrics, setCurrentMetrics] = useState(null);
	const [comparisonMetrics, setComparisonMetrics] = useState(null);

	const { loading, error } = useQuery(
		queries.getCompanyProfileMetricsChartAndCard,
		{
			variables,
			fetchPolicy: 'cache-and-network',
			onCompleted: (data) => {
				if (data.getCompanyProfileMetricsChartAndCard.length > 0) {
					setCurrentMetrics(
						data.getCompanyProfileMetricsChartAndCard
					);
				} else {
					setCurrentMetrics(null);
				}
			},
		}
	);

	const { compLoading } = useQuery(
		queries.getCompanyProfileMetricsChartAndCard,
		{
			variables: comparisonVariables,
			fetchPolicy: 'cache-and-network',
			onCompleted: (data) => {
				data.getCompanyProfileMetricsChartAndCard.length > 0 &&
					setComparisonMetrics(
						data.getCompanyProfileMetricsChartAndCard.filter(
							(e) => e.date?.value //Filter out results with no date  - Bad data
						)
					);
			},
		}
	);

	const secondsToMinutes = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const text = minutes > 1 ? ' mins' : ' min';
		return minutes + text;
	};

	return (
		<div className="row mt-3">
			<div className="col-12 col-md-6 col-lg-3 mb-2">
				<MetricCard
					loading={loading || compLoading}
					label="TIME SPENT WITH CONTENT"
					hideTooltip={true}
					value={
						currentMetrics
							? currentMetrics.reduce(
									(agg, dateData) =>
										dateData.time_spent + agg,
									0
							  )
							: null
					}
					comparisonValue={
						comparisonMetrics
							? comparisonMetrics.reduce(
									(agg, dateData) =>
										dateData.time_spent + agg,
									0
							  )
							: null
					}
					customFormat={secondsToMinutes}
				/>
			</div>
			<div className="col-12 col-md-6 col-lg-3 mb-2">
				<MetricCard
					loading={loading || compLoading}
					label="UNIQUE CONTACTS"
					hideTooltip={true}
					value={
						currentMetrics
							? currentMetrics.reduce(
									(agg, dateData) =>
										dateData.unique_contacts + agg,
									0
							  )
							: null
					}
					comparisonValue={
						comparisonMetrics
							? comparisonMetrics.reduce(
									(agg, dateData) =>
										dateData.unique_contacts + agg,
									0
							  )
							: null
					}
				/>
			</div>
			<div className="col-12 col-md-6 col-lg-3 mb-2">
				<MetricCard
					loading={loading || compLoading}
					label="TOTAL ENGAGEMENTS"
					hideTooltip={true}
					value={
						currentMetrics
							? currentMetrics.reduce(
									(agg, dateData) =>
										dateData.total_audience + agg,
									0
							  )
							: null
					}
					comparisonValue={
						comparisonMetrics
							? comparisonMetrics.reduce(
									(agg, dateData) =>
										dateData.total_audience + agg,
									0
							  )
							: null
					}
				/>
			</div>
			<div className="col-12 col-md-6 col-lg-3 mb-2">
				<MetricCard
					loading={loading || compLoading}
					label="TOTAL IMPRESSIONS"
					hideTooltip={true}
					value={
						currentMetrics
							? currentMetrics.reduce(
									(agg, dateData) =>
										dateData.impressions + agg,
									0
							  )
							: null
					}
					comparisonValue={
						comparisonMetrics
							? comparisonMetrics.reduce(
									(agg, dateData) =>
										dateData.impressions + agg,
									0
							  )
							: null
					}
				/>
			</div>
		</div>
	);
};

export default CompanyProfileMetricCards;
