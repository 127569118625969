import React from 'react';
import { connect } from 'react-redux';
import { getSelectedAccount } from 'store/selectors/accounts';
import { dispatch } from 'store/store';

import {
	Dropdown,
	DropdownButton,
	ButtonToolbar,
	Row,
	Col,
	Container,
	NavItem,
} from 'react-bootstrap';

import { getContentType, SHARE_MODAL_TYPE } from 'components/constants';
import {
	hideCollectionShareModal,
	showCollectionShareModal,
} from 'store/reducers/ui/modals/collectionShareModal';

import Drawer from 'components/shared/drawer';
import { Button } from 'components/shared';
import { ShareContentModal } from 'components/shared';
import poweredByCasted from 'assets/images/poweredByCasted.svg';

const ShowDetailHeader = (props) => {
	const {
		podcast,
		filter,
		setFilter,
		episodeCount,
		addEpisodeButtonAction = () => {},
		canEdit,
		selectedAccount,
		isShowHostedWithCasted = false,
		modals,
	} = props;

	const { name, description, thumbnail, id, slug } = podcast;
	const episodeContentType = getContentType(podcast.collectionType);

	const toggleFilter = (field, value) => {
		if (filter === null) {
			return setFilter({ field, value });
		}
		if (filter.field === field && filter.value === value) {
			return setFilter(null);
		}
		return setFilter({ field, value });
	};

	const publicUrl = podcast?.customDomain
		? `https://${podcast.customDomain}`
		: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;

	return (
		<Container className="show-detail-header">
			<Row className="show-detail">
				<Col xs={2}>
					<img
						className="show-details--thumbnail"
						src={thumbnail ? thumbnail : poweredByCasted}
						alt="Show Thumbnail"
					/>
				</Col>
				<Col xs={10} className="show-details--info">
					<h2 className="show-name">{name}</h2>
					<h5 className="show-episode-count">
						{episodeCount} {episodeContentType}s
					</h5>
					<Drawer maxHeight="65px" className="show-description">
						{description}
					</Drawer>
				</Col>
			</Row>

			<Row className="show-detail--actions">
				<Col>
					<h5 className="show-actions--header">
						{episodeContentType}s
					</h5>
				</Col>
				<Col className="collection-buttons">
					<div className="collection-share">
						<Button
							variant="light"
							className="episode-header--button"
							onClick={() => {
								dispatch(showCollectionShareModal());
							}}
						>
							Share
						</Button>
					</div>

					<ButtonToolbar className="show-actions--btn-container">
						<DropdownButton
							title="Filter By"
							variant="outline-dark"
							className="show-actions--filter"
						>
							<Dropdown.Item
								onClick={() =>
									toggleFilter('publicStatus', 'published')
								}
							>
								Published
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() =>
									toggleFilter('publicStatus', 'draft')
								}
							>
								Draft
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() =>
									toggleFilter('publicStatus', 'scheduled')
								}
							>
								Scheduled
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() =>
									toggleFilter('publicStatus', 'private')
								}
							>
								Private
							</Dropdown.Item>
						</DropdownButton>

						{isShowHostedWithCasted && (
							<DropdownButton
								title={`Upload New ${episodeContentType}`}
								variant="success"
								className="show-actions--sync"
								disabled={!canEdit}
							>
								<Dropdown.Item
									onClick={() =>
										addEpisodeButtonAction('video')
									}
									disabled={!selectedAccount.enableVideo}
								>
									{selectedAccount.enableVideo ? (
										'Video'
									) : (
										<div>
											<p className="option">Video</p>
											<p className="helper-text">
												Contact your CSM to learn more
												about Video podcasts!
											</p>
										</div>
									)}
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() =>
										addEpisodeButtonAction('audio')
									}
								>
									Audio
								</Dropdown.Item>
							</DropdownButton>
						)}
					</ButtonToolbar>
				</Col>
			</Row>
			<ShareContentModal
				isOpen={modals.collectionShareModal.showCollectionShareModal}
				onClose={() => {
					dispatch(hideCollectionShareModal());
				}}
				baseUrl={''}
				title="Share Collection"
				shareContentName={'Share Collection'}
				shareModalType={SHARE_MODAL_TYPE.COLLECTION}
				contentSlug={''}
				shareUrl={`${publicUrl}/public/${selectedAccount.id}/${slug}`}
				name={name}
				shareId={id}
				linkText="Create a direct link to your collection via a specialized URL"
				disableLinkSharing={podcast.disableLandingPages}
			/>
		</Container>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	modals: state.ui.modals,
});

export default connect(mapStateToProps)(ShowDetailHeader);
