import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { history, dispatch } from 'store/store';
import { showDeleteThemeModal } from 'store/reducers/ui/modals/deleteThemeModal';

import { Dropdown } from 'react-bootstrap';
import { FaEllipsisH } from 'react-icons/fa';

import Button from 'components/shared/button';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const ThemeCard = (props) => {
	const { permissions, history, theme = {} } = props;

	const { id, accountId, name = '', keywords = [], color = null } = theme;

	const themesPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.THEMES).rolePermission
		: {};

	return (
		<div className="theme-card-container">
			<div className="theme-card-header">
				<span
					className="color-dot"
					style={{
						backgroundColor: color,
					}}
				></span>
				<h5>{name}</h5>
				{themesPermission.canEdit && (
					<Dropdown className="theme-actions">
						<Dropdown.Toggle id="theme-actions" as="div">
							<FaEllipsisH className="ellipsis-icon" />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									dispatch(showDeleteThemeModal(theme));
								}}
							>
								Delete
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)}
			</div>
			{keywords && (
				<div className="theme-card-keywords">
					Keywords:
					<div className="theme-card-words">{keywords.join(', ')}</div>
				</div>
			)}
			<div className="theme-card-footer">
				<Link
					to={{
						pathname: `/account/${accountId}/themes/${id}`,
						state: { from: history.location.pathname },
					}}
					className="int-link view-theme text-right"
				>
					View theme
				</Link>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
});

export default withRouter(connect(mapStateToProps)(ThemeCard));
