import React from 'react';

import { Form } from 'react-bootstrap';

const ShareForm = (props) => {
	const { setShareProperty, share, className = '' } = props;
	const { title, destination, description } = share;

	const updateShareProperty = (property, value) => {
		setShareProperty({
			...share,
			[property]: value,
		});
	};

	return (
		<Form className="share-form">
			<Form.Group className={className}>
				<Form.Label>Name</Form.Label>
				<Form.Control
					required
					value={title}
					onChange={(e) => updateShareProperty('title', e.target.value)}
				/>
			</Form.Group>
			<Form.Group className={className}>
				<Form.Label>Destination (Optional)</Form.Label>
				<Form.Control
					value={destination || ''}
					onChange={(e) => updateShareProperty('destination', e.target.value)}
				/>
			</Form.Group>
			<Form.Group className={className}>
				<Form.Label>Description (Optional)</Form.Label>
				<Form.Control
					as="textarea"
					onChange={(e) => updateShareProperty('description', e.target.value)}
					value={description}
				/>
			</Form.Group>
		</Form>
	);
};

export default ShareForm;
