import React from 'react';

import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

/**
 *  React Bootstrap's ToggleButtonGroup with Casted branding
 * @param props
 */
const CastedToggleButtonGroup = (props) => {

    const {
        children,
        className,
        type = 'radio', // radio or checkbox
        name = 'options',
        onChange = () => {},
        toggleButtonList = [], // toggleButtonList elements are objects consisting of `text` and `value` properties
        defaultValue = null
    } = props;

    return (
        <ToggleButtonGroup 
            className={`
                casted-toggle-button-group
                ${className}
            `}
            type={type} 
            name={name} 
            defaultValue={defaultValue} 
            onChange={onChange}
        >
            {
                children ? children : 
                toggleButtonList.map((toggleButton, i) => {
                    return (
                        <ToggleButton key={i} value={toggleButton.value}>{toggleButton.text}</ToggleButton>
                    );
                })
            }
        </ToggleButtonGroup>
    );
};

export default CastedToggleButtonGroup;