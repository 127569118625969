import { arrayOfObjectsToMap } from 'utils/'

export const ACTIONS = {
  GET_ACCOUNTS: 'CASTED/GET_ACCOUNTS',
  SET_SELECTED_ACCOUNT_ID: 'CASTED/SET_SELECTED_ACCOUNT_ID',
  UNSET_ACCOUNTS: 'CASTED/UNSET_ACCOUNTS'
}

const initialState = {
  accounts: null,
  selectedAccountId: ''
}

export function getAccounts(accounts) {
  return {
    type: ACTIONS.GET_ACCOUNTS,
    accounts
  }
}

export function setSelectedAccountId(selectedAccountId) {
  return {
    type: ACTIONS.SET_SELECTED_ACCOUNT_ID,
    selectedAccountId
  }
}

export function unsetAccounts() {
  return {
    type: ACTIONS.UNSET_ACCOUNTS
  }
}

export default function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_ACCOUNTS:
      const { accounts } = action
      return {
        ...state,
        accounts: arrayOfObjectsToMap(accounts, 'id')
      }
    case ACTIONS.SET_SELECTED_ACCOUNT_ID:
      return {
        ...state,
        selectedAccountId: action.selectedAccountId
      }
    case ACTIONS.UNSET_ACCOUNTS:
      return initialState
    default:
      return state
  }
}
