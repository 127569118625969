import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { METRICS_CONTEXTS } from 'components/constants';
import { PromotionListWithRouter } from 'components/shared/promotions/promotionList';

const CollectionPromotionList = (props) => {
	return (
		<>
			<PromotionListWithRouter context={METRICS_CONTEXTS.collection} />
		</>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
});

export const CollectionPromotionListWithRouter = withRouter(
	connect(mapStateToProps)(CollectionPromotionList)
);
