import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import mutations from 'services/graphql/mutations';
import ThumbnailUploader from 'components/shared/thumbnailUploader';
import API from 'services/api';
import TextArea from 'components/shared/textArea';
import TextInput from 'components/shared/textInput';
import Button from 'components/shared/button';
import queries from 'services/graphql/queries';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';
import { PODCAST_COLLECTION_TYPE } from 'components/constants';
import Form from 'components/shared/form';

// REDUX
import {
	ContentModalChoicesEnum,
	setNewContentModalState,
} from 'store/reducers/ui/modals/newContentModal';
import { dispatch } from 'store/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

const NewShowManualStep = (props) => {
	const {
		match,
		hidden,
		onRSSClick,
		onCloseClick,
		collectionType = PODCAST_COLLECTION_TYPE.serial,
		userProfile,
	} = props;
	const { params } = match;
	const { accountId } = params;

	// HOOKS
	const [thumbnailSrc, setThumbnailSrc] = useState(null);
	const [showThumbnailUrl, setShowThumbnailUrl] = useState(null);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [collectionPermissions, setCollectionPermissions] = useState(false);

	const { featureCollectionLevelPermissions } = useFlags();

	// END HOOKS

	// nonSeries removes rss related UI and creates podcast records with no rss feed
	const nonSeries = !(collectionType === PODCAST_COLLECTION_TYPE.serial);

	const onThumbnailUpload = async (imgDataUrl, fileExt) => {
		setThumbnailSrc(imgDataUrl);

		try {
			const gcsUrl = await API.uploadPublicFile({
				dataURI: imgDataUrl,
				fileExt: fileExt,
			});
			setShowThumbnailUrl(gcsUrl);
		} catch (e) {
			setShowThumbnailUrl('');
			setThumbnailSrc('')
			Toast.error(
				typeof e.message  === 'string' ? e.message : 'Something went wrong!'
			);
			console.error(e);
			Sentry.captureException(e);
		}
	};

	let podcastsByAccountIdQueryVariables = {
		accountId: parseInt(accountId, 10),
	};

	if (featureCollectionLevelPermissions) {
		podcastsByAccountIdQueryVariables.userPermissions = userProfile.id;
	}

	return (
		<Mutation
			mutation={mutations.createHostedPodcast}
			refetchQueries={() => {
				return [
					{
						query: queries.podcastsByAccountId,
						variables: { ...podcastsByAccountIdQueryVariables },
					},
				];
			}}
		>
			{(createPodcast, { data, loading, error }) => {
				const saveAndQuit = async () => {
					try {
						const showToCreate = {
							name,
							description,
							accountId: parseInt(accountId, 10),
							thumbnail: showThumbnailUrl,
							collectionLevelPermissions: collectionPermissions,
						};

						const podcast = await createPodcast({
							variables: {
								podcast: showToCreate,
								imageURI: thumbnailSrc,
								collectionType: collectionType,
							},
						});

						trackEvent('new-collection', {
							name,
							method: 'manual',
							type: collectionType,
						});

						if (collectionPermissions) {
							dispatch(
								setNewContentModalState({
									modal: ContentModalChoicesEnum.COLLECTION_PERMISSIONS,
									extra: {
										collection:
											podcast.data.createHostedPodcast,
									},
								})
							);
						} else if (onCloseClick) {
							onCloseClick(podcast.data.createHostedPodcast);
						}
					} catch (err) {
						console.error(err);
						Toast.error(
							'Oops! An error occurred saving the collection!'
						);
						Sentry.captureException(err);
					}
				};

				const gotoRss = () => {
					if (onRSSClick) {
						onRSSClick();
					}
				};

				return (
					<div
						className={`new-show-modal-permissions ${
							hidden ? 'hidden' : ''
						}`}
					>
						<div className="new-show-details-header-row">
							<div className="form-label">
								Add your collection information
							</div>
							<div className="helper-text new-show-help-text">
								Adding your collection manually will let you
								upload collection items individually.
							</div>
						</div>
						<div className="new-show-details-container">
							<div className="new-show-details-col">
								<div className="new-show-details-row">
									<div className="new-show-details-col">
										<label className="form-label">
											Collection Image
										</label>
										<div className="helper-text new-show-help-text">
											Image must be between 1400x1400 and
											3000x3000 pixels with a maximum size
											of 2 MB.
										</div>
									</div>
									<div className="new-show-details-col ">
										<ThumbnailUploader
											thumbnailImgSrc={thumbnailSrc}
											onUpload={onThumbnailUpload}
											overlayMsg="Upload"
											className="thumbnail-styles"
										/>
									</div>
								</div>
								<label className="form-label">
									Collection Name
								</label>
								<label
									className={`form-label ${
										name.length > 140 ? 'error' : 'hidden'
									} `}
								>
									{name.length >= 140
										? '• Must be 140 characters or less'
										: null}
								</label>
								<TextInput
									name="new-show-details-name"
									placeholder=""
									value={name}
									onChange={(e) => setName(e.target.value)}
									onBlur={(e) =>
										setName(e.target.value.trim())
									}
									inputClassName="new-show-details-form-input helper-text"
								/>
								<label className="form-label">
									Collection Description
								</label>
								<TextArea
									placeholder="Add a short description"
									onChange={(e) =>
										setDescription(e.target.value)
									}
									onBlur={(e) =>
										setDescription(e.target.value.trim())
									}
									minRows={4}
									value={description}
									textAreaClassName="new-show-details-form-input helper-text"
								/>
								<Form.Check
									id="switch-use-collection-permissions"
									type="switch"
									label={
										<div className="sub-label">
											Enable permissions for this
											collection
										</div>
									}
									onChange={() =>
										setCollectionPermissions(
											!collectionPermissions
										)
									}
									checked={collectionPermissions}
								/>
							</div>
						</div>
						<div className="new-show-actions-container">
							<div className="new-show-actions-row">
								<Button
									name="new-show-save-btn"
									onClick={saveAndQuit}
									className="new-show-save-btn"
									variant="success"
									title="Add Collection & Save"
									disabled={!name || name.length > 140}
								>
									Add Collection & Save
								</Button>
							</div>
							{!nonSeries && (
								<div className="new-show-actions-row">
									<Button
										name="new-show-rss-btn"
										onClick={gotoRss}
										className="new-show-rss-btn"
										variant="flat"
										title="I want to link my RSS feed"
									>
										I want to link my RSS feed
									</Button>
								</div>
							)}
						</div>
					</div>
				);
			}}
		</Mutation>
	);
};

const mapStateToProps = (state) => ({
	userProfile: state.auth.profile,
});

export default withRouter(connect(mapStateToProps)(NewShowManualStep));
