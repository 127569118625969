import md5 from 'md5';

export const ACTIONS = {
	SET_PROFILE: 'CASTED/AUTH_SET_PROFILE',
	UNSET_PROFILE: 'CASTED/AUTH_UNSET_PROFILE',
	SET_IS_AUTHED: 'CASTED/AUTH_SET_IS_AUTHED',
	UNSET_IS_AUTHED: 'CASTED/AUTH_UNSET_IS_AUTHED',
	SET_LOGGED_IN: 'CASTED/AUTH_SET_LOGGED_IN',
	UNSET_LOGGED_IN: 'CASTED/AUTH_UNSET_LOGGED_IN',
	SET_LINKED_ACCOUNTS: 'CASTED/AUTH_SET_LINKED_ACCOUNTS',
	SET_PERMISSIONS: 'CASTED/AUTH_SET_PERMISSIONS',
};

const initialState = {
	isAuthed: false,
	profile: null,
	linkedAccounts: null,
	permissions: null,
};

export function setLoggedIn(userData) {
	return {
		type: ACTIONS.SET_LOGGED_IN,
		userData,
	};
}

export function unsetLoggedIn() {
	return {
		type: ACTIONS.UNSET_LOGGED_IN,
	};
}

export function setProfile(profile) {
	return {
		type: ACTIONS.SET_PROFILE,
		profile,
	};
}

export function unsetProfile() {
	return {
		type: ACTIONS.UNSET_PROFILE,
	};
}

export function setIsAuthed() {
	return {
		type: ACTIONS.SET_IS_AUTHED,
	};
}

export function unsetIsAuthed() {
	return {
		type: ACTIONS.UNSET_IS_AUTHED,
	};
}

export function setLinkedAccounts(linkedAccounts) {
	return {
		type: ACTIONS.SET_LINKED_ACCOUNTS,
		linkedAccounts,
	};
}

export function setPermissions(permissions) {
	// Two types of permissions. accountRole permissions and collectionRole permissions.
	// order of precedence goes collectionRole > accountRole UNLESS accountRole is admin.

	return {
		type: ACTIONS.SET_PERMISSIONS,
		permissions,
	};
}

/* eslint-disable no-case-declarations */
export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.SET_LOGGED_IN:
			const { userData } = action;
			const curPic = userData.picture || null;
			userData.picture = userData.photoURL || curPic;
			const curSettings =
				state.profile && state.profile.settings ? state.profile.settings : {};
			userData.settings = Object.assign(
				{},
				curSettings,
				userData.settings || {}
			);
			const firstName = userData.first_name;
			const lastName = userData.last_name;

			return {
				...state,
				isAuthed: true,
				profile: {
					...state.profile,
					...userData,
					firstName,
					lastName,
					fullName: `${firstName} ${lastName}`,
					hashedId: md5(`${userData.id}-${userData.email}`),
				},
			};
		case ACTIONS.UNSET_LOGGED_IN:
			return initialState;
		case ACTIONS.SET_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case ACTIONS.UNSET_LOADING:
			return {
				...state,
				isLoading: false,
			};
		case ACTIONS.SET_PROFILE:
			return {
				...state,
				profile: {
					...state.profile,
					...action.profile,
				},
			};
		case ACTIONS.UNSET_PROFILE:
			return {
				...state,
				profile: null,
			};
		case ACTIONS.SET_IS_AUTHED:
			return {
				...state,
				isAuthed: true,
			};
		case ACTIONS.UNSET_IS_AUTHED:
			return {
				...state,
				isAuthed: false,
			};
		case ACTIONS.SET_LINKED_ACCOUNTS:
			return {
				...state,
				linkedAccounts: action.linkedAccounts,
			};
		case ACTIONS.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.permissions,
			};
		default:
			return state;
	}
}
