import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Internal Components
import Button from 'components/shared/button';

// Internal Libraries
import { dispatch, history } from 'store/store';
import { setPromotionMetricsDate } from 'store/reducers/data/metrics';
import { getCanEdit, ObjectPermissionSlugs } from 'utils/permissionsManager';
import {
	PROMOTION_POSITION,
	PODCAST_COLLECTION_TYPE,
} from 'components/constants';

// Internal Assets
import promotionFallback from 'assets/images/promotion-fallback.svg';
import moment from 'moment';

const PromotionCard = (props) => {
	const {
		accountId,
		item,
		promotion,
		match,
		permissions,
		collectionType,
		standaloneItemId,
		showMetrics = true,
	} = props;

	const { params } = match;
	const { showId: collectionId } = params;

	const { viewPromotionMetrics } = useFlags();

	const canEditPromotions =
		permissions &&
		getCanEdit(permissions, ObjectPermissionSlugs.EPISODES, collectionId);

	let positionText = '';

	switch (promotion.position) {
		case PROMOTION_POSITION.INTRO:
			positionText = 'Intro';
			break;
		case PROMOTION_POSITION.OUTRO:
			positionText = 'Outro';
			break;
		case PROMOTION_POSITION.BOTH:
			positionText = 'Intro/Outro';
			break;
		default:
			break;
	}

	const startDate = new Date(promotion.startDate);
	const endDate = new Date(promotion.endDate);

	const dateRangeText = promotion.endDate
		? `${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}`
		: 'Always Active';

	const promotionPath = promotion.collectionId
		? `/account/${accountId}/shows/${collectionId}/promotions/${promotion.id}`
		: `/account/${accountId}/shows/${item.podcastId}/episodes/${item.id}/promotions/${promotion.id}`;

	const metricsPath =
		promotion.collectionId && collectionType !== PODCAST_COLLECTION_TYPE.one_off
			? `/account/${accountId}/shows/${collectionId}/metrics`
			: `/account/${accountId}/shows/${collectionId}/episodes/${
					item.id ? item.id : standaloneItemId
			  }/analytics`;

	return (
		<div className="promotion-card">
			<div className="promotion-card-header">
				<div>
					<h5>{positionText} Promotion</h5>
					<h4>{promotion.title}</h4>
				</div>
				<div>
					{promotion.collectionId && (
						<div className="promotion-banner">Collection-Wide Promotion</div>
					)}
					<h5 className="date-range-text">{dateRangeText}</h5>
				</div>
			</div>
			<div className="promotion-content">
				<div className="promotion-thumbnail">
					<img src={promotion.thumbnail || promotionFallback} />
				</div>
				<div className="promotion-text">
					<div>
						<p>{promotion.description}</p>
					</div>
					<div className="promotion-actions">
						{canEditPromotions && (
							<div>
								<Link
									to={{
										pathname: promotionPath,
										state: { from: history.location.pathname },
									}}
									className="int-link view-theme text-right mr-3"
								>
									<Button variant="success" title="Edit Promotion">
										Edit Promotion
									</Button>
								</Link>
							</div>
						)}
						{showMetrics && (
							<Link
								to={{
									pathname: metricsPath,
									state: {
										from: history.location.pathname,
									},
								}}
								target="_blank"
								onClick={() => {
									const alwaysActive = !promotion.endDate;
									const today = moment().format('yyyy-MM-DD');
									const yesterday = new Date(
										new Date().setDate(new Date().getDate() - 1)
									);

									if (
										alwaysActive &&
										moment(promotion.createdAt).format('yyyy-MM-DD') !== today
									) {
										dispatch(
											setPromotionMetricsDate({
												startDate: promotion.createdAt,
												endDate: new Date(
													new Date().setDate(new Date().getDate() - 1)
												),
											})
										);
									}

									if (
										!alwaysActive &&
										moment(promotion.startDate).format('yyyy-MM-DD') < today
									) {
										dispatch(
											setPromotionMetricsDate({
												startDate: promotion.startDate,
												endDate:
													moment(promotion.endDate).format('yyyy-MM-DD') >=
													today
														? yesterday
														: promotion.endDate,
											})
										);
									}
								}}
							>
								{viewPromotionMetrics && (
									<Button variant="outline-dark" title="View Metrics">
										View Metrics
									</Button>
								)}
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	item: state.episode.episode,
	permissions: state.auth.permissions,
});

export const PromotionCardWithRouter = withRouter(
	connect(mapStateToProps)(PromotionCard)
);
