import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';

import queries from 'services/graphql/queries';
import { METRICS_CONTEXTS } from 'components/constants';
import { getSelectedAccount } from 'store/selectors/accounts';

import MetricsScreen from 'components/shared/metricsScreen';

const ShowMetrics = (props) => {
	const { match, selectedAccount } = props;
	const { params } = match;
	const { showId: podcastId } = params;

	const { data, loading } = useQuery(queries.podcastById, {
		variables: { podcastId: podcastId, includeOldestEpisode: true },
	});
	const { podcast } = data || {};
	const isHosted = podcast && podcast.externalId ? true : false;

	if (loading) return <></>;

	const contextQueries = {
		integrations: queries.podcastIntegrations,
		totalAndUniqueAudience: queries.getShowListenSummary,
		newAndLoyalAudience: queries.getShowNewAndLoyalListeners,
		averageCompletion: queries.getShowAverageCompletion,
		listensOverTimeHosted: queries.getDailyShowDownloadAndPlayCount,
		listensOverTimeUnhosted: queries.getDailyShowListens,
	};

	return (
		<MetricsScreen
			metricsContext={METRICS_CONTEXTS.collection}
			isHosted={isHosted}
			contextIdVariable={{ podcastId: podcastId }}
			contextQueries={contextQueries}
			className={'show-metrics'}
		/>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
});

export default withRouter(connect(mapStateToProps)(ShowMetrics));
