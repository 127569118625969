import React, { useState, useEffect } from 'react'

import { Modal, ProgressBar } from 'react-bootstrap'
import { FaThumbsUp, FaTimes } from 'react-icons/fa'

import Button from '../shared/button';

const SyncingEpisodes = (props) => {
  const {
    syncing = true,
    addedCount = false,
    closeCallback
  } = props

  const [progress, setProgress] = useState(0)
  
  useEffect(() => {
    // run up a fake progress bar to make the user feel good
    if(progress < 100) setProgress(progress + 2)
  });

  if(syncing) {
    return (
      <>
      <Modal.Header closeButton><Modal.Title>Syncing Episodes</Modal.Title></Modal.Header>
      <Modal.Body className="syncing-episodes inner-modal">
        <h3>Hold Tight, Fetching Episodes</h3>
        <ProgressBar variant="info" animated now={progress} />
      </Modal.Body>
      </>
    )
  }
  
  if(addedCount !== false) {
    return (
      <>
        <Modal.Header closeButton><Modal.Title>Syncing Episodes</Modal.Title></Modal.Header>
        <Modal.Body className="syncing-episodes">
          <div className="success-icon"> <FaThumbsUp  /></div>
          {addedCount === 0 && 
            <h3>Already Up to Date - No New Episodes Added</h3>
          }
          {addedCount > 0 && 
            <h3>{addedCount} Episodes were Added!</h3>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={closeCallback}>Ok</Button>        
        </Modal.Footer>
      </>
    )
  }
  
  return (
    <>
      <Modal.Header closeButton><Modal.Title>Show Created</Modal.Title></Modal.Header>
      <Modal.Body className="syncing-episodes inner-modal">
        <div className="success-icon"> <FaThumbsUp  /></div>
        <h3>Nice Job!<br/>Your show has been added.</h3>
        <p>It may take a few minutes, but all of the episodes from your show will automatically download and be transcribed</p>      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={closeCallback}>Got it</Button>
      </Modal.Footer>
    </>
  )
}

export default SyncingEpisodes
