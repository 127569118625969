import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { FaTimes } from 'react-icons/fa';

import Form from 'components/shared/form';

// TODO: This component should probably replace the <TagsInput>
// Component sometime in the future
const TagListInput = (props) => {
	const {
		placeholder = `Type keywords and press enter/tab \nto add.`,
		tags = [],
		onChange = () => {},
        themeKeywordLimit,
	} = props;

	const [tagValue, setTagValue] = useState('');
	const [localTags, setLocalTags] = useState(tags);
	const [tagLimitReached, setTagLimitReached] = useState(false);

	const onAddTag = (value) => {
		let newTags = localTags;

		if (newTags.some((tag) => tag === value)) {
			return;
		}

		newTags.push(value);

		setLocalTags(newTags);
		onChange(newTags);
	};

	const onRemoveTag = (value) => {
		let newTags = localTags.filter((tag) => tag !== value);

		setLocalTags(newTags);
		onChange(newTags);
	};

	useEffect(() => {
		if (localTags.length >= themeKeywordLimit) {
			setTagLimitReached(true);
		} else if (tagLimitReached && localTags.length < themeKeywordLimit){
            setTagLimitReached(false);
        }
	}, [localTags.length, themeKeywordLimit]);

	return (
		<div className="tag-list-input">
			<Form.Control
				as="textarea"
				placeholder={placeholder}
				onChange={(e) => {
					// Remove spaces after third word
					const spaceCount = e.currentTarget.value.match(/(\s)/g);

					if (spaceCount?.length > 2) {
						setTagValue(e.currentTarget.value.trim());
					} else {
						setTagValue(e.currentTarget.value);
					}
				}}
				value={tagValue}
				onKeyDown={(e) => {
					if (e.key === 'Tab' && e.currentTarget.value !== '') {
						if (!localTags.find((x) => x.value === e.currentTarget.value)) {
							onAddTag(e.currentTarget.value);
						}
						setTagValue('');
					}
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter' && e.currentTarget.value !== '') {
						let newTag = e.currentTarget.value.replace(/(\r\n|\n|\r)/gm, '');
						if (!localTags.find((x) => x.value === newTag)) {
							onAddTag(newTag);
						}
						setTagValue('');
					}
				}}
                disabled={tagLimitReached}
			/>
			<div className="tag-list">
				{localTags &&
					localTags.map((option, key) => {
						return (
							<span key={`tag-${key}`} onClick={() => onRemoveTag(option)}>
								{option} <FaTimes />
							</span>
						);
					})}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
    themeKeywordLimit: state.themeData.themeKeywordLimit,
})

export default connect(mapStateToProps)(TagListInput);
