import React from 'react';

import { Form as BSForm } from 'react-bootstrap';

const CASTED_BRAND_CLASS = 'casted-form';

/**
 * Creates a Casted branded version of the React Bootstrap Form Component
 * @returns
 */
const Form = (props) => {
	const { className = '', children } = props;
	return (
		<BSForm className={`${CASTED_BRAND_CLASS} ${className}`} {...props}>
			{children}
		</BSForm>
	);
};

Form.Row = (props) => {
	const { className = '', children } = props;
	return (
		<BSForm.Row className={`${CASTED_BRAND_CLASS} ${className}`} {...props}>
			{children}
		</BSForm.Row>
	);
};

Form.Group = (props) => {
	const { className = '', children } = props;
	return (
		<BSForm.Group className={`${CASTED_BRAND_CLASS} ${className}`} {...props}>
			{children}
		</BSForm.Group>
	);
};

Form.Label = (props) => {
	const { className = '', children } = props;
	return (
		<BSForm.Label className={`${CASTED_BRAND_CLASS} ${className}`} {...props}>
			{children}
		</BSForm.Label>
	);
};

Form.Control = (props) => {
	const { className = '', children } = props;
	return (
		<BSForm.Control className={`${CASTED_BRAND_CLASS} ${className}`} {...props}>
			{children}
		</BSForm.Control>
	);
};

Form.Text = (props) => {
	const { className = '', children } = props;
	return (
		<BSForm.Text className={`${CASTED_BRAND_CLASS} ${className}`} {...props}>
			{children}
		</BSForm.Text>
	);
};

Form.Check = (props) => {
	const { className = '', children } = props;
	return (
		<BSForm.Check className={`${CASTED_BRAND_CLASS} ${className}`} {...props}>
			{children}
		</BSForm.Check>
	);
};

export default Form;
