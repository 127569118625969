import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import { formatLargeIntegers, numberSort  } from 'utils';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import BaseTable from 'components/shared/baseTable';
import MetricEmptyState from 'components/shared/metricEmptyState';

const TopShows = (props) => {
	const variables = {
		accountId: props.accountId,
		startDate: props.dateRange.start,
		endDate: props.dateRange.end,
		isHosted: props.isHosted,
	};
	const history = useHistory();
	const rootPath = history.location.pathname.replace('metrics', '');

	const [values, setValues] = useState([]);

	const popover = (name) => {
		return (
			<Popover className="long-title-popover">
				<Popover.Content>
					<strong>Collection Name: </strong>
					<br />
					{name}
				</Popover.Content>
			</Popover>
		);
	};

	const caseInsensitiveSort = (rowA, rowB) => {
		const a = rowA.name ? rowA.name.toLowerCase() : '';
		const b = rowB.name ? rowB.name.toLowerCase() : '';
	  
		if (a > b) {
		  return 1;
		}
	  
		if (b > a) {
		  return -1;
		}
	  
		return 0;
	  };
	  

	const columns = [
		{
			name: 'Collection',
			selector: (row) => row.name,
			sortable: true,
			sortFunction: caseInsensitiveSort,
			cell: (row) => (
				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement="right-end"
					overlay={popover(row.name)}
					show={row.name && row.name.length > 140}
				>
					<Link to={{pathname: `${rootPath}shows/${row.id}/metrics`}} target="_blank">
						{row.name && row.name.length > 140
							? row.name.substr(0, 139).concat('...')
							: row.name}
					</Link>
				</OverlayTrigger>
			),
		},
		{
			name: 'Engagements',
			selector: (row) => row.data[2],
			sortable: true,
			sortFunction: numberSort((row) => row.data[2]),
		  },
		  {
			name: 'Impressions',
			selector: (row) => row.data[0],
			omit: !props.isHosted,
			sortable: true,
			sortFunction: numberSort((row) => row.data[0]),
		  },
		  {
			name: 'Likes',
			selector: (row) => row.data[1],
			sortable: true,
			sortFunction: numberSort((row) => row.data[1]),
		  },
	];

	const { loading, error } = useQuery(queries.getTopShows, {
		variables,
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			const { getTopShows: shows = [] } = data || {};

			setValues(
				shows.map((show) => {
					let data = [];

					if (props.isHosted) {
						data.push(formatLargeIntegers(show.total_impressions));
					}

					data.push(
						formatLargeIntegers(show.total_likes),
						formatLargeIntegers(show.total_listens)
					);

					return {
						id: show.id,
						name: show.podcast_name,
						data,
					};
				})
			);
		},
	});

	const onClickHandler = (show) => {
		window.open(`${rootPath}shows/${show?.id}/metrics`, '_blank');
	};

	return (
		<>
			<h4>Top Collections</h4>
			<BaseTable
				values={values}
				columns={columns}
				onClick={onClickHandler}
				loading={loading}
				noDataComponent={<MetricEmptyState metricName="Top Collections" />}
				clickable={true}
				pagination={true}
				paginationPerPage={10}
				className={'clickable'}
			/>
		</>
	);
};

export default TopShows;
