import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { THEME_PUBLIC_STATUS } from 'components/constants';

import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

import { dispatch } from 'store/store';
import { setThemeUpdates } from 'store/reducers/data/theme';

import { FaExclamationCircle } from 'react-icons/fa';

// Shared Components
import Button from 'components/shared/button';
import ColorPicker from 'components/shared/colorPicker';
import Form from 'components/shared/form';
import TagListInput from 'components/shared/tagListInput';

const EditTheme = (props) => {
	const { theme, onSave = () => {}, themeUpdates, themeKeywordLimit } = props;

	// API call to update theme. We want to refetch the theme
	// API call after an update is made
	const [updateTheme] = useMutation(mutations.updateThemeById, {
		refetchQueries: ['theme'],
	});

	// localTheme is an aggregate of the original theme data and
	// any new data present in themeUpdates
	const [localTheme, setLocalTheme] = useState(theme || {});

	const [isNameInvalid, setIsNameInvalid] = useState(false);

	// Deconstructing theme to get values
	const {
		id,
		accountId,
		name,
		keywords,
		publicStatus,
		color,
		matchThreshold,
	} = localTheme || {};

	useEffect(() => {
		setLocalTheme(theme);
	}, [theme]);

	// Updates the provided theme property to the value provided
	const updateThemeProperty = (property, value) => {
		// Make sure property exists
		if (theme.hasOwnProperty(property)) {
			// We're setting keywords here so the API always knows the current keywords
			// in order to determine what matches should be deleted
			let newThemeUpdates = {
				keywords,
				...themeUpdates,
			};

			// Remove update if it matches the original property value.
			// Else, add it to unsaved updates
			if (
				value === theme[property] &&
				newThemeUpdates.hasOwnProperty(property)
			) {
				delete newThemeUpdates[property];
			} else {
				newThemeUpdates[property] = value;
			}

			// Update localEpisode to include unsaved edits
			setLocalTheme({
				...localTheme,
				[property]: value,
			});

			dispatch(setThemeUpdates(newThemeUpdates));
		}
	};

	// Function executed on click of save button
	const saveThemeOnClick = async () => {
		if (name === '') {
			setIsNameInvalid(true);
		} else {
			setIsNameInvalid(false);
			if (Object.keys(themeUpdates).length > 0) {
				await updateTheme({
					variables: {
						themeId: id,
						theme: {
							...themeUpdates,
							accountId: parseInt(accountId, 10),
						},
					},
				});
				trackEvent('edit-theme', { ...themeUpdates, name: name });

				// Clear out themeUpdates
				dispatch(setThemeUpdates({}));
			}

			onSave();
		}
	};

	return (
		<Form className="edit-theme">
			<Form.Group>
				<Form.Label>Name</Form.Label>
				<Form.Control
					required
					isInvalid={isNameInvalid}
					value={name}
					placeholder="Theme Name"
					onChange={(e) =>
						updateThemeProperty('name', e.target.value)
					}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Keywords</Form.Label>
				<TagListInput
					className="theme-keywords"
					placeholder={`Enter a word or phrase up to 3 words and press Enter/Tab to add. Total keywords limited to ${themeKeywordLimit}.`}
					tags={keywords || []}
					onChange={(keywords) =>
						updateThemeProperty('keywords', keywords)
					}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Match Threshold</Form.Label>
				<p className="helper-text threshold">
					<FaExclamationCircle size={14} className="warning" />{' '}
					Changing the match threshold will affect the Matched Content
					results.
				</p>
				<Form.Control
					type="number"
					value={parseInt(matchThreshold)}
					placeholder="Match Threshold"
					min={1}
					onChange={(e) =>
						updateThemeProperty(
							'matchThreshold',
							parseInt(e.target.value)
						)
					}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Status</Form.Label>
				<Form.Check
					type="switch"
					id="themeStatus"
					label="Priority Theme?"
					checked={publicStatus === THEME_PUBLIC_STATUS.priority}
					onChange={(e) =>
						updateThemeProperty(
							'publicStatus',
							publicStatus === THEME_PUBLIC_STATUS.priority
								? THEME_PUBLIC_STATUS.other
								: THEME_PUBLIC_STATUS.priority
						)
					}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Theme Color</Form.Label>
				<div className="d-flex">
					<ColorPicker
						color={color || '#868e96'}
						onColorChange={(color) => {
							updateThemeProperty('color', color);
						}}
					/>
				</div>
			</Form.Group>
			<Button className="w-100 mt-3" onClick={saveThemeOnClick}>
				Save Theme
			</Button>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	theme: state.themeData.theme,
	themeUpdates: state.themeData.themeUpdates,
	themeKeywordLimit: state.themeData.themeKeywordLimit,
});

export default connect(mapStateToProps)(EditTheme);
