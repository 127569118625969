import React from 'react';
import styles from './styles.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { WithSearch } from '@elastic/react-search-ui';

const SearchInput = (props) => {
	const {
		showClose = false,
		onClose = () => {},
		placeholder = 'Enter search terms here',
	} = props;

	return (
		<WithSearch
			mapContextToProps={({ searchTerm, setSearchTerm, reset }) => ({
				searchTerm,
				setSearchTerm,
				reset,
			})}
		>
			{({ searchTerm, setSearchTerm, reset }) => {
				return (
					<div className={styles.searchInputContainer}>
						<input
							className={styles.searchInput}
							value={searchTerm}
							onChange={(e) =>
								setSearchTerm(e.target.value, {
									shouldClearFilters: false,
								})
							}
							placeholder={placeholder}
						/>
						<button
							className={styles.clearButton}
							onClick={() =>
								setSearchTerm('', {
									shouldClearFilters: true,
								})
							}
						>
							Clear
						</button>
						{showClose && (
							<div
								className={styles.closeButton}
								onClick={() => {
									reset();
									onClose();
								}}
							>
								<FontAwesomeIcon icon={faTimes} size="sm" />
							</div>
						)}
					</div>
				);
			}}
		</WithSearch>
	);
};

export default SearchInput;
