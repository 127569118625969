import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaPlay, FaBackward, FaForward } from 'react-icons/fa';
import { hexToRgba } from 'utils';

const MockPlayer = (props) => {
	const {
		brandSettings,
		thumbnail,
		includeBrandColors = true,
		video = false,
		showFooter = true,
		children,
	} = props;

	const {
		primaryColor = '',
		secondaryColor = '',
		backgroundColor = '',
		headingTextColor = '',
		bodyTextColor = '',
	} = brandSettings || {};

	useState(() => {}, [brandSettings]);

	const hex = hexToRgba(primaryColor, 0.5);

	return (
		<div>
			<Row>
				<Col>
					<div className="mock-player">
						<Row>
							<Col xs={2}>
								<div className="mock-player--thumbnail">
									{thumbnail && <img src={thumbnail} />}
								</div>
							</Col>
							<Col xs>
								<div
									className="mock-player--title"
									style={{ color: headingTextColor }}
								>
									Title
								</div>
								<div className="mock-player--audio">
									<Row>
										<Col xs="auto">
											<FaBackward />
											<FaPlay style={{ color: primaryColor }} />
											<FaForward />
										</Col>
										<Col>
											<div className="mock-player--playbar">
												<div
													className="mock-player--takeaway"
													style={{
														background: hex,
														borderColor: primaryColor,
													}}
												></div>
												<div className="mock-player--bar">
													<div
														className="mock-player--progress"
														style={{ background: secondaryColor }}
													>
														<div
															className="mock-player--playhead"
															style={{ borderColor: secondaryColor }}
														></div>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</div>
								<div
									className="mock-player--body"
									style={{ color: bodyTextColor }}
								>
									Qui adipisicing culpa deserunt ad eiusmod ea amet ut sunt
									culpa elit nostrud aliquip irure. Fugiat sint veniam
									reprehenderit veniam labore. Elit elit aliqua anim sit commodo
									adipisicing irure.
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
			{showFooter && (
				<Row>
					<Col>
						<div
							className="mock-footer"
							style={{ background: backgroundColor }}
						></div>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default MockPlayer;
