import React from 'react';

import CastedModal from 'components/shared/modal';

const ConfirmationModal = (props) => {
	const {
		isOpen,
		title,
		confirmationText,
		confirmationButtonLabel,
		confirmButtonVariant = 'danger',
		onConfirm = () => {},
		onCancel = () => {},
		className = '',
		closeButton = true,
		additionalConfirmationText = '',
	} = props;

	const dialogOptions = [
		{
			variant: confirmButtonVariant,
			text: confirmationButtonLabel,
			onClick: onConfirm,
		},
		{
			variant: 'light',
			text: 'CANCEL',
			onClick: onCancel,
		},
	];

	return (
		<CastedModal
			show={isOpen}
			handleClose={onCancel}
			title={title}
			dialogOptions={dialogOptions}
			className={'confirm-modal ' + className}
			closeButton={closeButton}
		>
			<p className="modal-confirmation-text">{confirmationText}</p>
			{additionalConfirmationText && (
				<div
					dangerouslySetInnerHTML={{ __html: additionalConfirmationText }}
				></div>
			)}
		</CastedModal>
	);
};

export default ConfirmationModal;
