import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, Form } from 'react-bootstrap';
import { useMutation, useLazyQuery } from '@apollo/client/react/hooks';
import { FaThumbsUp } from 'react-icons/fa';

import Button from 'components/shared/button';
import { REV_PRICE } from 'components/constants';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { HorizontalLine } from 'components/shared/loadingSkeletons/skeleton';

const PurchaseCredits = (props) => {
	const { stageName, currentStage, onContinueClick, accountId, userId } =
		props;

	// HOOKS
	const [addedCredits, setAddedCredits] = useState(300);
	const [purchaseComplete, setPurchaseComplete] = useState(false);
	const [termsCheck, setTermsCheck] = useState(false);

	useEffect(() => {
		// make sure we reset the modal state when navigating
		setPurchaseComplete(false);
	}, [currentStage]);

	// Queries
	const [
		getAccountInfo,
		{ data: accountData, loading: accountLoading, called: accountCalled },
	] = useLazyQuery(queries.getAccountInfo, {
		variables: { accountId: accountId },
		fetchPolicy: 'no-cache',
	});

	const [addTranscriptionCredits] = useMutation(
		mutations.addTranscriptionCredits
	);

	if (currentStage !== stageName) {
		return null;
	}

	if (accountLoading) {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<h2 className="option-header">Premium Transcription Credits</h2>

				<HorizontalLine
					style={{
						height: '1.5rem',
						width: '100%',
						margin: '30px 0 50px 0',
					}}
				/>
			</div>
		);
	}

	if (!accountCalled && !accountLoading) {
		getAccountInfo();
	}

	const { account } = accountData || {};
	if (!account) {
		return null;
	}

	if (purchaseComplete) {
		return (
			<>
				<Modal.Body className="success-modal">
					<div className="success-icon">
						{' '}
						<FaThumbsUp />
					</div>
					<h3>Transcription Minutes Added</h3>
					<p>
						Thank you for purchasing additional Premium
						transcription minutes. The cost will be included on an
						upcoming invoice.
					</p>
				</Modal.Body>
				<Modal.Footer className="options-footer">
					<Button
						variant="contained"
						className="options-next-btn"
						onClick={() => {
							if (onContinueClick) {
								onContinueClick();
							}
						}}
					>
						Got it
					</Button>
				</Modal.Footer>
			</>
		);
	}

	return (
		<div>
			<h2 className="option-header">Premium Transcription Credits</h2>

			<p className="credits-description">
				One minute of Premium transcription costs $1.25. Add minutes to
				your account by selecting a package below.
			</p>

			<Row>
				<Col xs="10">
					<p className="credits-label">Available Minutes</p>
				</Col>
				<Col xs="2">
					<p className="credits-number">
						<strong>{account.transcriptionCredits}</strong>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs="4">
					<Card
						className="purchase-option transcription-purchase-option"
						onClick={() => {
							setAddedCredits(150);
						}}
						border={`${addedCredits === 150 ? 'success' : 'dark'}`}
					>
						<Card.Body>
							<Card.Title>150 Minutes</Card.Title>
							<Card.Text>
								Add 150 minutes for a cost of $
								{(REV_PRICE * 150).toFixed(2)}
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col xs="4">
					<Card
						className="purchase-option purchase-option"
						onClick={() => {
							setAddedCredits(300);
						}}
						border={`${addedCredits === 300 ? 'success' : 'dark'}`}
					>
						<Card.Body>
							<Card.Title>300 Minutes</Card.Title>
							<Card.Text>
								Add 300 minutes for a cost of $
								{(REV_PRICE * 300).toFixed(2)}
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col xs="4">
					<Card
						className="purchase-option purchase-option"
						onClick={() => {
							setAddedCredits(450);
						}}
						border={`${addedCredits === 450 ? 'success' : 'dark'}`}
					>
						<Card.Body>
							<Card.Title>450 Minutes</Card.Title>
							<Card.Text>
								Add 450 minutes for a cost of $
								{(REV_PRICE * 450).toFixed(2)}
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row>
				<Form.Check
					className="px-5"
					required
					label="I accept that Casted will send me an invoice for the selected amount, payable by the invoice terms."
					feedback="Please accept before continuing"
					checked={termsCheck}
					onChange={() => {
						setTermsCheck(!termsCheck);
					}}
				/>
			</Row>

			<div className="options-footer">
				<Button
					onClick={async () => {
						await addTranscriptionCredits({
							variables: { accountId, amount: addedCredits },
						});
						setPurchaseComplete(true);
					}}
					disabled={!termsCheck}
					id="confirm-transcription-credit-purchase"
					className="options-next-btn"
					variant="contained"
				>
					CONTINUE
				</Button>
			</div>
		</div>
	);
};

export default PurchaseCredits;
