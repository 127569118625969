export const ACTIONS = {
    GET_LINKED_CLIP: 'CASTED/GET_LINKED_CLIP',
    SET_LINKED_CLIP: 'CASTED/SET_LINKED_CLIP',
    GET_LINKED_TRANSCRIPT: 'CASTED/GET_LINKED_TRANSCRIPT',
    SET_LINKED_TRANSCRIPT: 'CASTED/SET_LINKED_TRANSCRIPT',
};

const initialState = {
    linkedClip: null,
    linkedTranscript: null
};

export const getLinkedClip = () => {
    return {
        type: ACTIONS.GET_LINKED_CLIP
    };
};

export const setLinkedClip = (linkedClip) => {
    return {
        type: ACTIONS.SET_LINKED_CLIP,
        linkedClip
    };
};

export const getLinkedTranscript = () => {
    return {
        type: ACTIONS.GET_LINKED_TRANSCRIPT
    };
};

export const setLinkedTranscript = (linkedTranscript) => {
    return {
        type: ACTIONS.SET_LINKED_TRANSCRIPT,
        linkedTranscript
    };
};

const studioReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIONS.GET_LINKED_CLIP:
            return state.linkedClip;
        case ACTIONS.SET_LINKED_CLIP:
            return {
                ...state,
                linkedClip: action.linkedClip
            };
        case ACTIONS.GET_LINKED_TRANSCRIPT:
            return state.linkedTranscript;
        case ACTIONS.SET_LINKED_TRANSCRIPT:
            return {
                ...state,
                linkedTranscript: action.linkedTranscript
            };
        default:
            return state;
    }
};

export default studioReducer;