import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import BaseTable from 'components/shared/baseTable';
import MetricEmptyState from 'components/shared/metricEmptyState';
import { numberSort } from 'utils';

const TopKeywords = (props) => {
	const { variables } = props;

	const [values, setValues] = useState([]);

	const columns = [
		{
			name: 'Top Keywords',
			selector: (row) => row.name,
			sortable: false,
		},
		{
			name: '# of Matches',
			selector: (row) => row.matches,
			sortable: true,
			sortFunction: numberSort((row) => row.matches),
		},
	];

	const { loading, error } = useQuery(queries.getThemeTopKeywords, {
		variables,
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			const { getThemeTopKeywords: keywords = [] } = data || {};

			setValues(
				keywords.map((keyword, i) => {
					return {
						id: i,
						name: keyword.keyword,
						matches: keyword.total_matches,
					};
				})
			);
		},
	});

	return (
		<BaseTable
			values={values}
			columns={columns}
			loading={loading}
			noDataComponent={<MetricEmptyState metricName="Top Keywords" />}
			clickable={false}
			pagination={true}
		/>
	);
};

export default TopKeywords;
