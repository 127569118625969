import React from 'react';
import { Facet } from '@elastic/react-search-ui';

import Checkbox from '../../checkbox';

import styles from './styles.scss';

const ClipTypeFilter = (props) => {
	const { field, fieldLabel } = props;

	return (
		<div className={styles.clipTypeFilter}>
			<Facet
				field={field}
				label={fieldLabel}
				filterType="any"
				view={({ label, onRemove, onSelect, options }) => {
					return (
						<fieldset>
							<h3>{label}</h3>
							<ul>
								{options &&
									options.map((option, key) => {
										if (option.value === 'true') {
											const checked = option.selected;

											return (
												<li key={`clip_has_audiogram-checkbox-${key}`}>
													<Checkbox
														id={`has_audiogram-${key}`}
														label="Has Audiogram"
														checked={checked || false}
														onChange={() =>
															checked ? onRemove('true') : onSelect('true')
														}
													/>
												</li>
											);
										}
									})}
							</ul>
						</fieldset>
					);
				}}
			/>
			<Facet
				field="clip_type"
				label="Clip Type"
				view={({ label, onRemove, onSelect, options }) => {
					return (
						<fieldset>
							<ul>
								{options &&
									options.map((option, key) => {
										if (option.value === 'takeaway') {
											const checked = option.selected;

											return (
												<li key={`clip_type-checkbox-${key}`}>
													<Checkbox
														id={
															option.id ||
															option.value.replace(/\s+/g, '-').toLowerCase() +
																key
														}
														label="Displayed on Episode Page"
														checked={checked || false}
														onChange={() =>
															checked
																? onRemove(option.value)
																: onSelect(option.value)
														}
													/>
												</li>
											);
										}
									})}
							</ul>
						</fieldset>
					);
				}}
			/>
		</div>
	);
};

export default ClipTypeFilter;
