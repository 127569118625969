import store from 'store/store';
import { getUserProfile } from 'store/selectors/auth';

const IS_PROD = process.env.REACT_APP_RELEASE === 'PROD';

/**
 * Sends tracking data to Vitally. Will only send data in production environment.
 * @param {string} eventName - Name of the event to track in Vitally
 * @param {Object} properties - Optional, any data that should be attached to the event
 */
const trackEvent = (eventName, properties) => {
	if (!window.Vitally || !IS_PROD) return;

	const state = store.getState();
	const authProfile = getUserProfile(state);

	// Do not track if user is in Casted or Casted Demo account
	// or if a Casted employee
	if (
		![5, 20].includes(authProfile.account_id) &&
		!authProfile.email.includes('@casted.us')
	) {
		window.Vitally.track({
			event: eventName,
			userId: authProfile.email,
			properties: properties || {},
		});
	}
};

export { trackEvent };
