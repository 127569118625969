import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { FaAngleDown } from 'react-icons/fa';
import moment from 'moment';

import CastedDropdownButton from 'components/shared/dropdownButton';

const CompareClipDropdown = (props) => {
	const { clips, setSelectedClip } = props;

	return (
		<div className="clip-dropdown">
			<CastedDropdownButton
				title={
					<div className="tracker-value">
						<FaAngleDown className="tracker-carrot" />
					</div>
				}
				size={'sm'}
				classType={'light-pill'}
				className={'clip-options'}
				displayDropArrow={false}
			>
				{clips.map((clip, i) => (
					<Dropdown.Item key={i} onClick={() => setSelectedClip(clip)}>
						<span className="clip-option">{clip.name}</span>|
						<span className="clip-date">
							{moment(clip?.createdAt).format('MM/DD/YY')}
						</span>
					</Dropdown.Item>
				))}
			</CastedDropdownButton>
		</div>
	);
};

export default CompareClipDropdown;
