import React, { useState, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client/react/hooks';

import { Card, Container, Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';

import queries from 'services/graphql/queries';
import Resource from './resourceInfo';

import { FaPlus } from 'react-icons/fa';

const EpisodeResources = (props) => {
	const {
		episodeId,
		canEdit,
		resources: _resources = [],
		onChange = () => {},
	} = props;

	const [resourcesList, setResourcesList] = useState(_resources || []);
	const [resourcesUpdated, setResourcesUpdated] = useState(false);

	const addResource = () => {
		let newResource = {
			id: uuid(),
			episodeId: episodeId,
			name: '',
			url: '',
			thumbnail: '',
			order: resourcesList.length,
		};
		if (!resourcesUpdated) {
			setResourcesUpdated(true);
		}
		setResourcesList(resourcesList.concat(newResource));
	};

	const updateResource = (resource, i) => {
		let list = [...resourcesList];
		list[i] = {
			...list[i],
			name: resource.name,
			url: resource.url,
			thumbnail: resource.thumbnail,
		};
		if (!resourcesUpdated) {
			setResourcesUpdated(true);
		}
		setResourcesList(list);
	};

	const removeResource = (i) => {
		let list = [...resourcesList];
		list.splice(i, 1);
		if (!resourcesUpdated) {
			setResourcesUpdated(true);
		}
		setResourcesList(list);
	};

	useEffect(() => {
		if (resourcesUpdated) {
			onChange(resourcesList);
		}
	}, [resourcesList]);

	const reorder = async (list, startIndex, endIndex) => {
		let result = Array.from(list);
		let [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.forEach((item) => {
			if (result.indexOf(item) !== item.order) {
				item.order = result.indexOf(item);
			}
		});
		setResourcesList(result);
	};

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		if (!result.destination) return;

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		)
			return;

		reorder(resourcesList, result.source.index, result.destination.index);
	};

	const renderResources = () => {
		return resourcesList.map((resource, index) => (
			<Draggable
				key={resource.id}
				draggableId={resource.id}
				index={index}
			>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={getItemStyle(
							snapshot.isDragging,
							provided.draggableProps.style
						)}
					>
						<Resource
							resource={resource}
							key={index}
							onDelete={removeResource}
							updateResource={updateResource}
							disabled={!canEdit}
						/>
						<div className="divider w-100"></div>
					</div>
				)}
			</Draggable>
		));
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		flex: 1,

		// styles we need to apply on draggables
		...draggableStyle,
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? '#f6f9fa' : '#ffffff',
		flex: 1,
	});

	// Render func
	return (
		<div className="resources">
			{resourcesList.length > 0 && (
				<Container className="p-0">
					<Row className="resource-list">
						<Col>
							<DragDropContext
								onDragEnd={(result) => onDragEnd(result)}
							>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
											style={getListStyle(
												snapshot.isDraggingOver
											)}
										>
											{resourcesList.map(
												(resource, index) => {
													return (
														<Draggable
															key={resource.id}
															draggableId={
																resource.id
															}
															index={index}
														>
															{(
																provided,
																snapshot
															) => (
																<div
																	ref={
																		provided.innerRef
																	}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided
																			.draggableProps
																			.style
																	)}
																>
																	<Resource
																		resource={
																			resource
																		}
																		key={
																			resource.id
																		}
																		onDelete={
																			removeResource
																		}
																		updateResource={
																			updateResource
																		}
																		index={
																			index
																		}
																		disabled={
																			!canEdit
																		}
																	/>
																	<div className="divider w-100"></div>
																</div>
															)}
														</Draggable>
													);
												}
											)}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</Col>
					</Row>
				</Container>
			)}
			{canEdit && (
				<Row className="add-guests mt-2">
					<Col>
						<Card className="p-4">
							<div className="add-item resource w-100">
								<div
									className="add-item--inner d-flex justify-content-center m-2"
									onClick={() => addResource({})}
								>
									<div className="py-3">
										<FaPlus className="add-item--icon mb-1" />
										<p>ADD RESOURCE</p>
									</div>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default EpisodeResources;
