import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Container, Row, Col } from 'react-bootstrap';
import { Tab, Nav } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import CollectionSettings from 'components/shows/settings/collectionSettings';
import Permissions from 'components/shows/settings/permissions';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const ShowAdmin = (props) => {
	const { match, history, permissions } = props;
	const { params } = match;
	const { showId, accountId } = params;
	const { featureCollectionLevelPermissions } = useFlags();

	const usersRolePermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.USERS, showId)
				.rolePermission
		: {};

	// State Hooks
	const [tab, setTab] = useState('settings');

	const {
		data: collectionData,
		loading: collectionLoading,
		error,
	} = useQuery(queries.podcastById, {
		variables: { podcastId: showId },
		fetchPolicy: 'cache-and-network',
	});
	const { podcast = {} } = collectionData || {};

	const [
		updatePodcast,
		{ data: updateData, loading: updateLoading, error: updateError },
	] = useMutation(mutations.updatePodcastById);

	if (collectionLoading) {
		return (
			<div className="collection-admin-loading">
				<Spinner animation="grow" variant="info" />
			</div>
		);
	}

	return (
		<div className="show-admin--container">
			<Tab.Container
				defaultActiveKey="settings"
				activeKey={tab}
				onSelect={(k) => setTab(k)}
			>
				<header className="show-admin--header">
					<Container>
						<Row className="show-header">
							<Col className="show-header--nav">
								<Row>
									<Nav className="flex-row">
										<Nav.Item>
											<Nav.Link eventKey="settings">
												<p className="show-nav--title">
													Collection Settings
												</p>
											</Nav.Link>
										</Nav.Item>
										{featureCollectionLevelPermissions &&
											usersRolePermission.canView && (
												<Nav.Item>
													<Nav.Link eventKey="permissions">
														<p className="show-nav--title">
															Permissions
														</p>
													</Nav.Link>
												</Nav.Item>
											)}
									</Nav>
								</Row>
							</Col>
						</Row>
					</Container>
				</header>

				<Tab.Content>
					<Tab.Pane eventKey="settings">
						<CollectionSettings
							podcast={podcast}
							updatePodcast={updatePodcast}
							history={history}
							showId={showId}
							accountId={accountId}
						/>
					</Tab.Pane>
					<Tab.Pane eventKey="permissions">
						<Permissions
							podcast={podcast}
							updatePodcast={updatePodcast}
							collectionId={showId}
							accountId={accountId}
						/>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
});

export default withRouter(connect(mapStateToProps)(ShowAdmin));
