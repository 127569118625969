import React from 'react';
import styles from './styles.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchTrigger = (props) => {
	const {
		placeholder = 'Search your content here.',
		onClick = () => {},
	} = props;

	return (
		<div className={styles.searchTrigger}>
			<div className={styles.placeholder} onClick={onClick}>
				<FontAwesomeIcon
					className={styles.searchIcon}
					icon={faSearch}
					size="sm"
				/>
				{placeholder}
			</div>
		</div>
	);
};

export default SearchTrigger;
