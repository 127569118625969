import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { Dropdown, Row, Col, Form } from 'react-bootstrap';
import { AiOutlineEllipsis } from 'react-icons/ai';
import Toast from 'services/toast';

import ConfirmationModal from 'components/shared/confirmationModal';
import ApiKeyModal from 'components/settings/apiKeys/apiKeyModal';
import CopyToClipboardButton from 'components/shared/copyToClipboardButton';
import { trackEvent } from 'services/vitally';

const ApiKeyListRow = (props) => {
	// Props
	const { apiKey, accountId } = props;

	const { name, clientId } = apiKey;

	const [editKey, setEditKey] = useState(false);
	const [confirmRevoke, setConfirmRevoke] = useState(false);

	/**
	 * Refs
	 */
	// Hold actions in a DOM ref so we can protect it's click from row click
	const actions = useRef(null);
	const copyIdRef = useRef(null);

	const [revokeApiKey] = useMutation(mutations.revokeApiKey, {
		refetchQueries: ['apiKeys'],
	});

	const revokeKey = async () => {
		await revokeApiKey({
			variables: {
				id: apiKey.id,
			},
		});
		setConfirmRevoke(false);
		Toast.success('Access revoked');
		trackEvent('delete-api-key', { keyName: name });
	};

	return (
		<div className="api-key-list--key">
			<Row className="key--row">
				<Col xs="3" className="key-row--name pl-3">
					<div>{name}</div>
				</Col>
				<Col className="key-row--clientId">
					<Row>
						<Col>
							<Form.Control
								ref={copyIdRef}
								className="client-id-text"
								value={clientId}
								readOnly
							/>
						</Col>
						<Col>
							<CopyToClipboardButton
								className="copy-client-id"
								copyRef={copyIdRef}
								btnText="Copy"
							/>
						</Col>
					</Row>
				</Col>
				<Col xs="auto" className="key-row--actions" ref={actions}>
					<Dropdown className="key--actions">
						<Dropdown.Toggle id="key-actions" as="div">
							<AiOutlineEllipsis fontSize={20} />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => setEditKey(true)}>
								Edit
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setConfirmRevoke(true);
								}}
							>
								Revoke
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
			</Row>

			<ApiKeyModal
				title={'Edit Key Information'}
				edit={true}
				isOpen={editKey}
				keyData={apiKey}
				onHide={() => {
					setEditKey(false);
				}}
				accountId={accountId}
			/>

			<ConfirmationModal
				isOpen={confirmRevoke}
				title="Revoke Access"
				confirmationText="Are you sure you want to revoke this key? It can no longer be used to access the Casted API."
				confirmationButtonLabel="REVOKE"
				onCancel={() => {
					setConfirmRevoke(false);
				}}
				onConfirm={revokeKey}
			/>
		</div>
	);
};

export default ApiKeyListRow;
