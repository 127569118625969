import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

export class ObjectPermissionSlugs {
	static SHOWS = 'shows';
	static EPISODES = 'episodes';
	static CLIPS = 'clips';
	static TRANSCRIPTS = 'transcripts';
	static IMPORTED_CONTENT = 'imported-content';
	static METRICS = 'metrics';
	static USERS = 'users';
	static THEMES = 'themes';
	static TAKEAWAYS = 'takeaways';
	static SAML = 'saml';
	static PLAYLISTS = 'playlists';
	static PUBLISH = 'publish';
}

/**
 * Returns a permission object instance found in the given permissions based on
 * the provided permission object slug and collectionID.
 *
 * @param {string} permissionObjectSlug - The slug that correlates to the permission_object to query.
 * @param {uuid} [collectionId] - UUID of collection to check permissions against.
 * @returns {object} - Permission Object
 */
const getPermission = (
	permissions,
	permissionObjectSlug,
	collectionId = undefined
) => {
	if (
		!permissions ||
		!permissions.accountRole ||
		!permissions.collectionRoles
	) {
		throw TypeError('Missing permissions argument');
	}
	if (!permissionObjectSlug) {
		throw TypeError('Missing permissionObjectSlug argument');
	}

	const accountRole = permissions.accountRole.find(
		(x) => x.slug === permissionObjectSlug
	);

	if (permissionObjectSlug && !collectionId) {
		return accountRole;
	} else {
		// If there are collection permissions return those,
		// otherwise use the account role permissions.
		const collectionRoleIds = Object.keys(permissions.collectionRoles);
		if (collectionRoleIds.includes(collectionId)) {
			return permissions.collectionRoles[collectionId]?.find(
				(x) => x.slug === permissionObjectSlug
			);
		} else {
			return accountRole;
		}
	}
};

const getCanView = (
	permissions,
	permissionObjectSlug,
	collectionId = undefined
) => {
	return getPermission(permissions, permissionObjectSlug, collectionId)
		.rolePermission?.canView;
};

const getCanEdit = (
	permissions,
	permissionObjectSlug,
	collectionId = undefined
) => {
	return getPermission(permissions, permissionObjectSlug, collectionId)
		.rolePermission?.canEdit;
};

export { getPermission, getCanView, getCanEdit };
