import React, { useState } from 'react';
import { trackEvent } from 'services/vitally';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import { setSelection, setHasSelection } from 'store/reducers/data/transcript';

// Apollo/GraphQL
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';

// External components
import { Form, Badge, Row, Col, Card } from 'react-bootstrap';
import { FaTimes, FaCut } from 'react-icons/fa';
import MaskedInput from 'react-text-mask';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Internal components
import Button from 'components/shared/button';
import TagsInput from 'components/shared/tagsInput';

// Internal utils
import { formatValue } from 'utils';
import { PODCAST_COLLECTION_TYPE } from 'components/constants';

const CreateClipCard = (props) => {
	/** Props **/
	const {
		onClose = () => {},
		episodeId,
		canCreateTakeaway,
		collectionType,
		/* Redux props start */
		selection,
		hasSelection,
		/* Redux props end */
	} = props;

	const { featureClipImprovements, featurePromotions } = useFlags();

	/** State **/
	const [clipName, setClipName] = useState('');
	const [clipDescription, setClipDescription] = useState('');
	const [clipTags, setClipTags] = useState([]);
	const [clipType, setClipType] = useState('promo');
	const [applyPromo, setApplyPromo] = useState(false);

	/** Mutations **/
	const [createClip, { data: newClipData }] = useMutation(
		mutations.createClip,
		{ refetchQueries: ['ClipsInEpisode'] }
	);

	/** Methods **/
	const createNewClip = async (event) => {
		const form = event.currentTarget;
		event.preventDefault();

		if (form.checkValidity() === false) {
			event.stopPropagation();
			return false;
		}

		const clipData = {
			episodeId,
			name: clipName,
			description: clipDescription,
			keywords: clipTags,
			type: clipType,
			startTime: selection.startTime || 0,
			endTime: selection.endTime || 0,
			status: 'active',
			applyPromo: applyPromo,
		};
		await createClip({ variables: { clip: clipData } });
		trackEvent('create-clip', { itemId: episodeId, clipName, clipType });
		onClose();
	};

	const removeTag = (tagToRemove) => {
		setClipTags(clipTags.filter((tag) => tag != tagToRemove));
	};

	const renderTag = (tag) => (
		<Badge className="tag" key={tag.tag + tag.key}>
			{tag.tag}
			<a onClick={() => removeTag(tag.tag)}>
				<FaTimes size={10} className="remove-tag" />
			</a>
		</Badge>
	);

	const handleCheck = (target) => {
		target ? setClipType('takeaway') : setClipType('promo');
	};

	const handleStartInput = (input) => {
		input.persist();

		if (input.target.id === document.activeElement.id) {
			const timeArray = input.target.value.replaceAll(' ', '0').split(':');

			let timeInSeconds = +timeArray[0] * 60 + +timeArray[1];

			if (timeInSeconds < 0) timeInSeconds = 0;
			if (selection.endTime && !hasSelection) dispatch(setHasSelection(true));
			dispatch(
				setSelection({
					startTime: timeInSeconds,
					startIndex: null,
					endTime: selection.endTime,
					endIndex: selection.endIndex,
				})
			);
		}
	};

	const handleEndInput = (input) => {
		input.persist();

		if (input.target.id === document.activeElement.id) {
			const timeArray = input.target.value.replaceAll(' ', '0').split(':');

			let timeInSeconds = +timeArray[0] * 60 + +timeArray[1];

			if (timeInSeconds < selection.startTime)
				timeInSeconds = selection.startTime;
			if (selection.startTime && timeInSeconds) dispatch(setHasSelection(true));
			dispatch(
				setSelection({
					startTime: selection.startTime,
					startIndex: selection.startIndex,
					endTime: timeInSeconds,
					endIndex: null,
				})
			);
		}
	};

	/** Render **/
	return (
		<Card className="clip-card-modal p-3">
			<Form onSubmit={createNewClip}>
				<Row>
					<Col xs={9} className="d-flex align-items-center">
						<FaCut />
						<h5 className="my-0 mx-2">Create Clip</h5>
					</Col>
					<Col xs={3} className="d-flex justify-content-end">
						<FaTimes className="clip-close" onClick={onClose} />
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<Form.Group>
							<Form.Label>Clip Time</Form.Label>
							<div className="d-flex">
								<MaskedInput
									id="clipStartTimeInput"
									className="ticker--time form-control"
									mask={[/[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
									placeholderChar=" "
									guide={true}
									value={formatValue(selection.startTime)}
									onChange={(input) => handleStartInput(input)}
								/>
								<div className="d-flex align-items-center">
									<p className="m-0 px-1 font-weight-bold">-</p>
								</div>
								<MaskedInput
									id="clipEndTimeInput"
									className="ticker--time form-control"
									mask={[/[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
									placeholderChar=" "
									guide={true}
									value={formatValue(selection.endTime)}
									onChange={(input) => handleEndInput(input)}
								/>
							</div>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<Form.Group>
							<Form.Label>Clip Name</Form.Label>
							<Form.Control
								required
								value={clipName}
								onChange={(e) => setClipName(e.target.value)}
								type="text"
								placeholder="Name this clip."
							/>
						</Form.Group>
						{featureClipImprovements && (
							<Form.Group>
								<Form.Label>Clip Description</Form.Label>
								<Form.Control
									required
									value={clipDescription}
									onChange={(e) => setClipDescription(e.target.value)}
									type="text"
									placeholder="This clip is about ..."
								/>
							</Form.Group>
						)}
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<Form.Group>
							<Form.Label>Clip Tags</Form.Label>
							<TagsInput
								className="clip-tags"
								tags={clipTags}
								onChange={(tags) => setClipTags(tags)}
								renderTag={renderTag}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<Form.Group>
							{featureClipImprovements && featurePromotions && (
								<Form.Check
									label={`Play promotions on this clip embed`}
									onChange={() => setApplyPromo(!applyPromo)}
									checked={applyPromo}
								/>
							)}
							<Form.Check
								label={`Display on ${
									collectionType === PODCAST_COLLECTION_TYPE.serial
										? 'episode'
										: 'casted'
								} page`}
								onChange={(e) => handleCheck(e.target.checked)}
								disabled={!canCreateTakeaway}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col xs="12" className="d-flex justify-content-end pt-3">
						<Button type="submit" variant="success">
							Create Clip <FaCut />
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	selection: state.transcript.selection,
	hasSelection: state.transcript.hasSelection,
});

export default connect(mapStateToProps)(CreateClipCard);
