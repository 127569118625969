import React, { useEffect, useRef, useState } from 'react';

import { Button, Overlay, Tooltip } from 'react-bootstrap';

const GenerateButton = (props) => {
	const {
		createShare = async () => {},
		buttonText = '',
		tooltipText = '',
		title = '',
		isLinkTab = false,
	} = props;

	const [showMessage, setShowMessage] = useState(false);

	const target = useRef(null);

	useEffect(() => {
		if (showMessage) {
			setTimeout(() => {
				setShowMessage(false);
			}, 5000);
		}
	}, [showMessage]);

	const onClick = async () => {
		await createShare();
		setShowMessage(true);
	};

	return (
		<div className="copy-share" ref={target}>
			<Button
				className={`${isLinkTab ? 'w-25' : 'w-50'}`}
				variant="success"
				onClick={() => onClick()}
				disabled={title.trim() === ''}
			>
				{buttonText}
			</Button>
			<Overlay show={showMessage} placement="left" target={target.current}>
				<Tooltip className="link-copied-tooltip">{tooltipText}</Tooltip>
			</Overlay>
		</div>
	);
};

export default GenerateButton;
