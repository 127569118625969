import React from 'react';
import styles from './styles.scss';

import { WithSearch, Facet } from '@elastic/react-search-ui';

import {
	CheckboxList,
	TagListFilter,
	ClipTypeFilter,
	ContentTypeFilter,
	DateFilter,
} from '../base';

import { RECORD_TYPES } from '../constants';

const SearchSidebar = (props) => {
	const {
		filters = [
			<CheckboxList fieldLabel="Collection" field="podcast_name" />,
			<TagListFilter fieldLabel="Item Tags" field="episode_keywords" />,
			<TagListFilter fieldLabel="Clip Tags" field="clip_keywords" />,
			<ContentTypeFilter
				fieldLabel="Content Type"
				field="record_type"
				allowedRecordTypes={[
					RECORD_TYPES.EPISODE_TRANSCRIPT,
					RECORD_TYPES.EPISODE,
					RECORD_TYPES.CLIP,
				]}
			/>,
			<DateFilter fieldLabel="Item Date" field="episode_published_at" />,
			<ClipTypeFilter fieldLabel="Clip Type" field="clip_has_audiogram" />,
		],
	} = props;

	return (
		<WithSearch mapContextToProps={({ setCurrent }) => ({ setCurrent })}>
			{({ setCurrent }) => {
				return (
					<div className={styles.sidebarFilters}>
						<CheckboxList field="Collection" fieldLabel="podcast_name" />
						<div className={styles.floatingContent}>
							<h4>Filter your search results.</h4>
							{filters.map((filter, i) =>
								React.cloneElement(filter, {
									key: i,
									setCurrent: (page) => {
										setCurrent(page);
									},
								})
							)}
						</div>
					</div>
				);
			}}
		</WithSearch>
	);
};

export default SearchSidebar;
