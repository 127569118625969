import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwtDecode from 'jwt-decode';

import { dispatch } from 'store/store';
import { unsetLoggedIn } from 'store/reducers/data/auth';
import { getAuthSingleton } from 'services/auth';

const ROOT_URL = `${process.env.REACT_APP_API_HOST}` || 'localhost:8001';

let apolloClient = null;

export const getBearerTokenAsync = () => {
	const AuthSingleton = getAuthSingleton();

	if (AuthSingleton) {
		const originalAccessTok = AuthSingleton.getAccessToken();

		if (originalAccessTok) {
			const decoded = jwtDecode(originalAccessTok);
			const { exp: expiration } = decoded;
			const isExpired = expiration * 1000 <= Date.now();

			if (isExpired) {
				return AuthSingleton.refreshToken()
					.then((newAccessTok) => `Bearer ${newAccessTok}`)
					.catch(async (e) => {
						let status = e.statusCode;
						let msg = e.message;

						// handle "axios" type error
						if (e && e.response) {
							status = e.response.status;
							msg = e.response.data;
						}

						if (status > 300) {
							dispatch(unsetLoggedIn());
							await apolloClient.clearStore();
							AuthSingleton.signOut();
						}
					});
			} else {
				return Promise.resolve(`Bearer ${originalAccessTok}`);
			}
		}
	}

	return Promise.resolve(`Bearer ${null}`);
};

export const initApolloClient = () => {
	const httpLink = createHttpLink({
		uri: `${ROOT_URL}/graphql`,
	});

	const authLink = setContext(async (_, { headers }) => {
		const token = await getBearerTokenAsync();

		return {
			headers: {
				...headers,
				authorization: token,
			},
		};
	});

	apolloClient = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache({
			addTypename: true,
			dataIdFromObject: (object) => {
				return `${object.__typename}:${
					object.id || object._id || object.category_id
				}`;
			},
		}),
	});
};

export const getApolloClient = () => {
	if (!apolloClient) {
		initApolloClient();
	}

	return apolloClient;
};
