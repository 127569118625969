import React from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client/react/hooks';

import queries from 'services/graphql/queries';

import { Spinner } from 'react-bootstrap';

import ShareList from './shareList';
import { getSelectedAccount } from 'store/selectors/accounts';

// Shared Components
import podcat from 'assets/images/podcat-playing-v2.png';

const Shares = (props) => {
	const { selectedAccountId: accountId, selectedAccount } = props;

	let variables = { accountId, limit: 1 };

	/** Queries & Mutations **/
	const { data, loading } = useQuery(queries.getShares, {
		variables,
		fetchPolicy: 'no-cache',
	});
	const { shares = [] } = data || {};

	const queryVars = {
		variables: {
			accountId: accountId,
			sortField: 'firstName',
			sortOrder: 'ASC',
			offset: 0,
			includeInternalUsers: selectedAccount.name === 'Casted',
		},
		fetchPolicy: 'cache-and-network',
	};

	const { data: { accountUsers = [] } = {}, loading: loadingUsers } =
		useQuery(queries.accountUsers, queryVars);

	if (loading || loadingUsers) {
		return (
			<div className="share-loading-container">
				<Spinner
					className="share-loading"
					animation="grow"
					variant="info"
				/>
			</div>
		);
	}

	const renderSharesLists = () => {
		if (shares.length > 0) {
			return <ShareList users={accountUsers} />;
		} else {
			return (
				<div className="share-content-empty">
					<img className="podcat" src={podcat} />
					<p>Podcat will allow you to manage your shares here.</p>
				</div>
			);
		}
	};

	return (
		<>
			<div className="shares">
				<div className="shares-header-row">
					<h2 className="shares-header">Share Center</h2>
					<p>
						View, manage, and analyze your shares across all of your
						content.
					</p>
				</div>
				<div className="share-sections">{renderSharesLists()}</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
	modals: state.ui.modals,
	selectedAccountId: state.accounts.selectedAccountId,
	selectedAccount: getSelectedAccount(state),
});

export default connect(mapStateToProps)(Shares);
