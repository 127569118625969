import { createStore, applyMiddleware, compose } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

import storage from 'redux-persist/lib/storage';
import LogRocket from 'logrocket';

import reducer from './reducers.js';

import {
	EpisodeUnsavedChanges,
	ThemeUnsavedChanges,
	PlaylistUnsavedChanges,
	DIALOGS,
} from 'store/dialogs';

const logger = createLogger({ collapsed: true });
export const history = createHistory({
	getUserConfirmation(message, callback) {
		const messageObj = JSON.parse(message);
		const { dialog } = messageObj;

		switch (dialog) {
			case DIALOGS.EPISODE_UNSAVED_CHANGES:
				EpisodeUnsavedChanges(messageObj, callback);
				break;
			case DIALOGS.THEME_UNSAVED_CHANGES:
				ThemeUnsavedChanges(messageObj, callback);
				break;
			case DIALOGS.PLAYLIST_UNSAVED_CHANGES:
				PlaylistUnsavedChanges(messageObj, callback);
				break;
			default:
				break;
		}
	},
});

const { routerMiddleware } = createReduxHistoryContext({
	history: createHistory(),
});

const enhancers = [];
const middleware = [thunk, routerMiddleware, LogRocket.reduxMiddleware()];

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}

	middleware.push(logger);
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'accounts', 'metricsData'],
	stateReconciler: autoMergeLevel1,
};

const persistedReducer = persistReducer(persistConfig, reducer(history));

const store = createStore(persistedReducer, {}, composedEnhancers);

function isStoreInitialized() {
	if (!store) {
		return false;
	}

	return true;
}

export function dispatch(action) {
	if (!isStoreInitialized()) {
		return null;
	}

	return store.dispatch(action);
}

export function getState() {
	if (!isStoreInitialized()) {
		return null;
	}

	return store.getState();
}

export const persistor = persistStore(store);

export default store;
