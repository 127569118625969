import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

import { getAuthSingleton } from 'services/auth';
import ContentList from 'components/sidebar/nav/contentList';
import LoggedInUserPopover from 'components/sidebar/loggedInUserPopover';
import NavShowElement from 'components/sidebar/nav/navShowElement';
import StrategyList from 'components/sidebar/nav/strategyList';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';
import logo from '../../assets/logos/casted-logo-horizontal.svg';

const Sidebar = (props) => {
	const {
		isOpen,
		logout,
		authProfile,
		selectedAccountId,
		accounts,
		permissions,
		match,
	} = props;
	const { params } = match;
	const { showId, accountId } = params;

	const metricPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.METRICS).rolePermission
		: {};

	const themesPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.THEMES).rolePermission
		: {};

	const onAccountSwitch = async (accountId) => {
		const authSvc = getAuthSingleton();

		if (accountId !== selectedAccountId) {
			await authSvc.refreshTokenForAccount(accountId);
			props.history.push(`/account/${accountId}/allContent/managedContent`);
		}
	};

	const onUsersClick = () => {
		props.history.push(`/account/${accountId}/users`);
	};

	const onApiKeysClick = () => {
		props.history.push(`/account/${accountId}/apiKeys`);
	};

	const onSsoClick = () => {
		props.history.push(`/account/${accountId}/ssoSettings`);
	};

	const onAccountIntegrationsClick = () => {
		props.history.push(`/account/${accountId}/accountIntegrations`);
	};
	/*
	<FaChartLine className="option-icon" />
*/

	const allContentNavItems = (
		<React.Fragment>
			<ContentList accountId={parseInt(accountId, 10)} />
			{showId && (
				<div className="sidebar-option">
					<NavShowElement />
				</div>
			)}
		</React.Fragment>
	);

	return (
		<div
			id="sidebar"
			className={`sidebar ${isOpen ? '' : 'sidebar-collapsed'}`}
		>
			<div className="sidebar-header">
				<NavLink to={'/'}>
					<img src={logo} alt="Casted" className="sb-casted-logo" />
				</NavLink>
			</div>

			<StrategyList
				accountId={parseInt(accountId, 10)}
				metricPermission={metricPermission}
				themesPermission={themesPermission}
			/>

			{allContentNavItems}

			<LoggedInUserPopover
				authProfile={authProfile}
				selectedAccountId={selectedAccountId}
				accounts={accounts}
				logout={logout}
				onAccountSwitchClick={onAccountSwitch}
				permissions={permissions}
				onUsersClick={onUsersClick}
				onApiKeysClick={onApiKeysClick}
				onSsoClick={onSsoClick}
				onAccountIntegrationsClick={onAccountIntegrationsClick}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	authProfile: state.auth.profile,
	selectedAccountId: state.accounts.selectedAccountId,
	accounts: state.accounts.accounts,
	permissions: state.auth.permissions,
});

export default withRouter(connect(mapStateToProps)(Sidebar));
