import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import uniqueSlug from 'unique-slug';
import { useFlags } from 'launchdarkly-react-client-sdk';

// External Components
import { Button, Form, Spinner } from 'react-bootstrap';

// Internal Libraries
import {
	EPISODE_EMBED_TYPE,
	PLAYLIST_EMBED_TYPE,
	SHARE_MODAL_TYPE,
} from 'components/constants';
import GenerateButton from '../../generateButton';
import ShareForm from 'components/shared/shareForm';
import { getBucketPrefix } from '../../../../../utils';

const EmbedTab = (props) => {
	const {
		isOpen,
		shareModalType,
		baseUrl,
		contentSlug,
		videoEnabled,
		name,
		selectedAccountId,
		shareId,
	} = props;

	const { featureCastedPlayer } = useFlags();

	const baseEmbedUrl = `${baseUrl}/embed/v2`;

	const bucketEnv = getBucketPrefix();

	const castedPlayerUrl = `https://${bucketEnv}player.casted.us/casted-player.js`;

	// Episode Embed URLs
	const episodeSmallPlayerUrl = `${baseEmbedUrl}/smallPlayer/${contentSlug}`;
	const episodeSmallPlayerUrlTabs = `${baseEmbedUrl}/smallPlayer/${contentSlug}/takeaways/guests/transcript/resources/subscribe`;
	const episodeRegularPlayerUrl = `${baseEmbedUrl}/regularPlayer/${contentSlug}`;
	const episodeRegularPlayerUrlTabs = `${baseEmbedUrl}/regularPlayer/${contentSlug}/takeaways/guests/transcript/resources/subscribe`;
	const episodeFullPlayerUrl = `${baseEmbedUrl}/full/${contentSlug}`;

	// Playlist Embed URLs
	const basePlaylistEmbedUrl = `${baseEmbedUrl}/playlist/${contentSlug}`;
	const playlistSmallPlayerUrl = `${basePlaylistEmbedUrl}/smallPlayer`;
	const playlistMediumPlayerUrl = `${basePlaylistEmbedUrl}/mediumPlayer`;
	const playlistLargePlayerUrl = `${basePlaylistEmbedUrl}/largePlayer`;

	// if video is disabled, then audio is only option
	const [audioOnly, setAudioOnly] = useState(!videoEnabled);
	const [selectedEmbedType, setSelectedEmbedType] = useState(
		EPISODE_EMBED_TYPE.PLAYER
	);
	const [embedIsLoading, setEmbedIsLoading] = useState(false);
	const [shareEmbedUrl, setShareEmbedUrl] = useState(episodeRegularPlayerUrl);
	const [shareDetails, setShareDetails] = useState({
		title: `${name} Embed ${new Date().toLocaleDateString()}`,
	});
	const { title, destination, description } = shareDetails;

	const [createShare] = useMutation(mutations.createShare);

	const createShareEmbed = async () => {
		const shareSlug = uniqueSlug();
		await copyEmbed(shareSlug);

		const embedType = getEmbedType();
		let share = await createShare({
			variables: {
				share: {
					accountId: parseInt(selectedAccountId, 10),
					itemId:
						shareModalType === SHARE_MODAL_TYPE.EPISODE
							? shareId
							: null,
					playlistId:
						shareModalType === SHARE_MODAL_TYPE.PLAYLIST
							? shareId
							: null,
					title: title,
					destination: destination,
					description: description,
					shareType: 'embed',
					shareSlug,
					contentType:
						shareModalType === SHARE_MODAL_TYPE.PLAYLIST
							? 'playlist'
							: 'item',
					shareOption: {
						embedType,
						audioOnly:
							audioOnly ||
							selectedEmbedType !== EPISODE_EMBED_TYPE.PLAYER,
						hasTabs:
							selectedEmbedType ===
								EPISODE_EMBED_TYPE.SMALL_PLAYER_TABS ||
							selectedEmbedType ===
								EPISODE_EMBED_TYPE.PLAYER_TABS,
					},
				},
			},
		});
		share = share.data.createShare;

		if (share && share.id) {
			trackEvent('create-share-embed', share);
		}
	};
	const copyEmbed = async (slug) => {
		if (featureCastedPlayer && shareEmbedUrl.includes('/video')) {
			await navigator.clipboard.writeText(
				`<div><casted-player account-id="${selectedAccountId}" slug="${contentSlug}" share-slug="${slug}"></casted-player>
				<script type="module" src="https://${bucketEnv}player.casted.us/casted-player.js"></script></div>`
			);
		} else {
			let shareUrl = shareEmbedUrl.search('/share');
			shareUrl = [
				shareEmbedUrl.slice(0, shareUrl + 6),
				`/${slug}`,
				shareEmbedUrl.slice(shareUrl + 6),
			].join('');

			await navigator.clipboard.writeText(
				`<div><iframe width="100%" allow="fullscreen" height="${
					audioOnly ? '215px' : '1000px'
				}" id="casted-embed-${contentSlug}" scrolling="no" style="border: none" src="${shareUrl}"></iframe>` +
					`<script type="text/javascript">window.addEventListener("message", function(message){if(message.origin === "${baseUrl}" ) { ` +
					`if( message.data.event) { ` +
					`if(message.data.event === "castedSizeUpdate") { ` +
					`var casted_episode_player = document.getElementById('casted-embed-' + message.data.payload.slug); ` +
					`if(casted_episode_player) { ` +
					`casted_episode_player.height = message.data.payload.height;` +
					`if(casted_episode_player.contentWindow) {` +
					`casted_episode_player.contentWindow.postMessage({ event: "castedStopUpdate" }, "${baseUrl}");` +
					`}}}}}}, false)</script></div>`
			);
		}
	};

	const getEmbedType = () => {
		switch (selectedEmbedType) {
			case EPISODE_EMBED_TYPE.SMALL_PLAYER:
			case EPISODE_EMBED_TYPE.SMALL_PLAYER_TABS:
			case PLAYLIST_EMBED_TYPE.SMALL:
				return 'smallPlayer';
			case EPISODE_EMBED_TYPE.PLAYER_TABS:
			case EPISODE_EMBED_TYPE.PLAYER:
				return 'regularPlayer';
			case EPISODE_EMBED_TYPE.FULL_PAGE:
				return 'full';
			case PLAYLIST_EMBED_TYPE.MEDIUM:
				return 'mediumPlayer';
			case PLAYLIST_EMBED_TYPE.LARGE:
				return 'largePlayer';
			default:
				return 'regularPlayer';
		}
	};

	// Embed Type OnClick
	const onClick = (newValue) => {
		setSelectedEmbedType(newValue);
	};

	const episodeToggleButtonList = [
		{
			text: 'Small Player',
			value: EPISODE_EMBED_TYPE.SMALL_PLAYER,
		},
		{
			text: 'Small + Tabs',
			value: EPISODE_EMBED_TYPE.SMALL_PLAYER_TABS,
		},
		{
			text: 'Player',
			value: EPISODE_EMBED_TYPE.PLAYER,
		},
		{
			text: 'Player + Tabs',
			value: EPISODE_EMBED_TYPE.PLAYER_TABS,
		},
		{
			text: 'Full Page',
			value: EPISODE_EMBED_TYPE.FULL_PAGE,
		},
	];

	const playlistToggleButtonList = [
		{
			text: 'Small',
			value: PLAYLIST_EMBED_TYPE.SMALL,
		},
		{
			text: 'Medium',
			value: PLAYLIST_EMBED_TYPE.MEDIUM,
		},
		{
			text: 'Large',
			value: PLAYLIST_EMBED_TYPE.LARGE,
		},
	];

	let embedToggleButtonList = [];

	switch (shareModalType) {
		// TODO: Add support for clips
		case SHARE_MODAL_TYPE.CLIP:
			break;
		case SHARE_MODAL_TYPE.PLAYLIST:
			embedToggleButtonList = playlistToggleButtonList;
			break;
		case SHARE_MODAL_TYPE.EPISODE:
		default:
			embedToggleButtonList = episodeToggleButtonList;
			break;
	}

	useEffect(() => {
		let embedUrl = '';

		switch (shareModalType) {
			// TODO: Add support for clips
			case SHARE_MODAL_TYPE.CLIP:
				break;
			case SHARE_MODAL_TYPE.PLAYLIST:
				switch (selectedEmbedType) {
					case PLAYLIST_EMBED_TYPE.SMALL:
						embedUrl = playlistSmallPlayerUrl;
						break;
					case PLAYLIST_EMBED_TYPE.MEDIUM:
						embedUrl = playlistMediumPlayerUrl;
						break;
					case PLAYLIST_EMBED_TYPE.LARGE:
					default:
						embedUrl = playlistLargePlayerUrl;
						break;
				}
				embedUrl += '/share';

				// Support for videos in playlist
				if (!audioOnly) {
					embedUrl += '?video=true';
				}
				break;
			case SHARE_MODAL_TYPE.EPISODE:
			default:
				switch (selectedEmbedType) {
					case EPISODE_EMBED_TYPE.SMALL_PLAYER:
						embedUrl = episodeSmallPlayerUrl;
						embedUrl += '/share';
						break;
					case EPISODE_EMBED_TYPE.SMALL_PLAYER_TABS:
						embedUrl = episodeSmallPlayerUrlTabs;
						embedUrl += '/share';
						break;
					case EPISODE_EMBED_TYPE.PLAYER_TABS:
						embedUrl = episodeRegularPlayerUrlTabs;
						embedUrl += '/share';
						if (!audioOnly) {
							embedUrl += '/video';
						}
						break;
					case EPISODE_EMBED_TYPE.FULL_PAGE:
						embedUrl = episodeFullPlayerUrl;
						embedUrl += '/share';
						break;
					case EPISODE_EMBED_TYPE.PLAYER:
					default:
						embedUrl = episodeRegularPlayerUrl;
						embedUrl += '/share';
						if (!audioOnly) {
							embedUrl += '/video';
						}
						break;
				}
				break;
		}
		if (
			featureCastedPlayer &&
			selectedEmbedType === EPISODE_EMBED_TYPE.PLAYER
		) {
			setEmbedIsLoading(false);
		} else if (shareEmbedUrl !== embedUrl) {
			setEmbedIsLoading(true);
		}

		setShareEmbedUrl(embedUrl);
	}, [selectedEmbedType, audioOnly]);

	useEffect(() => {
		// Listen for preview embed messages
		window.addEventListener(
			'message',
			function (message) {
				// Ensure event is coming from Casted
				if (message.data.event) {
					// Handle events
					switch (message.data.event) {
						case 'castedPlayerReady':
							setEmbedIsLoading(false);
							break;
						case 'castedSizeUpdate':
							document.getElementById(
								`casted-preview-player`
							).height = message.data.payload.height;
							break;
						default:
							break;
					}
				}
			},
			false
		);
		let script = document.createElement('script');
		script.type = 'application/javascript';
		script.src = castedPlayerUrl;
		document.body.appendChild(script);
	}, []);

	return (
		<>
			<div className="embed-options">
				<div className="share-content-options">
					<ShareForm
						share={shareDetails}
						setShareProperty={setShareDetails}
					/>
					<Form>
						<Form.Group>
							<Form.Label>Embed Type</Form.Label>
							<div>
								{embedToggleButtonList.map((button, i) => {
									return (
										<Button
											key={i}
											className={`share-content-button mr-2 mb-2 ${
												selectedEmbedType ===
													button.value && 'active'
											}`}
											variant="outline-primary"
											onClick={() =>
												onClick(button.value)
											}
										>
											{button.text}
										</Button>
									);
								})}
							</div>
						</Form.Group>
						{videoEnabled &&
							(selectedEmbedType ===
								EPISODE_EMBED_TYPE.PLAYER_TABS ||
								selectedEmbedType ===
									EPISODE_EMBED_TYPE.PLAYER ||
								selectedEmbedType ===
									PLAYLIST_EMBED_TYPE.SMALL ||
								selectedEmbedType ===
									PLAYLIST_EMBED_TYPE.MEDIUM ||
								selectedEmbedType ===
									PLAYLIST_EMBED_TYPE.LARGE) && (
								<Form.Group>
									<Form.Label>Embed Options</Form.Label>
									<div className="share-audio-option">
										<Form.Check
											id="check-audio-only"
											type="checkbox"
											className="share--switch"
											label="Audio Only"
											onChange={() =>
												setAudioOnly(!audioOnly)
											}
											checked={audioOnly}
										/>
									</div>
								</Form.Group>
							)}
					</Form>
				</div>
				<GenerateButton
					buttonText="Generate Embed Code"
					createShare={createShareEmbed}
					disabled={title.trim() === ''}
					tooltipText="Embed Copied"
					title={title}
				/>
			</div>
			<div className="embed-preview">
				<h5>Embed Preview</h5>
				<div className="scm-share-content-container">
					{featureCastedPlayer &&
					selectedEmbedType === EPISODE_EMBED_TYPE.PLAYER ? (
						<div style={{ maxHeight: '100%', width: '100%' }}>
							<casted-player
								account-id={selectedAccountId}
								slug={contentSlug}
								isPreview
							></casted-player>
						</div>
					) : (
						<iframe
							src={shareEmbedUrl}
							id="casted-preview-player"
							width="100%"
							style={{ maxHeight: '500px' }}
						></iframe>
					)}
					{embedIsLoading && (
						<div className="embed-loading">
							<Spinner
								className="content-detail--loading"
								animation="grow"
								variant="info"
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(EmbedTab);
