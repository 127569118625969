import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

// External Components
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import GoogleDrive from '@uppy/google-drive';

// Internal Libraries
import { history, dispatch } from 'store/store';
import {
	addPromotionUpload,
	removeAllPromotionUploads,
} from 'store/reducers/data/promotion';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { PROMOTION_POSITION } from 'components/constants';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const PromotionUpload = (props) => {
	const {
		promotion,
		collectionId,
		permissions,
		onUpload = () => {},
		onUploaded = (value) => {},
		onRemoved = () => {},
	} = props;

	const episodePermission = permissions
		? getPermission(
				permissions,
				ObjectPermissionSlugs.EPISODES,
				collectionId
		  ).rolePermission
		: {};

	const { id, accountId } = promotion;

	const [promotionUploadProgress, setPromotionUploadProgress] = useState(0);

	/** UPPY START **/
	const { data: fileUploadValidationData } = useQuery(
		queries.getFileUploadValidationSecret
	);

	const allowedFileTypes = ['audio/*', 'video/*'];

	const uppyConfig = {
		autoProceed: false,
		meta: {
			accountId: accountId,
			promotionId: promotion.id,
			fileUploadValidationSecret:
				fileUploadValidationData?.getFileUploadValidationSecret,
		},
		restrictions: {
			maxNumberOfFiles: 1,
			allowedFileTypes: allowedFileTypes,
		},
	};

	const uppyXhrConfig = {
		endpoint: `${process.env.REACT_APP_FILE_UPLOADER_HOST}/promotionUpload`,
		retryDelays: [0, 1000, 3000, 5000],
	};

	const uppyGoogleDriveConfig = {
		companionUrl: `http://localhost:8089/companion`,
	};

	const uppyFileAdded = (uppy, file) => {
		const nameParts = file.name.split('.');
		const fileExt = nameParts[nameParts.length - 1];
		const fileName = `${promotion.id}.${fileExt}`;

		uppy.setFileMeta(file.id, {
			name: fileName,
		});

		const promotionUpload = {
			id: uuid(),
			fileName: fileName,
		};

		dispatch(addPromotionUpload(promotionUpload));
		uppy.upload();
	};

	const uppyOnUpload = (data) => {
		onUpload();

		Toast.info(
			'Upload in progress',
			<span>Closing your browser will result in losing your file!</span>,
			{ autoClose: false, closeOnClick: false }
		);

		setPromotionUploadProgress(1);
	};

	const uppyOnProgress = (progress) => {
		setPromotionUploadProgress(progress);

		if (progress > 99) {
			setTimeout(() => {
				setPromotionUploadProgress(0);
			}, 1800);
		}
	};

	const uppyOnUploadError = (error) => {
		toast.dismiss();
		Toast.error('Unable to upload promotion', '', {
			autoClose: false,
			closeOnClick: true,
		});
		Sentry.captureException(error);

		// Reset upload states
		setPromotionUploadProgress(null);
	};

	const uppyOnUploadSuccess = async (file) => {
		const nameParts = file.name.split('.');
		let fileExt = nameParts[nameParts.length - 1];
		const fileName = `${promotion.id}.${fileExt}`;

		let fileType = '';

		if (file.type.search('audio') === 0) {
			fileType = 'audio';
		} else if (file.type.search('video') === 0) {
			fileType = 'video';
		}

		onUploaded(fileName, fileType, file);

		trackEvent('promotion-upload', {
			promotionId: promotion.id,
			mediaType: fileExt,
		});

		dispatch(removeAllPromotionUploads());

		toast.dismiss();
		Toast.success('Upload complete!', '', { autoClose: true });
	};

	// Need to create multiple instances of Uppy in order to display separate
	// upload zones to display in the upload wizard
	const uppyPromotion = useMemo(() => {
		return new Uppy(uppyConfig)
			.use(XHRUpload, uppyXhrConfig)
			.use(GoogleDrive, uppyGoogleDriveConfig)
			.on('file-added', (file) => uppyFileAdded(uppyPromotion, file))
			.on('file-removed', (file) => {
				onRemoved();
				dispatch(removeAllPromotionUploads());
			})
			.on('upload', (data) => uppyOnUpload(data))
			.on('progress', (progress) => uppyOnProgress(progress))
			.on('upload-error', (error) => uppyOnUploadError(error))
			.on('upload-success', (file) => uppyOnUploadSuccess(file));
	}, [fileUploadValidationData]);

	// Clean up event handlers etc when the component unmounts.
	useEffect(() => {
		return () => {
			uppyPromotion.close();
		};
	}, []);
	/** UPPY END **/

	const onUploadStart = () => {
		// Start Uppy upload
		if (uppyPromotion.getFiles()?.length > 0) {
			uppyPromotion.upload();
		}
	};

	return (
		<Dashboard
			uppy={uppyPromotion}
			// plugins={['GoogleDrive']}
			disabled={!episodePermission.canEdit}
			inline={true}
			height={250}
			hideCancelButton={false}
			hideUploadButton={true}
			hideRetryButton={true}
			showRemoveButtonAfterComplete={true}
			proudlyDisplayPoweredByUppy={false}
			disableStatusBar={true}
			disableThumbnailGenerator={true}
			onChange={onUploadStart}
		/>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
});

export const PromotionUploadWithRouter = withRouter(
	connect(mapStateToProps)(PromotionUpload)
);
