import React from 'react';

// External Components
import { FaQuestionCircle, FaRegStickyNote } from 'react-icons/fa';

// Internal Libraries
import { CONTENT_TYPE, EXTERNAL_OBJECT_TYPES } from 'components/constants';

// Internal Assets
import podcat from 'assets/images/podcat-playing-v2.png';

const ContentRow = (props) => {
	const { children, objectTypeName, name, image, onClick = () => {} } = props;

	const determineContentType = (objectTypeName) => {
		let contentIcon = '';
		let contentType = '';

		switch (objectTypeName) {
			case EXTERNAL_OBJECT_TYPES.WORDPRESS_BLOG:
			case EXTERNAL_OBJECT_TYPES.WORDPRESS_POST:
				contentIcon = <FaRegStickyNote />;
				contentType = CONTENT_TYPE.written;
				break;
			default:
				contentIcon = <FaQuestionCircle />;
				contentType = 'unknown content';
				break;
		}

		return (
			<>
				{contentIcon}
				<span>{contentType}</span>
			</>
		);
	};

	return (
		<div className="imported-content-row" onClick={onClick}>
			<div className="content-thumbnail">
				<img src={image || podcat} />
			</div>
			<div className="content-info">
				<div className="content-type">
					{determineContentType(objectTypeName)}
				</div>
				<div className="content-name">{name}</div>
			</div>
			{children}
		</div>
	);
};

export { ContentRow };
