export const ACTIONS = {
	HIDE_COLLECTION_SHARE_MODAL:
		'CASTED/COLLECTION_SHARE_MODAL/HIDE_COLLECTION_SHARE_MODAL',
	SHOW_COLLECTION_SHARE_MODAL:
		'CASTED/COLLECTION_SHARE_MODAL/SHOW_COLLECTION_SHARE_MODAL',
};

export const initialState = {
	showCollectionShareModal: false,
};

export const hideCollectionShareModal = () => {
	return {
		type: ACTIONS.HIDE_COLLECTION_SHARE_MODAL,
	};
};

export const showCollectionShareModal = () => {
	return {
		type: ACTIONS.SHOW_COLLECTION_SHARE_MODAL,
	};
};

const collectionShareModalReducer = (state = initialState, action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.HIDE_COLLECTION_SHARE_MODAL:
			return {
				...state,
				showCollectionShareModal: false,
			};
		case ACTIONS.SHOW_COLLECTION_SHARE_MODAL:
			return {
				...state,
				showCollectionShareModal: true,
			};
		default:
			return state;
	}
};

export default collectionShareModalReducer;
