import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import NewShowRssStepWithoutPermissions from './newShowNoPermissions/newShowRssStep';

const NewShowRssStep = (props) => {
	const renderNewShowRssStep = () => {
		return <NewShowRssStepWithoutPermissions {...props} />;
	};

	return renderNewShowRssStep();
};

export default NewShowRssStep;
