import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AiOutlineEllipsis } from 'react-icons/ai';

const RemoveItem = (props) => {
	const { item = {}, onDelete = () => {} } = props;

	const [dropdown, setDropdown] = useState(false);

	const toggleRemoveItem = () => {
		setDropdown(!dropdown);
	};

	return (
		<>
			<div className="remove-item" onClick={toggleRemoveItem}>
				<AiOutlineEllipsis />
			</div>
			<div
				className={`remove-item--dropdown ${dropdown ? 'visible' : ''}`}
				onClick={onDelete}
			>
				<p>Remove</p>
			</div>
		</>
	);
};

export default RemoveItem;
