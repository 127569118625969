import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client/react/hooks';

// External Components
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Form } from 'react-bootstrap';
import { Cell, Pie, PieChart, Legend } from 'recharts';
import { FaAngleDown, FaTemperatureLow } from 'react-icons/fa';

// Internal Libraries
import queries from 'services/graphql/queries';
import { history, dispatch } from 'store/store';
import {
	setThemeContentView,
	THEME_CONTENT_VIEWS,
} from 'store/reducers/ui/themes';
import { CONTENT_INTEGRATION_TYPES } from 'components/constants';
import { getDecimalHundredth, numberSort } from 'utils';

// Internal Components
import BaseTable from 'components/shared/baseTable';
import Button from 'components/shared/button';
import { TableFilter } from './tableFilter';
import MetricEmptyState from 'components/shared/metricEmptyState';
import CastedDropdownButton from 'components/shared/dropdownButton';
import { SegmentsDropdown } from 'components/shared/insights/segmentsDropdown';

// Internal Assets
import hubspotLogo from 'assets/logos/hubspot.svg';
import marketoLogo from 'assets/logos/marketo.svg';
import pardotLogo from 'assets/logos/pardot.svg';
import CastedModal from 'components/shared/modal';
import { CompanyProfileWithRouter } from 'components/shared/companyProfile';

const AudienceTable = (props) => {
	const {
		className = '',
		dateRange,
		selectedAccountId: accountId,
		collectionId = '',
		episodeId = '',
		themeId = '',
		showCompanyProfileInModal = false,
	} = props;

	const [crmIntegration, setCrmIntegration] = useState(null);
	const [activeFilter, setActiveFilter] = useState(0);
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [companyProfileModal, setCompanyProfileModal] = useState({
		show: false,
		companyId: null,
	});
	const [segmentFilter, setSegmentFilter] = useState([]);
	const [includeImpressions, setIncludeImpressions] = useState(false);

	useEffect(() => {
		setResetPaginationToggle(!resetPaginationToggle);
	}, [activeFilter, segmentFilter]);

	const { data: segmentData } = useQuery(queries.getSegmentsByAccountId, {
		variables: { accountId: parseInt(accountId, 10) },
		fetchPolicy: 'no-cache',
	});
	const { segments = [] } = segmentData || {};

	// Determine if account has any shows with an integration to Hubspot, Marketo, or Pardot
	const { data: integrationData } = useQuery(queries.accountIntegrations, {
		variables: { accountId },
	});
	const { accountIntegrations = [] } = integrationData || {};

	useEffect(() => {
		if (accountIntegrations) {
			let foundIntegrations = accountIntegrations.find(
				(x) =>
					[
						CONTENT_INTEGRATION_TYPES.HUBSPOT,
						CONTENT_INTEGRATION_TYPES.MARKETO,
						CONTENT_INTEGRATION_TYPES.PARDOT,
					].includes(x.integrationName) && x.status === 1
			);
			foundIntegrations &&
				Object.keys(foundIntegrations).length > 0 &&
				setCrmIntegration(foundIntegrations);
		}
	}, [accountIntegrations]);

	let revealVariables = {
		accountId,
		startDate: dateRange.start,
		endDate: dateRange.end,
		includeNoEngagements: includeImpressions,
	};

	if (collectionId) {
		revealVariables.collectionId = collectionId;
	}

	if (episodeId) {
		revealVariables.episodeId = episodeId;
	}

	const {
		data: revealData,
		loading,
		refetch,
	} = useQuery(queries.getRevealData, {
		variables: revealVariables,
	});
	const { getRevealData = [] } = revealData || {};

	const [applySegments, { loading: loadingCompanyIds }] = useLazyQuery(
		queries.getSegmentGroupsCompanyIds,
		{
			variables: {
				accountId,
				startDate: dateRange.start,
				endDate: dateRange.end,
				segmentGroupIds: selectedGroups.map((group) => group.id),
			},
			fetchPolicy: 'cache-and-network',
			onCompleted: (data) => {
				if (data.getSegmentGroupsCompanyIds.length > 0) {
					setSegmentFilter(
						data.getSegmentGroupsCompanyIds.map(
							(companyId) => companyId.id
						)
					);
				} else {
					setSegmentFilter([]);
				}
			},
		}
	);

	useEffect(() => {
		if (selectedGroups.length > 0) {
			applySegments();
		} else {
			setSegmentFilter([]);
		}
	}, [selectedGroups]);

	const linkOnClick = () => {
		dispatch(setThemeContentView(THEME_CONTENT_VIEWS.METRICS));
	};

	const downloadRevealColumns = [
		{
			name: 'Company',
			selector: (row) => (
				<>
					{row.name}{' '}
					<img alt={row.name} src={row.logo} className="table-logo" />
				</>
			),
			minWidth: '200px',
			sortable: false,
		},
		// {
		// 	name: 'Top Theme',
		// 	selector: (row) => {
		// 		if (row.topTheme) {
		// 			return (
		// 				<Link
		// 					to={{
		// 						pathname: `/account/${accountId}/themes/${row.topTheme.id}`,
		// 						state: { from: history.location.pathname },
		// 					}}
		// 					onClick={() => linkOnClick()}
		// 				>
		// 					<Badge
		// 						className={`insights-theme-badge ${
		// 							!row.topTheme.color ? 'no-color' : ''
		// 						}`}
		// 						style={{ backgroundColor: row.topTheme.color }}
		// 					>
		// 						{row.topTheme.name}
		// 					</Badge>
		// 				</Link>
		// 			);
		// 		} else {
		// 			return <></>;
		// 		}
		// 	},
		// 	sortable: false,
		// },
		{
			name: 'CRM (if applicable)',
			selector: (row) => {
				switch (row.integrationType) {
					case CONTENT_INTEGRATION_TYPES.HUBSPOT:
						return <img src={hubspotLogo} width={90} />;
					case CONTENT_INTEGRATION_TYPES.MARKETO:
						return <img src={marketoLogo} width={90} />;
					case CONTENT_INTEGRATION_TYPES.PARDOT:
						return <img src={pardotLogo} width={90} />;
					default:
						return <></>;
				}
			},
			sortable: false,
			omit: crmIntegration === null,
		},
		{
			name: 'Impressions',
			selector: (row) => row.impressions,
			sortable: true,
			omit: !includeImpressions,
			sortFunction: numberSort((row) => row.impressions),
		},
		{
			name: 'Engagements',
			selector: (row) => row.engagements,
			sortable: true,
			sortFunction: numberSort((row) => row.engagements),
		},
		{
			name: 'Last Engagement',
			selector: (row) => {
				let dateArray = row.lastEngagement.value.split('-');
				return dateArray[1] + '-' + dateArray[2] + '-' + dateArray[0];
			},
			sortable: true,
		},
		//{
		//	name: 'Unique Contacts',
		//	selector: (row) => row.uniqueContacts,
		//	sortable: true,
		//},
	];

	let tableData = [];
	const tableFilters = [];
	const castedData = getRevealData.filter(
		(revealData) => !revealData.integrationType?.length
	);
	const crmData = getRevealData.filter(
		(revealData) => revealData.integrationType?.length > 0
	);

	// Determine which dataset we want to display in the table.
	// If an account only have Casted data, then we only want to display the "Identified by Casted" card.
	// Because `activeFilter` is the index of the active filter, the "Identified by Casted" card index
	// will be 0 instead of 1 if `crmData` is not present. This works out fine because in that situation,
	// `getRevealData` and `castedData` will be the same values.
	switch (activeFilter) {
		case 1:
			tableData = castedData;
			break;
		case 2:
			tableData = crmData;
			break;
		case 0:
		default:
			tableData = getRevealData;
			break;
	}

	if (selectedGroups.length > 0) {
		tableData = tableData.filter((company) =>
			segmentFilter.includes(company.companyId)
		);
	}

	// If an account only have Casted data, then we only want to display the "Identified by Casted" card
	if (crmData.length > 0) {
		tableFilters.push({
			id: 0,
			name: 'All Contacts',
			text: 'These are all the contacts who have engaged with your content.',
			total: getRevealData.length,
			value: 1,
			fill: '#A6CEE3',
		});
	}

	tableFilters.push({
		id: 1,
		name: 'Identified by Casted',
		text: 'Contacts who were identified by Casted via IP from Apple, Casted Pages/Players, Google.',
		total: castedData.length,
		value: getDecimalHundredth(castedData.length / getRevealData.length),
		fill: '#3EBB85',
	});

	if (crmData.length > 0) {
		tableFilters.push({
			id: 2,
			name: 'Matched Accounts',
			text: 'Contacts found using Casted landing pages or embed players and matched in CRM.',
			total: crmData.length,
			value: getDecimalHundredth(crmData.length / getRevealData.length),
			fill: '#1F78B4',
		});
	}

	const onClickHandler = (contactRow) => {
		if (showCompanyProfileInModal) {
			setCompanyProfileModal({
				show: true,
				companyId: contactRow.companyId,
				collectionId,
				episodeId,
				themeId,
			});
		} else {
			history.push(
				`/account/${accountId}/company/${contactRow.companyId}`,
				{
					dateRange: {
						startDate: dateRange.start,
						endDate: dateRange.end,
					},
				}
			);
		}
	};

	const localValue = [
		{
			value: 1 - tableFilters[activeFilter],
			fill: '#F7F7F7',
		},
		tableFilters[activeFilter],
	];

	const renderCustomizedLegend = ({ payload }) => {
		if (payload.length > 0 && payload.indexOf(1) !== 0) {
			const legendData = payload[1].payload;

			return (
				<div className="filter-legend">
					<div className="legend-percent">
						{legendData.percent * 100}%
					</div>
					<p className="legend-total">
						{legendData.total.toLocaleString()}
					</p>
				</div>
			);
		}
		return <></>;
	};

	return (
		<>
			<div className={`insights-audience-table-container ${className}`}>
				<div className="insights-audience-table-header">
					<h4>Audience</h4>
					<p>
						Audience shows you which companies are engaging with
						your content and what they are engaging with. Casted
						identifies contacts using CRM connection and
						identification by Casted.
					</p>
				</div>
				<div className="insights-page-body">
					{(tableData.length > 0 || selectedGroups.length > 0) && (
						<div className="insights-table-filters">
							<div className="insights-filter">
								<CastedDropdownButton
									title={
										<div className="selected-value">
											<PieChart
												width={36}
												height={36}
												cx={18}
												cy={18}
												innerRadius={15}
												outerRadius={18}
											>
												<Pie
													width={36}
													height={36}
													innerRadius={15}
													outerRadius={18}
													data={localValue}
													startAngle={90}
													endAngle={450}
													labelLine={false}
													dataKey="value"
													isAnimationActive={false}
													paddingAngle={1}
													fill={
														tableFilters[
															activeFilter
														].fill
													}
												>
													{localValue.map(
														(entry, index) => (
															<Cell
																key={`cell-${index}`}
																fill={
																	entry.fill
																}
															/>
														)
													)}
												</Pie>
												<Legend
													content={
														renderCustomizedLegend
													}
												/>
											</PieChart>
											<div className="value-name">
												{
													tableFilters[activeFilter]
														.name
												}
											</div>
											<FaAngleDown />
										</div>
									}
									classType="round"
									size={'sm'}
									displayDropArrow={false}
								>
									{getRevealData.length > 0 &&
										tableFilters.map((tableFilter, i) => (
											<Dropdown.Item
												onClick={() =>
													setActiveFilter(i)
												}
												disabled={
													activeFilter === i ||
													tableFilters[i].length < 1
												}
											>
												<TableFilter
													key={`filter-${i}`}
													data={tableFilter}
												/>
											</Dropdown.Item>
										))}
								</CastedDropdownButton>
							</div>
							{segments.length > 0 && (
								<SegmentsDropdown
									segments={segments}
									setSelectedGroups={setSelectedGroups}
									selectedGroups={selectedGroups}
									limit={1000}
									segmentRestriction={true}
									value={
										selectedGroups.length > 0 ? (
											`${selectedGroups.length} segments applied`
										) : (
											<div className="default-value">
												Filter by segments
											</div>
										)
									}
								/>
							)}
							<Form.Check
								type="switch"
								className="toggle-include-impressions casted-form"
								id="toggle-include-impressions"
								label="Include low-engagement audience"
								checked={includeImpressions}
								disabled={false}
								onChange={() => {
									refetch({
										includeNoEngagements:
											!includeImpressions,
									});
									setIncludeImpressions(!includeImpressions);
								}}
							/>
						</div>
					)}
					<BaseTable
						values={tableData}
						columns={downloadRevealColumns}
						loading={loading || loadingCompanyIds}
						clickable={true}
						className={'clickable'}
						onClick={onClickHandler}
						pagination={getRevealData.length > 5}
						paginationPerPage={5}
						resetPaginationToggle={resetPaginationToggle}
						noDataComponent={
							<MetricEmptyState metricName={'Audience'} />
						}
					/>
				</div>
			</div>
			<CastedModal
				show={companyProfileModal.show}
				modalSize="lg"
				handleClose={() => {
					setCompanyProfileModal({
						show: false,
						companyId: null,
						collectionId: null,
						episodeId: null,
						themeId: null,
					});
				}}
				className={'company-profile-modal'}
			>
				<CompanyProfileWithRouter
					companyId={companyProfileModal.companyId}
					collectionId={companyProfileModal.collectionId}
					episodeId={companyProfileModal.episodeId}
					themeId={companyProfileModal.themeId}
					parentDateRange={dateRange}
					isRenderedInModal={true}
				/>
			</CastedModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions.accountRole,
	modals: state.ui.modals,
});

export const AudienceTableWithRouter = withRouter(
	connect(mapStateToProps)(AudienceTable)
);
