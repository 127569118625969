import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client/react/hooks';

// Internal Components
import { Button, ColorPicker, Form } from 'components/shared';

// Internal Libraries
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';
import Toast from 'services/toast';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';
import { dispatch } from 'store/store';
import { setPlaylistUpdates } from 'store/reducers/data/playlist';
import { setEditPlaylist } from 'store/reducers/ui/playlists';
import { showPlaylistShareModal } from 'store/reducers/ui/modals/playlistShareModal';

const PlaylistHeader = (props) => {
	const {
		accountId,
		playlist,
		playlistContent,
		playlistUpdates,
		editPlaylist,
		permissions,
	} = props;

	const playlistsPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.PLAYLISTS)
				.rolePermission
		: {};

	/** Queries & Mutations **/
	const { data, loading } = useQuery(queries.podcastsByAccountId, {
		variables: {
			accountId,
			includeEpisodes: false,
		},
		fetchPolicy: 'no-cache',
	});
	const collectionData = data?.podcasts || [];

	// Get a list of valid custom domains
	const collectionCustomDomains = collectionData
		.filter((collection) => collection.customDomain)
		.map((collection) => collection.customDomain);

	// API call to update playlist. We want to refetch the playlist
	// API call after an update is made
	const [updatePlaylist] = useMutation(mutations.updatePlaylistById, {
		refetchQueries: ['playlist'],
	});

	// localPlaylist is an aggregate of the original playlist data and
	// any new data present in playlistUpdates
	const [localPlaylist, setLocalPlaylist] = useState(playlist || {});

	const [isNameInvalid, setIsNameInvalid] = useState(false);

	// Deconstructing playlist to get values
	const { id, name, color, customDomain } = localPlaylist || {};

	useEffect(() => {
		setLocalPlaylist(playlist);
	}, [playlist]);

	const updatePlaylistProperty = (property, value) => {
		// Make sure property exists
		if (playlist.hasOwnProperty(property)) {
			let newPlaylistUpdates = { ...playlistUpdates };

			// Remove update if it matches the original property value.
			// Else, add it to unsaved updates
			if (
				value === playlist[property] &&
				newPlaylistUpdates.hasOwnProperty(property)
			) {
				delete newPlaylistUpdates[property];
			} else {
				newPlaylistUpdates[property] = value;
			}

			// Update localPlaylist to include unsaved edits
			setLocalPlaylist({
				...localPlaylist,
				[property]: value,
			});

			dispatch(setPlaylistUpdates(newPlaylistUpdates));
		}
	};

	const savePlaylistOnClick = async () => {
		if (name === '') {
			setIsNameInvalid(true);
		} else {
			setIsNameInvalid(false);

			// playlist content
			const originalContent = playlist.content;
			const updatedContent = playlistContent.map((content) => {
				return { internal_object_id: content.id };
			});

			let contentIsEqual = true;
			if (originalContent?.length !== updatedContent.length) {
				contentIsEqual = false;
			} else {
				updatedContent.forEach((updatedItem, index) => {
					const originalItem = originalContent[index];
					if (
						originalItem.internal_object_id ===
						updatedItem.internal_object_id
					) {
						contentIsEqual = contentIsEqual;
					} else {
						contentIsEqual = false;
						return contentIsEqual;
					}
				});
			}

			let newPlaylistUpdates = { ...playlistUpdates };
			if (!contentIsEqual) {
				newPlaylistUpdates['content'] = updatedContent;
			}

			if (Object.keys(playlistUpdates).length > 0 || !contentIsEqual) {
				await updatePlaylist({
					variables: {
						accountId: parseInt(accountId, 10),
						playlistId: id,
						playlist: newPlaylistUpdates,
					},
				});
				trackEvent('edit-playlist', { ...playlistUpdates, name: name });

				// Clear out playlistUpdates
				dispatch(setPlaylistUpdates({}));
				Toast.success(
					'Your playlist has been saved!',
					<span>
						You are now able to share or embed this playlist
					</span>
				);

				// Show share modal automatically if they save content the first time
				if (originalContent?.length < 1 && updatedContent.length > 0) {
					dispatch(showPlaylistShareModal());
				}
			}
			dispatch(setEditPlaylist(false));
		}
	};

	return (
		<div className="playlist-header-container">
			{playlistsPermission.canEdit && editPlaylist ? (
				<Form className="playlist-form">
					<Form.Group className="playlist-name">
						<Form.Control
							required
							isInvalid={isNameInvalid}
							value={name}
							placeholder="Playlist Name"
							className="name-input"
							onChange={(e) =>
								updatePlaylistProperty('name', e.target.value)
							}
						/>
					</Form.Group>
					<Form.Group className="playlist-color">
						<Form.Label className="color-label">
							Playlist Color
						</Form.Label>
						<div className="d-flex">
							<ColorPicker
								color={color || '#868e96'}
								onColorChange={(color) =>
									updatePlaylistProperty('color', color)
								}
							/>
						</div>
					</Form.Group>
					<Form.Group className="playlist-custom-domain">
						<Form.Label className="color-label">
							Custom Domain
						</Form.Label>
						<Form.Control
							as="select"
							value={customDomain}
							onChange={(e) =>
								updatePlaylistProperty(
									'customDomain',
									e.target.value
								)
							}
						>
							<option value="">None</option>
							{collectionCustomDomains.map((customDomain, i) => (
								<option key={i}>{customDomain}</option>
							))}
						</Form.Control>
					</Form.Group>
				</Form>
			) : (
				<div className="playlist-attributes">
					<div className="playlist-name">{name}</div>
					<div className="playlist-color-container">
						<label className="color-label">Playlist Color</label>
						<div
							className="playlist-color"
							style={{
								backgroundColor: color,
							}}
						></div>
					</div>
					<div className="playlist-custom-domain-container">
						<label>Custom Domain</label>
						<div className="playlist-name">
							{customDomain || 'None'}
						</div>
					</div>
				</div>
			)}
			<div className="playlist-buttons">
				{playlistsPermission.canEdit && editPlaylist && (
					<Button className="save" onClick={savePlaylistOnClick}>
						Save Playlist
					</Button>
				)}

				{playlistsPermission.canEdit && !editPlaylist && (
					<Button
						className="edit"
						onClick={() => dispatch(setEditPlaylist(true))}
					>
						Edit Playlist
					</Button>
				)}

				{!editPlaylist && (
					<Button
						className="share"
						disabled={playlistContent.length < 1}
						onClick={() => dispatch(showPlaylistShareModal())}
					>
						Share
					</Button>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	permissions: state.auth.permissions,
	playlist: state.playlistData.playlist,
	playlistContent: state.playlistData.playlistContent,
	editPlaylist: state.ui.playlists.editPlaylist,
	playlistUpdates: state.playlistData.playlistUpdates,
});

export default connect(mapStateToProps)(PlaylistHeader);
