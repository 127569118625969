import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import EpisodeListRow from 'components/shows/episodes/episodesList/episodeListRow';
import LoadingEpisodes from 'components/shared/loadingSkeletons/loadingEpisodes';
import Visible from 'react-visibility-sensor';
import Toast from 'services/toast';
import { getSelectedAccount } from 'store/selectors/accounts';

import { Container, Spinner } from 'react-bootstrap';

const EpisodesList = (props) => {
	// props
	const {
		selectedAccountId: accountId,
		selectedAccount,
		showId,
		showStatus,
		showSlug,
		filter,
		featuredEpisode,
		showThumbnail,
		refetchEpisodes,
		customDomain,
		showExternalId,
		youtubeIntegration,
		collectionType,
		disableLinkSharing,
	} = props;

	const { episodeId: featuredEpisodeId } = featuredEpisode || {};

	// Hooks
	const [moreEpisodesAvail, setMoreEpisodesAvail] = useState(true);

	const queryVars = {
		variables: {
			podcastId: showId,
			sortOrder: 'DESC',
			limit: 50,
			offset: 0,
		},
		fetchPolicy: 'cache-and-network',
	};

	useEffect(() => {
		if (filter) {
			queryVars.variables.publicStatus = filter.value;
			refetch(queryVars.variables);
		}
	}, [filter]);

	const {
		data: { episodes = [] } = {},
		loading,
		error,
		fetchMore,
		refetch,
	} = useQuery(queries.episodesByPodcastId, queryVars);
	// End Hooks

	useEffect(() => {
		if (episodes.length >= 50) {
			setMoreEpisodesAvail(true);
		}
	}, [episodes]);

	// If Podcast status changed, refetch episodes in case new eps were fetched
	// Also fire when New Episode dialog closes
	useEffect(() => {
		if (showStatus === 'active') refetch();
	}, [showStatus, refetchEpisodes]);

	// Infinite scroll - get more episodes and add to data layer
	const getMoreEpisodes = () => {
		fetchMore({
			variables: {
				offset: episodes.length,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult.episodes.length) {
					setMoreEpisodesAvail(false);
					return prev;
				}
				return Object.assign(
					{},
					{
						episodes: [
							...prev.episodes,
							...fetchMoreResult.episodes,
						],
					}
				);
			},
		});
	};
	// End Helper Functions

	// Render
	if (loading && !episodes.length) {
		return <LoadingEpisodes />;
	}

	if (error) {
		Toast.error('Error fetching episodes');
		return null;
	}

	return (
		<Container className="episodes">
			<div className="episodes-list">
				{episodes.map((e) => (
					<EpisodeListRow
						key={e.id}
						accountId={accountId}
						episode={e}
						showId={showId}
						showSlug={showSlug}
						showThumbnail={showThumbnail}
						featured={
							e.id === featuredEpisodeId ? featuredEpisode : false
						}
						refreshPage={() => {
							refetch();
						}}
						customDomain={customDomain}
						videoEnabled={selectedAccount.enableVideo}
						showExternalId={showExternalId}
						youtubeIntegration={youtubeIntegration}
						disableLinkSharing={disableLinkSharing}
					/>
				))}
			</div>

			<Visible
				onChange={(isVisible) => {
					if (isVisible && moreEpisodesAvail) getMoreEpisodes();
				}}
			>
				<div style={{ width: '100%', textAlign: 'center' }}>
					{moreEpisodesAvail ? (
						<Spinner animation="border" variant="info" />
					) : (
						<p className="infinite-scroll--bottom">
							That's all the episodes!
						</p>
					)}
				</div>
			</Visible>
		</Container>
	);
	// end Render
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
	selectedAccount: getSelectedAccount(state),
});

export default connect(mapStateToProps)(EpisodesList);
