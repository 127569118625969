import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

import { dispatch } from 'store/store';
import { showEditShareModal } from 'store/reducers/ui/modals/editShareModal';

// Internal Components
import Button from '../../shared/button';
import Checkbox from 'components/shared/checkbox';
import podcat from 'assets/images/podcat-playing.svg';

// Internal Libraries
import { format } from 'date-fns';
import collectionIcon from 'assets/icons/collection-icon.svg';
import itemIcon from 'assets/icons/item-icon.svg';
import playlistIcon from 'assets/icons/playlist-icon.svg';
import clipIcon from 'assets/icons/clip-icon.svg';
import { SHARE_CONTENT_TYPE, SHARE_STATUS } from 'components/constants';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const ShareCard = (props) => {
	const {
		share,
		selectedShares,
		selectShareHandler,
		setSelectedShare,
		setModalOpen,
		accountId,
		history,
		permissions,
		disabled,
		archivedShares,
		setArchivedShares,
	} = props;
	const {
		title,
		shareType,
		createdUser,
		contentType,
		shareSlug,
		status,
		thumbnail,
		collectionId,
	} = share;

	const playlistsPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.PLAYLISTS).rolePermission
		: {};

	const collectionPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.SHOWS).rolePermission
		: {};

	const itemPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.SHOWS, collectionId)
		: false;

	const clipsPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.CLIPS, collectionId)
				.rolePermission
		: {};

	let createdAt = new Date(share.createdAt);
	createdAt = format(createdAt, 'MM/dd/yy');

	const [updateShare] = useMutation(mutations.updateShareById, {
		refetchQueries: ['shares'],
	});

	const unArchiveShare = async () => {
		await updateShare({
			variables: {
				shareId: share.id,
				share: {
					accountId,
					title: share.title,
					status: SHARE_STATUS.ACTIVE,
				},
			},
		});
		setArchivedShares([...archivedShares, share]);
		trackEvent('edit-share', {
			shareId: share.id,
			status: SHARE_STATUS.ACTIVE,
		});
	};

	let icon;
	let color;
	let canEdit;
	switch (contentType) {
		case SHARE_CONTENT_TYPE.collection:
			icon = collectionIcon;
			color = 'navy';
			canEdit = collectionPermission.canEdit;
			break;
		case SHARE_CONTENT_TYPE.item:
			icon = itemIcon;
			color = 'sky';
			canEdit = itemPermission.canEdit;
			break;
		case SHARE_CONTENT_TYPE.playlist:
			icon = playlistIcon;
			color = 'purple';
			canEdit = playlistsPermission.canEdit;
			break;
		case SHARE_CONTENT_TYPE.clip:
			icon = clipIcon;
			color = 'gray';
			canEdit = clipsPermission.canEdit;
			break;
	}

	const renderViewPerformanceButton = () => {
		if (contentType === SHARE_CONTENT_TYPE.clip) {
			return (
				<Button
					className="edit-button"
					variant={status === SHARE_STATUS.ACTIVE ? 'success' : 'outline-dark'}
					title="View Performance"
					onClick={() => {
						setSelectedShare(share);
						setModalOpen(true);
					}}
					disabled={disabled}
				>
					View Performance
				</Button>
			);
		} else {
			return (
				<Link
					to={{
						pathname: `/account/${accountId}/shares/${shareSlug}/metrics`,
						state: { from: history.location.pathname },
					}}
				>
					<Button
						className={`edit-button ${
							contentType === SHARE_CONTENT_TYPE.playlist && 'disabled'
						}`}
						variant={
							status === SHARE_STATUS.ACTIVE ? 'success' : 'outline-dark'
						}
						title={
							contentType === SHARE_CONTENT_TYPE.playlist
								? 'Coming Soon!'
								: 'View Performance'
						}
						disabled={contentType === SHARE_CONTENT_TYPE.playlist || disabled}
					>
						View Performance
					</Button>
				</Link>
			);
		}
	};

	const renderArchiveButton = () => {
		if (status === SHARE_STATUS.HIDDEN) {
			return (
				<Button
					className="edit-button"
					variant="dark"
					title="Unarchive"
					onClick={() => unArchiveShare()}
					disabled={disabled}
				>
					Unarchive
				</Button>
			);
		}
	};

	return (
		<>
			<div className={`share-card ${disabled && 'disabled'}`}>
				<div className="share-card-left">
					<Checkbox
						onChange={() => selectShareHandler(share)}
						checked={selectedShares.some(
							(selectedShare) => selectedShare.id === share.id
						)}
						disabled={canEdit || disabled}
					/>
					<div className="share-thumbnail">
						<img
							src={thumbnail || podcat}
							alt="share Thumbnail"
							className={thumbnail ? 'thumbnail' : 'no-thumbnail'}
						/>
					</div>
				</div>
				<div className="share-card-right">
					<div className="share-text">
						<div className="share-details">
							<div className="share-info">
								<div className="detail">
									<b>Name:</b>
									<span
										className="share-card-title"
										dangerouslySetInnerHTML={{
											__html: title,
										}}
									></span>
								</div>
								<div className="detail">
									<b>Created By:</b>{' '}
									{createdUser &&
										createdUser.firstName.slice(0, 1) +
											createdUser.lastName.slice(0, 1)}
								</div>
								<div className="detail">
									<b>Created On:</b> {createdAt}
								</div>
								<div className="detail">
									<b>Type:</b>{' '}
									{shareType[0].toUpperCase() + shareType.substring(1)}
								</div>
							</div>
							<div className="content-type">
								<span className={`type ${color}`}>
									{contentType[0].toUpperCase() + contentType.substring(1)}
								</span>
								<img src={icon} className="icon" />
							</div>
						</div>
						<div className="share-description">
							<b>Description:</b>{' '}
							{share.description || (
								<span className="empty-description">
									No description provided
								</span>
							)}
						</div>
					</div>
					<div className="share-card-actions">
						{renderArchiveButton()}
						{renderViewPerformanceButton()}
						<Button
							variant="outline-dark"
							title="Edit Details"
							onClick={() => {
								setSelectedShare(share);
								dispatch(showEditShareModal());
							}}
							disabled={canEdit || disabled}
						>
							Edit Details
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	item: state.episode.episode,
	permissions: state.auth.permissions,
});

export default withRouter(connect(mapStateToProps)(ShareCard));
