import React from 'react';
import ReactDOM from 'react-dom';
import CastedModal from 'components/shared/modal';

import { dispatch, getState } from 'store/store';
import {
	setEpisodeUpdates,
	setEpisodeResourceCreates,
	setEpisodeResourceUpdates,
	setEpisodeResourceDeletes,
	removeAllEpisodeUploads,
} from 'store/reducers/data/episode';

import { getApolloClient } from 'services/graphql/apolloClient';
import mutations from 'services/graphql/mutations';

const EpisodeUnsavedChanges = (props, callback) => {
	const {
		title = 'Unsaved Changes',
		messageText = 'You have unsaved changes. Are you sure you want to leave?',
		hardDeleteEpisode = false,
		episodeId,
	} = props;

	const container = document.createElement('div');
	container.setAttribute('casted-confirmation-navigation', '');
	document.body.appendChild(container);

	const closeModal = (callbackState) => {
		ReactDOM.unmountComponentAtNode(container);
		document.body.removeChild(container);
		callback(callbackState);
	};

	const state = getState();

	const episodeState = state.episode;

	const dialogOptions = [
		{
			text: 'Cancel',
			variant: 'cancel',
			onClick: () => closeModal(false),
		},
		{
			text: 'Leave',
			variant: 'danger',
			onClick: async () => {
				if (episodeState.episodeUploads) {
					dispatch(removeAllEpisodeUploads());
				}
				dispatch(setEpisodeUpdates({}));
				dispatch(setEpisodeResourceCreates([]));
				dispatch(setEpisodeResourceUpdates([]));
				dispatch(setEpisodeResourceDeletes([]));

				if (hardDeleteEpisode) {
					await getApolloClient().mutate({
						mutation: mutations.deleteEpisodeByIdHard,
						variables: {
							episodeId: episodeId,
						},
					});
				}

				closeModal(true);
			},
		},
	];

	ReactDOM.render(
		<CastedModal
			show={true}
			closeButton={false}
			title={title}
			dialogOptions={dialogOptions}
		>
			{messageText}
		</CastedModal>,
		container
	);
};

export default EpisodeUnsavedChanges;
