export const ACTIONS = {
    SHOW_EDIT_THEME_ON_LOAD: 'CASTED/SHOW_EDIT_THEME_ON_LOAD',
    HIDE_EDIT_THEME_ON_LOAD: 'CASTED/HIDE_EDIT_THEME_ON_LOAD',
    SET_THEME_CONTENT_VIEW: 'CASTED/SET_THEME_CONTENT_VIEW',
    RESET_THEME: 'CASTED/RESET_THEME',
};

export const THEME_CONTENT_VIEWS = {
    RELEVANT_CONTENT: 'RELEVANT_CONTENT',
    METRICS: 'METRICS',
}

const initialState = {
    editThemeOnLoad: false,
    contentView: THEME_CONTENT_VIEWS.RELEVANT_CONTENT,
};

export const showEditThemeOnLoad = () => {
    return {
        type: ACTIONS.SHOW_EDIT_THEME_ON_LOAD
    };
};

export const hideEditThemeOnLoad = () => {
    return {
        type: ACTIONS.HIDE_EDIT_THEME_ON_LOAD
    };
};

export const setThemeContentView = (newContentView) => {
    return {
        type: ACTIONS.SET_THEME_CONTENT_VIEW,
        payload: newContentView
    };
}

export const resetTheme = () => {
    return {
        type: ACTIONS.RESET_THEME
    };
}

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SHOW_EDIT_THEME_ON_LOAD:
            return {
                ...state,
                editThemeOnLoad: true
            };
        case ACTIONS.HIDE_EDIT_THEME_ON_LOAD:
            return {
                ...state,
                editThemeOnLoad: false
            };
        case ACTIONS.SET_THEME_CONTENT_VIEW:
            return {
                ...state,
                contentView: action.payload
            };
        case ACTIONS.RESET_THEME:
            return {
                ...state,
                contentView: initialState.contentView
            };
        default:
            return state;
    }
};

export default themeReducer;