import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { METRICS_CONTEXTS } from 'components/constants';
import { PromotionWithRouter } from 'components/shared/promotions/promotion';

const ItemPromotion = (props) => {
	return (
		<>
			<PromotionWithRouter context={METRICS_CONTEXTS.item} />
		</>
	);
};

const mapStateToProps = (state) => ({
	accountId: state.accounts.selectedAccountId,
	promotionUpdates: state.promotionData.promotionUpdates,
});

export const ItemPromotionWithRouter = withRouter(
	connect(mapStateToProps)(ItemPromotion)
);