import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import chroma from 'chroma-js';

import styles from './styles.scss';

const ThemeListFilter = (props) => {
	const {
		fieldLabel,
		themes,
		selectedTheme = null,
		onChange = () => {},
		setCurrent = (page) => {},
	} = props;

	const [selectedValue, setSelectedValue] = useState(selectedTheme);

	const colourStyles = {
		control: (styles) => ({ ...styles, backgroundColor: 'white' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: isDisabled
					? undefined
					: isSelected
					? data.color
					: isFocused
					? color.alpha(0.1).css()
					: undefined,
				color: isDisabled
					? '#ccc'
					: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.color,
				cursor: isDisabled ? 'not-allowed' : 'default',

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled
						? isSelected
							? data.color
							: color.alpha(0.3).css()
						: undefined,
				},
			};
		},
		multiValue: (styles, { data }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: color.alpha(0.1).css(),
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			':hover': {
				backgroundColor: data.color,
				color: 'white',
			},
		}),
	};

	const colourOptions = themes.map((theme) => {
		return {
			value: theme,
			label: theme.name,
			color: theme.color || '#231f70',
		};
	});

	return (
		<div className={styles.filterThemes}>
			<fieldset>
				<h3>{fieldLabel}</h3>
				{selectedValue ? (
					<button
						type="button"
						className={styles.selectedTheme}
						onClick={() => {
							onChange(null);
							setCurrent(1);
							setSelectedValue(null);
						}}
						style={{ backgroundColor: selectedValue.color }}
					>
						{selectedValue.label}
						<FontAwesomeIcon icon={faTimes} size="sm" />
					</button>
				) : (
					<Select
						value={selectedValue}
						closeMenuOnSelect={true}
						options={colourOptions}
						styles={colourStyles}
						onChange={(value) => {
							onChange(value);
							setCurrent(1);
							setSelectedValue(value);
						}}
					/>
				)}
			</fieldset>
		</div>
	);
};

export default ThemeListFilter;
