import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery, useLazyQuery } from '@apollo/client/react/hooks';

import { Overlay, Spinner, Tooltip } from 'react-bootstrap';

import queries from 'services/graphql/queries';
import { METRICS_CONTEXTS } from 'components/constants';

import MetricsScreen from 'components/shared/metricsScreen';

const ShareMetrics = (props) => {
	const { match, selectedAccountId } = props;
	const { params } = match;
	const { shareId } = params;

	const target = useRef(null);

	const [showMessage, setShowMessage] = useState(false);
	const [loadingData, setLoadingData] = useState(true);
	const [item, setItem] = useState({});
	const [collection, setCollection] = useState({});

	const { data, loading } = useQuery(queries.getShare, {
		variables: { shareSlug: shareId },
		fetchPolicy: 'no-cache',
	});
	const share = data?.share || {};

	const [getEpisodeInfo] = useLazyQuery(queries.getEpisodeBasic, {
		variables: { episodeId: share.itemId, includePodcast: true },
		fetchPolicy: 'cache-and-network',
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			setItem(data.basicEpisode);
			setLoadingData(false);
		},
	});

	const [getPodcast] = useLazyQuery(queries.podcastById, {
		variables: { podcastId: share.collectionId },
		fetchPolicy: 'cache-and-network',
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			setCollection(data.podcast);
			setLoadingData(false);
		},
	});

	useEffect(() => {
		if (showMessage) {
			setTimeout(() => {
				setShowMessage(false);
			}, 5000);
		}
		if (share.id && share.contentType === METRICS_CONTEXTS.item) {
			getEpisodeInfo();
		} else if (
			share.id &&
			share.contentType === METRICS_CONTEXTS.collection
		) {
			getPodcast();
		}
	}, [showMessage, loading]);

	const getShareUrl = () => {
		let publicUrl;
		let shareUrl;
		if (share.contentType === METRICS_CONTEXTS.item) {
			publicUrl = item?.customDomain
				? `https://${item.customDomain}`
				: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;

			if (share.shareType === 'link') {
				shareUrl = `${publicUrl}/public/${selectedAccountId}/${item?.podcast?.slug}/${item.slug}/share/${share.shareSlug}`;
			}

			if (share.shareType === 'embed') {
				shareUrl = `${publicUrl}/embed/v2/${share?.shareOption?.embedType}/${item.slug}/share/${share.shareSlug}`;

				if (share?.shareOption?.hasTabs) {
					shareUrl = `${shareUrl}/takeaways/guests/transcript/resources/subscribe`;
				}
				shareUrl =
					`<div><iframe width="100%" height="${
						share?.shareOption?.audioOnly ? '215px' : '1000px'
					}" id="casted-embed-${
						item.slug
					}" scrolling="no" style="border: none" src="${shareUrl}"></iframe>` +
					`<script type="text/javascript">window.addEventListener("message", function(message){if(message.origin === "${publicUrl}" ) { ` +
					`if( message.data.event) { ` +
					`if(message.data.event === "castedSizeUpdate") { ` +
					`var casted_episode_player = document.getElementById('casted-embed-' + message.data.payload.slug); ` +
					`if(casted_episode_player) { ` +
					`casted_episode_player.height = message.data.payload.height;` +
					`if(casted_episode_player.contentWindow) {` +
					`casted_episode_player.contentWindow.postMessage({ event: "castedStopUpdate" }, "${publicUrl}");` +
					`}}}}}}, false)</script></div>`;
			}
		} else {
			publicUrl = collection?.customDomain
				? `https://${collection.customDomain}`
				: `${process.env.REACT_APP_PUBLIC_APP_ROOT}`;
			shareUrl = `${publicUrl}/public/${selectedAccountId}/${collection.slug}/share/${share.shareSlug}`;
		}

		return shareUrl;
	};

	let variables = { shareSlug: share.shareSlug };
	let contextQueries = {
		integrations: queries.podcastIntegrations,
		totalAndUniqueAudience: queries.getShowListenSummary,
		newAndLoyalAudience: queries.getShowNewAndLoyalListeners,
		averageCompletion: queries.getShowAverageCompletion,
		listensOverTimeUnhosted: queries.getDailyShowListens,
	};

	if (share.contentType === METRICS_CONTEXTS.item) {
		contextQueries = {
			integrations: queries.podcastIntegrations,
			totalAndUniqueAudience: queries.getEpisodeListenSummary,
			newAndLoyalAudience: queries.getEpisodeNewAndLoyalListeners,
			averageCompletion: queries.getEpisodeAverageCompletion,
			listensOverTimeUnhosted: queries.getDailyEpisodeListens,
		};
		variables.episodeId = share.itemId;
		variables.podcastId = item.podcastId;
	} else {
		variables.podcastId = share.collectionId;
	}

	const onClick = async () => {
		const shareUrl = getShareUrl();
		await navigator.clipboard.writeText(shareUrl);
		setShowMessage(true);
	};

	if (loading || loadingData)
		return (
			<div className="share-loading-container">
				<Spinner
					className="share-loading"
					animation="grow"
					variant="info"
				/>
			</div>
		);

	return (
		<MetricsScreen
			metricsContext={share.contentType}
			contextIdVariable={variables}
			contextQueries={contextQueries}
			className={'show-metrics'}
			metricsTitle={<h2>{share.title}</h2>}
			metricsHeader={
				<div className="share-header">
					<div className="share-description">
						<b>Description:</b>
						<span className={!share.description && 'light-text'}>
							{' '}
							{share.description
								? share.description
								: 'No description provided'}
						</span>
					</div>
					<div className="share-link" ref={target}>
						<b>Unique Link:</b>
						<span>
							{' '}
							<span className="light-text link" onClick={onClick}>
								{getShareUrl()}
							</span>
						</span>
						<Overlay
							show={showMessage}
							placement="top"
							target={target.current}
						>
							<Tooltip className="share-copied-tooltip">
								Copied!
							</Tooltip>
						</Overlay>
					</div>
				</div>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export default withRouter(connect(mapStateToProps)(ShareMetrics));
