import React from 'react'

import { HorizontalLine, Circle } from 'components/shared/loadingSkeletons/skeleton'

const LoadingTranscript = (/* props */) => {
  const commonBarStyles = { height: 12, marginBottom: 8 }
  const section = (
    <div style={{ marginBottom: 20 }}>
      <HorizontalLine style={{ width: '100%', ...commonBarStyles }} />
      <HorizontalLine style={{ width: '95%', ...commonBarStyles }} />
      <HorizontalLine style={{ width: '90%', ...commonBarStyles }} />
      <HorizontalLine style={{ width: '80%', ...commonBarStyles }} />
      <HorizontalLine style={{ width: '60%', ...commonBarStyles }} />
    </div>
  )

  return (
    <div className="studio-transcript transcript-loading" style={{ flex: 1 }}>
      <div className="transcript-actions">
        <Circle style={{width: '1.5em', height: '1.5em'}}/>
      </div>
      <div style={{padding: '8px 16px'}}>
        {section}
        {section}
        {section}
      </div>
    </div>
  )
}

export default LoadingTranscript
