import React from 'react';
import { connect } from 'react-redux';

import { dispatch } from 'store/store';
import {
	setAudiogramStep,
	AudiogramStepsEnum,
} from 'store/reducers/ui/modals/clipShareModal';

import { Row, Col, Alert } from 'react-bootstrap';
import { FaAngleRight, FaVideo } from 'react-icons/fa';

import Button from 'components/shared/button';
import CastedToggleButtonGroup from 'components/shared/toggleButtonGroup';

const AudiogramSteps = (props) => {
	const {
		embedType,
		selectedTemplate,
		setSelectedTemplate,
		setEmbedType,
		selectedClip,
		createAudiogram,
		templates,
	} = props;

	const { startTime, endTime } = selectedClip || {};

	const clipLength = endTime - startTime;
	const clipTooLong = clipLength >= 420;

	const toggleButtonList = [
		{
			text: 'STANDARD EMBED',
			value: 'standard',
		},
		{
			text: 'SOCIAL MEDIA EMBED',
			value: 'social',
		},
	];

	const onToggleChange = (newValue) => {
		setEmbedType(newValue);
	};

	const onNext = () => {
		if (embedType === 'standard') {
			createAudiogram();
		} else {
			dispatch(setAudiogramStep(AudiogramStepsEnum.OEMBED_DETAILS));
		}
	};

	return (
		<div className="create-audiogram audiogram-templates">
			<div className="preview-instructions">
				<h4 className="preview-title">Shareable Audiogram</h4>
				<p>
					Create a video that can be shared on social media or embedded on other
					channels. Keep in mind that most social platforms have a limit of 140
					seconds for videos. Start by
					<strong> choosing an embed type</strong> and{' '}
					<strong>choosing a template</strong> below
				</p>
				<p>
					<strong>Note</strong> The video will include subtitles - make sure you
					review the transcript for accuracy.
				</p>
			</div>

			{clipTooLong ? (
				<Alert variant="warning">
					<Alert.Heading>Clip Too Long</Alert.Heading>
					<p>
						The Clip needs to be <strong>less than 7 minutes long</strong> to
						generate an Audiogram.
					</p>
					<hr />
					<p>Try a shorter clip or share this clip as a link.</p>
				</Alert>
			) : (
				<>
					<CastedToggleButtonGroup
						toggleButtonList={toggleButtonList}
						defaultValue={embedType}
						className={'embed-type-toggle'}
						onChange={onToggleChange}
					/>
					<Row>
						{templates.map((template, i) => (
							<Col xs="6" key={template.id}>
								<img
									src={template.thumbnail}
									className={`audiogram-template ${
										selectedTemplate === template.id ? 'selected' : ''
									}`}
									onClick={() => {
										setSelectedTemplate(template.id);
									}}
								/>
								{template.templateMediaType === 'video' && (
									<div class="triangle-badge">
										<FaVideo className="video-icon" />
									</div>
								)}
							</Col>
						))}
					</Row>
				</>
			)}

			<div className="audiogram-next-step">
				<Button
					onClick={onNext}
					variant="link-no-href"
					disabled={!selectedTemplate}
				>
					Next <FaAngleRight />
				</Button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedClip: state.transcript.selectedClip,
	modals: state.ui.modals,
});

export default connect(mapStateToProps)(AudiogramSteps);
