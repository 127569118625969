export const ACTIONS = {
    SHOW_MODAL: 'CASTED/DELETE_THEME_MODAL/SHOW_MODAL',
    HIDE_MODAL: 'CASTED/DELETE_THEME_MODAL/HIDE_MODAL',
};

export const initialState = {
    showModal: false,
    themeToDelete: null
};

export const hideDeleteThemeModal = () => {
    return {
        type: ACTIONS.HIDE_MODAL
    };
};

export const showDeleteThemeModal = (themeToDelete) => {
    return {
        type: ACTIONS.SHOW_MODAL,
        payload: themeToDelete
    };
};

const deleteThemeModalReducer = (state = initialState, action ) => {
    const { 
        type, 
        payload 
    } = action;

    switch (type) {
        case ACTIONS.HIDE_MODAL:
            return {
                ...state,
                showModal: false,
                themeToDelete: null
            };
        case ACTIONS.SHOW_MODAL:
            return {
                ...state,
                showModal: true,
                themeToDelete: payload
            };
        default:
            return state;
    }
}

export default deleteThemeModalReducer;