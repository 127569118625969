import React from 'react';
import { FaExclamationTriangle, FaLightbulb } from 'react-icons/fa';
import passwordRating from 'zxcvbn';

const PasswordStrength = (props) => {
	const { password, messages } = props;

	if (!password && password.length < 1) {
		return null;
	}

	const pwRating = passwordRating(password);
	const pwScore = pwRating.score;
	const warningMsg = pwRating?.feedback?.warning;
	let suggestionMsgs = pwRating?.feedback?.suggestions;
	let strengthMsg = '';

	if (pwScore < 2) {
		strengthMsg = 'Very weak';
	} else if (pwScore > 1 && pwScore < 3) {
		strengthMsg = 'Could be stronger';
	} else {
		strengthMsg = 'Very strong';
	}

	if (messages) {
		suggestionMsgs.push(messages);
	}

	return (
		<div className="password-strength-container">
			<div className="password-strengh-bar">
				<div className="pw-strength-bar pw-too-weak" />
				<div
					className={`pw-strength-bar pw-could-be-stronger ${
						pwScore > 1 ? 'yellow' : ''
					}`}
				/>
				<div
					className={`pw-strength-bar pw-very-strong ${
						pwScore > 2 ? 'green' : ''
					}`}
				/>
				<span className="pw-strength-msg">{strengthMsg}</span>
			</div>
			{warningMsg && (
				<div className="pw-warning-msg">
					<FaExclamationTriangle className="warning-icon" />
					{warningMsg}
				</div>
			)}
			{suggestionMsgs && (
				<div className="pw-warning-msg">
					{suggestionMsgs.map((suggestion) => {
						if (suggestion != '') {
							return (
								<div>
									<FaLightbulb className="warning-icon" />
									{suggestion}
								</div>
							);
						} else {
							return <></>;
						}
					})}
				</div>
			)}
		</div>
	);
};

export default PasswordStrength;
