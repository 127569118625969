import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client/react/hooks';

// External Components
import { FaAngleDown, FaPlus } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';

// Internal Libraries
import queries from 'services/graphql/queries';

// Internal Components
import BaseTable from 'components/shared/baseTable';
import CastedDropdownButton from 'components/shared/dropdownButton';
import { SegmentsDropdown } from 'components/shared/insights/segmentsDropdown';

import podcat from 'assets/images/podcat-playing-v2.png';
import { numberSort } from 'utils';

const SegmentsTable = (props) => {
	const {
		dateRange,
		selectedAccountId: accountId,
		collectionId = '',
		episodeId = '',
		shareId = '',
	} = props;

	const [selectedSegment, setSelectedSegment] = useState({});
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [dimensions, setDimensions] = useState([]);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const { data, loading } = useQuery(queries.getSegmentsByAccountId, {
		variables: { accountId: parseInt(accountId, 10) },
		fetchPolicy: 'no-cache',
	});
	const { segments = [] } = data || {};

	let revealVariables = {
		accountId,
		segmentId: selectedSegment.id,
		startDate: dateRange.start,
		endDate: dateRange.end,
	};

	if (collectionId) {
		revealVariables.collectionId = collectionId;
	}

	if (episodeId) {
		revealVariables.episodeId = episodeId;
	}

	const [getSelectedSegment, { data: segmentData, loading: loadingData }] =
		useLazyQuery(queries.getSegmentData);
	const { getSegmentData = [] } = segmentData || {};

	const [addDimensions, { data: dimensionData, loading: loadingDimensions }] =
		useLazyQuery(queries.getSegmentData);
	let dimensionMetrics = dimensionData || {};
	dimensionMetrics = dimensionMetrics.getSegmentData || [];

	useEffect(() => {
		setResetPaginationToggle(!resetPaginationToggle);
	}, [selectedSegment, dimensions]);

	useEffect(() => {
		if (!loading && segments.length > 0) {
			setSelectedSegment(segments[0]);
		}
	}, [segments]);

	useEffect(() => {
		if (Object.keys(selectedSegment).length !== 0) {
			getSelectedSegment({ variables: revealVariables });
		}
	}, [selectedSegment, dateRange]);

	useEffect(() => {
		if (selectedGroups.length > 0) {
			revealVariables.dimensionIds = selectedGroups.map(
				(group) => group.id
			);
			addDimensions({ variables: revealVariables });
		} else {
			setDimensions([]);
		}
	}, [selectedGroups, dateRange, selectedSegment]);

	const segmentColumns = [
		{
			name: 'Category',
			selector: (row) => row.name,
			format: (row) => <b>{row.name}</b>,
			minWidth: '200px',
			sortable: true,
		},
		{
			name: 'Engagements',
			selector: (row) => row.engagements,
			sortable: true,
			sortFunction: numberSort((row) => row.engagements),
		},
		{
			name: 'Last Engagement',
			selector: (row) => {
				let dateArray = row.lastEngagement.value.split('-');
				return dateArray[1] + '-' + dateArray[2] + '-' + dateArray[0];
			},
			sortable: true,
		},
		{
			name: 'Unique Contacts',
			selector: (row) => row.uniqueContacts,
			sortable: true,
			sortFunction: numberSort((row) => row.uniqueContacts),
		},
	];

	let tableData = getSegmentData;

	if (selectedGroups.length > 0) {
		tableData = [...getSegmentData, ...dimensionMetrics];
	}

	const emptyText =
		segments.length > 0 ? (
			<div className="segments-empty-state">
				<div>
					Nothing to see here yet! But don’t worry, your Segments are
					keeping watch for new audience members.
				</div>
				<div>
					<span className="bold">Want to see more data here?</span>{' '}
					Consider editing your Segment settings or sharing more
					content!
				</div>
				<br></br>
				<div> Reach out to your CSM to learn more.</div>
			</div>
		) : (
			<div className="segments-empty-state">
				<div>Want to get more from your Insights Data?</div>
				<div>
					<span className="bold">Set up Segments</span> to customize
					how you see your data, and find what matters to you.
				</div>
				<br></br>
				<div>Reach out to your CSM to learn more!</div>
			</div>
		);

	return (
		<div className="segments-table-container">
			<h4>Segments</h4>
			<p>
				Utilize Segments to customize your data organization and find
				what matters to you. Want to change your segments? Reach out to
				your CSM anytime!
			</p>
			{segments.length > 0 && (
				<div className="segments-table-filters">
					<CastedDropdownButton
						title={
							<div className="segment-value">
								{selectedSegment.category}
								<FaAngleDown />
							</div>
						}
						classType="round"
						size={'sm'}
						displayDropArrow={false}
					>
						{segments.length > 0 &&
							segments.map((segment, i) => (
								<Dropdown.Item
									disabled={segment.id === selectedSegment.id}
									onClick={() => setSelectedSegment(segment)}
								>
									<div className="segment-menu">
										<h5 className="header">
											{segment.category}
										</h5>
										<div className="list">
											{segment.segmentGroups
												.map((group) => group.name)
												.join(', ')}
										</div>
									</div>
								</Dropdown.Item>
							))}
					</CastedDropdownButton>
					<SegmentsDropdown
						segments={segments}
						setSelectedGroups={setSelectedGroups}
						selectedGroups={selectedGroups}
						limit={2}
						selectedSegment={selectedSegment}
						value={
							selectedGroups.length > 0 ? (
								`${selectedGroups.length} dimensions added`
							) : (
								<div className="empty-value">
									<FaPlus className="plus-icon" />
									Add Dimension
								</div>
							)
						}
					/>
				</div>
			)}
			<BaseTable
				values={tableData}
				columns={segmentColumns}
				loading={loading || loadingData || loadingDimensions}
				clickable={false}
				pagination={tableData.length > 5}
				paginationPerPage={5}
				resetPaginationToggle={resetPaginationToggle}
				noDataComponent={
					<div className="empty-container">
						<img className="podcat" src={podcat} />
						{emptyText}
					</div>
				}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedAccountId: state.accounts.selectedAccountId,
});

export const SegmentsTableWithRouter = withRouter(
	connect(mapStateToProps)(SegmentsTable)
);
