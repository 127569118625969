import React from 'react';

// External Components
import { PieChart, Pie, Cell, Legend } from 'recharts';

const TableFilter = (props) => {
	const { data } = props;

	const filterName = data.name;
	const filterText = data.text;

	const localFilterData = [
		// This object acts as the grayed out section of the pie chart.
		{
			value: 1 - data.value,
			fill: '#F7F7F7',
		},
		data,
	];

	const renderCustomizedLegend = ({ payload }) => {
		if (payload.length > 0 && payload.indexOf(1) !== 0) {
			const legendData = payload[1].payload;

			return (
				<div className="filter-legend">
					<h5 className="legend-percent">{legendData.percent * 100}%</h5>
					<p className="legend-total">{legendData.total.toLocaleString()}</p>
				</div>
			);
		}
		return <></>;
	};

	return (
		<div className="filter-card">
			<PieChart
				width={54}
				height={54}
				cx={27}
				cy={27}
				innerRadius={24}
				outerRadius={27}
			>
				<Pie
					width={54}
					height={54}
					data={localFilterData}
					startAngle={90}
					endAngle={450}
					innerRadius={24}
					outerRadius={27}
					labelLine={false}
					dataKey="value"
					isAnimationActive={false}
					fill={data.fill}
				>
					{localFilterData.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={entry.fill} />
					))}
				</Pie>
				<Legend content={renderCustomizedLegend} />
			</PieChart>
			<div className="filter-text">
				{filterName}
				<p>{filterText}</p>
			</div>
		</div>
	);
};

export { TableFilter };
