import React, { useState, useEffect } from 'react';

import { Spinner } from 'react-bootstrap';

import {
	ENGAGEMENT_TRACKER_OPTIONS,
	ENAGAGEMENT_LEVEL_VALUES,
	ENGAGEMENT_GROUP_TIME,
} from 'components/constants';
import EngagementTrackerGraph from './engagementTrackerGraph';

import moment from 'moment';

const EngagementTracker = (props) => {
	const {
		selectedOption,
		isClip = false,
		selectedClip = {},
		duration,
		trackerData,
		loading,
		byCompany = false,
	} = props;

	const [engagementData, setEngagementData] = useState([]);
	const [highValues, setHighValues] = useState([]);
	const [mediumValues, setMediumValues] = useState([]);
	const [lowValues, setLowValues] = useState([]);

	useEffect(() => {
		// each item in the data array consist of an array
		// that has the time of the item and the amount of
		// engagement ex. [sec, value] => [1, 60]

		if (!loading && trackerData.length > 0) {
			const engagementsObject = trackerData[0];
			const selectedData = engagementsObject[selectedOption] || [];

			// Handle the case where we did get data, but the selected tracker option
			// has no data to render
			if (!selectedData || selectedData.length === 0) {
				return setEngagementData([]);
			}

			switch (ENGAGEMENT_TRACKER_OPTIONS[selectedOption]) {
				case ENGAGEMENT_TRACKER_OPTIONS.listenTime:
					return getListenTime(selectedData);
				case ENGAGEMENT_TRACKER_OPTIONS.likes:
				case ENGAGEMENT_TRACKER_OPTIONS.shares:
				case ENGAGEMENT_TRACKER_OPTIONS.pauses:
				case ENGAGEMENT_TRACKER_OPTIONS.dropoffs:
					return getActions(selectedData);
				default:
					return setEngagementData([]);
			}
		} else if (!loading && trackerData.length === 0) {
			return setEngagementData([]);
		}
	}, [selectedOption, loading]);

	const getListenTime = (data) => {
		let obj = {};

		data.forEach((timeArray) => {
			let timeValue = timeArray[0];
			let engagementValue = timeArray[1];

			// create an object with each key being a unique engagement value
			// and the values being arrays of seconds for that value
			if (engagementValue in obj) {
				let currentValues = obj[engagementValue];

				currentValues.forEach((valueGroup, index) => {
					const lastNumber = valueGroup.length - 1;
					if (valueGroup[lastNumber] === timeValue - 1) {
						currentValues.splice(index, 1);
						obj[engagementValue] = [
							...currentValues,
							[...valueGroup, timeValue],
						];
					} else if (currentValues.length === index + 1) {
						obj[engagementValue] = [...currentValues, [timeValue]];
					}
				});
			} else {
				obj[engagementValue] = [[timeValue]];
			}
		});

		if (isClip) {
			const durationArray = [...Array(duration + 1).keys()];
			const start = durationArray.slice(0, selectedClip.startTime);
			const end = durationArray.slice(selectedClip.endTime + 1);
			if ('0' in obj) {
				obj['0'] = [...obj['0'], start, end];
			} else {
				obj['0'] = [[start, end]];
			}
		}
		getGraphSegments(obj, [], 1);
	};

	const getGraphSegments = (obj, segmentsArray, currentValue) => {
		// create ordered array of objects
		// key is engagement value and values are consecutive seconds
		let newObject = {};
		let newCurrentValue;

		const keys = Object.keys(obj);
		keys.forEach((keyNumber) => {
			const found = obj[keyNumber].find((element) =>
				element.includes(currentValue)
			);

			if (found !== undefined) {
				newObject[keyNumber] = found;
				newCurrentValue = found[found.length - 1];
				return;
			}
		});

		const newSegmentsArray = [...segmentsArray, newObject];
		if (newCurrentValue < duration) {
			getGraphSegments(obj, newSegmentsArray, newCurrentValue + 1);
		} else {
			setEngagementLevels(keys);
			setEngagementData(newSegmentsArray);
		}
	};

	const setEngagementLevels = (values) => {
		const intValues = values.map((key) => parseInt(key));
		const highestValue = Math.max(...intValues);

		let high = [];
		let medium = [];
		let low = [];

		intValues.forEach((value) => {
			const percent = (value / highestValue) * 100;

			if (percent >= ENAGAGEMENT_LEVEL_VALUES.higher) {
				high = [...high, value.toString()];
			} else if (percent < ENAGAGEMENT_LEVEL_VALUES.lower) {
				low = [...low, value.toString()];
			} else if (
				percent < ENAGAGEMENT_LEVEL_VALUES.higher ||
				percent >= ENAGAGEMENT_LEVEL_VALUES.lower
			) {
				medium = [...medium, value.toString()];
			}
		});

		setHighValues(high);
		setMediumValues(medium);
		setLowValues(low);
	};

	const getActions = (data) => {
		let sortedArray = [...data].sort((a, b) => a - b);

		// creates array of arrays that include engagement times
		// that are within 20 seconds of eachother
		let newArray = [];
		sortedArray.forEach((value) => {
			if (newArray.length > 0) {
				let found = false;
				newArray.forEach((array) => {
					let lastValue = array[array.length - 1];
					if (
						value <= lastValue + ENGAGEMENT_GROUP_TIME.seconds &&
						value >= lastValue - ENGAGEMENT_GROUP_TIME.seconds
					) {
						found = true;
						array.push(value);
					}
				});

				if (found === false) {
					newArray = [...newArray, [value]];
				}
				found = true;
			} else {
				newArray.push([value]);
			}
		});

		const engagementLevels = newArray.map((array) => array.length);
		if (sortedArray.length !== duration) {
			engagementLevels.push(0);
		}
		setEngagementLevels(engagementLevels);

		// creates an array of objects that includes the count of
		// engagements for each array as the key and the value is the array of time values
		// also has to calcluate the 0 keys and time values
		let dataArray = [];
		let lastWidth = 0;

		if (isClip) {
			dataArray.push({ 0: [0, selectedClip.startTime] });
			lastWidth = selectedClip.startTime;
		}

		newArray.forEach((array, i) => {
			let obj = {};
			let firstValue = array[0];

			if (firstValue - lastWidth > 1) {
				let newObj = {};

				const begining = lastWidth === 0 ? 0 : lastWidth + 1;
				newObj[0] = [begining, firstValue - 1];
				dataArray.push(newObj);
			}

			lastWidth = array[array.length - 1];
			obj[array.length] = array;
			dataArray.push(obj);

			if (i === newArray.length - 1 && array[array.length - 1] !== duration) {
				let lastSegment = {};

				if (isClip) {
					dataArray.push({
						0: [array[array.length - 1], selectedClip.endTime],
					});
					dataArray.push({ 0: [selectedClip.endTime + 1, duration] });
				} else {
					lastSegment[0] = [array[array.length - 1], duration];
					dataArray.push(lastSegment);
				}
			}
		});
		setEngagementData(dataArray);
	};

	const getMarker = (value) => {
		// converts duration to minutes and seconds
		// and then creates 5 even markers for x-axis
		const length = (duration * value) / 60;
		const minutes = Math.floor(length);

		let seconds = length % 1;
		seconds = seconds * 60;
		seconds = Math.round(seconds);

		return (
			<div className="marker">
				{minutes}:{seconds}
			</div>
		);
	};

	if (loading) {
		return (
			<div className="tracker-loading">
				<Spinner animation="border" variant="info" />
			</div>
		);
	}

	return (
		<div
			className={`tracker-container ${isClip && 'clip'} ${
				byCompany && 'company'
			}`}
		>
			{!isClip && !byCompany && (
				<div className="tracker-legend">
					<span className="legend-title">Engagement:</span>
					<div className="legend-color low"></div>
					<span className="legend-text">Low</span>
					<div className="legend-color medium"></div>
					<span className="legend-text">Medium</span>
					<div className="legend-color high"></div>
					<span className="legend-text">High</span>
				</div>
			)}
			{isClip && (
				<div className="clip-title">
					{selectedClip.name} -{' '}
					{moment(selectedClip?.createdAt).format('MM/DD/YY')}
				</div>
			)}
			<EngagementTrackerGraph
				data={engagementData}
				highValues={highValues}
				mediumValues={mediumValues}
				lowValues={lowValues}
				duration={duration}
				selectedOption={selectedOption}
				selectedClip={selectedClip}
				isClip={isClip}
			/>
			<div className="tracker-xaxis">
				<div className="tracker-markers end">
					<div className="marker">00:00</div>
				</div>
				<div className="tracker-markers">{getMarker(0.25)}</div>
				<div className="tracker-markers">{getMarker(0.5)}</div>
				<div className="tracker-markers">{getMarker(0.75)}</div>
				<div className="tracker-markers end">{getMarker(1)}</div>
			</div>
		</div>
	);
};

export default EngagementTracker;
