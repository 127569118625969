import React from 'react';

import Badge from 'react-bootstrap/Badge';
const TablePermissionBadge = (props) => {
	const { roleId, id, rolesList, isAccountAdmin = false } = props;

	const getCurrentRoleName = (id) => {
		const role = rolesList.find((r) => r.id === id);
		if (role) return role.name;
		return 'No Access';
	};

	const roleName = isAccountAdmin
		? 'Account Admin'
		: getCurrentRoleName(roleId);

	const chars = { '&': 'and', ' ': '_' };
	const roleColor = roleName.replace(/[&' ']/g, (m) => chars[m]);

	const badgeColors = {
		admin: 'green',
		author: 'navy',
		view_and_clip: 'purple',
		episode_editor: 'blue',
		viewer: 'gray',
		no_access: 'red',
		account_admin: 'green',
	};

	const badgeClassName = `permission-badge ${
		badgeColors[roleColor.toLowerCase()]
	}`;

	return (
		<Badge pill className={badgeClassName} key={`badge-${id}`}>
			{roleName}
		</Badge>
	);
};

export default TablePermissionBadge;
