import { Auth, Francis } from 'casted-auth-js-sdk';
import jwtDecode from 'jwt-decode';

let AuthSingleton = null;

const AUTH_API_HOST =
	process.env.REACT_APP_AUTH_HOST || 'http://localhost:8080';

class AuthSvc {
	constructor({ baseURL = AUTH_API_HOST, storagePrefix = 'casted_' }) {
		this.akAuth = new Auth({ baseURL, storagePrefix });
		this.baseURL = baseURL;
		this.uniqueClientId = this.akAuth.clientId;
		this.currentAccountId = '';
		Francis.setStatusCallback(401, (req, res) => {
			return this.akAuth.unauthedCallback(req, res);
		});
	}

	signInLocal = async (username, password) => {
		return await this.akAuth.signInLocal(username, password);
	};

	signOut = async () => {
		return await this.akAuth.signOut();
	};

	postUser = async (username, password, user, key) => {
		return await this.akAuth.postUser(username, password, user, key);
	};

	inviteUser = async (user, accountId) => {
		const newUser = await this.akAuth.inviteUser(user, accountId);
		return newUser.id;
	};

	resetPassword = async (identifier, password, code) => {
		return await this.akAuth.resetPassword(identifier, password, code);
	};

	setPassword = async (code, password) => {
		return await this.akAuth.setPassword(code, password);
	};

	sendResetEmail = async (email) => {
		return await this.akAuth.sendResetEmail(email);
	};

	sendConfirmEmail = async (email, userId, confirm_code) => {
		return await this.akAuth.sendConfirmation(email, userId, confirm_code);
	};

	refreshToken = async () => {
		return await this.akAuth.refresh();
	};

	getAccountsFromDomain = async (domain) => {
		return await this.akAuth.getAccountsFromDomain(domain);
	};

	refreshTokenForAccount = async (accountId) => {
		const options = {
			params: {
				cid: this.akAuth.clientId,
				refreshToken: this.akAuth.refreshToken,
				userId: this.akAuth.userId,
				requestedAccount: accountId,
			},
		};
		const res = await Francis.get(`${this.baseURL}/auth/token`, options);
		const { accessToken } = res.data;
		this.setAccessToken(accessToken);
		return accessToken;
	};

	getSsoUrl = async (email) => {
		return await this.akAuth.ssoLoginCheck(email);
	};

	getAccessToken = () => this.akAuth.accessToken;
	setAccessToken = (accessToken) => {
		this.akAuth.accessToken = accessToken;
	};

	getUserId = () => this.akAuth.userId;
	setUserId = (userId) => {
		this.akAuth.userId = userId;
	};

	getRefreshToken = () => this.akAuth.refreshToken;
	setRefreshToken = (refreshToken) => {
		this.akAuth.refreshToken = refreshToken;
	};

	getClientId = () => this.akAuth.clientId;
	setClientId = (clientId) => {
		this.akAuth.clientId = clientId;
	};

	getGoogleAuthHref = (additionalQueryParams = '') => {
		return `${this.baseURL}/auth/google?clientId=${
			this.uniqueClientId || this.akAuth.clientId
		}&${additionalQueryParams}`;
	};

	getMicrosoftAuthHref = (additionalQueryParams = '') => {
		return `${this.baseURL}/auth/windowslive?clientId=${
			this.uniqueClientId || this.akAuth.clientId
		}&${additionalQueryParams}`;
	};

	getUserId = () => {
		try {
			const decoded = jwtDecode(this.akAuth.refreshToken);
			const { uid: userId } = decoded;
			return { userId };
		} catch (e) {
			console.error('Error parsing token');
			return {};
		}
	};
}

const initAuthSingleton = (authSvc) => {
	AuthSingleton = authSvc;
};
const getAuthSingleton = () => AuthSingleton;

export { AuthSvc, getAuthSingleton, initAuthSingleton };
