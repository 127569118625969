import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import moment from 'moment';
import { formatLargeIntegers, numberSort } from 'utils';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import BaseTable from 'components/shared/baseTable';
import MetricEmptyState from 'components/shared/metricEmptyState';

const TopEpisodes = (props) => {
	const { variables } = props;
	const history = useHistory();
	const episodePath = history.location.pathname.replace('metrics', '');

	const [values, setValues] = useState([]);

	const itemLink = (episode) => {
		if(episodePath.includes('shows')) {
			return `${episodePath}episodes/${episode.id}/analytics`;
		} else {
			return `${episodePath}shows/${episode.podcast_id}/episodes/${episode.id}/analytics`;
		}
	};

	const popover = (name) => {
		return (
			<Popover className="long-title-popover">
				<Popover.Content>
					<strong>Item Name: </strong>
					<br />
					{name}
				</Popover.Content>
			</Popover>
		);
	};

	
	const columns = [
		{
			name: 'Item',
			selector: (row) => row.name,
			grow: 2,
			sortable: true,
			cell: (row) => (
				<OverlayTrigger
					trigger={['hover', 'focus']}
					placement="right-end"
					overlay={popover(row.name)}
					show={row.name && row.name.length > 140}
				>
					<Link to={{pathname: itemLink(row)}} target="_blank">
						{row.name && row.name.length > 140
							? row.name.substr(0, 139).concat('...')
							: row.name}
					</Link>
				</OverlayTrigger>
			),
		},
		{
			name: 'Collection',
			selector: (row) => row.parentName,
			sortable: true,
		},
		{
			name: 'Publish Date',
			selector: (row) => row.data[0],
			sortable: true,
		},
		{
			name: 'Impressions',
			selector: (row) => row.data[1],
			sortable: true,
			sortFunction: numberSort((row) => row.data[1]),
		},
		{
			name: 'Likes',
			selector: (row) => row.data[2],
			sortable: true,
			sortFunction: numberSort((row) => row.data[2]),
		},
		{
			name: 'Listens',
			selector: (row) => row.data[3],
			sortable: true,
			sortFunction: numberSort((row) => row.data[3]),
		},
		{
			name: 'First 7',
			selector: (row) => row.data[4],
			sortable: true,
			sortFunction: numberSort((row) => row.data[4]),
		},
		{
			name: 'First 30',
			selector: (row) => row.data[5],
			sortable: true,
			sortFunction: numberSort((row) => row.data[5]),
		},
	];

	const { loading, error } = useQuery(queries.getTopEpisodes, {
		variables,
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			const { getTopEpisodes: episodes = [] } = data || {};

			setValues(
				episodes.map((episode) => {
					let data = [];

					data.push(
						episode.published_at
							? moment(episode.published_at).format('MM/DD/YY')
							: '--/--/--'
					);

					data.push(formatLargeIntegers(episode.total_impressions));

					data.push(
						formatLargeIntegers(episode.total_likes),
						formatLargeIntegers(episode.total_listens)
					);

					if (variables.isHosted) {
						data.push(
							formatLargeIntegers(episode.first_7_listens),
							formatLargeIntegers(episode.first_30_listens)
						);
					}

					return {
						id: episode.id,
						podcast_id: episode.podcast_id,
						name: episode.name,
						parentName:
							episode.podcast_name?.length > 35
								? `${episode.podcast_name.substring(0, 33)}...`
								: episode.podcast_name,
						data,
					};
				})
			);
		},
	});

	const onClickHandler = (episode) => {
		console.log(itemLink(episode))
		window.open(itemLink(episode), '_blank');
	};

	return (
		<>
			<h4>Top Items</h4>
			<BaseTable
				values={values}
				columns={columns}
				loading={loading}
				noDataComponent={<MetricEmptyState metricName="Top Items" />}
				clickable={true}
				className="clickable"
				pagination={true}
				paginationPerPage={props.numberOfRows}
				onClick={onClickHandler}
			/>
		</>
	);
};

export default TopEpisodes;
