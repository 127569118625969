import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { useFlags } from 'launchdarkly-react-client-sdk';
import sanitizeHtml from 'sanitize-html';

import { Container, Row, Col, Form } from 'react-bootstrap';
import QuillEditor from 'components/shared/quillEditor';
import { CONTENT_TYPE } from 'components/constants';

import GenerateInfoButton from './generateInfoButton';

const SocialSection = (props) => {
	const {
		showNotes: _showNotes,
		description: _description,
		socialCardDescription: _socialCardDescription,
		youtubeDescription: _youtubeDescription,
		onChange = () => {},
		canEdit,
		isHosted,
		episodeContentType,
		episodeId,
		accountId,
		generatingAll,
		editsMade,
		setSocialSectionAllGenerated,
		disableGenerateButton,
		autoPublishYoutube,
		uploadType
	} = props;

	const { featureAiSummarySuggestionAccess } = useFlags();

	const [editDescription, setEditDescription] = useState(_description || '');
	const [editSocialCardDescription, setSocialCardDescription] = useState(
		_socialCardDescription || ''
	);
	const [editYoutubeDescription, setYoutubeDescription] = useState(
		_youtubeDescription || ''
	);
	const [editShowNotes, setEditShowNotes] = useState(_showNotes);

	// generating states
	// description
	const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);
	const [descriptionIsGenerated, setDescriptionIsGenerated] = useState(false);
	const [showDescriptionGenerateError, setShowDescriptionGenerateError] = useState(false);
	const [descriptionEditsMade, setDescriptionEditsMade] = useState(false);
	const [descriptionComplete, setDescriptionComplete] = useState(false);
	const [customDescErrorMessage, setCustomDescErrorMessage] = useState(false);
	// show notes
	const [isGeneratingShowNotes, setIsGeneratingShowNotes] = useState(false);
	const [showNotesAreGenerated, setShowNotesAreGenerated] = useState(false);
	const [showShowNotesGenerateError, setShowShowNotesGenerateError] = useState(false);
	const [showNotesEditsMade, setShowNotesEditsMade] = useState(false);
	const [showNotesComplete, setShowNotesComplete] = useState(false);
	const [customNotesErrorMessage, setCustomNotesErrorMessage] = useState(false);

	// social meta
	const [isGeneratingSocialMeta, setIsGeneratingSocialMeta] = useState(false);
	const [socialMetaIsGenerated, setSocialMetaIsGenerated] = useState(false);
	const [showSocialMetaGenerateError, setShowSocialMetaGenerateError] = useState(false);
	const [socialMetaEditsMade, setSocialMetaEditsMade] = useState(false);
	const [socialMetaComplete, setSocialMetaComplete] = useState(false);
	const [customMetaErrorMessage, setCustomMetaErrorMessage] = useState(false);
	const [youtubeDescriptionEditsMade, setYoutubeDescriptionEditsMade] = useState(false);

	const [generateEpDescription] = useMutation(mutations.generateEpisodeDescription, {
		onError: (err) => {
			console.error(err);
			if(err?.message === 'RateLimitError'){
				setCustomDescErrorMessage('Our AI is a bit busy right now, try again in a few minutes');
			}
			setShowDescriptionGenerateError(true);
		}
	});

	const [generateNotes] = useMutation(mutations.generateShowNotes, {
			onError: (err) => {
				console.error(err);
				if(err?.message === 'RateLimitError'){
					setCustomNotesErrorMessage('Our AI is a bit busy right now, try again in a few minutes');
				}
				setShowShowNotesGenerateError(true);
			},
		}
	);

	const [generateSocialMeta] = useMutation(mutations.generateSocialMetaDescription, {
		onError: (err) => {
			console.error(err);
			if(err?.message === 'RateLimitError'){
				setCustomMetaErrorMessage('Our AI is a bit busy right now, try again in a few minutes');
			}
			setShowSocialMetaGenerateError(true);
		},
	});

	useEffect(() => {
		if (!editsMade) {
			setDescriptionEditsMade(false);
			setShowNotesEditsMade(false);
			setSocialMetaEditsMade(false);
			setYoutubeDescriptionEditsMade(false);
		}
	}, [editsMade]);

	useEffect(() => {
		if (generatingAll) {
			generateDescription();
			generateShowNotes();
			generateSocMeta();
		}
	}, [generatingAll]);

	useEffect(() => {
		if (descriptionComplete && showNotesComplete && socialMetaComplete) {
			setSocialSectionAllGenerated(true);
		}
	}, [descriptionComplete, showNotesComplete, socialMetaComplete]);

	useEffect(() => {
		if (showDescriptionGenerateError) {
			setTimeout(() => {
				setShowDescriptionGenerateError(false);
			}, 5000);
		}
	}, [showDescriptionGenerateError]);

	useEffect(() => {
		if (showShowNotesGenerateError) {
			setTimeout(() => {
				setShowShowNotesGenerateError(false);
			}, 5000);
		}
	}, [showShowNotesGenerateError]);

	useEffect(() => {
		if (showSocialMetaGenerateError) {
			setTimeout(() => {
				setShowSocialMetaGenerateError(false);
			}, 5000);
		}
	}, [showSocialMetaGenerateError]);

	const generateDescription = async () => {
		setIsGeneratingDescription(true);
		setDescriptionIsGenerated(false);
		setDescriptionComplete(false);
		const { data } = await generateEpDescription({
			variables: {
				episodeId,
				accountId,
			},
		});
		const { generateEpisodeDescription } = data || {};
		if (generateEpisodeDescription) {
			setEditDescription(generateEpisodeDescription);
			setIsGeneratingDescription(false);
			setDescriptionIsGenerated(true);
		} else {
			setIsGeneratingDescription(false);
			setShowDescriptionGenerateError(true);
		}
		setDescriptionComplete(true);
 	};

	const generateShowNotes = async () => {
		setIsGeneratingShowNotes(true);
		setShowNotesAreGenerated(false);
		setShowNotesComplete(false);
		const { data } = await generateNotes({
			variables: {
				episodeId,
				accountId,
			},
		});
		const { generateShowNotes } = data || {};
		if (generateShowNotes) {
			setEditShowNotes(generateShowNotes);
			setIsGeneratingShowNotes(false);
			setShowNotesAreGenerated(true);
		} else {
			setIsGeneratingShowNotes(false);
			setShowShowNotesGenerateError(true);
		}
		setShowNotesComplete(true);
	};

	const generateSocMeta = async () => {
		setIsGeneratingSocialMeta(true);
		setSocialMetaIsGenerated(false);
		setSocialMetaComplete(false);
		const { data } = await generateSocialMeta({
			variables: {
				episodeId,
				accountId,
			},
		});
		const { generateSocialMetaDescription } = data || {};
		if (generateSocialMetaDescription) {
			handleSocialCard(generateSocialMetaDescription);
			setIsGeneratingSocialMeta(false);
			setSocialMetaIsGenerated(true);
		} else {
			setIsGeneratingSocialMeta(false);
			setShowSocialMetaGenerateError(true);
		}
		setSocialMetaComplete(true);
	};

	const handleShowNotes = (content, delta, source, editor) => {
		if (content !== _showNotes && (content || _showNotes)) {
			setEditShowNotes(content);
			onChange('showNotes', content);
			setShowNotesEditsMade(true);
		}
	};

	const handleDescription = (content, delta, source, editor) => {
		if (content !== _description && (content || _description)) {
			setEditDescription(content);
			onChange('description', content);
			setDescriptionEditsMade(true);
		}
	};

	const handleSocialCard = (value) => {
		let sanitizedContent = sanitizeHtml(value, {
			allowedTags: [],
		});
		if (
			sanitizedContent !== _socialCardDescription &&
			(sanitizedContent || _socialCardDescription)
		) {
			setSocialCardDescription(sanitizedContent);
			onChange('socialCardDescription', sanitizedContent);
			setSocialMetaEditsMade(true);
		}
	};
	
	const handleYoutubeCard = (value) => {
		let sanitizedContent = sanitizeHtml(value, {
			allowedTags: [],
		});

		if (
			sanitizedContent !== _youtubeDescription 
		) {
			setYoutubeDescription(sanitizedContent);
			onChange('youtubeDescription', sanitizedContent);
			setYoutubeDescriptionEditsMade(true);
		}
	};

	return (
		<Container className="px-2 pb-2">
			{episodeContentType === CONTENT_TYPE.episode && (
				<Row className="mb-3">
					<Col>
						<Form.Label>Episode Description</Form.Label>
						<p>
							Episode Description is used for descriptions in your RSS feed
							across all podcast listening platforms like Apple Podcasts and
							Spotify.
						</p>
						<div className="show-notes--editor">
							<QuillEditor
								disabled={!canEdit || !isHosted}
								defaultValue={editDescription}
								onChange={handleDescription}
								style={{ height: '85%' }}
								placeholder={
									'Episode Description is used for descriptions in your RSS feed across all podcast listening platforms like Apple Podcasts and Spotify.'
								}
							/>
							{featureAiSummarySuggestionAccess && (
								<GenerateInfoButton
									editsMade={descriptionEditsMade}
									isGeneratingInfo={isGeneratingDescription}
									infoIsGenerated={descriptionIsGenerated}
									showInfoGenerateError={showDescriptionGenerateError}
									funcToCall={generateDescription}
									className="rich-editor"
									disabled={disableGenerateButton}
									customErrorMessage={customDescErrorMessage}
									generateMessage = 'Generate an Episode Description'
								/>
							)}
						</div>
					</Col>
				</Row>
			)}
			<Row className="mb-3">
				<Col>
					<Form.Label>
						{`${episodeContentType === CONTENT_TYPE.episode ? 'Show' : 'Item'}`}{' '}
						Notes
					</Form.Label>
					<p>
						{`${episodeContentType === CONTENT_TYPE.episode ? 'Show' : 'Item'}`}{' '}
						Notes will be used on the {episodeContentType} page above the key
						takeaways.{' '}
						{`${
							episodeContentType === CONTENT_TYPE.episode
								? 'By default show notes will use Episode Description if left empty.'
								: ''
						}`}
					</p>
					<div className="show-notes--editor">
						<QuillEditor
							disabled={!canEdit}
							defaultValue={editShowNotes}
							onChange={handleShowNotes}
							style={{ height: '85%' }}
							placeholder={`Show Notes will be used on the episode page above the key takeaways. ${
								episodeContentType === CONTENT_TYPE.episode
									? "By default show notes will use Episode Description if left empty.\n\nThis lets you use a different summary or description for the episode on Casted than what's in your RSS feed (and listening platforms like Apple Podcasts and Google)."
									: ''
							}`}
						/>
						{featureAiSummarySuggestionAccess && (
							<GenerateInfoButton
								editsMade={showNotesEditsMade}
								isGeneratingInfo={isGeneratingShowNotes}
								infoIsGenerated={showNotesAreGenerated}
								showInfoGenerateError={showShowNotesGenerateError}
								funcToCall={generateShowNotes}
								className="rich-editor"
								disabled={disableGenerateButton}
								customErrorMessage={customNotesErrorMessage}
								generateMessage = 'Generate Show Notes'
							/>
						)}
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label>Social Card Description</Form.Label>
					<p>
						Social Card Description is used when sharing episodes and clips on
						social platforms that generate preview cards.
					</p>
					<div className="meta--editor">
						<Form.Control
							as="textarea"
							rows="8"
							disabled={!canEdit}
							value={editSocialCardDescription}
							onChange={(e) => handleSocialCard(e.target.value)}
							placeholder={`This field lets you control exactly what text shows up when a link to the Casted listening page is shared. ${
								episodeContentType === CONTENT_TYPE.episode
									? 'If not set, it will default to Episode Description (RSS).'
									: ''
							} Social Cards only support plain text description.`}
						/>
						{featureAiSummarySuggestionAccess && (
							<GenerateInfoButton
								editsMade={socialMetaEditsMade}
								isGeneratingInfo={isGeneratingSocialMeta}
								infoIsGenerated={socialMetaIsGenerated}
								showInfoGenerateError={showSocialMetaGenerateError}
								funcToCall={generateSocMeta}
								className="simple-editor"
								disabled={disableGenerateButton}
								customErrorMessage={customMetaErrorMessage}
								generateMessage = 'Generate a Social Card Description'
							/>
						)}
					</div>
				</Col>
			</Row>
			{autoPublishYoutube && uploadType == 'video' && (
				<Row className='mt-3'>
					<Col>
						<Form.Label>Youtube Description</Form.Label>
						<p>
							Youtube Description is used when episodes are published to YouTube.
						</p>
						<div className="meta--editor">
							<Form.Control
								as="textarea"
								rows="8"
								disabled={!canEdit}
								value={editYoutubeDescription}
								onChange={(e) => handleYoutubeCard(e.target.value)}
								placeholder={`Youtube Description is used when episodes are published to YouTube. Youtube Description only support plain text description.`}
								isInvalid={editYoutubeDescription?.length > 5000}
							/>
							<Form.Control.Feedback type="invalid" className='text-danger'>
								• Must be 5000 characters or less. Currently {(editYoutubeDescription || '' ).length} characters length.
							</Form.Control.Feedback>
						</div>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default SocialSection;
