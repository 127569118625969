import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';

// Internal libraries
import queries from 'services/graphql/queries';
import {
	THEME_STATUS,
	CONTENT_TYPE,
	getContentType,
} from 'components/constants';
import { getPercentileMatches } from 'utils/probability';

// Redux
import { dispatch } from 'store/store';
import {
	setThemeContentView,
	THEME_CONTENT_VIEWS,
} from 'store/reducers/ui/themes';

// External Components
import { DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

// Internal Components
import Pagination from 'components/shared/pagination';
import CastedToggleButtonGroup from 'components/shared/toggleButtonGroup';
import ContentRow from './contentRow';
import ThemeMetrics from '../themeMetrics';
import { ContentMixChart } from '../../../shared/contentMixChart';

// Internal Assets
import podcat from 'assets/images/podcat-playing-v2.png';

const ThemeContent = (props) => {
	const {
		match,
		scrollToTop = () => {},
		/* Redux props start */
		theme,
		themeUI,
		/* Redux props end */
	} = props;

	const { params } = match;
	const { accountId } = params;

	const isProcessing = theme.status === THEME_STATUS.processing;

	// These relevancy values are used to determine when to display green
	// relevancy score and when to display the amplify text
	const highRelevancy = 75;
	const amplifyRelevancy = 90;

	// Filter setup
	const [activeFilter, setActiveFilter] = useState(null);

	// Pagination setup
	const resultsPerPage = 10;
	const [currentPage, setCurrentPage] = useState(1);

	const toggleButtonList = [
		{
			text: `Matched Content${isProcessing ? ' (Processing)' : ''}`,
			value: THEME_CONTENT_VIEWS.RELEVANT_CONTENT,
		},
		{
			text: 'Metrics',
			value: THEME_CONTENT_VIEWS.METRICS,
		},
	];

	const isInternalObject = (obj) => obj.hasOwnProperty('episode');

	const {
		data: matchesData,
		loading: matchesLoading,
		error: matchesError,
	} = useQuery(queries.getThemeMatches, {
		variables: {
			accountId: parseInt(accountId),
			themeId: theme.id,
			matchThreshold: theme.matchThreshold,
		},
	});
	const { themeMatches = [] } = matchesData || {};

	useEffect(() => {
		toggleButtonList[0].text = `Matched Content${
			isProcessing ? ' (Processing)' : ''
		}`;
	}, [isProcessing]);

	const percentileThemeMatches = getPercentileMatches(themeMatches);

	const percentileFilters = {
		HIGH: 'high_matches',
		MEDIUM: 'medium_matches',
		LOW: 'low_matches',
	};

	const filteredResults =
		percentileThemeMatches?.length > 0
			? percentileThemeMatches.filter((themeMatch) => {
					switch (activeFilter) {
						case 'audio':
							if (isInternalObject(themeMatch)) {
								if (
									themeMatch.episode.upload_type ===
									activeFilter
								) {
									return true;
								}
							}
							return false;

						case 'video':
							if (isInternalObject(themeMatch)) {
								if (
									themeMatch.episode.upload_type ===
									activeFilter
								) {
									return true;
								}
							}
							return false;

						case 'written':
							if (!isInternalObject(themeMatch)) {
								if (
									themeMatch.externalObject.externalObjectType
										.content_type === activeFilter
								) {
									return true;
								}
							}
							return false;

						// Not used currently. Maybe soon!
						// case percentileFilters.HIGH:
						// 	if (episodeThemeMatch.percentile >= 0.75) {
						// 		return true;
						// 	}

						// 	return false;
						// case percentileFilters.MEDIUM:
						// 	if (episodeThemeMatch.percentile >= 0.25) {
						// 		return true;
						// 	}

						// 	return false;
						// case percentileFilters.LOW:
						// 	if (episodeThemeMatch.percentile >= 0) {
						// 		return true;
						// 	}

						// 	return false;
						default:
							return true;
					}
			  })
			: [];

	const onActiveFilterChange = (newFilter) => {
		setCurrentPage(1);
		setActiveFilter(newFilter);
	};

	const onToggleChange = (newValue) => {
		dispatch(setThemeContentView(newValue));
	};

	const renderRelevantContent = () => {
		if (matchesLoading) {
			return (
				<div className="theme-loading-container">
					<Spinner
						className="theme-loading"
						animation="grow"
						variant="info"
					/>
				</div>
			);
		}

		if (isProcessing) {
			// Return processing state
			return (
				<div className="theme-content-empty">
					<img className="podcat" src={podcat} />
					<p>
						Podcat is currently processing this theme. You'll get an
						email when he's done working.
					</p>
				</div>
			);
		}

		// Return relevant content rows
		if (filteredResults?.length > 0) {
			return (
				<>
					<div className="theme-content-body">
						{filteredResults.map((themeMatch, i) => {
							const maxResult = currentPage * resultsPerPage;
							const minResult = maxResult - resultsPerPage;

							if (minResult <= i && i < maxResult) {
								return (
									<ContentRow
										key={i}
										accountId={accountId}
										highRelevancy={highRelevancy}
										amplifyRelevancy={amplifyRelevancy}
										relevancy={50}
										themeMatch={themeMatch}
									/>
								);
							}
						})}
					</div>
					<Pagination
						current={currentPage}
						onChange={(newPage) => {
							setCurrentPage(newPage);
							scrollToTop();
						}}
						resultsPerPage={resultsPerPage}
						totalResults={filteredResults.length}
					/>
				</>
			);
		}

		// Return empty state
		return (
			<>
				<div className="theme-content-empty">
					<img className="podcat" src={podcat} />
					<p>
						Podcat will display your content related to your theme
						here.
					</p>
				</div>
			</>
		);
	};

	const renderThemeContent = () => {
		switch (themeUI.contentView) {
			case THEME_CONTENT_VIEWS.METRICS:
				return <ThemeMetrics />;
			case THEME_CONTENT_VIEWS.RELEVANT_CONTENT:
			default:
				return renderRelevantContent();
		}
	};

	const getMatchContentTypes = () => {
		if (themeMatches?.length > 0) {
			let contentTypes = { total: 0 };

			themeMatches.forEach((themeMatch) => {
				let contentType = 'other';
				if (themeMatch.hasOwnProperty('episode')) {
					contentType = themeMatch.episode.upload_type;
				} else if (themeMatch.hasOwnProperty('externalObject')) {
					contentType =
						themeMatch.externalObject.externalObjectType
							.content_type;
				}

				if (!contentTypes.hasOwnProperty(contentType)) {
					contentTypes[contentType] = 0;
				}

				contentTypes[contentType] = contentTypes[contentType] + 1;
				contentTypes['total'] = contentTypes['total'] + 1;
			});
			return contentTypes;
		}
	};

	return (
		<div className="theme-content">
			<div className="theme-content-header">
				<h3>Theme Metrics</h3>
				<CastedToggleButtonGroup
					toggleButtonList={toggleButtonList}
					defaultValue={themeUI.contentView}
					onChange={onToggleChange}
				/>
			</div>
			<div className="theme-content-chart-container">
				<ContentMixChart
					filterChange={(newFilterValue) =>
						onActiveFilterChange(newFilterValue)
					}
					contentBreakdown={getMatchContentTypes()}
				/>
			</div>
			{renderThemeContent()}
		</div>
	);
};

const mapStateToProps = (state) => ({
	theme: state.themeData.theme,
	themeUI: state.ui.themes,
});

export default withRouter(connect(mapStateToProps)(ThemeContent));
