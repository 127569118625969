import React from 'react';
import { connect } from 'react-redux';

import { dispatch } from 'store/store';
import { hideClipShareModal } from 'store/reducers/ui/modals/clipShareModal';
import { setSelectedClip } from 'store/reducers/data/transcript';

import SocialPreview from 'components/shared/sharePreviews/social';
import EmbedPreview from 'components/shared/sharePreviews/embed';
import CreateAudiogram from 'components/shared/audiogram';

import { Modal, Nav, Tab, Alert } from 'react-bootstrap';
import { FaVideo, FaLink, FaCode } from 'react-icons/fa';

const ShareClipModal = (props) => {
	/** Props **/
	const {
		accountId,
		podcastName,
		episode,
		customDomain,
		podcastSlug,
		podcastThumbnail,
		selectedClip,
		modals,
		disableLinkSharing,
	} = props;

	const {
		episodeId,
		slug: episodeSlug,
		thumbnail,
		storageLink,
		socialThumbnail,
		duration: episodeDuration = 0,
		publicStatus,
	} = episode || {};

	const {
		name: clipName,
		slug: clipSlug,
		startTime,
		endTime,
	} = selectedClip || {};

	const clipTooLongForAudiogram = endTime - startTime > 140;
	const rootUrl = customDomain
		? `https://${customDomain}`
		: process.env.REACT_APP_PUBLIC_APP_ROOT;
	const shareUrl = `${rootUrl}/public/${accountId}/${podcastSlug}/${episodeSlug}`;
	const audioEmbedUrl = `${rootUrl}/embed/v2/smallPlayer/${clipSlug}/share`;
	const videoEmbedUrl = `${rootUrl}/embed/v2/regularPlayer/${clipSlug}/share/video`;
	const audiogramEmbedUrl = `${rootUrl}/embed/v2/regularPlayer/${clipSlug}/video?audiogram=true`;

	const onClose = () => {
		dispatch(hideClipShareModal());
		dispatch(setSelectedClip({}));
	};

	/** Render **/
	return (
		<Modal
			className="modal-share-clip"
			show={modals.clipShareModal.showModal}
			onHide={onClose}
			size="md"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Share Clip</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{publicStatus === 'draft' && (
					<Alert variant="info" className="som-share-status-banner">
						<strong>Heads Up!</strong>
						<br />
						This episode is not published yet. You can share via the
						options below, but users will not be able to see it
						until the episode is published.
					</Alert>
				)}
				<Tab.Container
					defaultActiveKey={
						clipTooLongForAudiogram && !disableLinkSharing
							? 'link'
							: 'audiogram'
					}
					mountOnEnter={true}
				>
					<Nav className="share-clip-tabs">
						<Nav.Item>
							<Nav.Link eventKey="audiogram">
								<FaVideo />
							</Nav.Link>
						</Nav.Item>
						{!disableLinkSharing && (
							<Nav.Item>
								<Nav.Link eventKey="link">
									<FaLink />
								</Nav.Link>
							</Nav.Item>
						)}
						<Nav.Item>
							<Nav.Link eventKey="embed">
								<FaCode />
							</Nav.Link>
						</Nav.Item>
					</Nav>

					<Tab.Content>
						<Tab.Pane eventKey="audiogram">
							<CreateAudiogram
								accountId={accountId}
								podcastName={podcastName}
								episode={episode}
								link={audiogramEmbedUrl}
								podcastThumbnail={podcastThumbnail}
								baseUrl={rootUrl}
							/>
						</Tab.Pane>

						{!disableLinkSharing && (
							<Tab.Pane eventKey="link">
								<SocialPreview
									name={clipName}
									thumbnail={
										socialThumbnail ||
										thumbnail ||
										podcastThumbnail
									}
									link={shareUrl}
									clipId={selectedClip.id}
									accountId={accountId}
								/>
							</Tab.Pane>
						)}

						<Tab.Pane eventKey="embed">
							<EmbedPreview
								episode={episode}
								audioEmbedUrl={audioEmbedUrl}
								videoEmbedUrl={videoEmbedUrl}
								podcastThumbnail={podcastThumbnail}
								baseUrl={rootUrl}
								accountId={accountId}
							/>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</Modal.Body>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	selectedClip: state.transcript.selectedClip,
	modals: state.ui.modals,
});

export default connect(mapStateToProps)(ShareClipModal);
