export const ACTIONS = {
	HIDE_PLAYLISTS_MODAL: 'CASTED/PLAYLIST_SHARE_MODAL/HIDE_PLAYLISTS_MODAL',
	SHOW_PLAYLISTS_MODAL: 'CASTED/PLAYLIST_SHARE_MODAL/SHOW_PLAYLISTS_MODAL',
	HIDE_PLAYLIST_MODAL: 'CASTED/PLAYLIST_SHARE_MODAL/HIDE_PLAYLIST_MODAL',
	SHOW_PLAYLIST_MODAL: 'CASTED/PLAYLIST_SHARE_MODAL/SHOW_PLAYLIST_MODAL',
};

export const initialState = {
	showPlaylistsModal: false,
	showPlaylistModal: false,
	playlistToShare: null,
};

export const hidePlaylistsShareModal = () => {
	return {
		type: ACTIONS.HIDE_PLAYLISTS_MODAL,
	};
};

export const showPlaylistsShareModal = (playlistToShare) => {
	return {
		type: ACTIONS.SHOW_PLAYLISTS_MODAL,
		payload: playlistToShare,
	};
};

export const hidePlaylistShareModal = () => {
	return {
		type: ACTIONS.HIDE_PLAYLIST_MODAL,
	};
};

export const showPlaylistShareModal = () => {
	return {
		type: ACTIONS.SHOW_PLAYLIST_MODAL,
	};
};

const playlistShareModalReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ACTIONS.HIDE_PLAYLISTS_MODAL:
			return {
				...state,
				showPlaylistsModal: false,
				playlistToShare: null,
			};
		case ACTIONS.SHOW_PLAYLISTS_MODAL:
			return {
				...state,
				showPlaylistsModal: true,
				playlistToShare: payload,
			};
		case ACTIONS.HIDE_PLAYLIST_MODAL:
			return {
				...state,
				showPlaylistModal: false,
				playlistToShare: null,
			};
		case ACTIONS.SHOW_PLAYLIST_MODAL:
			return {
				...state,
				showPlaylistModal: true,
				playlistToShare: null,
			};
		default:
			return state;
	}
};

export default playlistShareModalReducer;
