import React, { useState } from 'react';
import { FaUpload } from 'react-icons/fa';

const FileUploader = (props) => {
	const {
		overlayMsg = '',
		className = '',
		fileContainerClassName = '',
		overlayClassName = '',
		accept = '*',
		uploadInProgress = false,
		uploadProgress = null,
		isCastedHosted = false,
		disabled = false,
	} = props;

	// HOOKS
	const [isDragging, setIsDragging] = useState(false);
	const [showFileSizeError, setShowFileSizeError] = useState(false);
	// END HOOKS

	const onDragEnter = (e) => {
		setIsDragging(true);
	};

	const onDragLeave = (e) => {
		setIsDragging(false);
	};

	const onDragOver = (e) => {
		e.preventDefault();
	};

	const onFileChange = (e, f) => {
		const file = f || e.target.files[0];

		if (props.verifyFile && !props.verifyFile(file)) {
			return;
		}

		// Allow up to 300mb (Spreaker limit) for external hosting. Allow any size for casted hosting
		if (!isCastedHosted) {
			// Convert to mb
			const filesize = file.size / 1024 / 1024;

			if (filesize > 300) {
				setShowFileSizeError(true);
				return;
			}
		}

		if (props.onUpload) {
			props.onUpload(file);
		}
	};

	const onDrop = (e) => {
		e.preventDefault();
		setIsDragging(false);
		onFileChange(e, e.dataTransfer.files[0]);
	};

	const renderOverlayMsg = () => {
		if (overlayMsg) {
			return <>{overlayMsg}</>;
		}
		if (showFileSizeError) {
			return (
				<>
					<div className="text-danger">
						File size cannot exceed 300mb. Please upload a smaller file.
					</div>
					<div className="d-flex justify-content-center">
						Drag and drop or <br />
						Click to upload
					</div>
				</>
			);
		}
		return (
			<div className="d-flex flex-column align-items-center">
				<FaUpload size={30} className="mb-4" />
				<>
					Drag and drop or <br />
					Click to upload
				</>
			</div>
		);
	};

	const showProgress = uploadInProgress && uploadProgress && uploadProgress > 0;

	return (
		<div className={`file-uploader ${className}`}>
			<label
				className={`file-container ${fileContainerClassName} ${
					isDragging ? 'dragging' : ''
				}`}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDragOver={onDragOver}
				onDrop={onDrop}
			>
				<div className={`file-edit-overlay ${overlayClassName}`}>
					{!showProgress && renderOverlayMsg()}
					{showProgress && (
						<div className="upload-progress">
							<div className="bar" style={{ width: `${uploadProgress}%` }} />
						</div>
					)}
				</div>
				<input
					type="file"
					accept={accept}
					onChange={onFileChange}
					className="file-upload-input"
					disabled={disabled}
				/>
			</label>
		</div>
	);
};

export default FileUploader;
