import React from 'react';

import BaseTable from 'components/shared/baseTable';
import AddCollectionPermissionModal from 'components/users/addCollectionPermissionModal';
import TableConfirmationButton from 'components/shared/tableConfirmationButton';
import TablePermissionBadge from 'components/shared/tablePermissionBadge';
import TableRolesDropdown from 'components/shared/tableRolesDropdown';

const UserCollectionPermissions = (props) => {
	const {
		rolesList,
		userPermissions,
		setUserPermissions,
		permissionsToUpdate,
		setPermissionsToUpdate,
		permissionsToRemove,
		setPermissionsToRemove,
		defaultPermissions,
		setDefaultPermissions,
		filteredDefaultPermissions,
		setFilteredDefaultPermissions,
		loadingPermissions,
		isCurrentUser,
		accountRole,
		isAdmin,
	} = props;

	const onRemove = (row) => {
		let permissions = [...userPermissions];
		permissions = permissions.filter(
			(permission) => permission.collectionId !== row.collectionId
		);
		setUserPermissions(permissions);
		setDefaultPermissions([{ ...row }, ...defaultPermissions]);
		setFilteredDefaultPermissions([{ ...row }, ...defaultPermissions]);

		if (row.userId && !permissionsToRemove.includes(row.id)) {
			setPermissionsToRemove([row.id, ...permissionsToRemove]);
		}

		if (permissionsToUpdate.includes(row.collectionId)) {
			const updates = [...permissionsToUpdate];
			setPermissionsToUpdate(
				updates.filter((collectionId) => collectionId !== row.collectionId)
			);
		}
	};

	const onUpdateRole = (roleId, row) => {
		let permissions = [...userPermissions];
		permissions = permissions.map((permission) => {
			if (permission.collectionId === row.collectionId) {
				permission.roleId = roleId;
			}
			return permission;
		});
		setUserPermissions(permissions);

		if (!permissionsToUpdate.includes(row.collectionId)) {
			setPermissionsToUpdate([row.collectionId, ...permissionsToUpdate]);
		}
	};

	const onAdd = (collectionIds) => {
		const collectionPermissionObjects = [];
		const collectionPermissions = [];
		const updatedDefaultPermissions = [];

		defaultPermissions.forEach((permission) => {
			if (collectionIds.includes(permission.collectionId)) {
				collectionPermissionObjects.push(permission);
				collectionPermissions.push(permission.collectionId);
			} else {
				updatedDefaultPermissions.push(permission);
			}
		});
		setUserPermissions([...collectionPermissionObjects, ...userPermissions]);
		setPermissionsToUpdate([...collectionPermissions, ...permissionsToUpdate]);
		setDefaultPermissions(updatedDefaultPermissions);
		setFilteredDefaultPermissions(updatedDefaultPermissions);
	};

	const permissionColumns = [
		{
			name: 'Collection',
			cell: (row) => <div>{row.collectionName}</div>,
			selector: (row) => row.collectionName,
			sortable: true,
			minWidth: '300px',
		},
		{
			name: 'Role',
			cell: (row) => (
				<TablePermissionBadge
					roleId={row.roleId}
					id={row.collectionId}
					rolesList={rolesList}
				/>
			),
			selector: (row) => {
				const role = rolesList.find((r) => r.id === row.roleId);
				const roleName = role ? role.name : 'No Access';
				return roleName;
			},
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<TableRolesDropdown
					row={row}
					rolesList={rolesList}
					onUpdateRole={onUpdateRole}
				/>
			),
			style: { cursor: 'pointer' },
		},
		{
			name: '',
			cell: (row) => (
				<TableConfirmationButton
					row={row}
					onRemove={onRemove}
					action={'Revoke Access'}
					dialog={
						<span>
							Revoking this user's access will reset their permission for{' '}
							<strong>"{row.collectionName}"</strong> to default. They won't be
							able to perform any role specific actions.
						</span>
					}
					rowId={row.collectionId}
				/>
			),
			style: { cursor: 'pointer' },
		},
	];

	const customStyles = {
		header: {
			style: {
				backgroundColor: '#FCFCFC',
			},
		},
		subHeader: {
			style: {
				marginTop: '-50px',
				background: 'transparent',
			},
		},
		head: {
			style: {
				borderTop: '1px solid rgba(0, 0, 0, 0.125)',
			},
		},
	};

	let emptyState = (
		<h5 className="user-permissions-empty">
			<span>No individual roles have been set.</span>
		</h5>
	);

	if (isAdmin) {
		emptyState = (
			<h5 className="user-permissions-empty">
				<span>Account Admins have access to all content.</span>
			</h5>
		);
	}

	return (
		<BaseTable
			title="User Permissions"
			values={!isAdmin ? userPermissions : []}
			columns={permissionColumns}
			loading={loadingPermissions}
			clickable={false}
			pagination={true}
			paginationPerPage={3}
			customStyle={customStyles}
			subHeader={true}
			subHeaderComponent={
				<AddCollectionPermissionModal
					defaultPermissions={defaultPermissions}
					filteredDefaultPermissions={filteredDefaultPermissions}
					setFilteredDefaultPermissions={setFilteredDefaultPermissions}
					onAdd={onAdd}
					disabled={isCurrentUser || isAdmin}
				/>
			}
			subHeaderAlign={'right'}
			noDataComponent={emptyState}
			disabled={isCurrentUser}
			resetPaginationToggle={false}
		/>
	);
};

export default UserCollectionPermissions;
