import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import crypto from 'crypto';

import Button from '../shared/button';

import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';

import { ACCOUNT_INTEGRATION_TYPES } from 'components/constants';

const AccountListRow = (props) => {
	const { account, callbackUrl } = props;

	const decodedCallback = decodeURIComponent(callbackUrl);

	const { data } = useQuery(queries.podcastsByAccountId, {
		variables: { accountId: parseInt(account.id, 10) },
	});
	const { podcasts: shows = [] } = data || {};

	const [clientId, setClientId] = useState(uuid());
	const [clientSecret, setClientSecret] = useState(
		crypto.randomBytes(32).toString('hex')
	);

	const [setAuthCode] = useMutation(mutations.createApiKey, {
		variables: {
			accountId: account.id,
			name: callbackUrl.split('?')[0],
			clientId,
			clientSecret,
			integrationName: ACCOUNT_INTEGRATION_TYPES.WORDPRESS,
			isAccountLevel: true,
		},
		onCompleted: () => makeCallback(),
	});

	const makeCallback = () => {
		window.location = `${decodedCallback}&accountId=${account.id}&code=${clientSecret}&clientId=${clientId}&userId=-1`;
	};

	return (
		<div className="account-list--account">
			<Row className="account-row">
				<Col className="account-list--name">
					<h5>{account.name}</h5>
				</Col>
				<Col className="account-list--shows">
					<span>{shows.map((show) => show.name).join(', ')}</span>
				</Col>
				<Col className="account-list--action">
					<Button variant="success" onClick={() => setAuthCode()}>
						LINK ACCOUNT
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default AccountListRow;
