import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';

import queries from 'services/graphql/queries';
import Toast from 'services/toast';
import LoadingShow from 'components/shared/loadingSkeletons/loadingShow';
import Button from '../shared/button';
import mutations from 'services/graphql/mutations';

const MigrateShow = (props) => {
	// Props
	const { match } = props;
	const { params } = match;
	const { showId, accountId } = params;

	const [migrateComplete, setMigrateComplete] = useState(false);

	// Queries
	const {
		data: { podcast = {} } = {},
		loading,
		error,
	} = useQuery(queries.podcastById, { variables: { podcastId: showId } });

	const [migrateShow, { loading: migrateShowLoading }] = useMutation(
		mutations.migratePodcastToHosted,
		{
			variables: { podcastId: showId },
			onCompleted: () => {
				Toast.success('Show migrated!');
				setMigrateComplete(true);
			},
		}
	);

	// Render
	if (loading && !podcast) {
		return <LoadingShow />;
	}

	if (error) {
		return <p>Error getting migration page</p>;
	}

	if (migrateComplete) {
		return (
			<Redirect to={`/account/${accountId}/shows/${showId}/episodes`} />
		);
	}

	if (
		podcast.externalId &&
		podcast.externalId === podcast.slug &&
		!migrateComplete
	) {
		Toast.error('Show already migrated!');
		return (
			<Redirect to={`/account/${accountId}/shows/${showId}/episodes`} />
		);
	}

	return (
		<div>
			<header className="show-admin--header">
				<Container>
					<Row className="show-header">
						<Col className="show-header--nav">
							<h2 className="show-nav--title">Migrate Show</h2>
						</Col>
					</Row>
				</Container>
			</header>

			<Container className="show-admin--section">
				<Row>
					<Col>
						<Card>
							<Card.Body className="mx-3">
								<h4 className="admin-section--title my-3">
									Migrate {podcast.name}
								</h4>
								<Row className="mb-3">
									<Col xs={12} md={8}>
										<p
											style={{
												fontSize: '12px',
												marginBottom: 0,
											}}
										>
											Make sure you click the "Pull Latest
											Episodes" button on the episodes
											page before proceeding. Once all
											episodes have been pulled, click the
											"Migrate" button to migrate this
											show to Casted.
										</p>
									</Col>
									<Col
										xs={12}
										md={4}
										className="d-flex justify-content-end align-items-center"
									>
										<Button
											variant="primary"
											onClick={migrateShow}
											disabled={migrateShowLoading}
										>
											Migrate
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default withRouter(MigrateShow);
