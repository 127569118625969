import React from 'react';
import { connect } from 'react-redux';

import { dispatch } from 'store/store';
import {
	setAudiogramStep,
	AudiogramStepsEnum,
} from 'store/reducers/ui/modals/clipShareModal';

import { FaAngleLeft } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';

import Button from 'components/shared/button';
import ShareForm from 'components/shared/shareForm';

const SocialEmbedDetails = (props) => {
	const { socialDetails, setSocialDetails, createEmbedShare } = props;

	return (
		<div className="create-audiogram audiogram-preview">
			<Row className="preview-instructions">
				<Col>
					<h4 className="preview-title">Shareable Audiogram</h4>
				</Col>
				<Col style={{ textAlign: 'right' }}>
					<Button
						className="audiogram-start-over"
						variant="link-no-href"
						onClick={() => {
							dispatch(setAudiogramStep(AudiogramStepsEnum.TYPE));
						}}
					>
						<FaAngleLeft /> Back
					</Button>
				</Col>
			</Row>
			<ShareForm share={socialDetails} setShareProperty={setSocialDetails} />
			<div className="create-social-share">
				<Button
					className="w-50 mt-3 "
					variant="success"
					onClick={createEmbedShare}
					disabled={socialDetails.title.trim() === ''}
				>
					Create Audiogram
				</Button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedClip: state.transcript.selectedClip,
	modals: state.ui.modals,
	selectedAccountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(SocialEmbedDetails);
