import React, { useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import moment from 'moment-timezone';
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import LoadingLineChart from 'components/shared/loadingSkeletons/loadingLineChart';
import MetricEmptyState from 'components/shared/metricEmptyState';

import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Legend,
} from 'recharts';

const MetricLineChart = (props) => {
	const {
		values: listens = [],
		loading = true,
		title = 'Engagements',
		className = null,
		showHelp = true,
		showTooltip = true,
		datasets = [],
	} = props;

	// State
	const [displayBy, setDisplayBy] = useState('Days');
	const [dateFormat, setDateFormat] = useState('M/D');

	useEffect(() => {
		if (!loading) {
			const sortedListens = sortByDate(listens);
			// Compute number of days difference from earliest to most recent date
			// if more than 365 days add year to formatter
			if (
				sortedListens.length &&
				parseInt(
					moment(sortedListens[sortedListens.length - 1].date).diff(
						moment(sortedListens[0].date),
						'days'
					)
				) > 365
			) {
				setDateFormat('M/D/Y');
			}
		}
	}, [loading]);

	// Helpers
	const sortByDate = (array) => {
		return array.sort((item1, item2) => {
			if (new Date(item1.date) > new Date(item2.date)) {
				return 1;
			}
			return -1;
		});
	};

	const displayByFilter = (array) => {
		if (displayBy === 'Days') return array;

		const batchSize = displayBy === 'Weeks' ? 7 : 30;
		const arrCopy = array.slice();
		const returningArr = [];
		while (arrCopy.length) {
			const batch = arrCopy.splice(0, batchSize);
			const reduced = batch.reduce((sum, item) => {
				let retValue = {};
				datasets.forEach(
					(dataset) =>
						(retValue[dataset.dataKey] =
							sum[dataset.dataKey] + item[dataset.dataKey])
				);

				return retValue;
			});
			reduced.date_range = `${moment(batch[0].date).format(
				dateFormat
			)} - ${moment(batch[batch.length - 1].date).format(dateFormat)}`;
			reduced.date = batch[0].date;
			returningArr.push(reduced);
		}
		return returningArr;
	};

	const dateFormatter = (date) => {
		return moment(date).format(dateFormat);
	};

	// Render Helpers
	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			const { date, date_range, total_count, download_count, play_count } =
				payload[0].payload;
			return (
				<div className="timeline-tooltip">
					<p className="label label-date">
						{date_range ? date_range : moment(date).format('ddd MMM D')}
					</p>
					<hr />
					<p className="label tooltip-stat">
						Total Impressions <span className="value">{total_count}</span>
					</p>
					<ul className="tooltip-sub-stats">
						{datasets.map(
							(x) =>
								x.dataKey !== 'total_count' && (
									<li className="tooltip-stat">
										<span className="label">{x.name}</span>
										<span className="value">
											{payload[0].payload[x.dataKey]}
										</span>
									</li>
								)
						)}
					</ul>
				</div>
			);
		}
		return <></>;
	};

	const displayByDropdown = () => {
		if (!listens) return;
		const options = ['Days'];
		if (listens.length > 7) options.push('Weeks');
		if (listens.length > 30) options.push('Months');

		return (
			<DropdownButton
				className="display-by-dropdown"
				title={`Display By ${displayBy}`}
				variant="link"
			>
				{options.map((option) => (
					<Dropdown.Item
						key={option}
						onSelect={() => {
							setDisplayBy(option);
						}}
					>
						{option}
					</Dropdown.Item>
				))}
			</DropdownButton>
		);
	};

	// Render

	if (!loading && listens.length < 1) {
		return <MetricEmptyState metricName={title} />;
	}

	return (
		<Card className={`metric-line-chart ${className}`}>
			<Card.Body className="chart-header">
				<Row>
					<Col>
						<Card.Title className="chart-title">
							{title}{' '}
							{showHelp && (
								<FaInfoCircle className="info-icon" id={`help-${title}`} />
							)}
						</Card.Title>
					</Col>
					<Col>{displayByDropdown()}</Col>
				</Row>
			</Card.Body>

			<div className="chart-container">
				{loading ? (
					<LoadingLineChart />
				) : (
					<ResponsiveContainer>
						<AreaChart
							data={displayByFilter(sortByDate(listens))}
							margin={{ right: 30 }}
						>
							<CartesianGrid stroke="#ebedf3" />
							<XAxis
								dataKey="date"
								tickFormatter={dateFormatter}
								tickMargin={10}
								stroke="#ebedf3"
								tickSize={0}
							/>
							<YAxis
								tickSize={0}
								tickMargin={10}
								allowDecimals={false}
								stroke="#ebedf3"
							/>
							<Tooltip content={<CustomTooltip />} />
							<Legend align="left" verticalAlign="bottom" />
							{datasets.map((x) => (
								<Area
									type="monotone"
									legendType="circle"
									dataKey={x.dataKey}
									stroke={x.color}
									fill={x.color}
									name={x.name}
									key={x.dataKey}
								/>
							))}
						</AreaChart>
					</ResponsiveContainer>
				)}
			</div>
		</Card>
	);
};

export default MetricLineChart;
