import React from 'react'
import initials from 'initials'

class AvatarUploader extends React.Component {
  state = {
    avatarDndActive: false
  }

  onDragEnter = (e) => {
    this.setState({ avatarDndActive: true })
  }

  onDragLeave = (e) => {
    this.setState({ avatarDndActive: false })
  }

  onDragOver = (e) => {
    e.preventDefault()
  }

  onDrop = (e) => {
    e.preventDefault()
    this.setState({ avatarDndActive: false })
    this.onFileChange(e, e.dataTransfer.files[0])
  }

  onFileChange = (e, f) => {
    const file = f || e.target.files[0]
    const reader = new FileReader()

    // TODO: reject really big files

    if (!file.type.match(/image-*/)) {
      // TODO: handle error for wrong file type
      return
    }

    reader.onload = (e) => {
      if (this.props.onUpload) {
        this.props.onUpload(reader.result)
      }
    }

    reader.readAsDataURL(file)
  }

  render() {
    const { avatarDndActive } = this.state
    const { avatarImgSrc, name = 'Default Image', overlayMsg } = this.props
    const avatarImgOverlayClass = avatarDndActive ?
      'avatar-img-edit-overlay drag-active' :
      'avatar-img-edit-overlay'

    return (
      <div className="avatar-uploader">
        <label
          className="avatar-img-container"
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          <div className={avatarImgOverlayClass}>
            {overlayMsg || 'Change your photo'}
          </div>
          {
            avatarImgSrc &&
            <img
              src={avatarImgSrc}
              className="avatar-image"
              alt="avatar-upload"
            />
          }
          {
            !avatarImgSrc &&
            <span className="avatar-upload-initials">
              {initials(name)}
            </span>
          }
          <input
            type="file"
            accept="image/*"
            onChange={this.onFileChange}
            ref="input"
            className="avatar-editor-file-upload-input"
          />
        </label>
      </div>
    )
  }
}

export default AvatarUploader
