import React from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';

import { dispatch } from 'store/store';
import { hideDeleteClipModal } from 'store/reducers/ui/modals/deleteClipModal';

import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

import { Modal, Form } from 'react-bootstrap';

import Button from 'components/shared/button';

const DeleteClipModal = (props) => {
	/** Props **/
	const { modals } = props;

	/** Queries & Mutations **/
	const [deleteClip] = useMutation(mutations.deleteClipById, {
		variables: { clipId: modals.deleteClipModal.clipToDelete?.id },
		refetchQueries: ['ClipsInEpisode'],
	});

	const onDelete = async () => {
		await deleteClip();
		trackEvent('delete-clip', {
			name: modals.deleteClipModal.clipToDelete?.name,
		});
		dispatch(hideDeleteClipModal());
	};

	/** Render **/
	return (
		<Modal
			show={modals.deleteClipModal.showModal}
			onHide={() => {
				dispatch(hideDeleteClipModal());
			}}
			id="clip-modal"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h4>Delete Clip?</h4>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<h5>{modals.deleteClipModal.clipToDelete?.name}</h5>
				</Form.Group>
				<p>Are you sure you want to delete this clip?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => {
						dispatch(hideDeleteClipModal());
					}}
					variant="cancel"
				>
					Cancel
				</Button>
				<Button onClick={onDelete} variant="danger">
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	modals: state.ui.modals,
});

export default connect(mapStateToProps)(DeleteClipModal);
