import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';

import { dispatch } from 'store/store';
import { hideDeleteThemeModal } from 'store/reducers/ui/modals/deleteThemeModal';
import { showEditThemeOnLoad } from 'store/reducers/ui/themes';

import Toast from 'services/toast';
import queries from 'services/graphql/queries';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

import { THEME_PUBLIC_STATUS } from 'components/constants';

import { FaPlus } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

// Shared Components
import Button from 'components/shared/button';
import Modal from 'components/shared/modal';

import ThemeGroup from './themeGroup';

import podcat from 'assets/images/podcat-playing-v2.png';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const Themes = (props) => {
	const { match, permissions, modals, themeUI } = props;
	const { params } = match;
	const { accountId } = params;

	const themesPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.THEMES)
				.rolePermission
		: {};

	/** Queries & Mutations **/
	const { data, loading, error } = useQuery(queries.getThemesByAccountId, {
		variables: { accountId: parseInt(accountId, 10) },
		fetchPolicy: 'no-cache',
	});
	const { themes = [] } = data || {};

	const [createTheme] = useMutation(mutations.createTheme);

	const [deleteTheme] = useMutation(mutations.deleteThemeById, {
		refetchQueries: ['themes'],
	});

	const newThemeOnClick = async () => {
		try {
			let res = await createTheme({
				variables: {
					accountId: parseInt(accountId),
				},
			});
			const { data } = res;
			const { createTheme: createdTheme } = data;

			if (createdTheme && createdTheme.id) {
				trackEvent('add-theme');
				dispatch(showEditThemeOnLoad());
				props.history.push(
					`/account/${accountId}/themes/${createdTheme.id}`
				);
			}
		} catch (e) {
			console.error(e);
			Toast.error('Unable to create theme');
		}
	};

	const priorityThemes = themes.filter(
		(theme) => theme.publicStatus === THEME_PUBLIC_STATUS.priority
	);
	const moreThemes = themes.filter(
		(theme) => theme.publicStatus === THEME_PUBLIC_STATUS.other
	);

	const renderThemesContent = (loading, priorityThemes, moreThemes) => {
		let renderElements = [];

		if (loading) {
			return (
				<div className="theme-loading-container">
					<Spinner
						className="theme-loading"
						animation="grow"
						variant="info"
					/>
				</div>
			);
		}

		if (priorityThemes?.length > 0) {
			renderElements.push(
				<ThemeGroup
					groupHeader="Priority Themes"
					themeList={priorityThemes}
				/>
			);
		}

		if (moreThemes?.length > 0) {
			renderElements.push(
				<ThemeGroup groupHeader="More Themes" themeList={moreThemes} />
			);
		}

		if (!priorityThemes?.length > 0 && !moreThemes?.length > 0) {
			renderElements.push(
				<div className="theme-content-empty">
					<img className="podcat" src={podcat} />
					<p>Podcat will allow you to manage your themes here.</p>
				</div>
			);
		}

		return renderElements;
	};

	return (
		<>
			<div className="themes">
				<div className="themes-header-row">
					<h2 className="themes-header">Theme Management</h2>
					{themesPermission.canEdit && (
						<Button
							className="themes-button"
							variant="flat"
							title="Create a new show"
							onClick={newThemeOnClick}
						>
							<FaPlus className="plus-icon" />
							<div className="themes-button-text">New</div>
						</Button>
					)}
				</div>
				<div className="themes-list">
					{renderThemesContent(loading, priorityThemes, moreThemes)}
				</div>
			</div>
			<Modal
				show={modals.deleteThemeModal.showModal}
				handleClose={() => {
					dispatch(hideDeleteThemeModal());
				}}
				title="Delete Theme"
				dialogOptions={[
					{
						variant: 'light',
						text: 'Cancel',
						onClick: () => {
							dispatch(hideDeleteThemeModal());
						},
					},
					{
						variant: 'danger',
						text: 'Delete',
						onClick: async () => {
							await deleteTheme({
								variables: {
									themeId:
										modals.deleteThemeModal.themeToDelete
											?.id,
								},
							});
							trackEvent('delete-theme', {
								name: modals.deleteThemeModal.themeToDelete
									?.name,
							});
							dispatch(hideDeleteThemeModal());
						},
					},
				]}
			>
				<p>
					Are you sure you want to delete the{' '}
					{`${modals.deleteThemeModal.themeToDelete?.name || ''}`}{' '}
					theme?
				</p>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
	modals: state.ui.modals,
	themeUI: state.ui.themes,
});

export default withRouter(connect(mapStateToProps)(Themes));
