import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import MetricLineChart from 'components/shared/metricLineChart';

const ListensOverTime = (props) => {
	const variables = {
		clipId: props.clipId,
		shareSlug: props.shareSlug,
		startDate: props.dateRange.start,
		endDate: props.dateRange.end,
	};

	const { data, loading, error } = useQuery(queries.getDailyClipListens, {
		variables,
	});
	const { getDailyClipListens = [] } = data || {};

	let values = [];

	getDailyClipListens.forEach((item) => {
		let value = values.find((x) => x.date == item.date);

		if (!value) {
			value = {
				...item,
				audio_count: 0,
				video_count: 0,
			};

			values.push(value);
		}

		if (item.is_video) {
			value.video_count += item.total_views;
		} else {
			value.audio_count += item.total_views;
		}

		value.total_count = value.video_count + value.audio_count;
	});

	return (
		<MetricLineChart
			values={values || []}
			loading={loading}
			showHelp={false}
			datasets={[
				{ dataKey: 'audio_count', color: '#895AFB', name: 'Listens' },
				{ dataKey: 'video_count', color: '#3EBB85', name: 'Watches' },
			]}
		/>
	);
};

export default ListensOverTime;
