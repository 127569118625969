import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const ThumbnailUploader = (props) => {
	const {
		overlayMsg,
		loading,
		className = '',
		fileContainerClassName = '',
		overlayClassName = '',
		thumbnailImgSrc = null,
		inputName = '',
		onUpload = () => {},
		overlay = () => {},
		disabled = false,
	} = props;

	// HOOKS
	const [isDragging, setIsDragging] = useState(false);
	// END HOOKS

	const onDragEnter = (e) => {
		setIsDragging(true);
	};

	const onDragLeave = (e) => {
		setIsDragging(false);
	};

	const onDragOver = (e) => {
		e.preventDefault();
	};

	const onFileChange = (e, f) => {
		const file = f || e.target.files[0];
		const reader = new FileReader();

		// TODO: reject really big files

		if (!file || !file.type.match(/image-*/)) {
			// TODO: handle error for wrong file type
			return;
		}

		const fileExt = file.name.split('.').pop();

		reader.onload = (e) => {
			if (onUpload) {
				onUpload(reader.result, fileExt);
			}
		};

		reader.readAsDataURL(file);
	};

	const onDrop = (e) => {
		e.preventDefault();
		setIsDragging(false);
		onFileChange(e, e.dataTransfer.files[0]);
	};

	const renderOverlay = () => {
		return overlay();
	};

	return (
		<div className={`thumbnail-uploader ${className}`}>
			<label
				className={`thumbnail-container ${fileContainerClassName} ${
					isDragging ? 'dragging' : ''
				}`}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDragOver={onDragOver}
				onDrop={onDrop}
			>
				{!thumbnailImgSrc && !loading && !disabled && (
					<div className={`thumbnail-edit-overlay ${overlayClassName}`}>
						<span className="overlay-msg label-btn-text">
							{overlayMsg || 'Upload Thumbnail'}
						</span>
					</div>
				)}
				{thumbnailImgSrc && !loading && (
					<img
						src={thumbnailImgSrc}
						className="thumbnail-image"
						alt="thumbnail-upload"
					/>
				)}
				{loading && (
					<Spinner animation="border" className="thumbnail-loading-spiner" />
				)}
				<input
					type="file"
					accept="image/*"
					onChange={onFileChange}
					name={inputName}
					className="thumbnail-upload-input"
					disabled={disabled}
				/>
			</label>
			{overlay && !disabled && renderOverlay()}
		</div>
	);
};

export default ThumbnailUploader;
