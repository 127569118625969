import React from 'react';
import { Facet } from '@elastic/react-search-ui';

import Checkbox from '../../checkbox';

import styles from './styles.scss';

const ToggleFilter = (props) => {
	const {
		field,
		fieldLabel,
		toggledOnValue,
		toggledOffValue,
		onChange = () => {},
	} = props;

	return (
		<div className={styles.toggleFilter}>
			<Facet
				field={field}
				label={fieldLabel}
				filterType="any"
				view={(foo) => {
					const { label, onRemove, onSelect, options } = foo;

					const toggleOn = () => {
						onRemove(toggledOffValue);
						onSelect(toggledOnValue);
						onChange(toggledOnValue);
					};

					const toggleOff = () => {
						onRemove(toggledOnValue);
						onSelect(toggledOffValue);
						onChange(toggledOffValue);
					};

					let toggleOnOption = options.filter(
						(option) => option.value === toggledOnValue
					)[0];

					let toggleOffOption = options.filter(
						(option) => option.value === toggledOffValue
					)[0];

					//If both options are false, select the toggleOffValue

					if (
						toggleOnOption &&
						!toggleOnOption.selected &&
						toggleOffOption &&
						!toggleOffOption.selected
					) {
						toggleOff();
					}

					const toggled =
						(toggleOnOption && toggleOnOption.selected) || false;

					return (
						<fieldset>
							<hr />
							<ul>
								{
									<li key={`toggle`}>
										<Checkbox
											id={`${field}-toggle`}
											label={fieldLabel}
											checked={toggled || false}
											onChange={() =>
												toggled
													? toggleOff()
													: toggleOn()
											}
										/>
									</li>
								}
							</ul>
						</fieldset>
					);
				}}
			/>
		</div>
	);
};

export default ToggleFilter;
