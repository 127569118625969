import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { Row, Col, Button as ReactButton } from 'react-bootstrap';
import { FaRegClock, FaUserFriends, FaEquals } from 'react-icons/fa';

import Button from 'components/shared/button';
import queries from 'services/graphql/queries';
import { NEW_EPISODE_STAGES } from 'components/constants';
import { HorizontalLine } from 'components/shared/loadingSkeletons/skeleton';

const TranscriptionCost = (props) => {
	const { accountId, episodeId, stageName, currentStage, onContinueClick } =
		props;

	// Queries
	const [
		getAccountInfo,
		{
			data: accountData,
			loading: accountLoading,
			called: accountCalled,
			startPolling,
			stopPolling,
		},
	] = useLazyQuery(queries.getAccountInfo, {
		variables: { accountId: accountId },
		fetchPolicy: 'no-cache',
		pollInterval: 2000,
	});
	const [
		getEpisodeInfo,
		{ data: episodeData, loading: episodeLoading, called: episodeCalled },
	] = useLazyQuery(queries.getEpisodeBasic, {
		variables: { episodeId: episodeId },
	});

	// Hooks
	const [isStageChanging, setIsStageChanging] = useState(false);

	useEffect(() => {
		if (currentStage === stageName) {
			setIsStageChanging(true);
		}
	}, [currentStage]);
	// End Hooks

	if (currentStage !== stageName) {
		if (stopPolling) {
			stopPolling();
		}
		return null;
	}

	if (isStageChanging) {
		getAccountInfo();
		getEpisodeInfo();
		setIsStageChanging(false);
	}

	if (accountLoading || episodeLoading) {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<h2 className="option-header">Premium Transcription</h2>

				<HorizontalLine
					style={{
						height: '1.5rem',
						width: '100%',
						margin: '30px 0 50px 0',
					}}
				/>
			</div>
		);
	}

	const { account } = accountData || {};
	const { basicEpisode } = episodeData || {};

	if (!account || !basicEpisode) {
		return null;
	}

	const cost = basicEpisode ? Math.ceil(basicEpisode.duration / 60) : null;

	return (
		<div>
			<h2 className="option-header">Premium Transcription</h2>

			<p className="credits-description">
				If there are insufficient minutes to fully transcribe the audio,
				you will need to purchase more before continuing. Once
				transcription has begun, it cannot be canceled.
			</p>

			<Row>
				<Col xs={{ span: 8, offset: 2 }}>
					<div className="credits-card credits--total-credits">
						<Row>
							<Col style={{ flex: 1 }}>
								<span className="credits-label">
									<FaRegClock className="credits-icon" />{' '}
									Available Minutes
								</span>
							</Col>
							<Col xs="auto">
								<span className="credits-number">
									{account.transcriptionCredits}
								</span>
							</Col>
						</Row>
					</div>
					<div className="credits-card credits--cost">
						<Row>
							<Col style={{ flex: 1 }}>
								<span className="credits-label">
									<FaUserFriends className="credits-icon" />{' '}
									Episode Minutes
								</span>
							</Col>
							<Col xs="auto">
								<span className="credits-number">{cost}</span>
							</Col>
						</Row>
					</div>
					<div className="credits-card credits--remaining">
						<Row>
							<Col style={{ flex: 1 }}>
								<span className="credits-label">
									<FaEquals className="credits-icon" />{' '}
									Remaining Minutes
								</span>
							</Col>
							<Col xs="auto">
								<span className="credits-number">
									{account.transcriptionCredits - cost}
								</span>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>

			<div className="options-footer">
				<Button
					onClick={() => {
						if (onContinueClick) {
							onContinueClick();
						}
					}}
					id="confirm-premium-transcription-order"
					className="options-next-btn"
					variant="contained"
					disabled={account.transcriptionCredits - cost < 0}
				>
					Confirm Order
				</Button>

				<ReactButton
					onClick={() => {
						if (onContinueClick) {
							onContinueClick(
								NEW_EPISODE_STAGES.PURCHASE_CREDITS
							);
						}
					}}
					className="options-add-credits-btn"
					variant="link"
				>
					ADD CREDITS
				</ReactButton>
			</div>
		</div>
	);
};

export default withRouter(TranscriptionCost);
