import React, { useState, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import {
	setSelection,
	setHasSelection,
	setIsEditingClip,
	setSelectedClip,
} from 'store/reducers/data/transcript';
import {
	showClipShareModal,
	setAudiogramStep,
} from 'store/reducers/ui/modals/clipShareModal';
import { showDeleteClipModal } from 'store/reducers/ui/modals/deleteClipModal';
import { setIsPlaying } from 'store/reducers/ui/episodes/studio';

// External components
import {
	Dropdown,
	DropdownButton,
	Badge,
	OverlayTrigger,
	Tooltip,
	Spinner,
	Container,
	Row,
	Col,
	Button
} from 'react-bootstrap';
import { FaVideo, FaEllipsisH, FaCircle, FaTimes } from 'react-icons/fa';

// Internal utils
import { formatDuration } from 'utils';
import { useQuery, useMutation } from '@apollo/client';
import queries from '../../../../../../services/graphql/queries';
import mutations from '../../../../../../services/graphql/mutations';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CLIP_STANDALONE_STATUS, CLIP_TYPES } from '../../../../../constants';

const ClipCard = (props) => {
	const {
		clip = {},
		canEdit,
		onShareClip = () => {},
		getClosestNode,
		transcriptReady,
		highlightSelection,
		onPauseClip,
		onClipHover,
		onClipHoverLeave,
		updateRegionClass,
		addedOffset,
		isSelected = false,
		linkedClip,
		linkedTranscript,
		setLinkedClip,
		/* Redux props start */
		hasSelection,
		isEditingClip,
		episodesUI,
		modals,
		showSuggestions,
		showHighlights,
		isPlaying,
		/* Redux props end */
	} = props;

	const { name, type, startTime, endTime, storageLink } = clip || {};

	const [isActive, setIsActive] = useState(false);
	const [nodes, setNodes] = useState({});
	const [cardIsPlaying, setCardIsPlaying] = useState(false);
	const [playPauseText, setPlayPauseText] = useState('Play');
	const [isStandaloneProcessing, setIsStandaloneProcessing] = useState(false);

	const { featureClipImprovements, featureClipSuggestionAccess } = useFlags();

	const {
		data: clipStatusData,
		loading: clipStatusLoading,
		error: clipStatusError,
		stopPolling: clipStatusStopPolling,
	} = useQuery(queries.getStandaloneClipStatus, {
		variables: { clipId: clip?.id },
		fetchPolicy: 'no-cache',
		pollInterval: 5000,
	});

	const [updateClip] = useMutation(mutations.updateClipById, {
		refetchQueries: ['ClipsInEpisode'],
	});

	useEffect(() => {
		if (!featureClipImprovements) {
			return;
		}
		if (!clipStatusLoading) {
			if (
				(clipStatusData &&
					clipStatusData.standaloneClipStatus.standaloneStorageLink ===
						CLIP_STANDALONE_STATUS.processing) ||
				clipStatusData.standaloneClipStatus.transcriptStorageLink ===
					CLIP_STANDALONE_STATUS.processing ||
				clipStatusData.standaloneClipStatus.VTTStorageLink ===
					CLIP_STANDALONE_STATUS.processing
			) {
				setIsStandaloneProcessing(true);
			} else {
				clipStatusStopPolling();
			}
		}
	}, [clipStatusLoading, clipStatusData]);

	useEffect(() => {
		if (transcriptReady) {
			setNodes({
				start: getClosestNode(startTime),
				end: getClosestNode(endTime),
			});
		}
	}, [transcriptReady]);

	useEffect(() => {
		if (linkedClip?.id !== clip.id && isPlaying) {
			setCardIsPlaying(false);
			setPlayPauseText('Play');
		}
		if (isPlaying && cardIsPlaying) {
			setPlayPauseText('Stop');
		} else {
			setPlayPauseText('Play');
			setCardIsPlaying(false);
		}
	}, [linkedClip, isPlaying]);

	const onMouseEnter = () => {
		setIsActive(true);
		if (transcriptReady && !hasSelection && !episodesUI.studio.showDrawer) {
			if (nodes && nodes.start && nodes.end) {
				let startIndex = parseInt(
					nodes.start.getAttribute('data-entity-index'),
					10
				);
				let endIndex = parseInt(
					nodes.end.getAttribute('data-entity-index'),
					10
				);
				if (startIndex && endIndex) highlightSelection(startIndex, endIndex, false, clip.type === CLIP_TYPES.suggested);
			}
			onClipHover(clip);
		}
	};

	const onMouseLeave = () => {
		setIsActive(false);
		if (transcriptReady && !hasSelection && !episodesUI.studio.showDrawer) {
			if (!linkedClip && !linkedTranscript) {
				document.querySelectorAll('.select-layer.selected').forEach((span) => {
					span.classList.remove('selected');
				});
				if (!showHighlights) {
					document.querySelectorAll('.select-layer.suggested').forEach((span) => {
						span.classList.remove('suggested');
					});
				}
			}
			if (isActive && !cardIsPlaying) {
				onClipHoverLeave(clip.id);
			}
		}
	};

	const onPlayClick = (clipId) => {
		if (cardIsPlaying) {
			onPauseClip();
			dispatch(setIsPlaying(false));
		} else {
			dispatch(setIsPlaying(true));
			setLinkedClip({
				id: clipId,
				startTime,
				endTime,
				isPlaying: true,
			});

		}
		setCardIsPlaying(!cardIsPlaying);
	};

	const onEditClick = (clip) => {
		dispatch(setIsEditingClip(true));
		dispatch(setHasSelection(true));
		dispatch(setSelectedClip(clip));
		dispatch(
			setSelection({
				startTime: Number(nodes.start.getAttribute('data-start')),
				endTime: Number(nodes.end.getAttribute('data-end')),
				startIndex: Number(nodes.start.getAttribute('data-entity-index')),
				endIndex: Number(nodes.end.getAttribute('data-entity-index')),
			})
		);
		updateRegionClass(clip.id, 'selected', true);
	};

	const onDelete = () => {
		dispatch(showDeleteClipModal(clip));
	};

	const onRemoveSuggestion = async () => {
		let clipData= {
			episodeId: clip.episodeId,
			type: 'rejected'
		}
		await updateClip({ variables: { clipId: clip.id, clip: clipData } });
	}

	const onAccept = async () => {
		let clipData = {
			episodeId: clip.episodeId,
			type: 'takeaway',
			status: 'active',
		};
		await updateClip({ variables: { clipId: clip.id, clip: clipData } });
	}

	const onShare = () => {
		if (!modals.clipShareModal.showModal) {
			dispatch(setSelectedClip(clip));
			dispatch(setAudiogramStep(1));
			dispatch(showClipShareModal());
		}
	};

	const hasAudiogram = storageLink;

	const clipDot = () => {
		if (featureClipImprovements) {
			if (isStandaloneProcessing) {
				return (
					<div className="clip-dot processing">
						<FaCircle />
						<span>Processing</span>
					</div>
				);
			} else if (
				clipStatusData.standaloneClipStatus.standaloneStorageLink ===
				CLIP_STANDALONE_STATUS.error
			) {
				return (
					<div className="clip-dot error">
						<FaCircle />
						<span>Error</span>
					</div>
				);
			}
		}

		if (featureClipSuggestionAccess && type === 'suggested') {
			return (
				<div className="clip-dot suggested">
					<svg
						width="17"
						height="18"
						viewBox="0 0 20 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.58969 3.18516L6.72469 3.25266C7.27031 3.53391 7.71469 3.97828 7.99594 4.52391L8.06344 4.65891C8.33344 5.19328 9.09844 5.19328 9.37406 4.65891L9.44156 4.52391C9.72281 3.97828 10.1672 3.53391 10.7128 3.25266L10.8478 3.18516C11.3822 2.91516 11.3822 2.15016 10.8478 1.87453L10.7128 1.80703C10.1672 1.52578 9.72281 1.08141 9.44156 0.535781L9.37406 0.400781C9.10406 -0.133594 8.33906 -0.133594 8.06344 0.400781L7.99594 0.535781C7.71469 1.08141 7.27031 1.52578 6.72469 1.80703L6.58969 1.87453C6.05531 2.14453 6.05531 2.90953 6.58969 3.18516Z"
							fill="#14A7D6"
						/>
						<path
							d="M7.65281 8.45578C8.20406 8.17453 8.20406 7.39266 7.65281 7.11141L6.83156 6.68953C6.10031 6.31266 5.50406 5.71641 5.12719 4.98516L4.70531 4.16391C4.42406 3.61266 3.64219 3.61266 3.36094 4.16391L2.93906 4.98516C2.56219 5.71641 1.96594 6.31266 1.23469 6.68953L0.413438 7.11141C-0.137812 7.39266 -0.137812 8.17453 0.413438 8.45578L1.23469 8.87766C1.96594 9.25453 2.56219 9.85078 2.93906 10.582L3.36094 11.4033C3.64219 11.9545 4.42406 11.9545 4.70531 11.4033L5.12719 10.582C5.50406 9.85078 6.10031 9.25453 6.83156 8.87766L7.65281 8.45578Z"
							fill="#14A7D6"
						/>
						<path
							d="M18.2053 11.8195L17.3728 11.392C16.3659 10.8802 15.5616 10.0758 15.0497 9.06891L14.6222 8.23641C14.2453 7.49391 13.4916 7.03266 12.6591 7.03266C11.8266 7.03266 11.0728 7.49391 10.6959 8.23641L10.2684 9.06891C9.75656 10.0758 8.95219 10.8802 7.94531 11.392L7.11281 11.8195C6.37031 12.1964 5.90906 12.9502 5.90906 13.7827C5.90906 14.6152 6.37031 15.3689 7.11281 15.7458L7.94531 16.1733C8.95219 16.6852 9.75656 17.4895 10.2684 18.4964L10.6959 19.3289C11.0728 20.0714 11.8266 20.5327 12.6591 20.5327C13.4916 20.5327 14.2453 20.0714 14.6222 19.3289L15.0497 18.4964C15.5616 17.4895 16.3659 16.6852 17.3728 16.1733L18.2053 15.7458C18.9478 15.3689 19.4091 14.6152 19.4091 13.7827C19.4091 12.9502 18.9478 12.1964 18.2053 11.8195ZM17.4403 14.2439L16.6078 14.6714C15.2803 15.3464 14.2228 16.4039 13.5478 17.7314L13.1203 18.5639C12.9909 18.817 12.7547 18.8452 12.6591 18.8452C12.5634 18.8452 12.3272 18.817 12.1978 18.5639L11.7703 17.7314C11.0953 16.4039 10.0378 15.3464 8.71031 14.6714L7.87781 14.2439C7.62469 14.1145 7.59656 13.8783 7.59656 13.7827C7.59656 13.687 7.62469 13.4508 7.87781 13.3214L8.71031 12.8939C10.0378 12.2189 11.0953 11.1614 11.7703 9.83391L12.1978 9.00141C12.3272 8.74828 12.5634 8.72016 12.6591 8.72016C12.7547 8.72016 12.9909 8.74828 13.1203 9.00141L13.5478 9.83391C14.2228 11.1614 15.2803 12.2189 16.6078 12.8939L17.4403 13.3214C17.6934 13.4508 17.7216 13.687 17.7216 13.7827C17.7216 13.8783 17.6934 14.1145 17.4403 14.2439Z"
							fill="#14A7D6"
						/>
					</svg>
					<span>
						Recommended
					</span>
				</div>
			);
		}

		if (type === 'promo') {
			return (
				<div className="clip-dot link">
					<FaCircle />
					<span>Link</span>
				</div>
			);
		} else {
			return (
				<div className="clip-dot takeaway">
					<FaCircle />
					<span>Takeaway</span>
				</div>
			);
		}
	};

	const renderClipClose = () => {
		if (clip.type === CLIP_TYPES.suggested) {
			return (
				<OverlayTrigger 
					placement='top'
					overlay={<Tooltip>Ignore</Tooltip>}
				>
					<Button
						className="clip--actions"
						variant="link"
						onClick={onRemoveSuggestion}
					>
						<FaTimes />
					</Button>
				</OverlayTrigger>
			);
		} else {
			return (
				<DropdownButton
					className="clip--actions"
					variant="link"
					title={<FaEllipsisH />}
				>
					{canEdit && (
						<Dropdown.Item onClick={onDelete}>Remove</Dropdown.Item>
					)}
				</DropdownButton>
			)
		}
	}

	return featureClipImprovements && clipStatusLoading ? (
		<Container
			style={{
				...addedOffset,
			}}
		>
			<Spinner className="spinner" animation="border" variant="info" />
		</Container>
	) : (
		<>
			<Container
				style={{
					...addedOffset,
				}}
				className={`clip-card ${
					isActive || episodesUI.studio.showDrawer || isEditingClip
						? 'active'
						: clip.type === CLIP_TYPES.suggested && !showSuggestions
						? 'invisible'
						: ''
				} 
				${clip.type === CLIP_TYPES.suggested ? 'suggested-clip' : ''}
			${episodesUI.studio.showDrawer ? 'sidebar-visible' : ''} 
			${isSelected ? 'selected' : ''}`}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{isStandaloneProcessing && (
					<div className="clip-processing">
						<Spinner className="spinner" animation="border" variant="info" />
						<div className="label">Processing</div>
					</div>
				)}
				<div
					className={`clip-contents ${
						isStandaloneProcessing ? 'processing' : ''
					}`}
				>
					<Row className="clip--header px-3 py-2">
						<Col className="p-0 clip--type d-flex justify-content-start align-items-center">
							<div className="d-flex">
								{clipDot()}
								{hasAudiogram && (
									<OverlayTrigger
										overlay={<Tooltip>Has an Audiogram</Tooltip>}
										placement="right"
									>
										<Badge variant="light" pill className="clip-has-audiogram">
											{storageLink === 'loading' ? (
												<Spinner animation="grow" size="sm" />
											) : (
												<FaVideo
													className="audiogram-done"
													onClick={onShareClip}
												/>
											)}
										</Badge>
									</OverlayTrigger>
								)}
							</div>
							{!hasAudiogram && (
								<div>
									<p className="clip--duration">
										{formatDuration(startTime)} - {formatDuration(endTime)}
									</p>
								</div>
							)}
						</Col>
						<Col xs={1}>{canEdit && renderClipClose()}</Col>
					</Row>
					{hasAudiogram && (
						<Row className="px-3">
							<Col className="py-2 px-0 clip--duration">
								{formatDuration(startTime)} - {formatDuration(endTime)}
							</Col>
						</Row>
					)}
					<Row className="clip--name px-3">
						<Col className="py-2 px-0">{name}</Col>
					</Row>
					{clip.type === CLIP_TYPES.suggested && clip.suggestionReason && (
						<Row className="clip--suggestion-reason pb-2">
							<Col>
								<p className="question">Why did we choose this clip?</p>
								<p>{clip.suggestionReason}</p>
							</Col>
						</Row>
					)}
					<Row className="py-2 clip--links">
						<Col>
							<p
								className={`clip-link ${
									isStandaloneProcessing ? 'disabled' : ''
								}`}
								onClick={() =>
									!isStandaloneProcessing ? onPlayClick(clip.id) : ''
								}
								disabled={isStandaloneProcessing}
							>
								{playPauseText}
							</p>
						</Col>
						<Col>
							<p
								className={`clip-link ${
									isStandaloneProcessing ? 'disabled' : ''
								}`}
								onClick={() =>
									!isStandaloneProcessing ? onEditClick(clip) : ''
								}
							>
								{clip.type === CLIP_TYPES.suggested ? 'Tweak' : 'Edit'}
							</p>
						</Col>
						<Col>
							{clip.type === CLIP_TYPES.suggested ? (
								<p
									className={`clip-link ${
										isStandaloneProcessing ? 'disabled' : ''
									}`}
									onClick={() => (!isStandaloneProcessing ? onAccept() : '')}
								>
									Accept
								</p>
							) : (
								<p
									className={`clip-link ${
										isStandaloneProcessing ? 'disabled' : ''
									}`}
									onClick={() => (!isStandaloneProcessing ? onShare() : '')}
								>
									Share
								</p>
							)}
						</Col>
					</Row>
				</div>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => ({
	hasSelection: state.transcript.hasSelection,
	isEditingClip: state.transcript.isEditingClip,
	episodesUI: state.ui.episodes,
	modals: state.ui.modals,
	showSuggestions: state.transcript.showSuggestions,
	showHighlights: state.transcript.showHighlights,
	isPlaying: state.ui.episodes.studio.isPlaying,
});

export default connect(mapStateToProps)(ClipCard);
