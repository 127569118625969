import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Nav } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const StrategyList = (props) => {
	const { accountId, metricPermission, themesPermission } = props;
	const { featureThemes, featureInsights, shareCenter } = useFlags();

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<Nav>
			<div className="nav-header" onClick={() => setIsExpanded(!isExpanded)}>
				Strategy
				{isExpanded ? <FaChevronDown /> : <FaChevronRight />}
			</div>
			{isExpanded && (
				<>
					{metricPermission && (
						<Nav.Item className="nav-link">
							<NavLink
								to={`/account/${accountId}/metrics`}
								activeClassName="nav-active"
							>
								<div className="nav-option">Account Metrics</div>
							</NavLink>
						</Nav.Item>
					)}

					{featureInsights && metricPermission && (
						<Nav.Item className="nav-link">
							<NavLink
								to={`/account/${accountId}/insights`}
								activeClassName="nav-active"
							>
								<div className="nav-option">Insights</div>
							</NavLink>
						</Nav.Item>
					)}

					{featureThemes && themesPermission.canView && (
						<Nav.Item className="nav-link">
							<NavLink
								to={`/account/${accountId}/themes`}
								activeClassName="nav-active"
							>
								<div className="nav-option">Themes</div>
							</NavLink>
						</Nav.Item>
					)}

					{shareCenter && (
						<Nav.Item className="nav-link">
							<NavLink
								to={`/account/${accountId}/shares`}
								activeClassName="nav-active"
							>
								<div className="nav-option">Share Center</div>
							</NavLink>
						</Nav.Item>
					)}
				</>
			)}
		</Nav>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions.accountRole,
});

export default withRouter(connect(mapStateToProps)(StrategyList));
