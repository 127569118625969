export const ACTIONS = {
	SET_EDIT_PLAYLIST: 'CASTED/SET_EDIT_PLAYLIST',
};

const initialState = {
	editPlaylist: false,
};

export const setEditPlaylist = (editPlaylist) => {
	return {
		type: ACTIONS.SET_EDIT_PLAYLIST,
		editPlaylist,
	};
};

const playlistReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SET_EDIT_PLAYLIST:
			return {
				...state,
				editPlaylist: action.editPlaylist,
			};
		default:
			return state;
	}
};

export default playlistReducer;
