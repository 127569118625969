import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { withRouter, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Spinner } from 'react-bootstrap';

import { parseQuery } from 'utils/';
import mutations from 'services/graphql/mutations';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';

const HubspotCallback = (props) => {
	const params = parseQuery(props.location.search);
	const { code } = params;

	const [isLoading, setIsLoading] = useState(true);

	const [saveHubspotOAuthToken] = useMutation(
		mutations.saveHubspotOAuthToken,
		{
			variables: { code },
			onCompleted: () => {
				trackEvent('add-integration', { integrationName: 'hubspot' });
				Toast.success('Integration Added!');
				setIsLoading(false);
			},
			onError: (err) => {
				Toast.error('Unable to add integration with HubSpot');
				Sentry.captureException(err);
			},
		}
	);

	useEffect(() => {
		saveHubspotOAuthToken();
	}, []);

	return (
		<div>
			{isLoading ? (
				<Spinner animation="grow" variant="info" />
			) : (
				<Redirect to="/" />
			)}
		</div>
	);
};

export default withRouter(HubspotCallback);
