import React from 'react';

import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { history, dispatch } from 'store/store';
import {
	setThemeContentView,
	THEME_CONTENT_VIEWS,
} from 'store/reducers/ui/themes';

const EpisodeThemes = (props) => {
	const { accountId, themes } = props;

	const linkOnClick = () => {
		dispatch(setThemeContentView(THEME_CONTENT_VIEWS.METRICS));
	};

	return (
		<div className="episode-theme-badges">
			{themes.map((theme, i) => (
				<Link
					key={i}
					to={{
						pathname: `/account/${accountId}/themes/${theme.id}`,
						state: { from: history.location.pathname },
					}}
					onClick={() => linkOnClick()}
				>
					<Badge
						className="episode-theme-badge"
						style={{ backgroundColor: theme.color }}
					>
						{theme.name}
					</Badge>
				</Link>
			))}
		</div>
	);
};

export default EpisodeThemes;
