import React, { useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { ToastContainer } from 'react-toastify';

import Toast from 'services/toast';
import Button from 'components/shared/button';
import TextInput from 'components/shared/textInput';
import PasswordStrength from 'components/shared/passwordStrength';
import { getAuthSingleton } from 'services/auth';
import { parseQuery } from 'utils/';

import passwordRating from 'zxcvbn';

const isPasswordValid = (pw) => {
	const pwRating = passwordRating(pw);
	return pw.length >= 10 && pwRating.score >= 3 && !pwRating.feedback.warning;
};

const SetPassword = (props) => {
	// HOOKS
	const [newPassword, setNewPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	// END HOOKS

	const authSvc = getAuthSingleton();
	const params = parseQuery(props.location.search);
	const { inviteCode } = params;

	let messages = [''];

	// if there is no code in the URL then just take them to root
	if (!inviteCode) {
		return <Redirect to="/" />;
	}

	const canSet =
		isPasswordValid(newPassword) && newPassword === passwordConfirmation;

	if (newPassword !== passwordConfirmation && passwordConfirmation.length) {
		messages.push('Passwords must match.');
	}

	return (
		<div className="password-set">
			<div className="password-set-box">
				<h2 className="password-set-header">Set Password</h2>
				<p className="pw-minimum-length-msg ">
					Passwords must match and be at least 10 characters
				</p>
				<label className="password-set-label">New Password</label>
				<TextInput
					name="new-password"
					label="********"
					value={newPassword}
					type="password"
					onChange={(e) => setNewPassword(e.target.value)}
					inputClassName="password-set-form-input"
					rootStyles={{ margin: '0 0 10px 0' }}
				/>
				<PasswordStrength password={newPassword} messages={messages} />
				<label className="password-set-label">Confirm Password</label>
				<TextInput
					name="new-password-confirmation"
					label="********"
					value={passwordConfirmation}
					type="password"
					onChange={(e) => setPasswordConfirmation(e.target.value)}
					inputClassName="password-set-form-input"
					rootStyles={{ margin: 0 }}
				/>
				<Button
					name="password-set-button"
					onClick={async () => {
						try {
							await authSvc.setPassword(inviteCode, newPassword);
							Toast.success('Password set!');
							props.history.push('/login');
						} catch (e) {
							console.error(e);
							Toast.error(
								'Oops, something went wrong! Unable to set password.'
							);
							Sentry.captureException(e);
						}
					}}
					className="password-set-btn"
					variant="success"
					title="Set Password"
					disabled={!canSet}
				>
					Set Password
				</Button>
				<Button
					name="password-set-goto-login-btn"
					onClick={() => props.history.push('/login')}
					className="password-set-goto-login-btn"
					variant="flat"
					title="Back to login"
				>
					Back to Login
				</Button>
			</div>
			<ToastContainer autoClose={false} />
		</div>
	);
};

export default withRouter(SetPassword);
