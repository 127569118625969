import React from 'react';
import { NavLink } from 'react-router-dom';

import podcat from 'assets/images/podcat-playing.svg';

/**
 * Returns a HTMLElement for use as card displaying show thumbnail, title and type for use in ALLContent page
 * @param {*} props
 * @param { String } props.accountId - Account ID of user
 * @param { Podcast } props.show - Show ContentCard is being rendered for.
 * @returns HTMLElement
 */
const ContentCard = (props) => {
	const { id, name, icon = false, link, thumbnail } = props;

	return (
		<div className="contentCard-container">
			<NavLink key={id} to={link} title={name}>
				<div className="contentCard">
					<img
						src={thumbnail || podcat}
						alt="Content Thumbnail"
						className={
							thumbnail ? 'contentCard-thumbnail' : 'contentCard-no-thumbnail'
						}
					/>
					{icon && icon}
					<div className="contentCard-title">{name}</div>
				</div>
			</NavLink>
		</div>
	);
};

export default ContentCard;
