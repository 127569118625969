import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

// TODO: Replace the following imports with an import to ./reducers/data
// Waiting to do update until cooldown since it'll effect ~40 files.
// Data Reducers
import auth from 'store/reducers/data/auth';
import accounts from 'store/reducers/data/accounts';
import episode from 'store/reducers/data/episode';
import transcript from 'store/reducers/data/transcript';
import studio from 'store/reducers/data/studio';
import themeData from 'store/reducers/data/theme';
import playlistData from 'store/reducers/data/playlist';
import promotionData from 'store/reducers/data/promotion';
import metricsData from 'store/reducers/data/metrics';

// UI Reducers
import ui from './reducers/ui';

const { routerReducer } = createReduxHistoryContext({
	history: createBrowserHistory(),
});

export default () =>
	combineReducers({
		router: routerReducer,
		// Data Reducers
		auth,
		accounts,
		episode,
		studio,
		transcript,
		themeData,
		playlistData,
		promotionData,
		metricsData,
		// UI Reducers
		ui,
	});
