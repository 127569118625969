import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import { Button, Spinner } from 'react-bootstrap';
import ClipEngagementTracker from './clipEngagementTracker';
import CompareClipDropdown from './compareClipDropdown';
import { trackEvent } from 'services/vitally';

const ClipEngagements = (props) => {
	const {
		accountId,
		itemId,
		startDate,
		endDate,
		duration,
		selectedOption,
		selectedClip,
		setSelectedClip,
	} = props;

	const [showDropdown, setShowDropdown] = useState(false);

	const { data, loading } = useQuery(queries.clipsByEpisodeId, {
		variables: { episodeId: itemId },
	});
	const { clipsInEpisode: clips = [] } = data || {};

	const onClipSelect = (clip) => {
		setShowDropdown(false);
		setSelectedClip(clip);
		if(clip.episodeId && clip.name && clip.id){
			trackEvent('compare-clip-engagement', {
				itemId: clip.episodeId,
				name: clip.episode.name,
				clipId: clip.id,
				clipName: clip.name
			});
		}
	};

	if (loading) {
		return (
			<div className="tracker-loading">
				<Spinner animation="border" variant="info" />
			</div>
		);
	}

	if (clips.length === 0) {
		return <></>;
	}

	return (
		<div className="clip-engagement-container">
			<ClipEngagementTracker
				accountId={accountId}
				itemId={itemId}
				startDate={startDate}
				endDate={endDate}
				duration={duration}
				selectedClip={selectedClip}
				selectedOption={selectedOption}
			/>
			{!showDropdown && (
				<Button
					className="compare-button"
					style={{
						width: Object.keys(selectedClip).length === 0 ? '200px' : '275px',
					}}
					variant="flat"
					title="compare"
					onClick={() => setShowDropdown(!showDropdown)}
				>
					{Object.keys(selectedClip).length === 0
						? 'Compare Clip'
						: 'Compare a different clip'}
				</Button>
			)}
			{showDropdown && (
				<>
					<h4>Select a clip to compare</h4>
					<CompareClipDropdown clips={clips} setSelectedClip={onClipSelect} />
				</>
			)}
		</div>
	);
};

export default ClipEngagements;
