import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { trackEvent } from 'services/vitally';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import {
	setSelection,
	setHasSelection,
	setIsCreatingClip,
	setCurrentTime,
	setIsEditingClip,
} from 'store/reducers/data/transcript';
import {
	showDrawer,
	hideDrawer,
	setDrawerTab,
	DRAWER_TABS,
	setIsPlaying,
} from 'store/reducers/ui/episodes/studio';
import { hidePremiumTranscriptionModal } from 'store/reducers/ui/modals/premiumTranscriptionModal';

// LaunchDarkly
import { useFlags } from 'launchdarkly-react-client-sdk';

// Apollo/GraphQL
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

// Internal constants
import { EPISODE_UPLOAD_TYPES, CLIP_TYPES } from 'components/constants';
import aiIcon from 'assets/icons/ai-icon.svg';

// External components
import { Container, Row, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaCut, FaPaintBrush } from 'react-icons/fa';

// Internal componrnts
import Transcript from './transcript';
import TranscriptNav from './transcriptNav';
import TranscriptionWizard from 'components/shared/transcriptionOptions/transcriptionWizard';
import Waveform from './waveform';
import WaveformTools from './waveform/waveformTools';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const EpisodeStudio = (props) => {
	// Props
	const {
		accountId,
		podcast,
		episode,
		permissions,
		userId,
		selectedAccount,
		linkedClip = null,
		linkedTranscript = null,
		setLinkedClip,
		setLinkedTranscript,
		/* Redux props start */
		selection,
		hasSelection,
		selectedClip,
		currentTime,
		episodesUI,
		episodeId,
		modals,
		currentTranscript,
		/* Redux props end */
	} = props;

	// Episode values
	const {
		storageLink,
		waveformLink,
		status: episodeStatus,
		podcastId,
		showTranscript,
		hostedUrl,
		audioLink,
		videoLink,
		uploadType,
		humanTransOrderNumber,
	} = episode || {};

	const { showDrawer, drawerTab } = episodesUI.studio;

	const { featureThemes, featureClipSuggestionAccess } = useFlags();

	const transcriptPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.TRANSCRIPTS, podcastId)
				.rolePermission
		: {};
	const clipsPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.CLIPS, podcastId)
				.rolePermission
		: {};
	const takeawaysPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.TAKEAWAYS, podcastId)
				.rolePermission
		: {};

	const {
		data: clipData,
		loading: clipLoading,
		error: clipError,
		startPolling,
		stopPolling,
		refetch,
	} = useQuery(queries.clipsByEpisodeId, { variables: { episodeId } });
	const { clipsInEpisode: clips = [] } = clipData || {};

	// REF
	const waveSurfer = useRef();
	const editorRef = useRef(null);

	// Router
	const history = useHistory();

	/** State **/
	// Components Ready
	const [isTranscriptReady, setIsTranscriptReady] = useState(false);
	const [isWaveformReady, setIsWaveformReady] = useState(false);
	const [allComponentsAreReady, setAllComponentsAreReady] = useState(false);
	const [localClips, setLocalClips] = useState([]);
	const [localGeneratedClips, setLocalGeneratedClips] = useState([]);
	const [localSuggestedClips, setLocalSuggestedClips] = useState([]);
	const [disableExport, setDisableExport] = useState(true);
	const [canSaveTranscript, setCanSaveTranscript] = useState(false);
	const [isEditingTranscript, setIsEditingTranscript] = useState(false);
	const [hasSuggestions, setHasSuggestions] = useState(true);
	const [newRecsViewed, setNewRecsViewed] = useState(false);
	const [suggestionsTabActive, setSuggestionsTabActive] = useState(false);

	useEffect(() => {
		setHasSuggestions(clips.some((clip) => clip.type === CLIP_TYPES.suggested));
		let clipsArray = [];
		let generatedClipsArray = [];
		let suggestedClipsArray = [];
		clips.forEach((clip) => {
			let region = findRegion(clip.id);
			if (region) {
				region.update({
					start: clip.startTime,
					end: clip.endTime,
					id: clip.id,
					attributes: {
						class: 'hide',
					},
				});
			} else {
				waveSurfer.current.addRegion({
					start: clip.startTime,
					end: clip.endTime,
					id: clip.id,
					attributes: {
						class: 'hide',
					},
				});
			}

			if (clip.type !== CLIP_TYPES.suggested && clip.type !== CLIP_TYPES.rejected) {
				clipsArray.push(clip);
			}

			if (clip.type === CLIP_TYPES.suggested) {
				generatedClipsArray.push(clip);
				suggestedClipsArray.push(clip);
			}

			if (clip.type === CLIP_TYPES.rejected) {
				generatedClipsArray.push(clip);
			}

			setLocalClips(clipsArray);
			setLocalGeneratedClips(generatedClipsArray);
			setLocalSuggestedClips(suggestedClipsArray);
		});
	}, [clips]);

	useEffect(() => {
		if (!suggestionsTabActive) onRegionRemoved();
	}, [suggestionsTabActive]);

	// Reset currentTime on studio load
	useEffect(() => {
		if (currentTime !== 0) {
			dispatch(setCurrentTime(0));
		}
	}, []);

	// Active Cursor and Region States
	const [currentRegion, setCurrentRegion] = useState({});

	// Waveform Ref
	const [waveformEl, setWaveformEl] = useState(null);
	const waveformRefCb = useCallback((ref) => {
		ref && setWaveformEl(ref);
	}, []);
	const [duration, setDuration] = useState(0);

	/** Region Event handlers start **/
	const onRegionCreated = (region) => {
		if (region.start) waveSurfer.current.seekTo(region.start / duration);

		if (!hasSelection && region.id === 'newRegion') {
			dispatch(setIsCreatingClip(true));
		}
	};

	const onRegionUpdated = (region) => {};

	const onRegionUpdateEnd = (region) => {
		if (!hasSelection) dispatch(setHasSelection(true));
		if (
			selection.startTime != region.start ||
			selection.endTime != region.end
		) {
			dispatch(
				setSelection({
					startTime: region.start,
					endTime: region.end,
					startIndex: null,
					endIndex: null,
				})
			);

			if (region.start) waveSurfer.current.seekTo(region.start / duration);
		}
	};

	const onRegionRemoved = (region) => {
		dispatch(setHasSelection(false));
		dispatch(
			setSelection({
				startTime: null,
				endTime: null,
				startIndex: null,
				endIndex: null,
			})
		);
		dispatch(setIsCreatingClip(false));
	};

	const linkedSeekTo = (seekToTime) => {
		waveSurfer.current.seekTo(
			parseFloat(seekToTime) / waveSurfer.current.getDuration()
		);
	};

	/** Waveform Event Handlers **/
	const onSeek = (e) => {
		dispatch(setCurrentTime(waveSurfer.current.getCurrentTime()));
	};

	// Only update currentTime every 0.1 seconds. Transcripts are currently
	// tracking words to every tenth of a second. Updating too frequently
	// will slow text highlight cursor down too much.
	const onAudioProcess = (e) => {
		const newCurrentTime = waveSurfer.current.getCurrentTime();
		const timeDiff = newCurrentTime - currentTime;

		if (timeDiff >= 0.1) {
			dispatch(setCurrentTime(waveSurfer.current.getCurrentTime()));
		}
	};

	const toggleSidebar = () => {
		if (showDrawer) {
			dispatch(hideDrawer());
		} else {
			dispatch(showDrawer());
		}
	};

	const findRegion = (id) => {
		let region = null;
		if (waveSurfer.current.regions.list[id]) {
			region = waveSurfer.current.regions.list[id];
		}
		return region;
	};

	const updateRegionClass = (id, className, drag, suggested) => {
		const region = findRegion(id);
		if (suggested) className += ' suggested';
		region &&
			region.update({
				attributes: {
					class: className,
				},
				drag: drag,
			});
	};

	const onClipHover = (clip) => {
		updateRegionClass(clip.id, 'hovering', false, clip.type === CLIP_TYPES.suggested);
	};

	const onClipHoverLeave = (id) => {
		updateRegionClass(id, 'hide', false);
		if (linkedClip && !episodesUI.studio.isPlaying) {
			setLinkedClip(null);
		}
		if (linkedTranscript) {
			setLinkedTranscript(null);
		}
	};

	const showAllClipsOnWaveform = (clips) => {
		clips.forEach((clip) => {
			updateRegionClass(clip.id, 'hovering', false, clip.type === CLIP_TYPES.suggested);
		});
	};

	const hideAllClipsOnWaveform = () => {
		clips.forEach((clip) => {
			updateRegionClass(clip.id, 'hide', false);
		});
	};

	useEffect(() => {
		if (waveSurfer.current) {
			if (hasSelection) {
				waveSurfer.current.disableDragSelection();
			} else {
				waveSurfer.current.enableDragSelection({});
			}
		}
	}, [hasSelection]);

	useEffect(() => {
		let region = findRegion(selectedClip.id) || findRegion('newRegion');
		if (region) {
			if (region.start != selection.startTime)
				region.update({ start: selection.startTime });
			if (region.end != selection.endTime)
				region.update({ end: selection.endTime });
		}
		if (!region && selection.startTime && selection.endTime) {
			waveSurfer.current.addRegion({
				start: selection.startTime,
				end: selection.endTime,
				id: 'newRegion',
			});
			if (!hasSelection) {
				dispatch(setHasSelection(true));
			}
		}
		refetch();
	}, [selection]);

	useEffect(() => {
		if (selection.startTime)
			waveSurfer.current.seekTo(selection.startTime / duration);
	}, [selection.startTime]);

	// Determine when to play linkedClip. Search result clips don't auto-play.
	useEffect(() => {
		if (isTranscriptReady && isWaveformReady && linkedClip) {
			if (linkedClip.isPlaying) {
				const region = findRegion(linkedClip.id);
				region.play();
			} else {
				waveSurfer.current.pause();
			}
		}
	}, [linkedClip, isTranscriptReady, isWaveformReady]);

	/** On Components Ready **/
	const onWaveformReady = () => {
		if (waveSurfer.current && waveSurfer.current.getDuration)
			setDuration(waveSurfer.current.getDuration());
		setIsWaveformReady(true);

		if (linkedClip) {
			linkedSeekTo(linkedClip.startTime);
		}
	};

	const onZoom = (zoomLevel) => {
		waveSurfer.current.zoom(zoomLevel);
	};

	useEffect(() => {
		if (isTranscriptReady && isWaveformReady) setAllComponentsAreReady(true);
	}, [isTranscriptReady, isWaveformReady]);

	/* --- Transcript Editing Functions --- */
	const onConfirmChanges = async () => {
		setIsEditingTranscript(false);
		setCanSaveTranscript(true);
	};

	const onCancelChanges = () => {
		setIsEditingTranscript(false);
		editorRef.current.setEditorContentState(currentTranscript);
		// readyEditor(editorRef.current);
	};

	return (
		<div className="episode-studio">
			<Container className="studio--content" fluid>
				<div className="studio-content--card studio--transcript-card flex-column">
					<div className="row-premium-transcript">
						<TranscriptNav
							humanTransOrderNumber={humanTransOrderNumber}
							showTranscript={showTranscript}
							disableExport={disableExport}
							editorRef={editorRef}
							episodeName={episode.name}
							onSave={onConfirmChanges}
							onCancel={() => onCancelChanges()}
							isEditing={isEditingTranscript}
							setIsEditing={(editing) => setIsEditingTranscript(editing)}
							hasSuggestions={hasSuggestions}
							transcriptPermission={transcriptPermission}
							takeawaysPermission={takeawaysPermission}
							refreshClips={() => refetch()}
							clipLoading={clipLoading}
							setNewRecsViewed={setNewRecsViewed}
						/>
					</div>
					<Row
						className={`row-transcript-container ${
							transcriptPermission.canView ? '' : 'hidden'
						}`}
					>
						<Col
							className={`h-100 ${showDrawer ? 'show-drawer' : ''}`}
							xs={showDrawer ? 9 : 12}
						>
							<Transcript
								accountId={accountId}
								currentTime={currentTime}
								onReady={() => {
									setIsTranscriptReady(true);
								}}
								showTranscript={showTranscript}
								episodeStatus={episodeStatus}
								podcastId={podcastId}
								canEdit={transcriptPermission.canEdit}
								userId={userId}
								podcast={podcast}
								episode={episode}
								clips={localClips}
								suggestedClips={localSuggestedClips}
								generatedClips={localGeneratedClips}
								clipsPermission={clipsPermission}
								takeawaysPermission={takeawaysPermission}
								onPlayClip={(clipId) => {
									const region = findRegion(clipId);
									region.play();
								}}
								onPauseClip={() => {
									waveSurfer.current.pause();
									dispatch(setIsPlaying(false));
								}}
								onClipHover={onClipHover}
								onClipHoverLeave={onClipHoverLeave}
								showAllClipsOnWaveform={showAllClipsOnWaveform}
								hideAllClipsOnWaveform={hideAllClipsOnWaveform}
								updateRegionClass={updateRegionClass}
								findRegion={findRegion}
								linkedClip={linkedClip}
								setLinkedClip={setLinkedClip}
								linkedTranscript={linkedTranscript}
								setLinkedTranscript={setLinkedTranscript}
								isWaveformReady={isWaveformReady}
								linkedSeekTo={linkedSeekTo}
								editorRef={editorRef}
								setDisableExport={setDisableExport}
								canSave={canSaveTranscript}
								setCanSave={() => setCanSaveTranscript()}
								isEditing={isEditingTranscript}
							/>
							<Modal
								show={modals.premiumTranscriptionModal.showModal}
								onHide={() => {
									dispatch(hidePremiumTranscriptionModal());
								}}
								className="show-transcript-modal"
								centered
							>
								<TranscriptionWizard
									episodeId={episodeId}
									accountId={accountId}
									userId={userId}
									onCancelClick={() => {
										dispatch(hidePremiumTranscriptionModal());
									}}
									onSaveClick={() => {
										dispatch(hidePremiumTranscriptionModal());
										trackEvent('premium-transcribe', {
											itemName: episode.name,
											collectionName: podcast.name,
										});
										history.push(
											`/account/${accountId}/shows/${podcastId}/episodes`
										);
									}}
								/>
							</Modal>
						</Col>
					</Row>
				</div>
				<div className="drawer-tabs">
					<OverlayTrigger
						placement='left'
						overlay={
							<Tooltip id="generate-all-tooltip">
								All Clips
							</Tooltip>
						}
					>
						<div
							className={`
								drawer-tab
								${showDrawer && drawerTab === DRAWER_TABS.CLIPS ? 'active' : ''}
							`}
							onClick={() => {
								dispatch(setDrawerTab(DRAWER_TABS.CLIPS));
								dispatch(setIsEditingClip(false));
								setSuggestionsTabActive(false);
							}}
						>
							<FaCut size={20} />
						</div>
					</OverlayTrigger>
					{featureClipSuggestionAccess && (
						<OverlayTrigger
							placement='left'
							overlay={
								<Tooltip id="generate-all-tooltip">
									Recommended Clips
								</Tooltip>
							}
						>
							<div
								className={`
									drawer-tab
									${showDrawer && drawerTab === DRAWER_TABS.CLIP_SUGGESTIONS ? 'active' : ''}
								`}
								onClick={() => {
									if (!isWaveformReady) return;
									if (drawerTab === DRAWER_TABS.CLIP_SUGGESTIONS) {
										dispatch(setIsEditingClip(false));
									}
									setSuggestionsTabActive(!suggestionsTabActive);
									dispatch(setDrawerTab(DRAWER_TABS.CLIP_SUGGESTIONS));
									setNewRecsViewed(false);
								}}
							>
								<img src={aiIcon} className={`icon ${newRecsViewed && 'pulse'}`} />
							</div>
						</OverlayTrigger>
					)}
					{featureThemes && (
						<OverlayTrigger
							placement='left'
							overlay={
								<Tooltip id="generate-all-tooltip">
									Theme Content
								</Tooltip>
							}
						>
							<div
								className={`
										drawer-tab
										${showDrawer && drawerTab === DRAWER_TABS.THEMES ? 'active' : ''}
									`}
								onClick={() => {
									dispatch(setDrawerTab(DRAWER_TABS.THEMES));
									dispatch(setIsEditingClip(false));
									setSuggestionsTabActive(false);
								}}
							>
								<FaPaintBrush size={20} />
							</div>
						</OverlayTrigger>
					)}
				</div>
			</Container>

			<Container className="studio--waveform" fluid>
				<Row>
					<Waveform
						waveSurfer={waveSurfer}
						id={`waveform-${episodeId}`}
						ref={waveformRefCb}
						className="new-clip-waveform"
						audioFile={storageLink}
						videoFile={
							selectedAccount.enableVideo &&
							videoLink &&
							uploadType === EPISODE_UPLOAD_TYPES.VIDEO
								? storageLink
								: null
						}
						waveformFile={waveformLink}
						height={90}
						cursor
						cursorShowTime
						useRegions
						onRegionCreated={onRegionCreated}
						onRegionUpdated={onRegionUpdated}
						onRegionUpdateEnd={onRegionUpdateEnd}
						onRegionRemoved={onRegionRemoved}
						regionsOpts={{
							loop: true,
						}}
						onSeek={onSeek}
						onAudioprocess={onAudioProcess}
						onReady={onWaveformReady}
					/>
					<div>
						<WaveformTools
							initZoom={0}
							onZoom={onZoom}
							region={currentRegion}
						/>
					</div>
				</Row>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	modals: state.ui.modals,
	selection: state.transcript.selection,
	hasSelection: state.transcript.hasSelection,
	selectedClip: state.transcript.selectedClip,
	currentTime: state.transcript.currentTime,
	episodesUI: state.ui.episodes,
	episodeId: state.episode.episodeId,
	permissions: state.auth.permissions,
	currentTranscript: state.transcript.currentTranscript,
});

export default connect(mapStateToProps)(EpisodeStudio);
