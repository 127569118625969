import React, { useState, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { Overlay, Popover } from 'react-bootstrap';

const ColorPicker = ({ color = '', onColorChange = () => {} }) => {
	const [show, setShow] = useState(false);
	const target = useRef(null);

	return (
		<>
			<div
				className="color-picker-input"
				style={{ backgroundColor: `${color}` }}
				ref={target}
				onClick={() => setShow(!show)}
			>
				{!color ? <div id="color-picker-no-color" /> : ''}
			</div>

			<Overlay
				target={target.current}
				show={show}
				placement="bottom"
				rootClose
				onHide={() => setShow(!show)}
			>
				{({ placement, show: _show, ...props }) => (
					<Popover {...props}>
						<ChromePicker
							color={color}
							onChangeComplete={(_color) => onColorChange(_color.hex)}
						/>
					</Popover>
				)}
			</Overlay>
		</>
	);
};

export default ColorPicker;
