import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import NewShowManualPermissions from './newShowWithPermissions/newShowManualStep';
import OldNewShowManualStep from './newShowNoPermissions/newShowManualStep';

const NewShowManualStep = (props) => {
	// FLAG HOOKS
	const { featureCollectionLevelPermissions } = useFlags();
	// END HOOKS

	const renderNewShowManualStep = () => {
		if (featureCollectionLevelPermissions) {
			return <NewShowManualPermissions {...props} />;
		}

		return <OldNewShowManualStep {...props} />;
	};

	return renderNewShowManualStep();
};

export default NewShowManualStep;
