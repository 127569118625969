import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client/react/hooks';
import { connect } from 'react-redux';
import { addDays, format, startOfDay } from 'date-fns';
import {
	Container,
	Row,
	Col,
	Dropdown,
	DropdownButton,
	Form,
} from 'react-bootstrap';

import { useFlags } from 'launchdarkly-react-client-sdk';

import queries from 'services/graphql/queries';
import { ITEM_TYPES, METRICS_CONTEXTS } from 'components/constants';
import { getSelectedAccount } from 'store/selectors/accounts';
import MetricDateRangePicker from 'components/shared/metricDateRange';
import ExportMetrics from 'components/shared/metricDownloadCsv';
import MetricDownloadModal from 'components/shared/metricDownloadModal';
import { setPromotionMetricsDate } from 'store/reducers/data/metrics';
import * as MetricsCards from './cards';

import { SegmentsTableWithRouter } from 'components/shared/insights';
import { AudienceTableWithRouter } from 'components/shared/insights';
import { dispatch } from 'store/store';
import moment from 'moment';

const MetricsScreen = (props) => {
	const {
		match,
		selectedAccount,
		metricsContext,
		isHosted,
		contextIdVariable = {},
		contextQueries = {},
		metricsTitle = '',
		metricsHeader = <></>,
		duration = null,
		promotionMetricsDate,
		history,
	} = props;
	const { params } = match;
	const { accountId: rawAccountId } = params;

	const accountId = parseInt(rawAccountId, 10);
	const today = startOfDay(new Date());

	const [dateRange, setDateRange] = useState({
		start: format(addDays(today, -8), 'yyyy-MM-dd'),
		end: format(addDays(today, -1), 'yyyy-MM-dd'),
	});
	const [comparisonDateRange, setComparisonDateRange] = useState({
		start: format(addDays(today, -16), 'yyyy-MM-dd'),
		end: format(addDays(today, -9), 'yyyy-MM-dd'),
	});
	const [isExportModalOpen, setIsExportModalOpen] = useState(false);
	const [integrations, setIntegrations] = useState([]);
	const [showPercents, setShowPercents] = useState(false);

	const { featureInsights, featureHeatmap } = useFlags();
	// Determine if account has any shows with an integration to Hubspot, Marketo, or Pardot

	const { data: integrationData, loading } = useQuery(
		contextQueries.integrations,
		{
			variables: contextIdVariable,
			onCompleted: (data) => {
				setIntegrations(
					data[contextQueries.integrations?.definitions[0]?.name?.value]
				);
			},
		}
	);

	const variables = {
		accountId: selectedAccount.id,
		startDate: dateRange.start,
		endDate: dateRange.end,
	};

	if (metricsContext === METRICS_CONTEXTS.collection) {
		variables.collectionId = contextIdVariable.podcastId;
	}

	if (metricsContext === METRICS_CONTEXTS.item) {
		variables.episodeId = contextIdVariable.episodeId;
		variables.podcastId = contextIdVariable.podcastId;
	}

	const [getSegmentsExportData] = useLazyQuery(queries.exportSegmentsMetrics, {
		variables,
		fetchPolicy: 'no-cache',
	});

	const [getAudienceExportData] = useLazyQuery(queries.exportAudienceMetrics, {
		variables,
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		// check history to see if it is a new page
		// if it is use the promotion metrics date
		// if not, use default
		if (history.length === 1 && promotionMetricsDate.startDate) {
			setDateRange({
				start: moment(promotionMetricsDate.startDate).format('yyyy-MM-DD'),
				end: moment(promotionMetricsDate.endDate).format('yyyy-MM-DD'),
			});
		}
		dispatch(setPromotionMetricsDate({}));
	}, []);

	const showContactMetrics = integrations.find(
		(x) =>
			['hubspot', 'marketo', 'pardot'].includes(x.integrationName) &&
			x.status === 1
	);

	const exportMetricsQuery =
		metricsContext === METRICS_CONTEXTS.account
			? queries.exportAccountMetrics
			: queries.exportShowMetrics;

	const [exportMetrics] = useLazyQuery(exportMetricsQuery, {
		variables: { ...contextIdVariable, accountId: accountId },
	});

	const handleDateChange = (newRange) => {
		setDateRange({
			start: newRange.start,
			end: newRange.end,
		});

		setComparisonDateRange({
			start: newRange.comparableStart,
			end: newRange.comparableEnd,
		});
	};

	const renderShareHeader = () => {
		if (metricsHeader) {
			return metricsHeader;
		}
	};

	const handleSegmentExport = () => {
		setIsExportModalOpen(true);
		getSegmentsExportData();
	};

	const handleAudienceExport = () => {
		setIsExportModalOpen(true);
		getAudienceExportData();
	};

	const renderMetricsExport = () => {
		if (metricsContext === METRICS_CONTEXTS.item) {
			return (
				<div className="metrics-title">
					{metricsTitle ? metricsTitle : <div></div>}
					<ExportMetrics
						accountId={accountId}
						podcastId={params.showId || contextIdVariable.podcastId}
						episodeId={params.episodeId || contextIdVariable.episodeId}
						shareSlug={contextIdVariable.shareSlug}
						dateRange={dateRange}
						getSegmentsExportData={handleSegmentExport}
						getAudienceExportData={handleAudienceExport}
						context={metricsContext}
						setIsExportModalOpen={setIsExportModalOpen}
						contextIdVariable={contextIdVariable}
					/>
				</div>
			);
		}

		if (
			metricsContext === METRICS_CONTEXTS.account ||
			metricsContext === METRICS_CONTEXTS.collection
		) {
			const singleExportType =
				metricsContext === METRICS_CONTEXTS.account
					? ITEM_TYPES.ACCOUNT
					: ITEM_TYPES.PODCAST;
			return (
				<div className="metrics-title">
					{metricsTitle ? metricsTitle : <div></div>}
					<DropdownButton
						title="DOWNLOAD CSV FILE"
						className="export-menu mb-4"
						variant="success"
					>
						<Dropdown.Item
							onClick={() => {
								exportMetrics({
									variables: {
										startDate: dateRange.start,
										endDate: dateRange.end,
										exportType: singleExportType,
									},
									fetchPolicy: 'no-cache',
								});
								setIsExportModalOpen(true);
							}}
						>
							<span className="text-capitalize">{metricsContext}</span> Metrics
						</Dropdown.Item>
						{metricsContext === METRICS_CONTEXTS.account && (
							<Dropdown.Item
								onClick={() => {
									exportMetrics({
										variables: {
											startDate: dateRange.start,
											endDate: dateRange.end,
											exportType: ITEM_TYPES.ACCOUNT_PODCASTS,
										},
										fetchPolicy: 'no-cache',
									});
									setIsExportModalOpen(true);
								}}
							>
								All Collection Metrics
							</Dropdown.Item>
						)}
						<Dropdown.Item
							onClick={() => {
								exportMetrics({
									variables: {
										startDate: dateRange.start,
										endDate: dateRange.end,
										exportType: ITEM_TYPES.PODCAST_EPISODES,
									},
								});
								setIsExportModalOpen(true);
							}}
						>
							All Item Metrics
						</Dropdown.Item>
						<Dropdown.Item onClick={() => handleSegmentExport()}>
							Segments
						</Dropdown.Item>
						<Dropdown.Item onClick={() => handleAudienceExport()}>
							Audience
						</Dropdown.Item>
					</DropdownButton>
				</div>
			);
		}
	};

	const renderTrafficReferralsAndAudienceLocations = () => {
		return (
			<Row>
				<Col>
					<MetricsCards.TrafficReferrals
						variables={{
							...contextIdVariable,
							accountId: accountId,
							startDate: dateRange.start,
							endDate: dateRange.end,
						}}
						query={queries.getTrafficReferrals}
					/>
				</Col>
				<Col>
					<MetricsCards.ListenLocations
						variables={{
							...contextIdVariable,
							accountId: accountId,
							startDate: dateRange.start,
							endDate: dateRange.end,
						}}
						query={queries.getListenLocations}
						isHosted={isHosted}
						isShare={contextIdVariable.shareSlug}
					/>
				</Col>
			</Row>
		);
	};

	const renderContactsOrInsights = () => {
		if (showContactMetrics && !featureInsights) {
			return (
				<Row>
					<Col xs="3">
						<MetricsCards.KnownContacts
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
						/>
					</Col>
					<Col>
						<MetricsCards.TopCompanies
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
						/>
					</Col>
				</Row>
			);
		}

		if (featureInsights) {
			return (
				<>
					<Row className="mb-0">
						<Col>
							<h3>Insights</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<SegmentsTableWithRouter
								dateRange={dateRange}
								collectionId={contextIdVariable.podcastId}
								episodeId={contextIdVariable.episodeId}
								shareSlug={contextIdVariable.shareSlug}
							/>
							<AudienceTableWithRouter
								accountId={accountId}
								dateRange={dateRange}
								collectionId={contextIdVariable.podcastId}
								episodeId={contextIdVariable.episodeId}
								shareSlug={contextIdVariable.shareSlug}
							/>
						</Col>
					</Row>
				</>
			);
		}
	};

	if (loading) return <></>;

	return (
		<>
			<Container className="metrics-screen">
				{renderMetricsExport()}
				{renderShareHeader()}
				<MetricDateRangePicker
					accountCreatedOn={selectedAccount.createdOn}
					dateRange={dateRange}
					onChange={handleDateChange}
				/>

				<Row className="metric-toggle">
					<div className="toggle-text">Toggle Percentage View</div>
					<Form.Check
						type="switch"
						id="percentageView"
						checked={showPercents}
						className="casted-form percent-toggle"
						onChange={() => setShowPercents(!showPercents)}
					/>
				</Row>
				<Row className="total-cards">
					<Col>
						<MetricsCards.TotalAudience
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
							}}
							query={contextQueries.totalAndUniqueAudience}
							showPercents={showPercents}
						/>
					</Col>
					<Col>
						<MetricsCards.UniqueListens
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
							}}
							query={contextQueries.totalAndUniqueAudience}
							showPercents={showPercents}
						/>
					</Col>
					<Col>
						<MetricsCards.NewListeners
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
							}}
							query={contextQueries.newAndLoyalAudience}
							showPercents={showPercents}
						/>
					</Col>
					<Col>
						<MetricsCards.LoyalListeners
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
							}}
							query={contextQueries.newAndLoyalAudience}
							showPercents={showPercents}
							context={metricsContext}
						/>
					</Col>
				</Row>
				<Row className="total-cards">
					<Col>
						<MetricsCards.TotalImpressions
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
								isHosted,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
								isHosted,
							}}
							query={queries.getTotalImpressions}
							showPercents={showPercents}
						/>
					</Col>
					<Col>
						<MetricsCards.TotalLikes
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
							}}
							query={queries.getTotalLikes}
							showPercents={showPercents}
						/>
					</Col>
					<Col>
						<MetricsCards.TotalShares
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
							}}
							query={queries.getTotalShares}
							showPercents={showPercents}
						/>
					</Col>
					<Col>
						<MetricsCards.AverageCompletion
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							comparisonVariables={{
								...contextIdVariable,
								startDate: comparisonDateRange.start,
								endDate: comparisonDateRange.end,
							}}
							query={contextQueries.averageCompletion}
							showPercents={showPercents}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<br></br>
						<MetricsCards.ListensOverTime
							variables={{
								...contextIdVariable,
								startDate: dateRange.start,
								endDate: dateRange.end,
							}}
							isHosted={isHosted}
							showWatches={selectedAccount.enableVideo}
							query={
								isHosted
									? contextQueries.listensOverTimeHosted
									: contextQueries.listensOverTimeUnhosted
							}
							showPercents={showPercents}
						/>
					</Col>
				</Row>

				{metricsContext === METRICS_CONTEXTS.account &&
					renderTrafficReferralsAndAudienceLocations()}

				{featureHeatmap && metricsContext === METRICS_CONTEXTS.item && (
					<Row>
						<Col>
							<MetricsCards.ItemEngagementTracker
								variables={{
									accountId: accountId,
									collectionId: variables.podcastId,
									itemId: contextIdVariable.episodeId,
									startDate: dateRange.start,
									endDate: dateRange.end,
									duration,
								}}
							/>
						</Col>
					</Row>
				)}

				{metricsContext === METRICS_CONTEXTS.account && (
					<>
						<Row>
							<Col>
								<MetricsCards.TopShows
									accountId={accountId}
									dateRange={dateRange}
									isHosted={isHosted}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<MetricsCards.TopEpisodes
									variables={{
										...contextIdVariable,
										startDate: dateRange.start,
										endDate: dateRange.end,
										isHosted: isHosted,
										numberOfRows: 10,
									}}
								/>
							</Col>
						</Row>
					</>
				)}

				{renderContactsOrInsights()}

				{(metricsContext === METRICS_CONTEXTS.collection ||
					metricsContext === METRICS_CONTEXTS.item) &&
					renderTrafficReferralsAndAudienceLocations()}

				{metricsContext === METRICS_CONTEXTS.collection && (
					<Row>
						<Col>
							<MetricsCards.TopEpisodes
								variables={{
									...contextIdVariable,
									startDate: dateRange.start,
									endDate: dateRange.end,
									isHosted: isHosted,
									numberOfRows: 10,
								}}
							/>
						</Col>
					</Row>
				)}

				{(metricsContext === METRICS_CONTEXTS.collection ||
					metricsContext === METRICS_CONTEXTS.item) && (
					<Row>
						<Col>
							<MetricsCards.TopClips
								variables={{
									...contextIdVariable,
									startDate: dateRange.start,
									endDate: dateRange.end,
									isHosted: isHosted,
									numberOfRows: 10,
								}}
							/>
						</Col>
					</Row>
				)}

				{metricsContext === METRICS_CONTEXTS.item && (
					<Row>
						<Col>
							<MetricsCards.ResourceMetrics
								variables={{
									...contextIdVariable,
									podcastId: params.showId,
									startDate: dateRange.start,
									endDate: dateRange.end,
									numberOfRows: 5,
								}}
							/>
						</Col>
					</Row>
				)}

				{metricsContext === METRICS_CONTEXTS.theme && (
					<Row>
						<Col>
							<MetricsCards.TopKeywords
								variables={{
									...contextIdVariable,
									numberOfRows: 4,
								}}
							/>
						</Col>
					</Row>
				)}
			</Container>

			<MetricDownloadModal
				title={`Exporting ${metricsContext} Metrics`}
				isOpen={isExportModalOpen}
				onClose={() => setIsExportModalOpen(false)}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedAccount: getSelectedAccount(state),
	promotionMetricsDate: state.metricsData.promotionMetricsDate,
});

export default withRouter(connect(mapStateToProps)(MetricsScreen));
