import React from 'react';

// External Components
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
	FaVideo,
	FaHeadphones,
	FaInfoCircle,
	FaArrowRight,
	FaPen,
} from 'react-icons/fa';

import { history } from 'store/store';

// Internal constants
import { getContentType } from 'components/constants';

const ContentRow = (props) => {
	const { accountId, relevancy, highRelevancy, amplifyRelevancy, themeMatch } =
		props;

	const isInternalObject = themeMatch.hasOwnProperty('episode');

	const {
		total_matches: totalMatches,
		keywords: distinctKeywords,
		distinct_paragraphs: distinctParagraphs,
	} = themeMatch;

	const content = isInternalObject
		? themeMatch.episode
		: themeMatch.externalObject;

	const tooltip = (
		<Tooltip>
			<p className="label mb-0">
				<strong>Total keyword matches:</strong> {totalMatches}
			</p>
			<p className="label mb-0">
				<strong>Unique keyword matches:</strong> {distinctKeywords?.length || 0}
			</p>
			<p className="label mb-0">
				<strong>Paragraphs containing matches:</strong> {distinctParagraphs}
			</p>
		</Tooltip>
	);

	const getThumbnail = () => {
		if (!isInternalObject) {
			return content?.externalObjectType?.object_image;
		}

		return content?.thumbnail || content?.podcast?.thumbnail;
	};

	const getContentHeader = () => {
		if (!isInternalObject) {
			return (
				<div className="content-type">
					<FaPen></FaPen>
					<span>Item</span>
					<span className="link-text">
						Click to go to content page <FaArrowRight />
					</span>
				</div>
			);
		}

		return (
			<div className="content-type">
				{content?.uploadType === 'audio' ? (
					<FaHeadphones></FaHeadphones>
				) : (
					<FaVideo></FaVideo>
				)}
				<span>{getContentType(content?.podcast?.collectionType)}</span>
				<span className="link-text">
					Click to go to content page <FaArrowRight />
				</span>
			</div>
		);
	};

	const renderRowContent = () => {
		return (
			<>
				<div className="content-thumbnail">
					<img src={getThumbnail()} />
				</div>
				<div className="content-info">
					{getContentHeader()}
					<div className="content-name">{content.name}</div>
				</div>
				<div className="content-relevancy">
					<span
						className={`${relevancy >= highRelevancy ? 'high-relevancy' : ''}`}
					>
						{totalMatches > 1
							? `${totalMatches} Matches`
							: `${totalMatches} Match`}
						{`${relevancy >= amplifyRelevancy ? ' (Amplify!)' : ''}`}
					</span>
					<span className="pl-1 match-info">
						<OverlayTrigger
							overlay={tooltip}
							trigger={tooltip ? ['hover', 'focus'] : false}
						>
							<FaInfoCircle size={14} />
						</OverlayTrigger>
					</span>
				</div>
			</>
		);
	};

	const renderRow = () => {
		if (!isInternalObject) {
			return (
				<a target="_blank" href={content.url}>
					{renderRowContent()}
				</a>
			);
		}

		return (
			<Link
				to={{
					pathname: `/account/${accountId}/shows/${content?.podcast?.id}/episodes/${content?.id}/info`,
					state: { from: history.location.pathname },
				}}
			>
				{renderRowContent()}
			</Link>
		);
	};

	return <div className="content-row">{renderRow()}</div>;
};

export default ContentRow;
