export const ACTIONS = {
    HIDE_ON_LOAD: 'CASTED/UPLOAD_WIZARD/HIDE_ON_LOAD',
    SHOW_ON_LOAD: 'CASTED/UPLOAD_WIZARD/SHOW_ON_LOAD',
};

export const initialState = {
    showOnLoad: false
};

export const hideUploadWizardOnLoad = () => {
    return {
        type: ACTIONS.HIDE_ON_LOAD
    };
};

export const showUploadWizardOnLoad = () => {
    return {
        type: ACTIONS.SHOW_ON_LOAD
    };
};

const uploadWizardModalReducer = (state = initialState, action ) => {
    const { 
        type, 
        payload 
    } = action;

    switch (type) {
        case ACTIONS.HIDE_ON_LOAD:
            return {
                ...state,
                showOnLoad: false
            };
        case ACTIONS.SHOW_ON_LOAD:
            return {
                ...state,
                showOnLoad: true
            };
        default:
            return state;
    }
}

export default uploadWizardModalReducer;