import React, {useEffect, useState} from 'react'

import { Dropdown } from 'react-bootstrap';
import { FaAngleDown, FaTimes } from 'react-icons/fa';

import Checkbox from 'components/shared/checkbox';
import Button from 'components/shared/button';
import CastedDropdownButton from 'components/shared/dropdownButton';

const SegmentsDropdown = (props) => {
	const { segments, selectedGroups=[], setSelectedGroups, limit=1, value, selectedSegment={}, segmentRestriction=false } = props;

	const [localGroups, setLocalGroups] = useState([]);

	useEffect(() => {
		setLocalGroups(selectedGroups);
	}, [selectedGroups]);

	const onSelect = (group) => {
		if (localGroups.some(segmentGroup => segmentGroup.id === group.id)) {
			const newSelectedGroups = localGroups.filter(selectedGroup => selectedGroup.id !== group.id)
			setLocalGroups(newSelectedGroups)
		} else {
			if (limit === 1) {
				setLocalGroups([group])
			} else {
				const newSelectedGroups = [...localGroups, group]
				setLocalGroups(newSelectedGroups)
			}
		}
	}

	const onApply = () => {
		setSelectedGroups(localGroups)
	}

	const onToggle = () => {
		setLocalGroups(selectedGroups)
	}

	return (
		<div className="segments-dropdown">
			<CastedDropdownButton
				title={
					<div className="segment-value">
						{value}
						<FaAngleDown className={`segment-carrot ${localGroups.length === 0 ? 'default' : ' '}`} />
					</div>
				}
				classType="round"
				size={'sm'}
				displayDropArrow={false}
				onToggle={onToggle}
			>
				<div className="segment-menu">
					<div className="segment-menu-items">
						{segments.map((segment, i) => (
							<>
								<Dropdown.Header>{segment.category}</Dropdown.Header>
								{segment.segmentGroups.map((group, i) => (
									<div className="segment-menu-groups">
										<Checkbox 
											key={i}
											checked={localGroups.some(segmentGroup => segmentGroup.id === group.id)}
											onChange={() => onSelect(group)}
											// disable if (the limit is hit OR only one item per segment can be selected and an item is already selected OR (segment table) this item belongs to the selected segment) AND this item is not already selected
											disabled={((limit > 1 && localGroups.length === limit) || (segmentRestriction && localGroups.some(segmentGroup => segmentGroup.segmentId === group.segmentId))  || segment.id === selectedSegment.id) && !localGroups.some(segmentGroup => segmentGroup.id === group.id)}
										/>
										<p>{group.name}</p>
										{localGroups.some(segmentGroup => segmentGroup.id === group.id) && <span className="remove" onClick={() => onSelect(group)}><FaTimes /></span>}
									</div>
								))}
							</>
						))}
					</div>
					<div className="segment-button">
						<Dropdown.Item className="button-item">
							<Button className="button" variant="success" onClick={onApply}>
								Apply
							</Button>
						</Dropdown.Item>
					</div>
				</div>
			</CastedDropdownButton>
		</div>
	);
};

export { SegmentsDropdown };




