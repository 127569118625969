import React, { useState, useEffect, useRef } from 'react'

import ReactTagsInput from 'components/shared/tagsInput'
import { useDebouncedCallback } from 'use-debounce'

import { Badge, Card } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'

const EpisodeTags = (props) => {
  const {
    tags: _tags = [],
    onChange = () => {},
    canEdit
  } = props
  
  const input = useRef(null)
  
  /** State **/
  const [episodeTags, setEpisodeTags] = useState(_tags || [])
  
  useEffect(() => {
    if(episodeTags !== _tags) {
      onChange('keywords', episodeTags)
    }
  },[episodeTags])

  useEffect(() => {
    setEpisodeTags(_tags)
  },[_tags])
  
  /** Handlers **/
  const removeTag = (tagToRemove) => {
    setEpisodeTags(episodeTags.filter(tag => tag != tagToRemove))
  }

  const onTagsChanged = (tags) => {
    setEpisodeTags(tags)
  }
  
  const renderTag = (tag) => (
    <Badge className="tag" key={tag.tag + tag.key}>
      {tag.tag}
      <a onClick={() => removeTag(tag.tag)}>
        <FaTimes size={10} className="remove-tag" />
      </a>
    </Badge>
  )
  
  
  /** Render **/
  if (canEdit) {
      return (
        <ReactTagsInput 
          ref={input}
          tags={episodeTags} 
          onChange={onTagsChanged}
          tagClassName='tag' 
          removeTagClassName='remove-tag'
          className='episode-tags' 
          renderTag={(tag) => renderTag(tag)}
          onlyUnique
          addOnBlur
        />
      )
  } else {
    return (
      <Card className="episode-tags no-edit">
        {episodeTags.map( tag => (
          <Badge className="tag" key={tag + tag.index}>
            {tag}
          </Badge>
        ))}
      </Card>
    )
  }
}

export default EpisodeTags