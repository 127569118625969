import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { withRouter, NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client/react/hooks';

import { DIALOGS } from 'store/dialogs';
import { THEME_STATUS } from 'components/constants';

import queries from 'services/graphql/queries';

import { dispatch } from 'store/store';
import { setTheme, clearThemeStore } from 'store/reducers/data/theme';
import { resetTheme } from 'store/reducers/ui/themes';
import {
	hideEditThemeOnLoad,
	showEditThemeOnLoad,
} from 'store/reducers/ui/themes';

import { Spinner } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';

import EditTheme from './editTheme';
import ViewTheme from './viewTheme';
import ThemeContent from './themeContent';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';

const Theme = (props) => {
	const { match, permissions, history, theme, themeUpdates, themeUI } = props;

	const { params } = match;
	const { themeId } = params;

	const themeContainerRef = useRef(null);

	const themesPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.THEMES)
				.rolePermission
		: {};

	/** Queries & Mutations **/
	const { data, loading, error, startPolling, stopPolling } = useQuery(
		queries.getThemeById,
		{
			variables: { themeId: themeId },
			fetchPolicy: 'no-cache',
		}
	);
	const themeData = data?.theme || {};

	useEffect(() => {
		if (Object.keys(theme).length > 0) {
			// Display edit theme form if new theme is created
			if (theme?.name === '') {
				dispatch(showEditThemeOnLoad());
				setEditTheme(true);
			} else {
				dispatch(hideEditThemeOnLoad());
				setEditTheme(false);
			}

			// Poll for updated theme status every 5 seconds
			if (theme?.status === THEME_STATUS.processing) {
				startPolling(5000);
			} else {
				stopPolling();
			}
		}
	}, [theme]);

	useEffect(() => {
		if (!loading) {
			// Only store episode value if there are properties to store
			if (Object.keys(themeData).length > 0) {
				dispatch(setTheme(themeData));
			}
		}
	}, [loading, themeData]);

	useEffect(() => {
		// Stop polling for theme status when unmounting component
		return () => {
			stopPolling();
			dispatch(clearThemeStore());
			dispatch(resetTheme());
		};
	}, []);

	const [editTheme, setEditTheme] = useState(
		themeUI?.editThemeOnLoad || false
	);

	const createPromptMessage = () => {
		let dialog = DIALOGS.THEME_UNSAVED_CHANGES;
		let messageText =
			'You have unsaved changes. Are you sure you want to leave?';
		let deleteThemeId = null;

		if (theme.name === '') {
			messageText =
				'Your theme will be deleted without a name saved. Are you sure you want to leave?';
			deleteThemeId = theme.id;
		}

		return JSON.stringify({
			dialog,
			messageText,
			deleteThemeId,
		});
	};

	// Scrolls user to top of results when paging
	const scrollToTop = () => {
		themeContainerRef.current.scrollIntoView();
	};

	let breadcrumbText = 'Theme Management';

	if (history.location?.state?.from) {
		if (history.location.state.from.indexOf('shows') !== -1) {
			breadcrumbText = `Content ${history.location?.state?.from
				?.split('/')
				.pop()}`;
		}
		if (history.location.state.from.indexOf('insights') !== -1) {
			breadcrumbText = `${history.location?.state?.from
				?.split('/')
				.pop()}`;
		}
	}

	return (
		<>
			<Prompt
				when={
					!loading &&
					(Object.keys(themeUpdates).length > 0 || !theme.name)
				}
				message={createPromptMessage}
			/>
			<div className="theme-container" ref={themeContainerRef}>
				<div className="theme-nav">
					<h2 onClick={() => history.goBack()}>
						<FaChevronLeft className="arrow-left" />{' '}
						{breadcrumbText}
					</h2>
				</div>
				<div className="theme-body">
					{loading || !theme.id ? (
						<div className="theme-loading-container">
							<Spinner
								className="theme-loading"
								animation="grow"
								variant="info"
							/>
						</div>
					) : (
						<>
							<div className="theme-sidebar">
								{themesPermission.canEdit && editTheme ? (
									<EditTheme
										onSave={() => {
											setEditTheme(false);
										}}
									/>
								) : (
									<ViewTheme
										themesPermission={themesPermission}
										onEdit={() => setEditTheme(true)}
									/>
								)}
							</div>
							<ThemeContent scrollToTop={scrollToTop} />
						</>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	permissions: state.auth.permissions,
	theme: state.themeData.theme,
	themeUpdates: state.themeData.themeUpdates,
	themeUI: state.ui.themes,
});

export default withRouter(connect(mapStateToProps)(Theme));
