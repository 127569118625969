export const ACTIONS = {
	SET_EPISODE_ID: 'CASTED_SET_EPISODE_ID',
	SET_EPISODE: 'CASTED/SET_EPISODE',
	SET_EPISODE_UPDATES: 'CASTED/SET_EPISODE_UPDATES',
	SET_EPISODE_RESOURCE_CREATES: 'CASTED/SET_EPISODE_RESOURCE_CREATES',
	SET_EPISODE_RESOURCE_UPDATES: 'CASTED/SET_EPISODE_RESOURCE_UPDATES',
	SET_EPISODE_RESOURCE_DELETES: 'CASTED/SET_EPISODE_RESOURCE_DELETES',
	ADD_EPISODE_UPLOAD: 'CASTED/ADD_EPISODE_UPLOAD',
	UPDATE_EPISODE_UPLOAD: 'CASTED/UPDATE_EPISODE_UPLOAD',
	REMOVE_EPISODE_UPLOAD: 'CASTED/REMOVE_EPISODE_UPLOAD',
	REMOVE_ALL_EPISODE_FILE_UPLOADS: 'CASTED/REMOVE_ALL_EPISODE_FILE_UPLOADS',
	REMOVE_ALL_RSS_FILE_UPLOADS: 'CASTED/REMOVE_ALL_RSS_FILE_UPLOADS',
	REMOVE_ALL_EPISODE_UPLOADS: 'CASTED/REMOVE_ALL_EPISODE_UPLOADS',
};

const initialState = {
	episodeId: null,
	episode: {},
	episodeUpdates: {},
	episodeResourceCreates: [],
	episodeResourceUpdates: [],
	episodeResourceDeletes: [],
	episodeUploads: [],
};

export const setEpisodeId = (episodeId) => {
	return {
		type: ACTIONS.SET_EPISODE_ID,
		episodeId,
	};
};

export const setEpisode = (episode) => {
	return {
		type: ACTIONS.SET_EPISODE,
		episode,
	};
};

export const setEpisodeUpdates = (episodeUpdates) => {
	return {
		type: ACTIONS.SET_EPISODE_UPDATES,
		episodeUpdates,
	};
};

export const setEpisodeResourceCreates = (episodeResourceCreates) => {
	return {
		type: ACTIONS.SET_EPISODE_RESOURCE_CREATES,
		episodeResourceCreates,
	};
};

export const setEpisodeResourceUpdates = (episodeResourceUpdates) => {
	return {
		type: ACTIONS.SET_EPISODE_RESOURCE_UPDATES,
		episodeResourceUpdates,
	};
};

export const setEpisodeResourceDeletes = (episodeResourceDeletes) => {
	return {
		type: ACTIONS.SET_EPISODE_RESOURCE_DELETES,
		episodeResourceDeletes,
	};
};

export const addEpisodeUpload = (episodeUpload) => {
	return {
		type: ACTIONS.ADD_EPISODE_UPLOAD,
		episodeUpload,
	};
};

export const updateEpisodeUpload = (episodeUpload) => {
	return {
		type: ACTIONS.UPDATE_EPISODE_UPLOAD,
		episodeUpload,
	};
};

export const removeEpisodeUpload = (episodeUpload) => {
	return {
		type: ACTIONS.REMOVE_EPISODE_UPLOAD,
		episodeUpload,
	};
};

export const removeAllEpisodeFileUploads = () => {
	return {
		type: ACTIONS.REMOVE_ALL_EPISODE_FILE_UPLOADS,
	};
};

export const removeAllRSSFileUploads = () => {
	return {
		type: ACTIONS.REMOVE_ALL_RSS_FILE_UPLOADS,
	};
};

export const removeAllEpisodeUploads = () => {
	return {
		type: ACTIONS.REMOVE_ALL_EPISODE_UPLOADS,
	};
};

const episodeReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.SET_EPISODE_ID:
			return {
				...state,
				episodeId: action.episodeId,
			};
		case ACTIONS.SET_EPISODE:
			return {
				...state,
				episode: action.episode,
			};
		case ACTIONS.SET_EPISODE_UPDATES:
			return {
				...state,
				episodeUpdates: action.episodeUpdates,
			};
		case ACTIONS.SET_EPISODE_RESOURCE_CREATES:
			return {
				...state,
				episodeResourceCreates: action.episodeResourceCreates,
			};
		case ACTIONS.SET_EPISODE_RESOURCE_UPDATES:
			return {
				...state,
				episodeResourceUpdates: action.episodeResourceUpdates,
			};
		case ACTIONS.SET_EPISODE_RESOURCE_DELETES:
			return {
				...state,
				episodeResourceDeletes: action.episodeResourceDeletes,
			};
		case ACTIONS.ADD_EPISODE_UPLOAD:
			const newEpisodeUploads = state.episodeUploads.concat({
				id: action.episodeUpload.id,
				fileType: action.episodeUpload.fileType,
			});

			return {
				...state,
				episodeUploads: newEpisodeUploads,
			};
		case ACTIONS.UPDATE_EPISODE_UPLOAD:
			return {
				...state,
				episodeUploads: state.episodeUploads.map((episodeUpload) => {
					if (episodeUpload.id !== action.episodeUpload.id) {
						return episodeUpload;
					}
				}),
			};
		case ACTIONS.REMOVE_EPISODE_UPLOAD:
			return {
				...state,
				episodeUploads: state.episodeUploads.filter(
					(episodeUpload) => episodeUpload.id !== action.episodeUpload.id
				),
			};
		case ACTIONS.REMOVE_ALL_EPISODE_FILE_UPLOADS:
			return {
				...state,
				episodeUploads: state.episodeUploads.filter(
					(episodeUpload) => episodeUpload.fileType !== 'episode'
				),
			};
		case ACTIONS.REMOVE_ALL_RSS_FILE_UPLOADS:
			return {
				...state,
				episodeUploads: state.episodeUploads.filter(
					(episodeUpload) => episodeUpload.fileType !== 'rss'
				),
			};
		case ACTIONS.REMOVE_ALL_EPISODE_UPLOADS:
			return {
				...state,
				episodeUploads: [],
			};
		default:
			return state;
	}
};

export default episodeReducer;
