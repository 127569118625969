import React, { useEffect, useRef, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

/**
 *
 * @param {*} props
 * @param { String } props.contentGroupTitle - The title for the content group
 * @param { Array[ContentCards] } props.contentGroupItems - The array of ContentCards that should be in the group
 * @returns HTMLElement
 */
const ContentGroup = (props) => {
	const { contentGroupTitle, contentGroupItems } = props;

	const [displaySeeMore, setDisplaySeeMore] = useState(true);
	const [displayAll, setDisplayAll] = useState(false);

	const contentGroup = useRef(null);

	// Computes if the 'see more' toggle should be shown
	// WARNING: This code is dependent on the list of items being the 2nd
	// element of the parent <div> below.
	// Changing the element order can affect this functionality.
	const computeDisplaySeeMore = () => {
		if (contentGroup.current?.children?.length) {
			let contentCardArray = contentGroup.current.children[1];

			if (
				contentCardArray.lastChild.offsetTop >=
				contentCardArray.offsetTop + contentCardArray.clientHeight
			) {
				setDisplaySeeMore(true);
			} else {
				setDisplaySeeMore(false);
				setDisplayAll(false);
			}
		}
	};

	useEffect(() => {
		computeDisplaySeeMore();
	}, [contentGroup]);

	useEffect(() => {
		window.addEventListener('resize', () => {
			if (window.innerWidth % 5 === 0) {
				computeDisplaySeeMore();
			}
		});
	}, []);

	return (
		<div ref={contentGroup}>
			<div className="content-group-header">
				<h3>{contentGroupTitle}</h3>
			</div>
			<div className={`content-group ${!displayAll ? 'display-less' : ''}`}>
				{contentGroupItems}
			</div>
			{displaySeeMore && (
				<div
					className="content-group-expand"
					onClick={() => setDisplayAll(!displayAll)}
				>
						<div className="content-group-expand-text">
							{`${displayAll ? 'see less' : 'see more'
								}`}</div>
						{displayAll ? <FaAngleUp className='content-group-expand-icon'/> : <FaAngleDown className='content-group-expand-icon'  />}
				</div>
			)}
		</div>
	);
};

export default ContentGroup;
