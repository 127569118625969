import React, { useState } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import MetricCard from 'components/shared/metricCard';
import { Tooltip } from 'react-bootstrap';

const TotalAudience = (props) => {
	const { variables, comparisonVariables, query, showPercents } = props;

	const [value, setValue] = useState(0);
	const [comparisonValue, setComparisonValue] = useState(0);
	const [downloadCount, setDownloadCount] = useState(null);
	const [playCount, setPlayCount] = useState(null);

	const { loading } = useQuery(query, {
		variables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const {
				total_views = 0,
				play_count,
				download_count,
			} = data[query?.definitions[0]?.name?.value];
			// Casted page views are included in play_count
			// If downloads aren't present, use the total_views metric
			setValue(download_count ? download_count + play_count : total_views);
			setDownloadCount(download_count);
			setPlayCount(play_count);
		},
	});
	const { compLoading } = useQuery(query, {
		variables: comparisonVariables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			const {
				total_views = 0,
				play_count,
				download_count,
			} = data[query?.definitions[0]?.name?.value];
			// Casted page views are included in play_count
			// If downloads aren't present, use the total_views metric
			setComparisonValue(
				download_count ? download_count + play_count : total_views
			);
		},
	});

	const tooltip = (
		<Tooltip className="metric-card-tooltip">
			<p className="label tooltip-stat">
				This Period: <span className="value">{value}</span>
			</p>
			{(downloadCount || playCount) && (
				<ul className="tooltip-sub-stats">
					{downloadCount ? (
						<li className="tooltip-stat label">
							Downloads <span className="value">{downloadCount}</span>
						</li>
					) : null}
					{playCount ? (
						<li className="tooltip-stat label">
							Plays <span className="value">{playCount}</span>
						</li>
					) : null}
				</ul>
			)}

			{comparisonVariables.startDate && (
				<>
					<hr />
					<p className="label label-date previous-period">
						Previously: <span className="value">{comparisonValue}</span>
					</p>
				</>
			)}
		</Tooltip>
	);

	return (
		<MetricCard
			value={value || 0}
			comparisonValue={comparisonValue}
			loading={loading || compLoading}
			tooltip={tooltip}
			label="Total Audience"
			showPercents={showPercents}
			hideTooltip={true}
		/>
	);
};

export default TotalAudience;
