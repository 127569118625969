import React from 'react';

// External Components
import DataTable from 'react-data-table-component';
import {
	FaAngleDoubleLeft,
	FaAngleDoubleRight,
	FaAngleLeft,
	FaAngleRight,
	FaAngleDown,
} from 'react-icons/fa';

// Internal Components
import MetricCircles from 'components/shared/metricCircles';

const BaseTable = (props) => {
	const {
		values = [],
		columns = [],
		title = null,
		className = '',
		loading = false,
		pagination = false,
		paginationPerPage = 5,
		clickable = false,
		onClick = () => {},
		noDataComponent = null,
		subHeader = false,
		subHeaderComponent = null,
		subHeaderAlign = 'right',
		customStyle = {
			headRow: {
				style: {
					backgroundColor: '#F7F8FC',
					color: '#434B59',
					fontWeight: 700,
				},
			},
		},
		disabled = false,
		resetPaginationToggle = () => {},
		noTableHead = false,
	} = props;

	return (
		<div className={`base-table ${className}`}>
			<DataTable
				title={values.length ? title : ''}
				columns={columns}
				data={values}
				pagination={pagination}
				responsive
				paginationPerPage={paginationPerPage}
				paginationComponentOptions={{
					noRowsPerPage: true,
				}}
				customStyles={customStyle}
				paginationIconFirstPage={<FaAngleDoubleLeft />}
				paginationIconLastPage={<FaAngleDoubleRight />}
				paginationIconNext={<FaAngleRight />}
				paginationIconPrevious={<FaAngleLeft />}
				paginationResetDefaultPage={resetPaginationToggle}
				sortIcon={<FaAngleDown />}
				progressPending={loading}
				progressComponent={<MetricCircles showIcon={false} />}
				pointerOnHover={clickable}
				onRowClicked={(row, event) => onClick(row)}
				noDataComponent={noDataComponent}
				subHeader={subHeader}
				subHeaderComponent={subHeaderComponent}
				subHeaderAlign={subHeaderAlign}
				disabled={disabled}
				noTableHead={noTableHead}
			/>
		</div>
	);
};

export default BaseTable;
