import React, { useState } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { Form, ModalFooter } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';

import API from 'services/api';
import mutations from 'services/graphql/mutations';

import Button from 'components/shared/button';
import TextArea from 'components/shared/textArea';
import ThumbnailUploader from 'components/shared/thumbnailUploader';

const YoutubeForm = (props) => {
	const {
		accountId,
		episodeId,
		title = '',
		description = '',
		thumbnail = null,
		showThumbnail = null,
		onClose = () => {},
		includeTitle = true,
		smallFooter = false,
		uploadVideo = null,
		autoPublishYoutube = false,
	} = props;

	const [youtubeForm, setYoutubeForm] = useState({
		title,
		description: description?.replace(/(<([^>]+)>)/gi, ''),
		thumbnail: thumbnail || showThumbnail,
		visibility: 'private',
	});

	const [localImage, setLocalImage] = useState(null);
	const [count, setCount] = useState(youtubeForm.title.length);
	/** Mutations **/
	const refetch = uploadVideo
		? {}
		: {
				refetchQueries: ['EpisodeById'],
		  };

	const [updateEpisode] = useMutation(mutations.updateEpisodeById, refetch);
	const [publishToYoutube] = useMutation(mutations.publishToYoutube);

	const updateFormProperty = (property, value) => {
		if (property === 'title') {
			const limit = 100;
			setYoutubeForm({
				...youtubeForm,
				[property]: value.slice(0, limit),
			});
		} else {
			setYoutubeForm({
				...youtubeForm,
				[property]: value,
			});
		}
	};

	const beginUpload = async () => {
		if (uploadVideo) {
			// if uploadVideo is defined, then this is part of a multi-step wizard
			// so need to upload the video and set the initial episode values
			uploadVideo();

			let episodeUpdates = {};
			if (title === '' && youtubeForm.title !== '') {
				episodeUpdates.name = youtubeForm.title;
			}

			if (description === '' && youtubeForm?.description !== '') {
				episodeUpdates.description = youtubeForm.description;
			}

			if (thumbnail === null && localImage !== null) {
				episodeUpdates.thumbnail = await API.uploadPublicFile({
					dataURI: localImage.imgDataUrl,
					fileExt: localImage.fileExt,
				});
			}

			if (episodeUpdates) {
				await updateEpisode({
					variables: {
						accountId: parseInt(accountId, 10),
						episodeId,
						episode: episodeUpdates,
					},
				});
			}
		}

		if (autoPublishYoutube) {
			onClose(youtubeForm.visibility);
		} else {
			await publishToYoutube({
				variables: {
					episodeId,
					accountId: parseInt(accountId, 10),
					youtubeEpisode: youtubeForm,
				},
			});
			onClose();
		}
	};

	const onUploadThumbnail = async (imgDataUrl, fileExt) => {
		updateFormProperty('thumbnail', imgDataUrl);
		try {
			const gcsUrl = await API.uploadPublicFile({
				dataURI: imgDataUrl,
				fileExt,
			});
			updateFormProperty('thumbnail', gcsUrl);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div id="youtube-form">
			{includeTitle && (
				<h5 className="youtube-header">Upload to YouTube</h5>
			)}
			<div className={`youtube-form-body ${smallFooter && 'small-body'}`}>
				{!autoPublishYoutube && (
					<div>
						<div className="youtube-input title">
							<Form.Label className="label">Title</Form.Label>
							<TextArea
								label={'Title'}
								minRows={2}
								value={youtubeForm.title}
								onChange={(e) => {
									updateFormProperty('title', e.target.value);
									setCount(e.target.value.length);
								}}
							/>
							<p>{count > 100 ? 100 : count}/100</p>
						</div>
						<div className="youtube-input">
							<Form.Label className="label">
								Description
							</Form.Label>
							<TextArea
								label={'Description'}
								minRows={4}
								value={youtubeForm?.description || ''}
								onChange={(e) =>
									updateFormProperty(
										'description',
										e.target.value
									)
								}
							/>
						</div>
						<div className="youtube-input">
							<Form.Label className="label">Thumbnail</Form.Label>
							<div className="youtube-form-thumbnail">
								<ThumbnailUploader
									thumbnailImgSrc={youtubeForm.thumbnail}
									onUpload={onUploadThumbnail}
									className="hosted"
								/>
							</div>
						</div>
					</div>
				)}
				<div className="youtube-input">
					<Form.Label className="label">Visibility</Form.Label>
					<Form.Check
						className="youtube-radio"
						type="radio"
						label={
							<div className="radio-option">
								Public
								<div className="radio-description">
									Everyone can watch your video
								</div>
							</div>
						}
						checked={youtubeForm.visibility === 'public'}
						onChange={(e) =>
							updateFormProperty('visibility', 'public')
						}
					/>
					<Form.Check
						className="youtube-radio"
						type="radio"
						label={
							<div className="radio-option">
								Unlisted
								<div className="radio-description">
									Anyone with the video link can watch your
									video
								</div>
							</div>
						}
						checked={youtubeForm.visibility === 'unlisted'}
						onChange={(e) =>
							updateFormProperty('visibility', 'unlisted')
						}
					/>
					<Form.Check
						className="youtube-radio"
						type="radio"
						label={
							<div className="radio-option">
								Private
								<div className="radio-description">
									Only you and people you choose can watch
									your video
								</div>
							</div>
						}
						checked={youtubeForm.visibility === 'private'}
						onChange={(e) =>
							updateFormProperty('visibility', 'private')
						}
					/>
				</div>
			</div>
			<ModalFooter className="youtube-footer">
				<div
					className={`youtube-upload-button ${
						smallFooter && ' small-footer'
					}`}
				>
					<Button key="youtube-upload-button" onClick={beginUpload}>
						Begin Upload
					</Button>
				</div>
			</ModalFooter>
		</div>
	);
};

export default YoutubeForm;
