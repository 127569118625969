import React, { useState } from 'react';
import { Spinner, OverlayTrigger, Tooltip, Modal, Button } from 'react-bootstrap';
import { BsExclamationCircleFill } from 'react-icons/bs';

const GenerateInfoButton = (props) => {
	const {
		editsMade = false,
		isGeneratingInfo = false,
		infoIsGenerated = false,
		showInfoGenerateError = false,
		funcToCall = () => { },
		className = '',
		disabled = false,
		customErrorMessage,
		generateMessage = 'Generate'
	} = props;

	const [showGenerateConfirmationModal, setShowGenerateConfirmationModal] = useState(false);


	const showModalOrGenerate = (funcToCall) => {
		if(!disabled){
			if (editsMade) {
				setShowGenerateConfirmationModal(true);
			} else {
					funcToCall();
			}
		}
};

	const OverLay = ({ children }) => {
			return <OverlayTrigger
				placement="right"
				trigger="hover"
				overlay={
					<Tooltip id="generate-error-tooltip">
						<p>{disabled ? 'Waiting for Transcript' : generateMessage}</p>
					</Tooltip>
				}
			>
				{children}
			</OverlayTrigger>
	}

	return (
		<div className={`generate-item-info ${className}`}>
			{isGeneratingInfo ? (
				<>
					<Spinner className="generate-spinner" animation="border" />
				</>
			) : showInfoGenerateError ? (
				<OverlayTrigger
					placement="right"
					delay={{ show: 100, hide: 4000 }}
					overlay={
						<Tooltip id="generate-error-tooltip">
							{ customErrorMessage ? customErrorMessage : <> <p>Error Generating Title</p>
							Try again or{' '}
							<a href="https://casted.atlassian.net/servicedesk/customer/portal/3">
								contact support
							</a> </> }
						</Tooltip>
					}
				>
					<BsExclamationCircleFill id="generate-error-icon" />
				</OverlayTrigger>
			) : infoIsGenerated ? (
				<div
					className="generate-info-button"
					onClick={() => showModalOrGenerate(funcToCall)}
					click-disabled={disabled ? 'true' : 'false'}
				>
					<OverLay>
						<svg
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.20834 11.9042L7.13834 12.0442C6.84667 12.61 6.38584 13.0708 5.82001 13.3625L5.68001 13.4325C5.12584 13.7125 5.12584 14.5058 5.68001 14.7917L5.82001 14.8617C6.38584 15.1533 6.84667 15.6142 7.13834 16.18L7.20834 16.32C7.48834 16.8742 8.28167 16.8742 8.56751 16.32L8.63751 16.18C8.92917 15.6142 9.39001 15.1533 9.95584 14.8617L10.0958 14.7917C10.65 14.5117 10.65 13.7183 10.0958 13.4325L9.95584 13.3625C9.39001 13.0708 8.92917 12.61 8.63751 12.0442L8.56751 11.9042C8.28751 11.35 7.49417 11.35 7.20834 11.9042Z"
								fill="currentColor"
							/>
							<path
								d="M9.49501 10.8717L9.68167 10.965C10.265 11.2625 10.7317 11.7292 11.0292 12.3125L11.1225 12.4992C11.4433 13.1292 12.085 13.52 12.7908 13.52C13.4967 13.52 14.1383 13.1292 14.465 12.4992L14.5583 12.3125C14.8558 11.7292 15.3225 11.2625 15.9058 10.965L16.0925 10.8717C16.7225 10.5508 17.1133 9.90917 17.1133 9.20333C17.1133 8.4975 16.7225 7.85583 16.0925 7.52917L15.9058 7.43583C15.3225 7.13833 14.8558 6.67167 14.5583 6.08833L14.465 5.90167C14.1442 5.27167 13.5025 4.88083 12.7908 4.88083C12.0792 4.88083 11.4433 5.27167 11.1167 5.90167L11.0233 6.08833C10.7258 6.67167 10.2592 7.13833 9.67584 7.43583L9.48917 7.52917C8.85917 7.85 8.46834 8.49167 8.46834 9.20333C8.46834 9.915 8.85917 10.5508 9.48917 10.8775L9.49501 10.8717ZM10.2883 9.08667L10.475 8.99333C11.3908 8.52667 12.12 7.7975 12.5867 6.88167L12.68 6.695C12.68 6.695 12.715 6.625 12.7908 6.625C12.8667 6.625 12.8958 6.67167 12.9017 6.695L12.995 6.88167C13.4617 7.7975 14.1908 8.52667 15.1067 8.99333L15.2933 9.08667C15.2933 9.08667 15.3633 9.12167 15.3633 9.1975C15.3633 9.27333 15.3108 9.3025 15.2933 9.30833L15.1067 9.40167C14.1908 9.86833 13.4617 10.5975 12.995 11.5133L12.9017 11.7C12.9017 11.7 12.8667 11.77 12.7908 11.77C12.715 11.77 12.6858 11.7175 12.68 11.7L12.5867 11.5133C12.12 10.5975 11.3908 9.86833 10.475 9.40167L10.2883 9.30833C10.2883 9.30833 10.2183 9.27333 10.2183 9.1975C10.2183 9.12167 10.2708 9.0925 10.2883 9.08667Z"
								fill="currentColor"
							/>
							<path
								d="M17.5042 16.8042L14.2783 17.16C13.6192 17.23 13.2867 17.9883 13.6775 18.525L13.9633 18.91C13.0183 19.2658 12.0092 19.4583 11 19.4583C10.1542 19.4583 9.33167 19.33 8.56167 19.0967C8.41584 19.0558 8.27584 18.9975 8.13584 18.9508C8.00751 18.9042 7.87917 18.8633 7.75667 18.8108C7.66917 18.7758 7.58751 18.735 7.50584 18.6942C7.33084 18.6125 7.15001 18.5308 6.98084 18.4375C6.96917 18.4317 6.95751 18.4258 6.95167 18.42C4.33251 16.9792 2.54751 14.1967 2.54751 11C2.54751 10.9883 2.54751 10.9767 2.54751 10.9592C2.54751 10.7608 2.55917 10.5683 2.57084 10.37C2.57667 10.265 2.58834 10.1658 2.60001 10.0608C2.61751 9.915 2.63501 9.76917 2.65834 9.62333C2.67584 9.53 2.68751 9.43667 2.70501 9.3375C2.74584 9.13333 2.79834 8.92917 2.85084 8.725C3.04917 8.01917 3.33501 7.33667 3.71417 6.695C3.95917 6.28083 3.81917 5.74417 3.40501 5.49917C2.99084 5.25417 2.45417 5.39417 2.20917 5.80833C1.80084 6.5025 1.48001 7.24333 1.24084 8.0075C1.22917 8.0425 1.21167 8.07167 1.20001 8.10667C1.18834 8.15333 1.17667 8.2 1.16501 8.24667C1.09501 8.4975 1.03084 8.74833 0.984172 8.99917C0.960839 9.11 0.949172 9.22083 0.925839 9.33167C0.896672 9.50667 0.873339 9.6875 0.850005 9.86833C0.838339 9.99083 0.820839 10.1133 0.815005 10.2358C0.797505 10.4692 0.785838 10.6967 0.785838 10.93C0.774172 12.6392 1.18834 14.3367 2.02834 15.8825C2.97917 17.6267 4.39084 19.0208 6.09417 19.9483C6.10584 19.9542 6.12334 19.9658 6.13501 19.9717C6.33917 20.0825 6.55501 20.1817 6.77084 20.275C6.87584 20.3217 6.97501 20.3742 7.08001 20.415C7.23167 20.4792 7.38334 20.5258 7.53501 20.5842C7.70417 20.6483 7.87334 20.7125 8.04834 20.765C8.07167 20.7708 8.08917 20.7825 8.11251 20.7883C8.13001 20.7883 8.14751 20.7883 8.16501 20.7942C9.06334 21.0567 10.0142 21.2025 10.9942 21.2025C12.3825 21.2025 13.7533 20.9108 15.0308 20.3625L15.5967 21.1383C15.9875 21.6692 16.81 21.5817 17.0783 20.975L18.385 18.0058C18.6533 17.3992 18.1633 16.7342 17.5042 16.8042Z"
								fill="currentColor"
							/>
							<path
								d="M11 0.791666C9.61167 0.791666 8.24084 1.08333 6.96334 1.63167L6.39751 0.855833C6.00667 0.324999 5.18417 0.412499 4.91584 1.01917L3.60917 3.98833C3.34084 4.595 3.83084 5.26 4.49001 5.19L7.71584 4.83417C8.37501 4.76417 8.70751 4.00583 8.31667 3.46917L8.03084 3.08417C8.97584 2.72833 9.98501 2.53583 10.9942 2.53583C15.6608 2.53583 19.4525 6.3275 19.4525 10.9942C19.4525 12.4933 19.0442 13.9808 18.2742 15.2817C18.0292 15.6958 18.1692 16.2325 18.5833 16.4775C18.7233 16.5592 18.875 16.6 19.0267 16.6C19.3242 16.6 19.6158 16.4483 19.7792 16.1683C20.7067 14.5933 21.2025 12.8025 21.2025 10.9883C21.2025 5.35917 16.6233 0.779999 10.9942 0.779999L11 0.791666Z"
								fill="currentColor"
							/>
						</svg>
					</OverLay>
				</div>
			) : (
				<div
					className="generate-info-button"
					onClick={() => showModalOrGenerate(funcToCall)}
					click-disabled={disabled ? 'true' : 'false'}
				>
					<OverLay
					>
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="currentColor"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M6.58969 3.18516L6.72469 3.25266C7.27031 3.53391 7.71469 3.97828 7.99594 4.52391L8.06344 4.65891C8.33344 5.19328 9.09844 5.19328 9.37406 4.65891L9.44156 4.52391C9.72281 3.97828 10.1672 3.53391 10.7128 3.25266L10.8478 3.18516C11.3822 2.91516 11.3822 2.15016 10.8478 1.87453L10.7128 1.80703C10.1672 1.52578 9.72281 1.08141 9.44156 0.535781L9.37406 0.400781C9.10406 -0.133594 8.33906 -0.133594 8.06344 0.400781L7.99594 0.535781C7.71469 1.08141 7.27031 1.52578 6.72469 1.80703L6.58969 1.87453C6.05531 2.14453 6.05531 2.90953 6.58969 3.18516Z"
								fill="currentColor"
							/>
							<path
								d="M7.65281 8.45578C8.20406 8.17453 8.20406 7.39266 7.65281 7.11141L6.83156 6.68953C6.10031 6.31266 5.50406 5.71641 5.12719 4.98516L4.70531 4.16391C4.42406 3.61266 3.64219 3.61266 3.36094 4.16391L2.93906 4.98516C2.56219 5.71641 1.96594 6.31266 1.23469 6.68953L0.413438 7.11141C-0.137812 7.39266 -0.137812 8.17453 0.413438 8.45578L1.23469 8.87766C1.96594 9.25453 2.56219 9.85078 2.93906 10.582L3.36094 11.4033C3.64219 11.9545 4.42406 11.9545 4.70531 11.4033L5.12719 10.582C5.50406 9.85078 6.10031 9.25453 6.83156 8.87766L7.65281 8.45578Z"
								fill="currentColor"
							/>
							<path
								d="M18.2053 11.8195L17.3728 11.392C16.3659 10.8802 15.5616 10.0758 15.0497 9.06891L14.6222 8.23641C14.2453 7.49391 13.4916 7.03266 12.6591 7.03266C11.8266 7.03266 11.0728 7.49391 10.6959 8.23641L10.2684 9.06891C9.75656 10.0758 8.95219 10.8802 7.94531 11.392L7.11281 11.8195C6.37031 12.1964 5.90906 12.9502 5.90906 13.7827C5.90906 14.6152 6.37031 15.3689 7.11281 15.7458L7.94531 16.1733C8.95219 16.6852 9.75656 17.4895 10.2684 18.4964L10.6959 19.3289C11.0728 20.0714 11.8266 20.5327 12.6591 20.5327C13.4916 20.5327 14.2453 20.0714 14.6222 19.3289L15.0497 18.4964C15.5616 17.4895 16.3659 16.6852 17.3728 16.1733L18.2053 15.7458C18.9478 15.3689 19.4091 14.6152 19.4091 13.7827C19.4091 12.9502 18.9478 12.1964 18.2053 11.8195ZM17.4403 14.2439L16.6078 14.6714C15.2803 15.3464 14.2228 16.4039 13.5478 17.7314L13.1203 18.5639C12.9909 18.817 12.7547 18.8452 12.6591 18.8452C12.5634 18.8452 12.3272 18.817 12.1978 18.5639L11.7703 17.7314C11.0953 16.4039 10.0378 15.3464 8.71031 14.6714L7.87781 14.2439C7.62469 14.1145 7.59656 13.8783 7.59656 13.7827C7.59656 13.687 7.62469 13.4508 7.87781 13.3214L8.71031 12.8939C10.0378 12.2189 11.0953 11.1614 11.7703 9.83391L12.1978 9.00141C12.3272 8.74828 12.5634 8.72016 12.6591 8.72016C12.7547 8.72016 12.9909 8.74828 13.1203 9.00141L13.5478 9.83391C14.2228 11.1614 15.2803 12.2189 16.6078 12.8939L17.4403 13.3214C17.6934 13.4508 17.7216 13.687 17.7216 13.7827C17.7216 13.8783 17.6934 14.1145 17.4403 14.2439Z"
								fill="currentColor"
							/>
						</svg>
					</OverLay>
				</div>
			)}

				<Modal
					show={showGenerateConfirmationModal}
					onHide={() => setShowGenerateConfirmationModal(false)}
					id="generate-summary-modal"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>
							<h4>Warning</h4>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							Generating a suggestion will overwrite any unsaved changes. Save
							changes first, then try again.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={() => {
								funcToCall();
								setShowGenerateConfirmationModal(false);
							}}
							variant="light"
							className="label-btn-text"
						>
							GENERATE
						</Button>
						<Button
							onClick={() => setShowGenerateConfirmationModal(false)}
							variant="primary"
							className="label-btn-text"
						>
							CLOSE
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
}

export default GenerateInfoButton;