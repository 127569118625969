import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import EngagementTracker from 'components/shared/engagementTracker';

const ClipEngagementTracker = (props) => {
	const {
		accountId,
		itemId,
		startDate,
		endDate,
		duration,
		selectedClip,
		selectedOption,
	} = props;

	const { data, loading } = useQuery(queries.getHeatmap, {
		variables: {
			accountId,
			itemId,
			clipId: selectedClip.id,
			startDate,
			endDate,
		},
		fetchPolicy: 'cache-and-network',
	});
	const { getHeatmap: trackerData = [] } = data || {};

	if (Object.keys(selectedClip).length === 0) {
		return <></>;
	}

	return (
		<EngagementTracker
			selectedOption={selectedOption}
			isClip={true}
			selectedClip={selectedClip}
			trackerData={trackerData}
			duration={duration}
			loading={loading}
		/>
	);
};

export default ClipEngagementTracker;
