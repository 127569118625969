import React, { useState } from 'react';

import Button from 'components/shared/button';

const CopyToClipboardButton = (props) => {
	// HOOKS
	const [copySuccess, setCopySuccess] = useState(null);
	// END HOOKS

	const {
		copyRef,
		className = '',
		copyBtnClassName = '',
		copySuccessMessageClassName = '',
		copiedTextTimeoutMs = 2500,
		btnText = 'Copy',
		onCopy = () => {},
	} = props;

	if (!copyRef) {
		return null;
	}

	const copyToClipboard = (e) => {
		copyRef.current.select();
		document.execCommand('copy');
		e.target.focus();
		setCopySuccess('Copied!');
		setTimeout(() => setCopySuccess(null), copiedTextTimeoutMs);
		onCopy();
	};

	return (
		<React.Fragment>
			{
				/* Logical shortcut for only displaying the
           button if the copy command exists */
				document.queryCommandSupported('copy') && (
					<div className={`copy-to-clipboard ${className}`}>
						{!copySuccess && (
							<Button
								name="copy-to-clipboard-button"
								className={`copy-to-clipboard-btn ${copyBtnClassName}`}
								onClick={copyToClipboard}
								variant="success"
							>
								{btnText}
							</Button>
						)}
						{copySuccess && (
							<span
								className={`copy-success-msg ${copySuccessMessageClassName}`}
							>
								{copySuccess}
							</span>
						)}
					</div>
				)
			}
		</React.Fragment>
	);
};

export default CopyToClipboardButton;
