import gaussian from 'gaussian';

const getMeanStandardDeviation = (array) => {
	const n = array.length;
	const mean =
		array.reduce((a, b) => {
			let aVal = a;
			if (a.total_matches) {
				aVal = parseInt(a.total_matches, 10);
			}
			return aVal + parseInt(b.total_matches, 10);
		}) / n;

	const sd = Math.sqrt(
		array
			.map((x) => Math.pow(parseInt(x.total_matches, 10) - mean, 2))
			.reduce((a, b) => a + b) / n
	);

	return {
		mean: mean,
		standardDeviation: sd,
	};
};

/**
 * Takes in episodeThemeMatches array. Creates normal distribution based on total
 *  matches and assigns a percentile to each match.
 * @param {*} themeMatches
 * @returns
 */
const getPercentileMatches = (themeMatches = []) => {
	const themeMatchesArray = [...themeMatches];

	if (themeMatchesArray.length > 0) {
		const { mean, standardDeviation } =
			getMeanStandardDeviation(themeMatchesArray);
		const normal = gaussian(
			mean /* mean */,
			standardDeviation /* standard deviation */
		);

		for (let i = 0; i < themeMatchesArray.length; i++) {
			let themeMatch = { ...themeMatchesArray[i] };
			const totalMatches = parseInt(themeMatchesArray[i].total_matches, 10);
			const percentile = normal.cdf(totalMatches); // cumulative distribution function
			themeMatch.percentile = percentile;
			themeMatchesArray[i] = themeMatch;
		}
	}
	return themeMatchesArray;
};

export { getPercentileMatches };
