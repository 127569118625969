import { combineReducers } from 'redux';

import deleteThemeModal from './deleteThemeModal';
import newContentModal from './newContentModal';
import uploadWizardModal from './uploadWizardModal';
import importContentModal from './importContentModal';
import disconnectAccountIntegrationModal from './disconnectAccountIntegrationModal';
import deleteImportedContentModal from './deleteImportedContentModal';
import addCollectionPermissionModal from './addCollectionPermissionModal';
import playlistShareModal from './playlistShareModal';
import clipShareModal from './clipShareModal';
import deleteClipModal from './deleteClipModal';
import confirmPromotionOverwriteModal from './confirmPromotionOverwriteModal';
import confirmPromotionDeleteModal from './confirmPromotionDeleteModal';
import archiveShareModal from './archiveShareModal';
import editShareModal from './editShareModal';
import collectionShareModal from './collectionShareModal';
import premiumTranscriptionModal from './premiumTranscriptionModal';

export default combineReducers({
	deleteThemeModal,
	newContentModal,
	uploadWizardModal,
	importContentModal,
	disconnectAccountIntegrationModal,
	deleteImportedContentModal,
	addCollectionPermissionModal,
	playlistShareModal,
	clipShareModal,
	deleteClipModal,
	confirmPromotionOverwriteModal,
	confirmPromotionDeleteModal,
	archiveShareModal,
	editShareModal,
	collectionShareModal,
	premiumTranscriptionModal,
});
