import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import podcat from 'assets/images/podcat-playing-v2.png';
import BaseTable from 'components/shared/baseTable';
import EngagementTracker from 'components/shared/engagementTracker';

import { FaSearch } from 'react-icons/fa';

const EngagedCompanies = (props) => {
	const { accountId, itemId, startDate, endDate, selectedOption, duration } =
		props;

	const [filteredCompanyData, setFilteredCompanyData] = useState([]);

	const { data, loading } = useQuery(queries.getHeatmap, {
		variables: {
			accountId,
			itemId,
			startDate,
			endDate,
			byCompany: true,
		},
		fetchPolicy: 'no-cache',
	});
	const { getHeatmap: companyData = [] } = data || [];

	useEffect(() => {
		if (!loading) {
			setFilteredCompanyData(companyData);
		}
	}, [loading]);

	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let result = [];

		result = companyData.filter((company) => {
			const companyName = company.name.toLowerCase();
			return companyName.search(value) != -1;
		});
		setFilteredCompanyData(result);
	};

	const columns = [
		{
			selector: (row) => row.name,
			cell: (row) => (
				<div className="engaged-company-name">
					<h3>{row.name}</h3>
					<img alt={row.name} src={row.logo} className="table-logo" />{' '}
				</div>
			),
			sortable: true,
			grow: 1,
		},
		{
			cell: (row) => (
				<div className="engaged-company-tracker">
					<EngagementTracker
						selectedOption={selectedOption}
						trackerData={[row]}
						duration={duration}
						loading={loading}
						byCompany={true}
					/>
				</div>
			),
			grow: 6,
		},
	];

	return (
		<div className="engaged-companies-container">
			<div className="header">
				<h4>Most Engaged Companies</h4>
				<div className="engaged-companies-search">
					<FaSearch className="search-icon" />
					<input
						placeholder="Search companies"
						onChange={(event) => handleSearch(event)}
					/>
				</div>
			</div>
			<BaseTable
				noTableHead={true}
				values={filteredCompanyData}
				columns={columns}
				loading={loading}
				clickable={false}
				pagination={companyData.length > 5}
				paginationPerPage={5}
				subHeaderAlign={'right'}
				noDataComponent={
					<div className="empty-container">
						<img className="podcat" src={podcat} />
						<div className="empty-state">
							<div className="bold-header">No results to see here!</div>
							<div>
								Try <span className="bold">changing your search criteria</span>{' '}
								or <span className="bold">sharing your content</span> with your
								audience <br></br>Reach out to your CSM to learn more
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default EngagedCompanies;
