import React, { useState } from 'react';
import CloseIcon from 'react-feather/dist/icons/x';
import { FaChevronLeft } from 'react-icons/fa';
import { useMutation } from '@apollo/client/react/hooks';
import { FaThumbsUp } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';

import {
	NEW_EPISODE_STAGES,
	TRANSCRIPTION_OPTIONS,
} from 'components/constants';
import TranscriptionOptions from './options';
import TranscriptionCost from './creditCost';
import PurchaseCredits from './purchaseCredits';
import ImportTranscription from './import';
import mutations from 'services/graphql/mutations';
import Button from 'components/shared/button';

const TranscriptionWizard = (props) => {
	const { accountId, onCancelClick, onSaveClick, userId, episodeId } = props;

	const [startRevTranscription] = useMutation(
		mutations.startRevTranscription
	);

	// HOOKS
	const [currentStage, setCurrentStage] = useState(
		NEW_EPISODE_STAGES.TRANSCRIPTION_CHOICE
	);
	const [isComplete, setIsComplete] = useState(false);
	const [isImportComplete, setIsImportComplete] = useState(false);

	const handleContinueClicked = async (selection) => {
		switch (currentStage) {
			case NEW_EPISODE_STAGES.TRANSCRIPTION_CHOICE:
				switch (selection) {
					case TRANSCRIPTION_OPTIONS.AUTO:
						setIsComplete(true);
						break;
					case TRANSCRIPTION_OPTIONS.HUMAN:
						setCurrentStage(NEW_EPISODE_STAGES.CREDITS_REMAINING);
						break;
					case TRANSCRIPTION_OPTIONS.IMPORT:
						setCurrentStage(
							NEW_EPISODE_STAGES.IMPORT_TRANSCRIPTION
						);
				}
				break;

			case NEW_EPISODE_STAGES.CREDITS_REMAINING:
				if (selection === NEW_EPISODE_STAGES.PURCHASE_CREDITS) {
					setCurrentStage(NEW_EPISODE_STAGES.PURCHASE_CREDITS);
				} else {
					await startRevTranscription({
						variables: { episodeId, accountId, userId },
					});
					setIsComplete(true);
				}
				break;

			case NEW_EPISODE_STAGES.PURCHASE_CREDITS:
				setCurrentStage(NEW_EPISODE_STAGES.CREDITS_REMAINING);
				break;

			case NEW_EPISODE_STAGES.IMPORT_TRANSCRIPTION:
				setIsImportComplete(true);
				break;
		}
	};

	const renderCloseButton = () => (
		<CloseIcon
			className="close-icon"
			onClick={async () => {
				if (onCancelClick) {
					onCancelClick();
				}
			}}
		/>
	);

	const renderBackButton = () => (
		<div
			className="back-button"
			onClick={() => {
				switch (currentStage) {
					case NEW_EPISODE_STAGES.CREDITS_REMAINING:
					case NEW_EPISODE_STAGES.IMPORT_TRANSCRIPTION:
						setCurrentStage(
							NEW_EPISODE_STAGES.TRANSCRIPTION_CHOICE
						);
						break;
					case NEW_EPISODE_STAGES.PURCHASE_CREDITS:
						setCurrentStage(NEW_EPISODE_STAGES.CREDITS_REMAINING);
						break;
				}
			}}
		>
			<FaChevronLeft className="back-icon" />
			<span className="back-label">BACK</span>
		</div>
	);

	if (isComplete) {
		return (
			<>
				<Modal.Body className="success-modal">
					<div className="success-icon">
						{' '}
						<FaThumbsUp />
					</div>
					<h3>Transcription Started</h3>
					<p>
						Episode transcription is underway! You will receive an
						email when it's complete. Please allow up to 24 hours
						for premium transcriptions.
					</p>
				</Modal.Body>
				<Modal.Footer className="options-footer">
					<Button
						variant="contained"
						className="options-next-btn"
						onClick={() => {
							if (onSaveClick) {
								onSaveClick();
							}
						}}
					>
						Got it
					</Button>
				</Modal.Footer>
			</>
		);
	}

	if (isImportComplete) {
		return (
			<>
				<Modal.Body className="success-modal">
					<div className="success-icon">
						{' '}
						<FaThumbsUp />
					</div>
					<h3>Import Successful</h3>
					<p>
						Transcription has been imported. Reminder that it will
						only be displayed in the public episode page. It will
						not be used with clipping and audiograms.
					</p>
				</Modal.Body>
				<Modal.Footer className="options-footer">
					<Button
						variant="contained"
						className="options-next-btn"
						onClick={() => {
							if (onCancelClick) {
								onCancelClick();
							}
						}}
					>
						Got it
					</Button>
				</Modal.Footer>
			</>
		);
	}

	return (
		<div className="transcript-wizard-container">
			{renderCloseButton()}
			<TranscriptionOptions
				stageName={NEW_EPISODE_STAGES.TRANSCRIPTION_CHOICE}
				currentStage={currentStage}
				onContinueClick={async (selection) =>
					await handleContinueClicked(selection)
				}
				premierOnly={true}
			/>
			<TranscriptionCost
				stageName={NEW_EPISODE_STAGES.CREDITS_REMAINING}
				currentStage={currentStage}
				onContinueClick={async (selection) =>
					await handleContinueClicked(selection)
				}
				accountId={accountId}
				episodeId={episodeId}
			/>
			<PurchaseCredits
				stageName={NEW_EPISODE_STAGES.PURCHASE_CREDITS}
				currentStage={currentStage}
				onContinueClick={async (selection) =>
					await handleContinueClicked(selection)
				}
				accountId={accountId}
				userId={userId}
			/>
			<ImportTranscription
				stageName={NEW_EPISODE_STAGES.IMPORT_TRANSCRIPTION}
				currentStage={currentStage}
				onContinueClick={async (selection) =>
					await handleContinueClicked(selection)
				}
				accountId={accountId}
				episodeId={episodeId}
			/>
			{currentStage !== NEW_EPISODE_STAGES.TRANSCRIPTION_CHOICE &&
				renderBackButton()}
		</div>
	);
};

export default TranscriptionWizard;
