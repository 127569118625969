import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/shared/button';

const ViewTheme = (props) => {
	const { theme, themesPermission, onEdit = () => {} } = props;

	const { name, keywords, publicStatus, color, matchThreshold } = theme;

	const editThemeOnClick = () => {
		onEdit();
	};

	return (
		<div className="theme-details">
			<div className="theme-property">
				<label className="form-label">Name</label>
				<div className="property-value">{name}</div>
			</div>
			<div className="theme-property">
				<label className="form-label">Keywords</label>
				<div className="property-value">{keywords && keywords.join(', ')}</div>
			</div>
			<div className="theme-property">
				<label className="form-label">Match Threshold</label>
				<div className="property-value">{matchThreshold}</div>
			</div>
			<div className="theme-property">
				<label className="form-label">Status</label>
				<div className="property-value capitalize">{publicStatus}</div>
			</div>
			<div className="theme-property">
				<label className="form-label">Theme Color</label>
				<div className="property-value">
					<span
						className="theme-color"
						style={{
							backgroundColor: color,
						}}
					></span>
				</div>
			</div>
			{themesPermission.canEdit && (
				<Button
					className="w-100 mt-3"
					variant="outline-dark"
					onClick={editThemeOnClick}
				>
					Edit
				</Button>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	theme: state.themeData.theme,
});

export default connect(mapStateToProps)(ViewTheme);
