import React from 'react';
import { Facet } from '@elastic/react-search-ui';

import SelectList from '../../filters/select-list';

import styles from './styles.scss';

const DateFilter = (props) => {
	const { field, fieldLabel } = props;

	return (
		<div className={styles.dateFilter}>
			<Facet
				field={field}
				label={fieldLabel}
				filterType="any"
				view={({ label, onRemove, onSelect, options }) => {
					return (
						<fieldset>
							<h3>{label}</h3>
							{options && (
								<SelectList
									options={options}
									onSelect={(value) => onSelect(value)}
									onRemove={(value) => onRemove(value)}
								/>
							)}
						</fieldset>
					);
				}}
			/>
		</div>
	);
};

export default DateFilter;
