import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client/react/hooks';
import mutations from 'services/graphql/mutations';
import { trackEvent } from 'services/vitally';

import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { addDays, format, startOfDay } from 'date-fns';

import { dispatch } from 'store/store';
import { hideEditShareModal } from 'store/reducers/ui/modals/editShareModal';
import { hideArchiveShareModal } from 'store/reducers/ui/modals/archiveShareModal';

import Modal from 'components/shared/modal';
import ShareForm from 'components/shared/shareForm';
import { SHARE_STATUS } from 'components/constants';
import ClipModal from 'components/shared/metricClipModal';
import { ShareSearchWithRouter } from '../shareSearch';

const ShareList = (props) => {
	const { users, modals, selectedAccountId } = props;

	const [selectedShares, setSelectedShares] = useState([]);
	const [selectedShare, setSelectedShare] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [updatedShares, setUpdatedShares] = useState([]);
	const [archivedShares, setArchivedShares] = useState([]);

	const [updateShare] = useMutation(mutations.updateShareById, {
		refetchQueries: ['shares'],
	});

	const today = startOfDay(new Date());

	const saveShareOnClick = async () => {
		let updatedShare = { ...selectedShare };

		await updateShare({
			variables: {
				shareId: selectedShare.id,
				share: {
					accountId: parseInt(selectedAccountId, 10),
					title: updatedShare.title,
					destination: updatedShare.destination,
					description: updatedShare.description,
				},
			},
		});

		if (updatedShares.some((share) => share.id === updatedShare.id)) {
			const newUpdates = updatedShares.map((share) => {
				if (share.id === updatedShare.id) {
					share = { ...updatedShare };
					return share;
				}
			});
			setUpdatedShares(newUpdates);
		} else {
			setUpdatedShares([...updatedShares, updatedShare]);
		}

		trackEvent('edit-share', { shareId: selectedShare.id });
		dispatch(hideEditShareModal());
	};

	const archiveSharesOnClick = async (status) => {
		const sharesToArchive = [...selectedShares];

		for (let i = 0; i < sharesToArchive.length; i++) {
			const share = sharesToArchive[i];
			await updateShare({
				variables: {
					shareId: share.id,
					share: {
						accountId: parseInt(selectedAccountId, 10),
						title: share.title,
						status: status,
					},
				},
			});
			trackEvent('edit-share', { shareId: share.id, status });
		}
		setArchivedShares([...archivedShares, ...sharesToArchive]);
		dispatch(hideArchiveShareModal());
		setSelectedShares([]);
	};

	const selectShareHandler = (share) => {
		if (selectedShares.some((selectedShare) => selectedShare.id === share.id)) {
			setSelectedShares(
				selectedShares.filter((selectedShare) => selectedShare.id !== share.id)
			);
		} else {
			setSelectedShares([...selectedShares, share]);
		}
	};

	return (
		<div className="shares-list">
			<ShareSearchWithRouter
				selectedShares={selectedShares}
				selectShareHandler={selectShareHandler}
				setSelectedShare={setSelectedShare}
				setModalOpen={setModalOpen}
				setSelectedShares={setSelectedShares}
				users={users}
				updatedShares={updatedShares}
				archivedShares={archivedShares}
				setArchivedShares={setArchivedShares}
			/>
			<BootstrapModal
				show={modals.editShareModal.showModal}
				onHide={() => {
					dispatch(hideEditShareModal());
				}}
				className="casted-modal share-edit"
			>
				<BootstrapModal.Header closeButton={true}>
					<BootstrapModal.Title>
						<h4>
							<span className="share-title">Share Center</span> / Edit Share
						</h4>
					</BootstrapModal.Title>
				</BootstrapModal.Header>
				<BootstrapModal.Body>
					<div>
						<p className="edit-share-header">
							This information helps you track your share information in the{' '}
							<b>Casted Share Center.</b>
						</p>
						<ShareForm
							share={selectedShare}
							setShareProperty={setSelectedShare}
							className={'share-edit'}
						/>
					</div>
				</BootstrapModal.Body>
				<BootstrapModal.Footer>
					<div className="w-100">
						<Button
							variant="success"
							onClick={saveShareOnClick}
							className="w-50"
						>
							Save
						</Button>
					</div>
				</BootstrapModal.Footer>
			</BootstrapModal>
			<Modal
				show={modals.archiveShareModal.showModal}
				handleClose={() => {
					dispatch(hideArchiveShareModal());
				}}
				title={`Are you sure you want to ${
					selectedShares[0]?.status === SHARE_STATUS.ACTIVE
						? 'archive'
						: 'unarchive'
				} ${selectedShares.length} ${
					selectedShares.length > 1 ? 'items' : 'item'
				}?`}
				dialogOptions={[
					{
						variant: 'cancel',
						text: 'Cancel',
						onClick: () => {
							dispatch(hideArchiveShareModal());
						},
					},
					{
						variant: 'danger',
						text: 'Confirm',
						onClick: () =>
							archiveSharesOnClick(
								selectedShares[0]?.status === SHARE_STATUS.ACTIVE
									? SHARE_STATUS.HIDDEN
									: SHARE_STATUS.ACTIVE
							),
					},
				]}
			>
				<div>
					<p>
						{selectedShares[0]?.status === SHARE_STATUS.ACTIVE
							? 'Your links will still be active, and you may restore them later from your archived section. This change may take a few minutes to take effect.'
							: 'This change may take a few minutes to take effect.'}
					</p>
				</div>
			</Modal>
			<ClipModal
				clipData={{
					id: selectedShare?.id,
					name: selectedShare?.title,
				}}
				shareSlug={selectedShare ? selectedShare.shareSlug : null}
				isOpen={modalOpen}
				onHide={() => setModalOpen(false)}
				parentDateRange={{
					start: format(addDays(today, -8), 'yyyy-MM-dd'),
					end: format(addDays(today, -1), 'yyyy-MM-dd'),
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	modals: state.ui.modals,
	selectedAccountId: state.accounts.selectedAccountId,
});

export default connect(mapStateToProps)(ShareList);
