import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';
import MetricCard from 'components/shared/metricCard';
import MetricLineChart from '../metricLineChart';
import x from 'react-feather/dist/icons/x';

const CompanyProfileMetricsChart = (props) => {
	const {
		accountId,
		companyId,
		dateRange,
		collectionId = '',
		episodeId = '',
		themeId = '',
	} = props;

	const [currentMetrics, setCurrentMetrics] = useState(null);

	const variables = {
		accountId: accountId,
		companyId: companyId,
		startDate: dateRange.start,
		endDate: dateRange.end,
		...(collectionId && { collectionId: collectionId }),
		...(episodeId && { episodeId: episodeId }),
		...(themeId && { themeId: themeId }),
	};

	const { loading } = useQuery(queries.getCompanyProfileMetricsChartAndCard, {
		variables,
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			if (data.getCompanyProfileMetricsChartAndCard.length > 0) {
				//Filter out results with no date - Bad Data
				const filtered = data.getCompanyProfileMetricsChartAndCard
					.filter((e) => e.date?.value)
					.map((chartData) => {
					return {
						...chartData,
						date: chartData.date.value,
						impressions: chartData.impressions || 0
					};
				});

				setCurrentMetrics(
					filtered
				);
			} else {
				setCurrentMetrics(null);
			}
		},
	});

	const datasets = [
		{ dataKey: 'impressions', color: '#B8D3EB', name: 'Impressions' },
		{ dataKey: 'listen_count', color: '#895AFB', name: 'Listens' },
		{ dataKey: 'watch_count', color: '#3EBB85', name: 'Watches' },
	];

	return (
		<div className="col-12 mb-2">
			<MetricLineChart
				title="Company Metrics"
				showHelp={false}
				values={currentMetrics || []}
				loading={loading}
				datasets={datasets}
			/>
		</div>
	);
};

export default CompanyProfileMetricsChart;
