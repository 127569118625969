import React from 'react';

import styles from './styles.scss';

import { PAGINATION_POSITIONS } from '../../constants';

const Pagination = (props) => {
	const {
		current = 1,
		resultsPerPage,
		onChange = () => {},
		totalResults,
		paginationPosition = PAGINATION_POSITIONS.CENTER,
	} = props;

	let minPage = 1;
	let maxPage = Math.ceil(totalResults / resultsPerPage);
	let pageRange = 2;
	let pageItems = [];

	for (let i = minPage; i <= maxPage; i++) {
		if (i >= current - pageRange && i <= current + pageRange) {
			pageItems.push(
				<li
					className={`${styles.paginationItem} ${
						current === i ? styles.paginationActive : ''
					}`}
					key={`pagination-item-${i}`}
					onClick={() => changePage(i)}
				>
					{i}
				</li>
			);
		}
	}

	const changePage = (page) => {
		onChange(page);
	};

	const prevPage = () => {
		let minPage = 1;
		let newPage = current - 1;

		if (newPage >= minPage) {
			changePage(newPage);
		}
	};

	const nextPage = () => {
		let newPage = current + 1;

		if (newPage <= maxPage) {
			changePage(newPage);
		}
	};

	const determinePosition = () => {
		switch (paginationPosition) {
			case PAGINATION_POSITIONS.LEFT:
				return styles.left;
			case PAGINATION_POSITIONS.RIGHT:
				return styles.right;
			case PAGINATION_POSITIONS.CENTER:
			default:
				return styles.center;
		}
	};

	return (
		<div className={styles.pagination}>
			<ul className={`${styles.paginationList} ${determinePosition()}`}>
				<li
					className={`${styles.paginationItem} ${
						current === minPage ? styles.paginationDisabled : ''
					}`}
					onClick={() => prevPage()}
				>
					Prev
				</li>
				{current > minPage + pageRange && (
					<React.Fragment>
						<li
							className={`${styles.paginationItem} ${
								current === minPage ? styles.paginationDisabled : ''
							}`}
							onClick={() => changePage(minPage)}
						>
							{minPage}
						</li>
						<li className={styles.paginationItem}>...</li>
					</React.Fragment>
				)}
				{pageItems}
				{current < maxPage - pageRange && (
					<React.Fragment>
						<li className={styles.paginationItem}>...</li>
						<li
							className={styles.paginationItem}
							onClick={() => changePage(maxPage)}
						>
							{maxPage}
						</li>
					</React.Fragment>
				)}
				<li
					className={`${styles.paginationItem} ${
						current === maxPage ? styles.paginationDisabled : ''
					}`}
					onClick={() => nextPage()}
				>
					Next
				</li>
			</ul>
		</div>
	);
};

export default Pagination;
