import React from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import queries from 'services/graphql/queries';

import { Row, Col } from 'react-bootstrap';
import MetricCard from 'components/shared/metricCard';

import { getPercentage } from 'utils';

const ClipCards = (props) => {
	const variables = {
		clipId: props.clipId,
		shareSlug: props.shareSlug,
		startDate: props.dateRange.start,
		endDate: props.dateRange.end,
	};
	const { data, loading } = useQuery(queries.getClipListenSummary, {
		variables,
	});
	const { getClipListenSummary } = data || {};
	const { total_views = 0, average_listen_percentage = 0 } =
		getClipListenSummary || {};
	const percentage = getPercentage(average_listen_percentage, 1, 1);

	return (
		<Row>
			<Col>
				<MetricCard
					value={percentage + '%'}
					loading={loading}
					label="Average Clip Completion"
					hideToolTip={true}
				/>
			</Col>
			<Col>
				<MetricCard
					value={total_views}
					loading={loading}
					label="Total Views"
					hideToolTip={true}
				/>
			</Col>
		</Row>
	);
};

export default ClipCards;
